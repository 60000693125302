import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Experimental } from 'src/app/utils/shared';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ErrorListService } from 'src/app/shared/services';
import { BankProcessService } from 'src/app/bank-process';
import {
    BusinessFormService,
    SoleFormService
} from 'src/app/application/v3/services';
import { MustMatch } from 'src/app/application/application/application.business.info';

@Component({
    selector: 'app-connect-account',
    templateUrl: './connect-account.component.html',
    styleUrls: ['./connect-account.component.css', '../bank-process.styles.css']
})
export class ConnectAccountComponent implements OnInit {
    readonly form = this.formBuilder.group({
        accountName: ['', Validators.required],
        accountNumber: ['', Validators.required],
        accountNumberConfirm: ['', Validators.required],
        accountRoutingNumber: ['', Validators.required],
        bankName: ['', Validators.required],
    }, {
        validator: MustMatch('accountNumber', 'accountNumberConfirm')
    });
    private access_token: string = null;
    private applicationId: number = null;
    private loadedBankNames = {};
    private onClick = false;
    private token: any = null;
    private started: string = "started";
    accountNumberInput: string = null;
    accountNumberConfirmInput: string = null;
    bankURL: string = null;
    invalidRouting = false;
    showErrorModal = false;
    showGiactErrorModal = false;
    showNotify = false;
    submitted = false;
    validateBankAccount = false;

    //Create Notify
    phone: string;
    sent: string;
    title: string;
    textA: string;
    textB: string;

    public disclosureText: string = `By clicking "continue", I authorize Clicklease LLC and it’s assigns and the` +
        ` financial institution named above to initiate debits and credits from my business checking account for any` +
        ` and all amounts due or payable under the Equipment Lease Agreement (full terms provided at contract signing).` +
        ` This authority will remain in effect until I notify Clicklease LLC in writing to cancel it in such time as to afford the financial institution a reasonable opportunity to act on it.`;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private googleAnalyticsService: GoogleAnalyticsService,
        private errorListService: ErrorListService,
        private bankProcessService: BankProcessService,
        private soleService: SoleFormService,
        private businessService: BusinessFormService,
    ) {
        const navigation = this.router.getCurrentNavigation();

        if (navigation.extras.state) {
            this.access_token = navigation.extras.state.accessToken;
            this.applicationId = navigation.extras.state.appId;
            this.token = navigation.extras.state.token;
            this.started = navigation.extras.state.started ? navigation.extras.state.started : this.started;
        } else this.errorListService.redirectToV3Error(
            null,
            'bank-info-without-params',
            this.isClicksign ? "click-sign-otp" : "started"
        );
    }

    get currentFlow() {
        return !Experimental.isExperimental();
    }

    get formControls() {
        return this.form.controls;
    }

    get formEncode() {
        return {
            accountName: this.formControls.accountName.value,
            accountNumber: this.formControls.accountNumber.value.replace(/X/g, '').replace(/\*/g, ''),
            accountRoutingNumber: this.formControls.accountRoutingNumber.value,
            bankName: this.formControls.bankName.value,
            validateAccount: this.formControls.accountNumber.value.indexOf('******') === -1
        }
    }

    get isClicksign() {
        return this.bankProcessService.isClicksing;
    }

    get production() {
        return this.bankProcessService.isProduction;
    }

    get steps() {
        return Experimental.appSteps();
    }

    private createMessageNotify() {
        this.title = 'We just sent you an email';
        this.textA = 'We just sent a link to ';
        this.textB = "to verify bank account. If that doesn't work, please contact our support team at";
        this.phone = '(888) 509-5592';

        if (this.soleService.getControlValue('email')) {
            this.sent = this.soleService.getControlValue('email') ? this.soleService.getControlValue('email') : 'your email';
        } else this.sent = this.businessService.getControlValue('businessEmail') ?
            this.businessService.getControlValue('businessEmail') : 'your email';
        this.showNotify = true;
    }

    private decodeForm(data: any) {
        this.form.controls.accountName.setValue(data.accountName);
        this.form.controls.accountNumber.setValue(data.accountNumber);
        this.form.controls.accountNumberConfirm.setValue(data.accountNumber);
        this.form.controls.accountRoutingNumber.setValue(data.accountRoutingNumber);
        this.form.controls.bankName.setValue(data.bankName);
    }

    private redirecToBankVerification(finicityURL) {
        this.trackEvent(
            'connect-bank-prefill',
            this.bankProcessService.vendorReferrerName,
            'finicity-flow'
        );

        this.router.navigate(['../bank-verification'], {
            relativeTo: this.activatedRoute,
            replaceUrl: true,
            queryParamsHandling: "merge",
            state: {
                appId: this.applicationId,
                finicityURL,
                accessToken: this.access_token,
                token: this.token,
                started: this.started
            }
        });
    }

    private trackEvent(eventName, refererName, eventData, errorCode?) {
        if (this.isClicksign) {
            this.googleAnalyticsService.trackClickSignEvent(
                eventName,
                refererName,
                eventData,
                errorCode
            );
        } else this.googleAnalyticsService.trackV3Event(
            eventName,
            refererName,
            eventData,
            errorCode
        );
    }

    private validateErrorRedirect(data) {
        if (data && data.error) {
            switch (data.error.code) {
                case '412':
                    this.router.navigate(['../../offers-selection'], {
                        relativeTo: this.activatedRoute,
                        queryParamsHandling: "merge",
                        state: {
                            accessToken: this.access_token,
                            token: this.token,
                            appId: this.applicationId,
                            offersChanged: true
                        }
                    });
                    break;

                case '409':
                    this.router.navigate(['../giact-error'], {
                        relativeTo: this.activatedRoute,
                        queryParamsHandling: "merge",
                        state: {
                            event: data.error.code
                        }
                    });
                    break;

                default:
                    this.errorListService.redirectToV3Error(
                        data,
                        'bankinfo-submit-error',
                        this.isClicksign ? "click-sign-otp" : this.started
                    );
                    break;
            }
        } else {
            this.errorListService.redirectToV3Error(
                data,
                'bankinfo-submit-error',
                this.isClicksign ? "click-sign-otp" : this.started
            );
        }
    }

    ngOnInit() {
        this.trackEvent(
            'connect-bank-open',
            this.bankProcessService.vendorReferrerName,
            this.applicationId.toString()
        );

        this.bankProcessService.prefillBankAccountInfo(this.applicationId, this.access_token)
            .then(data => {
                this.trackEvent(
                    'getLesseeBankAccountDetails-response',
                    this.bankProcessService.vendorReferrerName,
                    JSON.stringify(data)
                );

                if (typeof data === 'string' || data instanceof String) {
                    this.redirecToBankVerification(data);
                } else {
                    this.prefillBankInformation(data);
                    this.formControls.accountRoutingNumber.valueChanges.subscribe((routing) => {
                        this.updateBankName(routing);
                    });
                }
            })
            .catch(errorB => {
                this.trackEvent(
                    'connect-bank-noprefill',
                    this.bankProcessService.vendorReferrerName,
                    JSON.stringify(errorB)
                );

                this.formControls.accountRoutingNumber.valueChanges.subscribe((routing) => {
                    this.updateBankName(routing);
                });
            });
    }

    clearRoutingInput() {
        this.accountNumberInput = '';
        this.accountNumberConfirmInput = '';
    }

    clearAccountInput() {
        this.accountNumberInput = '';
        this.accountNumberConfirmInput = '';
    }

    clearAccountInputConfirm() {
        this.accountNumberConfirmInput = '';
    }

    onCloseNotify() {
        this.showNotify = false;
    }

    onCompleteInformationClick() {
        this.decodeForm({
            accountName: 'Audio visual equipment',
            accountNumber: '1222613232',
            accountNumberConfirm: '1222613232',
            accountRoutingNumber: '121000358',
            bankName: 'US Bank association'
        });
    }

    onfailGiactContinue() {
        this.router.navigate(['../bank-verification'], {
            relativeTo: this.activatedRoute,
            replaceUrl: true,
            queryParamsHandling: "merge",
            state: {
                appId: this.applicationId,
                finicityURL: this.bankURL,
                accessToken: this.access_token,
                token: this.token,
            }
        });
    }

    onSelectedAccount() {
        this.bankProcessService.getConfirmPage(this.applicationId, this.access_token)
            .then(innerResponse => {
                if (innerResponse && innerResponse.object) {
                    this.router.navigate(['../../contract-signing'], {
                        relativeTo: this.activatedRoute,
                        queryParamsHandling: "merge",
                        replaceUrl: true,
                        state: {
                            appId: this.applicationId,
                            accessToken: this.access_token,
                            token: this.token
                        }
                    });
                } else this.errorListService.redirectToV3Error(
                    innerResponse,
                    'bankinfo-submit-error',
                    this.isClicksign ? "click-sign-otp" : "started"
                );
            })
            .catch(innerError => this.errorListService.redirectToV3Error(
                innerError,
                'bankinfo-submit-error',
                this.isClicksign ? "click-sign-otp" : "started"
            ));
    }

    prefillBankInformation({ bankAccountNumber, bankABA, bankName, accountName }) {
        this.decodeForm({
            accountNumber: ('******') + bankAccountNumber.slice(bankAccountNumber.length - 4) + '',
            accountNumberConfirm: ('******') + bankAccountNumber.slice(bankAccountNumber.length - 4) + '',
            accountRoutingNumber: bankABA + '',
            bankName: bankName + '',
            accountName: accountName
        });

        this.trackEvent(
            'connect-bank-prefill',
            this.bankProcessService.vendorReferrerName,
            `${bankAccountNumber}|${bankABA}|${bankName}`
        );
    }

    sendFinicityVerifyBankAccountEmail() {
        this.bankProcessService.sendFinicityVerifyBankAccountEmail(this.access_token)
            .then(res => {
                this.createMessageNotify();
            })
            .catch(err => {
                this.createMessageNotify();
            });
    }

    uploadBankInfo() {
        this.submitted = true;

        if (this.onClick) return

        if (this.form.invalid) return

        this.onClick = true;

        this.trackEvent(
            'connect-bank-continue',
            this.bankProcessService.vendorReferrerName,
            this.applicationId.toString()
        );

        this.bankProcessService.uploadBankInfo(
            this.applicationId,
            this.formEncode,
            this.access_token,
            this.token
        ).then(data => {
            this.onClick = false;

            this.trackEvent(
                'connect-bank-response',
                this.bankProcessService.vendorReferrerName,
                JSON.stringify(data)
            );

            if (data && (data.msg == "Finicity Required" || data.msg == "Finicity") && data.object && data.object.url) {
                this.bankURL = data.object.url;
                this.showGiactErrorModal = true;
                return;
            }

            if (data && data.object && data.object.errorCode) {
                this.router.navigate(['../giact-error'], {
                    relativeTo: this.activatedRoute,
                    queryParamsHandling: "merge",
                    state: {
                        event: data.object.errorCode
                    }
                });
                return;
            }

            if (data && data.object && data.object.bankAccountNumber) {
                this.bankProcessService.connectedAccountsObject = {};
                this.bankProcessService.clickSignleaseTermsData = data.object;
                this.router.navigate(['../../contract-signing'], {
                    relativeTo: this.activatedRoute,
                    queryParamsHandling: "merge",
                    replaceUrl: true,
                    state: {
                        appId: this.applicationId,
                        accessToken: this.access_token,
                        token: this.token
                    }
                });
                return;
            }

            if (data && data.object) {
                const offersReady = this.bankProcessService.getOffersFinicity(data);

                if (offersReady === true) {
                    this.googleAnalyticsService.trackV3Event(
                        'opportunity-created',
                        this.bankProcessService.vendorReferrerName,
                        ''
                    );
                    this.router.navigate(['../../approval'], { relativeTo: this.activatedRoute, queryParamsHandling: "merge" });
                } else {
                    if (offersReady.redirect) {
                        this.errorListService.setErrorsData(offersReady);

                        this.router.navigate([`/${offersReady.redirect}`], {
                            queryParamsHandling: "merge",
                            state: {
                                endpoint: "bank-info-error",
                                error: true,
                                start: this.isClicksign ? "click-sign-otp" : this.started
                            }
                        });

                    }
                }
            }
        }).catch(err => this.validateErrorRedirect(err));
    }

    updateBankName(routing: string) {
        this.invalidRouting = false;
        if (routing && this.formControls.accountRoutingNumber.valid) {
            if (this.loadedBankNames[routing]) {
                this.formControls.bankName.setValue(this.loadedBankNames[routing]);
            } else {

                this.bankProcessService.getBankNameByRoutingNumber(routing, this.access_token)
                    .then(bodyResponse => {
                        if (bodyResponse && bodyResponse.object) {
                            this.loadedBankNames[routing] = bodyResponse.object;
                            this.formControls.bankName.setValue(this.loadedBankNames[routing]);
                            this.trackEvent(
                                'valid-routing-number',
                                this.bankProcessService.vendorReferrerName,
                                JSON.stringify(bodyResponse)
                            );
                        } else {
                            this.trackEvent(
                                'invalid-routing-number',
                                this.bankProcessService.vendorReferrerName,
                                JSON.stringify(bodyResponse)
                            );
                            this.invalidRouting = true;
                        }
                    })
                    .catch(errorResponse => {
                        this.formControls.bankName.setValue("");
                        this.trackEvent(
                            'invalid-routing-number',
                            this.bankProcessService.vendorReferrerName,
                            JSON.stringify(errorResponse)
                        );
                        this.invalidRouting = true;
                    });
            }
        }
    }
}