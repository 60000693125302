import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { DataRobotItem } from '../data-robot.item';
declare var $: any;

@Component({
    selector: 'app-data-robot-item',
    templateUrl: './data-robot-item.component.html',
    styleUrls: ['./data-robot-item.component.css']
})
export class DataRobotItemComponent implements OnInit {

    activeDataRobot: DataRobotItem | any = {};
    dataRobotModelForm: UntypedFormGroup;
    updateModelDescriptionSubmitted = false;
    updateModelAffixSubmitted = false;

    @Input() dataRobotModelId = '';
    @Input() dataRobotModels: any[] = [];

    @Output() itemLoaded = new EventEmitter<void>();
    @Output() getDataRobotModelList = new EventEmitter<any>();
    @Output() showErrorMessage = new EventEmitter<string>();

    @ViewChild('testModelSwitch', { static: true }) testModelSwitchRef: ElementRef;
    @ViewChild('byOwnerSwitch', { static: true }) byOwnerSwitchRef: ElementRef;

    constructor(
        private connectionService: ConnectionService
    ) {
        this.dataRobotModelForm = new UntypedFormGroup({
            description: new UntypedFormControl('', Validators.required),
            modelAffix: new UntypedFormControl('', Validators.required)
        });
    }

    ngOnInit(): void {
        this.testModelSwitchRef.nativeElement.checked = false;
        this.byOwnerSwitchRef.nativeElement.checked = false;
        this.getDataRobotModelDetailAux(this.dataRobotModelId).then(
            () => this.itemLoaded.emit()
        );
    }

    getDataRobotModelDetailAux(pModelId: string) {
        return new Promise((resolve, reject) => {
            this.getDataRobotModelDetail(pModelId).then(
                (data: DataRobotItem) => {
                    this.activeDataRobot = data;
                    this.testModelSwitchRef.nativeElement.checked = data.testModel;
                    this.dataRobotModelForm.controls.description.patchValue(data.description);
                    this.dataRobotModelForm.controls.modelAffix.patchValue(data.modelAffix);
                    this.byOwnerSwitchRef.nativeElement.checked = data.byOwner;
                    resolve(data);
                },
                (error: any) => {
                    resolve(error);
                }
            );
        });
    }

    getDataRobotModelDetail(dataRobotModelId: string) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'datarobotmodel/detail/' + dataRobotModelId, 'application/json').subscribe(
                (data: any) => {
                    const dataRobotM: DataRobotItem = new DataRobotItem(data.object);
                    resolve(dataRobotM);
                },
                (error: any) => {
                    this.showErrorMessage.emit(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    onChangeDescriptionValue() {
        this.updateModelDescriptionSubmitted = true;
        if (this.dataRobotModelForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            const postData: any = {
                id: Number(this.dataRobotModelId),
                description: this.dataRobotModelForm.controls.description.value
            };
            this.connectionService.postRequest(environment.gateway + 'datarobotmodel/description', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    resolveSuccess(resolve: (value: unknown) => void, data: any) {
        this.getDataRobotModelDetailAux(this.dataRobotModelId);
        this.getDataRobotModelList.emit(this.dataRobotModelId);
        this.updateModelDescriptionSubmitted = false;
        this.updateModelAffixSubmitted = false;
        resolve(data);
    }

    resolveError(reject: (reason?: any) => void, error: any) {
        this.getDataRobotModelDetailAux(this.dataRobotModelId);
        this.getDataRobotModelList.emit(this.dataRobotModelId);
        this.showErrorMessage.emit(error.error.msg);
        this.updateModelDescriptionSubmitted = false;
        this.updateModelAffixSubmitted = false;
        reject(error);
    }

    onChangeTestModel() {
        return new Promise((resolve, reject) => {
            const postData: any = {
                id: Number(this.dataRobotModelId),
                testModel: this.testModelSwitchRef.nativeElement.checked
            };
            this.connectionService.postRequest(environment.gateway + 'datarobotmodel/testModel', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onChangeByOwner() {
        return new Promise((resolve, reject) => {
            const postData: any = {
                id: Number(this.dataRobotModelId),
                byOwner: this.byOwnerSwitchRef.nativeElement.checked
            };
            this.connectionService.postRequest(environment.gateway + 'datarobotmodel/byOwner', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onChangeModelAffix() {
        this.updateModelAffixSubmitted = true;
        if (this.dataRobotModelForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            const postData: any = {
                id: Number(this.dataRobotModelId),
                modelAffix: this.dataRobotModelForm.controls.modelAffix.value
            };
            this.connectionService.postRequest(environment.gateway + 'datarobotmodel/modelAffix', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }
}