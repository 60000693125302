<ul *ngIf="processStatus.vodStatusProcess.statusBar && processStatus.vodStatusProcess.statusBar.length"
    class="progress-bar-list d-inline-flex align-items-stretch bg-white py-md-2 py-3 px-3 mt-4 text-black-50 flex-column flex-md-row w-100">
    <li
        class="order-0 pb-1 mb-2 mb-md-0 pt-0 flex-lg-grow-1 progress-bar-item d-flex pr-3 pb-md-3 pl-md-3 pl-0 pt-md-3">
        <div class="d-flex align-items-start step ERROR">
            <span class="font-mont-bold flex-grow-1 step-text">Verification Status</span>
        </div>
    </li>
    <li *ngFor="let item of processStatus.vodStatusProcess.statusBar | sort:'order'; let i =index"
        class="pt-3 progress-bar-item d-flex pr-3 pb-md-3 pl-md-3 pl-0 pt-md-3">
        <div class="d-flex align-items-start step" [ngClass]="[itemStyle(item)]">
            <span class="progress-bar-icon d-flex mr-2 status-img">
                <div></div>
            </span>
            <span class="font-mont-bold flex-grow-1 step-text">{{item.textA}} {{item.textB}}</span>
        </div>
    </li>
</ul>