import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-docu-sign-completed',
    templateUrl: './docu-sign-completed.component.html',
    styleUrls: ['./docu-sign-completed.component.css']
})
export class DocuSignCompletedComponent implements OnInit {
    private event: any;

    constructor(private route: ActivatedRoute) {
        this.event = this.route.snapshot.queryParams.event;
    }

    ngOnInit(): void {
        console.log("ng Init Completed");
        if(window.parent && window.parent.window) {
            console.log("ng Init Sent");
            window.parent.window.postMessage({ type: 'docu-sign', event: this.event });
        }
    }
}
