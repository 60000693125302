<table>
    <thead>
        <tr>
            <th>Name</th>
            <th>Size</th>
            <th>Type</th>
            <th>Remove</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let document of documents">
            <td>{{document.file.name}}</td>
            <td>{{document.file.size}}</td>
            <td>{{document.file.type}}</td>
            <td class="link-cell" (click)="onRemoveDocument(document);">remove</td>
        </tr>
    </tbody>
</table>