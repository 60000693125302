import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Experimental } from 'src/app/utils/shared';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services';
import { ApplicationService } from 'src/app/application/v3/services';
import { ClickSingService } from '../click-sing';

@Injectable()
export class BankProcessService {
    private socket: SockJS = null;
    private socketConnection: Stomp.Client = null;
    bankSatusRequired: any = null;
    connectedAccountsObject: any = {};
    daysToExpire: number = null;
    finicityLink: string = null;
    leaseTermsData: any = null;
    maxApprovedAmount: number = null;
    offerList: any = null;
    requestedAmount: number = null;
    
    vendorPhone = null;

    constructor(
        private router: Router,
        private connectionService: ConnectionService,
        private authService: AuthService,
        private applicationService: ApplicationService,
        private clickSingService: ClickSingService
    ) { }

    get accessToken() {
        return this.authService.authTokenV3;
    }

    set accessToken(token) {
        this.authService.authTokenV3 = token;
    }

    get hasSokcet() {
        let hasSoket = false;

        if (this.socket) hasSoket = true;

        return hasSoket;
    }

    get isClicksing(): boolean {
        const indicator = this.router.url.toLowerCase().indexOf('clicksign'.toLowerCase());
        let isClicksing = false

        if (indicator !== -1) isClicksing = true;

        return isClicksing;
    }

    get isProduction() {
        return this.connectionService.isProduction;
    }

    get vendorReferrerName() {
        return this.authService.vendorReferrerName;
    }

    set clickSignleaseTermsData(data) {
        this.clickSingService.leaseTermsData = data;
    }

    async getBankNameByRoutingNumber(routing, access_token) {
        try {
            const data = await this.connectionService.getV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/getBankNameByRoutingNumber/${routing}`,
                contentType: 'application/json',
                access_token
            });

            return data;
        } catch (err) { throw err; }
    }

    async getConfirmPage(applicationId, access_token) {
        try {
            const data = await this.connectionService.getV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/getHeadersConfirmationPage/${applicationId}`,
                contentType: 'application/json',
                access_token,
            });

            if (data && data.object) this.clickSingService.leaseTermsData = data.object;

            return data;
        } catch (err) { throw err; }
    }

    async loadBankAccountData(applicationId, access_token?) {
        try {
            const data = await this.connectionService.getV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/getLesseeBankAccountDetails/${applicationId}`,
                contentType: 'application/json',
                access_token: access_token
            });
    
            if (data && data.object) {
                this.connectedAccountsObject[applicationId] = data.object;
            }

            return this.getConnectAccountById(applicationId);
        } catch (err) { throw err; }
    }

    async prefillBankAccountInfo(applicationId, access_token?) {
        try {
            let data;

            if (this.getConnectAccountById(applicationId)) {
                data = this.getConnectAccountById(applicationId);
            } else data = await this.loadBankAccountData(applicationId, access_token);

            return data
        } catch (err) { throw err; }
    }

    async sendFinicityVerifyBankAccountEmail(access_token) {
        try {
            const data = await this.connectionService.getV3RequestP({
                url: `${environment.gatewayV3}offerservice/api/sendFinicityEmailLink/${this.applicationService.applicationId}`,
                contentType: 'application/json',
                access_token
            });

            return data;
            
        } catch (err) { return err; }
    }

    async uploadBankInfo(applicationId, postData, access_token, vendor_token) {
        try {
            const data = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/application/bankinfo/${applicationId}`,
                postData,
                contentType: 'application/json',
                access_token,
                vendor_token
            });

            return data;
        } catch (err) { throw err; }
    }
    connectSocket(applicationId, access_token: string): Promise<Stomp.Message> {
        return new Promise((resolve, reject) => {
            this.socket = new SockJS(`${environment.gatewayV3}leaseapplication/notification?access_token=${access_token}`);
            this.socketConnection = Stomp.over(this.socket);
            this.socketConnection.debug = () => { };

            this.socketConnection.connect({ applicationId }, () =>
                this.socketConnection.subscribe('/users/queue/messages', (message) => {
                    this.socketConnection.disconnect(() => this.resetSocket());
                    resolve(message);
                }, err => reject({ err, errType: 'subscribe' }))
                , err => reject({ err, errType: 'connect' }));
        });
    }

    getOffersFinicity(data: any) {
        let response;

        if (data && data.object) {

            if (data.object.applicationId) {
                this.applicationService.applicationId = data.object.applicationId;
            }

            this.applicationService.maxApprovedAmount = data.object.maxApproved;
            this.applicationService.requestedAmount = data.object.requestAmount;
            this.applicationService.daysToExpire = data.object.daysToExpire;

            response = {
                redirect: 'process-error',
                error: data
            };

            if (data.msg == 'DATA_ERROR') response = {
                redirect: 'data-error',
                error: data
            };

            if (data.object.status == 'REJECTED') response = {
                redirect: 'decline-error',
                error: data
            }

            if (data.object.offerList && data.object.offerList.length) {
                this.applicationService.offerList = data.object.offerList;
                response = true;
            }

        } else if (data) {
            switch (data.msg) {
                case 'DATA_ERROR':
                    response = {
                        redirect: 'data-error',
                        error: data
                    };
                    break;
                case 'No offer available':
                    response = {
                        redirect: 'decline-error',
                        error: data
                    };
                    break;
                
                default:
                    response = {
                        redirect: 'general-error-v3',
                        error: data
                    };
                    break;
            }
        }

        return response
    }

    getConnectAccountById(id: string) {
        return this.connectedAccountsObject[id];
    }

    hasConnectedAccount(id: string) {
        let hasConnectedAccount = false;

        if (this.getConnectAccountById(id)) hasConnectedAccount = true;

        return hasConnectedAccount;
    }

    resetSocket() {
        if (this.hasSokcet) {
            this.socket.close();
            this.socket = null;
        }
    }
}