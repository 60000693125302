<div class="modal fade fullscreen-modal" id="mappingProductsModal" tabindex="-1" aria-labelledby="selectOfferModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-0">
      <div class="modal-body">
        <div class="close_button" (click)="onCloseMapingModal()" ></div>
        <form #importForm="ngForm">
          <div class="container">
            <div class="row">
              <label for="recipient-name" class="labelHeader spacing3">Import Products</label>
            </div>
            <div class="row">
              <p class="spacing3">Use the table below to map the column headings from your imported file.</p>
            </div>
            <div class="row wrapper">
              <div class="spacing3">
                <label class="labelHeader2" for="title">Column Headings</label>
              </div>
              <div class="arrow-image">
              </div>
              <div class="spacing3">
                <label class="labelHeader2 " for="title">From Imported File</label>
              </div>
            </div>
            <hr>
            <div class="row wrapper">
              <div class="spacing3">
                <label for="title">Title</label>
              </div>
              <div class="arrow-image">
              </div>
              <div class="spacing3 dropdown">
                <select class="form-control dropdown-content" name="selectAction" [(ngModel)]="title">
                  <option [ngValue]="product.title" selected disabled>-</option>
                  <option [ngValue]="op" *ngFor="let op of headersProducts;">
                    {{op}}
                  </option>
                </select>
              </div>
            </div>
            <hr>
            <div class="row wrapper">
              <div class="spacing3">
                <label for="title">SKU</label>
              </div>
              <div class="arrow-image">
              </div>
              <div class="spacing3 dropdown">
                <select class="form-control dropdown-content" name="selectAction" [(ngModel)]="sku">
                  <option [ngValue]="product.sku" selected disabled>-</option>
                  <option [ngValue]="op" *ngFor="let op of headersProducts">
                    {{op}}
                  </option>
                </select>
              </div>
            </div>
            <hr>
            <div class="row wrapper">
              <div class="spacing3">
                <label for="title">Description</label>
              </div>
              <div class="arrow-image">
              </div>
              <div class="spacing3 dropdown">
                <select class="form-control dropdown-content" name="selectAction" [(ngModel)]="description">
                  <option [ngValue]="product.description" selected disabled>-</option>
                  <option [ngValue]="op" *ngFor="let op of headersProducts">
                    {{op}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row wrapper">
              <div class="spacing3">
                <button type="button" class="btnCancel" data-dismiss="modal" aria-label="Close" (click)="cleanHeaders()">
                  Cancel
                </button>
              </div>
              <div></div>
              <div>
                <button type="submit" class="btnImport" (click)="import()">Import</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
