import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { AuthService } from 'src/app/auth/auth.service';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
declare var $: any;

@Component({
    selector: 'app-additional-payment-calculator',
    templateUrl: './additional-payment-calculator.component.html',
    styleUrls: ['./additional-payment-calculator.component.css']
})
export class AdditionaPaymentCalculatorComponent implements OnInit {
    @Input() id = 0;
    @Input() approvedAmount = 0;
    public modalId = 'paymentCalculatorModal' + this.id;

    public calculatorForm: UntypedFormGroup;
    public submitted: boolean = false;

    public offers: any = {
        offerList: []
    };

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        public googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.calculatorForm = this.formBuilder.group({
            amount: ['', Validators.required, this.requestedAmount.bind(this)]
        });
    }

    ngOnInit() {
        this.modalId = 'paymentCalculatorModal' + this.id;
    }

    onClick() {
        this.googleAnalyticsService.trackEvent('additional-options', 'open', 'payment-calculator');
        this.getOffers().then(
            () => {
                this.submitted = false;
                this.calculatorForm.controls.amount.setValue('');
                $('#paymentCalculatorModal' + this.id).modal();
            }
        );
    }

    getOffers() {
        const promise = new Promise((resolve, reject) => {
            const url = environment.gateway + 'application/' + this.id + '/activeOffers/' + this.authService.getUserId();
            this.connectionService.getRequest(url, 'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
                (data: any) => {
                    if (data.object) {
                        this.offers = data.object;
                    }
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

    offerRecalc() {
        const promise = new Promise((resolve, reject) => {
            this.googleAnalyticsService.trackEvent('additional-options', 'recalc', 'payment-calculator');
            const url = environment.gateway + 'v2/application/' + this.id + '/offerRecalc/calculation/' + this.authService.getUserId();
            this.connectionService.postRequest(url, { newRequestAmount: this.calculatorForm.controls.amount.value }, 'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
                (data: any) => {
                    if (data.object) {
                        this.offers = data.object;
                    }
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

    onCalculateClick() {
        this.submitted = true;
        if (this.calculatorForm.invalid) {
            return;
        }

        this.offerRecalc();
    }

    requestedAmount(control: UntypedFormControl) {
        if (control.errors && !control.errors.requestedAmount) {
            return;
        }

        if (control.value < 500) {
            control.setErrors({ requestedAmount: true });
        } else {
            control.setErrors(null);
        }
        const promise = new Promise((resolve, reject) => {
        });
        return promise;
    }

}
