import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.css']
})
export class ReadMoreComponent implements OnInit {
  public isCollapsed: boolean = true;

  @Input() textToDisplay: string = '';

  private limit = 207;
  private lessText = "Read less";
  private moreText = "Read more";

  public textAllowed = '';
  public toggleText = '';

  constructor() { }

  ngOnInit() {
    this.toggleText = this.moreText;
    this.textAllowed = this.textToDisplay.slice(0, this.limit);
  }

  showText() {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      this.toggleText = this.moreText;
      this.textAllowed = this.textToDisplay.slice(0, this.limit);
    } else {
      this.toggleText = this.lessText;
      this.textAllowed = this.textToDisplay;
    }
  }

}
