<app-application-wrapper [subTitleA]="'LEASE AGREEMENT'" [subTitleB]="'STEP 1 OF 4'">
  <ng-template appApplicationWrapperHelper>
    <div>
      <h1 class="title font-mont-bold">Choose a payment term</h1>
      <div class="payment-changed" *ngIf="paymentChanged">
        <img class="info-icon" src="\assets\images\icons\info-icon.png" alt="info-icon" />
        <h1 class="pay-changed-title font-mont-bold">Your term options have changed</h1>
        <h1 class="pay-changed font-mont-regular">
          Our system recognized an update to your invoice since you made your selection.
        </h1>
        <h1 class="pay-changed font-mont-bold">Select your payment option below. </h1>
      </div>
      <!-- <p class="text"> <strong class="font-mont-bold">Invoice Amount: </strong><span class="font-mont-regular"
          *ngIf="offers">{{ offers.requestAmount | currency:'$':'code':'.2-2' }}</span></p> -->
      <div class="row table-container">
        <div class="col-12">
          <div class="box-child clearfix">
            <form (ngSubmit)="selectoffer()" [formGroup]="selectedOfferGroup">
              <table class="table table-hover" aria-describedby="offers table">
                <thead>
                  <tr>
                    <th class="font-mont-bold" scope="col">MONTHS</th>
                    <th class="font-mont-bold est-head" scope="col">EST. MONTHLY PAYMENT</th>
                  </tr>
                </thead>
                <tbody *ngIf="offers">
                  <tr *ngFor="let offer of offers.offerList; index as i" [ngClass]="{'bg-blue': selectedOption == i}"
                    (click)="onSelectOffer(i, offer.offerId);">
                    <td style="width:25%" class="terms font-mont-bold">{{offer.term}}</td>
                    <td style="width:75%" class="font-mont-regular">{{ offer.payment | currency:'$':'code':'.2-2' }}
                      <input class="radioButton" type="radio" id="offer{{offer.offerId}}" [value]="offer.offerId"
                        formControlName="selectedOfferControl" name="selectedOfferControl"
                        [checked]="selectedOption == i">
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3 class="font-mont-regular addText">Payment amounts include estimated taxes. Terms include purchase option payments.</h3>
              <button type="submit" class="btn btn-secondary btn-lg submit-btn w-100 font-mont-bold">
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="../../../../assets/images/logoPayment.svg" alt="">
  </ng-container>
</app-application-wrapper>
