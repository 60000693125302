<div class="modal fade" id="headerVendorBrockerSelectDialog" tabindex="-1" role="dialog"
    aria-labelledby="headerVendorBrockerSelectDialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold">
                    <img src="/assets/images/icons/addCoSigner.svg" />
                    Select your user
                </h5>
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <h3 class="font-mont-semi-bold">Broker</h3>
                        <table class="table" *ngIf='vendors.length>1'>
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">
                                        <div class="font-mont-semi-bold">Sales Person Name</div>
                                    </th>
                                    <th scope="col" class="text-center">
                                        <div class="font-mont-semi-bold">Vendor Name</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let vendor of vendors; let i = index">
                                    <tr class="row-data" (click)="changeVendor(i)" *ngIf="vendor.vendorType == 'BROKER'"
                                        [ngClass]="{'selected-sales-person': vendorSelected.salespersonId == vendor.salespersonId}">
                                        <td class="text-center">{{vendor.spFullName}}</td>
                                        <td class="text-center">{{vendor.vendorName}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <h3 class="font-mont-semi-bold mgt-50">Vendor</h3>
                        <table class="table" *ngIf='vendors.length > 2'>
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">
                                        <div class="font-mont-semi-bold">Sales Person Name</div>
                                    </th>
                                    <th scope="col" class="text-center">
                                        <div class="font-mont-semi-bold">Vendor Name</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let vendor of vendors; let i = index">
                                    <tr class="row-data" (click)="changeVendor(i)" *ngIf="vendor.vendorType == 'VENDOR'"
                                        [ngClass]="{'selected-sales-person': vendorSelected.salespersonId == vendor.salespersonId}">
                                        <td class="text-center">{{vendor.spFullName}}</td>
                                        <td class="text-center">{{vendor.vendorName}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div *ngIf='vendors.length==1' class="noVendors">
                            <h3>NO VENDORS TO SHOW!</h3>
                        </div>
                        <h5 class="modal-title w-100 text-left font-mont-bold text-center mgt-50">
                            <div class="font-mont-semi-bold">Selected Sales Person:</div>
                            <div class="font-mont-semi-bold">
                                {{vendorSelected.spFullName}} -
                                {{vendorSelected.vendorName}} -
                                {{vendorSelected.vendorType}}
                            </div>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="submit" data-dismiss="modal" (click)="close();"
                            class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                            SELECT
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="'SendApplicationCompleted'" [title]="errorTitle" [messageA]="errorMessageA"
    [messageB]="errorMessageB" [messageC]="errorMessageC" [messageBClass]="errorMessageBClass"
    [dialogClass]="dialogClass">
</app-error-dialog>