import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DirectAccessGuardGuard } from 'src/app/auth/direct-access-guard.guard';

// Components clicksing
import { ConfirmTermsComponent } from './confirm-terms/confirm-terms.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { DriversLicenseComponent } from './drivers-license/drivers-license.component';
import { DocusignErrorComponent } from './docusign-error/docusign-error.component';
import { TryAgainComponent } from './try-again/try-again.component';
import { DeclinedComponent } from './declined/declined.component';

// Also found in oldsing
import { DocusignOtpComponent } from './docusign-otp/docusign-otp.component';
import { DocusignPageComponent } from './docusign-page/docusign-page.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'otp-verification',
        pathMatch: 'full'
    },
    {
        path: 'otp-verification',
        component: DocusignOtpComponent
    },
    {
        path: 'contract-signing',
        component: ConfirmTermsComponent,
        canActivate: [DirectAccessGuardGuard]
    },
    {
        path: 'docusign-contract',
        component: DocusignPageComponent,
        canActivate: [DirectAccessGuardGuard]
    },
    {
        path: 'final-step',
        component: DriversLicenseComponent,
        canActivate: [DirectAccessGuardGuard]
    },
    {
        path: 'docusign-failed',
        component: DocusignErrorComponent,
        canActivate: [DirectAccessGuardGuard]
    },
    {
        path: 'offers-selection',
        component: PaymentOptionsComponent,
        canActivate: [DirectAccessGuardGuard]
    },
    { 
        path: 'try-again', 
        component: TryAgainComponent, 
        canActivate: [DirectAccessGuardGuard]
    },
    { 
        path: 'declined', 
        component: DeclinedComponent, 
        canActivate: [DirectAccessGuardGuard]
    },
    // ???
    {
        path: 'bank-process', loadChildren: () => import('../bank-process/bank-process.module').then(m => m.BankProcessModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class ClicksingRoutingModule { }
