import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ClickResponse } from './click.response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
    private loggedUserEmail: string = null;
    private loggedUser: any = null;
    private _keyCloakToken: any = null;
    private impersonatedUser: any = null;
    private vendorInfo = undefined;
    private vendorInfoPromise = undefined;
    private appToken = null;
    private queryToken: string = '';
    private isGPSMultiLocation = false;
    private _applyOptionDisabled: boolean = false;

    staticToken: string = null;
    authTokenV3: string = null;
    token: string = null;

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private route: ActivatedRoute
    ) {
        const navigation = this.router.getCurrentNavigation();
        this.appToken = navigation && navigation.extras.state && navigation.extras.state.token ? navigation.extras.state.token : null;
    }

    get clickPlus() {
        return new Promise((resolve) => {
            this.vendorTokenInfo.then(
                (data: any) => {
                    if (data.clickPlus) {
                        resolve(data.clickPlus);
                    } else if (this.route.snapshot.queryParams.token) {
                        resolve(false);
                    }
                    else {
                        resolve(this.vendor.clickPlus);
                    }
                }
            )
        })
    }

    get getQueryToken() {
        return this.queryToken || '';
    }

    get keyCloakToken() {
        if (!this._keyCloakToken) {
            this._keyCloakToken = JSON.parse(localStorage.getItem('click_cloak_data')).access_token;
        }
        return this._keyCloakToken;
    }

    get maxApplicationAmount() {
        return new Promise((resolve) => {
            this.vendorTokenInfo.then(
                (data: any) => {
                    if (data.maxApplicationAmount) {
                        resolve(data.maxApplicationAmount);
                    } else if (this.route.snapshot.queryParams.token) {
                        resolve(null);
                    } else {
                        resolve(this.vendor.maxApplicationAmount);
                    }
                }
            );
        });
    }

    get vendor() {
        let vendor: any;
        if (this.getImpersonatedUser()) {
            return this.impersonatedUser;
        } else {
            if (this.vendorSelected()) {
                vendor = this.vendorSelected()
                return vendor;
            } else {
                return this.getLoggedUser() ? this.loggedUser.defaultVendor : {};
            }
        }
    }

    get vendorExcludeAppResults() {
        if (this.vendorInfo && this.vendorInfo.excludeApplicationResults) {
            return this.vendor.excludeApplicationResults;
        }
        return false
    }

    get vendorReferrerName() {
        if (this.vendorInfo && this.vendorInfo.dbaName) {
            return this.vendorInfo.dbaName;
        } else {
            let vendor = this.vendor;
            if (vendor && vendor.vendorName) {
                return vendor.vendorName;
            }
        }
        return '';
    }

    get vendorTokenInfo() {
        if (!this.vendorInfoPromise) {
            if (!this.appToken) {
                this.appToken = this.route.snapshot.queryParams.token;
            }
            const url = environment.gateway + `vendor/info?token=${this.appToken}`;
            const httpOptions = {
                headers: this.getHttpHeadders('application/json')
            };

            this.vendorInfoPromise = new Promise((resolve) => {
                if (this.vendorInfo != undefined) {
                    resolve(this.vendorInfo);
                } else if (this.route.snapshot.queryParams.token) {
                    this.httpClient.post(url, {}, httpOptions).pipe(tap(
                        (data: any) => {
                            this.vendorInfo = data.object;
                            resolve(this.vendorInfo);
                        },
                        () => {
                            this.vendorInfo = {};
                            resolve(this.vendorInfo);
                        }
                    )).subscribe();
                } else {
                    this.vendorInfo = {};
                    resolve(this.vendorInfo);
                }
            });
        }

        return this.vendorInfoPromise;
    }

    get applyOptionDisabled(): boolean {
        return this._applyOptionDisabled;
    }

    set applyOptionDisabled(value: boolean) {
        this._applyOptionDisabled = value;
    }

    private getHttpHeadders(pContentType: string): HttpHeaders {
        if (environment.userSecret && environment.x3scaleProxySecretToken && environment.xClkToken) {
            return new HttpHeaders({
                'Content-type': pContentType,
                'user-secret': environment.userSecret,
                'X-3scale-proxy-secret-token': environment.x3scaleProxySecretToken,
                'X-clk-token': environment.xClkToken,
                'X-application-source': environment.xApplicationSource,
                'X-environment-url': environment.xEnvironmentUrl
            });
        } else {
            return new HttpHeaders({
                'Content-type': pContentType,
                'X-application-source': environment.xApplicationSource,
                'X-environment-url': environment.xEnvironmentUrl
            });
        }
    }

    getVendorTokenInfo(token: string) {
        if (!this.vendorInfoPromise) {
            const url = environment.gateway + `vendor/info?token=${token}`;
            const httpOptions = {
                headers: this.getHttpHeadders('application/json')
            };

            this.vendorInfoPromise = new Promise((resolve) => {
                if (this.vendorInfo != undefined) {
                    resolve(this.vendorInfo);
                } else if (token) {
                    this.httpClient.post(url, {}, httpOptions).pipe(tap(
                        (data: any) => {
                            this.vendorInfo = data.object;
                            resolve(this.vendorInfo);
                        },
                        () => {
                            this.vendorInfo = {};
                            resolve(this.vendorInfo);
                        }
                    )).subscribe();
                } else {
                    this.vendorInfo = {};
                    resolve(this.vendorInfo);
                }
            });
        }

        return this.vendorInfoPromise;
    }

    setQueryToken(token: string) {
        this.queryToken = token;
    }

    singinUser(email: string, password: string) {
        return new Promise((resolve, reject) => {
            const url = environment.gateway + 'oauth/token/';
            const postData = new HttpParams()
                .set('grant_type', 'password')
                .set('username', email)
                .set('password', encodeURI(btoa(password)));
            const httpOptions = {
                headers: this.getHttpHeadders('application/x-www-form-urlencoded')
            };
            this.httpClient.post<ClickResponse>(url, postData.toString(), httpOptions).subscribe(
                (data: any) => {
                    localStorage.setItem('click_oauth_data', JSON.stringify(data.object));
                    this.loggedUserEmail = email;
                    resolve(data);
                },
                (error: any) => {
                    this.loggedUserEmail = null;
                    reject(error);
                }
            );
        });
    }

    getLoggedUserData(email?) {
        this.loggedUserEmail = email ? email : this.loggedUserEmail;
        const url = environment.gateway + 'user/' + this.loggedUserEmail + '?access_token=' + this.getAccessToken();
        const httpOptions = {
            headers: this.getHttpHeadders('application/json')
        };

        return new Promise((resolve, reject) => {
            this.httpClient.get<ClickResponse>(url, httpOptions).subscribe(
                (data: any) => {
                    this.loggedUser = data.object;
                    localStorage.setItem('logged_user_data', JSON.stringify(data.object));
                    localStorage.setItem('click_cloak_data', JSON.stringify({ access_token: data.object.keyCloakToken }));
                    if (!email) {
                        localStorage.removeItem('impersonated_user_data');
                        this.impersonatedUser = null;
                    }
                    resolve(data);

                },
                (error: any) => {
                    this.loggedUser = null;
                    this.impersonatedUser = null;
                    reject(error);
                }
            );
        });
    }

    getVendors() {
        const user = JSON.parse(localStorage.getItem('logged_user_data'));
        if (!user) { return null; }
        const broker = user.defaultVendor;
        const vendors = user.salesPersonList;
        let salesPerList = [];
        for (let vend = 0; vend < vendors.length + 1; vend++) {
            if (vend != 0) {
                salesPerList[vend] = vendors[vend - 1]

            } else {
                salesPerList[vend] = broker;

            }
        }
        return salesPerList;
    }

    sendResetPasswordEmail(email: string) {
        const url = environment.gateway + 'user/resetpassword/sendToken?email=' + email;
        const httpOptions = {
            headers: this.getHttpHeadders('application/json')
        };

        return this.httpClient.post(url, {}, httpOptions);
    }

    resetPassword(token: string, password: string) {
        const url = environment.gateway + 'user/resetpassword/reset?token=' + token + '&password=' + encodeURI(btoa(password));
        const httpOptions = {
            headers: this.getHttpHeadders('application/json')
        };

        return new Promise((resolve, reject) => {
            this.httpClient.post(url, {}, httpOptions).pipe(tap(
                data => resolve(data),
                error => reject(error)
            )).subscribe();
        });
    }

    signOutUser() {
        this.token = '';
        localStorage.removeItem('click_oauth_data');
        localStorage.removeItem('logged_user_data');
        localStorage.removeItem('click_cloak_data');
        localStorage.removeItem('impersonated_user_data');
        localStorage.removeItem('vendSelected_data');
        this.router.navigate(['signin']);
        this.loggedUserEmail = null;
        this.loggedUser = null;
        this.impersonatedUser = null;
    }

    getAccessToken() {
        const click_oauth = JSON.parse(localStorage.getItem('click_oauth_data'));
        return click_oauth != null ? JSON.parse(localStorage.getItem('click_oauth_data')).access_token : null;
    }

    getLoggedUser() {
        if (!this.loggedUser) {
            this.loggedUser = JSON.parse(localStorage.getItem('logged_user_data'));
        }
        return this.loggedUser;
    }

    getImpersonatedUser() {
        if (!this.impersonatedUser) {
            this.impersonatedUser = JSON.parse(localStorage.getItem('impersonated_user_data'));
        }
        return this.impersonatedUser;
    }

    getExcludePortalApp() {
        let defaultVendor: any = localStorage.getItem('logged_user_data');
        let vendorSelected: any = localStorage.getItem("vendSelected_data");

        if (vendorSelected) {
            vendorSelected = JSON.parse(vendorSelected);
            return vendorSelected ? vendorSelected.excludePortalApp ? vendorSelected.excludePortalApp : false : false;



        } else if (defaultVendor) {
            defaultVendor = JSON.parse(defaultVendor).defaultVendor;
            return defaultVendor ? defaultVendor.excludePortalApp ? defaultVendor.excludePortalApp : false : false;

        }
        return false;
    }

    vendorSelected() {
        const loggedUser = JSON.parse(localStorage.getItem('vendSelected_data'));
        if (loggedUser) {
            return loggedUser
        }
    }

    getUserId() {
        return this.getLoggedUser() ? this.loggedUser.id : null;
    }

    getSalesPersonId() {
        return this.vendor.salesPersonId ? this.vendor.salesPersonId : this.vendor.salespersonId;
    }

    getVendorId() {
        return this.vendor.vendorId;
    }

    getVendorInfoId() {
        return this.vendorInfo.id;
    }

    getVendorType() {
        return this.vendor.vendorType;
    }

    gpsUnitRequired() {
        return this.vendor.gpsUnitRequired;
    }

    setGpsUnitRequired(pGpsUnitRequired: boolean) {
        this.vendor.gpsUnitRequired = pGpsUnitRequired;
    }

    setIsGPSMultiLocation(isMultiLocation: boolean) {
        this.isGPSMultiLocation = isMultiLocation;
    }

    getIsGPSMultiLocation() {
        return this.isGPSMultiLocation;
    }

    getEmail() {
        if (this.getImpersonatedUser()) {
            return this.impersonatedUser.userName;
        } else {
            return this.getLoggedUser() ? this.loggedUser.userName : null;
        }
    }

    getMaxApplicationAmount() {
        if (this.getImpersonatedUser()) {
            return this.impersonatedUser.maxApplicationAmount;
        } else {
            return this.getLoggedUser() ? this.loggedUser.defaultVendor.maxApplicationAmount : null;
        }
    }

    setImpersonatedVendor(impersonatedUser: any) {
        this.impersonatedUser = impersonatedUser;
        localStorage.setItem('impersonated_user_data', JSON.stringify(impersonatedUser));
    }

    clearImpersonatedVendor() {
        this.impersonatedUser = null;
        localStorage.removeItem('impersonated_user_data');
    }

    validateSession() {
        let accessToken = this.getAccessToken();
        if (accessToken) {
            const url = environment.gateway + 'oauth/validateToken?access_token=' + accessToken;
            const httpOptions = {
                headers: this.getHttpHeadders('application/json')
            };

            return new Promise((resolve, reject) => {
                this.httpClient.get(url, httpOptions).pipe(tap(
                    data => resolve(data),
                    error => reject(error)
                )).subscribe();
            });
        } else {
            return new Promise((resolve, reject) => {
                reject({ code: "400", msg: "Token validated", object: { errorCode: "200", errorMsg: "Invalid token" } });
            });
        }
    }

    validateToken(token: string) {
        const url = environment.gateway + 'validateToken?token=' + token;
        const httpOptions = {
            headers: this.getHttpHeadders('application/json')
        };

        return new Promise((resolve, reject) => {
            this.httpClient.post(url, {}, httpOptions).pipe(tap(
                data => resolve(data),
                error => reject(error)
            )).subscribe();
        });
    }
}
