import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { CoSignerItem } from './co-signer-item';
declare var $: any;

@Component({
    selector: 'app-additional-add-co-signer',
    templateUrl: './additional-add-co-signer.component.html',
    styleUrls: ['./additional-add-co-signer.component.css']
})
export class AdditionaAddCoSignerComponent implements OnInit {

    @Input() id = 0;
    @Input() ownerEmail = '';
    @Input() statesIssued: any[] = [];
    @Input() vendors: any[] = [];

    @Output() emailClick = new EventEmitter<void>();
    @Output() reloadSlice = new EventEmitter<void>();

    submitted = false;
    cities: any[] = [];
    states: any[] = [];
    today = new Date();
    maxAgeDate = new Date((new Date()).setFullYear(new Date().getFullYear() - 18));
    production: boolean = environment.production;
    owner: CoSignerItem = new CoSignerItem(this.formBuilder);

    modalId = 'AddCoSignerSuccess' + this.id;
    messageTitle = '';
    messageMessageA = '';
    messageMessageB = '';
    messageMessageC = '';
    messageMessageAClass = '';
    messageMessageBClass = '';
    messageMessageCClass = '';
    dialogClass = '';

    modalIdE = 'AddCoSignerError' + this.id;
    messageTitleE = '';
    messageMessageAE = '';
    messageMessageBE = '';
    messageMessageCE = '';
    messageMessageAClassE = '';
    messageMessageBClassE = '';
    messageMessageCClassE = '';
    dialogClassE = '';
    availablePercent = 0;
    mainModalId = 'additionaAddCoSigner' + this.id;

    private county: string = '';

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        public googleAnalyticsService: GoogleAnalyticsService
    ) { }

    ngOnInit() {
        this.owner.oForm.authorize.setValue(false);
        this.modalId = 'AddCoSignerSuccess' + this.id;
        this.modalIdE = 'AddCoSignerError' + this.id;
        this.mainModalId = 'additionaAddCoSigner' + this.id;
        this.getTotalPercencentage();
    }

    getTotalPercencentage() {
        let totalPer = 0;
        for (const owner of this.vendors) {
            totalPer += owner.ownershipPercentage;
        }
        this.availablePercent = 100 - totalPer;
        this.validPercencentage()
    }

    validPercencentage() {
        this.owner.oForm.ownershipPercentage.setValidators(Validators.max(this.availablePercent));
    }

    onClick() {
        this.googleAnalyticsService.trackEvent('additional-options', 'open', 'add-co-signer');
        $('#additionaAddCoSigner' + this.id).modal();
    }

    onEmailClick() {
        this.emailClick.emit();
    }

    onZipCodeKeyUp(owner: any) {
        this.getZipCodeData(owner.zipCode.value).then(
            (data: any) => {
                if (data.object) {
                    this.cities = data.object.city;
                    this.states = this.statesIssued;
                    owner.state.setValue(data.object.state);
                    owner.city.setValue(data.object.city[0]);
                    data.object.cityCounties.forEach(element => {
                        if (element.city === owner.city.value) {
                            this.county = element.county;
                            return;
                        }
                    });
                } else {
                    this.cities = [];
                }
            },
            (error: any) => {
                this.cities = [];
            }
        );
    }

    getZipCodeData(zipCode: string) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'application/zipData/' + zipCode, 'application/json').subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    onCompleteInformationClick(owner: CoSignerItem) {
        owner.setDefaultData();
        this.onZipCodeKeyUp(owner.oForm);
        this.owner.oForm.authorize.setValue(true);
    }

    onContinueClick() {

        this.submitted = true;
        if (this.owner.form.invalid) {
            return;
        }
        this.addressvalidation().then(
            () => this.addOwner(),
            () => { void (0); }
        );

        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }

    addressvalidation() {
        return new Promise((resolve, reject) => {
            const address = {
                city: this.owner.oForm.city.value,
                county: this.county,
                state: this.owner.oForm.state.value,
                zipCode: this.owner.oForm.zipCode.value,
            };

            this.connectionService.postRequest(
                environment.gateway + 'addressvalidation/', address, 'application/json').subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (error: any) => {
                        this.messageTitleE = 'Sorry, we found a problem';
                        this.messageMessageAE = 'Unfortunately we are unable to process your request at this moment.';
                        this.messageMessageBE = error.error.msg;
                        this.messageMessageCE = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.messageMessageBClassE = 'mB20';
                        this.dialogClassE = 'w600';
                        $('#additionaAddCoSigner').modal('hide');
                        $('#errorModalDialogAddCoSignerError' + this.id).modal();
                        reject(error);
                    }
                );
        });
    }

    addOwner() {
        return new Promise((resolve, reject) => {
            let tOwner = this.owner.encode();
            this.connectionService.postRequest(
                `${environment.gateway}owner/${this.id}`, tOwner, 'application/json').subscribe(
                    (data: any) => {
                        this.googleAnalyticsService.trackEvent('additional-options', 'added', 'add-co-signer');
                        this.messageTitle = 'Owner Added';
                        this.messageMessageA = 'Owner added successfully.';
                        this.messageMessageB = '';
                        this.messageMessageC = '';
                        this.messageMessageBClass = '';
                        this.dialogClass = 'w450';
                        $('#additionaAddCoSigner').modal('hide');
                        $(`#errorModalDialogAddCoSignerSuccess${this.id}`).modal();
                        resolve(data);
                    },
                    (error: any) => {
                        this.messageTitleE = 'Sorry, we found a problem';
                        this.messageMessageAE = 'Unfortunately we are unable to process your request at this moment.';
                        if (error.error.msg.split('Max value').length > 1) {
                            this.messageMessageBE = error.error.msg + ': ' + error.error.object.value + '%';
                        } else {
                            this.messageMessageBE = error.error.msg;
                        }
                        this.messageMessageCE = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.messageMessageBClassE = 'mB20';
                        this.dialogClassE = 'w600';
                        $('#additionaAddCoSigner').modal('hide');
                        $(`#errorModalDialogAddCoSignerError${this.id}`).modal();
                        reject(error);
                    }
                );
        });
    }

    onCloseModal() {
        this.reloadSlice.emit();
        this.ngOnInit();
    }

    onCloseEModal() {
        setTimeout(function () { $('#additionaAddCoSigner').modal(); }, 500);
    }
}
