<button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular double-white"
    (click)="onClick();">
    <div>Payment</div>
    <div>Calculator</div>
</button>
<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="paymentCalculatorModal"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                    <img src="/assets/images/icons/calculator.svg" />
                    Payment Calculator
                </h5>
                <div class="calculator-header font-mont-bold">
                    Application approved for {{approvedAmount | currency:'$ ':'code':'.0-0'}}
                </div>
                <form [formGroup]="calculatorForm" (ngSubmit)="onCalculateClick();">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div>
                                        <div>
                                            Recalculate payment based on finance amount.
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-sm-12">
                                                <input type="text" formControlName="amount" class="form-control"
                                                    id="amount" name="amount"
                                                    [ngClass]="{ 'is-invalid': submitted && calculatorForm.controls.amount.errors }"
                                                    placeholder="$ " prefix="$ " mask="separator.2" thousandSeparator=",">
                                                <div *ngIf="submitted && calculatorForm.controls.amount.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="calculatorForm.controls.amount.errors.required">
                                                        The amount is required.
                                                    </div>
                                                    <div *ngIf="calculatorForm.controls.amount.errors.requestedAmount">
                                                        The requested amount must be greater than $ 500.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit"
                                            class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                                            CALCULATE
                                        </button>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 offer-table">
                                    <div>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="font-mont-semi-bold">TERM</th>
                                                    <th scope="col" class="font-mont-semi-bold">AMOUNT</th>
                                                    <th scope="col" class="font-mont-semi-bold">PAYMENT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let offer of offers.offerList">
                                                    <td scope="row"> {{offer.term}} </td>
                                                    <td> {{ (offer.useMaxApprovedAmount ? offers.maxApproved : offers.requestAmount) | currency:'$ ':'code':'.0-0'}}
                                                    </td>
                                                    <td> {{ offer.payment | currency:'$ ':'code':'.2-2' }} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>