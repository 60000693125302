<div class="row sub-section-title" [ngClass]="titleClass" *ngIf="showCustomerAccionRequired">
  <div class="col-12">
    <img *ngIf="titleClass == 'title-red'" src="/assets/images/icons/red_exclamation.svg" alt="title red" />
    <img *ngIf="titleClass == 'title-gray'" src="/assets/images/icons/gray_exclamation.svg" alt="title gray" />
    <img *ngIf="titleClass == 'title-blue'" src="/assets/images/icons/blue-circle-check.svg" alt="title blue" />
    <span>{{title}}</span>
  </div>
</div>
<div class="row sub-section-content" *ngIf="showCustomerAccionRequired">
  <div class="col-12">
    <div *ngIf="(processStatus.sentence || processStatus.sentence2) && !processStatus.vodStatusProcess.statusBar?.length" class="bullets-margin-bottom">
      <div *ngIf="processStatus.sentence">
        {{processStatus.sentence}}
      </div>
      <div class="font-mont-semi-bold" *ngIf="processStatus.sentence2">
        {{processStatus.sentence2}}
      </div>
    </div>
    <ol class="bullets-margin-bottom">
      <li *ngIf="processStatus.contractStatusProcess.bullet">
        {{processStatus.contractStatusProcess.bullet}}
      </li>
      <li *ngIf="processStatus.vodStatusProcess.bullet">
        {{processStatus.vodStatusProcess.bullet}}
      </li>
      <li *ngIf="processStatus.dlStatusProcess.bullet && !dlStatusUR">
        {{processStatus.dlStatusProcess.bullet}}
      </li>
    </ol>
    <div class="status-bar" *ngIf="processStatus.contractStatusProcess.statusBar.length > 0">
      <div class="title w170" [ngClass]="processStatus.contractStatusProcess.customerRequiredFlag">
        <div class="font-mont-semi-bold">Contract</div>
        <div class="font-mont-semi-bold">Status</div>
      </div>
      <div class="row-bar"
        *ngFor="let step of processStatus.contractStatusProcess.statusBar | sort:'order'; let i = index">

        <div class="box1-row">
          <img *ngIf="step.completed" src="/assets/images/icons/gray_circle_check.svg" alt="gray circle check" />
          <img *ngIf="!step.completed" src="/assets/images/icons/gray_circle_empty.svg" alt="gray circle empty" />
        </div>
        <div class="box2-row" [ngClass]="{ 'disabled': !step.completed }">
          <div [ngClass]="{ 'margin-t-10-b-11': !step.textB }">{{step.textA}}</div>
          <div>{{step.textB}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="status-bar" *ngIf="processStatus.vodStatusProcess.statusBar.length > 0">
      <div class="title w170" [ngClass]="processStatus.vodStatusProcess.customerRequiredFlag">
        <div class="font-mont-semi-bold">{{processStatus.vodStatusProcess.displayName}}</div>
      </div>
      <div class="row-bar" *ngFor="let step of processStatus.vodStatusProcess.statusBar | sort:'order'; let i = index">
        <div class="box1-row">
          <img *ngIf="step.completed" src="/assets/images/icons/gray_circle_check.svg" alt="gray circle check" />
          <img *ngIf="!step.completed" src="/assets/images/icons/gray_circle_empty.svg" alt="gray circle empty" />
        </div>
        <div class="box2-row" [ngClass]="{ 'disabled': !step.completed }">
          <div [ngClass]="{ 'margin-t-10-b-11': !step.textB }">{{step.textA}}</div>
          <div>{{step.textB}}</div>
        </div>
      </div>
    </div> -->
    <div class="status-bar" *ngIf="processStatus.dlStatusProcess.statusBar.length > 0">
      <div class="title w170" [ngClass]="processStatus.dlStatusProcess.customerRequiredFlag">
        <div class="font-mont-semi-bold">Driver's License</div>
        <div class="font-mont-semi-bold">Status</div>
      </div>
      <div class="row-bar" *ngFor="let step of processStatus.dlStatusProcess.statusBar | sort:'order'; let i = index">
        <div class="box1-row">
          <img *ngIf="step.completed" src="/assets/images/icons/gray_circle_check.svg" alt="gray circle check" />
          <img *ngIf="!step.completed" src="/assets/images/icons/gray_circle_empty.svg" alt="gray circle empty" />
        </div>
        <div class="box2-row" [ngClass]="{ 'disabled': !step.completed }">
          <div [ngClass]="{ 'margin-t-10-b-11': !step.textB }">{{step.textA}}</div>
          <div>{{step.textB}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
