<app-header></app-header>
<div class="containerD">
    <div class="mx-auto frame">
        <div class="boxDec">
            <h5 class="tittleDeclined font-mont-regular">Please Upload The Invoice Document</h5>
            <app-invoice-uploader (onFileAdded)="onFileAdded($event);" [elementId]="uploadDocumentId">
            </app-invoice-uploader>
        </div>
        <div class="rounded-pill btn btn-primary btn-lg disabled bth font-mont-regular" (click)="uploadInvoiceFile();">
            DONE
        </div>
    </div>
</div>
<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageAClass]="messageMessageAClass" [messageBClass]="messageMessageBClass"
    [messageCClass]="messageMessageCClass" [dialogClass]="dialogClass" (closeModal)="onCloseModal();">