import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl, FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { CustomFormValidationsService } from 'src/app/shared/validations/customFormValidations.service';
declare var $: any;

interface FormStateOptions {
    status: boolean;
    contrls?: AbstractControl[];
    controlsKeys?: string[];
    formGroup?: FormGroup;
}


@Component({
    selector: 'app-create-send-contract',
    templateUrl: './create-send-contract.component.html',
    styleUrls: ['../summary.component.css', './create-send-contract.component.css', '../ept-ui-elements.css']
})
export class CreateSendContractComponent implements OnInit {

    @Input() statesOfIncorporation: any[] = [];
    @Input() appId: any = 0;
    @Input() defaultOfferId: any = 0;
    @Input() maxApprovedAmount: any = 0;
    @Input() vendorPaused: boolean;

    @Output() reloadSlice = new EventEmitter<void>();

    sendDocsForm: UntypedFormGroup;
    invoiceAddressForm: UntypedFormGroup;

    sendDocsFormSubmitted = false;
    invoiceAddressFormSubmitted = false;

    numberOfAttempts = 0;
    numberOfDocsExceeded = false;

    clickProccess: boolean = false;
    private fldSubs: { [l: string]: Subscription } = {};

    messageTitle = '';
    messageMessageA = '';
    messageMessageB = '';
    messageMessageC = '';
    messageMessageAClass = '';
    messageMessageBClass = '';
    messageMessageCClass = '';
    dialogClass = '';

    modalIdE = 'InvoiceError' + this.appId;
    attempModalId = 'attempModal' + this.appId;
    modalId = 'summarySendDocsAddress' + this.appId;
    sendContractsSuccessId = 'sendContractsSuccess' + this.appId;

    defaultLeaseeAddress = {
        streetAddress: '',
        city: '',
        county: '',
        state: '',
        unit: '',
        zipCode: ''
    };

    invoiceAddresArrays = {
        cities: [],
        states: [],
        counties: []
    };

    private gpsUnitRequired: boolean;
    public currentStep = 1;
    public gpsTrackerRequiredForm: any;

    constructor(
        private authService: AuthService,
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private customFormValidation: CustomFormValidationsService
    ) {
        this.invoiceAddressForm = this.formBuilder.group({
            sameAddres: ['true', Validators.required],
            streetAddress: [{ value: '', disabled: true }, Validators.required],
            unit: [{ value: '', disabled: true }],
            zipCode: [{ value: '', disabled: true }, Validators.required],
            city: [{ value: '', disabled: false }, Validators.required],
            state: [{ value: '', disabled: true }, Validators.required],
            county: [
                { value: '', disabled: false },
                [Validators.required],
                [this.customFormValidation.isCountyInCity({ kZip: 'zipCode', kCounty: 'county', kCity: 'city' })]
            ]
        });
        this.gpsTrackerRequiredForm = new FormGroup({
            gpsTrackerRequired: new FormControl('', Validators.required)
        });

    }
    ngOnInit() {
        this.modalIdE = 'InvoiceError' + this.appId;
        this.attempModalId = 'attempModal' + this.appId;
        this.modalId = 'summarySendDocsAddress' + this.appId;
        this.sendContractsSuccessId = 'sendContractsSuccess' + this.appId;
        this.invoiceAddressForm.get('city').valueChanges.subscribe(res => {
            this.invoiceAddressForm.get('county').updateValueAndValidity();
        });
        this.gpsUnitRequired = this.authService.gpsUnitRequired();


        this.sendDocsForm = this.formBuilder.group({
            invoiceNumber: [{ value: '', disabled: this.isDisabled }, Validators.required,],
            subTotal: [{ value: '', disabled: this.isDisabled }, Validators.required, this.requestedAmount.bind(this)
            ]
        });
    }

    openModal() {
        this.sendDocsFormSubmitted = true;
        if (this.sendDocsForm.invalid) {
            return;
        }
        if (!this.gpsUnitRequired) {
            this.onSendDocsToCustomerSubmit();
        }
        this.gpsTrackerRequiredForm.reset();

        $('#summarySendDocsAddress' + this.appId).modal()
        setTimeout(() => {

            this.clickProccess = false;
        }, 500);
    }

    openBannerModal() {
        $('#mcoBannerModal').modal();
    }


    onSendDocsToCustomerSubmit() {
        if (this.clickProccess) {
            return;
        }
        this.clickProccess = true;
        this.offerInvoiceRecalc().then(
            () => {
                this.getApplication(this.appId).then(
                    () => {
                        this.getInvoice(this.appId).then(
                            () => {
                                this.moveLastStep();
                            }, (error) => {
                                console.log(error);
                                this.clickProccess = false;
                            }
                        )
                    }, (error) => {
                        console.log(error);
                        this.clickProccess = false;
                    }
                )
            }, (error) => {
                console.log(error);
                this.clickProccess = false;
            }
        );
    }

    onNextSaveGPSTrackerRequired() {
        let selectedValue: boolean = this.gpsTrackerRequiredForm.controls['gpsTrackerRequired'].value;
        return new Promise((resolve, reject) => {
            const body = {
                gpsUnitCostRequired: selectedValue
            };

            this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/application/' + this.appId + '/gps/device-needed', body, 'application/json', this.authService.keyCloakToken)
                .subscribe(
                    (data: any) => {
                        resolve(data);
                        this.onSendDocsToCustomerSubmit();
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
        });
    }

    getInvoice(applicationId: number) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'equipment/' + applicationId, 'application/json').subscribe(
                (data: any) => {
                    if (data.object) {
                        data.object.forEach((invoiceItem: any) => {
                            this.invoiceAddressForm.controls.sameAddres.setValue(invoiceItem.lesseeAddress ? 'true' : 'false');
                            this.invoiceAddressForm.controls.streetAddress.setValue(invoiceItem.streetAddress);
                            this.invoiceAddressForm.controls.unit.setValue(invoiceItem.unit);
                            this.invoiceAddressForm.controls.zipCode.setValue(invoiceItem.zipCode);
                            this.invoiceAddressForm.controls.city.setValue(String(invoiceItem.city).toUpperCase());
                            this.invoiceAddressForm.controls.state.setValue(invoiceItem.state);
                            this.invoiceAddressForm.controls.county.setValue(String(invoiceItem.county).toUpperCase());
                            this.changeSameAddress();
                        });
                        if (data.object.length < 1) {
                            this.invoiceAddressForm.controls.sameAddres.setValue('true');
                            this.changeSameAddress();
                        }
                    }
                    resolve(data);
                },
                (error: any) => {
                    resolve(error);
                }
            );
        });
    }

    getInvoiceData(selectedOfferId: number) {
        return {
            equipmentList: [
                {
                    city: this.invoiceAddressForm.controls.city.value,
                    condition: 'true',
                    cost: this.sendDocsForm.controls.subTotal.value,
                    county: this.invoiceAddressForm.controls.county.value,
                    description: '',
                    lesseeAddress: this.invoiceAddressForm.controls.sameAddres.value === 'true',
                    make: 'See invoice: ' + this.sendDocsForm.controls.invoiceNumber.value,
                    model: 'See invoice: ' + this.sendDocsForm.controls.invoiceNumber.value,
                    serialNumber: '',
                    state: this.invoiceAddressForm.controls.state.value,
                    streetAddress: this.invoiceAddressForm.controls.streetAddress.value,
                    supplier: '',
                    unit: this.invoiceAddressForm.controls.unit.value,
                    year: new Date().getFullYear(),
                    zipCode: this.invoiceAddressForm.controls.zipCode.value
                }
            ],
            offerId: selectedOfferId
        };
    }

    changeSameAddress() {
        this.changeFormControlsStatus({ status: true, controlsKeys: ['zipCode', 'city', 'county'], formGroup: this.invoiceAddressForm });
        if (this.invoiceAddressForm.controls.sameAddres.value == 'true') {
            this.invoiceAddressForm.controls.streetAddress.setValue(this.defaultLeaseeAddress.streetAddress);
            this.invoiceAddressForm.controls.unit.setValue(this.defaultLeaseeAddress.unit);
            this.invoiceAddressForm.controls.zipCode.setValue(this.defaultLeaseeAddress.zipCode);
            this.invoiceAddressForm.controls.city.setValue(String(this.defaultLeaseeAddress.city).toUpperCase());
            this.invoiceAddressForm.controls.state.setValue(this.defaultLeaseeAddress.state);
            this.invoiceAddressForm.controls.county.setValue(String(this.defaultLeaseeAddress.county).toUpperCase());
            this.changeFormControlsStatus({
                status: false,
                controlsKeys: ['streetAddress', 'unit', 'zipCode', 'city', 'county'],
                formGroup: this.invoiceAddressForm
            });
            this.onZipCodeKeyUp(
                this.defaultLeaseeAddress.state,
                String(this.defaultLeaseeAddress.city).toUpperCase(),
                String(this.defaultLeaseeAddress.county).toUpperCase()
            );
            this.checkFldValidicity(['zipCode', 'city', 'county'], this.invoiceAddressForm);
        } else {
            this.changeFormControlsStatus({
                status: true,
                contrls: [
                    this.invoiceAddressForm.get('streetAddress'),
                    this.invoiceAddressForm.get('unit'),
                    this.invoiceAddressForm.get('zipCode'),
                    this.invoiceAddressForm.get('city'),
                    this.invoiceAddressForm.get('county'),
                ]
            });
            this.onZipCodeKeyUp();
        }
    }

    /**
    * Enable or disable controls state that are passed
    * 
    * @param {FormStateOptions} pOptions options to determine how to change form controls state based on values passed
    * @param {AbstractControl[]} [pOptions.contrls] options to determine how to change form controls state based on values passed
    */
    changeFormControlsStatus(pOptions: FormStateOptions) {
        const { status, contrls = [], controlsKeys = [], formGroup } = pOptions;
        const changeContrlState = (c: AbstractControl) => status ? c.enable() : c.disable();
        const updateContrlsState = (cArr: AbstractControl[]) => cArr.forEach(c => c && changeContrlState(c));
        if (!contrls.length && formGroup) {
            const mapContrls = controlsKeys.map(k => formGroup.get(k)).filter(c => c);
            updateContrlsState(mapContrls);
        } else updateContrlsState(contrls);
    }

    /**
     * Active fields for the params passed and check their validicity. If same address is true
     * the field remains active until a valid value is enter 
     * 
     * @param {string[]} fldsKeys 
     * @param {FormGroup} formGrp 
     */
    checkFldValidicity(fldsKeys: string[], formGrp: FormGroup) {
        if (this.invoiceAddressForm.get('sameAddres')?.value !== 'true') return;
        this.changeFormControlsStatus({ status: true, controlsKeys: fldsKeys, formGroup: formGrp });
        Object.entries(this.invoiceAddressForm.controls)
            .filter(([ctrlName, ctrl]) => fldsKeys.find(k => k === ctrlName))
            .forEach(([ctrlName, ctrl]) => {
                if (ctrl.status !== 'VALID') {
                    if (this.fldSubs[ctrlName]) return;
                    const sub = ctrl.statusChanges.subscribe(val => {
                        if (this.invoiceAddressForm.get('sameAddres')?.value === 'true' &&
                            !ctrl.errors && val === 'VALID') {
                            ctrl.disable({ emitEvent: false });
                            this.defaultLeaseeAddress[ctrlName] = ctrl.value;
                            sub.unsubscribe();
                            delete this.fldSubs[ctrlName];
                            if (ctrlName === 'county') {
                                this.defaultLeaseeAddress.city = this.invoiceAddressForm.controls.city.value;
                                this.invoiceAddressForm.controls.city.disable();
                            }
                        }
                    });
                    this.fldSubs[ctrlName] = sub;
                } else ctrlName !== 'city' ? ctrl.disable() : fldsKeys.indexOf('county') < 0 && ctrl.disable();
            });
    }

    onZipCodeKeyUp(state?: any, city?: any, county?: any) {
        this.getZipCodeData(this.invoiceAddressForm.controls.zipCode.value).then(
            (data: any) => {
                if (data.object) {
                    this.invoiceAddresArrays.cities = data.object.city;
                    this.invoiceAddresArrays.states = this.statesOfIncorporation;
                    this.invoiceAddresArrays.counties = data.object.county;
                    this.invoiceAddressForm.controls.state.setValue(state ? state : data.object.state);
                    if (data.object.city.length > 0) this.invoiceAddressForm.controls.city.setValue(city ? city : data.object.cityCounties[0].city);
                    if (data.object.county.length > 0) this.invoiceAddressForm.controls.county.setValue(county ? county : data.object.cityCounties[0].county);
                } else {
                    this.invoiceAddresArrays.cities = [];
                    this.invoiceAddresArrays.counties = [];
                }
            },
            (error: any) => {
                this.invoiceAddresArrays.cities = [];
                this.invoiceAddresArrays.counties = [];
            }
        );
    }

    getInvoiceAddress() {
        return [{
            city: this.invoiceAddressForm.controls.city.value,
            county: this.invoiceAddressForm.controls.county.value,
            state: this.invoiceAddressForm.controls.state.value,
            zipCode: this.invoiceAddressForm.controls.zipCode.value
        }];
    }

    getZipCodeData(zipCode: string) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'application/zipData/' + zipCode, 'application/json').subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    offerInvoiceRecalc() {
        return new Promise((resolve, reject) => {
            this.connectionService.postRequest(
                environment.gateway + 'application/' + this.defaultOfferId + '/offerInvoiceRecalc/' + this.authService.getUserId(),
                { newRequestAmount: this.sendDocsForm.controls.subTotal.value }, 'application/json',
                'sp_id=' + this.authService.getSalesPersonId()).subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (error: any) => {
                        resolve(error);
                    }
                );
        });
    }

    getApplication(appId: number) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'application/' + appId, 'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
                    (data: any) => {
                        if (data.object) {
                            this.defaultLeaseeAddress = {
                                streetAddress: data.object.lessee.address,
                                city: data.object.lessee.city,
                                county: data.object.lessee.county,
                                state: data.object.lessee.state,
                                unit: data.object.lessee.unit,
                                zipCode: data.object.lessee.zipCode
                            };
                        }
                        resolve(data);
                    },
                    (error: any) => {
                        resolve(error);
                    }
                );
        });
    }

    onSubmitInvoiceDialog() {
        // let isValid = false;
        if (this.clickProccess) {
            return;
        }
        this.checkFldValidicity(['zipCode', 'city', 'county'], this.invoiceAddressForm);
        this.invoiceAddressFormSubmitted = true;
        const submitState = this.invoiceAddressForm.statusChanges.subscribe(state => {
            if (!this.invoiceAddressForm.errors && state !== 'VALID') {
                if (state === 'INVALID') submitState.unsubscribe();
                return;
            }
            // if (isValid) return;
            // isValid = true;
            this.clickProccess = true;
            this.validateQuantity().then(
                (data: any) => {
                    this.numberOfDocsExceeded = data.object.numberOfDocsExceeded;
                    if (data.object.attemptNumber < 1) {
                        this.openSelectOffersDialogAfterQuantityValidation();
                    } else if (data.object.attemptNumber > 0) {
                        $('#summarySendDocsAddress' + this.appId).modal('hide');
                        this.numberOfAttempts = data.object.attemptNumber;
                        $('#attempModal' + this.appId).modal({ backdrop: 'static', keyboard: false });
                        setTimeout(() => {
                            this.clickProccess = false;
                        }, 500);
                    }
                },
                (error: any) => {
                    this.clickProccess = false;
                    this.messageTitle = 'Sorry, we found a problem';
                    this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
                    this.messageMessageB = error.error.msg;
                    this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                    this.messageMessageBClass = 'mB20';
                    this.dialogClass = 'w600';
                    $('#summarySendDocsAddress' + this.appId).modal('hide');
                    $('#errorModalDialog' + this.modalIdE).modal();
                }
            );
            submitState.unsubscribe();
        });
        (<EventEmitter<any>>this.invoiceAddressForm.statusChanges).emit(this.invoiceAddressForm.status);
    }

    validateQuantity() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'validateQuantity/' + this.appId, 'application/json').subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
        });
    }

    openSelectOffersDialogAfterQuantityValidation() {
        this.multipleAddressValidation().then(
            (addressData: any) => {
                this.sendDocsToCustomer(Number(this.defaultOfferId));
            },
            (addressError: any) => {
                this.clickProccess = false;
                this.messageTitle = 'Sorry, we found a problem';
                this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
                this.messageMessageB = addressError.error.msg;
                this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                this.messageMessageBClass = 'mB20';
                this.dialogClass = 'w600';
                $('#summarySendDocsAddress' + this.appId).modal('hide');
                $('#errorModalDialog' + this.modalIdE).modal();
            }
        );
    }

    multipleAddressValidation() {
        return new Promise((resolve, reject) => {
            this.connectionService.postRequest(
                environment.gateway + 'multipleaddressvalidation/', this.getInvoiceAddress(), 'application/json').subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
        });
    }

    sendDocsToCustomer(selectedOffer: number) {
        return new Promise((resolve, reject) => {
            this.connectionService.postRequest(
                environment.gateway + 'equipment/' + this.appId + '/' + this.authService.getUserId(),
                this.getInvoiceData(selectedOffer), 'application/json').subscribe(
                    (data: any) => {
                        resolve(data);
                        $('#summarySendDocsAddress' + this.appId).modal('hide');
                        $('#sendContractsSuccess' + this.appId).modal();
                    },
                    (error: any) => {
                        this.messageTitle = 'Sorry, we found a problem';
                        this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
                        this.messageMessageB = error.msg;
                        this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.messageMessageBClass = 'mB20';
                        this.dialogClass = 'w600';
                        $('#summarySendDocsAddress' + this.appId).modal('hide');
                        $('#errorModalDialog' + this.modalIdE).modal();
                        reject(error);
                    }
                );
        });
    }

    onCloseModal() {
        this.reloadSlice.emit();
        this.ngOnInit();
    }

    requestedAmount(control: UntypedFormControl) {
        if (control.errors && !control.errors.requestedAmount) {
            return;
        }
        if (control.value < 500) {
            control.setErrors({ requestedAmount: true });
        } else {
            control.setErrors(null);
        }
        return new Promise((resolve, reject) => {
            void (0);
        });
    }

    moveFirstStep() {
        this.currentStep = 1;
        this.clickProccess = false;
    }

    moveLastStep() {
        this.currentStep = 2;
        this.clickProccess = false;
    }

    submitGPSForm() {
        this.onNextSaveGPSTrackerRequired();
    }

    set isDisabled(value: boolean) {
        if (value) {
            this.sendDocsForm.controls['invoiceNumber'].disable();
            this.sendDocsForm.controls['subTotal'].disable();

        }
        else {
            this.sendDocsForm.controls['invoiceNumber'].enable();
            this.sendDocsForm.controls['subTotal'].enable();
        }
    }

    get isDisabled() {
        return this.vendorPaused;
    }
}
