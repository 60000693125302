import { Component, OnDestroy, ElementRef, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ExpiredLoginService } from 'src/app/shared/services/expired-login.service';

@Component({
    selector: 'app-expired-login-dialog',
    templateUrl: './expired-login-dialog.component.html',
    styleUrls: ['./expired-login-dialog.component.css']
})
export class ExpiredLoginDialogComponent implements AfterViewInit, OnDestroy {

    @ViewChild('emailInput', { static: true }) emailInputRef: ElementRef;
    @ViewChild('passwordInput', { static: true }) passwordInputRef: ElementRef;
    @ViewChild('mEmailInput', { static: true }) mEmailInputRef: ElementRef;

    loadingSubscription: Subscription;
    debounceTime = 200;

    animation = Math.floor(Math.random() * 3);

    constructor(private expiredLoginService: ExpiredLoginService,
        private _elmRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
        private googleAnalyticsService: GoogleAnalyticsService) { }

    ngAfterViewInit(): void {
        this.googleAnalyticsService.trackEvent("app-component", "app-expired-login-dialog");
        this._elmRef.nativeElement.style.display = 'none';
        this.loadingSubscription = this.expiredLoginService.loadingStatus.pipe(debounceTime(this.debounceTime)).subscribe(
            (status: boolean) => {
                document.getElementsByTagName('body')[0].style.overflow = status ? 'hidden' : 'initial';
                this._elmRef.nativeElement.style.display = status ? 'block' : 'none';
                this._changeDetectorRef.detectChanges();
            }
        );
    }

    ngOnDestroy(): void {
        this.loadingSubscription.unsubscribe();
    }

    onSignin() {
        this.expiredLoginService.onSuccessFunction(this.emailInputRef.nativeElement.value, this.passwordInputRef.nativeElement.value);
    }
}
