<div class="bank-stage-wrapp d-block px-0 py-3 my-4 font-mont-regular text-dark">
    <p *ngIf="!isBankApproved" class="bank-stage-wrapp__tlt font-mont-regular mb-4">
        <img class="mr-1" src="/assets/images/icons/red_exclamation_2.svg" alt="warning icon">
        {{ actionTlt }}
    </p>
    <p class="font-mont-regular action-description-a">
        {{
        processStatus && processStatus.vodStatusProcess && processStatus.vodStatusProcess.nextStepVerification == 'MOBILE' ?
        'Verification Required. A link has been sent to the customer with information for completion.' :
        'Customer is currently verifying their bank account.'
        }}
    </p>
    <p class="font-mont-bold action-description-b">You cannot send the contract to the customer until these verification steps are complete.</p>
    <p *ngIf="processStatus.sentence" [class.m-0]="isBankApproved"
        [wordHighlight]="{wordsMatch: [getBoldMeg( application.currentStage, actionDesc)], types: ['bold'], styleOpts: {boldClr: '#101112  '}}">
        {{ actionDesc }}
    </p>
    <ol class="mb-5">
        <li *ngFor="let step of steps; let i = index">
            <p *ngIf="!isBankApproved && step.desc">{{ i +1 }}. {{step.desc}}</p>
            <app-ept-status-bar [processStatus]="processStatus"></app-ept-status-bar>
        </li>
    </ol>
    <div class="verification-buttons" *ngIf="processStatus.vodStatusProcess.nextStepVerification">
        <p class="font-mont-bold action-description-b" *ngIf="processStatus.vodStatusProcess.nextStepVerification == 'MOBILE'">Mobile verification required</p>
        <p class="font-mont-bold action-description-b" *ngIf="processStatus.vodStatusProcess.nextStepVerification == 'BANK'">Bank verification required</p>
        <p class="font-mont-regular action-description-a">
            Send a link for the customer to complete their verification.
        </p>
        <button *ngIf="processStatus.vodStatusProcess.buttonFlag == 'EMAIL' || processStatus.vodStatusProcess.buttonFlag == 'BOTH'" class="btn ept-btn ept-btn-primary btn-lg rounded-pill round-button font-mont-semi-bold mb-3"
            (click)="sendBankVerificationEmailLink()">Send an email</button>
        <button *ngIf="processStatus.vodStatusProcess.buttonFlag == 'SMS' || processStatus.vodStatusProcess.buttonFlag == 'BOTH'" class="btn ept-btn ept-btn-primary btn-lg rounded-pill round-button font-mont-semi-bold mb-3"
            (click)="sendBankVerificationSmSLink()">Send a text</button>
    </div>
</div>
<app-ept-modal [isOpen]="isMdlOpen" [theme]="'eptV2'" (onStatusChanges)="changeMdlStatus($event)"
    [ngClass]="['mdl-resend-link']">
    <div class="p-3">
        <h4 [ngStyle]="{'--ept-font-size': '1.25rem'}"
            class="font-mont-bold text-center m-0 p-0 pb-3 ept-custom-font-size">
            {{ mdlBodyTlt }}
        </h4>
        <p [ngStyle]="{'--ept-font-size': '1.125rem'}" [ngClass]="{'mt-2': mdlBodyType === 'msg-err'}"
            class="mdl-desc text-center font-mont-semi-bold ept-custom-font-size">
            {{ mdlBodyDesc }}
        </p>
        <p *ngIf="(mdlBodyType === 'sms') || (mdlBodyType === 'email')" [ngSwitch]="mdlBodyType"
            [ngStyle]="{'--ept-font-size': '1.75rem'}"
            class="phone-number ept-custom-font-size  text-center text-dark m-0 py-3">
            <strong *ngSwitchCase="'sms'" class="font-mont-bold">{{ phoneNumber | mask:'(000) 000-0000' }}</strong>
            <strong *ngSwitchCase="'email'" class="font-mont-bold">{{ ownerEmail }}</strong>
        </p>
        <p [ngStyle]="{'--ept-font-size': '1rem'}"
            [wordHighlight]="{wordsMatch: ['(888) 509-5592'], types: ['bold', 'underline'], styleOpts: {boldClr: '#0889C5'}}"
            class="mdl-support-msg text-center font-mont-regular ept-custom-font-size pt-3">
            {{ supportMsg }}
        </p>
    </div>
</app-ept-modal>