import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateValidator, EmailValidator } from 'src/app/utils/shared';

export interface IBusinessForm {
    businessType: string;
    businessName: string;
    DBA: string;
    businessPhone: number;
    percentOwnership: number;
    businessStartDate: Date;
    termsService: boolean;
    businessEmail: string;
    businessAddress: string;
    zipCode: string;
    city: string;
    state: string;
    county: string;
}

export class BusinessFormItem {
    form: UntypedFormGroup;
    current

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
            businessType: ['', Validators.required],
            businessName: ['', Validators.required],
            DBA: ['', Validators.required],
            percentOwnership: ['', [Validators.required, Validators.max(100), Validators.min(51)]],
            termsService: [false, [Validators.requiredTrue]],
            businessStartDate: ['', [Validators.required, DateValidator()]],
            businessEmail: ['', [Validators.required, Validators.email, EmailValidator()]],
            businessAddress: ['', Validators.required],
            zipCode: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            county: ['', Validators.required],
            businessPhone: ['', Validators.required]
        });
    }

    decode(data: IBusinessForm) {
        this.form.controls.businessName.setValue(data.businessName);
        this.form.controls.DBA.setValue(data.DBA);
        this.form.controls.businessType.setValue(data.businessType);
        this.form.controls.percentOwnership.setValue(data.percentOwnership);
        this.form.controls.businessStartDate.setValue(data.businessStartDate);
        this.form.controls.businessEmail.setValue(data.businessEmail);
        this.form.controls.businessAddress.setValue(data.businessAddress);
        this.form.controls.zipCode.setValue(data.zipCode);
        this.form.controls.city.setValue(data.city);
        this.form.controls.state.setValue(data.state);
        this.form.controls.county.setValue(data.county);
        this.form.controls.termsService.setValue(data.termsService);
        this.form.controls.businessPhone.setValue(data.businessPhone);
    }

    enconde(): IBusinessForm {
        return {
            businessType: this.form.controls.businessType.value,
            businessName: this.form.controls.businessName.value,
            DBA: this.form.controls.DBA.value,
            percentOwnership: this.form.controls.percentOwnership.value,
            businessStartDate: this.form.controls.businessStartDate.value,
            termsService: this.form.controls.termsService.value,
            businessEmail: this.form.controls.businessEmail.value,
            businessAddress: this.form.controls.businessAddress.value,
            zipCode: this.form.controls.zipCode.value,
            city: this.form.controls.city.value,
            state: this.form.controls.state.value,
            county: this.form.controls.county.value,
            businessPhone: this.form.controls.businessPhone.value
        }
    }

    setDefaultData(pOwnershipPercentage?: number) {
        this.decode(
            {
                businessType: 'Corporation',
                businessName: 'JacobTest',
                DBA: 'Doe',
                percentOwnership: pOwnershipPercentage ? pOwnershipPercentage : 100,
                businessStartDate: new Date(2000, 10, 10),
                termsService: false,
                businessEmail: 'business@mail.com',
                businessAddress: '1182 Main St',
                zipCode: '80112',
                city: 'ENGLEWOOD'.toUpperCase(),
                state: 'CO',
                county: '',
                businessPhone: 2001062109
            }
        );
    }

}
