<div class="login-header">
    <img src="/assets/images/click-lease-logo-svg.svg" />
</div>
<div class="login-form-container">
    <div class="login-form">
        <div class="form-header">
            <h3 class="font-mont-bold">RESET PASSWORD</h3>
            <img src="/assets/images/icons/login_user.svg" />
            <div class="clear"></div>
        </div>
        <form [formGroup]="resetForm" (ngSubmit)="onResetPassword()">
            <div class="form-group">
                <label class="font-mont-book" for="password">New Password: *</label>
                <input [ngClass]="{ 'is-invalid': submitted && resetForm.controls.password.errors }" type="password"
                    id="password" name="password" formControlName="password" class="form-control">
                <div *ngIf="submitted && resetForm.controls.password.errors" class="invalid-feedback">
                    <div *ngIf="resetForm.controls.password.errors.required">
                        The password is required.
                    </div>
                </div>
                <div class="regex-error"
                    [ngClass]="{'invalid': resetForm.controls.password.errors && resetForm.controls.password.errors.pattern}">
                    Password must contain:
                    <ul>
                        <li>Between 8 and 30 characters</li>
                        <li>At least 1 lowercase character</li>
                        <li>At least 1 uppercase character</li>
                        <li>At least 1 number</li>
                    </ul>
                </div>
            </div>
            <div class="form-group">
                <label class="font-mont-book" for="repassword">Re-enter Password: *</label>
                <input [ngClass]="{ 'is-invalid': submitted && resetForm.controls.repassword.errors }" type="password"
                    id="repassword" name="repassword" formControlName="repassword" class="form-control">
                <div *ngIf="submitted && resetForm.controls.repassword.errors" class="invalid-feedback">
                    <div *ngIf="resetForm.controls.repassword.errors.required">
                        The password is required.
                    </div>
                    <div *ngIf="resetForm.controls.repassword.errors.mustMatch">
                        Passwords must match
                    </div>
                </div>
            </div>
            <div class="bottom-buttons">
                <button class="btn btn-primary float-right" type="submit">NEXT</button>
            </div>
        </form>
        <div class="footer-links">
        </div>
    </div>
</div>
<div class="login-footer">
    <a routerLink="/support">Support</a>
    <a routerLink="/privacy">Privacy</a>
    <a routerLink="/about">About</a>
    <a routerLink="/terms">Terms</a>
</div>

<!-- Error sending email password modal -->
<div class="modal fade" id="changePasswordErrorModal" role="dialog">
    <div class="modal-dialog small">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title font-mont-bold">New Password</h5>
                <div class="dialog-message">{{ loginErrorMsg }}</div>
                <div class="clear"></div>
                <div class="row text-right">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary dialog-button close-button"
                            data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Email password sent successfully modal -->
<div class="modal fade" id="changePasswordSuccessModal" role="dialog">
    <div class="modal-dialog small">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title font-mont-bold">New Password</h5>
                <div class="dialog-message">New password established successfully.</div>
                <div class="clear"></div>
                <div class="row text-right">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary dialog-button close-button" data-dismiss="modal"
                            (click)="onChangedOk()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>