<div class="row sub-section-title title-red">
    <div class="col-12">
        <img src="/assets/images/icons/red_exclamation.svg" alt="title red" />
        <span>Customer Action Required: Complete Verification Steps</span>
    </div>
</div>
<div class="row sub-section-content">
    <div class="col-12">
        <div class="bullets-margin-bottom">
            <div class="font-mont-semi-bold">
                Waiting for Customer to Complete Bank Verification.
            </div>
            <div class="font-mont-semi-bold">
                If customer needs new Bank Verification link click button below.
            </div>
        </div>
    </div>
</div>