<button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular double-white"
    (click)="onClick();">
    <div>Add</div>
    <div>Co-Signer</div>
</button>
<div class="modal" id="{{mainModalId}}" tabindex="-1" role="dialog" aria-labelledby="additionaAddCoSigner"
    data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w600" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="close b" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                    <img src="/assets/images/icons/addCoSigner.svg" alt="add co-signer" />
                    ADD CO-SIGNER
                    <span type="submit" class="send-co-signer-email-link" data-dismiss="modal"
                        (click)="onEmailClick();">
                        Send Email To Add Co-Signer
                    </span>
                </h5>
                <div class="row" *ngIf="!production">
                    <button class="btn btn-primary btn-lg auto-populate"
                        (click)="onCompleteInformationClick(owner); $event.preventDefault();">
                        <em class="fa fa-check"></em>
                    </button>
                </div>
                <form [formGroup]="owner.form" (ngSubmit)="onContinueClick();">
                    <div class="row">
                        <div class="col-12">
                            <!-- Owner Info -->
                            <div class="owner-info-section clearfix">
                                <div class="form-row">
                                    <div class="form-group col-sm-6">
                                        <label for="firstNameAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.firstName.errors }">First
                                            Name: *</label>
                                        <input type="text" formControlName="firstName" class="form-control"
                                            id="firstNameAdditionalAddCoSigner{{id}}" name="firstName"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.firstName.errors }">
                                        <div *ngIf="submitted && owner.oForm.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="owner.oForm.firstName.errors.required">
                                                First Name is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="lastNameAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.lastName.errors }">Last
                                            Name: *</label>
                                        <input type="text" formControlName="lastName" class="form-control"
                                            id="lastNameAdditionalAddCoSigner{{id}}" name="lastName"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.lastName.errors }">
                                        <div *ngIf="submitted && owner.oForm.lastName.errors" class="invalid-feedback">
                                            <div *ngIf="owner.oForm.lastName.errors.required">
                                                Last Name is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-sm-6">
                                        <label for="birthDateAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.birthDate.errors }">Birth
                                            Date: *</label>
                                        <input type="text" formControlName="birthDate" class="form-control"
                                            id="birthDateAdditionalAddCoSigner{{id}}" name="birthDate"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.birthDate.errors }"
                                            bsDatepicker [maxDate]="maxAgeDate"
                                            [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, containerClass: 'theme-dark-blue' }">
                                        <div *ngIf="submitted && owner.oForm.birthDate.errors" class="invalid-feedback">
                                            <div *ngIf="owner.oForm.birthDate.errors.required">
                                                Birth Date is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="socialSecurityNumberAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.socialSecurityNumber.errors }">Social
                                            Security Number: *</label>
                                        <input type="text" formControlName="socialSecurityNumber" class="form-control"
                                            id="socialSecurityNumberAdditionalAddCoSigner{{id}}"
                                            name="socialSecurityNumber" [showMaskTyped]="true" mask="000-00-0000"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.socialSecurityNumber.errors }">
                                        <div *ngIf="submitted && owner.oForm.socialSecurityNumber.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="owner.oForm.socialSecurityNumber.errors.required">
                                                Social Security Number is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-sm-6">
                                        <label for="mobilePhoneNumberAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.mobilePhoneNumber.errors }">Mobile
                                            Phone Number: *</label>
                                        <input type="text" formControlName="mobilePhoneNumber" class="form-control"
                                            id="mobilePhoneNumberAdditionalAddCoSigner{{id}}" name="mobilePhoneNumber"
                                            [showMaskTyped]="true" mask="(000) 000-0000"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.mobilePhoneNumber.errors }">
                                        <div *ngIf="submitted && owner.oForm.mobilePhoneNumber.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="owner.oForm.mobilePhoneNumber.errors.required">
                                                Mobile Phone Number is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="ownershipPercentageAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.ownershipPercentage.errors }">Ownership
                                            Percentage: *</label>
                                        <input type="text" formControlName="ownershipPercentage" class="form-control"
                                            id="ownershipPercentageAdditionalAddCoSigner{{id}}"
                                            name="ownershipPercentage"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.ownershipPercentage.errors }"
                                            mask="percent" suffix=" %">
                                        <div *ngIf="submitted && owner.oForm.ownershipPercentage.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="owner.oForm.ownershipPercentage.errors.required">
                                                Ownership Percentage is required.
                                            </div>
                                            <div *ngIf="owner.oForm.ownershipPercentage.errors.max">
                                                The Percentage must be less than {{availablePercent}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-sm-6">
                                        <label for="emailAdditionalAddCoSigner{{id}}" class="font-mont-book"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.email.errors }">Email
                                            Address:
                                            *</label>
                                        <input type="email" formControlName="email" class="form-control"
                                            id="coSignerEmail" name="emailAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.email.errors }">
                                        <div *ngIf="submitted && owner.oForm.email.errors" class="invalid-feedback">
                                            <div *ngIf="owner.oForm.email.errors.required">
                                                Email Address is required.
                                            </div>
                                            <div *ngIf="owner.oForm.email.errors.email">
                                                Invalid email address.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Address Info -->
                            <div class="owner-info-section clearfix">
                                <div class="form-row">
                                    <div class="form-group col-sm-6">
                                        <label for="homeAddressAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.homeAddress.errors }">Home
                                            Address: *</label>
                                        <input type="text" formControlName="homeAddress" class="form-control"
                                            id="homeAddressAdditionalAddCoSigner{{id}}" name="homeAddress"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.homeAddress.errors }">
                                        <div *ngIf="submitted && owner.oForm.homeAddress.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="owner.oForm.homeAddress.errors.required">
                                                Home Address is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="zipCodeAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.zipCode.errors }">Zip
                                            Code: *</label>
                                        <input type="text" formControlName="zipCode" class="form-control"
                                            id="zipCodeAdditionalAddCoSigner{{id}}" name="zipCode"
                                            (keyup)="onZipCodeKeyUp(owner.oForm)"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.zipCode.errors }">
                                        <div *ngIf="submitted && owner.oForm.zipCode.errors" class="invalid-feedback">
                                            <div *ngIf="owner.oForm.zipCode.errors.required">
                                                Zip Code is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-sm-6">
                                        <label for="cityAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.city.errors }">City:
                                            *</label>
                                        <select formControlName="city" id="cityAdditionalAddCoSigner{{id}}"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.city.errors }">
                                            <option *ngFor="let city of cities" [ngValue]="city">{{city}}</option>
                                        </select>
                                        <div *ngIf="submitted && owner.oForm.city.errors" class="invalid-feedback">
                                            <div *ngIf="owner.oForm.city.errors.required">
                                                City is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="stateAdditionalAddCoSigner{{id}}"
                                            [ngClass]="{ 'color-red': submitted && owner.oForm.state.errors }">State:
                                            *</label>
                                        <select formControlName="state" id="stateAdditionalAddCoSigner{{id}}"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && owner.oForm.state.errors }"
                                            [attr.disabled]="true" [attr.disabled]="true">
                                            <option value="" selected="selected"></option>
                                            <option *ngFor="let state of states" [ngValue]="state.code">
                                                {{state.name}}</option>
                                        </select>
                                        <div *ngIf="submitted && owner.oForm.state.errors" class="invalid-feedback">
                                            <div *ngIf="owner.oForm.state.errors.required">
                                                State is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="form-row">
                                    <div class="form-group col-sm-12 autorize">
                                        <div>
                                            <input type="checkbox" id="creditAuthAddCoSigner{{id}}"
                                                [formControl]="owner.oForm.creditAuth"
                                                name="creditAuthAddCoSigner{{id}}">
                                            <div>
                                                By clicking this box I confirm that the Co-Applicant has been provided the following disclosure and authorized Clicklease to proceed with their Lease Application. 
                                                “I acknowledge my consent to the following: I authorize ClickLease LLC, and its assigns: 
                                                (1) to obtain my personal credit report, 
                                                (2) I release all credit and other information ClickLease LLC may request, 
                                                (3) I confirm that I provide my credit consent, and 
                                                (4) I allow the use of my personal consumer credit report to confirm my identity to avoid fraudulent transactions in my name.”
                                            </div>
                                        </div>
                                        <div>
                                            <input type="checkbox" id="authorizeAdditionalAddCoSigner{{id}}"
                                                [formControl]="owner.oForm.creditAuthTwo"
                                                name="authorizeAdditionalAddCoSigner{{id}}">
                                            <div>
                                                By clicking this box, I consent to receiving marketing communications
                                                from ClickLease at the telephone numbers I provided herein, which may be
                                                delivered by prerecorded message or autodialed telephone calls or text
                                                messages. I understand that my consent is not a condition of doing
                                                business with, or making purchase of any goods/services from,
                                                ClickLease. For text messages, message and data rates may apply. I
                                                understand I may opt-out of any telemarketing calls or text messages at
                                                any time by communicating my opt-out request during the call, emailing
                                                my opt-out request to ClickLease at <a
                                                    href="mailto:CustomerCare@ClickLease.com">
                                                    CustomerCare@ClickLease.com </a> with the subject line "Opt out" or
                                                texting STOP in response to the text message.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" [disabled]="!owner.oForm.creditAuth.value"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                                ADD CO-SIGNER
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageBClass]="messageMessageBClass" [dialogClass]="dialogClass"
    (closeModal)="onCloseModal();"></app-error-dialog>
<app-error-dialog [modalId]="modalIdE" [title]="messageTitleE" [messageA]="messageMessageAE"
    [messageB]="messageMessageBE" [messageC]="messageMessageCE" [messageBClass]="messageMessageBClassE"
    [dialogClass]="dialogClassE" (closeModal)="onCloseEModal();"></app-error-dialog>
