import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
    selector: 'app-header-send-application-dialog',
    templateUrl: './header-send-application-dialog.component.html',
    styleUrls: ['./header-send-application-dialog.component.css']
})
export class HeaderSendApplicationDialogComponent implements OnInit {

    public sendForm: UntypedFormGroup;
    public sendFormText: UntypedFormGroup;

    public submitted = false;
    public submittedText = false;
    public errorTitle = '';
    public errorMessageA = '';
    public errorMessageB = '';
    public errorMessageC = '';
    public errorMessageBClass = '';
    public dialogClass = '';

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.sendForm = this.formBuilder.group({ email: ['', [Validators.required, Validators.email]] });
        this.sendFormText = this.formBuilder.group({ text: ['', Validators.required] });
    }

    ngOnInit() {
    }

    sendApp() {
        this.submitted = true;
        if (this.sendForm.invalid) {
            return;
        }

        const promise = new Promise((resolve, reject) => {
            const postData = {
                ownerEmail: this.sendForm.controls.email.value,
                salesPersonId: this.authService.getSalesPersonId(),
                userEmail: this.authService.getEmail()
            };
            this.connectionService.postRequest(environment.gateway + 'applicationToken', postData, 'application/json').subscribe(
                (data: any) => {
                    this.errorTitle = 'Application sent.';
                    this.errorMessageA = 'Your application was successfully sent by email.';
                    this.errorMessageB = '';
                    this.errorMessageC = '';
                    this.errorMessageBClass = '';
                    this.dialogClass = 'w450';
                    $('#headerSendApplicationModal').modal('hide');
                    $('#errorModalDialogSendApplicationCompleted').modal();
                    this.sendForm = this.formBuilder.group({ email: ['', [Validators.required, Validators.email]] });
                    this.submitted = false;
                    resolve(data);
                },
                (error: any) => {
                    errorFn(error);
                }
            );

            const errorFn = function (error: any) {
                this.errorTitle = 'Sorry, we found a problem';
                this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                this.errorMessageB = error.error.msg;
                this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                this.errorMessageBClass = 'mB20';
                this.dialogClass = 'w600';
                $('#headerSendApplicationModal').modal('hide');
                $('#errorModalDialogSendApplicationCompleted').modal();
                this.sendForm = this.formBuilder.group({ email: ['', [Validators.required, Validators.email]] });
                this.submitted = false;
                reject(error);
            }.bind(this);
        });
        return promise;
    }

    sendAppByText() {
        this.submittedText = true;
        if (this.sendFormText.invalid) {
            return;
        }

        const promise = new Promise((resolve, reject) => {
            const postData = {
                ownerEmail: this.sendFormText.controls.text.value,
                salesPersonId: this.authService.getSalesPersonId(),
                userEmail: this.authService.getEmail()
            };
            this.connectionService.postRequest(environment.gateway + 'applicationToken/sms/', postData, 'application/json').subscribe(
                (data: any) => {
                    this.errorTitle = 'Application sent';
                    this.errorMessageA = 'Your application was successfully sent by text message.';
                    this.errorMessageB = '';
                    this.errorMessageC = '';
                    this.errorMessageBClass = '';
                    this.dialogClass = 'w450';
                    $('#headerSendApplicationTextModal').modal('hide');
                    $('#errorModalDialogSendApplicationCompleted').modal();
                    this.sendFormText = this.formBuilder.group({ text: ['', Validators.required] });
                    this.submittedText = false;
                    resolve(data);
                },
                (error: any) => {
                    errorFn(error);
                }
            );

            const errorFn = function (error: any) {
                this.errorTitle = 'Sorry, we found a problem';
                this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                this.errorMessageB = error.error.msg;
                this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                this.errorMessageBClass = 'mB20';
                this.dialogClass = 'w600';
                $('#headerSendApplicationTextModal').modal('hide');
                $('#errorModalDialogSendApplicationCompleted').modal();
                this.sendFormText = this.formBuilder.group({ text: ['', Validators.required] });
                this.submittedText = false;
                reject(error);
            }.bind(this);
        });
        return promise;
    }

    couldBeRequired(controlNames: string[]) {
        return (formGroup: UntypedFormGroup) => {
            controlNames.forEach(controlName => {
                const control = formGroup.controls[controlName];
                if (controlName == formGroup.controls['radio'].value && control.value == '') {
                    control.setErrors({ required: true });
                } if (controlName != formGroup.controls['radio'].value) {
                    control.setErrors(null);
                }
            });
        };
    }

}
