import { HttpParams } from '@angular/common/http';

export interface Data {
    name: string;
    code: number
}

export interface RequestsParams {
    url: string
    blobType?: boolean
    access_token?: string
    contentType?: string
    params?: string
    postData?: any
    token?: string
    tokenType?: string
    vendor_token?: string
}

export interface RequestResponse<T = any> {
    code: string
    msg: string
    object: T
}

interface InterceptorMetadata {
    readonly options: any;
}

interface MyHttpParamsConfig {
    interceptorMetadata: Partial<InterceptorMetadata>;
    params: string | Record<string, string | string[]>; // the actual params which will be included in the real request
}

export class MyHttpParams extends HttpParams {
    public readonly interceptorMetadata: Partial<InterceptorMetadata>;

    constructor({ params, interceptorMetadata }: Partial<MyHttpParamsConfig> = {}) {
        if (typeof params === 'string') {
            super({ fromString: params });
        }
        else if (typeof params === 'object') {
            super({ fromObject: params });
        }
        else {
            super();
        }

        this.interceptorMetadata = interceptorMetadata;
    }

    // orverrides HttpParams.append
    append(param: string, value: string): MyHttpParams {
        const updatedHttpParams = super.append(param, value);
        return new MyHttpParams({
            interceptorMetadata: this.interceptorMetadata,
            params: updatedHttpParams.toString()
        });
    }

    // orverrides HttpParams.set
    set(param: string, value: string): MyHttpParams {
        const updatedHttpParams = super.set(param, value);
        return new MyHttpParams({
            interceptorMetadata: this.interceptorMetadata,
            params: updatedHttpParams.toString()
        });
    }

    // orverrides HttpParams.delete
    delete(param: string, value?: string): MyHttpParams {
        const updatedHttpParams = super.delete(param, value);
        return new MyHttpParams({
            interceptorMetadata: this.interceptorMetadata,
            params: updatedHttpParams.toString()
        });
    }
}