import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { BankProcessService } from 'src/app/bank-process';

@Component({
    selector: 'app-finicity-declined',
    templateUrl: './finicity-declined.component.html',
    styleUrls: ['./finicity-declined.component.css']
})
export class FinicityDeclinedComponent implements OnInit {
    errorData = '';

    constructor(
        private router: Router,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private bankProcessService: BankProcessService,
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state && navigation.extras.state.endpoint) {
            this.errorData = navigation.extras.state.endpoint;
        }
    }

    get errorCode() {
        return this.errorListService.errorData
    }

    get isClickSign() {
        return this.bankProcessService.isClicksing;
    }

    ngOnInit(): void {
        if (this.isClickSign) {
            this.googleAnalyticsService.trackClickSignEvent(
                'finicity-error',
                this.bankProcessService.vendorReferrerName,
                this.errorData,
                this.errorCode
            );
        } else this.googleAnalyticsService.trackV3Event(
            'finicity-error',
            this.bankProcessService.vendorReferrerName,
            this.errorData,
            this.errorCode
        );
    }
}
