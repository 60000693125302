import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from '../../shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ArraySortPipe } from '../../shared/pipes/array.sort.pipe';
import { ArrayFilterPipe } from '../../shared/pipes/array.filter.pipe';
import { DataRobotItem } from './data-robot.item';
declare var $: any;

@Component({
    selector: 'app-data-robot-tool',
    templateUrl: './data-robot-tool.component.html',
    styleUrls: ['./data-robot-tool.component.css']
})
export class DataRobotToolComponent implements OnInit, OnDestroy {

    dataRobotModelId = '';
    operators: any[] = [];

    dataRobotModels: DataRobotItem[] = [];

    newDataRobotModelForm: UntypedFormGroup;
    dataRobotModelForm: UntypedFormGroup;
    scheduleBacklogForm: UntypedFormGroup;
    addNewDataRobotModelSubmitted = false;
    scheduleBacklogSubmitted = false;

    selectedRow: number = -1;
    tempSelectedRow: number = -1;

    progressBarInfo: {
        total: number,
        completed: number,
        status: string,
        startDate: string,
        endDate: string,
        dataRobotModelId: number
    };

    modalId = 'ErrorsModal';
    messageTitle = '';
    messageMessageA = '';
    messageMessageB = '';
    messageMessageC = '';
    messageMessageBClass = '';
    dialogClass = '';

    testModelFilterOpen: boolean = false;

    filter: string = '';
    filterValue: string = '';

    sortField: string = '';
    invertedSort: boolean = false;

    updateInterval: any;

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private arrayFilterPipe: ArrayFilterPipe,
        private arraySortPipe: ArraySortPipe
    ) {
        this.dataRobotModelForm = this.formBuilder.group({ dataRobotModel: [''] });
        this.newDataRobotModelForm = this.formBuilder.group({
            description: ['', Validators.required],
            modelAffix: ['', Validators.required]
        });
        this.scheduleBacklogForm = this.formBuilder.group({
            dataRobotModelSelect: ['', Validators.required],
            scheduleStartDate: ['', Validators.required],
            scheduleEndDate: [''],
            submitButton: new UntypedFormControl()
        });
        this.progressBarInfo = {
            total: 0,
            completed: 0,
            status: 'Open',
            startDate: '01-01-2016',
            endDate: '',
            dataRobotModelId: 3
        };
        this.controlsToggle();

        this.updateInterval = setInterval(() => {
            if (this.progressBarInfo.status != 'Open') {
                this.checkScheduledBacklogStatus();
            }
        }, 30000);
    }

    controlsToggle() {
        if (this.progressBarInfo.status == 'Open') {
            this.scheduleBacklogForm.controls.dataRobotModelSelect.enable();
            this.scheduleBacklogForm.controls.scheduleStartDate.enable();
            this.scheduleBacklogForm.controls.scheduleEndDate.enable();
        } else {
            this.scheduleBacklogForm.controls.dataRobotModelSelect.disable();
            this.scheduleBacklogForm.controls.scheduleStartDate.disable();
            this.scheduleBacklogForm.controls.scheduleEndDate.disable();
        }
    }

    ngOnInit() {
        this.getDataRobotModelList();
    }

    ngOnDestroy() {
        clearInterval(this.updateInterval);
    }

    onCreateNewDataRobotModelSubmit() {
        this.addNewDataRobotModelSubmitted = false;
        this.newDataRobotModelForm.controls.description.setValue('');
        this.newDataRobotModelForm.controls.modelAffix.setValue('');
        $('#addNewDataRobotModelModal').modal();
    }

    onCreateSubmit() {
        this.addNewDataRobotModelSubmitted = true;
        if (this.newDataRobotModelForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            const postData: any = {
                useDeploymentId: false,
                description: this.newDataRobotModelForm.controls.description.value,
                modelAffix: this.newDataRobotModelForm.controls.modelAffix.value,
                testModel: true,
                byOwner: false
            };
            this.connectionService.postRequest(`${environment.gateway}datarobotmodel`, postData, 'application/json').subscribe(
                (data: any) => {
                    $('#addNewDataRobotModelModal').modal('hide');
                    this.showSuccessMessage("New DataRobot Model Created!");
                    this.dataRobotModelId = data.object.id;
                    this.getDataRobotModelList(this.dataRobotModelId).then(
                        () => this.dataRobotModelForm.controls.dataRobotModel.setValue(this.dataRobotModelId),
                        () => this.dataRobotModelForm.controls.dataRobotModel.setValue(this.dataRobotModelId)
                    );
                    resolve(data);
                },
                (error: any) => {
                    $('#addNewDataRobotModelModal').modal('hide');
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getDataRobotModelList(dataRobotModelId?: any) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}datarobotmodel/list`, 'application/json').subscribe(
                (data: any) => {
                    let result = [];
                    data.object.forEach(element => {
                        let lDataRobotModel = new DataRobotItem(element);
                        result.push(lDataRobotModel);
                    });
                    this.dataRobotModels = result;
                    let filteredAndSortedResult = this.arrayFilterPipe.transform(result, this.filter, this.filterValue);
                    filteredAndSortedResult = this.arraySortPipe.transform(filteredAndSortedResult, this.sortField, this.invertedSort);

                    if (dataRobotModelId) {
                        for (let index = 0; index < filteredAndSortedResult.length; index++) {
                            if (filteredAndSortedResult[index].id == dataRobotModelId) {
                                this.tempSelectedRow = index;
                            }
                        }
                    }
                    this.checkScheduledBacklogStatus();
                    this.patchProgressValues();
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    toggleSelectedRow(rowId: number, appId: number) {
        this.dataRobotModelId = String(appId);
        if (this.tempSelectedRow == rowId) {
            this.selectedRow = -1;
            this.tempSelectedRow = -1;
        } else {
            this.tempSelectedRow = rowId;
        }
    }

    onItemLoaded() {
        setTimeout(() => {
            this.selectedRow = this.tempSelectedRow;
            setTimeout(() => {
                let el = document.getElementById('itemNumber_' + this.selectedRow);
                let pos = el.getBoundingClientRect().top + window.pageYOffset - 95;
                window.scrollTo({ top: pos, behavior: 'smooth' });
            }, 250);
        }, 250);
    }

    toggleTestModelFilter() {
        this.testModelFilterOpen = !this.testModelFilterOpen;
    }

    onStatusCheckClick(field: any, value: any) {
        this.filter = field;
        this.filterValue = value;
        this.selectedRow = -1;
        this.tempSelectedRow = -1;
    }

    onSortClick(field: any) {
        this.selectedRow = -1;
        this.tempSelectedRow = -1;
        if (this.sortField == field) {
            this.invertedSort = !this.invertedSort;
        } else {
            this.invertedSort = false;
            this.sortField = field;
        }
    }

    onScheduledBacklogSubmit() {
        this.scheduleBacklogSubmitted = true;
        if (this.scheduleBacklogForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            const postData: any = {
                dataRobotModelId: this.scheduleBacklogForm.controls.dataRobotModelSelect.value.id,
                startDate: this.getFormatedDate(this.scheduleBacklogForm.controls.scheduleStartDate.value),
                endDate: this.getFormatedDate(this.scheduleBacklogForm.controls.scheduleEndDate.value)
            };
            this.connectionService.postRequest(`${environment.gateway}/datarobotmodel/modelBacklog`, postData, 'application/json').subscribe(
                (data: any) => {
                    this.showSuccessMessage("Backlog scheduled!");
                    console.log('onScheduledBacklogSubmit');
                    this.loadProgressInfo(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    checkScheduledBacklogStatus() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}/datarobotmodel/modelBacklogStatus`, 'application/json').subscribe(
                (data: any) => {
                    console.log('checkScheduledBacklogStatus');
                    this.loadProgressInfo(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    cancelScheduledBacklog() {
        return new Promise((resolve, reject) => {
            this.connectionService.postRequest(`${environment.gateway}/datarobotmodel/cancelModelBacklog`, {}, 'application/json').subscribe(
                (data: any) => {
                    this.loadProgressInfo(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    private showErrorMessage(pError: string) {
        this.messageTitle = 'Sorry, we found a problem';
        this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
        this.messageMessageB = pError;
        this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
        this.messageMessageBClass = 'mB20';
        this.dialogClass = 'w600';
        $('#errorModalDialog' + this.modalId).modal();
    }

    private showSuccessMessage(pMessage: string) {
        this.messageTitle = 'Success!!';
        this.messageMessageA = pMessage;
        this.messageMessageB = '';
        this.messageMessageC = '';
        this.messageMessageBClass = '';
        this.dialogClass = 'w450';
        $('#errorModalDialog' + this.modalId).modal();
    }

    private loadProgressInfo(resolve: (value: unknown) => void, data: any) {
        this.progressBarInfo = data.object;
        this.patchProgressValues();
        this.controlsToggle();
        resolve(data);
    }

    private patchProgressValues() {
        var startDate = new Date(this.progressBarInfo.startDate);
        startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000);
        var endDate = new Date(this.progressBarInfo.endDate);
        endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000);
        console.log(this.progressBarInfo);
        this.scheduleBacklogForm.controls.scheduleStartDate.patchValue(startDate);
        this.scheduleBacklogForm.controls.scheduleEndDate.patchValue(endDate);
        for (var i = this.dataRobotModels.length - 1; i >= 0; i--) {
            if (this.dataRobotModels[i].id === this.progressBarInfo.dataRobotModelId) {
                this.scheduleBacklogForm.controls.dataRobotModelSelect.patchValue(this.dataRobotModels[i]);
                break;
            }
        }
    }

    private resolveError(reject: (reason?: any) => void, error: any) {
        this.showErrorMessage(error.error.msg);
        reject(error);
    }

    private getFormatedDate(pDate: any): string {
        var date = new Date(pDate);
        if (date instanceof Date && !isNaN(date.getTime())) {
            const year = date.getFullYear();
            const month = date.getMonth() > 8 ? '-' + (date.getMonth() + 1) : '-0' + (date.getMonth() + 1);
            const day = date.getDate() > 9 ? '-' + date.getDate() : '-0' + date.getDate();
            return year + month + day;
        } else {
            return null;
        }
    }
}