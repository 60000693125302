<div class="error-content">
    <div>
        <div class="img">
            <img src="/assets/images/icons/application-errors/clicklease-logo.svg" class="clicklease-logo" />
        </div>
    </div>
    <div class="loadingio-spinner-double-ring-yh97i1p4cv">
        <div class="ldio-ygni1657hwb">
            <div></div>
            <div></div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
        </div>
    </div>
    <div class="font-mont-bold text">We are verifying your<br />information</div>
    <!--<img src="/assets/images/icons/application-errors/clicklease-logo.svg" class="clicklease-logo" />-->
</div>