import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectAccessGuardGuard } from '../auth/direct-access-guard.guard';

//bank process
import { ConnectAccountComponent } from './connect-account/connect-account.component';
//import { DeniedBVComponent } from './denied-bv/denied-bv.component';
import { GiactErrorComponent } from './giact-error/giact-error.component';
import { FinicityProcessComponent } from './finicity-process/finicity-process.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
//import { PreApprovalComponent } from './pre-approval/pre-approval.component';


const routes: Routes = [
  { path: 'bank-information', component: ConnectAccountComponent, canActivate: [DirectAccessGuardGuard] },
  { path: 'bank-verification', component: FinicityProcessComponent, canActivate: [DirectAccessGuardGuard] },
  { path: 'giact-error', component: GiactErrorComponent, canActivate: [DirectAccessGuardGuard] },

  { path: 'bank-selection', component: BankAccountComponent, canActivate: [DirectAccessGuardGuard] },

  //remove this preapproval page and change the navigates to this page to navigate directly to finicity, bank information or approval.
  //{ path: 'pre-approval', component: PreApprovalComponent/*, canActivate: [DirectAccessGuardGuard]*/ }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class BankProcessRoutingModule { }
