<button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular double-white"
    (click)="onClick();">
    <div>Re-Assign</div>
    <div>Salesperson</div>
</button>
<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="changeSalespersonModal"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                    <img src="/assets/images/icons/calculator.svg" />
                    Re-Assign Salesperson
                </h5>
                <form [formGroup]="changeSalespersonForm" (ngSubmit)="onSubmit();">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-container">
                                        <div class="form-title">
                                            Select the salesperson to re-assign application.
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-12">
                                                <input type="text" formControlName="salesperson" class="form-control" id="salesperson"
                                                    name="salesperson" (keyup)="onSalespersonNameKeyUp(0, 8)"
                                                    placeholder="Search Salesperson Name">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-12">
                                                <table>
                                                    <tbody>
                                                        <tr *ngFor="let salesperson of salespeopleData">
                                                            <td (click)="onSalespersonClick(salesperson);"
                                                                [ngClass]="{'selected':salespersonSelected.id == salesperson.id}">
                                                                {{salesperson.name}}</td>
                                                        </tr>
                                                    </tbody> 
                                                    <div class="notifySalesPerson">
                                                        <p>Would you like to notify the salesperson that this application has been transferred to their name?</p>
                                                          <div class="optionSalesPerson">
                                                                <div class="leftCheck">
                                                                    <input class="opt"  type="radio" id="selectChoice1"
                                                                    name="select" value="Yes" (click)="selectNotifyPerson(true);" checked >
                                                                    <label for="selectChoice1">YES</label>
                                                                </div>
                                                                <div class="rightCheck">
                                                                    <input class="opt" type="radio" id="selectChoice2"
                                                                    name="select" value="no"  (click)="selectNotifyPerson(false);">
                                                                    <label for="selectChoice2">NO</label>
                                                                </div>
                                                          </div>
                                                    </div>
                                                </table>
                                                <ul class="pagination justify-content-end" *ngIf="sTotalPages > 1">
                                                    <li class="page-item" [ngClass]="{'disabled': sSliceNumber == 0}"
                                                        (click)="onSBackClick();">
                                                        <a class="page-link prev" tabindex="-1"><img
                                                                src="/assets/images/icons/prev.svg" /></a>
                                                    </li>
                                                    <li class="page-item" *ngFor="let slice of sSlices"
                                                        [ngClass]="{'active': sSliceNumber + 1 == slice}"
                                                        (click)="onSSliceClick(slice - 1);">
                                                        <a class="page-link"
                                                            *ngIf="slice - 1 > sSliceNumber - 5 && sSliceNumber + 5 > slice - 1">{{slice}}</a>
                                                    </li>
                                                    <li class="page-item"
                                                        [ngClass]="{'disabled': sSliceNumber == sTotalPages - 1}"
                                                        (click)="onSNextClick();">
                                                        <a class="page-link next"><img
                                                                src="/assets/images/icons/next.svg" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <button type="submit" [disabled]="!salespersonSelected.id"
                                            class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right">
                                            RE-ASSIGN SALESPERSON
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="modalIdE" [title]="errorTitle" [messageA]="errorMessageA" [messageB]="errorMessageB"
    [messageC]="errorMessageC" [messageBClass]="errorMessageBClass" [dialogClass]="dialogClass">
</app-error-dialog>