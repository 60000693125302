<div>
    <button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular double-white"
        (click)="onClick();">
        <div>Replicate App.</div>
        <div>w/ New Vendor</div>
    </button>
    <div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="reSubmitModal"
        data-keyboard="false" data-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" (click)="closeWindow()" aria-label="Close">
                        <img src="/assets/images/icons/close_b.svg" />
                        CLOSE WINDOW
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                        Replicate app with new vendor
                    </h5>

                    <div class="container repAppContainer" *ngIf="currentStep === 1">
                        <div class="row">
                            <div class="col-6">
                                <label class="font-mont-bold labelTitle">Account Name:</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label
                                    class="font-mont-semi-bold labelSelectedTitle">{{getSelectedVendorText()}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="body-container">
                                <div class="row center">
                                    <div class="input-icons">
                                        <i class="fa fa-search icon"></i>
                                        <input type="text" class="font-mont-regular searchTextbox"
                                            [(ngModel)]="searchVendorText"
                                            placeholder="Search by account, DBA, or vendor ID" name="searchVendorText"
                                            (keyup)="onVendorNameKeyUp(0, 5)">
                                    </div>
                                </div>
                                <hr>
                                <div class="tableContainer">
                                    <div class="row center">
                                        <table class="table table-hover table-borderless">
                                            <thead>
                                                <tr class="heading">
                                                    <th class="text-left font-mont-bold">Account Name</th>
                                                    <th class="font-mont-semi-bold">DBA Name</th>
                                                    <th class="text-left font-mont-semi-bold">Vendor ID</th>
                                                </tr>
                                            </thead>

                                            <tbody *ngIf="searchVendorText.trim()">
                                                <tr [ngClass]="{'selected-row': vendor.id == selectedVendor.id, 
                                                                'row-disabled': vendor.salesPersonCount === 0,
                                                                'cellWithComment' : true                                                                
                                                                }" *ngFor="let vendor of vendorData.records"
                                                    (click)="onVendorClick(vendor);">
                                                    <td [ngClass]="{'font-mont-bold': vendor.id == selectedVendor.id}">
                                                        {{vendor.accountName}}
                                                        <span *ngIf="vendor.salesPersonCount === 0"
                                                            class="cellComment">This account does not have a sales
                                                            person associated</span>
                                                    </td>
                                                    <td style="border-top: none;"
                                                        [ngClass]="{'font-mont-bold': vendor.id == selectedVendor.id}">
                                                        {{vendor.name}}
                                                    </td>
                                                    <td [ngClass]="{'font-mont-bold': vendor.id == selectedVendor.id}">
                                                        {{vendor.id}}
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                    <nav aria-label="Page navigation example"
                                        *ngIf="searchVendorText.trim() && vTotalPages > 1">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item" [ngClass]="{'disabled': vSliceNumber == 0}"
                                                (click)="onVBackClick();">
                                                <a class="page-link prev" tabindex="-1"><img
                                                        src="/assets/images/icons/prev.svg" /></a>
                                            </li>
                                            <li class="page-item" *ngFor="let slice of vSlices"
                                                [ngClass]="{'active': vSliceNumber + 1 == slice}"
                                                (click)="onVSliceClick(slice - 1);">
                                                <a class="page-link"
                                                    *ngIf="slice - 1 > vSliceNumber - 5 && vSliceNumber + 5 > slice - 1">{{slice}}</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{'disabled': vSliceNumber == vTotalPages - 1}"
                                                (click)="onVNextClick();">
                                                <a class="page-link next"><img
                                                        src="/assets/images/icons/next.svg" /></a>
                                            </li>
                                        </ul>
                                    </nav>

                                    <div class="row mt-2" *ngIf="vNumberOfElements === 0 && searchVendorText === ''">
                                        <div class="center">
                                            <p class="font-mont-regular searchLegend">Start by searching for an account,
                                                DBA, or vendor ID</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="row center">
                                    <div class="col-12">
                                        <button class="btn btn-primary font-mont-regular continueButton"
                                            [ngClass]="{'disabled': !selectedVendor.name }" type="button"
                                            [disabled]="!selectedVendor.name" (click)="onContinueClick();">
                                            Continue
                                        </button>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary font-mont-regular cancelButton"
                                            (click)="onCancelClick()">Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container repAppContainer" *ngIf="currentStep === 2">
                        <div class="row">
                            <div class="col-6">
                                <label class="font-mont-bold labelTitle">Account Name:</label>
                            </div>
                            <div class="col-6">
                                <label class="font-mont-bold labelTitle">Account Sales Person:</label>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label class="font-mont-bold labelTitle">{{selectedVendor.accountName}}</label>
                            </div>
                            <div class="col-6">
                                <label class="font-mont-semi-bold labelSelectedTitle">{{getSalesPersonText()}}</label>
                            </div>

                        </div>

                        <div class="row">
                            <div class="body-container">
                                <div class="row center">
                                    <div class="input-icons">
                                        <i class="fa fa-search icon"></i>
                                        <input type="text" class="font-mont-regular searchTextbox"
                                            [(ngModel)]="searchSalesPersonText"
                                            placeholder="Search by sales person name" name="searchSalesPersonText"
                                            (keyup)="onSalesPersonNameKeyUp(0, 5)">
                                    </div>
                                </div>
                                <hr>
                                <div class="tableContainer">
                                    <div class="row center ">
                                        <table class="table table-hover table-borderless ">
                                            <thead>
                                                <tr class="heading">
                                                    <th class="text-left font-mont-semi-bold">Sales Person</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="sNumberOfElements > 0">
                                                <tr [ngClass]="{'selected-row': salesPerson.id == selectedSalesPerson.id}"
                                                    *ngFor="let salesPerson of salesPersonData"
                                                    (click)="onSalesPersonClick(salesPerson);">
                                                    <td
                                                        [ngClass]="{'font-mont-bold': salesPerson.id == selectedSalesPerson.id}">
                                                        {{salesPerson.name}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation example"
                                        *ngIf="sNumberOfElements > 0 && sTotalPages > 1">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item" [ngClass]="{'disabled': sSliceNumber == 0}"
                                                (click)="onSBackClick();">
                                                <a class="page-link prev" tabindex="-1"><img
                                                        src="/assets/images/icons/prev.svg" /></a>
                                            </li>
                                            <li class="page-item" *ngFor="let slice of sSlices"
                                                [ngClass]="{'active': sSliceNumber + 1 == slice}"
                                                (click)="onSSliceClick(slice - 1);">
                                                <a class="page-link"
                                                    *ngIf="slice - 1 > sSliceNumber - 5 && sSliceNumber + 5 > slice - 1">{{slice}}</a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{'disabled': sSliceNumber == sTotalPages - 1}"
                                                (click)="onSNextClick();">
                                                <a class="page-link next"><img
                                                        src="/assets/images/icons/next.svg" /></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div class="row center">
                                    <div class="col-12">
                                        <button class="btn btn-primary font-mont-regular continueButton"
                                            [ngClass]="{'disabled': !selectedSalesPerson.name }" type="button"
                                            [disabled]="!selectedSalesPerson.name" (click)="resubmitApplication();">
                                            Replicate
                                        </button>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary font-mont-regular cancelButton"
                                            (click)="onGoBackClick()">
                                            Go Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <app-error-dialog [modalId]="modalIdE" [title]="errorTitle" [messageA]="errorMessageA" [messageB]="errorMessageB"
        [messageC]="errorMessageC" [messageBClass]="errorMessageBClass" [dialogClass]="dialogClass">
    </app-error-dialog>
</div>