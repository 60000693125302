import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

/**
 * @class SocketService
 */
@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private sock: SockJS = null;
    private stompClient: Stomp.Client = null;

    /**
     * 
     */
    get hasSock() {
        let hasSock = false;

        if (this.sock) hasSock = true;

        return hasSock;
    }

    /**
     * 
     * @param {string} requestID 
     * @returns {Promise<Stomp.Message>}
     */
    connectSocket(requestID: string): Promise<Stomp.Message> {
        return new Promise((resolve, reject) => {
            this.sock = new SockJS(`${environment.gatewayV3}prefillservice/prove`);

            this.stompClient = Stomp.over(this.sock);
            this.stompClient.debug = () => { };

            this.stompClient.connect({ requestID }, () =>

                this.stompClient.subscribe('/users/queue/messages',
                    response => {
                        this.stompClient.disconnect(() => this.resetSocket());
                        resolve(response);
                    },
                    err => reject({ err, errType: 'subscribe' })),
                err => reject({ err, errType: 'connect' })
            );
        });
    }

    /**
     * 
     */
    resetSocket(): void {
        if (this.hasSock) {
            this.sock.close();
            this.sock = null;
            this.stompClient = null;
        }
    }
}