import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { datepickerAnimation } from "ngx-bootstrap/datepicker/datepicker-animations";

const minDate = new Date(1900, 0, 1);
const maxDate = new Date();

export function DateValidator(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    let controlValue: Date = new Date(control.value + '');
    let response: ValidationErrors | null = null;

    if (minDate > controlValue) response = {
      dateError: {
        value: controlValue
      }
    }

    if (controlValue > maxDate) response = {
      dateError: {
        value: controlValue
      }
    }

    return response;
  }

}