<button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular double-white" (click)="onClick();">
    <div>Resend</div>
    <div>Contract</div>
</button>
<div class="modal fade" id="{{additionalResendContractModal}}" tabindex="-1" role="dialog" aria-labelledby="additionalResendContractModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w600" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title w-100 text-left position-absolute" id="exampleModalLongTitle">

                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="close b" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="sendDocsForm" (ngSubmit)="onSendDocsToCustomerSubmit();">
                    <div class="row">
                        <div class="form-group col-12 info-type">
                            <label for="sameInfo">Has the information below changed?</label>
                            <input type="radio" formControlName="sameInfo" id="invoice-same-info-no" value="false" name="sameInfo" (change)="changeSameInfo()">
                            <label for="invoice-same-info-no">No</label>
                            <input type="radio" formControlName="sameInfo" id="invoice-same-info-yes" value="true" name="sameInfo" (change)="changeSameInfo()">
                            <label for="invoice-same-info-yes">Yes</label>
                            <label class="no-description" *ngIf="sendDocsForm.controls.sameInfo.value === 'true'">
                                Please update the applicable fields below then click 'CONTINUE'.
                            </label>
                        </div>
                    </div>
                    <div class="row sub-section-form">
                        <div class="col-12 m-b-20">
                            <label for="name">
                                Invoice/Quote #:
                                <div class="tooltip-two">
                                    <img src="/assets/images/icons/gray_i.svg" alt="gray i" />
                                    <span class="tooltip-two-text">
                                        If your invoice is not finalized, you may enter your quote or estimate number.
                                    </span>
                                </div>
                            </label>
                            <input type="text" formControlName="invoiceNumber" class="form-control" id="invoiceNumber" name="invoiceNumber" [ngClass]="{ 'is-invalid': sendDocsFormSubmitted && sendDocsForm.controls.invoiceNumber.errors }">
                            <div *ngIf="sendDocsFormSubmitted && sendDocsForm.controls.invoiceNumber.errors" class="invalid-feedback">
                                <div *ngIf="sendDocsForm.controls.invoiceNumber.errors.required">
                                    Invoice number is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <label for="name">
                                Financed Amount:
                                <div class="tooltip-two">
                                    <img src="/assets/images/icons/gray_i.svg" alt="gray i" />
                                    <span class="tooltip-two-text">
                                        The amount we will be funding for the transaction. This amount should exclude
                                        sales
                                        tax and not
                                        include more than 20% of soft costs.
                                    </span>
                                </div>
                            </label>
                            <input type="text" formControlName="subTotal" class="form-control" id="subTotal" name="subTotal" [ngClass]="{ 'is-invalid': sendDocsFormSubmitted && sendDocsForm.controls.subTotal.errors }" placeholder="$ " prefix="$ " mask="separator.2" thousandSeparator=",">
                            <div *ngIf="sendDocsFormSubmitted && sendDocsForm.controls.subTotal.errors" class="invalid-feedback">
                                <div *ngIf="sendDocsForm.controls.subTotal.errors.required">
                                    Requested Amount is required.
                                </div>
                                <div *ngIf="sendDocsForm.controls.subTotal.errors.requestedAmount">
                                    The requested amount must be greater than $ 500.
                                </div>
                            </div>
                            <span>Excluding Sales Tax</span>
                        </div>
                        <div class="col-12 m-b-20">
                            <label for="name">
                                Guarantor's Email:
                                <div class="tooltip-two">
                                    <img src="/assets/images/icons/gray_i.svg" alt="gray i" />
                                    <span class="tooltip-two-text">
                                        Where contracts are sent.
                                    </span>
                                </div>
                            </label>
                            <input type="text" formControlName="ownerEmail" class="form-control" id="ownerEmail" name="ownerEmail" [ngClass]="{ 'is-invalid': sendDocsFormSubmitted && sendDocsForm.controls.ownerEmail.errors }">
                            <div *ngIf="sendDocsFormSubmitted && sendDocsForm.controls.ownerEmail.errors" class="invalid-feedback">
                                <div *ngIf="sendDocsForm.controls.ownerEmail.errors.required">
                                    Guarantor email is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="{{additionalSummarySendDocsAddress}}" tabindex="-1" role="dialog" aria-labelledby="summarySendDocsAddress" data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title w-100 text-left position-absolute" id="exampleModalLongTitle">

                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="close b" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="invoiceAddressForm" (ngSubmit)="onSubmitInvoiceDialog();">
                    <div class="row">
                        <div class="form-group col-12 invoice-type">
                            <label for="sameAddres">Is the equipment to be installed at the address
                                below?</label>
                            <input type="radio" formControlName="sameAddres" id="invoice-same-address-no" value="false" name="sameAddres" (change)="changeSameAddress()">
                            <label for="invoice-same-address-no">No</label>
                            <input type="radio" formControlName="sameAddres" id="invoice-same-address-yes" value="true" name="sameAddres" (change)="changeSameAddress()">
                            <label for="invoice-same-address-yes">Yes</label>
                            <label class="no-description" *ngIf="invoiceAddressForm.controls.sameAddres.value === 'false'">
                                If 'No', please update the address where equipment will be delivered/installed
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <input type="text" formControlName="address" class="form-control" id="address" placeholder="Street Address" name="address" [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.address.errors }">
                                </div>
                                <div class="form-group col-6">
                                    <input type="text" formControlName="unit" class="form-control" id="unit" placeholder="Unit" name="unit" [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.unit.errors }">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <input type="text" formControlName="zip" class="form-control" id="zip" placeholder="Zip" name="zip" (keyup)="onZipCodeKeyUp()" [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.zip.errors }">
                                </div>
                                <div class="form-group col-6">
                                    <select formControlName="city" id="city" class="form-control" [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.city.errors }">
                                        <option value="" disabled selected>City</option>
                                        <option *ngFor="let city of invoiceAddresArrays.cities" [ngValue]="city">
                                            {{city}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <select formControlName="state" id="state" class="form-control" [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.state.errors }" [attr.disabled]="true">
                                        <option value="" disabled selected>State</option>
                                        <option *ngFor="let state of invoiceAddresArrays.states" [ngValue]="state.code">
                                            {{state.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-6">
                                    <select formControlName="county" id="county" class="form-control" [ngClass]="{ 'is-invalid': invoAddressForm.county.errors }">
                                        <option value="" disabled selected>County</option>
                                        <option *ngFor="let county of invoiceAddresArrays.counties" [ngValue]="county">
                                            {{county}}
                                        </option>
                                    </select>
                                    <div *ngIf="invoAddressForm.county.errors" class="invalid-feedback pl-2 pt-2">
                                        <div *ngIf="invoAddressForm.county.errors.required">
                                            County is required.
                                        </div>
                                        <div *ngIf="invoAddressForm.county.errors.countyNotMatchCity">
                                            City and County do not match. Please verify your answer.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                                Send Contracts
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="additionalSendContractErrorDialog" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB" [messageC]="messageMessageC" [messageBClass]="messageMessageBClass" [dialogClass]="dialogClass" (closeModal)="onCloseModal();"></app-error-dialog>
<div class="modal fade" id="{{additionalSendContractAttempDialog}}" tabindex="-1" role="dialog" aria-labelledby="attempModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w600" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="close b" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 font-mont-bold" id="exampleModalLongTitle">Having problems?
                </h5>
                <div>It seems you are having issues sending documents to your customer.</div>
                <div>Please speak with a member of our Customer Care team to get this resolved.</div>
                <div>You can reach them at (888) 509-5592.</div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="attemps-number mr-auto">(Resend Docs attempt {{numberOfAttempts}} of 3)</div>
                    </div>
                    <div class="col-12 col-lg-6 text-right">
                        <button type="button" class="btn btn-secondary close-button" [ngClass]="{'full': numberOfDocsExceeded}" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary close-button" (click)="openSelectOffersDialogAfterQuantityValidation();" data-dismiss="modal" *ngIf="!numberOfDocsExceeded">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade success-create-send-modal" id="{{additionalSendContractsSuccessId}}" tabindex="-1" role="dialog" aria-labelledby="additionalSendContractsSuccess" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w600" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal();">
                    <img src="/assets/images/icons/close_b.svg" alt="close b" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 font-mont-bold text-center" id="exampleModalLongTitle">
                    Nice! The contract has been sent to the customer to be signed.
                </h5>
                <h6 class="font-mont-semi-bold">Here's what happens next:</h6>
                <ol>
                    <li>The customer will get an email with a link taking them to sign their documents.</li>
                    <li>
                        They will choose their term, enter their bank account, sign the contract and upload their driver’s license. You can monitor their status in the portal.
                    </li>
                    <li>
                        Once the contracts are signed, you will receive your Purchase Order via email and you may release the items or setup delivery. That email will instruct you on how to get paid!
                    </li>
                </ol>
                <div class="row">
                    <div class="col-12 text-right">
                        <button type="button" class="btn btn-secondary close-button full" data-dismiss="modal" (click)="onCloseModal();">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>