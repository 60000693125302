<div class="containerD">
    <div class="mx-auto frame">
        <div class="boxDec">
            <div>
                <img [ngClass]="isVerifyInfo ? 'imgVerify' : 'imgDec'" src="/assets/images/icons/application-errors/submit-icon.svg" alt="de declined" />
            </div>
            <div *ngIf="!isVerifyInfo">
                <h5 class="tittleDeclined font-mont-bold">Thanks for submitting your information!</h5>            
                <h2 class="subtitle font-mont-regular">Please check with <span class="font-mont-bold">{{partner_name}}</span> to confirm the </h2>
                <h2 class="subtitle font-mont-regular">status of your application.</h2>
            </div>
            <div *ngIf="isVerifyInfo">
                <h5 class="tittleDeclined font-mont-bold">Thanks for submitting the information</h5>            
                <h2 class="subtitle font-mont-regular">Please check the status of this application in the portal.</h2>                
            </div>

            <a *ngIf="isVerifyInfo"  type="button" href="/" class="btnVerify font-mont-regular" >Back to Portal</a>
            
            <div class="information">
                <span class="font-mont-regular">Need Help? Have Questions?</span>
            </div>
            <div class="phone">
                <div class="font-mont-regular">Call <span class="font-mont-regular">1-888-509-5592</span></div>
            </div>
        </div>
    </div>
</div>