import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { MultiRiskAttibuteModelItem } from '../multi-risk.attribute.model.item';
import { MultiRiskModelItem } from '../multi-risk.model.item';
declare let $: any;

@Component({
    selector: 'app-multi-risk-item',
    templateUrl: './multi-risk-item.component.html',
    styleUrls: ['./multi-risk-item.component.css']
})
export class MultiRiskItemComponent implements OnInit {

    modelName = '';
    model: MultiRiskModelItem | any = {};
    allModelsModel: any;
    modelDisabled = true;
    addNewAttributeSubmitted = false;
    newAttributeForm: UntypedFormGroup;

    customLogicSelected = false;
    addCustomLogicSubmitted = false;

    @Input() modelId = '';
    @Input() outcomes: any[] = [];
    @Input() dataRobotModels: any[] = [];
    @Input() attributes: any[] = [];
    @Input() operators: any[] = [];

    @Output() itemLoaded = new EventEmitter<void>();
    @Output() showErrorMessage = new EventEmitter<string>();
    @Output() getModelList = new EventEmitter<any>();

    myform: UntypedFormGroup = new UntypedFormGroup({
        outcomeSelect: new UntypedFormControl(),
        dataRobotModelSelect: new UntypedFormControl(),
        mrModelPriority: new UntypedFormControl(),
        modelLogic: new UntypedFormControl(),
        customLogic: new UntypedFormControl('', Validators.required),
        statusSwitch: new UntypedFormControl()
    });

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.newAttributeForm = this.formBuilder.group({
            description: ['', Validators.required],
            operator: ['', Validators.required],
            value: ['', Validators.required]
        });
        this.allModelsModel = {
            id: 0, description: 'All Models'
        }
    }

    ngOnInit(): void {
        this.disableFields();
        this.getModelDetailAux(this.modelId).then(
            () => this.itemLoaded.emit()
        );
    }

    onChangeOutcome() {
        return new Promise((resolve, reject) => {
            const postData: any = {
                outcomeId: this.myform.controls.outcomeSelect.value.id,
                id: Number(this.modelId)
            };
            this.connectionService.postRequest(environment.gateway + 'model/outcome', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    getModelDetailAux(pModelId: string) {
        return new Promise((resolve, reject) => {
            this.getModelDetail(pModelId).then(
                (data: MultiRiskModelItem) => {
                    this.model = data;
                    this.modelName = data.name;
                    data.active ? this.disableFields() : this.enableFields();
                    this.myform.controls.statusSwitch.patchValue(data.active);
                    this.outcomes.forEach(element => {
                        if (element.id === data.outcomeId) {
                            this.myform.controls.outcomeSelect.patchValue(element);
                        }
                    });

                    if (data.outcomeId !== 8 &&
                        this.dataRobotModels.filter((f: any) => f.id === 0 && f.description === 'All Models').length === 0) {
                        this.dataRobotModels.push(this.allModelsModel);
                    }

                    if (data.outcomeId === 8 &&
                        this.dataRobotModels.filter((f: any) => f.id === 0 && f.description === 'All Models').length > 0) {
                        this.dataRobotModels.pop();
                    }

                    this.myform.controls.dataRobotModelSelect.patchValue(null);
                    this.customLogicSelected = false;
                    this.addCustomLogicSubmitted = false;
                    this.myform.controls.customLogic.patchValue('');

                    let logic = String(data.logic);
                    if (data.logicType === 'CUSTOM') {
                        this.customLogicSelected = true;
                        this.myform.controls.customLogic.patchValue(logic);
                    }
                    
                    this.myform.controls.modelLogic.patchValue(data.logicType);
                    for (let i = this.dataRobotModels.length - 1; i >= 0; i--) {
                        if (this.dataRobotModels[i].id === data.datarobotModelId) {
                            this.myform.controls.dataRobotModelSelect.patchValue(this.dataRobotModels[i]);
                            break;
                        }
                    }
                    this.myform.controls.mrModelPriority.patchValue(data.priority);
                    resolve(data);
                },
                (error: any) => {
                    resolve(error);
                }
            );
        });
    }

    getModelDetail(detailId: string) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'model/detail/' + detailId, 'application/json').subscribe(
                (data: any) => {
                    const multiRiskModel: MultiRiskModelItem = new MultiRiskModelItem(data.object, this.attributes, this.operators, this.dataRobotModels, this.outcomes);
                    resolve(multiRiskModel);
                },
                (error: any) => {
                    this.showErrorMessage.emit(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    onChangeModelStatus() {
        this.disableFields();
        return new Promise((resolve, reject) => {
            const postData: any = {
                active: this.myform.controls.statusSwitch.value,
                id: Number(this.modelId)
            };
            this.connectionService.postRequest(environment.gateway + 'model/status/', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onChangeDataRobotModel() {
        return new Promise((resolve, reject) => {
            const postData: any = {
                datarobotModelId: this.myform.controls.dataRobotModelSelect.value.id,
                id: Number(this.modelId)
            };
            this.connectionService.postRequest(environment.gateway + 'model/dataRobotModel', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onChangePriority() {
        return new Promise((resolve, reject) => {
            const postData: any = {
                priority: this.myform.controls.mrModelPriority.value,
                id: Number(this.modelId)
            };
            this.connectionService.postRequest(environment.gateway + 'model/priority', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onRemoveClick(attribute: MultiRiskAttibuteModelItem) {
        if (this.modelDisabled) {
            return;
        } else {
            return new Promise((resolve, reject) => {
                const postUrl = environment.gateway + '/model/remove/' + attribute.modelId + '/' + attribute.id;
                this.connectionService.deleteRequest(postUrl, 'application/json').subscribe(
                    (data: any) => {
                        this.resolveSuccessNoModelsEmit(resolve, data);
                    },
                    (error: any) => {
                        this.resolveErrorNoModelsEmit(reject, error);
                    }
                );
            });
        }
    }

    onCreateNewAttributeSubmit() {
        this.addNewAttributeSubmitted = false;
        this.newAttributeForm.controls.description.setValue('');
        this.newAttributeForm.controls.operator.setValue('');
        this.newAttributeForm.controls.value.setValue('');
        $('#createAttributeModal').modal();
    }

    onCreateClick() {
        this.addNewAttributeSubmitted = true;
        if (this.newAttributeForm.invalid) {
            return;
        }

        $('#createAttributeModal').modal('hide');
        return new Promise((resolve, reject) => {
            const postData: any = {
                modelId: Number(this.modelId),
                dataRobotAttributeId: this.newAttributeForm.controls.description.value.id,
                operatorId: this.newAttributeForm.controls.operator.value.id,
                value: this.newAttributeForm.controls.value.value,
                conditionIdentifier: Number(this.model.attributes.length + 1)
            };
            this.connectionService.postRequest(environment.gateway + 'modelattribute', postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccessNoModelsEmit(resolve, data);
                },
                (error: any) => {
                    this.resolveErrorNoModelsEmit(reject, error);
                }
            );
        });
    }

    onChangeModelLogic(temp: string) {
        
        let logicToUse = '';
        if (this.customLogicSelected && temp === 'CUSTOM') {
            this.addCustomLogicSubmitted = true;
            if (this.myform.invalid) {
                return;
            }
            logicToUse = this.myform.controls.customLogic.value;
        } else if (temp === 'CUSTOM') {
            this.customLogicSelected = true;
            return;
        } else {
            this.customLogicSelected = false;
            this.addCustomLogicSubmitted = false;
            if (this.model.attributes.length <= 1) {
                logicToUse = '1';
            } else {
                let tempL = Array.from({ length: this.model.attributes.length }, (_, i) => i + 1);
                if (temp === 'ALL_AND') {
                    logicToUse = tempL.join(' AND ');
                }
                if (temp === 'ALL_OR') {
                    logicToUse = tempL.join(' OR ');
                }
            }
        }
        return new Promise((resolve, reject) => {
            const postData: any = {
                logic: logicToUse,
                logicType: temp,
                id: Number(this.modelId)
            };
            this.connectionService.postRequest(`${environment.gateway}model/logic`, postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    private disableFields() {
        this.modelDisabled = true;
        this.myform.controls.outcomeSelect.disable();
        this.myform.controls.dataRobotModelSelect.disable();
        this.myform.controls.mrModelPriority.disable();
        this.myform.controls.modelLogic.disable();
        this.myform.controls.customLogic.disable();
    }

    private enableFields() {
        this.modelDisabled = false;
        this.myform.controls.outcomeSelect.enable();
        this.myform.controls.dataRobotModelSelect.enable();
        this.myform.controls.mrModelPriority.enable();
        this.myform.controls.modelLogic.enable();
        this.myform.controls.customLogic.enable();
    }

    private resolveSuccess(resolve: (value: unknown) => void, data: any) {
        this.getModelDetailAux(this.modelId);
        this.getModelList.emit(this.modelId);
        resolve(data);
    }

    private resolveError(reject: (reason?: any) => void, error: any) {
        this.getModelDetailAux(this.modelId);
        this.getModelList.emit(this.modelId);
        this.showErrorMessage.emit(error.error.msg);
        reject(error);
    }

    private resolveSuccessNoModelsEmit(resolve: (value: unknown) => void, data: any) {
        this.getModelDetailAux(this.modelId);
        resolve(data);
    }

    private resolveErrorNoModelsEmit(reject: (reason?: any) => void, error: any) {
        this.getModelDetailAux(this.modelId);
        this.showErrorMessage.emit(error.error.msg);
        reject(error);
    }
}