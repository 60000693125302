<app-application-wrapper [subTitleA]="''" [subTitleB]="''">
  <ng-template appApplicationWrapperHelper>
    <div class="content-container">
      <h1 class="cls-title-30px font-mont-bold">Thanks for submitting your information.</h1>
      <p class="font-mont-regular" *ngIf="!excludeAppResults">
        Unfortunately, your application was declined.
      </p>
      <h4 class="subtitle font-mont-semi-bold">What’s next?</h4>
      <div class="p-container">
        <div class="div-img">
          <img src="assets\images\event_repeat.svg" alt="" class="building-img">
        </div>
        <div class="div-p-cont">
          <p class="font-mont-regular">You can try again in 30 days to see if the results change. We
            hope to work with you in the future.</p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="/assets/images/icons/application-errors/denied-error-page.svg" alt="">
  </ng-container>
  <ng-container ngProjectAs="[mobileImage]">
      <img src="/assets/images/icons/application-errors/denied-error-page.svg" alt="image">
  </ng-container>
</app-application-wrapper>
