<app-application-wrapper [style]="0" [footerHidden]="true">
    <ng-template appApplicationWrapperHelper>
        <div class="center-cont" style="position: relative;">
            <div class="msg">
                <h2 class="font-mont-bold">Your authentication</h2>
                <h2 class="font-mont-bold">failed too many times and</h2>
                <h2 class="font-mont-bold">we cannot continue with</h2>
                <h2 class="font-mont-bold">your lease</h2>
            </div>
            <div class="notification">
                <p class="font-mont-regular">What’s next?</p>
            </div>
            <div class="menssage">
                <img src="../../../../assets/images/icons/authentication-icon.svg" alt="">
                <p class="font-mont-regular">You can try again in 30 days.</p>

            </div>
        </div>
    </ng-template>
    <ng-container ngProjectAs="[image]">
        <img src="../../../../assets/images/edgar-decline.svg" alt="">
    </ng-container>
</app-application-wrapper>