import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-pop-up-notify',
    templateUrl: './pop-up-notify.component.html',
    styleUrls: ['./pop-up-notify.component.css']
})
export class PopUpNotifyComponent {
    @Input() title = '';
    @Input() textA = '';
    @Input() sent = '';
    @Input() textB = '';
    @Input() phone = '';
    @Output() closeNotify = new EventEmitter<void>();

    onCloseNotify() {
        this.closeNotify.emit();
    }

}
