import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-soft-decline',
  templateUrl: './app-soft-decline.component.html',
  styleUrls: ['./app-soft-decline.component.css']
})
export class AppSoftDeclineComponent implements OnInit {
 // AppResolveComponent
  partnerName: string = "";

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.partnerName = this.authService.vendorReferrerName;
  }

}
