import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateValidator } from 'src/app/utils/shared';

export interface IPersonalInformation {
  id: number;
  firstName: string;
  lastName: string;
  birthDate: Date;
  ssn: number;
  street: string;
  zipcode: string;
  city: string;
  state: string;
  county: string;
  phoneNumber: number;
}

export class PersonalInformationItem {
  form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
          id: [''],
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          birthDate: ['', [Validators.required, DateValidator()]],
          socialSecurityNumber: ['', Validators.required],
          address: ['', Validators.required],
          zipCode: ['', Validators.required],
          city: ['', Validators.required],
          state: ['', Validators.required],
          county: ['', Validators.required],
          phoneNumber: [{ value: '', disabled: true }],
          testPhone: ['']
    });
  }

  decode(data: IPersonalInformation) {
    this.form.controls.id.setValue(data.id);
    this.form.controls.firstName.setValue(data.firstName);
    this.form.controls.lastName.setValue(data.lastName);
    this.form.controls.birthDate.setValue(data.birthDate);
    this.form.controls.socialSecurityNumber.setValue(data.ssn);
    this.form.controls.address.setValue(data.street);
    this.form.controls.zipCode.setValue(data.zipcode);
    this.form.controls.city.setValue(data.city);
    this.form.controls.state.setValue(data.state);
    this.form.controls.phoneNumber.setValue(data.phoneNumber);
    this.form.controls.county.setValue(data.county);
  }

  enconde(): IPersonalInformation {
    return {
      id: this.form.controls.id.value,
      firstName: this.form.controls.firstName.value,
      lastName: this.form.controls.lastName.value,
      birthDate: this.form.controls.birthDate.value.toISOString().slice(0, 10),
      ssn: this.form.controls.socialSecurityNumber.value,
      street: this.form.controls.address.value,
      zipcode: this.form.controls.zipCode.value,
      city: this.form.controls.city.value,
      state: this.form.controls.state.value,
      county: this.form.controls.county.value,
      phoneNumber: this.form.controls.phoneNumber.value,
    }
  }

  setDefaultData() {

    this.decode(
      {
        id: this.form.controls.id.value ? this.form.controls.id.value : 0,
        firstName: this.form.controls.firstName.value ? this.form.controls.firstName.value : 'Jacob',
        lastName: this.form.controls.lastName.value ? this.form.controls.lastName.value : 'Weaver',
        birthDate: this.form.controls.birthDate.value ? this.form.controls.birthDate.value : new Date(1984, 6, 25),
        ssn: this.form.controls.socialSecurityNumber.value ? this.form.controls.socialSecurityNumber.value : 999243136,
        street: this.form.controls.address.value ? this.form.controls.address.value : '1182 Main St',
        zipcode: this.form.controls.zipCode.value ? this.form.controls.zipCode.value : '80112',
        city: this.form.controls.city.value ? this.form.controls.city.value : 'ENGLEWOOD'.toUpperCase(),
        state: this.form.controls.state.value ? this.form.controls.state.value : 'CO',
        county: this.form.controls.county.value ? this.form.controls.county.value : 'ARAPAHOE',
        phoneNumber: this.form.controls.phoneNumber.value ? this.form.controls.phoneNumber.value : 2001062109
      }
    );
  }


}
