import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

export interface IStarted {
    phoneNumber: number;
}

export class StartedItem {
    form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
            mobilePhoneNumber: ['', Validators.required]
        });
    }

    decode(data: IStarted) {
        this.form.controls.mobilePhoneNumber.setValue(data.phoneNumber);
    }

    enconde(): IStarted {
        return {
            phoneNumber: this.form.controls.mobilePhoneNumber.value
        }
    }

    setDefaultData() {
        this.decode(
            {
                phoneNumber: 2001062109
            }
        );
    }
}