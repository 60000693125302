export class MultiRiskAttibuteModelItem {
  dataRobotAttributeId: number;
  id: number;
  modelId: number;
  operatorId: number;
  value: string;
  dataRobotAttributeName: string;
  operatorDescription: string;
  conditionIdentifier: number;

  constructor(data: any, attributes: any, operators: any) {
    this.dataRobotAttributeId = data.dataRobotAttributeId;
    this.id = data.id;
    this.modelId = data.modelId;
    this.operatorId = data.operatorId;
    this.value = data.value;
    this.conditionIdentifier = data.conditionIdentifier;
    attributes.forEach((element: any) => {
      if (element.id === this.dataRobotAttributeId) {
        this.dataRobotAttributeName = element.description;
      }
    });
    operators.forEach((element: any) => {
      if (element.id === this.operatorId) {
        this.operatorDescription = element.operator;
      }
    });
  }
}
