import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ClickSingService } from 'src/app/click-sing';

@Component({
    selector: 'app-docusign-error',
    templateUrl: './docusign-error.component.html',
    styleUrls: ['./docusign-error.component.css']
})
export class DocusignErrorComponent implements OnInit {
    event: any = null;

    constructor(
        private router: Router,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private clickSingService: ClickSingService,
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state) {
            this.event = navigation.extras.state.event;
        } else this.errorListService.redirectToV3Error(
            null,
            'docusign-error-params',
            'click-sign-otp'
        );
    }

    ngOnInit() {
        this.googleAnalyticsService.trackClickSignEvent(
            'docusign-error',
            this.clickSingService.vendorReferrerName,
            this.event
        );
    }
}
