import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Experimental } from 'src/app/utils/shared';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ErrorListService } from 'src/app/shared/services';
import { BankProcessService } from 'src/app/bank-process';
import {
  BusinessFormService,
  SoleFormService
} from 'src/app/application/v3/services';

declare var $: any;

@Component({
    selector: 'app-finicity-process',
    templateUrl: './finicity-process.component.html',
    styleUrls: ['./finicity-process.component.css']
})
export class FinicityProcessComponent implements OnInit {
    loading = false;
    tryCount = 0;
    errorTitle = '';

    //Create Notify
    title: string;
    textA: string;
    sent: string;
    textB: string;
    phone: string;
    public showNotify: boolean = false;

    isClicksign: any = false;

    private finicityURL: string;
    private applicationId: number = 0;
    private token: string;
    private access_token: string;
    private started: string = 'started';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private bankProcessService: BankProcessService,
        private businessService: BusinessFormService,
        private soleFormService: SoleFormService
    ) {
        const navigation = this.router.getCurrentNavigation();

        if (navigation && navigation.extras && navigation.extras.state) {
            this.applicationId = navigation.extras.state.appId;
            this.finicityURL = navigation.extras.state.finicityURL;
            this.access_token = navigation.extras.state.accessToken;
            this.token = navigation.extras.state.token;
            this.started = navigation.extras.state.started ? navigation.extras.state.started: this.started;
        } else this.errorListService.redirectToV3Error(
            null,
            'finicity-without-state-params',
            this.isClickSign ? 'click-sign-otp' : this.started
        );
    }

    get currentFlow() {
        return !Experimental.isExperimental();
    }

    get isClickSign() {
        return this.bankProcessService.isClicksing;
    }

    get production() {
        return this.bankProcessService.isProduction;
    }

    get steps() {
        return Experimental.appSteps();
    }

    private connectFinicitySocket(applicationId: string, resolve: any, reject: any) {

        this.bankProcessService.connectSocket(applicationId, this.access_token)
            .then((message) => {
            if (message.body === 'DATA_ERROR') {
                reject(message.body);
            } else if (message.body === 'ERROR') {
                this.errorListService.redirectToV3Error(
                    message,
                    'finicity-socked-success-error',
                    this.isClicksign ? 'click-sign-otp' : this.started
                );
            } else {
                const data = message.body ? JSON.parse(message.body) : false;

                if (data) {
                    resolve(data);
                } else reject(message);

            }
        })
            .catch(({ err, errType }) => {
            this.errorListService.redirectToV3Error(
                err,
                `finicity-socked-${errType}-error`,
                this.isClicksign ? 'click-sign-otp' : this.started
            )
        });
    }

    private trackEvent(eventName, refererName, eventData, errorCode?) {
        if (this.isClickSign) {
            this.googleAnalyticsService.trackClickSignEvent(
                eventName,
                refererName,
                eventData,
                errorCode
            );
        } else this.googleAnalyticsService.trackV3Event(
            eventName,
            refererName,
            eventData,
            errorCode
        );
    }

    ngOnInit() {
        this.trackEvent('finicity-shown', this.bankProcessService.vendorReferrerName, '');
    }

    ngAfterViewInit() {
        this.getFinicityURL();
    }

    ngOnDestroy() {
        window['finicityConnect'].destroy();
    }

    createMessageNotify() {
        this.title = 'We just sent you an email';
        this.textA = 'We just sent a link to ';
        this.textB = `to verify bank account. If that doesn't work, please contact our support team at`;
        this.phone = '(888) 509-5592';

        if (this.soleFormService.getControlValue('email')) {
            this.sent = this.soleFormService.getControlValue('email') ?
                this.soleFormService.getControlValue('email') : 'your email';
        } else {
            this.sent = this.businessService.getControlValue('businessEmail') ?
                this.businessService.getControlValue('businessEmail') : 'your email';
        }
        this.showNotify = true;
    }

    fakeFinicitySuccess() {
        this.loading = !this.loading;
        /*setTimeout(() => {
            this.router.navigate(['../../approval'], {
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'merge'
            });
        }, 3000);*/
    }

    getFinicityURL() {
        let windowlaunch: string;
        let selectorIframe: string = 'finicityIframe';

        windowlaunch = this.finicityURL;

        if (windowlaunch) {
  
            window['finicityConnect'].launch(windowlaunch, {

                selector: '#' + selectorIframe,
                overlay: 'rgba(255,255,255, 0)',
                success: (event: any) => {

                    this.trackEvent(
                        'finicity-uploaded',
                        this.bankProcessService.vendorReferrerName,
                        JSON.stringify(event)
                    );

                    if (event.reason == 'complete' && event.code == 200) {
                        this.loading = true;
                        this.validateTransaction().then(
                            offers => this.onFinicityCompleted(offers),
                            vError => {
                                this.errorListService.setErrorsData(vError);
                                this.router.navigate(['/finicity-declined'], {
                                    queryParamsHandling: 'merge',
                                    state: {
                                        endpoint: 'finicity-validate-error',
                                        error: true
                                    }
                                });
                            }
                        );
                    }

                }
            });

        } else if (this.isClickSign) this.errorListService.redirectToV3Error(
            null,
            'finicity-without-url',
            this.isClickSign ? 'click-sign-otp' : this.started
        );

    }

    onCloseNotify() {
        this.showNotify = false;
    }

    onFinicityCompleted(data: any) {
        if (this.isClickSign) {
            if (data && data.object && data.object.bankAccountNumber) {

                this.googleAnalyticsService.trackClickSignEvent(
                    'finicity-success',
                    this.bankProcessService.vendorReferrerName,
                    JSON.stringify(data)
                );
                this.bankProcessService.connectedAccountsObject = {};
                this.bankProcessService.clickSignleaseTermsData = data.object;

                this.router.navigate(['../../contract-signing'], {
                    relativeTo: this.activatedRoute,
                    replaceUrl: true,
                    queryParamsHandling: 'merge',
                    state: {
                        appId: this.applicationId,
                        accessToken: this.access_token,
                        token: this.token
                    }
                });

            } else if (data && data.code === '412') {

                this.googleAnalyticsService.trackClickSignEvent(
                    'finicity-success',
                    this.bankProcessService.vendorReferrerName
                );

                this.router.navigate(['../../offers-selection'], {
                    relativeTo: this.activatedRoute,
                    replaceUrl: true,
                    queryParamsHandling: 'merge',
                    state: {
                        accessToken: this.access_token,
                        token: this.token,
                        appId: this.applicationId,
                        offersChanged: true
                    }
                });
  
            } else this.onFinicityErrorCompleted(data);

        } else {

            this.googleAnalyticsService.trackV3Event(
                'finicity-success',
                this.bankProcessService.vendorReferrerName,
                JSON.stringify(data)
            );

            const offersReady = this.bankProcessService.getOffersFinicity(data);

            if (offersReady === true) {
                this.googleAnalyticsService.trackV3Event(
                    'opportunity-created',
                    this.bankProcessService.vendorReferrerName,
                    ''
                );
                this.router.navigate(['../../approval'], {
                    relativeTo: this.activatedRoute,
                    queryParamsHandling: 'merge'
                });
            } else {
                if (offersReady.redirect) {
                    this.errorListService.setErrorsData(offersReady);

                    this.router.navigate([`/${offersReady.redirect}`], {
                        queryParamsHandling: 'merge', state: {
                            endpoint: 'finicity-offers-error',
                            error: true,
                            start: this.started
                        }
                    });
                }
            }
        }
    }

    onFinicityErrorCompleted(fError) {
        this.googleAnalyticsService.trackClickSignEvent(
            'finicity-error',
            this.bankProcessService.vendorReferrerName,
            JSON.stringify(fError)
        );

        this.router.navigate(['/decline-error'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
        });
    }

    //Finicity
    sendFinicityVerifyBankAccountEmail() {
        return new Promise((resolve, reject) =>
            this.bankProcessService.sendFinicityVerifyBankAccountEmail(this.access_token)
                .then(data => {
                    // this.onFinicityLinkSentSuccess();
                    this.createMessageNotify();
                    resolve(data);
                })
                .catch((error: any) => {
                    // this.onFinicityLinkSentError(error.error.msg);
                    this.createMessageNotify();
                    reject(error);
                })
        );
    }

    validateTransaction() {
        return new Promise((resolve, reject) => {
            this.connectFinicitySocket(
                this.applicationId.toString(),
                resolve,
                reject
            );
        });
    }

}