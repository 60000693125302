import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-ach-converter-component',
    templateUrl: './ach-converter-component.component.html',
    styleUrls: ['./ach-converter-component.component.css']
})
export class AchConverterComponentComponent implements OnInit {

    achAttachments = [];
    textValueAch = '';
    dataDocuments = [];
    csv = '';
    constructor(
        private connectionService: ConnectionService) { }

    ngOnInit() {
    }


    onDownloadAchCsv() {
        const submitSize = this.achAttachments.reduce(function (a, b) { return a + b.rawSize; }, 0);

        if (submitSize > 6291456) {
            return;
        }

        for (const file in this.achAttachments) {
            if (this.achAttachments.hasOwnProperty(file)) {
                this.dataDocuments.push({
                    name: this.achAttachments[file].name,
                    documentB: this.textValueAch
                });
            }
        }
        const promise = new Promise((resolve, reject) => {
            this.connectionService.postRequest(environment.gateway + 'save/ach', this.dataDocuments, 'application/json').subscribe(
                (data: any) => {
                    debugger;
                    const pdfs = data.object;
                    const pdf = pdfs.documentB;
                    if (pdfs.name === 'ach.csv') {
                        this.csv = pdf
                    }
                    resolve(data);
                },
                (error: any) => {
                    $('#achDialogModal').modal('hide');
                    reject(error.error);
                }
            );
        });
        return promise;
    }

    uploadFile(event: any) {
        const file = (event.target as HTMLInputElement).files[0];
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.textValueAch = btoa(fileReader.result as string);
        }
        fileReader.readAsText(file);
        this.achAttachments = [];
        this.achAttachments.push(file);
    }

    downloadFileCsv() {
        let csvContent = atob(this.csv);
        var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
        var url = window.URL.createObjectURL(blob);
        // you need to precise a front-end button to have a name
        const downloadLink = document.createElement("a");
        const fileName = "ach.csv";
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
    }

}
