import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { PricingAdjustmentConditionItem } from '../pricing.pricing.condition';
import { PricingAdjustmentItem } from '../pricing.pricing.item';
declare var $: any;

@Component({
    selector: 'app-pricing-item',
    templateUrl: './pricing-item.component.html',
    styleUrls: ['./pricing-item.component.css']
})
export class PricingItemComponent implements OnInit {

    @Input() adjustmentId = '';
    @Input() dataRobotModels: any[] = [];
    @Input() operators: any[] = [];
    @Input() attributes: any[] = [];

    @Output() itemLoaded = new EventEmitter<void>();
    @Output() getAdjustmentsList = new EventEmitter<any>();
    @Output() showErrorMessage = new EventEmitter<string>();

    activeAdjustment: PricingAdjustmentItem | any = {};
    customLogicSelected = false;
    addCustomLogicSubmitted = false;
    disabledFields = true;
    addNewConditionSubmitted = false;
    adjustmentsForm: UntypedFormGroup;
    newConditionForm: UntypedFormGroup;

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.adjustmentsForm = new UntypedFormGroup({
            adjustmentVal: new UntypedFormControl(),
            dataRobotModelSelect: new UntypedFormControl(),
            adjustmentLogic: new UntypedFormControl(),
            customLogic: new UntypedFormControl('', Validators.required),
            statusSwitch: new UntypedFormControl()
        });

        this.newConditionForm = this.formBuilder.group({
            description: ['', Validators.required],
            operator: ['', Validators.required],
            value: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        this.disableFields();
        this.adjustmentsForm.controls.statusSwitch.patchValue(true);
        this.getAdjustmentDetailAux(this.adjustmentId).then(
            () => this.itemLoaded.emit()
        );
    }

    onChangeDataRobotModel() {
        return new Promise((resolve, reject) => {
            const postData: any = {
                dataRobotModelId: this.adjustmentsForm.controls.dataRobotModelSelect.value.id,
                id: Number(this.adjustmentId)
            };
            this.connectionService.postRequest(`${environment.gateway}pricingadjustment/dataRobotModel`, postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    getAdjustmentDetailAux(pModelId: string) {
        return new Promise((resolve, reject) => {
            this.getAdjustmentDetail(pModelId).then(
                (data: PricingAdjustmentItem) => {
                    this.activeAdjustment = data;
                    data.active ? this.disableFields() : this.enableFields();
                    this.adjustmentsForm.controls.statusSwitch.patchValue(data.active);
                    this.adjustmentsForm.controls.adjustmentVal.patchValue(data.adjustment);
                    this.adjustmentsForm.controls.dataRobotModelSelect.patchValue(null);
                    this.customLogicSelected = false;
                    this.addCustomLogicSubmitted = false;
                    this.adjustmentsForm.controls.customLogic.patchValue('');

                    let logic = String(data.logic);
                    if (data.logicType === 'CUSTOM') {
                        this.customLogicSelected = true;
                        this.adjustmentsForm.controls.customLogic.patchValue(logic);
                    }
                    this.adjustmentsForm.controls.adjustmentLogic.patchValue(data.logicType);
                    for (var i = this.dataRobotModels.length - 1; i >= 0; i--) {
                        if (this.dataRobotModels[i].id === data.dataRobotModelId) {
                            this.adjustmentsForm.controls.dataRobotModelSelect.patchValue(this.dataRobotModels[i]);
                            break;
                        }
                    }
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    getAdjustmentDetail(adjustmentId: string) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}pricingadjustment/detail/${adjustmentId}`, 'application/json').subscribe(
                (data: any) => {
                    const pricingAdjustment: PricingAdjustmentItem = new PricingAdjustmentItem(data.object, this.attributes, this.operators, this.dataRobotModels);
                    resolve(pricingAdjustment);
                },
                (error: any) => {
                    this.showErrorMessage.emit(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    onChangeAdjustmentValue() {
        return new Promise((resolve, reject) => {
            const postData: any = {
                adjustment: this.adjustmentsForm.controls.adjustmentVal.value,
                id: Number(this.adjustmentId)
            };
            this.connectionService.postRequest(`${environment.gateway}pricingadjustment/value/`, postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onChangeAdjustmentStatus() {
        this.disableFields();
        return new Promise((resolve, reject) => {
            const postData: any = {
                active: this.adjustmentsForm.controls.statusSwitch.value,
                id: Number(this.adjustmentId)
            };
            this.connectionService.postRequest(`${environment.gateway}pricingadjustment/status/`, postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onChangeAdjustmentLogic(temp: string) {
        let logicToUse = '';
        if (this.customLogicSelected && temp === 'CUSTOM') {
            this.addCustomLogicSubmitted = true;
            if (this.adjustmentsForm.invalid) {
                return;
            }
            logicToUse = this.adjustmentsForm.controls.customLogic.value;
        } else if (temp === 'CUSTOM') {
            this.customLogicSelected = true;
            return;
        } else {
            this.customLogicSelected = false;
            this.addCustomLogicSubmitted = false;
            if (this.activeAdjustment.conditions.length <= 1) {
                logicToUse = '1';
            } else {
                var tempL = Array.from({ length: this.activeAdjustment.conditions.length }, (_, i) => i + 1);
                if (temp === 'ALL_AND') {
                    logicToUse = tempL.join(' AND ');
                }
                if (temp === 'ALL_OR') {
                    logicToUse = tempL.join(' OR ');
                }
            }
        }
        return new Promise((resolve, reject) => {
            const postData: any = {
                logic: logicToUse,
                logicType: temp,
                id: Number(this.adjustmentId)
            };
            this.connectionService.postRequest(`${environment.gateway}pricingadjustment/logic`, postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccess(resolve, data);
                },
                (error: any) => {
                    this.resolveError(reject, error);
                }
            );
        });
    }

    onRemoveClick(condition: PricingAdjustmentConditionItem) {
        if (this.activeAdjustment.active) {
            return;
        } else {
            return new Promise((resolve, reject) => {
                const postUrl = `${environment.gateway}/pricingadjustment/remove/${condition.pricingAdjustmentId}/${condition.id}`;
                this.connectionService.deleteRequest(postUrl, 'application/json').subscribe(
                    (data: any) => {
                        this.resolveSuccessNoAdjustmentsEmit(resolve, data);
                    },
                    (error: any) => {
                        this.resolveErrorNoAdjustmentsEmit(reject, error);
                    }
                );
            });
        }
    }

    onCreateNewConditionSubmit() {
        this.addNewConditionSubmitted = false;
        this.newConditionForm.controls.description.setValue('');
        this.newConditionForm.controls.operator.setValue('');
        this.newConditionForm.controls.value.setValue('');
        $('#addNewAdjustmentConditionModal').modal();
    }

    onCreateConditionSubmit() {
        this.addNewConditionSubmitted = true;
        if (this.newConditionForm.invalid) {
            return;
        }

        $('#addNewAdjustmentConditionModal').modal('hide');
        return new Promise((resolve, reject) => {
            const postData: any = {
                pricingAdjustmentId: Number(this.adjustmentId),
                dataRobotAttributeId: this.newConditionForm.controls.description.value.id,
                operatorId: this.newConditionForm.controls.operator.value.id,
                value: this.newConditionForm.controls.value.value,
                conditionIdentifier: Number(this.activeAdjustment.conditions.length + 1)
            };
            this.connectionService.postRequest(`${environment.gateway}adjustmentCondition`, postData, 'application/json').subscribe(
                (data: any) => {
                    this.resolveSuccessNoAdjustmentsEmit(resolve, data);
                },
                (error: any) => {
                    this.resolveErrorNoAdjustmentsEmit(reject, error);
                }
            );
        });
    }

    private resolveSuccess(resolve: (value: unknown) => void, data: any) {
        this.getAdjustmentDetailAux(this.adjustmentId);
        this.getAdjustmentsList.emit(this.adjustmentId);
        resolve(data);
    }

    private resolveError(reject: (reason?: any) => void, error: any) {
        this.getAdjustmentDetailAux(this.adjustmentId);
        this.getAdjustmentsList.emit(this.adjustmentId);
        this.showErrorMessage.emit(error.error.msg);
        reject(error);
    }

    private disableFields() {
        this.disabledFields = true;
        this.adjustmentsForm.controls.adjustmentVal.disable();
        this.adjustmentsForm.controls.dataRobotModelSelect.disable();
        this.adjustmentsForm.controls.adjustmentLogic.disable();
        this.adjustmentsForm.controls.customLogic.disable();
    }

    private enableFields() {
        this.disabledFields = false;
        this.adjustmentsForm.controls.adjustmentVal.enable();
        this.adjustmentsForm.controls.dataRobotModelSelect.enable();
        this.adjustmentsForm.controls.adjustmentLogic.enable();
        this.adjustmentsForm.controls.customLogic.enable();
    }

    private resolveSuccessNoAdjustmentsEmit(resolve: (value: unknown) => void, data: any) {
        this.getAdjustmentDetailAux(this.activeAdjustment.id);
        resolve(data);
    }

    private resolveErrorNoAdjustmentsEmit(reject: (reason?: any) => void, error: any) {
        this.getAdjustmentDetailAux(this.activeAdjustment.id);
        this.showErrorMessage.emit(error.error.msg);
        reject(error);
    }
}