import { Component, OnInit, Injector } from '@angular/core';
import { ConnectionService } from '../../shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MultiRiskModelItem } from './multi-risk.model.item';
import { ArrayFilterPipe } from '../../shared/pipes/array.filter.pipe';
import { ArraySortPipe } from '../../shared/pipes/array.sort.pipe';
declare var $: any;

@Component({
    selector: 'app-multi-risk',
    templateUrl: './multi-risk.component.html',
    styleUrls: ['./multi-risk.component.css']
})
export class MultiRiskComponent implements OnInit {

    models: any = [];
    newModelForm: UntypedFormGroup;
    selectedRow: number = -1;
    tempSelectedRow: number = -1;
    addNewModelSubmitted = false;

    modalId = 'ErrorsModal';
    messageTitle = '';
    messageMessageA = '';
    messageMessageB = '';
    messageMessageC = '';
    messageMessageBClass = '';
    dialogClass = '';

    modelId = '';
    dataRobotModels: any[] = [];
    attributes: any[] = [];
    operators: any[] = [];
    outcomes: any[] = [];

    statusFilterOpen: boolean = false;
    outcomeFilterOpen: boolean = false;
    dataRobotModelFilterOpen: boolean = false;

    filter: string = '';
    filterValue: string = '';

    sortField: string = '';
    invertedSort: boolean = false;

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private arrayFilterPipe: ArrayFilterPipe,
        private arraySortPipe: ArraySortPipe
    ) {
        this.newModelForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.getDataRobotModelList();
        this.getOutcomeList().then(
            (outcomeData) => {
                this.getAttributeList().then(
                    (attributeData) => {
                        this.getOperatorList().then(
                            (operatorData) => { 
                                // This is intentional
                            },
                            (operatorError) => {
                                // This is intentional
                             }
                        );
                    },
                    (attributeError) => {
                        // This is intentional
                     }
                );
            },
            (outcomeError) => {
                // This is intentional
             }
        );
        this.getModelList();
    }

    onCreateNewModelSubmit() {
        this.addNewModelSubmitted = false;
        this.newModelForm.controls.name.setValue('');
        this.newModelForm.controls.description.setValue('');
        $('#addNewModelModal').modal();
    }

    onCreateSubmit() {
        this.addNewModelSubmitted = true;
        if (this.newModelForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            this.getOutcomeList().then(
                (outcomeData: any) => {
                    const postData: any = {
                        active: false,
                        description: this.newModelForm.controls.description.value,
                        express: true,
                        name: this.newModelForm.controls.name.value,
                        outcomeId: 1,
                        datarobotModelId: this.dataRobotModels[0].id,
                        priority: Number(0),
                        logic: '1',
                        logicType: 'ALL_AND'
                    };

                    this.connectionService.postRequest(environment.gateway + 'model', postData, 'application/json').subscribe(
                        (data: any) => {
                            $('#addNewModelModal').modal('hide');
                            this.showSuccessMessage('Model ' + data.object.name + ' created!');
                            this.modelId = data.object.id;
                            this.getModelList(this.modelId);
                            resolve(data);
                        },
                        (error: any) => {
                            $('#addNewModelModal').modal('hide');
                            this.showErrorMessage(error.error.msg);
                            reject(error);
                        }
                    );
                },
                (outcomeError: any) => {
                    reject(outcomeError);
                });
        });
    }

    getModelList(modelId?: any) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'model/list', 'application/json').subscribe(
                (data: any) => {
                    let result = [];
                    data.object.forEach(element => {
                        let lModel = new MultiRiskModelItem(element, this.attributes, this.operators, this.dataRobotModels, this.outcomes);
                        result.push(lModel);
                    });

                    this.models = result;
                    let filteredAndSortedResult = this.arrayFilterPipe.transform(result, this.filter, this.filterValue);
                    filteredAndSortedResult = this.arraySortPipe.transform(filteredAndSortedResult, this.sortField, this.invertedSort);

                    if (modelId) {
                        for (let index = 0; index < filteredAndSortedResult.length; index++) {
                            if (filteredAndSortedResult[index].id == modelId) {
                                this.tempSelectedRow = index;
                            }
                        }
                    }
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getOutcomeList() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'outcome/list', 'application/json').subscribe(
                (data: any) => {
                    this.outcomes = data.object;
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getAttributeList() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'attribute/list', 'application/json').subscribe(
                (data: any) => {
                    this.attributes = data.object;
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getOperatorList() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'operator/list', 'application/json').subscribe(
                (data: any) => {
                    this.operators = data.object;
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getDataRobotModelList() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'datarobotmodel/list', 'application/json').subscribe(
                (data: any) => {
                    this.dataRobotModels = data.object;
                    this.dataRobotModels.unshift({ id: 0, description: "" });
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    showErrorMessage(pError: string) {
        this.messageTitle = 'Sorry, we found a problem';
        this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
        this.messageMessageB = pError;
        this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
        this.messageMessageBClass = 'mB20';
        this.dialogClass = 'w600';
        $('#errorModalDialog' + this.modalId).modal();
    }

    showSuccessMessage(pMessage: string) {
        this.messageTitle = 'Success!!';
        this.messageMessageA = pMessage;
        this.messageMessageB = '';
        this.messageMessageC = '';
        this.messageMessageBClass = '';
        this.dialogClass = 'w450';
        $('#errorModalDialog' + this.modalId).modal();
    }

    toggleSelectedRow(rowId: number, appId: number) {
        this.modelId = String(appId);
        if (this.tempSelectedRow == rowId) {
            this.selectedRow = -1;
            this.tempSelectedRow = -1;
        } else {
            this.tempSelectedRow = rowId;
        }
    }

    onItemLoaded() {
        setTimeout(() => {
            this.selectedRow = this.tempSelectedRow;
            setTimeout(() => {
                let el = document.getElementById('itemNumber_' + this.selectedRow);
                let pos = el.getBoundingClientRect().top + window.pageYOffset - 95;
                window.scrollTo({ top: pos, behavior: 'smooth' });
            }, 250);
        }, 250);
    }

    toggleStatusFilter() {
        this.statusFilterOpen = !this.statusFilterOpen;
    }

    toggleOutcomeFilter() {
        this.outcomeFilterOpen = !this.outcomeFilterOpen;
    }

    toggleDataRobotModelFilter() {
        this.dataRobotModelFilterOpen = !this.dataRobotModelFilterOpen;
    }

    onStatusCheckClick(field: any, value: any) {
        this.filter = field;
        this.filterValue = value;
        this.selectedRow = -1;
        this.tempSelectedRow = -1;
    }

    onSortClick(field: any) {
        this.selectedRow = -1;
        this.tempSelectedRow = -1;
        if (this.sortField == field) {
            this.invertedSort = !this.invertedSort;
        } else {
            this.invertedSort = false;
            this.sortField = field;
        }
    }
}
