import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';

@Injectable()
export class BlockGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private googleAnalyticsService: GoogleAnalyticsService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
          if(!this.authService.getExcludePortalApp()) {            
            resolve(true);
            return;
          }
          this.router.navigate(['signin']);
          reject(false);
          return;
        });
    }
}
