import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services';
import { maskBankAccount } from 'src/app/utils/shared';
import { TermsData } from 'src/app/click-sing/models';

@Injectable({
    providedIn: 'root'
})
export class ClickSingService {
    leaseTermsData: TermsData = {
        accountNumbers: 5678,
        basePayment: 391.22,
        docFee: 399,
        leaseAmount: 15000,
        leaseTerm: 48,
        taxPayment: 26.19,
        totalPayment: 400.01
    };
    leaseTaxIncluded = false;
    offers: any = null;

    ownerId : any = null;

    constructor(
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService,
        private connectionService: ConnectionService,
    ) { }

    get formattedConnectedAccount(): TermsData {
        const data = this.leaseTermsData;

        if (data) {
            data.accountNumbers = maskBankAccount(`${this.leaseTermsData.bankAccountNumber}`);
            data.basePayment = this.leaseTermsData.totalPayment;
            data.docFee = this.leaseTermsData.docFee;
            data.leaseAmount = this.leaseTermsData.leaseAmount;
            data.leaseTerm = this.leaseTermsData.term;
            data.taxPayment = this.leaseTermsData.monthlyPayment - this.leaseTermsData.totalPayment;
            data.totalPayment = this.leaseTermsData.monthlyPayment;
        }

        return data;
    }

    get isClicksing(): boolean {
        const indicator = this.router.url.toLowerCase().indexOf('clicksign'.toLowerCase());
        let isClicksing = false

        if (indicator !== -1) isClicksing = true;

        return isClicksing;
    }

    get isDevelopment(): boolean {
        return environment.development;
    }

    get isProduction(): boolean {
        return environment.production;
    }

    get vendorReferrerName() {
        return this.authService.vendorReferrerName
    }

    async getOffers(applicationId, access_token) {
        try {
            const response = await this.connectionService.getV3RequestP({
                url: `${environment.gatewayV3}offerservice/api/getActiveOffers/${applicationId}?addTaxes=true`,
                contentType: 'application/json',
                access_token
            });
            this.offers = response.object;
            this.leaseTaxIncluded = response.object.isTaxIncluded;

            return response;
        } catch (err) { throw err; }
    }

    async sendAgreement(applicationId, vendor_token) {
        try {
            const response = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/sendAgreementOtp/${applicationId}`,
                contentType: 'application/json',
                postData: {},
                vendor_token,
            });

            return response;
        } catch (err) { return err }
    }

    async getConfirmPage(applicationId, access_token) {
        try {
            const data = await this.connectionService.getV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/getHeadersConfirmationPage/${applicationId}`,
                contentType: 'application/json',
                access_token,
            });

            if (data && data.object) this.leaseTermsData = data.object;

            return data;
        } catch (err) { throw err; }
    }

    async selectOffer(applicationId, selected, access_token?, vendor_token?) {
        try {
            const data = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}offerservice/api/selectOffer/${applicationId}/${selected}`,
                postData: {},
                contentType: 'application/json',
                access_token,
                vendor_token
            });

            return data;
        } catch (err) { throw err; }
    }

    async signDocument(applicationId, access_token, vendor_token) {
        try {
            this.googleAnalyticsService.trackClickSignEvent(
                'confirm-terms-continue',
                this.vendorReferrerName,
                applicationId
            );

            const data = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}docusignservice/api/signDocusign/${applicationId}`,
                contentType: 'application/json',
                postData: {},
                access_token,
                vendor_token
            });   
            
            this.ownerId = data.object.ownerId;

            return data;

        } catch (err) { throw err; }
    }

    async kbaDocusignFailed (applicationId, access_token, vendor_token) {
        try {
            const data = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}docusignservice/api/registerDocusignAuthenticationError/${applicationId}?ownerId=${this.ownerId}`,
                postData: {},
                contentType: 'application/json',
                access_token,
                vendor_token
            });
            return data;
        } catch(err) { throw err; }
    }

    async retryDocusignKba(applicationId, access_token, vendor_token) {
        try {
            const data = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}docusignservice/api/signDocusign/${applicationId}`,
                postData: {},
                contentType: 'application/json',
                access_token,
                vendor_token
            });
            return data;
        } catch (err) { throw err; }
    }

    async validateAgreement(applicationId, otp) {
        try {
            const data = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/open/validateOtpAgreement/${applicationId}?otp=${otp}`,
                postData: {},
                contentType: 'application/json'
            });

            return data;
        } catch (err) { throw err; }
    }

    async validateTokenOffert(token) {
        try {
            const data = await this.connectionService.postCustomRequestP({
                url: `${environment.gateway}validateTokenForOffer`,
                postData: {},
                contentType: 'application/json',
                tokenType: 'token',
                token,
            });

            return data;
        } catch (err) { return err; }
    }
}