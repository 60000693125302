<app-header></app-header>
<div class="body-container">
    <div class="row">
        <div class="col-12 models-list">
            <div class="row decision-model-title-container">
                <h1 class="decision-title font-mont-regular">Term Adjustment</h1>
                <button class="btn btn-secondary font-mont-regular float-right" type="submit"
                    id="createNewPricingAdjustmentOpenDlgBtn" (click)="onCreateNewAdjustmentSubmit()">
                    <div class="tooltip-two">
                        <div class="plus-area">
                            <em class="fas fa-plus"></em>
                        </div>
                        <span class="tooltip-two-text">
                            Click here to add a new term adjustment.
                        </span>
                    </div>
                </button>
            </div>
            <div class="form-row">
                <table aria-label="Adjustments" class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center" (click)="onSortClick('name')">
                                    Name
                                </div>
                                <em class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'name'"></em>
                                <em class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'name'"></em>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center" (click)="onSortClick('active')">
                                    Status
                                </div>
                                <em class="fas fa-filter filter-icon" [ngClass]="{'v-center': sortField != 'active'}"
                                    (click)="toggleStatusFilter()"></em>
                                <em class="fas fa-caret-up caret-up-icon"
                                    *ngIf="!invertedSort && sortField == 'active'"></em>
                                <em class="fas fa-caret-down caret-down-icon"
                                    *ngIf="invertedSort && sortField == 'active'"></em>
                                <div class="apps-menu" [ngClass]="{'open': statusFilterOpen}"
                                    (click)="toggleStatusFilter()">
                                    <div class="backdrop"></div>
                                    <div class="apps-menu-container">
                                        <div (click)="onStatusCheckClick('active', '');">
                                            <em *ngIf="filterValue == ''" class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != ''" class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> All </span>
                                            <div class="clear"></div>
                                        </div>
                                        <div (click)="onStatusCheckClick('active', 'true');">
                                            <em *ngIf="filterValue == 'true'" class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != 'true'" class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> Active </span>
                                            <div class="clear"></div>
                                        </div>
                                        <div (click)="onStatusCheckClick('active', 'false');">
                                            <em *ngIf="filterValue == 'false'" class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != 'false'" class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> Inactive </span>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20" (click)="onSortClick('datarobotName')">
                                    Datarobot
                                </div>
                                <div class="font-mont-semi-bold margin-r-20" (click)="onSortClick('datarobotName')">
                                    Model
                                </div>
                                <em class="fas fa-filter filter-icon"
                                    [ngClass]="{'v-center': sortField != 'datarobotName'}"
                                    (click)="toggleDataRobotModelFilter()"></em>
                                <em class="fas fa-caret-up caret-up-icon"
                                    *ngIf="!invertedSort && sortField == 'datarobotName'"></em>
                                <em class="fas fa-caret-down caret-down-icon"
                                    *ngIf="invertedSort && sortField == 'datarobotName'"></em>
                                <div class="apps-menu" [ngClass]="{'open': dataRobotModelFilterOpen}"
                                    (click)="toggleDataRobotModelFilter()">
                                    <div class="backdrop"></div>
                                    <div class="apps-menu-container">
                                        <div (click)="onStatusCheckClick('datarobotName', '');">
                                            <em *ngIf="filterValue == ''" class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != ''" class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> All </span>
                                            <div class="clear"></div>
                                        </div>
                                        <div *ngFor="let dataRobotModel of dataRobotModels"
                                            (click)="onStatusCheckClick('datarobotName', dataRobotModel.description);">
                                            <em *ngIf="filterValue == dataRobotModel.description"
                                                class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != dataRobotModel.description"
                                                class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> {{dataRobotModel.description}} </span>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center" (click)="onSortClick('priority')">
                                    Priority
                                </div>
                                <em class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'priority'"></em>
                                <em class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'priority'"></em>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center"
                                    (click)="onSortClick('minimumTerm')">
                                    Minimum Term
                                </div>
                                <em class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'minimumTerm'"></em>
                                <em class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'minimumTerm'"></em>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center"
                                    (click)="onSortClick('middleTerm')">
                                    Middle Term
                                </div>
                                <em class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'middleTerm'"></em>
                                <em class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'middleTerm'"></em>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center" (click)="onSortClick('maxTerm')">
                                    Max Term
                                </div>
                                <em class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'maxTerm'"></em>
                                <em class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'maxTerm'"></em>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let adjustment of adjustments | filter:filter:filterValue | sort:sortField:invertedSort; let i = index">
                            <tr (click)="toggleSelectedRow(i, adjustment.id);" class="row-data cursor-pointer"
                                [ngClass]="{'selected': selectedRow == i}" [attr.id]="'itemNumber_' + i">
                                <td>{{adjustment.name}}</td>
                                <td class="text-center">{{adjustment.active ? 'Active' : 'Inactive'}}</td>
                                <td class="text-center">{{adjustment.datarobotName}}</td>
                                <td class="text-center">{{adjustment.priority}}</td>
                                <td class="text-center">{{adjustment.minimumTerm}}</td>
                                <td class="text-center">{{adjustment.middleTerm}}</td>
                                <td class="text-center">{{adjustment.maxTerm}}</td>
                            </tr>
                            <tr class="no-highlight summary" [ngClass]="{'selected': selectedRow == i}">
                                <td colspan="7">
                                    <div *ngIf="tempSelectedRow == i">
                                        <app-term-item [adjustmentId]="adjustmentId" [attributes]="attributes"
                                            [dataRobotModels]="dataRobotModels" [operators]="operators"
                                            (itemLoaded)="onItemLoaded();"
                                            (getAdjustmentsList)="getAdjustmentsList($event);"
                                            (showErrorMessage)="showErrorMessage($event);">
                                        </app-term-item>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 attibute-list">
        </div>
    </div>
</div>

<div class="modal fade" id="addNewAdjustmentModal" tabindex="-1" role="dialog" aria-labelledby="addNewAdjustmentModal"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img alt="Close" src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="adjustmentModalLongTitle">
                    <img alt="Add new" src="/assets/images/icons/add_blue.svg" />
                    Add new term adjustment
                </h5>
                <form [formGroup]="newAdjustmentForm" (ngSubmit)="onCreateSubmit();">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="newAdjustmentName"
                                        [ngClass]="{ 'color-red': addNewAdjustmentSubmitted && newAdjustmentForm.controls.name.errors }">Name:
                                        *</label>
                                    <input type="text" formControlName="name" class="form-control"
                                        id="newAdjustmentName" placeholder="Name" name="name"
                                        [ngClass]="{ 'is-invalid': addNewAdjustmentSubmitted && newAdjustmentForm.controls.name.errors }">
                                    <div *ngIf="addNewAdjustmentSubmitted && newAdjustmentForm.controls.name.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newAdjustmentForm.controls.name.errors.required">
                                            Name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="newAdjustmentDescription"
                                        [ngClass]="{ 'color-red': addNewAdjustmentSubmitted && newAdjustmentForm.controls.description.errors }">Description:
                                        *</label>
                                    <textarea formControlName="description" class="form-control"
                                        id="newAdjustmentDescription" rows="4" placeholder="Description"
                                        name="description"
                                        [ngClass]="{ 'is-invalid': addNewAdjustmentSubmitted && newAdjustmentForm.controls.description.errors }"></textarea>
                                    <div *ngIf="addNewAdjustmentSubmitted && newAdjustmentForm.controls.description.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newAdjustmentForm.controls.description.errors.required">
                                            Description is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right">
                                Add adjustment
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageBClass]="messageMessageBClass" [dialogClass]="dialogClass"></app-error-dialog>