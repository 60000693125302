import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Userpilot } from 'userpilot';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.authService.validateSession()
                .then((data) => {
                    Userpilot.reload();
                    window.scroll(0, 0);
                    resolve(true);
                })
                .catch((error) => {
                    this.router.navigate(['signin']);
                    reject(false);
                });
        });
    }
}
