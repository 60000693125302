import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { StartedFormService } from 'src/app/application/v3/services';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-otp-modal',
    templateUrl: './otp-modal.component.html',
    styleUrls: ['./otp-modal.component.css']
})
export class OTPModalComponent {
    @Output() closeModal = new EventEmitter<boolean>();
    @Output() verifyActionOTP = new EventEmitter<any>();
    @Output() reApplyAction = new EventEmitter<void>();
    @Input() usaPhoneNumber: string;
    @Input() leadId: string;
    @Input() otpRose: boolean;
    @Input() started: boolean;
    @Input() useService: any;
    @ViewChild("divView") divView: ElementRef;

    approvalExpired: boolean = false;
    requestedAmount: string;
    invalidCode: boolean = false;
    private clickProccess: boolean = false;

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService,
        private startedService: StartedFormService,
    ) { 
    }

    onCloseModal() {
        this.closeModal.emit(false);
    }

    invalidCodeChange($event) {
        this.invalidCode = $event;
    }

    OTPData($event) {
        if (this.clickProccess) {
            return;
        }
        let phoneNumber;
        if (this.usaPhoneNumber) {
            phoneNumber = this.usaPhoneNumber;
        } else phoneNumber = this.startedService.getControlValue('mobilePhoneNumber');

        if (!$event || !phoneNumber || $event.length < 4) {
            this.googleAnalyticsService.trackV3Event(
                'verify-code-error',
                this.authService.vendorReferrerName,
                'input-error'
            );
            this.invalidCode = true;
            return;
        }

        this.invalidCode = false;
        this.clickProccess = true;

        if (this.usaPhoneNumber && !this.leadId) {
            this.googleAnalyticsService.trackClickSignEvent(
                'verify-code-clicked',
                this.authService.vendorReferrerName,
                'OTP'
            );
        } else {
            this.googleAnalyticsService.trackV3Event(
                'verify-code-clicked',
                this.authService.vendorReferrerName,
                'OTP'
            );
        }

        this.verifyActionOTP.emit({
            otp: $event,
            callback: this.onResponse.bind(this)
        });
    }

    onResponse(response: any) {
        if (response) {
            this.invalidCode = response.invalidCode ? true : false;
            this.clickProccess = false;
            this.approvalExpired = response.approvalExpired ? true : false;
            this.requestedAmount = response.requestedAmount ? response.requestedAmount : 0;
        }
    }

    onContinue() {
        this.googleAnalyticsService.trackV3Event(
            're-apply-clicked',
            this.authService.vendorReferrerName,
            'payments-otp'
        );
        this.reApplyAction.emit();
    }

}
