<app-application-wrapper [subTitleA]="'LEASE AGREEMENT'" [subTitleB]="'STEP 2 OF 4'">
  <ng-template appApplicationWrapperHelper>
    <div class="bank-account-title font-mont-bold">Bank Account</div>
    <div class="bank-account-indication font-mont-regular">Confirm the bank account you’ll use to make your payments.
      ACH (Checking Account) is required for all transactions.
    </div>
    <!--*ngIf="conectedAccounts.hasExtraGiactAttempt"-->
    <span *ngIf="!edit" class="edit" (click)="editNav()">
      <a class="font-mont-bold">Edit</a>
      <img class="edit" src="/assets/images/icons/editAccount.svg" alt="EditAccount">
    </span>
    <div *ngIf="conectedAccounts" class="account">
      <div class="build-icon">
        <img src="/assets/images/icons/building.svg" alt="account">
      </div>
      <div class="account-info">
        <span class="account-title font-mont-bold">{{conectedAccounts.bankName}}</span>
        <span class="account-number font-mont-regular"> Account *******{{conectedAccounts.bankAccountNumber}}</span>
      </div>
      <div class="check">
        <input checked type="radio" id="accountCheck" name="account" class="accountCheck"
          [value]="'conectedAccounts.bankName'">
      </div>
    </div>
    <span class="font-mont-regular terms">
      <app-read-more [textToDisplay]="disclosureText"></app-read-more>
    </span>
    <button class="continue font-mont-bold" (click)="onSelectedAccount()">Continue</button>
    <button class="back font-mont-regular" (click)="back()">Go Back</button>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="/assets/images/slc-bank-account.svg" alt="">
  </ng-container>
</app-application-wrapper>