<app-header></app-header>
<div class="body-container clearfix">
    <div class="row">
        <div class="col-xl-12 main-container">
            <h2>ClickLease</h2>
            <h4>This software uses these libraries</h4>
            <div class="row">
                <div class="content col-md-4">
                    <a href="https://github.com/angular-ui/ui-router/blob/master/LICENSE">@uirouter/angularjs</a><br />
                    <a href="https://github.com/angular/angular.js/blob/master/LICENSE">angular.js</a><br />
                    <a href="https://github.com/angular/angular.js/blob/master/LICENSE">angular-animate</a><br />
                    <a href="https://github.com/angular/angular.js/blob/master/LICENSE">angular-aria</a><br />
                    <a href="https://github.com/jtblin/angular-chart.js/blob/master/LICENSE">angular-chart.js</a><br />
                    <a href="https://github.com/angular/material/blob/master/LICENSE">angular-material</a><br />
                    <a href="https://github.com/angular/angular.js/blob/master/LICENSE">angular-messages</a><br />
                    <a href="https://github.com/angular/angular.js/blob/master/LICENSE">angular-sanitize</a><br />
                    <a
                        href="https://github.com/angular-translate/angular-translate/blob/master/LICENSE">angular-translate</a><br />
                    <a href="https://github.com/angular-ui/bootstrap/blob/master/LICENSE">angular-ui-bootstrap</a><br />
                    <a href="https://github.com/angular-ui/ui-mask/blob/master/LICENSE">angular-ui-mask</a><br />
                    <a href="https://github.com/twbs/bootstrap/blob/v4-dev/LICENSE">bootstrap</a><br />
                    <a href="https://github.com/BrowserSync/browser-sync/blob/master/LICENSE">browser-sync</a><br />
                    <a href="https://github.com/chartjs/Chart.js/blob/master/LICENSE.md">chart.js</a><br />
                </div>
                <div class="content col-md-4">
                    <a href="https://github.com/sindresorhus/del/blob/master/license">del</a><br />
                    <a href="https://github.com/eslint/eslint/blob/master/LICENSE">eslint</a><br />
                    <a href="https://github.com/FortAwesome/Font-Awesome">font-awesome</a><br />
                    <a href="https://github.com/gulpjs/gulp/blob/master/LICENSE">gulp</a><br />
                    <a
                        href="https://github.com/deepak1556/gulp-browserify/blob/master/LICENSE">gulp-browserify</a><br />
                    <a href="https://github.com/gulp-community/gulp-concat/blob/master/LICENSE">gulp-concat</a><br />
                    <a
                        href="https://github.com/johnturingan/gulp-html-to-json/blob/master/LICENSE">gulp-html-to-json</a><br />
                    <a href="https://github.com/jonschlinkert/gulp-htmlmin/blob/master/LICENSE">gulp-htmlmin</a><br />
                    <a href="https://github.com/slushjs/gulp-install/blob/master/LICENSE">gulp-install</a><br />
                    <a href="https://github.com/spalger/gulp-jshint/blob/master/LICENSE">gulp-jshint</a><br />
                    <a href="https://github.com/vohof/gulp-livereload#license">gulp-livereload</a><br />
                    <a href="https://github.com/hustxiaoc/gulp-minify/blob/master/LICENSE">gulp-minify</a><br />
                    <a href="https://github.com/pioug/gulp-preprocess/blob/master/LICENSE.md">gulp-preprocess</a><br />
                    <a href="https://github.com/hparra/gulp-rename/blob/master/LICENSE">gulp-rename</a><br />
                </div>
                <div class="content col-md-4">
                    <a href="https://github.com/lazd/gulp-replace/blob/master/LICENSE">gulp-replace</a><br />
                    <a
                        href="https://github.com/sindresorhus/gulp-ruby-sass/blob/master/license">gulp-ruby-sass</a><br />
                    <a href="https://github.com/terinjokes/gulp-uglify">gulp-uglify</a><br />
                    <a href="https://github.com/ubirak/gulp-uglifycss/blob/master/LICENSE">gulp-uglifycss</a><br />
                    <a href="https://github.com/gulpjs/gulp-util/blob/master/LICENSE">gulp-util</a><br />
                    <a href="https://github.com/floatdrop/gulp-watch/blob/master/LICENSE">gulp-watch</a><br />
                    <a href="https://github.com/jquery/jquery/blob/master/LICENSE.txt">jquery</a><br />
                    <a href="https://github.com/jshint/jshint/blob/master/LICENSE">jshint</a><br />
                    <a href="https://github.com/dominictarr/map-stream/blob/master/LICENCE">map-stream</a><br />
                    <a href="https://github.com/asafdav/ng-csv/blob/master/LICENSE">ng-csv</a><br />
                    <a href="https://github.com/textAngular/textAngular/blob/master/README.md">textangular</a><br />
                    <a href="https://github.com/faisalman/ua-parser-js/blob/master/license.md">ua-parser-js</a><br />
                    <a href="https://github.com/yargs/yargs/blob/master/LICENSE">yargs</a><br />
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>