import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthTokenParamGuard implements CanActivate {
    public token: any;
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        this.token = route.params.token;
        this.authService.setQueryToken(this.token);
        return new Promise((resolve, reject) => {
            this.authService.validateToken(this.token)
                .then((data: any) => {
                    if (data.msg == 'Valid token.') {
                        route.data = { object: data.object };
                        this.authService.getVendorTokenInfo(this.token).then((result: any) => {
                            window.scroll(0, 0);
                            resolve(true);
                        }).catch((error) => {
                            console.log(error);
                            window.scroll(0, 0);
                            resolve(true);
                        });
                    } else {
                        this.router.navigate(['/token-error-v3'], { state: { token: route.params.token } });
                        reject(false);
                    }
                })
                .catch((error) => {
                    this.router.navigate(['/token-error-v3'], { state: { token: route.params.token } });
                    reject(false);
                });
        });
    }
}
