import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { BankProcessService } from 'src/app/bank-process';

@Component({
    selector: 'app-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent implements OnInit {
    private access_token: string = null;
    private applicationId: number = null;
    private token: any = null;
    conectedAccounts: any = null;
    index = 0;
    submitted = false;
    edit = false;

    public disclosureText: string = `By clicking "continue", I authorize Clicklease LLC and it’s assigns and the` +
        ` financial institution named above to initiate debits and credits from my business checking account for any` +
        ` and all amounts due or payable under the Equipment Lease Agreement (full terms provided at contract signing).` +
        ` This authority will remain in effect until I notify Clicklease LLC in writing to cancel it in such time as to afford the financial institution a reasonable opportunity to act on it.`;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private bankProcessService: BankProcessService,
    ) {
        const navigation = this.router.getCurrentNavigation();

        if (navigation.extras.state) {
            this.access_token = navigation.extras.state.accessToken;
            this.applicationId = navigation.extras.state.appId;
            this.token = navigation.extras.state.token;
        }
    }

    get isClicksign() {
        return this.bankProcessService.isClicksing;
    }

    private redirectToBankInfo(data) {
        this.googleAnalyticsService.trackClickSignEvent(
            'bank-account-no',
            this.bankProcessService.vendorReferrerName,
            JSON.stringify(data)
        );

        this.router.navigate(['../bank-information'], {
            relativeTo: this.route,
            replaceUrl: true,
            queryParamsHandling: "merge",
            state: {
                token: this.token,
                appId: this.applicationId,
                accessToken: this.access_token
            }
        });
    }

    private redirectToConfirm(data) {
        if (data && data.object) {

            this.googleAnalyticsService.trackClickSignEvent(
                'continue-to-response',
                this.bankProcessService.vendorReferrerName,
                JSON.stringify(data)
            );

            this.router.navigate(['../../contract-signing'], {
                relativeTo: this.route,
                queryParamsHandling: "merge",
                state: {
                    appId: this.applicationId,
                    accessToken: this.access_token,
                    token: this.token
                }
            });

        } else this.errorListService.redirectToV3Error(
            data,
            'getHeadersConfirmationPage',
            'click-sign-otp'
        );
    }

    ngOnInit(): void {
        this.googleAnalyticsService.trackClickSignEvent(
            'bank-account-open',
            this.bankProcessService.vendorReferrerName,
            'bank account open'
        );

        this.bankProcessService.prefillBankAccountInfo(this.applicationId, this.access_token)
            .then(response => {
                if (response != null && response.bankAccountNumber) {

                    this.googleAnalyticsService.trackClickSignEvent(
                        'bank-account-yes',
                        this.bankProcessService.vendorReferrerName,
                        JSON.stringify(response)
                    );
                    this.conectedAccounts = response;
                    this.edit = response.riskClassification == 'HIGH';
                } else this.redirectToBankInfo(response);
            })
            .catch(err => this.redirectToBankInfo(err));
    }

    back() {
        this.googleAnalyticsService.trackClickSignEvent(
            'bank-account-back',
            this.bankProcessService.vendorReferrerName,
            this.applicationId.toString()
        );

        window.history.back()
    }

    editNav() {
        this.googleAnalyticsService.trackClickSignEvent(
            'bank-account-edit',
            this.bankProcessService.vendorReferrerName,
            'Bank accont edit tag clicked'
        );

        this.router.navigate(['../bank-information'], {
            relativeTo: this.route,
            queryParamsHandling: "merge",
            state: {
                token: this.token,
                appId: this.applicationId,
                accessToken: this.access_token
            }
        });
    }

    onSelectedAccount() {
        if (this.submitted) {
            return;
        }

        this.submitted = true;

        this.googleAnalyticsService.trackClickSignEvent(
            'bank-account-SelectedAccount',
            this.bankProcessService.vendorReferrerName,
            this.applicationId.toString()
        );

        this.bankProcessService.getConfirmPage(this.applicationId, this.access_token)
            .then(innerResponse => this.redirectToConfirm(innerResponse))
            .catch(innerError => this.errorListService.redirectToV3Error(
                innerError,
                'getHeadersConfirmationPage',
                'click-sign-otp'
            ));
    }
}
