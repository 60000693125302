import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../../analytics/google.analytics.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public impersonatedUser: any = null;

  constructor(private authService: AuthService, private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.googleAnalyticsService.trackEvent("app-component", "app-footer");
  }

  isImpersonating() {
    this.impersonatedUser = this.authService.getImpersonatedUser();
    return this.impersonatedUser;
  }

}
