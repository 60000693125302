import { PricingAdjustmentConditionItem } from './pricing.pricing.condition';

export class PricingAdjustmentItem {
  id: number;
  name: string;
  description: string;
  active: boolean;
  logic: string;
  adjustment: number;
  dataRobotModelId: number;
  logicType: string;
  conditions: PricingAdjustmentConditionItem[];
  datarobotName: string;

  constructor(data: any, attributes: any, opperators: any, datarobotmodels: any) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.active = data.active;
    this.logic = data.logic;
    this.adjustment = data.adjustment;
    this.dataRobotModelId = data.dataRobotModelId;
    this.logicType = data.logicType;
    this.conditions = [];

    datarobotmodels.forEach((element: any) => {
      if (element.id === this.dataRobotModelId) {
        if (this.dataRobotModelId === 0) {
          this.datarobotName = "All Models";
        } else {
          this.datarobotName = element.description;
        }
      }
    });

    data.conditions.forEach((element: any) => {
      this.conditions.push(new PricingAdjustmentConditionItem(element, attributes, opperators));
    });
  }
}