<div class="row align-items-end">
    <form [formGroup]="vendorForm" (ngSubmit)="onEditVendor();">
        <div class="row">
            <div class="col-12">
                <div class="form-section">
                    <div class="form-section-title font-mont-bold">
                        Required Information:
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <label for="dbaName" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.dbaName.errors }">DBA
                                Name:*</label>
                            <input [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.dbaName.errors }"
                                id="dbaName" type="text" formControlName="dbaName" class="form-control"
                                placeholder="dbaName" name="dbaName">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.dbaName.errors" class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.dbaName.errors.required">
                                    DBA Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="accountName" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.accountName.errors }">Account
                                Name:*</label>
                            <input
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.accountName.errors }"
                                id="accountName" type="text" formControlName="accountName" class="form-control"
                                placeholder="Account Name" name="accountName">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.accountName.errors"
                                class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.accountName.errors.required">
                                    Account Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="email" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.email.errors }">Email:*</label>
                            <input [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.email.errors }"
                                id="email" type="text" formControlName="email" class="form-control" placeholder="Email"
                                name="email">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.email.errors" class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.email.errors.required">
                                    Email is required.
                                </div>
                                <div *ngIf="vendorForm.controls.email.errors.email">
                                    Email format error.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="phone" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.phone.errors }">Phone:*</label>
                            <input [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.phone.errors }"
                                id="phone" type="text" formControlName="phone" class="form-control" placeholder="Phone"
                                name="phone" [showMaskTyped]="true" mask="(000) 000-0000">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.phone.errors" class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.phone.errors.required">
                                    Phone is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <label for="entityType" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.entityType.errors }">Entity
                                Type: *</label>
                            <select formControlName="entityType" class="form-control" id="entityType" name="entityType"
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.entityType.errors }">
                                <option *ngFor="let businessType of businessTypes" [ngValue]="businessType.code">
                                    {{businessType.name}}</option>
                            </select>
                            <div *ngIf="vendorSubmitted && vendorForm.controls.entityType.errors"
                                class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.entityType.errors.required">
                                    Entity Type is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="federalTaxId" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.federalTaxId.errors }">Federal
                                Tax Id:*</label>
                            <input
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.federalTaxId.errors }"
                                id="federalTaxId" type="text" formControlName="federalTaxId" class="form-control"
                                placeholder="99-9999999" name="federalTaxId" [showMaskTyped]="true" mask="00-0000000">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.federalTaxId.errors"
                                class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.federalTaxId.errors.required">
                                    Federal Tax Id is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="equipmentClassification" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.equipmentClassification.errors }">
                                Equipment Classification: *</label>
                            <select formControlName="equipmentClassification" class="form-control"
                                id="equipmentClassification"
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.equipmentClassification.errors }">
                                <option *ngFor="let equipmentClassification of equipmentClassifications"
                                    [ngValue]="equipmentClassification">{{equipmentClassification}}
                                </option>
                            </select>
                            <div *ngIf="vendorSubmitted && vendorForm.controls.equipmentClassification.errors"
                                class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.equipmentClassification.errors.required">
                                    equipmentClassification is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="industry" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.industry.errors }">Industry:
                                *</label>
                            <select formControlName="industry" class="form-control" id="industry"
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.industry.errors }">
                                <option *ngFor="let industry of industries" [ngValue]="industry.code">{{industry.name}}
                                </option>
                            </select>
                            <div *ngIf="vendorSubmitted && vendorForm.controls.industry.errors"
                                class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.industry.errors.required">
                                    Industry is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-title font-mont-bold">
                        Address Information:
                    </div>
                    <div class="form-row">
                        <div class="form-group col-3">
                            <label for="address" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.address.errors }">Address:*</label>
                            <input [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.address.errors }"
                                id="address" type="text" formControlName="address" class="form-control"
                                placeholder="Address" name="address">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.address.errors" class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.address.errors.required">
                                    Address is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="zipCode" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.zipCode.errors }">Zip
                                Code:*</label>
                            <input [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.zipCode.errors }"
                                id="zipCode" type="text" formControlName="zipCode" class="form-control"
                                placeholder="Zip Code" name="zipCode" (keyup)="onZipCodeKeyUp()">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.zipCode.errors" class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.zipCode.errors.required">
                                    Zip Code is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="state" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.state.errors }">State:
                                *</label>
                            <select formControlName="state" class="form-control" id="state"
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.state.errors }">
                                <option *ngFor="let state of statesOfIncorporation" [ngValue]="state.code">
                                    {{state.name}}</option>
                            </select>
                            <div *ngIf="vendorSubmitted && vendorForm.controls.state.errors" class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.state.errors.required">
                                    State is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label for="city" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.city.errors }">City:
                                *</label>
                            <select formControlName="city" class="form-control" id="city"
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.city.errors }">
                                <option *ngFor="let city of cities" [ngValue]="city">{{city}}</option>
                            </select>
                            <div *ngIf="vendorSubmitted && vendorForm.controls.city.errors" class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.city.errors.required">
                                    City is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-title font-mont-bold">
                        Other Information:
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label for="defaultSalesperson" class="col-form-label">Default Sales Person:</label>
                            <select formControlName="defaultSalespersonId" class="form-control" id="defaultSalesperson"
                                name="defaultSalespersonId" #defaultSalespersonId>
                                <option *ngFor="let salesPerson of vendor.spNameList" [ngValue]="salesPerson.id">
                                    {{salesPerson.firstName}} {{salesPerson.middleName}} {{salesPerson.lastName}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-4">
                            <label for="secondaryContactEmail" class="col-form-label"
                                [ngClass]="{ 'color-red': vendorSubmitted && vendorForm.controls.secondaryContactEmail.errors }">Secondary
                                Contact Email:</label>
                            <input
                                [ngClass]="{ 'is-invalid': vendorSubmitted && vendorForm.controls.secondaryContactEmail.errors }"
                                id="secondaryContactEmail" type="text" formControlName="secondaryContactEmail"
                                class="form-control" placeholder="Secondary Contact Email" name="secondaryContactEmail">
                            <div *ngIf="vendorSubmitted && vendorForm.controls.secondaryContactEmail.errors"
                                class="invalid-feedback">
                                <div *ngIf="vendorForm.controls.secondaryContactEmail.errors.email">
                                    Email format error.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-4">
                            <div class="toggle-wrapper">
                                <div class="toggle checkcross">
                                    <input id="excludeLessee" type="checkbox" formControlName="excludeLessee" />
                                    <label class="toggle-item" for="excludeLessee">
                                        <div class="check"></div>
                                    </label>
                                </div>
                                <div class="name">Exclude Lessee</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-section button">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <button class="btn btn-primary float-right" type="submit" data-toggle="modal"
                                id="edit-vendor-button">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="clear"></div>
<div class="form-section">
    <div class="form-section-title font-mont-bold">
        Sales People:
    </div>
    <table class="sales-people table">
        <thead>
            <tr>
                <th scope="col" class="font-mont-semi-bold">ID</th>
                <th scope="col" class="text-center font-mont-semi-bold">Name</th>
                <th scope="col" class="text-center font-mont-semi-bold">Edit</th>
            </tr>
        </thead>
        <tbody *ngIf="vendor.spNameList && vendor.spNameList.length > 0">
            <tr *ngFor="let salesPerson of vendor.spNameList" class="row-data">
                <td scope="row">{{salesPerson.id}}</td>
                <td class="text-center">{{salesPerson.firstName}} {{salesPerson.middleName}} {{salesPerson.lastName}}
                </td>
                <td class="text-center cursor-poiner" (click)="onCreateNewAttributeSubmit(salesPerson.id);"
                    id="remove-attribute-link">
                    <i class="fas fa-user-edit"></i>
                </td>
            </tr>
        </tbody>
    </table>
    <form (ngSubmit)="onCreateNewAttributeSubmit()">
        <button class="btn btn-primary float-right" type="submit" data-toggle="modal"
            data-target="#createAttributeModal" id="create-attribute-button">
            Add Sales Person
        </button>
    </form>
    <div class="clear"></div>
</div>

<div class="modal fade" id="createAttributeModal" tabindex="-1" role="dialog" aria-labelledby="createAttributeModal"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                    <img src="/assets/images/icons/add_blue.svg" />
                    {{salesPersonForm.controls.id.value ? 'Edit sales person' : 'Create new sales person'}}
                </h5>
                <form [formGroup]="salesPersonForm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="sFirstName"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && salesPersonForm.controls.firstName.errors }">First
                                        Name:
                                        *</label>
                                    <input type="text" id="sFirstName" class="form-control" formControlName="firstName"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && salesPersonForm.controls.firstName.errors }"
                                        placeholder="firstName">
                                    <div *ngIf="addNewVendorSubmitted && salesPersonForm.controls.firstName.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="salesPersonForm.controls.firstName.errors.required">
                                            First Name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="sLastName"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && salesPersonForm.controls.lastName.errors }">Last
                                        Name:
                                        *</label>
                                    <input type="text" id="sLastName" class="form-control" formControlName="lastName"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && salesPersonForm.controls.lastName.errors }"
                                        placeholder="lastName">
                                    <div *ngIf="addNewVendorSubmitted && salesPersonForm.controls.lastName.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="salesPersonForm.controls.lastName.errors.required">
                                            Last Name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="sEmail"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && salesPersonForm.controls.email.errors }">Email:
                                        *</label>
                                    <input type="text" id="sEmail" class="form-control" formControlName="email"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && salesPersonForm.controls.email.errors }"
                                        placeholder="Email">
                                    <div *ngIf="addNewVendorSubmitted && salesPersonForm.controls.email.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="salesPersonForm.controls.email.errors.required">
                                            Email is required.
                                        </div>
                                        <div *ngIf="salesPersonForm.controls.email.errors.email">
                                            Email format error.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="sContactPhone"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && salesPersonForm.controls.contactPhone.errors }">Mobile
                                        #: *</label>
                                    <input type="text" id="sContactPhone" class="form-control"
                                        formControlName="contactPhone"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && salesPersonForm.controls.contactPhone.errors }"
                                        placeholder="Contact Phone" [showMaskTyped]="true" mask="(000) 000-0000">
                                    <div *ngIf="addNewVendorSubmitted && salesPersonForm.controls.contactPhone.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="salesPersonForm.controls.contactPhone.errors.required">
                                            Mobile number is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" id="createAttributeCancelButton"
                                class="btn btn-secondary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right"
                                data-dismiss="modal">CANCEL</button>
                            <button type="submit" id="createAttributeCreateButton"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right"
                                (click)="onCreateClick()">{{salesPersonForm.controls.id.value ? 'EDIT' :
                                'CREATE'}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>