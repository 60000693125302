<app-application-wrapper [subTitleA]="'LEASE AGREEMENT'" [subTitleB]="'FINAL STEP'">
  <ng-template appApplicationWrapperHelper>
    <div class="drivers-license-title font-mont-bold">Almost done!</div>
    <div class="upload-license-subtitle font-mont-regular">Please upload your drivers license using the form below.
    </div>
    <div class="drivers-license-subtitle font-mont-bold">DRIVERS LICENSE</div>
    <div class="drivers-upload-container">
      <div>
        <div class="font-mont-bold">Front</div>
        <div class="drop-zone-box" [ngClass]="{'file-over': hasDropOverLeft,'errorFile':isErrorLeft}" ng2FileDrop
          [uploader]="frontUpload" (onFileDrop)="handleFiles($event, false)" (fileOver)="fileOverBase($event)">
          <img [src]="frontImageUrl ? frontImageUrl : '/assets/images/icons/file-upload-line.svg'" />
          <img [src]="frontImageUrl ? frontImageUrl : '/assets/images/icons/file-upload-line-2.svg'" />
          <div *ngIf="leftInputFile" class="drivers-name font-mont-bold">
            {{ leftInputFile ? leftInputFile.name : ''}}
          </div>
          <input *ngIf="!leftInputFile" accept=".jpg,.png,.pdf" type="file"
            (onFileSelected)="handleFiles($event,false)" ng2FileSelect [uploader]="frontUpload" />
          <div *ngIf="leftInputFile" class="remove-image-background" (click)="removeLeftFiles()">Remove</div>
          <div *ngIf="!leftInputFile" class="drag-and-drop-details font-mont-semi-bold">Drag and drop here or <span
              class="font-mont-semi-bold">select file</span></div>
          <div *ngIf="!leftInputFile" class="drag-and-drop-details-mobile font-mont-semi-bold"><span
              class="font-mont-semi-bold">Photo Gallery</span> or <span class="font-mont-semi-bold">Camera</span>
          </div>
          <div *ngIf="!leftInputFile" class="font-mont-regular">JPG, PNG or PDF only 10MB or less.</div>
        </div>
        <div class="message-error">
          <span *ngIf="isErrorLeft" class="errorMessage font-mont-regular">{{messageLeft}}</span>
        </div>
      </div>
      <div>
        <div class="font-mont-bold">Back</div>
        <div class="drop-zone-box " [ngClass]="{'file-over': hasDropOverRight,'errorFile':isErrorRight}"
          (onFileDrop)="handleFiles($event, true)" ng2FileDrop [uploader]="backUpload"
          (fileOver)="fileOverBaseR($event)">
          <img [src]="backImageUrl ? backImageUrl : '/assets/images/icons/file-upload-line.svg'" />
          <img [src]="backImageUrl ? backImageUrl : '/assets/images/icons/file-upload-line-2.svg'" />
          <div *ngIf="rightInputFile" class="drivers-name font-mont-bold">
            {{ rightInputFile ? rightInputFile.name : ''}}
          </div>
          <input *ngIf="!rightInputFile" type="file" (onFileSelected)="handleFiles($event,true)" ng2FileSelect
            [uploader]="backUpload" accept=".jpg,.pdf,.png" />

          <div *ngIf=" rightInputFile" class="remove-image-background" (click)="removeRightFiles()">Remove
          </div>
          <div *ngIf="!rightInputFile" class="drag-and-drop-details font-mont-semi-bold">Drag and drop here or <span
              class="font-mont-semi-bold">select file</span></div>
          <div *ngIf="!rightInputFile" class="drag-and-drop-details-mobile font-mont-semi-bold"><span
              class="font-mont-semi-bold">Photo Gallery</span> or <span class="font-mont-semi-bold">Camera</span>
          </div>
          <div *ngIf="!rightInputFile" class="font-mont-regular">JPG, PNG or PDF only 10MB or less.</div>
        </div>
        <div class="message-error">
          <span *ngIf="isErrorRight" class="errorMessage font-mont-regular">
            {{messageRight}}
          </span>
        </div>
      </div>
    </div>
    <button class="font-mont-bold" [disabled]="!rightInputFile || !leftInputFile"
      (click)="onContinueClick()">Upload</button>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="/assets/images/upload-license-img.svg" alt="">
  </ng-container>
</app-application-wrapper>
