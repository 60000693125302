import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExpiredLoginService } from '../services/expired-login.service';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
    private AUTH_HEADER = "Authorization";
    private token = "secrettoken";
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    constructor(private httpClient: HttpClient, private expiredLoginService: ExpiredLoginService, private googleAnalyticsService: GoogleAnalyticsService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.addAuthenticationToken(req);
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                this.googleAnalyticsService.trackErrorEvent(error.statusText, error.name, error.url, 0, error.status, error.type, error.message);
                return throwError(error);
            })
        );
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        if (request.params.get('access_token') == null) {
            return request;
        }
        return request.clone({
            params: request.params.set('access_token', JSON.parse(localStorage.getItem('click_oauth_data')).access_token)
        });
    }
}