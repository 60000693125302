<app-header></app-header>
<div class="body-container clearfix">
    <div class="row">
        <div class="col-xl-1 d-xl-flex d-lg-none">
        </div>
        <div class="col-xl-3 col-lg-4">
            <div class="application-steps">
                <div class="step-selected">Report a problem</div>
            </div>
        </div>
        <div class="col-xl-1 d-xl-flex d-lg-none">
        </div>
        <div class="col-xl-6 col-lg-8">
            <form [formGroup]="ticketForm.form" (ngSubmit)="onContinueClick()">
                <!-- Business Info -->
                <div class="business-info-section clearfix">
                    <div class="row" *ngIf="!production">
                        <button class="btn btn-primary btn-lg auto-populate"
                            (click)="onCompleteInformationClick(); $event.preventDefault();">
                            <i class="fa fa-check"></i>
                        </button>
                    </div>
                    <div class="row title">
                        <div class="col-12">
                            Open a New Ticket
                        </div>
                    </div>
                    <div class="row title">
                        <div class="col-12">
                            Please fill in the form below to open a new ticket.
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-3">
                            <label for="emailAddress"
                                [ngClass]="{ 'color-red': submitted && tForm.emailAddress.errors }">Email
                                Address: *</label>
                            <input type="email" formControlName="emailAddress" class="form-control" id="emailAddress"
                                placeholder="Email Address" name="emailAddress"
                                [ngClass]="{ 'is-invalid': submitted && tForm.emailAddress.errors }">
                            <div *ngIf="submitted && tForm.emailAddress.errors" class="invalid-feedback">
                                <div *ngIf="tForm.emailAddress.errors.required">
                                    Email address is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-3">
                            <label for="fullName" [ngClass]="{ 'color-red': submitted && tForm.fullName.errors }">Full
                                Name:
                                *</label>
                            <input type="text" formControlName="fullName" class="form-control" id="fullName"
                                placeholder="Full Name" name="fullName"
                                [ngClass]="{ 'is-invalid': submitted && tForm.fullName.errors }">
                            <div *ngIf="submitted && tForm.fullName.errors" class="invalid-feedback">
                                <div *ngIf="tForm.fullName.errors.required">
                                    Full name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-3">
                            <label for="phoneNumber"
                                [ngClass]="{ 'color-red': submitted && tForm.phoneNumber.errors }">Phone Number:
                                *</label>
                            <input type="text" formControlName="phoneNumber" class="form-control" id="phoneNumber"
                                placeholder="Phone Number" name="phoneNumber" [showMaskTyped]="true"
                                mask="(000) 000-0000"
                                [ngClass]="{ 'is-invalid': submitted && tForm.phoneNumber.errors }">
                            <div *ngIf="submitted && tForm.phoneNumber.errors" class="invalid-feedback">
                                <div *ngIf="tForm.phoneNumber.errors.required">
                                    Phone number is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-3">
                            <label for="phoneExt"
                                [ngClass]="{ 'color-red': submitted && tForm.phoneExt.errors }">Ext.:</label>
                            <input type="text" formControlName="phoneExt" class="form-control" id="phoneExt"
                                placeholder="Ext." name="phoneExt"
                                [ngClass]="{ 'is-invalid': submitted && tForm.phoneExt.errors }">
                            <div *ngIf="submitted && tForm.phoneExt.errors" class="invalid-feedback">
                                <div *ngIf="tForm.phoneExt.errors.required">
                                    Ext is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-4">
                            <label for="helpTopic" [ngClass]="{ 'color-red': submitted && tForm.helpTopic.errors }">Help
                                Topic:*</label>
                            <select formControlName="helpTopic" class="form-control" id="helpTopic" name="helpTopic"
                                [ngClass]="{ 'is-invalid': submitted && tForm.helpTopic.errors }">
                                <option *ngFor="let helpTopic of helpTopics" [ngValue]="helpTopic.id">
                                    {{helpTopic.name}}</option>
                            </select>
                            <div *ngIf="submitted && tForm.helpTopic.errors" class="invalid-feedback">
                                <div *ngIf="tForm.helpTopic.errors.required">
                                    Help topic is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-8">
                            <label for="issueSummary"
                                [ngClass]="{ 'color-red': submitted && tForm.issueSummary.errors }">Issue
                                Summary:*</label>
                            <input type="text" formControlName="issueSummary" class="form-control" id="issueSummary"
                                placeholder="Issue Summary" name="issueSummary"
                                [ngClass]="{ 'is-invalid': submitted && tForm.issueSummary.errors }">
                            <div *ngIf="submitted && tForm.issueSummary.errors" class="invalid-feedback">
                                <div *ngIf="tForm.issueSummary.errors.required">
                                    Issue summary is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="issueDetails"
                                [ngClass]="{ 'color-red': submitted && tForm.issueDetails.errors }">Details on the
                                reason(s) for opening the ticket:</label>
                            <textarea formControlName="issueDetails" class="form-control" id="issueDetails" rows="4"
                                placeholder="Details on the reason(s) for opening the ticket" name="issueDetails"
                                [ngClass]="{ 'is-invalid': submitted && tForm.issueDetails.errors }"></textarea>
                            <div *ngIf="submitted && tForm.issueDetails.errors" class="invalid-feedback">
                                <div *ngIf="tForm.issueDetails.errors.required">
                                    Details on the reason(s) for opening the ticket.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <app-documents-table [documents]="documents"></app-documents-table>
                        </div>
                        <div class="form-group col-md-12">
                            <app-upload-document (onFileAdded)="onFileAdded($event);"></app-upload-document>
                        </div>
                    </div>
                </div>
                <div class="form-row display-block">
                    <div class="form-group col-md-3 col-xs-6 float-left">
                        <button class="btn btn-secondary btn-lg col-md-12" (click)="onResetClick(); $event.preventDefault();">Reset</button>
                    </div>
                    <div class="col-md-3 float-right">
                        <button type="submit" class="btn btn-primary btn-lg col-md-12">Create Ticket</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-xl-1 d-xl-flex d-lg-none">
        </div>
    </div>
	<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageA" [messageB]="messageB" [messageC]="messageC" [messageAClass]="messageAClass" [dialogClass]="'w450'">
	</app-error-dialog>
</div>
<app-footer></app-footer>