import { AfterViewInit, Directive, ElementRef, Input, Renderer2, SimpleChanges } from '@angular/core';

type WordHighlightType = 'highligh' | 'underline' | 'bold';

interface WordHighlightStyles {
  bgClr?: string;
  boldClr?: string;
  underLineClr?: string;
}

interface WordHighlightProps {
  wordsMatch?: string[];
  types: WordHighlightType[] | WordHighlightType;
  styleOpts?: WordHighlightStyles
}

@Directive({
  selector: '[wordHighlight]'
})
export class WordHighlightDirective implements AfterViewInit {

  // Default colors are transparent
  @Input('wordHighlight') opts: WordHighlightProps = {
    types: 'highligh',
    styleOpts: { bgClr: '#ffffff00', boldClr: '#ffffff00', underLineClr: '#ffffff00' }
  }

  constructor(private txtEle: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    // const {} = this.txtEle.nativeElement;
    this.opts = {
      types: 'highligh',
      styleOpts: { bgClr: '#ffffff00', boldClr: '#ffffff00', underLineClr: '#ffffff00', ...this.opts.styleOpts },
      ...this.opts
    }
    this.runMarker();
  }

  runMarker() {
    const isWholeTextMarked: boolean = !this.opts.wordsMatch?.length;
    if (isWholeTextMarked) {
      this.renderer.setStyle(this.txtEle, 'background-color', this.opts.styleOpts.bgClr);
    } else {
      const { sentences, wordsMatched } = this.targetTextEle(this.txtEle.nativeElement);
      if (!wordsMatched?.length) return;
      // console.log(sentences, wordsMatched);
      this.markSplittedText(this.txtEle.nativeElement, sentences, this.opts.wordsMatch)
    }
  }

  addMarkers<E = HTMLElement>(type: WordHighlightType, pTxtEle: E): void {
    switch (type) {
      case 'highligh':
        this.renderer.setStyle(pTxtEle, 'background-color', this.opts.styleOpts.bgClr);
        break;
      case 'bold':
        this.renderer.addClass(pTxtEle, 'font-mont-bold');
        this.renderer.setStyle(pTxtEle, 'color', this.opts.styleOpts.boldClr);
        break;
      case 'underline':
        this.renderer.setStyle(pTxtEle, 'text-decoration', 'underline');
        break;

      default:
        return;
    }
  }

  targetTextEle(txtEle: Element): { sentences: string[], wordsMatched: string[] } {
    const { wordsMatch } = this.opts;
    let res = { wordsMatched: null, sentences: null };
    let textArr: string[] = [txtEle.textContent];
    // make a search of the words in the array
    let wordsFound = wordsMatch
      .map((word, i) => {
        let txtNode = null;
        word = this.escapeRegex(word);
        let wordMatch: RegExpMatchArray | string | null =
          txtEle.textContent.match(new RegExp(`${word}\\s+|\\s+${word}|\\s+${word}\\s+`, 'gi'));
        if (wordMatch?.length) {
          txtNode = [...wordMatch].shift();
        }
        return txtNode;
      }).filter(w => !!w);
    // console.log(wordsFound);
    //* If there's no match, it does nothing
    if (!wordsFound.length) return res;
    res.wordsMatched = wordsFound;
    //* Creates an array which is compose by both matched words and 
    //* split sentence chunks
    wordsFound.forEach(w => {
      res.sentences = this.clsFlatMap<string>(
        textArr.map(messW => {
          return messW.split(new RegExp(`(${this.escapeRegex(w)})`, 'gi'));
        })
      )
    });
    // console.log(res.sentences);
    //* If sentence cannot be splitted, it does nothing
    if (!textArr.length) return res;
    return res;
  }

  markSplittedText(textEle: Element, sentenceSplittedArr: string[], wordsToMatch: string[]) {
    //* creates span eles for the words which have to be marked and it wrapps the rest of the sentence chunks
    //* into text node elemetns
    const sentenceSplittedEles = sentenceSplittedArr.filter(w => !!w).map(w => {
      const isMarkedWord = wordsToMatch.find(wordMark => (wordMark as string).toLowerCase().trim() === w.toLowerCase().trim());
      let txtEle = this.renderer.createText(w);
      let markedEle;
      if (isMarkedWord) {
        markedEle = this.renderer.createElement('span');
        this.renderer.appendChild(markedEle, txtEle);
        // this.renderer.setStyle(markedEle, 'background-color', this.opts.styleOpts.bgClr);
        this.opts.types instanceof Array
          ? this.opts.types.forEach(t => this.addMarkers(t, markedEle))
          : this.addMarkers(this.opts.types, markedEle);
      }
      return markedEle || txtEle;
    });
    //* remove directive target element's children
    // console.log(sentenceSplittedEles);
    textEle.innerHTML = '';
    //* add new nodes with marked words
    sentenceSplittedEles.forEach(newEles => this.renderer.appendChild(textEle, newEles));
    // console.log(
    //   textArr
    // )
    // console.log(
    //   wordsFound
    // );
  }

  clsFlatMap<T = any>(arr: (T | T[])[]) {
    const newArr = [];
    arr.forEach(arrEle => {
      if (!(arrEle instanceof Array)) newArr.push(arrEle);
      else arrEle.forEach(nestedEle => newArr.push(nestedEle));
    });
    return newArr;
  }

  escapeRegex(str: string) {
    return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
  }


}
