import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services';
import { AuthService } from 'src/app/auth/auth.service';
import { IPersonalInformation } from 'src/app/application/v3/model';
import { DateValidator, formatUSPhone, getProveRightCity, getCityRightCounty } from 'src/app/utils/shared';
import { ZipCodeService } from 'src/app/application/v3/services';

@Injectable({
    providedIn: 'root'
})
export class PersonalFormService {
    readonly form = this.formBuilder.group({
        address: ['', Validators.required],
        birthDate: ['', [Validators.required, DateValidator()]],
        city: ['', Validators.required],
        county: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        socialSecurityNumber: ['', Validators.required],
        state: ['', Validators.required],
        zipCode: ['', Validators.required],
        id: [''],
        phone: [''],
    });
    personalInformationRetry = false;

    constructor(
        private formBuilder: FormBuilder,
        private connectionService: ConnectionService,
        private authService: AuthService,
        private zipCodeService: ZipCodeService,
    ) { }

    get controls() {
        return this.form.controls;
    }

    get enconde() {
        return <IPersonalInformation>{
            birthDate: new Date(this.getControlValue('birthDate')),
            city: this.getControlValue('city'),
            county: this.getControlValue('county'),
            firstName: this.getControlValue('firstName'),
            id: Number(this.getControlValue('id')),
            lastName: this.getControlValue('lastName'),
            phone: Number(this.getControlValue('phone')),
            ssn: this.getControlValue('socialSecurityNumber'),
            state: this.getControlValue('state'),
            street: this.getControlValue('address'),
            zipcode: this.getControlValue('zipCode'),
        }
    }

    get invalid() {
        return this.form.invalid;
    }

    get legalName() {
        return `${this.getEncodedValue('firstName')} ${this.getEncodedValue('lastName')}`
    }

    get valid() {
        return this.form.valid;
    }

    private getControlById(key: string) {
        return this.controls[key];
    }

    async putPersonalInformation(personalData: IPersonalInformation = this.enconde) {
        try {
            const pdata = await this.connectionService.patchV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/leads/${personalData.id}`,
                contentType: 'application/json',
                access_token: this.authService.authTokenV3,
                postData: personalData
            });

            return pdata;
        } catch (err) { return err; }
    }

    addFormData(data) {
        return new Promise((resolve, reject) => {
            if (data.leadId) {
                this.setValueToControl('id', data.leadId);

                if (data.individual) {
                    this.setValueToControl('phone', formatUSPhone(data.individual.phoneNumber ? data.individual.phoneNumber : ''));
                    this.setValueToControl('firstName', data.individual.firstName ? data.individual.firstName : '');
                    this.setValueToControl('lastName', data.individual.lastName ? data.individual.lastName : '');
                    this.setValueToControl('birthDate', data.individual.dob ? new Date(`${data.individual.dob}T12:00`) : '');
                    this.setValueToControl('socialSecurityNumber', data.individual.ssn ? data.individual.ssn : '');

                    if (data.individual.addresses && data.individual.addresses.length && data.individual.addresses[0].postalCode) {
                        const zipCode = data.individual.addresses[0].postalCode;
                        const personalAddress = data.individual.addresses[0].address ? data.individual.addresses[0].address : '';
                        const city = data.individual.addresses[0].city;

                        this.setValueToControl('address', personalAddress);

                        if (this.zipCodeService.validateZipCode(zipCode, personalAddress, city)) {
                            const dataZipCode = this.zipCodeService.getZipCode(zipCode, personalAddress, city);
                            let currentCity: string = getProveRightCity(dataZipCode.object.city, city);
                            let countyInformation: any = getCityRightCounty(currentCity, dataZipCode.object.county, dataZipCode.object.cityCounties);
                            this.setAddresData(
                                currentCity,
                                countyInformation.rightCounty,
                                dataZipCode.object.state
                            );
                            this.setValueToControl('zipCode', zipCode);
                            resolve(true);
                        } else this.zipCodeService.addZipCode(zipCode, personalAddress, city)
                            .then(dataZipCode => {
                                let currentCity: string = getProveRightCity(dataZipCode.object.city, city);
                                let countyInformation: any = getCityRightCounty(currentCity, dataZipCode.object.county, dataZipCode.object.cityCounties);
                                this.setAddresData(
                                    currentCity,
                                    countyInformation.rightCounty,
                                    dataZipCode.object.state
                                );
                                this.setValueToControl('zipCode', zipCode);
                                resolve(true);
                            }).catch(err => {
                                this.resetAdress();
                                resolve(true);
                            });
                    } else {
                        this.resetAdress(true);
                        resolve(true);
                    }
                } else reject(true);
            } else reject(true);
        });
    }

    decode({
        birthDate,
        city,
        id,
        firstName,
        lastName,
        phone,
        ssn,
        state,
        street,
        zipcode,
    }: IPersonalInformation) {
        this.setValueToControl('birthDate', birthDate);
        this.setValueToControl('city', city);
        this.setValueToControl('id', id);
        this.setValueToControl('firstName', firstName);
        this.setValueToControl('lastName', lastName);
        this.setValueToControl('phone', phone);
        this.setValueToControl('socialSecurityNumber', ssn);
        this.setValueToControl('state', state);
        this.setValueToControl('address', street);
        this.setValueToControl('zipCode', zipcode);
    }

    getControlValue(key: string) {
        return this.getControlById(key).value;
    }

    getControlInvalid(key: string) {
        return this.getControlById(key).invalid;
    }

    getEncodedValue(key: string) {
        return this.enconde[key];
    }

    reset() {
        this.form.reset();
    }

    resetAdress(resetAddress = false) {
        this.setValueToControl('zipCode', '');
        this.setAddresData('', '', '');
        if (resetAddress) {
            this.setValueToControl('address', '');
        }
    }

    setAddresData(city, county, state, errors = null) {
        this.setValueToControl('state', state);
        this.setValueToControl('city', city);
        this.setValueToControl('county', county);
        this.setErrors('zipCode', errors);
    }

    setDefaultData() {
        this.decode({
            id: this.getEncodedValue('id') ? this.getEncodedValue('id') : 10752,
            firstName: 'Jane',
            lastName: 'Doe',
            birthDate: new Date(1990, 0, 1),
            ssn: '123412312',
            street: '1182 Main St',
            zipcode: '84105',
            city: 'Salt Lake City'.toUpperCase(),
            state: 'UT',
            county: '',
            phone: this.getEncodedValue('phone') ? this.getEncodedValue('phone') : 2001062109
        });
    }

    setErrors(key: string, errors = null) {
        this.getControlById(key).setErrors(errors);
    }

    setValueToControl(key: string, data) {
        this.getControlById(key).setValue(data);
    }

    /**
     *
     */
    setZipCodeError() {
        this.setAddresData('', '', '', { 'wrong': true });
    }
}
