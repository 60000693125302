import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ClickSingService } from './click-sing.service';

import { ClicksingRoutingModule } from 'src/app/click-sing/click-sing-routing.module';
import { HeaderModule } from '../header/header.module';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';


// Clicksign Components
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { ConfirmTermsComponent } from './confirm-terms/confirm-terms.component';
import { DriversLicenseComponent } from './drivers-license/drivers-license.component';
import { DocusignErrorComponent } from './docusign-error/docusign-error.component';
import { DocusignOtpComponent } from './docusign-otp/docusign-otp.component';
import { DocusignPageComponent } from './docusign-page/docusign-page.component';
import { TryAgainComponent } from './try-again/try-again.component';
import { DeclinedComponent } from './declined/declined.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        DocusignOtpComponent,
        PaymentOptionsComponent,
        ConfirmTermsComponent,
        DriversLicenseComponent,
        DocusignErrorComponent,
        DocusignPageComponent,
        TryAgainComponent,
        DeclinedComponent
    ],
    imports: [
        ClicksingRoutingModule,
        HeaderModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        NgxMaskModule.forRoot(options),
    ],
    providers: [
        ClickSingService
    ]
})
export class ClickSingModule { }
