import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface EptMdlStylesOpts {
  vAlign?: 'top' | 'center',
  bg?: 'gray' | 'light' | 'dark',
  bs?: 'sm' | 'md' | 'lg',
  rounded?: boolean,
  backDrop?: 'light' | 'dark',
  closeBtnType?: 'button' | 'single-icon'
}

type EptMdlTheme = 'eptV1' | 'eptV2';

@Component({
  selector: 'app-ept-modal',
  templateUrl: './ept-modal.component.html',
  styleUrls: ['./ept-modal.component.css'],
  animations: [
    trigger('mdlState', [
      state('show', style({
        transform: 'none'
      })),
      state('hide', style({
        transform: 'translate(0, -125px)'
      })),
      transition(':enter', [style({ transform: 'translate(0, -125px)' }), animate('250ms 50ms ease-out', style({ transform: 'none' }))]),
      transition(':leave', [style({ transform: 'none' }), animate('250ms ease-out', style({ transform: 'translate(0, -125px)' }))])
    ]),
    trigger('mdlWrappState', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms linear', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms 100ms linear', style({ opacity: 0 }))])
    ])
  ]
})
export class EptModalComponent implements OnInit {

  @Input()
  set isOpen(newIsOpen: boolean) {
    this._isOpen = newIsOpen;
    // this.isVisible = newIsOpen;
  }
  get isOpen(): boolean {
    return this._isOpen;
  }
  private _isOpen: boolean = false;

  @Input()
  set mdlId(newMdlId: string) {
    this._mdlId = newMdlId;
  }
  get mdlId(): string {
    return this._mdlId;
  }
  private _mdlId: string = 'ept-mdl-' + Date.now();

  @Input()
  theme: EptMdlTheme = 'eptV1';

  @Input()
  styleOpts: EptMdlStylesOpts;

  @Output()
  onStatusChanges: EventEmitter<boolean> = new EventEmitter();

  crrTheme: { [mdlTheme: string]: EptMdlStylesOpts } = {
    'eptV1': {
      vAlign: 'center',
      bg: 'gray',
      bs: 'md',
      rounded: false,
      backDrop: 'light',
      closeBtnType: 'button'
    },
    'eptV2': {
      vAlign: 'center',
      bg: 'light',
      bs: 'lg',
      rounded: true,
      backDrop: 'dark',
      closeBtnType: 'single-icon'
    }
  };

  constructor() {
  }

  ngOnInit(): void {
    // it allows the component to use a theme but at the same time make some look chages
    // which doesnt matcht with the theme
    if (!this.styleOpts) this.styleOpts = this.crrTheme[this.theme];
    else
      this.styleOpts = {
        ...this.crrTheme[this.theme],
        ...this.styleOpts
      }
  }

  closeMdl(pMdlStatus: boolean) {
    this.isOpen = pMdlStatus;
    this.onStatusChanges.emit(pMdlStatus);
  }

  getBgClr(clrName?: string): string {
    if (!clrName) clrName = this.styleOpts.bg;
    let clrVal = '#fff';
    if (clrName === 'gray') clrVal = '#F1F5F7';
    if (clrName === 'light') clrVal = '#FFF';
    if (clrName === 'dark') clrVal = '#FFF';
    return clrVal;
  }

  getBoxShadow(size?: string): string {
    !size && (size = this.styleOpts.bs);
    let bsClass;
    if (size === 'sm') bsClass = 'shadow-sm';
    if (size === 'md') bsClass = 'shadow';
    if (size === 'lg') bsClass = 'shadow-lg';
    return bsClass;
  }

  getRounded(): string {
    return this.styleOpts.rounded ? 'mdl-rounded' : 'rounded';
  }

  getCloseIcon(btnType = 'button') {
    let path;
    btnType === 'single-icon' && (path = 'close.svg');
    btnType === 'button' && (path = 'close_circle_gray.svg');
    return path ? '/assets/images/icons/' + path : this.getCloseIcon(this.styleOpts.closeBtnType);
  }

} 
