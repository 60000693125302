import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ApplicationService } from 'src/app/application/v3/services';

@Component({
    selector: 'app-benefits',
    templateUrl: './benefits.component.html',
    styleUrls: ['./benefits.component.css']
})

export class BenefitsComponent implements OnInit {
    private source: any = null;
    private token: any = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private googleAnalyticsService: GoogleAnalyticsService,
        private applicationService: ApplicationService,
    ) { }

    ngOnInit(): void {
        this.googleAnalyticsService.trackV3Event(
            'benefits-open',
            this.applicationService.vendorReferrerName,
            ''
        );
        this.token = this.activatedRoute.snapshot.params.token;
        this.source = this.activatedRoute.snapshot.queryParams.source;
    }

    goToForm() {
        window.location.href = `${window.location.protocol}//${window.location.host}/inlineapp/started?token=${this.token}&source=${this.source}`;
        //this.router.navigate(['/inlineapp/started'], { queryParams: { token: this.actRout.snapshot.params.token }, queryParamsHandling: 'merge' });
    }

    goToLearn() {
        window.open('https://www.clicklease.com/small-business-owners/', "_blank");
    }

    goToFacebook() {
        window.open('https://www.facebook.com/ClickLease1/', "_blank");
    }

    goToLinkedIn() {
        window.open('https://www.linkedin.com/company/clicklease-llc/mycompany/verification/', "_blank");
    }
}
