<app-header></app-header>
<div class="body-container clearfix">
    <div class="row">
        <div class="col-xl-12 main-container">
            <h2>ClickLease Privacy Policy</h2>
            <h4>Google Analytics</h4>
            <div class="content">We use cookies from third-party partners such as Google for marketing pruposes. These
                information stored in the cookies related to the use of the website is sent to Google in order to
                evaluate the use of this website and generates statistical reports and error reports.</div>
            <div class="content">By using this website you consent the use of this data by Google.</div>
            <div class="content">
                Check here the
                <a href="http://www.google.com/policies/privacy/partners/">Google Analytics Policy.</a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>