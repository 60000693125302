export class TermToolCondition {
    id: number;
    offerTermAdjustmentId: number;
    dataRobotAttributeId: number;
    operatorId: number;
    value: string;
    conditionIdentifier: number;
    dataRobotAttributeName: string;
    operatorDescription: string;

    constructor(data: any, attributes: any, operators: any) {
        this.id = data.id;
        this.offerTermAdjustmentId = data.offerTermAdjustmentId;
        this.dataRobotAttributeId = data.dataRobotAttributeId;
        this.operatorId = data.operatorId;
        this.value = data.value;
        this.conditionIdentifier = data.conditionIdentifier;
        attributes.forEach((element: any) => {
            if (element.id === this.dataRobotAttributeId) {
                this.dataRobotAttributeName = element.description;
            }
        });
        operators.forEach((element: any) => {
            if (element.id === this.operatorId) {
                this.operatorDescription = element.operator;
            }
        });
    }
}
