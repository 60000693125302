import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-token-error',
    templateUrl: './token-error.component.html',
    styleUrls: ['./token-error.component.css']
})
export class TokenErrorComponent implements OnInit {

    token = '';

    constructor(private router: Router, private googleAnalyticsService: GoogleAnalyticsService, private authService: AuthService) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state) {
            this.token = navigation.extras.state.token;
        } else {
            this.router.navigateByUrl('/');
        }
    }

    ngOnInit(): void {
        this.googleAnalyticsService.trackV2Event('token-error', this.authService.vendorReferrerName, this.token);
        void (0);
    }

    onBackClick() {
        this.router.navigateByUrl('/');
    }
}
