import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorListService } from 'src/app/shared/services';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-resume',
  templateUrl: './app-resume.component.html',
  styleUrls: ['./app-resume.component.css']
})
export class AppResumeComponent implements OnInit {

  private encodeKey: string;
  private token: string;


  constructor(
    private router: Router,
    private connectionService: ConnectionService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private errorListService: ErrorListService,
    private googleAnalyticsService: GoogleAnalyticsService
    )
    {
      this.encodeKey = this.activatedRoute.snapshot.queryParams.key;
      if (!this.encodeKey) {
        this.googleAnalyticsService.trackV3Event('app-resume-page', this.authService.vendorReferrerName, "app-resume-page", "Mising encodeKey");
        this.router.navigate(['/token-error-v3'], { state: { token: this.token, data: 'app-resume-page' } });
        return;
      }
      if(this.encodeKey.indexOf('key=KEY?') != -1) {
        this.encodeKey = this.encodeKey.replace('key=KEY?', '');
      }
    }

  ngOnInit(): void {
    this.googleAnalyticsService.setV3Label('app-resume');
    this.getToken();
  }

  getToken() {
    this.connectionService.getV3Request(`${environment.gatewayV3}prefillservice/api/open/decodeKey?encodedKey=${this.encodeKey}`, 'application/json').subscribe(
        (data: any) => {
          this.googleAnalyticsService.trackV3Event('app-resume-page-response', this.authService.vendorReferrerName, JSON.stringify(data));
          if (!data || !data.object ) {
            this.router.navigate(['/token-error-v3'], { queryParamsHandling: "merge", state: { endpoint: "decodeKey-error-" + data.msg, start: "resume", token: this.encodeKey } });
          } else this.router.navigate(['/inlineapp/resume'], {queryParams:{token: data.object.vendorToken, key: this.encodeKey}, state: { appId: data.object.applicationId, accessToken: data.object.accessToken, phoneNumber: data.object.leadPhoneNumber }} );
        },
        (error: any) => {
          let errorData = error.error ? error.error : error;
          if(errorData && errorData.code && errorData.code == '401 UNAUTHORIZED') {
            this.router.navigate(['/token-error-v3'], { queryParamsHandling: "merge", state: { endpoint: errorData.msg, start: "resume", token: this.encodeKey } });
          } else this.errorListService.redirectToV3Error(error.error, 'decodeKey-error', 'resume');
        }
    );
  }
}
