<div class="container cont-screen">
    <div class="container">
        <img src="../../../../assets/images/ecom-logo.svg" alt="">
    </div>
    <div class="title">
        <h4 class="font-mont-bold tit">Click Now. Pay Later.</h4>
        <p class="sub-tit font-mont-book">Now offering <span class="font-mont-book">early payoff options!</span></p>
    </div>
    <div class="container cont-grid1">
        <div class="row">
            <div class="col-6">
                <img src="../../../../assets/images/scores.svg" alt="">
                <p class="tit font-mont-bold">All Credit Scores Welcome</p>
                <p class="desc font-mont-book ">Whether you have great credit or bad credit, we have a solution.</p>
            </div>
            <div class="col-6">
                <img src="../../../../assets/images/pay-check.svg" alt="">
                <p class="tit font-mont-bold">Pick Your Payment</p>
                <p class="desc font-mont-book ">Choose the payment and lease terms that best fit your budget.</p>
            </div>
            <div class="col-6">
                <img src="../../../../assets/images/stop-watch.svg" alt="">
                <p class="tit font-mont-bold">Amazingly Fast Process</p>
                <p class="desc font-mont-book ">Complete the entire purchase in less than 10 minutes.</p>
            </div>
            <div class="col-6">
                <img src="../../../../assets/images/money.svg" alt="">
                <p class="tit font-mont-bold">Instant Approvals Up to $20,000</p>
                <p class="desc font-mont-book ">As good as cash in hand, not just a pre-qualified amount. </p>
            </div>
            <div class="col-6">
                <img src="../../../../assets/images/credit-card.svg" alt="">
                <p class="tit font-mont-bold">No Hard Credit Pull.</p>
                <p class="desc font-mont-book ">Risk free application and no impact to your personal credit score.</p>
            </div>
            <div class="col-6">
                <img src="../../../../assets/images/bank.svg" alt="">
                <p class="tit font-mont-bold">Tax Benefits</p>
                <p class="desc font-mont-book ">Your lease payments may be fully tax-deductible as a business expense.
                </p>
            </div>
        </div>
    </div>
    <div class="button-info" (click)="goToForm()">
        <svg width="95%" height="43" viewBox="0 0 468 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style="cursor: pointer;"
                d="M0 21.5C0 9.62588 9.62588 0 21.5 0H446.5C458.374 0 468 9.62588 468 21.5C468 33.3741 458.374 43 446.5 43H21.5C9.62587 43 0 33.3741 0 21.5Z"
                fill="#20B6F1" />
            <path
                d="M185.964 28H189.264L183.604 14H180.744L175.244 28H178.464L179.424 25.36H184.984L185.964 28ZM182.204 17.7L184.044 22.72H180.364L182.204 17.7ZM196.419 14H191.039V28H194.059V23.64H196.419C199.239 23.64 201.359 21.58 201.359 18.82C201.359 16.06 199.219 14 196.419 14ZM196.279 20.84H194.059V16.82H196.279C197.479 16.82 198.299 17.66 198.299 18.82C198.299 19.98 197.479 20.84 196.279 20.84ZM208.861 14H203.481V28H206.501V23.64H208.861C211.681 23.64 213.801 21.58 213.801 18.82C213.801 16.06 211.661 14 208.861 14ZM208.721 20.84H206.501V16.82H208.721C209.921 16.82 210.741 17.66 210.741 18.82C210.741 19.98 209.921 20.84 208.721 20.84ZM218.942 25.2V14H215.922V28H224.322V25.2H218.942ZM236.293 14H233.053L229.813 19.62L226.593 14H223.373L228.253 22.34V28H231.353V22.36L236.293 14ZM251.403 14V22.54L245.263 14H242.543V28H245.503V19.26L251.843 28H254.383V14H251.403ZM264.222 28.32C268.482 28.32 271.662 25.18 271.662 21C271.662 16.84 268.482 13.7 264.222 13.7C259.942 13.7 256.742 16.86 256.742 21C256.742 25.16 259.942 28.32 264.222 28.32ZM264.222 25.46C261.722 25.46 259.822 23.52 259.822 21C259.822 18.48 261.722 16.56 264.222 16.56C266.722 16.56 268.582 18.46 268.582 21C268.582 23.54 266.722 25.46 264.222 25.46ZM289.456 14L286.756 23.3L282.996 14.16H281.976L278.296 23.32L275.536 14H272.336L276.736 28H279.436L282.476 20.16L285.596 28H288.296L292.616 14H289.456Z"
                fill="white" />
        </svg>
    </div>
    <div class="info">
        <h4 class="title font-mont-bold">Trust the Professionals</h4>
        <p class="inf font-mont-book">Clicklease is here to provide industry-leading
            industrial equipment for a low monthly payment customized to fit your budget.
        </p>
        <p class="inf font-mont-book">It's never been easier to afford the equipment you need to grow your business.</p>
        <p class="inf font-mont-book">Apply in just 4 minutes and get a real approval in seconds—no hard credit pull, no documents
            needed.</p>
    </div>
    <div class="moreInfo container cont-2">
        <div class="row">
            <div class="col-6">
                <img src="../../../../assets/images/girlInfo_Large.png" width="80%" height="90%" alt="">
            </div>
            <div class="col">
                <h4 class="title font-mont-bold">
                    More Information
                </h4>
                <p class="more-Inf font-mont-book">Available 24/7 so you can apply whenever it's convenient for you and
                    it will only
                    take three minutes of your time. </p>
            </div>
        </div>
        <div class="cont-Desc">
            <p class="inf font-mont-book">Once approved, you'll have the option to select the term that best fits your
                budget and, if
                approved for more than you decide to add to your lease.</p>
            <p class="inf font-mont-book">eDocs are available immediatly, allowing you to order your new gear within
                minutes. </p>
            <p class="inf font-mont-book">Have additional questions? Contact Us at 888-509-5592</p>
        </div>
    </div>
    <div class="learn">
        <svg width="95%" height="43" viewBox="0 0 468 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path (click)="goToLearn()" style="cursor: pointer;"
                d="M0 21.5C0 9.62588 9.62588 0 21.5 0H446.5C458.374 0 468 9.62588 468 21.5C468 33.3741 458.374 43 446.5 43H21.5C9.62587 43 0 33.3741 0 21.5Z"
                fill="#20B6F1" />
            <path
                d="M174.001 25.2V14H170.981V28H179.381V25.2H174.001ZM184.45 25.2V22.32H189.31V19.58H184.45V16.8H189.83V14H181.43V28H190.05V25.2H184.45ZM201.882 28H205.182L199.522 14H196.662L191.162 28H194.382L195.342 25.36H200.902L201.882 28ZM198.122 17.7L199.962 22.72H196.282L198.122 17.7ZM214.657 28H218.037L214.937 22.72C216.497 21.96 217.497 20.48 217.497 18.64C217.497 15.94 215.397 14 212.597 14H206.957V28H209.977V23.24H211.917L214.657 28ZM209.977 16.8H212.397C213.677 16.8 214.417 17.58 214.417 18.62C214.417 19.68 213.677 20.44 212.397 20.44H209.977V16.8ZM229.04 14V22.54L222.9 14H220.18V28H223.14V19.26L229.48 28H232.02V14H229.04ZM257.144 28L254.984 14H251.944L248.144 22.6L244.344 14H241.324L239.124 28H242.204L243.584 18.88L247.764 27.98H248.504L252.684 18.88L254.064 28H257.144ZM266 28.32C270.26 28.32 273.44 25.18 273.44 21C273.44 16.84 270.26 13.7 266 13.7C261.72 13.7 258.52 16.86 258.52 21C258.52 25.16 261.72 28.32 266 28.32ZM266 25.46C263.5 25.46 261.6 23.52 261.6 21C261.6 18.48 263.5 16.56 266 16.56C268.5 16.56 270.36 18.46 270.36 21C270.36 23.54 268.5 25.46 266 25.46ZM283.525 28H286.905L283.805 22.72C285.365 21.96 286.365 20.48 286.365 18.64C286.365 15.94 284.265 14 281.465 14H275.825V28H278.845V23.24H280.785L283.525 28ZM278.845 16.8H281.265C282.545 16.8 283.285 17.58 283.285 18.62C283.285 19.68 282.545 20.44 281.265 20.44H278.845V16.8ZM292.067 25.2V22.32H296.927V19.58H292.067V16.8H297.447V14H289.047V28H297.667V25.2H292.067Z"
                fill="white" />
        </svg>
    </div>
    <div class="contact">
        <div class="container">
            <div class="row">
                <div class="col col-left">
                    <img src="../../../../assets/images/icon2-clk.svg" alt="">
                    <p class="font-mont-light">Our simple, innovative financing solution helps small businesses afford
                        the equipment they need
                        to grow.</p>
                </div>
                <div class="col col-right">
                    <p class="follow-p font-mont-bold">Follow Us</p>
                    <p class="number-phone font-mont-light">888-509-5592</p>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path (click)="goToFacebook()" style="cursor: pointer;"
                                        d="M12 2C6.477 2 2 6.477 2 12C2 16.991 5.657 21.128 10.438 21.879V14.89H7.898V12H10.438V9.797C10.438 7.291 11.93 5.907 14.215 5.907C15.309 5.907 16.453 6.102 16.453 6.102V8.562H15.193C13.95 8.562 13.563 9.333 13.563 10.124V12H16.336L15.893 14.89H13.563V21.879C18.343 21.129 22 16.99 22 12C22 6.477 17.523 2 12 2Z"
                                        fill="#828282" />
                                </svg>
                            </div>
                            <div class="col-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path (click)="goToLinkedIn()" style="cursor: pointer;"
                                        d="M18.335 18.339H15.67V14.162C15.67 13.166 15.65 11.884 14.28 11.884C12.891 11.884 12.679 12.968 12.679 14.089V18.339H10.013V9.75H12.573V10.92H12.608C12.966 10.246 13.836 9.533 15.136 9.533C17.836 9.533 18.336 11.311 18.336 13.624V18.339H18.335ZM7.003 8.575C6.79956 8.57526 6.59806 8.53537 6.41006 8.45761C6.22207 8.37984 6.05127 8.26574 5.90746 8.12184C5.76365 7.97793 5.64965 7.80706 5.57201 7.61901C5.49437 7.43097 5.4546 7.22944 5.455 7.026C5.4552 6.71983 5.54618 6.4206 5.71644 6.16615C5.8867 5.91169 6.12859 5.71343 6.41153 5.59645C6.69447 5.47947 7.00574 5.44902 7.30598 5.50894C7.60622 5.56886 7.88196 5.71648 8.09831 5.93311C8.31466 6.14974 8.46191 6.42566 8.52145 6.72598C8.58099 7.0263 8.55013 7.33753 8.43278 7.62032C8.31543 7.9031 8.11687 8.14474 7.86219 8.31467C7.60751 8.4846 7.30817 8.5752 7.002 8.575H7.003ZM8.339 18.339H5.666V9.75H8.34V18.339H8.339ZM19.67 3H4.329C3.593 3 3 3.58 3 4.297V19.703C3 20.42 3.594 21 4.328 21H19.666C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3H19.669H19.67Z"
                                        fill="#828282" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="rights-cont">
        <p class="rights font-mont-light">© All rights reserved 2022 Clicklease. Privacy Policy</p>
    </div>
</div>