import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { AdditionaAddCoSignerEmailComponent } from './additional-options/additional-add-co-signer-email/additional-add-co-signer-email.component';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css', './ept-ui-elements.css'],
    // styleUrls: ['./summary.component.css', './ept-ui-elements.css'],
    // encapsulation: ViewEncapsulation.None,
})
export class SummaryComponent implements OnInit {

    @Input() status: any[] = [];
    @Input() industries: any[] = [];
    @Input() statesOfIncorporation: any[] = [];
    @Input() appId: any = 0;
    @Input() currentStep: any = '';
    @Input() nextStep: any = '';
    @Input() nextStepDisabled: any = '';
    @Input() risk: any;
    @Input() app: any;
    @Input() application: any = {};
    @Input() vendorPaused: any = {};

    @Output() toggleSelectedRow = new EventEmitter<void>();
    @Output() reloadSlice = new EventEmitter<void>();
    @Output() throwError = new EventEmitter<any>();

    @ViewChild(AdditionaAddCoSignerEmailComponent) addCosignerEmail: AdditionaAddCoSignerEmailComponent;

    public showModifyInvoice: boolean = false;
    public showSelectedOffer: boolean = false;
    public showUploadInvoice: boolean = false;
    public showAddGuarantor: boolean = false;
    public showPoDocument: boolean = false;
    public showResendDocs: boolean = false;
    public showOfferList: boolean = false;

    public documents: any[] = [];

    public openBusiness = false;
    public openOwner = false;
    public skipMultiRisk: boolean = false;

    public offers: any = {
        offerList: []
    };
    public selectedOffer: any = {};

    public innerWidth: number = 0;

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
        public router: Router
    ) { }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.openBusiness = false;
        this.openOwner = false;
        this.skipMultiRisk = this.risk?.skipMultiRisk ?? false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.innerWidth = window.innerWidth;
    }

    get scrollContainerWidth() {
        if (this.innerWidth < 992) {
            return this.application.applicationProcess.length * 145 + 'px';
        } else {
            return 'auto';
        }
    }

    getOffers(applicationId: number) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                `${environment.gateway}application/${applicationId}/activeOffers/${this.authService.getUserId()}`,
                'application/json', `sp_id=${this.authService.getSalesPersonId()}`).subscribe(
                    (data: any) => {
                        if (data.object) {
                            this.offers = data.object;
                            this.selectedOffer = {};
                            data.object.offerList.forEach((element: any) => {
                                if (element.selected) {
                                    this.selectedOffer = element;
                                }
                            });
                        }
                        resolve(data);
                    },
                    (error: any) => {
                        resolve(error);
                    }
                );
        });
    }

    getDocusignHistory(appId: number) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}app/${appId}/agreement/docusignHistory`,
                'application/json').subscribe(
                    (data: any) => {
                        if (data.object[0]) {
                            this.application.decodeDocusignHistory(data.object[0]);
                        }
                        resolve(undefined);
                    },
                    (error: any) => {
                        resolve(undefined);
                    }
                );
        });
    }

    getActiveOffers(appId: number) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}application/${appId}/activeOffers/${this.authService.getUserId()}`,
                'application/json').subscribe(
                    (data: any) => {
                        resolve(undefined);
                    },
                    (error: any) => {
                        resolve(undefined);
                    }
                );
        });
    }

    onToggleSelectedRow() {
        this.toggleSelectedRow.emit();
    }

    onReloadSlice() {
        this.reloadSlice.emit();
        this.ngOnInit();
    }

    onEmailClick() {
        this.addCosignerEmail.onClick();
    }

    sendBankLink(opts: { sendLinkOpts: any, linkSentRes: BehaviorSubject<any> }): void {
        const { linkSentRes, sendLinkOpts } = opts
        if (sendLinkOpts.type === 'SMS') {
            const queryParams = new HttpParams().set('isCommSender', 'true').set('appId', String(sendLinkOpts.appId));
            const endPoint = `${environment.gatewayV3}prefillservice/api/reSendVerificationPPFlowSms?${queryParams.toString()}${environment.development ? '&testPhone=7262085668' : ''}`;
            this.connectionService.getV3Request(endPoint, 'application/json', this.authService.keyCloakToken)
                .subscribe(
                    res => {
                        linkSentRes.next(res);
                    },
                    err => {
                        console.log(err);
                        linkSentRes.error(err);
                    }
                );
            return;
        } else {
            this.connectionService.getRequest(`${environment.gateway}application/finicity-link/${sendLinkOpts.appId}`, 'application/json').subscribe(
                (data: any) => {
                    linkSentRes.next(data);
                },
                (error: any) => {
                    console.log(error);
                    linkSentRes.error(error);
                }
            );
        }
    }

    updateSkipMr(pToggleState: { mrToggleState: boolean, appId: number }) {
        const { appId, mrToggleState } = pToggleState
        const queryParams = new HttpParams({ fromObject: { token: this.authService.getAccessToken(), skipMultiRisk: mrToggleState } });
        let endpointUrl = `${environment.gatewayV3}leaseapplication/api/updateAppSkipMultirisk/${appId}`;
        endpointUrl = `${endpointUrl}?${queryParams.toString()}`;
        // console.log('Updating skit multi risk flag... ', pToggleState);
        this.connectionService.postV3Request(endpointUrl, {}, 'application/json', this.authService.keyCloakToken)
            .subscribe(
                (res: any) => {
                    if (!String(res?.code).includes('200')) return;
                    this.skipMultiRisk = mrToggleState;
                },
                err => {
                    if (err?.error && !String(err.code).includes('20')) {
                        // if(!err.error?.code === 401)
                        if (this.risk.hasOwnProperty('skipMultiRisk')) this.skipMultiRisk = !this.skipMultiRisk;
                        this.throwError.emit({
                            title: 'Sorry, we found a problem',
                            messageA: 'Unfortunately we are unable to disable multirik rules at this moment.',
                            messageB: err.error?.msg ?? '',
                            messageBClass: 'mB20',
                            dialogClass: 'w600'
                        })
                    }
                    console.log('ERROR => ', err);
                }
            );
        // console.log('First => ', this.skipMultiRisk);
        this.skipMultiRisk = !this.risk.skipMultiRisk;
    }

    isRiskShown(): boolean {
        return this.risk && !!Object.keys(this.risk || {}).length;
    }

    get ownersNames() {
        let ownersNames = '';
        this.app.owners.forEach((owner: any) => {
            ownersNames += ', ' + owner.name;
        });

        return ownersNames.substr(1, ownersNames.length);
    }

    get showCreateSendContracts(): boolean {
        return this.nextStep == 'Create Contracts' && this.application.defaultOfferId;
    }

    get showSubmitInvoice(): boolean {
        return (this.nextStep == 'Submit Invoice' && !this.nextStepDisabled) || this.nextStep == 'Re-Submit Invoice';
    }

    get showContractStatus(): boolean {
        return this.application.processStatus.contractStatusProcess.statusBar.length > 0 || this.application.processStatus.dlStatusProcess.statusBar.length > 0;
    }

    get showVodStatus(): boolean {
        return this.application.processStatus?.vodStatusProcess.statusBar.length;
    }

    get showInvoiceReview(): boolean {
        return this.currentStep == 'Invoice Under Review';
    }

    get showFundingPending(): boolean {
        return this.currentStep == 'Funding Pending';
    }

    get showFunded(): boolean {
        return this.currentStep == 'Funded';
    }

    get showDeclined(): boolean {
        return this.currentStep == 'Declined';
    }

    get showDLDeclined(): boolean {
        return this.currentStep == 'Driver License Declined';
    }

    get showDataError(): boolean {
        return this.currentStep == 'Data Error';
    }

    get showResendBankVerificationLink(): boolean {
        return this.currentStep == 'Preapproval';
    }
}
