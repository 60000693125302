<div *ngIf="isOpen" [ngStyle]="{'opacity': 1, 'display': isOpen ? 'block': 'none'}" [id]="mdlId" [ariaHidden]="!isOpen"
    [ariaLabel]="mdlId + 'Label'" [ariaModal]="!isOpen" role="{{ isOpen ? 'dialog': '' }}" [@mdlWrappState]
    (click)="closeMdl(!isOpen)" class="modal fade" tabindex="-1" #eptMdlWrapp>
    <div [ngClass]="{'modal-dialog-centered':styleOpts.vAlign === 'center', 'mt-5': styleOpts.vAlign === 'top'}"
        [@mdlState]="isOpen ? 'show': 'hide'" (click)="$event.stopPropagation()" class="modal-dialog">
        <div [ngClass]="[getBoxShadow(), getRounded()]" class="modal-content position-relative">
            <button *ngIf="styleOpts.closeBtnType === 'button'"
                class="btn btn-light d-flex align-items-center btn-close position-absolute m-0"
                (click)="closeMdl(!isOpen)">
                <span class="d-inline-flex flex-wrap align-self-stretch">
                    <img alt="Close window" [src]="getCloseIcon()" />
                </span>
                <span class="text-uppercase font-mont-semi-bold  pl-2">close window</span>
            </button>
            <button *ngIf="styleOpts.closeBtnType === 'single-icon'"
                class="btn btn-light d-flex align-items-center btn-close-icon position-absolute m-0 p-2"
                (click)="closeMdl(!isOpen)">
                <span class="d-inline-flex flex-wrap align-self-stretch">
                    <img alt="Close window" [src]="getCloseIcon(styleOpts.closeBtnType)" />
                </span>
            </button>
            <!-- <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div> -->
            <div [ngStyle]="{'--ept-mdl-bg': getBgClr()}" [ngClass]="[getRounded()]" class="modal-body">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
<app-ept-backdrop [isShow]="isOpen" [bgClr]="styleOpts.backDrop" (changeBdStatus)="closeMdl(!isOpen)">
</app-ept-backdrop>