import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SoleFormData } from '../../model';
import { DateValidator, EmailValidator } from 'src/app/utils/shared';

/**
 * @class SoleFormService
 */
@Injectable({
    providedIn: 'root'
})
export class SoleFormService {
    readonly form = this.formBuilder.group({
        businesAddressDifferent: [false, [Validators.required]],
        businessStartDate: ['', [Validators.required, DateValidator()]],
        businessType: ['SoleProprietorship', Validators.required],
        email: ['', [Validators.required, EmailValidator()]],
        DBA: ['', Validators.required],
        termsService: [true, [Validators.requiredTrue]],
        businessAddress: [''],
        city: [''],
        county: [''],
        state: [''],
        zipCode: [''],
    });
    isSolePropiertorApp = false;

    constructor(private formBuilder: FormBuilder) { }

    get controls() {
        return this.form.controls;
    }

    get enconde() {
        return <SoleFormData>{
            businessAddress: this.getControlValue('businessAddress'),
            businesAddressDifferent: JSON.parse(this.getControlValue('businesAddressDifferent')),
            businessStartDate: new Date(this.getControlValue('businessStartDate')),
            businessType: this.getControlValue('businessType'),
            city: this.getControlValue('city'),
            county: this.getControlValue('county'),
            email: this.getControlValue('email'),
            DBA: this.getControlValue('DBA'),
            state: this.getControlValue('state'),
            termsService: true,
            zipCode: this.getControlValue('zipCode'),
        }
    }

    get invalid() {
        return this.form.invalid;
    }

    get valid() {
        return this.form.valid;
    }

    /**
     * @private
     * @param {string} controlKey
     * @returns
     */
    private getControlById(controlKey: string) {
        return this.controls[controlKey];
    }

    /**
     *
     * @param {SoleFormData} data
     */
    decode({
        businessAddress,
        businesAddressDifferent,
        businessStartDate,
        businessType,
        city,
        county,
        email,
        DBA,
        state,
        zipCode,
    }: SoleFormData) {
        this.setValueToControl('businessType', businessType);
        this.setValueToControl('email', email);
        this.setValueToControl('DBA', DBA);
        this.setValueToControl('businessStartDate', businessStartDate);
        this.setValueToControl('businesAddressDifferent', businesAddressDifferent);
        this.setValueToControl('businessAddress', businessAddress);
        this.setValueToControl('zipCode', `${zipCode}`);
        this.setValueToControl('city', city);
        this.setValueToControl('state', state);
        this.setValueToControl('county', county);
    }

    /**
     *
     * @param {string} controlKey
     * @returns
     */
    getControlValue(controlKey: string) {
        return this.getControlById(controlKey).value;
    }

    /**
     *
     * @param {string} controlKey
     * @returns
     */
    getEncodedValue(controlKey: string) {
        return this.enconde[controlKey];
    }

    /**
     *
     */
    reset() {
        this.form.reset();
    }

    /**
     *
     * @param city
     * @param county
     * @param state
     * @param errors
     */
    setAddresData(city, county, state, errors = null) {
        this.setValueToControl('state', state);
        this.setValueToControl('city', city);
        this.setValueToControl('county', county);
        this.setErrors('zipCode', errors);
    }

    cleanAddressData() {
        this.setValueToControl('state', '');
        this.setValueToControl('city', '');
        this.setValueToControl('county', '');
        this.setValueToControl('zipCode', '');
    }

    /**
     *
     * @param {string} controlKey
     * @param errors
     */
    setErrors(controlKey: string, errors = null) {
        this.getControlById(controlKey).setErrors(errors);
    }

    /**
     *
     * @param {boolean} differentBusiness
     */
    setDefaultData(differentBusiness) {
        if(differentBusiness) {
            this.decode({
                businessType: 'SoleProprietorship',
                email: 'me@mail.com',
                DBA: 'My DBA Name',
                businessStartDate: new Date(2000, 10, 10),
                businesAddressDifferent: differentBusiness,
                termsService: true,
                businessAddress: '1182 Main St',
                zipCode: 84105,
                city: 'Salt Lake City'.toUpperCase(),
                state: 'UT',
                county: 'Salt Lake'
            });
        } else {
            this.decode({
                businessType: 'SoleProprietorship',
                email: 'me@mail.com',
                DBA: 'My DBA Name',
                businessStartDate: new Date(2000, 10, 10),
                businesAddressDifferent: differentBusiness,
                termsService: true
            });
        }
    }

    /**
     *
     * @param {string} controlKey
     * @param data
     */
    setValueToControl(controlKey: string, data) {
        this.getControlById(controlKey).setValue(data);
    }

    /**
     *
     */
    setZipCodeError() {
        this.setAddresData('', '', '', { 'wrong': true })
    }

    /**
     *
     * @param {boolean} state
     */
    validateDifferentBusiness(state: boolean) {
        if (state) {
            this.controls.businessAddress.addValidators([Validators.required]);
            this.controls.zipCode.addValidators([Validators.required]);
            this.controls.city.addValidators([Validators.required]);
            this.controls.state.addValidators([Validators.required]);
            this.controls.county.addValidators([Validators.required]);
            this.form.updateValueAndValidity();
        } else {
            this.controls.businessAddress.clearValidators();
            this.controls.businessAddress.updateValueAndValidity();
            this.controls.zipCode.clearValidators();
            this.controls.zipCode.updateValueAndValidity();
            this.controls.city.clearValidators();
            this.controls.city.updateValueAndValidity();
            this.controls.state.clearValidators();
            this.controls.state.updateValueAndValidity();
            this.controls.county.clearValidators();
            this.controls.county.updateValueAndValidity();
        }
    }
}
