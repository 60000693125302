import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export class CoSignerItem {

    form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
            authorize: ['false'],
            sameAsBusiness: [false],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            birthDate: ['', Validators.required],
            socialSecurityNumber: ['', Validators.required],
            ownershipPercentage: ['', Validators.required],
            mobilePhoneNumber: ['', Validators.required],
            homeAddress: ['', Validators.required],
            zipCode: ['', Validators.required],
            city: ['City Value', Validators.required],
            state: ['State value', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            creditAuth: [false, Validators.required],
            creditAuthTwo: [false]
        });
    }

    decode(data: any) {
        this.form.controls.authorize.setValue(data.primaryOwner ? 'true' : 'false');
        this.form.controls.firstName.setValue(data.firstName);
        this.form.controls.lastName.setValue(data.lastName);
        this.form.controls.birthDate.setValue(data.birthDate);
        this.form.controls.socialSecurityNumber.setValue(data.socialSecurityNumber);
        this.form.controls.ownershipPercentage.setValue(data.ownershipPercentage);
        this.form.controls.mobilePhoneNumber.setValue(data.mobilePhoneNumber);
        this.form.controls.homeAddress.setValue(data.streetAddress);
        this.form.controls.zipCode.setValue(data.zipCode);
        this.form.controls.city.setValue(data.city);
        this.form.controls.state.setValue(data.state);
        this.form.controls.creditAuth.setValue(data.creditAuth);
        this.form.controls.creditAuthTwo.setValue(data.creditAuthTwo);
        this.form.controls.email.setValue(data.email);
    }

    encode(): any {
        return {
            'firstName': this.form.controls.firstName.value,
            'lastName': this.form.controls.lastName.value,
            'birthDate': this.getFormatedDate(this.form.controls.birthDate.value),
            'socialSecurityNumber': this.form.controls.socialSecurityNumber.value,
            'ownershipPercentage': this.form.controls.ownershipPercentage.value,
            'mobilePhoneNumber': this.form.controls.mobilePhoneNumber.value,
            'streetAddress': this.form.controls.homeAddress.value,
            'zipcode': this.form.controls.zipCode.value,
            'city': this.form.controls.city.value,
            'state': this.form.controls.state.value,
            'country': 'USA',
            'primaryOwner': this.form.controls.authorize.value,
            'creditAuth': this.form.controls.creditAuth.value,
            'creditAuthTwo': this.form.controls.creditAuthTwo.value,
            'email': this.form.controls.email.value
        };
    }

    getFormatedDate(date: any): string {
        const year = date.getFullYear();
        const month = date.getMonth() > 8 ? '-' + (date.getMonth() + 1) : '-0' + (date.getMonth() + 1);
        const day = date.getDate() > 9 ? '-' + date.getDate() : '-0' + date.getDate();
        return year + month + day;
    }

    get oForm() { return this.form.controls; }

    getOwnerName(ownerNumber: number) {
        if (this.form.controls.firstName.value || this.form.controls.lastName.value) {
            return (
                this.form.controls.firstName.value ?
                    this.form.controls.firstName.value : '') + ' ' + (this.form.controls.lastName.value ?
                        this.form.controls.lastName.value : '');
        } else {
            return 'Owner ' + ownerNumber;
        }
    }

    setDefaultData(pOwnershipPercentage?: number) {
        const currentTime = (new Date()).getTime().toString();

        this.decode({
            firstName: 'Jane',
            lastName: 'Doe',
            birthDate: new Date(1990, 0, 1),
            socialSecurityNumber: currentTime.substr(currentTime.length - 9),
            ownershipPercentage: pOwnershipPercentage ? pOwnershipPercentage : 1,
            mobilePhoneNumber: '3219780000',
            streetAddress: '1182 Main St',
            zipCode: '84105',
            city: 'Salt Lake City'.toUpperCase(),
            state: 'UT',
            creditAuth: true,
            creditAuthTwo: false,
            email: 'me@mail.com'
        });
    }
}
