import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';


export interface IMoneyStep{
    financingRequested: number;
}

export class MoneyStepItem {
    form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService) {
        this.form = this.formBuilder.group({
            financingRequested : ['', [Validators.required, Validators.min(500), Validators.minLength(2)]],
        });


    }

    decode(data: IMoneyStep) {
        this.form.controls.financingRequested.setValue(data.financingRequested);

    }

    enconde(): IMoneyStep {
        return {
            financingRequested: this.form.controls.financingRequested.value
        }
    }

    setDefaultData() {
        this.decode(
            {
                financingRequested: 15000,
            }
        );
    }

}
