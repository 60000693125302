import { Component, OnInit, Injectable, HostListener, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
@Injectable()
export class HeaderComponent implements OnInit {
    token: any;
    navCollapsed = true;
    navSmall = false;
    applyOpen = false;
    adminOpen = false;
    riskOpen = false;
    leasableOpen = false;
    userOptionsOpen = false;
    vendorOptionsOpen = false;
    userAdmin = false;
    salesVPerson = null;
    userRisk = false;
    vendorSelected = null;
    vendors = null;
    impersonatedUser: any = null;
    searchVendorForm: UntypedFormGroup;
    vendorLogo: string;
    leasableUser = false;
    leasableAdmin = false;

    @Input() isV3: boolean = false;

    private defaultLogo = '/assets/images/click-lease-logo.png';

    constructor(
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras && navigation.extras.state) {
            this.token = navigation.extras.state.token ? navigation.extras.state.token : null;
        } else {
            this.token = this.authService.getQueryToken;
        }

        this.salesVPerson = this.authService.getVendors();
        if (!this.route.snapshot.queryParams.token && this.salesVPerson != null) {
            this.searchVendorForm = this.formBuilder.group({
                search: ['']
            });
        }
    }

    get logged() {
        return !this.token && this.salesVPerson != null;
    }


    hideUser() {

        return this.authService.getExcludePortalApp();

    }

    ngOnInit() {
        if (!this.token) {
            this.token = this.route.snapshot.queryParams.token;
        }
        let userData = this.authService.getLoggedUser();
        if (!this.token && userData) {
            this.navCollapsed = true;
            this.userAdmin = userData.roles.filter((f: any) => f.id === 1).length > 0;
            this.salesVPerson = this.authService.getVendors();
            this.userRisk = userData.roles.filter((f: any) => f.id === 6).length > 0;
            this.leasableUser = userData.roles.filter((f: any) => f.id === 12).length > 0;
            this.leasableAdmin = userData.roles.filter((f: any) => f.id === 11).length > 0;

            this.vendorList();
            this.searchVendorForm.controls.search.setValue('');
        }
        this.changeImageVendor();
    }


    toggleNav() {
        this.navCollapsed = !this.navCollapsed;
    }

    logout() {
        this.authService.signOutUser();
    }

    toggleApply() {
        this.applyOpen = !this.applyOpen;
    }

    navigateToApplication() {
        let ref: string = '/edgar';
        this.router.navigateByUrl(ref);
    }

    toggleAdmin() {
        this.adminOpen = !this.adminOpen;
    }

    toggleRisk() {
        this.riskOpen = !this.riskOpen;
    }

    toggleUserOptions() {
        this.userOptionsOpen = !this.userOptionsOpen;
    }

    toggleVendorOptions() {
        this.vendorOptionsOpen = !this.vendorOptionsOpen;
    }

    vendorList() {
        if (!localStorage.getItem("vendSelected_data")) {
            this.vendorSelected = this.salesVPerson[0];
        } else {
            this.vendorSelected = JSON.parse(localStorage.getItem("vendSelected_data"));
        }
        this.vendors = this.salesVPerson;
    }

    changeVendor(vendor: any) {
        this.vendorSelected = this.vendors.filter((fvendor: any) => fvendor.vendorId == vendor.vendorId)[0];
        localStorage.setItem("vendSelected_data", JSON.stringify(this.vendorSelected));
        this.changeImageVendor();
        window.location.reload();
    }

    changeImageVendor() {
        this.authService.vendorTokenInfo.then(
            (data: any) => {
                if (data.logoUrl) {
                    this.vendorLogo = data.logoUrl;
                } else if (this.token) {
                    this.vendorLogo = this.defaultLogo;
                } else if (this.vendorSelected && this.vendorSelected.logoUrl) {
                    this.vendorLogo = this.vendorSelected.logoUrl;
                } else {
                    this.vendorLogo = this.defaultLogo;
                }
            }
        );
    }

    onClearBroker(reload?: boolean) {
        this.vendors.forEach((vendor: any) => {
            if (vendor.vendorType == 'BROKER' || vendor.vendorType == 'INDYREP') {
                this.vendorSelected = vendor;
            }
        });
        localStorage.setItem("vendSelected_data", JSON.stringify(this.vendorSelected));
        if (reload) {
            window.location.reload();
        }
    }

    onClearImpersonatedVendor() {
        this.authService.clearImpersonatedVendor();
        window.location.reload();
    }

    isImpersonating() {
        this.impersonatedUser = this.authService.getImpersonatedUser();
        return this.impersonatedUser;
    }

    get vFilter() {
        return 'vendorName';
    }

    get vFilterValue() {
        return this.searchVendorForm.controls.search.value;
    }

    get applyOptionDisabled() {
        return this.authService.applyOptionDisabled;
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (number > 50) {
            this.navSmall = true;
        } else {
            this.navSmall = false;
        }
    }
}
