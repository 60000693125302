<app-header></app-header>
<div class="body-container">
    <div class="row">
        <div class="col-12 models-list">
            <div class="row decision-model-title-container">
                <h1 class="decision-title font-mont-regular">Vendor List</h1>
                <button class="btn btn-secondary font-mont-regular float-right" type="submit"
                    (click)="onCreateNewModelSubmit()" id="createNewDecisionModelOpenDlgBtn">
                    <div class="tooltip-two">
                        <div class="plus-area">
                            <i class="fas fa-plus"></i>
                        </div>
                        <span class="tooltip-two-text">
                            Click here to add a new vendor.
                        </span>
                    </div>
                </button>
            </div>
            <div class="form-row">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20" (click)="onSortClick('dbaName')">
                                    DBA
                                </div>
                                <div class="font-mont-semi-bold margin-r-20" (click)="onSortClick('dbaName')">
                                    Name
                                </div>
                                <i class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'dbaName'"></i>
                                <i class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'dbaName'"></i>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20" (click)="onSortClick('accountName')">
                                    Account
                                </div>
                                <div class="font-mont-semi-bold margin-r-20" (click)="onSortClick('accountName')">
                                    Name
                                </div>
                                <i class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'accountName'"></i>
                                <i class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'accountName'"></i>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center" (click)="onSortClick('type')">
                                    Type</div>
                                <i class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'type'"></i>
                                <i class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'type'"></i>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center" (click)="onSortClick('id')">
                                    ID
                                </div>
                                <i class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'id'"></i>
                                <i class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'id'"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let vendor of vendors | filter:filter:filterValue | sort:sortField:invertedSort; let i = index">
                            <tr (click)="toggleSelectedRow(i, vendor.id);" class="row-data cursor-pointer"
                                [ngClass]="{'selected': selectedRow == i}" [attr.id]="'itemNumber_' + i">
                                <td scope="row">{{vendor.dbaName}}</td>
                                <td class="text-center">{{vendor.accountName}}</td>
                                <td class="text-center">{{vendor.type}}</td>
                                <td class="text-center">{{vendor.id}}</td>
                            </tr>
                            <tr class="no-highlight summary" [ngClass]="{'selected': selectedRow == i}">
                                <td colspan="4">
                                    <div *ngIf="tempSelectedRow == i">
                                        <app-vendor-list-item [vendor]="vendor" (itemLoaded)="onItemLoaded();"
                                            (getVendorList)="getVendorList($event);" [industries]="industries"
                                            [businessTypes]="businessTypes"
                                            [statesOfIncorporation]="statesOfIncorporation"
                                            [equipmentClassifications]="equipmentClassifications"
                                            [vendorTypes]="vendorTypes" (showErrorMessage)="showErrorMessage($event);">
                                        </app-vendor-list-item>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addNewModelModal" tabindex="-1" role="dialog" aria-labelledby="addNewModelModal"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                    <img src="/assets/images/icons/add_blue.svg" />
                    ADD NEW VENDOR
                </h5>
                <form [formGroup]="newVendorForm" (ngSubmit)="onCreateSubmit();">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="dbaName" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.dbaName.errors }">DBA
                                        Name:*</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.dbaName.errors }"
                                        id="dbaName" type="text" formControlName="dbaName" class="form-control"
                                        placeholder="dbaName" name="dbaName">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.dbaName.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.dbaName.errors.required">
                                            DBA Name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="accountName" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.accountName.errors }">Account
                                        Name:*</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.accountName.errors }"
                                        id="accountName" type="text" formControlName="accountName" class="form-control"
                                        placeholder="Account Name" name="accountName">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.accountName.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.accountName.errors.required">
                                            Account Name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="email" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.email.errors }">Email:*</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.email.errors }"
                                        id="email" type="text" formControlName="email" class="form-control"
                                        placeholder="Email" name="email">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.email.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.email.errors.required">
                                            Email is required.
                                        </div>
                                        <div *ngIf="newVendorForm.controls.email.errors.email">
                                            Email format error.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="phone" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.phone.errors }">Phone:*</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.phone.errors }"
                                        id="phone" type="text" formControlName="phone" class="form-control"
                                        placeholder="Phone" name="phone" [showMaskTyped]="true" mask="(000) 000-0000">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.phone.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.phone.errors.required">
                                            Phone is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row form-group-end">
                                <div class="form-group col-6">
                                    <label for="entityType" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.entityType.errors }">Entity
                                        Type: *</label>
                                    <select formControlName="entityType" class="form-control" id="entityType"
                                        name="entityType"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.entityType.errors }">
                                        <option *ngFor="let businessType of businessTypes"
                                            [ngValue]="businessType.code">
                                            {{businessType.name}}</option>
                                    </select>
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.entityType.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.entityType.errors.required">
                                            Entity Type is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="federalTaxId" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.federalTaxId.errors }">Federal
                                        Tax Id:*</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.federalTaxId.errors }"
                                        id="federalTaxId" type="text" formControlName="federalTaxId"
                                        class="form-control" placeholder="99-9999999" name="federalTaxId"
                                        [showMaskTyped]="true" mask="00-0000000">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.federalTaxId.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.federalTaxId.errors.required">
                                            Federal Tax Id is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="equipmentClassification" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.equipmentClassification.errors }">
                                        Equipment Classification: *</label>
                                    <select formControlName="equipmentClassification" class="form-control"
                                        id="equipmentClassification"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.equipmentClassification.errors }">
                                        <option *ngFor="let equipmentClassification of equipmentClassifications"
                                            [ngValue]="equipmentClassification">{{equipmentClassification}}
                                        </option>
                                    </select>
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.equipmentClassification.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.equipmentClassification.errors.required">
                                            equipmentClassification is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="industry" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.industry.errors }">Industry:
                                        *</label>
                                    <select formControlName="industry" class="form-control" id="industry"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.industry.errors }">
                                        <option *ngFor="let industry of industries" [ngValue]="industry.code">
                                            {{industry.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.industry.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.industry.errors.required">
                                            Industry is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row form-group-end">
                                <div class="form-group col-6">
                                    <label for="address" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.address.errors }">Address:*</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.address.errors }"
                                        id="address" type="text" formControlName="address" class="form-control"
                                        placeholder="Address" name="address">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.address.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.address.errors.required">
                                            Address is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="zipCode" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.zipCode.errors }">Zip
                                        Code:*</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.zipCode.errors }"
                                        id="zipCode" type="text" formControlName="zipCode" class="form-control"
                                        placeholder="Zip Code" name="zipCode" (keyup)="onZipCodeKeyUp()">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.zipCode.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.zipCode.errors.required">
                                            Zip Code is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="state" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.state.errors }">State:
                                        *</label>
                                    <select formControlName="state" class="form-control" id="state"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.state.errors }">
                                        <option *ngFor="let state of statesOfIncorporation" [ngValue]="state.code">
                                            {{state.name}}</option>
                                    </select>
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.state.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.state.errors.required">
                                            State is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <label for="city" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.city.errors }">City:
                                        *</label>
                                    <select formControlName="city" class="form-control" id="city"
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.city.errors }">
                                        <option *ngFor="let city of cities" [ngValue]="city">{{city}}</option>
                                    </select>
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.city.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.city.errors.required">
                                            City is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-6">
                                    <label for="secondaryContactEmail" class="col-form-label"
                                        [ngClass]="{ 'color-red': addNewVendorSubmitted && newVendorForm.controls.secondaryContactEmail.errors }">Secondary
                                        Contact
                                        Email:</label>
                                    <input
                                        [ngClass]="{ 'is-invalid': addNewVendorSubmitted && newVendorForm.controls.secondaryContactEmail.errors }"
                                        id="secondaryContactEmail" type="text" formControlName="secondaryContactEmail"
                                        class="form-control" placeholder="Secondary Contact Email"
                                        name="secondaryContactEmail">
                                    <div *ngIf="addNewVendorSubmitted && newVendorForm.controls.secondaryContactEmail.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newVendorForm.controls.secondaryContactEmail.errors.email">
                                            Email format error.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <div class="toggle-wrapper">
                                        <div class="toggle checkcross">
                                            <input id="excludeLessee" type="checkbox" formControlName="excludeLessee" />
                                            <label class="toggle-item" for="excludeLessee">
                                                <div class="check"></div>
                                            </label>
                                        </div>
                                        <div class="name">Exclude Lessee</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right">
                                ADD VENDOR
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageBClass]="messageMessageBClass" [dialogClass]="dialogClass"></app-error-dialog>