<app-modals-wrapper (closeModal)="onCloseModal()" [close]="true" [modalOff]="true">
    <ng-template appApplicationWrapperHelper>
        <div class="sizeContent" [ngClass]="{ 'expired-content': approvalExpired}" #divView>
            <ng-container *ngIf="!approvalExpired">
                <app-otp-input
                    [otpRose]="otpRose"
                    [started]="started"
                    [usaPhoneNumber]="usaPhoneNumber"
                    [leadId]="leadId"
                    [invalidCode]="invalidCode"
                    [useService]="useService"
                    (invalidCodeChange)="invalidCodeChange($event);"
                    (closeModal)="closeModal($event);"
                    (OTPData)="OTPData($event);"
                    
                ></app-otp-input>
            </ng-container>

            <ng-container *ngIf="approvalExpired">
                <div class="approval-expired-content">
                    <h1 class="font-mont-bold">We’re sorry, the {{requestedAmount | currency:'$ ':'code':'.0-0'}} you were approved for is no longer available.</h1>
                    <p class="font-mont-regular">Don’t worry, you can apply again for a new lease.</p>
                    <button type="submit" class="btn-secondary btn-lg submit-btn w-100 font-mont-bold" (click)="onContinue()">Apply Now</button>
                </div>
            </ng-container>
        </div>
    </ng-template>
</app-modals-wrapper>
