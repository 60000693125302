import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-started-success',
  templateUrl: './started-success.component.html',
  styleUrls: ['./started-success.component.css']
})
export class StartedSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
