import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-token-error-v3',
  templateUrl: './token-error-v3.component.html',
  styleUrls: ['./token-error-v3.component.css']
})
export class TokenErrorV3Component implements OnInit {
  token = '2e68abb0-2d07-43c4-8e97-eba6c6a24a05';
  message: any;
  screen: string;
  showData: boolean;

  private data: string = 'token-error';
  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private authService: AuthService
    ) {
    const navigation = this.router.getCurrentNavigation();
      if (navigation.extras.state) {
        this.token = navigation.extras.state.token;
        this.data = navigation.extras.state.data ? navigation.extras.state.data : this.data;
        this.screen = navigation.extras.state.path ? navigation.extras.state.path : '';
      } else {
        this.router.navigateByUrl('/');
      }
   }

  ngOnInit(): void {
    this.createMessage();
    if(this.data.indexOf('click-sign') !== -1) {
      this.googleAnalyticsService.trackClickSignEvent('token-error', this.authService.vendorReferrerName, this.data);
    } else {
      this.googleAnalyticsService.trackV3Event('token-error', this.authService.vendorReferrerName, this.data);
    }
  }

  createMessage() {
    switch(this.screen) {
      case 'upload-license' :
        this.token = "";
        this.showData = false;
        this.message = {
          image: "/assets/images/resolve.svg",
          title: "You're done!" ,
          text1: "This link expired since we already received and approved a copy of your drivers license.",
          email: "",
          text2: "Please contact your dealer to finalize your equipment purchase.",
        };
        break;
      default :
        this.showData = true;
        this.message = {
          image: "/assets/images/icons/application-errors/invalid-token-icon.svg",
          title: "Sorry, your link has expired" ,
          text1: "Please  contact your sales representative to request a new link OR you can reach out to Clicklease support via email",
          email: "CustomerCare@ClickLease.com",
          text2: "Please copy and paste the token below in your email to better assist you.",
        };
        break;            
    }
  }
}

 
