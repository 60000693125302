import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { IMoneyStep } from 'src/app/application/v3/model';

@Injectable({
    providedIn: 'root'
})
export class MoneyFormService {
    readonly form = this.formBuilder.group({
        financingRequested: ['', [
            Validators.required,
            Validators.min(500),
            Validators.minLength(2)
        ]],
    });
    maxApplicationAmount = 0;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService
    ) { }

    get controls() {
        return this.form.controls;
    }

    get enconde() {
        return <IMoneyStep>{
            financingRequested: Number(this.getControlValue('financingRequested'))
        }
    }

    get invalid() {
        return this.form.invalid;
    }

    get valid() {
        return this.form.valid;
    }

    private getControlById(key: string) {
        return this.controls[key];
    }

    private initFormsValidators() {
        this.maxApplicationAmount = 0
        this.getControlById('financingRequested').setValidators([
            Validators.required,
            Validators.min(500),
            Validators.minLength(2),
        ]);
        this.updateValidatity();
    }

    private updateValidatity() {
        this.form.controls.financingRequested.updateValueAndValidity();
    }



    decode({ financingRequested }: IMoneyStep) {
        this.setValueToControl('financingRequested', financingRequested);
    }

    getControlValue(key: string) {
        return this.getControlById(key).value;
    }

    getControlInvalid(key: string) {
        return this.getControlById(key).invalid;
    }

    getEncodedValue(key: string) {
        return this.enconde[key];
    }

    reset() {
        this.form.reset();
    }

    setDefaultData() {
        this.decode({
            financingRequested: 15000,
        });
    }

    setErrors(key: string, errors = null) {
        this.getControlById(key).setErrors(errors);
    }

    setValueToControl(key: string, data) {
        this.getControlById(key).setValue(data);
    }
}
