
<app-header [hidden]="showData" [isV3]="true"></app-header>

<div [ngClass]="{'error-content-DL':!showData}" class="error-content">
           
        
    <img [ngClass]="{'imgDL':!showData}" src="{{message.image}}"/>
    
    
    <div [ngClass]="{'error-content-title':!showData}" class="font-mont-bold">{{message.title}}</div>    
    <div [ngClass]="{'error-content-text':!showData}" class="font-mont-regular">{{message.text1}} <br><span class="font-mont-regular">{{message.email}}</span></div>
    <div [ngClass]="{'error-content-text':!showData}" class="font-mont-regular">{{message.text2}} <br><span *ngIf="showData" class="font-mont-bold">Token: </span>{{token}}</div>    
    <div [ngClass]="{'footer-content-DL':!showData}" class="footer-message font-mont-regular">Need help? Have questions? Call <span class="font-mont-regular">1 (888) 509-5592</span></div>
</div>
