import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-header-send-upsell-application',
    templateUrl: './header-send-upsell-application.component.html',
    styleUrls: ['./header-send-upsell-application.component.css']
})
export class HeaderSendUpsellApplicationComponent implements OnInit {

    public sendForm: UntypedFormGroup;

    public submitted = false;
    public errorTitle = '';
    public errorMessageA = '';
    public errorMessageB = '';
    public errorMessageC = '';
    public errorMessageBClass = '';
    public dialogClass = '';

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.sendForm = this.formBuilder.group({
            code: ['', Validators.required]
        });
    }

    ngOnInit() {
    }

    sendApp() {
        this.submitted = true;
        if (this.sendForm.invalid) {
            return;
        }

        const promise = new Promise((resolve, reject) => {
            this.connectionService.postRequest(environment.gateway + 'upsell/' + this.sendForm.controls.code.value, {}, 'application/json').subscribe(
                (data: any) => {
                    this.errorTitle = 'Application sent.';
                    this.errorMessageA = 'Your application was successfully sent by email.';
                    this.errorMessageB = '';
                    this.errorMessageC = '';
                    this.errorMessageBClass = '';
                    this.dialogClass = 'w450';
                    $('#headerSendUppsellApplicationModal').modal('hide');
                    $('#errorModalDialogSendApplicationUpsellCompleted').modal();
                    this.sendForm = this.formBuilder.group({ code: ['', Validators.required] });
                    this.submitted = false;
                    resolve(data);
                },
                (error: any) => {
                    this.errorTitle = 'Sorry, we found a problem';
                    this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                    this.errorMessageB = '';
                    this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                    this.errorMessageBClass = 'mB20';
                    this.dialogClass = 'w600';
                    $('#headerSendUppsellApplicationModal').modal('hide');
                    $('#errorModalDialogSendApplicationUpsellCompleted').modal();
                    this.sendForm = this.formBuilder.group({ code: ['', Validators.required] });
                    this.submitted = false;
                    reject(error);
                }
            );
        });
        return promise;
    }
}
