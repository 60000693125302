import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-error',
  templateUrl: './data-error.component.html',
  styleUrls: ['./data-error.component.css']
})
export class DataErrorComponent implements OnInit {
  @Input() processStatus: any = '';

  constructor() { }

  ngOnInit() {
  }

}
