  <div *ngIf="searchClass; else modalFilter">
    <div class="input-wrapper">
      <input class=" icon popoverFilter" id="vendorName"
      placeholder="Business name" [formControl]="vendorName" [ngModel]="selectedVendor.name"
      name="vendorName" (keyup)="onVendorNameKeyUp(0, 5)" autocomplete="off">
      <ul class="vendorList" *ngIf="vTotalElements > 0">
        <li class="filter" *ngFor="let vendor of vendorData.records" (click)="onVendorClick(vendor)">
          <label for="vendorName">{{ vendor.name }} </label>
        </li>
      </ul>   
    </div>
</div>
<ng-template #modalFilter>
  <div class="input-wrapper">
    <input class="modalFilter" id="vendorName"
    placeholder="Business name" [formControl]="vendorName" [ngModel]="selectedVendor.name"
    name="vendorName" (keyup)="onVendorNameKeyUp(0, 5)" autocomplete="off">
    <ul class="vendorList" *ngIf="vTotalElements > 0">
      <li class="searchRentagle" *ngFor="let vendor of vendorData.records" (click)="onVendorClick(vendor)">
        <label for="vendorName">{{ vendor.name }} </label>
      </li>
    </ul>
  </div>
   
</ng-template>

  