
export function getCityRightCounty(city: string, counties: string[], cityCounties: any) {
    let possibleCounties: string[] = [];
    if(cityCounties) {
        cityCounties.forEach((cityCounty: any) => {
            if(cityCounty.city.toUpperCase() === city.toUpperCase()) {
                if(!possibleCounties.includes(cityCounty.county)) {
                    possibleCounties.push(cityCounty.county);
                }
            }
        });
    }
    let rightCounty: string;
    if(possibleCounties.includes(counties[0])) {
        rightCounty = counties[0];
    } else {
        rightCounty = cityCounties ? possibleCounties[0] : counties[0];
    }
    return { rightCounty, possibleCounties };
  }