<app-application-wrapper [subTitleA]="'LEASE AGREEMENT'" [subTitleB]="'FINAL STEP'">
    <ng-template appApplicationWrapperHelper>
        <div>
            <h1 class="title font-mont-bold">Final step! Upload your drivers license</h1>
            <p class="font-mont-regular">
                We just sent a text message directing you to upload a picture of the front and back of your driver’s
                license.
            </p>
            <p class="font-mont-bold">Follow the link from your mobile device to finalize your transaction.</p>
            <p class="mobile-text font-mont-regular">If you did not receive the text message,
                <a class="font-mont-bold"
                    href="https://home-c35.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=97e77c7f-d8a6-4af0-95eb-f8975110c562&bu=4599274"
                    target="_blank">chat with us here</a>, or call us at <span
                    class="font-mont-bold">1-888-509-5592</span>.
            </p>
        </div>
    </ng-template>
    <ng-container ngProjectAs="[image]">
        <img src="../../../../assets/images/licenseLogo.svg" alt="">
    </ng-container>
</app-application-wrapper>