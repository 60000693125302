<div class="modal-step-one" *ngIf="!resendView">
    <h1 *ngIf="!codeResended" class="font-mont-bold">We just texted you</h1>
    <h2 *ngIf="codeResended" class="font-mont-bold">We just sent another text</h2>
    <div *ngIf="codeResended" class="popup-subtitle-upper font-mont-regular">We just sent a text to
        <span *ngIf="started" class="font-mont-bold">{{phoneClicked | mask:'(000) 000-0000'}}</span>
        <span *ngIf="!started" class="font-mont-bold">(XXX) XXX-{{usaPhoneNumber | lastFourDigits}}</span> with a new one-time passcode. If you’re still
        having trouble, please contact our support team at (888) 509-5592.</div>
    <div *ngIf="!codeResended" class="popup-subtitle font-mont-regular">Enter the 4-digit code we just sent to
        <span *ngIf="started" class="font-mont-regular">{{phoneClicked | mask:'(000) 000-0000'}}</span>
        <span *ngIf="!started" class="font-mont-regular">(XXX) XXX-{{usaPhoneNumber | lastFourDigits}}</span>.</div>
    <div *ngIf="codeResended" class="popup-subtitle-resend font-mont-regular">Enter the 4-digit code below.</div>
    <form [formGroup]="form">
        <input
            *ngFor="let input of formInput; index as i"
            inputmode="numeric"
            #formRow
            type="text"
            formControlName="{{input}}"
            class="form-control font-mont-semi-bold"
            maxlength="1"
            (input)="keyUpEvent($event, i)"
            (paste)="onPaste($event)"
            mask="0"
            [ngClass]="{ 'invalid-code': invalidCode }"
            >
    </form>
    <button type="submit" class="cls-primary-btn font-mont-semi-bold" (click)="onSubmit()"
    [disabled]="!form.valid">Verify</button>
    <div *ngIf="!codeResended" class="resend-text font-mont-regular" [ngClass]="{ 'invalid-code': invalidCode }">
        {{invalidCode ? 'Invalid code entered ' : 'Didn’t receive the code? '}} <span class="font-mont-semi-bold"
            (click)="onResend()">Resend</span></div>
    <div *ngIf="codeResended && invalidCode" class="resend-text font-mont-regular invalid-code">Invalid code entered</div>
</div>
<div class="modal-step-two" *ngIf="resendView && started">
    <h1 class="font-mont-bold">Right Phone number?</h1>
    <p class="font-mont-regular">Let's make sure you have the right phone number. Right now we have:</p>
    <form [formGroup]="startedForm">
        <input inputmode="numeric" type="text" formControlName="mobilePhoneNumber" class="form-control font-mont-bold"
            id="mobilePhoneNumber" name="mobilePhoneNumber" [showMaskTyped]="true" mask="(000) 000-0000"
            [ngClass]="{ 'is-invalid': startedForm.controls.mobilePhoneNumber.touched && startedForm.controls.mobilePhoneNumber.errors }">
        <img class="edit-input-icon" src="\assets\images\icons\application-started\edit_phoneNumber-icon.svg" />
        <div *ngIf="startedForm.controls.mobilePhoneNumber.touched && startedForm.controls.mobilePhoneNumber.errors"
            class="invalid-feedback">
            <div class="font-mont-bold" *ngIf="startedForm.controls.mobilePhoneNumber.errors.required">
                Mobile phone number is required.
            </div>
            <div class="font-mont-bold"
                *ngIf="startedForm.controls.mobilePhoneNumber.errors.wrong || startedForm.controls.mobilePhoneNumber.errors.mask">
                Mobile phone number is not correct.
            </div>
        </div>
    </form>
    <button [disabled]="!startedForm.controls.mobilePhoneNumber.valid"
        class="cls-primary-btn font-mont-semi-bold" (click)="sendNewCode()">Send new code</button>
    <div class="resend-text font-mont-regular">Still having trouble? Call <span
            class="font-mont-semi-bold">1-888-509-5592</span></div>
</div>
<div class="modal-step-two" *ngIf="resendView && !started">
    <h1 class="font-mont-bold">Send another text</h1>
    <p class="font-mont-regular">A new OTP code will be sent to the number we have on file.</p>
    <div class="mobile-number-check font-mont-regular">Send text message to (XXX) XXX-{{usaPhoneNumber | lastFourDigits}}</div>
    <button class="cls-primary-btn font-mont-semi-bold" (click)="sendNewCode()">Send a new
        code</button>
    <div class="resend-text font-mont-regular">Still having trouble? Call <span
            class="font-mont-semi-bold">1-888-509-5592</span></div>
</div>
