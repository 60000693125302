import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ClickSingService } from 'src/app/click-sing';

@Component({
    selector: 'app-docusign-page',
    templateUrl: './docusign-page.component.html',
    styleUrls: ['./docusign-page.component.css']
})
export class DocusignPageComponent implements OnInit {
    private contractUrl: string = null;
    private accesToken : string;
    private token : string;

    public emitApplicationId: string;
    docuSignUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private clickSingService: ClickSingService,
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state) {
            this.contractUrl = navigation.extras.state.docusignUrl;
            this.accesToken = navigation.extras.state.accesToken;
            this.emitApplicationId = navigation.extras.state.emitApplicationId;
            this.token = navigation.extras.state.token;
        } else this.errorListService.redirectToV3Error(
            null,
            'docusign-url-params',
            'click-sign-otp'
        );
    }

    get development() {
        return this.clickSingService.isDevelopment;
    }

    private redirectToError(event) {
        this.router.navigate(['../docusign-failed'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            state: { event }
        });
    }

    private redirectToFinalStep(event) {
        this.router.navigate(['../final-step'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            state: { event }
        });
    }

    ngOnInit() {
        this.docuSignUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.contractUrl);
        this.googleAnalyticsService.trackClickSignEvent(
            'contract-signing-open',
            this.clickSingService.vendorReferrerName
        );
    }

    goToFinalStep() {
        this.router.navigate(['../final-step'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            state: { event: '' }
        });
    }

    goToFailedStep() {
        this.router.navigate(['../docusign-failed'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            state: { event: 'exception' }
        });
    }

    goToError() {
        this.router.navigate(['/general-error-v3'], {
            queryParamsHandling: 'merge',
            state: {
                endpoint: 'docusign-url-params',
                start: 'click-sign-otp',
                error: false
            }
        });
    }

    goToFailPage() {  
        this.clickSingService.kbaDocusignFailed(this.emitApplicationId,this.accesToken,
            this.token).then(
            (data) => {
                if(data.code == '200' && !data.msg.includes('0')) {
                    this.router.navigate(['../try-again'], { relativeTo: this.activatedRoute, queryParamsHandling: "merge", state: { token: this.token, appId: this.emitApplicationId, accessToken: this.accesToken } }); 
                } else this.router.navigate(['../declined'], { relativeTo: this.activatedRoute, queryParamsHandling: "merge" });
            }
        ).catch(
            (error) => {
                console.log(error);
                if(error && error.error && error.error.code === '412'){
                    this.router.navigate(['../declined'], { relativeTo: this.activatedRoute, queryParamsHandling: "merge" });
                } else this.errorListService.redirectToV3Error(
                    error,
                    'registerDocusignAuthenticationError',
                    'click-sign-otp'
                );
            }
        );
    }           

    @HostListener('window:message', ['$event'])
    onMessage(e) {
        if (e.isTrusted && e.data && e.data.type == 'docu-sign') {
            this.googleAnalyticsService.trackClickSignEvent(
                'contract-signing-finish',
                this.clickSingService.vendorReferrerName,
                this.emitApplicationId
            );

            if (e.data.event == 'signing_complete' || e.data.event == 'viewing_complete') {
                this.redirectToFinalStep(e.data.event);
            } else if(e.data.event == 'id_check_failed'){
                this.goToFailPage();
            } else this.redirectToError(e.data.event);
        }
    }
}
