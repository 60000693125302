import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggleCollapseService {
  private isCollapsed = true;

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
      const hiddenDiv = document.getElementById("d-hidden");
      const collapsedParagraph = document.getElementById("p-collapsed-expanded");
      const spanReadMore = document.getElementById('span-readmore');
      if (this.isCollapsed === false) {
        hiddenDiv.classList.remove("div-min-heigth");
        hiddenDiv.classList.add("div-max-heigth");

        collapsedParagraph.classList.add("expanded");
        spanReadMore.classList.add("span-readless");

      } else {
        hiddenDiv.classList.remove("div-max-heigth");
        hiddenDiv.classList.add("div-min-heigth");
        collapsedParagraph.classList.remove("expanded");
        spanReadMore.classList.remove("span-readless");
      }

  }

  getIsCollapsed(): boolean {
    return this.isCollapsed;
  }

}
