import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
declare var $: any;

@Component({
    selector: 'app-additional-resend-dl-link',
    templateUrl: './additional-resend-dl-link.component.html',
    styleUrls: ['./additional-resend-dl-link.component.css']
})
export class AdditionalResendDlLinkComponent implements OnInit {

    @Input() id = 0;

    @Output() reloadSlice = new EventEmitter<void>();

    sendForm: UntypedFormGroup;

    submitted = false;
    errorTitle = '';
    errorMessageA = '';
    errorMessageB = '';
    errorMessageC = '';
    errorMessageBClass = '';
    dialogClass = '';

    modalId = 'additionalResendDlLinkModal' + this.id;
    modalIdE = 'AdditionalResendDlLinkCompleted' + this.id;

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        public googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.sendForm = this.formBuilder.group({
            radio: ['email'],
            email: ['', [Validators.required, Validators.email]],
            text: ['']
        }, {
            validator: this.couldBeRequired(['email', 'text'])
        });
    }

    ngOnInit() {
        this.modalId = 'additionalResendDlLinkModal' + this.id;
        this.modalIdE = 'AdditionalResendDlLinkCompleted' + this.id;
    }

    onClick() {
        this.googleAnalyticsService.trackEvent('additional-options', 'open', 'resend-dl-link');
        $('#additionalResendDlLinkModal' + this.id).modal();
    }

    sendApp() {
        this.submitted = true;
        if (this.sendForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            const postData = {
                userEmail: '', //this.authService.getEmail(),
                ownerEmail: this.sendForm.controls.radio.value == 'email' ? this.sendForm.controls.email.value : this.sendForm.controls.text.value,
                salesPersonId: this.authService.getSalesPersonId()
            };
            this.connectionService.postRequest(environment.gateway + '/v2/send/dl/' + this.id, postData, 'application/json').subscribe(
                (data: any) => {
                    this.googleAnalyticsService.trackEvent('additional-options', 'sent', 'resend-dl-link');
                    this.errorTitle = 'DL Link sent.';
                    this.errorMessageA = 'Your application DL Link was successfully sent by ' + (this.sendForm.controls.radio.value == 'email' ? 'email' : 'text') + '.';
                    this.errorMessageB = '';
                    this.errorMessageC = '';
                    this.errorMessageBClass = '';
                    this.dialogClass = 'w450';
                    $('#additionalResendDlLinkModal' + this.id).modal('hide');
                    $('#errorModalDialogAdditionalResendDlLinkCompleted' + this.id).modal();
                    this.sendForm = this.formBuilder.group({
                        radio: ['email'], email: [''], text: ['']
                    }, { validator: this.couldBeRequired(['email', 'text']) });
                    this.submitted = false;
                    resolve(data);
                },
                (error: any) => {
                    this.errorTitle = 'Sorry, we found a problem';
                    this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                    this.errorMessageB = error.error.msg;
                    this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                    this.errorMessageBClass = 'mB20';
                    this.dialogClass = 'w600';
                    $('#additionalResendDlLinkModal' + this.id).modal('hide');
                    $('#errorModalDialogAdditionalResendDlLinkCompleted' + this.id).modal();
                    this.sendForm = this.formBuilder.group({
                        radio: ['email'], email: [''], text: ['']
                    }, { validator: this.couldBeRequired(['email', 'text']) });
                    this.submitted = false;
                    reject(error);
                }
            );
        });
    }

    couldBeRequired(controlNames: string[]) {
        return (formGroup: UntypedFormGroup) => {
            controlNames.forEach(controlName => {
                const control = formGroup.controls[controlName];
                if (controlName == formGroup.controls['radio'].value && control.value == '') {
                    control.setErrors({ required: true });
                } if (controlName != formGroup.controls['radio'].value) {
                    control.setErrors(null);
                }
            });
        };
    }
}
