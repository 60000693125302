<app-modals-wrapper >
  <ng-template appApplicationWrapperHelper>
    <div class="sizeContent">
      <h2 class="font-mont-bold title">{{title}}</h2>
      <div class="content">
          <p class="font-mont-regular" >{{textB}}</p>
      </div>
      <button class="continue-btn font-mont-bold" (click)="failGiactContinueBnt()">Continue</button>
    </div>
  </ng-template>
</app-modals-wrapper>

