<app-header></app-header>
<div class="containerD">
    <div class="mx-auto frame">
        <div class="boxDec">
            <div>
                <img class="imgDec" src="/assets/images/Declined.svg" alt="declined" />
            </div>
            <h5 class="tittleDeclined font-mont-regular">Sorry!</h5>
            <div class="decline-desciption">
                <div class="font-mont-book">We were unable to approve your request at this time. We hope</div>
                <div class="font-mont-book">to have another opportunity to support your financing needs.</div>
            </div>
            <div class="information">
                <span class="font-mont-regular">Need Help? Have Questions?</span>
            </div>
            <div class="phone">
                <div class="font-mont-book">Call <span class="font-mont-book">1-888-509-5592</span></div>
            </div>
        </div>
    </div>
</div>