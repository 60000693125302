<app-modals-wrapper (closeModal)="onCloseNotify()" [close]="true">
    <ng-template appApplicationWrapperHelper>
        <div class="sizeContent">

            <h2 class="font-mont-bold title">{{title}}</h2>            
            <div class="content">
                <p class="font-mont-regular" >{{textA}} <span class="font-mont-bold">{{sent}} </span>{{textB}} <span class="font-mont-regular spanPhone">{{phone}}</span></p>            
            </div>

        </div>
    </ng-template>
</app-modals-wrapper>

