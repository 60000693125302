<div class="error-content">
  <div class="logo">
      <img class="click-lease-logo" src="\assets\images\click-lease-logo-svg.svg"/>
  </div>
  <div class="cases" [ngSwitch]="true">
      <div class="switchcase">
          <img src="/assets/images/icons/dl-error/dl-upload-error.svg"/>
          <div class="font-mont-bold">Image Upload Failed.</div>
          <div class="font-mont-regular">Please verify you have a good network connection and try again. </div>
      </div>

  </div>
  <button class="font-mont-bold" (click)="backToUpload()">Try Again</button>
  <div class="footer-message font-mont-regular">Need help? Have questions? Call <span class="font-mont-regular">1-888-509-5592</span></div>
</div>
