<div *ngIf="style === 2">
    <div class="main-container-style-three">
      <div class="mobile-clicklease-logo">
        <div></div>
      </div>
      <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
    </div>
  </div>
  <div *ngIf="style === 1" class="main-style-two">
    <div class="application-header form-row justify-content-center align-items-center">
      <img class="logo-large" [ngClass]="{'is-v3-header': isV3}" [src]="vendorLogo" alt="logo-larg" />
      <div *ngFor="let step of steps; let i = index" class="application-header-item"
        [ngClass]="{'disabled': stepIndex < i, 'active': stepIndex === i }">
        <div class="font-mont-bold" *ngIf="i < steps.length - 1">{{i + 1}}</div>
        <div class="approval-icon" *ngIf="i === steps.length - 1"></div>
        <p class="font-mont-bold">{{step}}</p>
        <div class="application-header-divider" *ngIf="i > 0"></div>
      </div>
    </div>
    <div class="main-container-style-two">
      <div>
        <div>
          <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="style === 0" class="main-container">
      <div class="left">
          <div class="mobile-clicklease-logo">
              <div></div>
          </div>
          <div class="left-container">
              <img src="/assets/images/click-lease-logo-svg.svg" class="clicklease-logo" alt="clicklease-logo">
              <div class="mobileImage">
                  <ng-content select="[mobileImage]"></ng-content>
              </div>
              <div class="sub-title-a font-mont-bold" *ngIf="subTitleA">
                  {{subTitleA}}
                  <div class="sub-title-b font-mont-bold" *ngIf="subTitleB">
                      {{subTitleB}}
                  </div>
              </div>
              <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
          </div>
          <div *ngIf="!footerHidden" class="footer-message font-mont-semi-bold">
            <p class="footer-text font-mont-semi-bold">
                <ng-content select="[footerText]"></ng-content>
            </p>
            <p class="font-mont-book">Need Help? Have Questions?<br class="br-none"/> Call</p> <br class="br-display">
            <span class="footer-phone font-mont-semi-bold">
                <ng-content select="[footerPhone]"></ng-content>
            </span>
            <span class="font-mont-book">
                1-888-509-5592
            </span>
        </div>
      </div>
      <div class="right">
          <div>
              <ng-content select="[image]"></ng-content>
          </div>
      </div>
  </div>
  