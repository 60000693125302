<div class="row align-items-end">
    <div class="col-12">
        <div class="form-section">
            <div class="form-row">
                <div class="form-group col-12" [formGroup]="adjustmentsForm">
                    <label for="dataRobotModel" class="col-form-label">DataRobot Model:</label>
                    <select id="dataRobotModel" class="form-control form-control-sm" name="dataRobotModel"
                        formControlName="dataRobotModelSelect" (change)="onChangeDataRobotModel()">
                        <option *ngFor="let dataRobotModel of dataRobotModels" [ngValue]="dataRobotModel">
                            {{dataRobotModel.description}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-8" [formGroup]="adjustmentsForm">
                    <label for="adjustmentVal" class="col-form-label">Adjustment Value:</label>
                    <input id="adjustmentVal" type="text" class="form-control" formControlName="adjustmentVal"
                        name="mrAdjustmentValue" [allowNegativeNumbers]="true" mask="separator.4" thousandSeparator=","
                        on-focusout="onChangeAdjustmentValue()">
                </div>
                <div class="form-group col-4" [formGroup]="adjustmentsForm">
                    <div class="toggle-wrapper">
                        <div class="toggle checkcross">
                            <input id="statusSwitch" type="checkbox" formControlName="statusSwitch"
                                class="custom-switch-cls" (change)="onChangeAdjustmentStatus()" />
                            <label class="toggle-item" for="statusSwitch">
                                <div class="check"></div>
                            </label>
                        </div>
                        <div class="name">Status ({{adjustmentsForm.controls.statusSwitch.value ? 'Active' :
                            'Inactive'}})</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12" [formGroup]="adjustmentsForm">
                    <label for="adjustmentLogic">Conditions:</label>
                    <div class="col-12 adjust-logic">
                        <div class="row col-12">
                            <input type="radio" id="adjustmentLogic_and" value="ALL_AND"
                                formControlName="adjustmentLogic" name="adjustmentLogic"
                                on-click="onChangeAdjustmentLogic('ALL_AND')" class="radio-fix">
                            <div class="radio-label">All of the conditions are met (AND)</div>
                        </div>
                        <div class="row col-12">
                            <input type="radio" id="adjustmentLogic_or" value="ALL_OR" formControlName="adjustmentLogic"
                                name="adjustmentLogic" on-click="onChangeAdjustmentLogic('ALL_OR')" class="radio-fix">
                            <div class="radio-label">Any of the conditions are met (OR)</div>
                        </div>
                        <div class="row col-12">
                            <div class="row col-12">
                                <input type="radio" id="adjustmentLogic_custom" value="CUSTOM"
                                    formControlName="adjustmentLogic" name="adjustmentLogic"
                                    on-click="onChangeAdjustmentLogic('CUSTOM')" class="radio-fix">
                                <div class="radio-label">Customize the logic</div>
                            </div>
                            <div class="row col-12">
                                <div class="form-group col-md-12" [hidden]="!customLogicSelected">
                                    <label for="mCustomLogic"
                                        [ngClass]="{ 'color-red': addCustomLogicSubmitted && adjustmentsForm.controls.customLogic.errors }">Logic:
                                        *</label>
                                    <input type="text" id="mCustomLogic" class="form-control"
                                        formControlName="customLogic" on-focusout="onChangeAdjustmentLogic('CUSTOM')"
                                        [ngClass]="{ 'is-invalid': addCustomLogicSubmitted && adjustmentsForm.controls.customLogic.errors }"
                                        placeholder="Logic">
                                    <div *ngIf="addCustomLogicSubmitted && adjustmentsForm.controls.customLogic.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="adjustmentsForm.controls.customLogic.errors.required">
                                            Logic is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clear"></div>
<div class="form-section">
    <div class="form-section-title font-mont-bold">
        Conditions
    </div>
    <table aria-label="Conditions" class="table">
        <thead>
            <tr>
                <th scope="col" class="text-center font-mont-semi-bold">Identifier</th>
                <th scope="col" class="font-mont-semi-bold">Attribute</th>
                <th scope="col" class="text-center font-mont-semi-bold">Operator</th>
                <th scope="col" class="text-center font-mont-semi-bold">Value</th>
                <th scope="col" class="text-center font-mont-semi-bold">Remove</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let condition of activeAdjustment.conditions" class="row-data">
                <td class="text-center">{{condition.conditionIdentifier}}</td>
                <td>{{condition.dataRobotAttributeName}}</td>
                <td class="text-center">{{condition.operatorDescription}}</td>
                <td class="text-center">{{condition.value}}</td>
                <td class="text-center cursor-poiner" (click)="onRemoveClick(condition)" id="remove-attribute-link">
                    <em class="fa fa-minus"></em>
                </td>
            </tr>
        </tbody>
    </table>
    <form (ngSubmit)="onCreateNewConditionSubmit()">
        <button class="btn btn-primary float-right" type="submit" data-toggle="modal"
            data-target="#addNewAdjustmentConditionModal" [disabled]="disabledFields" id="create-condition-button">
            Create Condition
        </button>
        <div class="clear"></div>
    </form>
</div>

<div class="modal fade" id="addNewAdjustmentConditionModal" tabindex="-1" role="dialog"
    aria-labelledby="createConditionModal" data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img alt="Close" src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="conditionModalLongTitle">
                    <img alt="Add wew" src="/assets/images/icons/add_blue.svg" />
                    Add new condition
                </h5>
                <form [formGroup]="newConditionForm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="mAttribute"
                                        [ngClass]="{ 'color-red': addNewConditionSubmitted && newConditionForm.controls.description.errors }">Attribute:
                                        *</label>
                                    <select id="mAttribute" class="form-control" formControlName="description"
                                        [ngClass]="{ 'is-invalid': addNewConditionSubmitted && newConditionForm.controls.description.errors }">
                                        <option *ngFor="let attribute of attributes" [ngValue]="attribute">
                                            {{attribute.description}}
                                        </option>
                                    </select>
                                    <div *ngIf="addNewConditionSubmitted && newConditionForm.controls.description.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newConditionForm.controls.description.errors.required">
                                            Attribute is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="mOperator"
                                        [ngClass]="{ 'color-red': addNewConditionSubmitted && newConditionForm.controls.operator.errors }">Operator:
                                        *</label>
                                    <select id="mOperator" class="form-control" formControlName="operator"
                                        [ngClass]="{ 'is-invalid': addNewConditionSubmitted && newConditionForm.controls.operator.errors }">
                                        <option *ngFor="let operator of operators" [ngValue]="operator">
                                            {{operator.operator}}
                                        </option>
                                    </select>
                                    <div *ngIf="addNewConditionSubmitted && newConditionForm.controls.operator.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newConditionForm.controls.operator.errors.required">
                                            Operator is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="mValue"
                                        [ngClass]="{ 'color-red': addNewConditionSubmitted && newConditionForm.controls.value.errors }">Value:
                                        *</label>
                                    <input type="text" id="mValue" class="form-control" formControlName="value"
                                        [ngClass]="{ 'is-invalid': addNewConditionSubmitted && newConditionForm.controls.value.errors }"
                                        placeholder="Value">
                                    <div *ngIf="addNewConditionSubmitted && newConditionForm.controls.value.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newConditionForm.controls.value.errors.required">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" id="createAttributeCancelButton"
                                class="btn btn-secondary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right"
                                data-dismiss="modal">CANCEL</button>
                            <button type="submit" id="createAttributeCreateButton"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right"
                                (click)="onCreateConditionSubmit();">ADD CONDITION</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>