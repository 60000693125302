import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ClickSingService } from 'src/app/click-sing';

@Component({
    selector: 'app-payment-options',
    templateUrl: './payment-options.component.html',
    styleUrls: ['./payment-options.component.css']
})
export class PaymentOptionsComponent implements OnInit {
    private applicationId: number = null;
    private access_token: string = null;
    private onClick = false;
    private token: any = null;
    paymentChanged = false;
    selectedOfferGroup = this.formBuilder.group({
        selectedOfferControl: ['']
    });
    selectedOption = 0;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private clickSingService: ClickSingService,
    ) {
        const navigation = this.router.getCurrentNavigation();

        if (navigation.extras.state) {
            this.access_token = navigation.extras.state.accessToken;
            this.applicationId = navigation.extras.state.appId;
            this.token = navigation.extras.state.token;
            this.paymentChanged = navigation.extras.state.offersChanged ? true : false;
        } else this.errorListService.redirectToV3Error(
            null,
            'offers-without-params',
            'click-sign-otp'
        );
    }

    get leaseTaxIncluded() {
        return this.clickSingService.leaseTaxIncluded
    }

    get offers() {
        return this.clickSingService.offers;
    }

    get selectedOfferValue() {
        return this.selectedOfferGroup.controls.selectedOfferControl.value;
    }

    private getConfirmPage(responseData) {
        this.googleAnalyticsService.trackClickSignEvent(
            're-select-payment-response',
            this.clickSingService.vendorReferrerName,
            JSON.stringify(responseData)
        );

        this.clickSingService.getConfirmPage(
            this.applicationId,
            this.access_token
        ).then(innerResponse => {
            if (innerResponse && innerResponse.object) {
                this.router.navigate(['../contract-signing'], {
                    relativeTo: this.activatedRoute,
                    queryParamsHandling: "merge",
                    state: {
                        appId: this.applicationId,
                        accessToken: this.access_token,
                        token: this.token
                    }
                });
            } else this.errorListService.redirectToV3Error(
                innerResponse,
                'getHeadersConfirmationPage',
                'click-sign-otp'
            );

        }).catch(innerError => this.errorListService.redirectToV3Error(
            innerError,
            'getHeadersConfirmationPage',
            'click-sign-otp'
        ));
    }

    private redirectToBankSelection(responseData) {
        this.googleAnalyticsService.trackClickSignEvent(
            'select-payment-response',
            this.clickSingService.vendorReferrerName,
            JSON.stringify(responseData)
        );

        this.router.navigate(['../bank-process/bank-selection'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: "merge",
            state: {
                token: this.token,
                appId: this.applicationId,
                accessToken: this.access_token,
            }
        });
    }

    private redirectToError(data) {
        this.googleAnalyticsService.trackClickSignEvent(
            this.paymentChanged ? 're-signing-offers-error' : 'signing-offers-error',
            this.clickSingService.vendorReferrerName,
            JSON.stringify(data)
        );
        this.errorListService.redirectToV3Error(data, 'get-valid-offers', 'click-sign-otp')
    }

    private validateResponse(data) {
        if (data && data.object && data.object.offerList) {
            this.googleAnalyticsService.trackClickSignEvent(
                this.paymentChanged ? 're-signing-offers-loaded' : 'signing-offers-loaded',
                this.clickSingService.vendorReferrerName,
                JSON.stringify(data)
            );
            this.selectedOfferGroup.controls.selectedOfferControl.setValue(data.object.offerList[0].offerId);
        } else this.redirectToError(data);
    }

    ngOnInit(): void {
        this.googleAnalyticsService.trackClickSignEvent(
            this.paymentChanged ? 're-select-payment-open' : 'select-payment-open',
            this.clickSingService.vendorReferrerName,
            this.applicationId.toString()
        );
        this.clickSingService.getOffers(this.applicationId, this.access_token)
            .then(data => this.validateResponse(data))
            .catch(err => this.redirectToError(err));
    }

    onSelectOffer(index, offerId) {
        this.selectedOption = index;
        this.selectedOfferGroup.controls.selectedOfferControl.setValue(offerId);
    }

    selectoffer() {
        if (this.onClick) return ;

        this.onClick = true;
        
        this.googleAnalyticsService.trackClickSignEvent(
            this.paymentChanged ? 're-select-payment-continue' : 'select-payment-continue',
            this.clickSingService.vendorReferrerName,
            this.selectedOfferValue
        );

        this.clickSingService.selectOffer(
            this.applicationId,
            this.selectedOfferValue,
            this.access_token,
            this.token,
        ).then(responseData => {
            if (responseData && responseData.code == "200") {
                if (this.paymentChanged) {
                    this.getConfirmPage(responseData);
                } else this.redirectToBankSelection(responseData);
            } else this.errorListService.redirectToV3Error(
                responseData,
                'selectofferNl',
                'click-sign-otp'
            );
        }).catch(err => this.errorListService.redirectToV3Error(
            err,
            'selectofferNl',
            'click-sign-otp'
        ));
    }
}