<app-modals-wrapper [modalOff]="true">
  <ng-template appApplicationWrapperHelper>
      <div class="sizeContent">
        <div class="error-modal-content-title">
          Oops. Looks like we couldn’t connect to that bank account.
        </div>
        <div class="error-modal-subTitle">
          We will continue to use the bank account previously connected to your Clicklease account.
        </div>
        <div>
          <div class="account">
            <div class="account-info-img"><img src="/assets/images/icons/building.svg" alt="account"></div>
            <div class="account-info" *ngIf="connectAccount">
              <span class="account-tittle font-mont-bold">{{connectAccount.bankName}}</span>
              <span class="account-number font-mont-regular">Account ******{{connectAccount.bankAccountNumber}}</span>
            </div>
            <div class="account-info-check"><input checked type="radio" id="accountCheck" name="account" class="accountCheck" [value]="connectAccount.bankName"></div>
          </div>
        </div>
        <button class="continue font-mont-bold" (click)="onAccountContinueClick()">Continue</button>
      </div>
  </ng-template>
</app-modals-wrapper>
