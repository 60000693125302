<div class="modal fade" id="headerSendUppsellApplicationModal" tabindex="-1" role="dialog"
    aria-labelledby="HeaderSendApplicationModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <form [formGroup]="sendForm" (ngSubmit)="sendApp();">
                <div class="modal-header">
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-sm-12">
                            <label for="headerUpSellCodeInput"
                                [ngClass]="{ 'color-red': submitted && sendForm.controls.code.errors }">UP-Sell Code:
                                *</label>
                            <input type="text" formControlName="code" class="form-control" id="headerUpSellCodeInput"
                                placeholder="UP-Sell Code" name="headerUpSellCodeInput"
                                [ngClass]="{ 'is-invalid': submitted && sendForm.controls.code.errors }">
                            <div *ngIf="submitted && sendForm.controls.code.errors" class="invalid-feedback">
                                <div *ngIf="sendForm.controls.code.errors.required">
                                    Up-Sell code is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">BOOM! SEND IT.</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">X</button>
                </div>
            </form>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="'SendApplicationUpsellCompleted'" [title]="errorTitle" [messageA]="errorMessageA"
    [messageB]="errorMessageB" [messageC]="errorMessageC" [messageBClass]="errorMessageBClass" [dialogClass]="dialogClass">
</app-error-dialog>