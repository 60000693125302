<div class="footer-container">
    <!--<div *ngIf="isImpersonating()" class="impersonate-footer-text">
        <span> Impersonating: </span>
        <span> {{impersonatedUser.vendorName}} (Vendor) </span>
        -
        <span> {{impersonatedUser.salesPersonName}} (Sales Person) </span>
    </div>-->
    <a routerLink="/about">About</a>
    |
    <a routerLink="/support">Customer Support</a>
</div>