import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorListService } from 'src/app/shared/services';
import { EdgarApplicationPeristenceService } from '../../edgar-application/services/edgar-application-peristence.service';

@Component({
    selector: 'app-general-error-v3',
    templateUrl: './general-error-v3.component.html',
    styleUrls: ['./general-error-v3.component.css']
})
export class GeneralErrorV3Component implements OnInit {
    errorData = '';
    errorCode: any = '';
    startedPage: string = '';
    private queryParams: any;

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService,
        private router: Router,
        private errorListService: ErrorListService,
        private route: ActivatedRoute,
        private edgarApplicationService: EdgarApplicationPeristenceService
    ) {
        this.queryParams = this.route.snapshot.queryParams;
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state && navigation.extras.state.endpoint) {
            this.errorData = navigation.extras.state.endpoint;
        }
        if (navigation.extras.state && navigation.extras.state.error) {
            this.errorCode = this.errorListService.storeErrors ? this.errorListService.storeErrors : this.edgarApplicationService.storeErrors;
        }
        if (navigation.extras.state && navigation.extras.state.start) {
            this.startedPage = '/' + navigation.extras.state.start;
        }
    }

    ngOnInit(): void {
        if(this.startedPage == '/click-sign-otp') {
            this.googleAnalyticsService.trackClickSignEvent('general-error', this.authService.vendorReferrerName, this.errorData, this.errorCode);
        } else {
            this.googleAnalyticsService.trackV3Event('general-error', this.authService.vendorReferrerName, this.errorData, this.errorCode);
        }
    }

    navegateStarted(): void {
        switch(this.startedPage) {
            case "/click-sign-otp":
                if(this.queryParams && this.queryParams.token && this.queryParams.appId && this.queryParams.phoneNumber) {
                    this.router.navigate([`/clicksign/otp-verification`], {queryParams: this.queryParams});
                } else {
                    history.back();
                }
                break;
            case "/started":
                window.location.href = `${window.location.protocol}//${window.location.host}/inlineapp${this.startedPage}?token=${this.authService.getQueryToken}`;
                break;
            case "/finicity-otp":
                if(this.queryParams && this.queryParams.token && this.queryParams.lead_id && this.queryParams.phone) {
                    this.router.navigate([`/inlineapp/bank-process/finicity-otp`], {queryParams: this.queryParams});
                } else {
                    history.back();  
                }
                break;
            case "/resume":
                if(this.queryParams && this.queryParams.key) {
                    window.location.href = `${window.location.protocol}//${window.location.host}/app-resume?key=${this.queryParams.key}`;
                } else {
                    history.back();  
                }
                break;
            case "/edgar":
                window.location.href = `${window.location.protocol}//${window.location.host}/edgar`;
                break;
            default:
                this.router.navigate([`/`]);
        }
    }
}
