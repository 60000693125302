export const environment = {
    production: true,
    development: false,
    gateway: 'https://app.clicklease.com/api/',
    gatewayV3: 'https://app.clicklease.com/services/',
    keyCloakSecret: "16d9cb64-51fe-4107-bdae-6a06e7958f3d",
    userSecret: '302fd9fd741c725a444b10ffa6a1a43e',
    x3scaleProxySecretToken: '2F2989B64E87F7E7A8B5EEA53073C48EA0F890F4B9663C5A0970C26B9E22C06D9329F7428B8DB28F',
    xClkToken: '70B1150811FCA95C5E2D9A92CAE90B2FA91DE2768C47E12D4E2C9D45C948853C61978DA7A6CB6A25',
    xApplicationSource: 'rc',
    xEnvironmentUrl: 'app',
    GoogleExperience: 'S2 Prod Experiment 2022-11-15',
    TRACKING_ID: 'UA-119826944-1',
    TRACKING_ID_GA4: 'G-G7RM3KM866',
    GA_DIMENSIONS: {
        DATA: 'dimension4',
        REFERRAL_PAGE: 'dimension14',
        GOOGLE_EXPERIENCE: 'dimension15',
        GOOGLE_VARIANT: 'dimension16',
        ERROR_CODE: 'dimension2',
        CLIENT_ID: 'dimension17',
        APPLICATION_ID: 'dimension18',
        SOURCE: 'dimension19'
    }
};


