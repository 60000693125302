import { Component, OnInit } from '@angular/core';
import { CustomerSupportTicketModel } from './customer.support.ticket.model';
import { UntypedFormBuilder } from '@angular/forms';
import { ConnectionService } from '../shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-customer-support',
    templateUrl: './customer-support.component.html',
    styleUrls: ['./customer-support.component.css']
})
export class CustomerSupportComponent implements OnInit {

    public submitted: boolean = false;
    public production: boolean = environment.production;

    public ticketForm: CustomerSupportTicketModel = new CustomerSupportTicketModel(this.formBuilder);
    public documents: any[] = [];

    public helpTopics: any[] = [
        { id: 2, name: 'Feedback' },
        { id: 1, name: 'General Inquiry' },
        { id: 10, name: 'Report a Problem' },
        { id: 11, name: 'Report a Problem / Access Issue' }
    ];

    public messageTitle = '';
    public modalId = 'AppE';
    public messageA = '';
    public messageB = '';
    public messageC = '';
    public messageAClass = 'text-center';

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder
    ) { }

    ngOnInit() {
    }

    get tForm() { return this.ticketForm.form.controls; }

    onResetClick() {
        this.ticketForm =  new CustomerSupportTicketModel(this.formBuilder);
        this.documents = [];
        this.submitted = false;
    }

    onContinueClick() {
        this.submitted = true;

        if (this.ticketForm.form.invalid) {
            return;
        }

        const pData = this.ticketForm.encode(this.documents);

        const promise = new Promise((resolve, reject) => {
            this.connectionService.postRequestUnsecured(environment.gateway + 'osTicket', pData, 'application/json').then(
                (data: any) => {
                    this.messageTitle = 'New ticket.';
                    this.messageA = 'New ticket created successfully.';
                    $('#errorModalDialogAppE').modal();
                    resolve(data);
                },
                (error: any) => {
                    this.messageTitle = 'New ticket.';
                    this.messageA = 'Unable to create new ticket.';
                    $('#errorModalDialogAppE').modal();
                    reject(error);
                }
            );
        });
        return promise;
    }

    onFileAdded(file: any) {
        this.documents.push(file);
    }

    onCompleteInformationClick() {
        this.ticketForm.setDefaultData();
    }
}
