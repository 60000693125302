import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-application-details-history',
    templateUrl: './application-details-history.component.html',
    styleUrls: ['./application-details-history.component.css', '../../ept-ui-elements.css']
})
export class ApplicationDetailsHistoryComponent implements OnInit {
    @Input() history: any[] = [];

    public open: boolean = false;

    constructor() { }

    ngOnInit() {
    }

}