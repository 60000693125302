import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-additional-change-salesperson',
    templateUrl: './additional-change-salesperson.component.html',
    styleUrls: ['./additional-change-salesperson.component.css']
})
export class AdditionalChangeSalespersonComponent implements OnInit {
    @Input() id = 0;
    @Input() vendorId = 0;
    public modalId = 'changeSalespersonModal' + this.id;
    public modalIdE = 'AdditionalChangeSalespersonCompleted' + this.id;
    public salespersonNameList = 'salespersonNameListChangeSalesperson' + this.id;

    public changeSalespersonForm: UntypedFormGroup;
    public submitted: boolean = false;
    public notify: boolean = true;
    public errorTitle = '';
    public errorMessageA = '';
    public errorMessageB = '';
    public errorMessageC = '';
    public errorMessageBClass = '';
    public dialogClass = '';

    public sNumberOfElements = 0;
    public sSliceNumber = 0;
    public sSliceSize = 8;
    public sTotalElements = 0;
    public sTotalPages = 0;
    public sSlices: number[] = [];

    public salespeopleData: any = [];

    public salespersonSelected = { id: 0, name: '' };

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        public googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.changeSalespersonForm = this.formBuilder.group({
            salesperson: ['']
        });
    }

    ngOnInit() {
        this.modalId = 'changeSalespersonModal' + this.id;
        this.modalIdE = 'AdditionalChangeSalespersonCompleted' + this.id;
        this.salespersonNameList = 'salespersonNameListChangeSalesperson' + this.id;
    }

    onClick() {
        this.changeSalespersonForm.controls.salesperson.setValue('');
        this.googleAnalyticsService.trackEvent('additional-options', 'open', 'change-sales-person');
        this.onSalespersonNameKeyUp(0, 8).then(
            () => $('#changeSalespersonModal' + this.id).modal()
        );
    }

    onSubmit() {
        this.submitted = true;
        if (this.changeSalespersonForm.invalid) {
            return;
        }
        this.changeSalespersonApplication();
    }

    changeSalespersonApplication() {
        const promise = new Promise((resolve, reject) => {
            if (this.salespersonSelected.id) {
                this.googleAnalyticsService.trackEvent('additional-options', 'submit', 'change-sales-person');
                const url = environment.gateway + 'application/' + this.id + '/salesperson/' + this.salespersonSelected.id;
                this.connectionService.postRequest(url, {}, 'application/json', 'sp_id=' + this.authService.getSalesPersonId() + '&application_transfer_notify=' + this.notify).subscribe(
                    (data: any) => {
                        window.location.reload();
                        resolve(data);
                    },
                    (error: any) => {
                        this.errorTitle = 'Sorry, we found a problem';
                        this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                        this.errorMessageB = error.error.msg;
                        this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.errorMessageBClass = 'mB20';
                        this.dialogClass = 'w600';
                        $('#errorModalDialogAdditionalChangeSalespersonCompleted' + this.id).modal();
                        reject(error);
                    }
                );
            } else {
                this.errorTitle = 'Sorry, we found a problem';
                this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                this.errorMessageB = '';
                this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                this.errorMessageBClass = 'mB20';
                this.dialogClass = 'w600';
                $('#errorModalDialogAdditionalChangeSalespersonCompleted' + this.id).modal();
                reject(null);
            }
        });
        return promise;
    }

    onSalespersonNameKeyUp(slice: number, elements: number) {
        this.salespersonSelected = { id: 0, name: '' };
        const promise = new Promise((resolve, reject) => {
            if (this.vendorId) {
                this.getSalesPeople(this.vendorId, this.changeSalespersonForm.controls.salesperson.value, slice, elements).then(
                    (salespeopleData: any) => {
                        this.salespeopleData = salespeopleData.object.records;
                        this.sNumberOfElements = salespeopleData.object && salespeopleData.object.numberOfElements ? salespeopleData.object.numberOfElements : 0;
                        this.sSliceNumber = salespeopleData.object && salespeopleData.object.sliceNumber ? salespeopleData.object.sliceNumber : 0;
                        this.sSliceSize = salespeopleData.object && salespeopleData.object.sliceSize ? salespeopleData.object.sliceSize : 0;
                        this.sTotalElements = salespeopleData.object && salespeopleData.object.totalElements ? salespeopleData.object.totalElements : 0;
                        this.sTotalPages = salespeopleData.object && salespeopleData.object.totalPages ? salespeopleData.object.totalPages : 0;
                        this.sSlices = Array.from(Array(this.sTotalPages), (x, index) => index + 1);
                        resolve(salespeopleData);
                    },
                    (salespeopleError: any) => {
                        this.salespeopleData = [];
                        resolve(salespeopleError);
                    }
                );
            } else {
                this.changeSalespersonForm.controls.salesperson.setValue('');
                this.salespeopleData = [];
                resolve(null);
            }
        });

        return promise;
    }

    getSalesPeople(vendorId: number, salesPersonName: string, page: number, pageSize: number) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'salespersonbyvendor/' + vendorId + '/' + page + '/' + pageSize, 'application/json', 'name=' + salesPersonName
            ).subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

    selectNotifyPerson(change: boolean) {
        this.notify = change;
    }

    onSalespersonClick(salesperson: any) {
        this.salespersonSelected = salesperson;
    }

    onSBackClick() {
        if (this.sSliceNumber > 0) {
            this.onSalespersonNameKeyUp(this.sSliceNumber - 1, this.sSliceSize);
        }
    }

    onSSliceClick(slice: number) {
        this.onSalespersonNameKeyUp(slice, this.sSliceSize);
    }

    onSNextClick() {
        if (this.sSliceNumber < this.sTotalPages - 1) {
            this.onSalespersonNameKeyUp(this.sSliceNumber + 1, this.sSliceSize);
        }
    }

    get isSalesPersonDisabled() {
        return this.salespeopleData.length < 1;
    }
}
