import { ErrorHandler, Injectable } from '@angular/core';
import { GoogleAnalyticsService } from '../analytics/google.analytics.service';
@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlerService implements ErrorHandler {
  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }
  handleError(errorEvent: TypeError) {
    console.log(errorEvent);
    let errorData: any = {};
    errorData.eventData = errorEvent && errorEvent.message ? errorEvent.message : '';
    errorData.eventAction = errorEvent && errorEvent.name ? errorEvent.name : '';
    errorData.errorCode = errorEvent && errorEvent.stack ? errorEvent.stack : '';
    this.googleAnalyticsService.trackErrorEvent('exception-ui-error', errorData.eventAction, this.googleAnalyticsService.getLabel, 1, errorData.eventData, 'n/a', errorData.errorCode);
  }
}