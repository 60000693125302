function initExperimental() {
    let _isExperimental = false;

    const isExperimental = () => _isExperimental;

    const updateExperimental = (value?: string) => {
        // if (value === 'B') this.highRiskExperimentFlow = true;
    }

    const appSteps = () => {
        const appSteps = [
            'Personal Information',
            'Request Funding',
            'Business Information',
        ];

        if (isExperimental()) {
            appSteps.push('Bank Verification', 'Approval');
        } else appSteps.push('Approval');

        return appSteps;
    }

    return {
        appSteps,
        isExperimental,
        updateExperimental,
    }
}

export const Experimental = initExperimental();