<div class="row align-items-end">
    <div class="form-group col-2">
    </div>
    <div class="form-group custom-control custom-switch col-3">
        <input type="checkbox" class="custom-control-input" id="testModelSwitch" name="testModelSwitch" ngModel
            #testModelSwitch (change)="onChangeTestModel()">
        <label class="custom-control-label" for="testModelSwitch">
            Test Model ({{testModelSwitchRef.nativeElement.checked ? 'True' : 'False'}})
        </label>
    </div>
    <div class="form-group custom-control custom-switch col-3"></div>
    <div class="form-group custom-control custom-switch col-3">
        <input type="checkbox" class="custom-control-input" id="byOwnerSwitch" name="byOwnerSwitch" ngModel
            #byOwnerSwitch (change)="onChangeByOwner()">
        <label class="custom-control-label" for="byOwnerSwitch">
            By Owner ({{byOwnerSwitchRef.nativeElement.checked ? 'True' : 'False'}})
        </label>
    </div>
    <div class="container">
        <div class="row">
            <div class="form-group col-6" [formGroup]="dataRobotModelForm">
                <label for="description" class="col-form-label">Description:</label>
                <input id="description" type="text" class="form-control" formControlName="description"
                    name="description" on-focusout="onChangeDescriptionValue()"
                    [ngClass]="{ 'is-invalid': updateModelDescriptionSubmitted && dataRobotModelForm.controls.description.errors }">
                <div *ngIf="updateModelDescriptionSubmitted && dataRobotModelForm.controls.description.errors"
                    class="invalid-feedback">
                    <div *ngIf="dataRobotModelForm.controls.description.errors.required">
                        Description is required.
                    </div>
                </div>
            </div>
            <div class="form-group col-6" [formGroup]="dataRobotModelForm">
                <label for="modelAffix" class="col-form-label">Model Affix:</label>
                <input id="modelAffix" type="text" class="form-control" formControlName="modelAffix" name="modelAffix"
                    on-focusout="onChangeModelAffix()"
                    [ngClass]="{ 'is-invalid': updateModelAffixSubmitted && dataRobotModelForm.controls.modelAffix.errors }">
                <div *ngIf="updateModelAffixSubmitted && dataRobotModelForm.controls.modelAffix.errors"
                    class="invalid-feedback">
                    <div *ngIf="dataRobotModelForm.controls.modelAffix.errors.required">
                        Model Affix is required.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>