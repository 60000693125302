import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-additional-add-co-signer-email',
    templateUrl: './additional-add-co-signer-email.component.html',
    styleUrls: ['./additional-add-co-signer-email.component.css']
})
export class AdditionaAddCoSignerEmailComponent implements OnInit {
    @Input() id = 0;

    @Output() reloadSlice = new EventEmitter<void>();

    public sendForm: UntypedFormGroup;

    public modalId = 'AddCoSignerEmail' + this.id;
    public modalIdE = 'AddCoSignerEmailError' + this.id;

    public submitted = false;
    public submittedText = false;
    public errorTitle = '';
    public errorMessageA = '';
    public errorMessageB = '';
    public errorMessageC = '';
    public errorMessageBClass = '';
    public dialogClass = '';

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        public googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.sendForm = this.formBuilder.group({ email: ['', [Validators.required, Validators.email]] });
    }

    ngOnInit() {
        this.modalId = 'AddCoSignerEmail' + this.id;
        this.modalIdE = 'AddCoSignerEmailError' + this.id;
    }

    onCloseModal() {
        this.reloadSlice.emit();
        this.ngOnInit();
    }

    onClick() {
        this.googleAnalyticsService.trackEvent('additional-options', 'open', 'add-co-signer-email');
        $('#AddCoSignerEmail' + this.id).modal();
    }

    sendApp() {
        this.submitted = true;
        if (this.sendForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            const postData = {
                ownerEmail: this.sendForm.controls.email.value,
                salesPersonId: this.authService.getSalesPersonId(),
                userEmail: this.authService.getEmail()
            };
            this.connectionService.postRequest(environment.gateway + 'owner/email/' + this.id, postData, 'application/json').subscribe(
                (data: any) => {
                    this.googleAnalyticsService.trackEvent('additional-options', 'sent', 'add-co-signer-email');
                    this.errorTitle = 'Email sent.';
                    this.errorMessageA = 'Your email to add an owner was successfully sent by email.';
                    this.errorMessageB = '';
                    this.errorMessageC = '';
                    this.errorMessageBClass = '';
                    this.dialogClass = 'w450';
                    $('#AddCoSignerEmail' + this.id).modal('hide');
                    $('#errorModalDialog' + this.modalIdE).modal();
                    this.sendForm = this.formBuilder.group({ email: ['', Validators.required] });
                    this.submitted = false;
                    resolve(data);
                },
                (error: any) => {
                    errorFn(error);
                }
            );

            const errorFn = function (error: any) {
                this.errorTitle = 'Sorry, we found a problem';
                this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                this.errorMessageB = error.error.msg;
                this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                this.errorMessageBClass = 'mB20';
                this.dialogClass = 'w600';
                $('#AddCoSignerEmail' + this.id).modal('hide');
                $('#errorModalDialog' + this.modalIdE).modal();
                this.sendForm = this.formBuilder.group({ email: ['', Validators.required] });
                this.submitted = false;
                reject(error);
            }.bind(this);
        });
    }

}
