<div class="error-content" [ngSwitch]="event">
    <div class="logo">
        <img class="click-lease-logo" src="\assets\images\click-lease-logo-svg.svg"/>
    </div>
    <img src="/assets/images/icons/application-errors/denied-error-icon.svg"/>
    <div class="switchcase" *ngSwitchCase="'cancel'">
        <div class="font-mont-bold">Sorry, the recipient decides to finish later.</div>
    </div>
    <div class="switchcase" *ngSwitchCase="'decline'">
        <div class="font-mont-bold">Sorry, the recipient has declined the signature.</div>
    </div>
    <div class="switchcase" *ngSwitchCase="'exception'">
        <div class="font-mont-bold">Sorry, a processing error occurs during the signing session.</div>
    </div>
    <div class="switchcase" *ngSwitchCase="'fax_pending'">
        <div class="font-mont-bold">Sorry, the recipient chose to print, sign and fax back.</div>
    </div>
    <div class="switchcase" *ngSwitchCase="'id_check_failed'">
        <div class="font-mont-bold">Sorry, the authentication that was added to the document has failed.</div>
    </div>
    <div class="switchcase" *ngSwitchCase="'session_timeout'">
        <div class="font-mont-bold">Sorry, the signing session is over.</div>
    </div>
    <div class="switchcase" *ngSwitchCase="'ttl_expired'">
        <div class="font-mont-bold"> Sorry, the token was not used within the timeout period or the token was already accessed.</div>
    </div>
    <div class="footer-message font-mont-regular">Need help? Have questions? Call <span class="font-mont-regular">1-888-509-5592</span></div>
</div>