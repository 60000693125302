import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
declare var $: any;

@Component({
    selector: 'app-header-vendor-brocker-select-dialog',
    templateUrl: './header-vendor-brocker-select-dialog.component.html',
    styleUrls: ['./header-vendor-brocker-select-dialog.component.css']
})
export class HeaderVendorBrockerSelectDialogComponent implements OnInit {

    public salesVPerson = null;
    public vendorSelected = null;
    public vendors = null;

    public errorTitle = '';
    public errorMessageA = '';
    public errorMessageB = '';
    public errorMessageC = '';
    public errorMessageBClass = '';
    public dialogClass = '';

    constructor(private authService: AuthService) {
        this.salesVPerson = this.authService.getVendors();
    }

    ngOnInit() {
        this.vendorList();
    }

    vendorList() {
        if (!localStorage.getItem("vendSelected_data")) {
            this.vendorSelected = this.salesVPerson[0];
        } else {
            this.vendorSelected = JSON.parse(localStorage.getItem("vendSelected_data"));
        }
        this.vendors = this.salesVPerson;
    }

    changeVendor(id: any) {
        this.vendorSelected = this.vendors[id];
        localStorage.setItem("vendSelected_data", JSON.stringify(this.vendorSelected));
    }

    close() {
        window.location.reload();
    }
}
