<div class="bg-secondary loading-screen-wrapper" *ngIf="showLoading">
    <div class="loading-screen-icon">
        <img src="/assets/images/click-lease-logo-white.svg" class="cls-logo" />
        <div class="d-flex align-items-center">
            <strong>Please Wait, Loading</strong><strong *ngIf="animation != 0">...</strong>
            <div class="spinner-grow ml-auto" role="status" aria-hidden="true" *ngIf="animation == 0"></div>
            <div class="spinner-grow ml-auto" role="status" aria-hidden="true" *ngIf="animation == 0"></div>
            <div class="spinner-grow ml-auto" role="status" aria-hidden="true" *ngIf="animation == 0"></div>
        </div>
        <div class="progress" *ngIf="animation == 1">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
                aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <div class="d-flex align-items-center" style="height: 20px;" *ngIf="animation == 2">
            <div class="slide-in-right-0" style="position: absolute; left: calc(45% - 15px);"></div>
            <div class="slide-in-right-1" style="position: absolute; left: calc(50% -  5px);"></div>
            <div class="slide-in-right-2" style="position: absolute; left: calc(55% +  5px);"></div>
        </div>
    </div>
</div>
<div class="bg-secondary loading-screen-wrapper" style="background-color: #fff !important; color: #000;"
    *ngIf="showFinicityLoading">
    <div class="loading-screen-icon" style="width: 100vw; padding: 40px;">
        <div class="finicity-text">
            <span>
                Thank you for submitting your bank information. We are now verifying your account to make sure it meets
                our minimum qualifications for an approval. This usually takes less than a minute. We will update your
                status on this screen
            </span>
            <span style="text-decoration: underline;">
                if you don’t close your browser</span>
            <span>
                -
            </span>
            <span style="font-weight: 600;">
                we will also send you an email when it’s complete if you need to step away.
            </span>
        </div>
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0"
                aria-valuemax="100" aria-valuenow="100" style="width: 100%"></div>
        </div>
        <div class="finicity-text">
            <span>
                If you have any questions about this process you can chat us
            </span>
            <span style="text-decoration: underline; color: #276dac;">
                <a href="https://home-c35.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=97e77c7f-d8a6-4af0-95eb-f8975110c562&bu=4599274"
                    target="_blank">here</a></span>
            <span>
                or contact us at (888) 509-5592.
            </span>
        </div>
    </div>
</div>