<div class="collapsable-tab sub-title d-flex d-flex justify-content-between align-items-center pt-3">
    <button (click)="open ? open = false : open = true"
        class="ept-btn-app-detail pl-0 pr-lg-4 m-0 border-0 flex-grow-lg-1 text-left bg-transparent">
        <span [ngClass]="{'collapse-icon--open': open}" class="collapse-icon mr-2"></span>
        <span class="font-mont-semi-bold">notes</span>
    </button>
</div>
<div class="row container" [ngClass]="{ 'open': open }">
    <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit();">
            <textarea rows="5" formControlName="notes" class="form-control" id="notes" name="notes"></textarea>
            <button type="submit" class="btn btn-secondary close-button">Save Notes</button>
        </form>
    </div>
</div>