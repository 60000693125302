<div class="login-header">
    <img src="/assets/images/click-lease-logo-svg.svg" alt="clicklease logo" />
</div>
<div class="login-form-container">
    <div class="login-form">
        <div class="form-header">
            <h3 class="font-mont-bold">SIGN IN</h3>
            <img src="/assets/images/icons/login_user.svg" alt="login user" />
            <div class="clear"></div>
        </div>
        <form [formGroup]="customerUserForm">
            <div class="form-group">
                <label for="ssn" [ngClass]="{ 'color-red': submitted && customerUserForm.controls.ssn.errors }">
                    SSN (last 4 digits): *
                </label>
                <input type="text" formControlName="ssn" class="form-control" id="ssn" name="ssn"
                    placeholder="XXX-XXXX-dddd" prefix="XXX-XXXX-" mask="0000" [showMaskTyped]="true"
                    placeHolderCharacter="d"
                    [ngClass]="{ 'is-invalid': submitted && customerUserForm.controls.ssn.errors }">
                <div *ngIf="submitted && customerUserForm.controls.ssn.errors" class="invalid-feedback">
                    <div *ngIf="customerUserForm.controls.ssn.errors.required">
                        SSN is required.
                    </div>
                    <div *ngIf="customerUserForm.controls.ssn.errors.mask">
                        Last 4 SSN digits required.
                    </div>
                </div>
            </div>
            <div class="form-group bsDatepicker">
                <label for="dob" [ngClass]="{ 'color-red': submitted && customerUserForm.controls.dob.errors }">
                    DOB: *
                </label>
                <input placeholder="MM/DD/YYYY" type="text" formControlName="dob" class="form-control" id="dob"
                    name="dob" [ngClass]="{ 'is-invalid': submitted && customerUserForm.controls.dob.errors }"
                    bsDatepicker [maxDate]="maxAgeDate"
                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, containerClass: 'theme-dark-blue'}">
                <div *ngIf="submitted && customerUserForm.controls.dob.errors" class="invalid-feedback">
                    <div *ngIf="customerUserForm.controls.dob.errors.required">
                        BOD is required.
                    </div>
                </div>
            </div>
            <div class="bottom-buttons">
                <button class="btn btn-primary float-right" id="onSignin" type="submit" (click)="onSignin()">REQUEST
                    ACCESS</button>
            </div>
        </form>
        <div class="footer-links">
        </div>
    </div>
</div>
<div class="login-footer">
    <a routerLink="/support">Support</a>
    <a routerLink="/privacy">Privacy</a>
    <a routerLink="/about">About</a>
    <a routerLink="/terms">Terms</a>
</div>

<!-- Login error modal -->
<div class="modal fade" id="loginErrorModal" role="dialog">
    <div class="modal-dialog small">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="close b" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title font-mont-bold">{{loginTitle}}</h5>
                <div class="dialog-message">
                    {{loginMsg1}}
                    <a href="mailto:{{loginMsg2}}" class="">{{loginMsg2}}</a>
                </div>
                <div class="clear"></div>
                <div class="row text-right">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary dialog-button close-button"
                            data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>