import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ConnectionService } from '../../shared/services/connection.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-leasable-filter',
    templateUrl: './leasable-filter.component.html',
    styleUrls: ['./leasable-filter.component.css']
})
export class LeasableFilterComponent implements OnInit {

    @Input() searchClass: boolean;
    @Input() selectedVendor: any = {};

    @Output() newItemEvent = new EventEmitter<any>();

    public vNumberOfElements = 0;
    public vSliceNumber = 0;
    public vSliceSize = 0;
    public vTotalElements = 0;
    public vTotalPages = 0;
    public vSlices: number[] = [];

    public vendorName: UntypedFormControl = new UntypedFormControl('');
    public vendorData: any = {
        numberOfElements: 0,
        totalElements: 0,
        records: []
    };

    constructor(
        private connectionService: ConnectionService,
    ) { }

    ngOnInit() {
        this.selectedVendor = {};
    }

    getVendors(vendorName: string, page: number, pageSize: number) {
        if (vendorName !== '') {
            const promise = new Promise((resolve, reject) => {
                this.connectionService.getRequest(
                    environment.gateway + 'vendor/filtered/' + page + '/' + pageSize, 'application/json', 'name=' + vendorName
                ).subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
            });
            return promise;
        }
    }

    getSalesPeople(vendorId: number, salesPersonName: string, page: number, pageSize: number) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'salesperson/filtered/' + page + '/' + pageSize, 'application/json',
                'vendorId=' + vendorId + '&name=' + salesPersonName
            ).subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

    onVendorNameKeyUp(slice: number, elements: number) {
        if (this.vendorName.value !== '') {
            this.getVendors(this.vendorName.value, slice, elements).then(
                (vendorsData: any) => {
                    this.selectedVendor = {};
                    this.vendorData = vendorsData.object;
                    this.vNumberOfElements = vendorsData.object && vendorsData.object.numberOfElements ? vendorsData.object.numberOfElements : 0;
                    this.vSliceNumber = vendorsData.object && vendorsData.object.sliceNumber ? vendorsData.object.sliceNumber : 0;
                    this.vSliceSize = vendorsData.object && vendorsData.object.sliceSize ? vendorsData.object.sliceSize : 0;
                    this.vTotalElements = vendorsData.object && vendorsData.object.totalElements ? vendorsData.object.totalElements : 0;
                    this.vTotalPages = vendorsData.object && vendorsData.object.totalPages ? vendorsData.object.totalPages : 0;
                    this.vSlices = Array.from(Array(this.vTotalPages), (x, index) => index + 1);
                },
                (vendorsError: any) => {
                    this.vendorData = {
                        numberOfElements: 0,
                        totalElements: 0,
                        records: []
                    };
                }
            );
        }
        this.onCloseVendorClick();
    }

    onVendorClick(vendor: any) {
        this.selectedVendor = vendor;
        this.vTotalElements = 0;
        this.addVendor();  
    }

    onCloseVendorClick() {
        this.selectedVendor = {};
        this.vendorData = {};
        this.vTotalElements = 0;
    }

    addVendor() {
        this.newItemEvent.emit(this.selectedVendor);
    }

}
