import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';
import { BlockGuard } from './application/v2/block.guard';
import { DropdownDirective } from './shared/directives/dropdown.directive';
import { AuthAntiGuard } from './auth/auth-anti-guard.service';
import { AuthBrokerGuard } from './auth/auth-broker-guard.service';
import { ConnectionService } from './shared/services/connection.service';
import { GoogleAnalyticsService } from './analytics/google.analytics.service';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { MultiRiskComponent } from './admin/multi-risk/multi-risk.component';
import { ToastService } from './shared/services/toast.service';
import { OffersComponent } from './application/offers/offers.component';
import { ImpersonateComponent } from './admin/impersonate/impersonate.component';
// tslint:disable-next-line: max-li./click-sing/bank-info/bank-info.component
// tslint:disable-next-line: max-line-length
import { IndustyPipe } from './shared/pipes/industry.pipe';
import { StatePipe } from './shared/pipes/state.pipe';
import { LoadingScreenComponent } from './utils/loading-screen/loading-screen.component';
import { LoadingScreenInterceptor } from './shared/interceptors/loading.interceptor';
import { AnalyticsInterceptor } from './shared/interceptors/analytics.interceptor';
import { AchConverterComponentComponent } from './admin/ach-converter-component/ach-converter-component.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';
// import { ListSideBarComponent } from './applications-list/list-side-bar/list-side-bar.component';
import { AuthTokenGuard } from './auth/auth-token-guard.service';
import { FundingPendingComponent } from './home/summary/funding-pending/funding-pending.component';
import { ExpiredLoginDialogComponent } from './utils/expired-login-dialog/expired-login-dialog.component';
import { GeneralErrorComponent } from './error/general-error/general-error.component';
import { PricingComponent } from './admin/pricing-tool/pricing-tool.component';
import { MultiRiskItemComponent } from './admin/multi-risk/multi-risk-item/multi-risk-item.component';
import { PricingItemComponent } from './admin/pricing-tool/pricing-item/pricing-item.component';
import { MaxOfferToolComponent } from './admin/max-offer-tool/max-offer-tool.component';
import { MaxOfferItemComponent } from './admin/max-offer-tool/max-offer-item/max-offer-item.component';
import { TermToolComponent } from './admin/term-tool/term-tool.component';
import { TermItemComponent } from './admin/term-tool/term-item/term-item.component';
import { DataRobotToolComponent } from './admin/data-robot-tool/data-robot-tool.component';
import { DataRobotItemComponent } from './admin/data-robot-tool/data-robot-item/data-robot-item.component';
import { VendorListComponent } from './vendors/vendor-list/vendor-list.component';
import { VendorListItemComponent } from './vendors/vendor-list-item/vendor-list-item.component';
import { AddOwnerTokenComponent } from './application-token/add-owner-token/add-owner-token.component';
import { DndDirective } from './shared/directives/dnd.directive';
import { DeclinedComponent } from './application/declined/declined.component';
import { DeclineFinicityComponent } from './application/decline-finicity/decline-finicity.component';
import { DeclineDataErrorComponent } from './application/decline-data-error/decline-data-error.component';
import { TokenErrorComponent } from './error/token-error/token-error.component';
import { UploadInvoiceComponent } from './application/invoice/upload-invoice/upload-invoice.component';
import { InvoiceUploaderComponent } from './application/invoice/upload-invoice/invoice-uploader/invoice-uploader.component';
import { CustomerUserComponent } from './auth/customer-user/customer-user.component';
import { FraudListComponent } from './admin/fraud-list/fraud-list.component';
import { HeaderModule } from './header/header.module';
import { SharedModule } from './shared/shared.module';
import { TokenErrorV3Component } from './application/errors/token-error-v3/token-error-v3.component';
import { GeneralErrorV3Component } from './application/errors/general-error-v3/general-error-v3.component';
import { FinicityDeclinedComponent } from './bank-process/finicity-declined/finicity-declined.component';
import { FraudErrorComponent } from './application/errors/fraud-error/fraud-error.component';
import { DataErrorV3Component } from './application/errors/data-error-v3/data-error-v3.component';
import { DeclineErrorComponent } from './application/errors/decline-error/decline-error.component';
import { StartedSuccessComponent } from './application/v3/started-success/started-success.component';
import { MobileStartedSuccessComponent } from './application/v3/mobile-started-success/mobile-started-success.component';
import { V3IframeCommunicationComponent } from './application/v3/v3-iframe-communication/v3-iframe-communication.component';
import { DocuSignCompletedComponent } from './click-sing/docu-sign-completed/docu-sign-completed.component';
import { LeasableListComponent } from './leasable-items/leasable-list/leasable-list.component';
import { LeasableImportComponent } from './leasable-items/leasable-import/leasable-import.component';
import { LeasableItemsDragAndDropComponent } from './leasable-items/leasable-items-drag-and-drop/leasable-items-drag-and-drop.component';
import { LeasebleExportComponent } from './leasable-items/leasable-export/leasable-export.component';
import { LeasableImportPreviewComponent } from './leasable-items/leasable-import/leasable-import-preview/leasable-import-preview.component';
import { LeasableFilterComponent } from './leasable-items/leasable-filter/leasable-filter.component';
import { BenefitsComponent } from './application/v3/benefits/benefits.component';
import { ExceptionHandlerService } from './utils/exception-handler.service';
import { AuthLeasableGuard } from './auth/auth-leasable-guard.service';
import { AuthTokenParamGuard } from './auth/auth-token-param-guard.service';
import { ConfirmStatusComponent } from './application/new-finicity-errors/confirm-status/confirm-status.component';
import { SubmitErrorsComponent } from './application/new-finicity-errors/submit-errors/submit-errors.component';
import { CustomFormValidationsService } from './shared/validations/customFormValidations.service';
import { UploadDriverLicenseComponent } from './dl-process/upload-driver-license/upload-driver-license.component'
import { FileUploadModule } from 'ng2-file-upload';
import { DlSuccessSubmitionComponent } from './dl-process/dl-success-submition/dl-success-submition.component';
import { DlErrorUploadFailedComponent } from './dl-process/dl-error-upload-failed/dl-error-upload-failed.component';
import { AuthRiskGuard } from './auth/auth-risk-guard.service';
import { AppResumeComponent } from './application/app-resume/app-resume.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { NotFoundErrorComponent } from './application/errors/not-found-error/not-found-error.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        SigninComponent,
        DropdownDirective,
        ResetPasswordComponent,
        MultiRiskComponent,
        OffersComponent,
        ImpersonateComponent,
        IndustyPipe,
        StatePipe,
        LoadingScreenComponent,
        AchConverterComponentComponent,
        PrivacyComponent,
        AboutComponent,
        FundingPendingComponent,
        ExpiredLoginDialogComponent,
        PricingComponent,
        MultiRiskItemComponent,
        PricingItemComponent,
        MaxOfferToolComponent,
        MaxOfferItemComponent,
        TermToolComponent,
        TermItemComponent,
        DataRobotToolComponent,
        DataRobotItemComponent,
        GeneralErrorComponent,
        VendorListComponent,
        VendorListItemComponent,
        AddOwnerTokenComponent,
        DndDirective,
        DeclinedComponent,
        DeclineFinicityComponent,
        DeclineDataErrorComponent,
        TokenErrorComponent,
        UploadInvoiceComponent,
        InvoiceUploaderComponent,
        CustomerUserComponent,
        FraudListComponent,
        TokenErrorV3Component,
        GeneralErrorV3Component,
        FinicityDeclinedComponent, //New_folders
        FraudErrorComponent,
        DataErrorV3Component,
        DeclineErrorComponent,
        StartedSuccessComponent,
        MobileStartedSuccessComponent,
        V3IframeCommunicationComponent,
        LeasableListComponent,
        LeasableItemsDragAndDropComponent,
        LeasableImportComponent,
        LeasebleExportComponent,
        LeasableImportPreviewComponent,
        LeasableFilterComponent,
        /*UploadDriverLicenseComponent,*/
        DocuSignCompletedComponent,
        BenefitsComponent,
        ConfirmStatusComponent,
        SubmitErrorsComponent,
        UploadDriverLicenseComponent,
        DlSuccessSubmitionComponent,
        DlErrorUploadFailedComponent,
        CustomerSupportComponent,
        AppResumeComponent,
        NotFoundErrorComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        FontAwesomeModule,
        HeaderModule,
        SharedModule,
        NgxMaskModule.forRoot(options),
        FileUploadModule
    ],
    providers: [
        AuthService,
        ConnectionService,
        GoogleAnalyticsService,
        AuthGuard,
        BlockGuard,
        AuthLeasableGuard,
        AuthAntiGuard,
        AuthTokenGuard,
        AuthBrokerGuard,
        AuthTokenParamGuard,
        ToastService,
        AuthRiskGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingScreenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AnalyticsInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ExceptionHandlerService
        },
        CustomFormValidationsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
