import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-additional-resend-bank-verification-link',
    templateUrl: './additional-resend-bank-verification-link.component.html',
    styleUrls: ['./additional-resend-bank-verification-link.component.css']
})
export class AdditionalResendBankVerificationLinkComponent implements OnInit {

    sendForm: UntypedFormGroup;

    submitted = false;
    errorTitle = '';
    errorMessageA = '';
    errorMessageB = '';
    errorMessageC = '';
    errorMessageBClass = '';
    dialogClass = '';

    @Input() id = 0;

    @Output() reloadSlice = new EventEmitter<void>();

    defaultSendDocsFormData = { ownerEmail: '' }

    modalId = 'additionalResendBankVerificationLinkModal' + this.id;
    modalIdE = 'AdditionalResendBankVerificationLinkCompleted' + this.id;

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        public googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.sendForm = this.formBuilder.group({
            sameInfo: ['true'],
            ownerEmail: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
        });
    }

    ngOnInit() {
        this.modalId = 'additionalResendBankVerificationLinkModal' + this.id;
        this.modalIdE = 'AdditionalResendBankVerificationLinkCompleted' + this.id;
    }

    onClick() {
        this.googleAnalyticsService.trackEvent('additional-options', 'open', 'resend-bank-verification-link');
        this.sendForm.controls.ownerEmail.setValue('');
        this.sendForm.controls.sameInfo.setValue('true');
        this.getApplication(this.id).then(
            () => {
                this.changeSameInfo();
                $('#additionalResendBankVerificationLinkModal' + this.id).modal();
            }
        );
    }

    getApplication(appId: number) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'application/' + appId, 'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
                    (data: any) => {
                        if (data.object) {
                            data.object.lessee.owners.forEach((ow: any) => {
                                if (ow.primaryOwner) {
                                    this.defaultSendDocsFormData.ownerEmail = ow.email;
                                }
                            });
                        }
                        resolve(data);
                    },
                    (error: any) => {
                        resolve(error);
                    }
                );
        });
    }

    sendApp() {
        this.submitted = true;
        if (this.sendForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}application/finicity-link/${this.id}`, 'application/json', `newEmail=${this.sendForm.controls.ownerEmail.value}`).subscribe(
                (data: any) => {
                    this.googleAnalyticsService.trackEvent('additional-options', 'sent', 'resend-bank-verification-link');
                    this.errorTitle = 'Bank Verification Link sent.';
                    this.errorMessageA = 'Your application Bank Verification Link was successfully sent by email.';
                    this.errorMessageB = '';
                    this.errorMessageC = '';
                    this.errorMessageBClass = '';
                    this.dialogClass = 'w450';
                    $('#additionalResendBankVerificationLinkModal' + this.id).modal('hide');
                    $('#errorModalDialogAdditionalResendBankVerificationLinkCompleted' + this.id).modal();
                    this.submitted = false;
                    resolve(data);
                },
                (error: any) => {
                    this.errorTitle = 'Sorry, we found a problem';
                    this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                    this.errorMessageB = error.error.msg;
                    this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                    this.errorMessageBClass = 'mB20';
                    this.dialogClass = 'w600';
                    $('#additionalResendBankVerificationLinkModal' + this.id).modal('hide');
                    $('#errorModalDialogAdditionalResendBankVerificationLinkCompleted' + this.id).modal();
                    this.submitted = false;
                    reject(error);
                }
            );
        });
    }

    changeSameInfo() {
        if (this.sendForm.controls.sameInfo.value != 'true') {
            this.sendForm.controls.ownerEmail.setValue(this.defaultSendDocsFormData.ownerEmail);
            this.sendForm.controls.ownerEmail.disable();
        } else {
            this.sendForm.controls.ownerEmail.enable();
        }
    }
}
