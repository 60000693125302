import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ApplicationOwnerItem } from '../application/application.owner.item';
import { ApplicationBusinessInfo } from '../application/application.business.info';
import { LoadingScreenService } from '../../shared/services/loading-screen.service';
declare var $: any;

@Component({
	selector: 'app-offers',
	templateUrl: './offers.component.html',
	styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit, OnDestroy {

	loaded: boolean;
	customerName: any;
	vodRequired: boolean;
	applicationId: number;
	errorGettingOffers: boolean;
	finicityCompletedError: boolean;
	recalculateFormControl: UntypedFormControl = new UntypedFormControl();

	errorTitle = '';
	errorMessageA = '';
	errorId = 'OffersE';

	industries: any[] = [];
	statesOfIncorporation: any[] = [];
	owners: ApplicationOwnerItem[] = [new ApplicationOwnerItem(this.formBuilder)];
	appInfo: ApplicationBusinessInfo = new ApplicationBusinessInfo(this.formBuilder, this.authService);

	private errors: any = [];
	private tryCount: number = 0;
	private generateOffers = false;
	private offers: any = { offerList: [] };

	constructor(
		private connectionService: ConnectionService,
		public router: Router,
		private authService: AuthService,
		private formBuilder: UntypedFormBuilder,
		private loadingScreenService: LoadingScreenService
	) {
		const navigation = this.router.getCurrentNavigation();
		if (navigation.extras.state) {
			this.applicationId = navigation.extras.state.applicationId;
			this.generateOffers = navigation.extras.state.generateOffers;
		} else {
			this.router.navigateByUrl('/');
		}
	}

	ngOnInit() {
		//this.googleAnalyticsService.trackEvent("app-component", "app-offers");
		this.getCustomerName();
		this.loaded = false;
		this.getOffers(this.applicationId).then(
			(data: any) => {
				if (data.object && data.object.vodRequired) {
					this.vodRequired = true;
					this.getFinicityURL(this.applicationId).then(
						() => { this.loaded = true; },
						() => { this.loaded = true; }
					)
				} else {
					this.vodRequired = false;
					this.loaded = true;
				}
			},
			(error: any) => {
				this.loaded = true;
			}
		);
	}

	ngOnDestroy() {
		window['finicityConnect'].destroy();
	}

	getCustomerName() {
		var appID = this.applicationId;
		this.connectionService.getRequest(environment.gateway + 'application/' + appID + '/details?', 'application/json').subscribe(
			(data: any) => {
				this.customerName = data.object.fullName;
			}, (error: any) => {
				console.log(error);
			}
		);
	}

	getOffers(applicationId: number, options?: any) {
		return new Promise((resolve, reject) => {
			const url = this.generateOffers ?
				environment.gateway + 'application/' + applicationId + '/offers/' + this.authService.getUserId() :
				environment.gateway + 'application/' + applicationId + '/activeOffers/' + this.authService.getUserId();
			this.connectionService.getRequest(url, 'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
				(data: any) => {
					if (data.object) {
						if (data.msg == 'DATA_ERROR') {
							this.errors = data.object;
							console.log(this.errors)
							this.errorGettingOffers = true;
						} else if (data.object.status != 'REJECTED') {
							this.offers = data.object;
							this.errorGettingOffers = false;
						}
						else {
							this.errorGettingOffers = true;
						}
					} else {
						this.errorGettingOffers = true;
					}
					resolve(data);
				},
				(error: any) => {
					this.errorGettingOffers = true;
					reject(error);
				}
			);
		});
	}

	validateTransaction(applicationId: number) {
		this.tryCount += 1;
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				this.connectionService.getRequestUnsecured(environment.gateway + 'application/transaction/validate/' + applicationId, 'application/json').subscribe(
					(data: any) => {
						if (data.object) {
							resolve(data);
						} else if (this.tryCount < 3) {
							this.validateTransaction(this.applicationId).then(
								(vData: any) => resolve(vData),
								(vError: any) => reject(vError)
							);
						} else {
							reject(data);
						}
					}, (error: any) => {
						reject(error);
					}
				);
			}, 40000);
		});
	}

	getFinicityURL(applicationId: number) {
		return new Promise((resolve, reject) => {
			this.connectionService.getRequest(environment.gateway + 'application/' + applicationId + '/vod/information', 'application/json').subscribe(
				(data: any) => {
					if (data.object) {
						if (data.object.url) {
							window['finicityConnect'].launch(data.object.url, {
								selector: '#finicityIframe',
								overlay: 'rgba(255,255,255, 0)',
								success: (event) => {
									if (event.reason == 'complete' && event.code == 200) {
										this.loaded = false;
										this.tryCount = 0;
										this.loadingScreenService.startFinicityLoading();
										this.validateTransaction(this.applicationId).then(
											(vData: any) => {
												this.getOffers(this.applicationId, { finicity: true }).then(
													(oData: any) => {
														this.loadingScreenService.stopFinicityLoading();
														this.finicityCompletedError = false;
														this.vodRequired = false;
														this.loaded = true;
													},
													(error: any) => {
														console.log(error);
														this.loadingScreenService.stopFinicityLoading();
														this.finicityCompletedError = true;
														this.loaded = true;
													}
												)
											},
											(vError: any) => {
												console.log(vError);
												this.loadingScreenService.stopFinicityLoading();
												this.finicityCompletedError = true;
												this.loaded = true;
											}
										);
									}
								},
								cancel: (event: any) => {
									console.log(event);
								},
								error: (error: any) => {
									console.log(error);
								},
								loaded: () => {
									$('div.finicityIframe')[0].appendChild($('#finicityConnectIframe')[0]);
								},
								route: (event: any) => {
									console.log(event);
								},
								user: (event: any) => {
									console.log(event);
								}
							});
						} else {
							this.router.navigateByUrl('/general-error');
						}
					}
					resolve(data);
				},
				(error: any) => {
					this.errorGettingOffers = true;
					reject(error);
				}
			);
		});
	}

	getApplication(applicationId: number) {
		return new Promise((resolve, reject) => {
			this.connectionService.getRequest(environment.gateway + 'application/' + applicationId, 'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
				(data: any) => {
					if (data.object) {
						this.owners = [];
						this.appInfo.decode(data.object);
						data.object.lessee.owners.forEach((owner: any) => {
							let ownerTemp: ApplicationOwnerItem = new ApplicationOwnerItem(this.formBuilder);
							ownerTemp.decode(owner);
							this.owners.push(ownerTemp);
						});
					}
					resolve(data);
				},
				(error: any) => {
					reject(error);
				}
			);
		});
	}

	getStates() {
		return new Promise((resolve, reject) => {
			this.connectionService.getRequestUnsecured(environment.gateway + 'utils/states', 'application/json').subscribe(
				(data: any) => {
					this.statesOfIncorporation = data.object;
					resolve(data);
				},
				(error: any) => {
					reject(error);
				}
			);
		});
	}

	getNaics() {
		return new Promise((resolve, reject) => {
			this.connectionService.getRequestUnsecured(environment.gateway + 'utils/naics', 'application/json').subscribe(
				(data: any) => {
					this.industries = data.object;
					resolve(data);
				},
				(error: any) => {
					reject(error);
				}
			);
		});
	}

	onContinueClick() {
		this.router.navigateByUrl('/home', { state: { appId: this.applicationId } });
	}

	sendFinicityVerifyBankAccountEmail() {
		return new Promise((resolve, reject) => {
			this.connectionService.getRequest(environment.gateway + 'application/finicity-link/' + this.applicationId, 'application/json').subscribe(
				(data: any) => {
					this.errorTitle = 'Email sent.';
					this.errorMessageA = 'An email to verify your bank account was sent.';
					$('#errorModalDialogOffersE').modal();
					resolve(data);
				},
				(error: any) => {
					this.errorTitle = 'Error sending email.';
					this.errorMessageA = error.error.msg;
					$('#errorModalDialogOffersE').modal();
					reject(error);
				}
			);
		});
	}

	get displayOption(): string {
		if (this.finicityCompletedError) {
			return 'finicityError';
		} else if (this.vodRequired) {
			return 'finicity';
		} else if (this.errorGettingOffers && this.errors.length > 0) {
			return 'dROffersError';
		} else if (this.errorGettingOffers) {
			return 'offersError';
		} else {
			return 'offers';
		}
	}

}
