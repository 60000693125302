<div class="impersonate-header" *ngIf="logged && isImpersonating()">
  <div>
    <img src="/assets/images/icons/addCoSignerB.svg" alt="" />
    <div>Logged in as {{impersonatedUser.accountName}}</div>
    <span class="out" (click)="onClearImpersonatedVendor();">Exit</span>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light"
  [ngClass]="{'navbar-small': navSmall, 'navbar-small navbar-v3': isV3}">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      <img class="logo-large" [ngClass]="{'is-v3-header': isV3}"
        [src]="vendorLogo ? vendorLogo : '/assets/images/click-lease-logo.png'" alt="logo-larg" />
      <img class="logo-small" [ngClass]="{'is-v3-header': isV3}" src="/assets/images/logo.png" alt="logo-small" />
    </a>
    <button [hidden]="isV3" class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
      aria-label="Toggle navigation" *ngIf="logged">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup" *ngIf="logged && !isV3">
      <div class="navbar-nav">
        <div class="user-options-container" *ngIf="vendors.length > 1">
          <a class="nav-item nav-link sales-person" id="header-select-vendor-link">
            <span class="sales-person" (click)="toggleVendorOptions()">
              Selected Vendor: {{vendorSelected.vendorName}}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.619" height="5.022" viewBox="0 0 8.619 5.022"
              (click)="toggleVendorOptions()">
              <defs>
                <style>
                  .cls-1 {
                    fill: #5e6367;
                  }
                </style>
              </defs>
              <path id="Path_1363" data-name="Path 1363" class="cls-1" d="M4.309,5l4.309,5.022H0Z"
                transform="translate(8.619 10.022) rotate(180)" />
            </svg>
            <span class="cancel-broker"
              *ngIf="vendorSelected.vendorType != 'BROKER' || vendorSelected.vendorType != 'INDYREP'"
              (click)="onClearBroker(true);">Clear</span>
          </a>
          <div class="user-options sales-person " [ngClass]="{'open': vendorOptionsOpen}">
            <div class="backdrop" (click)="toggleVendorOptions()"></div>
            <div class="user-options-options vendorScroll">
              <div class="only-mobile close" (click)="toggleVendorOptions()">
                <img src="/assets/images/icons/close_b.svg" alt="close" />
              </div>
              <form [formGroup]="searchVendorForm">
                <div class="form-row">
                  <div class="form-group col-12">
                    <input id="searchVendorName" type="text" formControlName="search" class="form-control"
                      placeholder="Search..." name="search">
                    <em class="fas fa-search"></em>
                  </div>
                </div>
              </form>
              <h3 class="font-mont-semi-bold">
                Vendor
                <span class="font-mont-light" routerLink="/vendor" (click)="onClearBroker(false);">
                  Vendor Management
                </span>
              </h3>
              <div *ngFor="let vendor of vendors | like:vFilter:vFilterValue; let i = index"
                [ngClass]="{'selected-sales-person': vendorSelected.salespersonId == vendor.salespersonId}">
                <img src="/assets/images/icons/addCoSigner.svg" *ngIf="vendor.vendorType == 'VENDOR'" alt="add" />
                <img src="/assets/images/icons/addCoSignerBlack.svg" class="img-black"
                  *ngIf="vendor.vendorType == 'VENDOR'" alt="add" />
                <div>
                  <div (click)="toggleVendorOptions(); changeVendor(vendor);" role="button"
                    class="font-mont-semi-bold vendor-name" *ngIf="vendor.vendorType == 'VENDOR'">
                    {{vendor.vendorName}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="nav-item nav-link" routerLinkActive="link-active" id="header-home-link" routerLink="/home">Home</a>
        <a class="nav-item nav-link" routerLinkActive="link-active" id="header-apply-link"
          (click)="toggleApply()">Apply</a>
        <a class="nav-item nav-link" routerLinkActive="link-active" id="header-apply-link" href="leasable/list"
          *ngIf="leasableUser||leasableAdmin">Product
          Catalog</a>
        <a class="nav-item nav-link" routerLinkActive="link-active" id="header-admin-link" (click)="toggleAdmin()"
          *ngIf="userAdmin">Admin</a>
        <a class="nav-item nav-link" routerLinkActive="link-active" id="header-admin-link" (click)="toggleRisk()"
          *ngIf="userRisk">Risk</a>
        <a class="nav-item nav-link" routerLinkActive="link-active" id="header-home-link" routerLink="/application"
          *ngIf="false">Analytics</a>
        <a class="nav-item nav-link" routerLinkActive="link-active" id="header-help-link"
          href="https://www.clicklease.com/partner_help_desk/" target="_blank">Help</a>
        <a class="nav-item nav-link only-mobile" routerLinkActive="link-active" id="header-logout-link"
          (click)="logout();" target="_blank">Logout</a>
        <div class="only-mobile">
          <a class="live-chat" target="_blank"
            href="https://home-c35.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=97e77c7f-d8a6-4af0-95eb-f8975110c562&bu=4599274">
            <img src="/assets/images/icons/live_chat.svg" alt="live_chat" />
          </a>
        </div>
        <div class="user-img-bar hide-mobile">|</div>
        <div class="user-options-container hide-mobile">
          <img src="/assets/images/icons/user.svg" class="user-img" (click)="toggleUserOptions()" alt="user" />
          <div class="user-options" [ngClass]="{'open': userOptionsOpen}">
            <div class="backdrop" (click)="toggleUserOptions()"></div>
            <div class="user-options-options">
              <div (click)="toggleUserOptions(); logout();" role="button" class="font-mont-semi-bold">
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<div class="apps-menu" [ngClass]="{'open': applyOpen}" *ngIf="logged">
  <div class="backdrop" (click)="toggleApply()"></div>
  <div class="apps-menu-container">
    <div class="only-mobile close" (click)="toggleApply()">
      <img src="/assets/images/icons/close_b.svg" alt="close" />
    </div>
    <div [ngClass]="{'disabledMenu': applyOptionDisabled}" *ngIf="!hideUser()"
      (click)="toggleApply(); navigateToApplication();" id="headerApplyNow">
      <img src="/assets/images/icons/apply_now.svg" alt="apply" />
      <div class="font-mont-semi-bold">Apply Now</div>
    </div>
    <div [ngClass]="{'disabledMenu': applyOptionDisabled}" data-toggle="modal" data-target="#headerSendApplicationModal"
      (click)="toggleApply()">
      <img src="/assets/images/icons/send_via_email.svg" alt="email" />
      <div class="font-mont-semi-bold">Send Via Email</div>
    </div>
    <div [ngClass]="{'disabledMenu': applyOptionDisabled}" data-toggle="modal" data-target="#headerSendApplicationTextModal"
      (click)="toggleApply()">
      <img src="/assets/images/icons/send_via_text.svg" alt="text" />
      <div class="font-mont-semi-bold">Send Via Text</div>
    </div>
    <div data-toggle="modal" data-target="#headerSendUppsellApplicationModal" *ngIf="false">
      <img src="/assets/images/icons/down.svg" alt="down" />
      <div class="font-mont-semi-bold">Up-Sell Code</div>
    </div>
  </div>
</div>
<div class="apps-menu" [ngClass]="{'open': adminOpen}" *ngIf="logged && userAdmin">
  <div class="backdrop" (click)="toggleAdmin()"></div>
  <div class="apps-menu-container">
    <div class="only-mobile close" (click)="toggleAdmin()">
      <img src="/assets/images/icons/close_b.svg" alt="close" />
    </div>
    <div routerLink="/impersonate" (click)="toggleAdmin()">
      <img src="/assets/images/icons/impersonate_vendor.svg" alt="" />
      <div class="font-mont-semi-bold">Impersonate Vendor</div>
    </div>
    <div routerLink="/ach-converter" (click)="toggleAdmin()">
      <img src="/assets/images/icons/ach_documents_converter.svg" alt="" />
      <div class="font-mont-semi-bold">ACH Converter</div>
    </div>
    <div data-toggle="modal" data-target="#headerReportModal" (click)="toggleAdmin()">
      <img src="/assets/images/icons/reports.svg" alt="" />
      <div class="font-mont-semi-bold">Reports</div>
    </div>
  </div>
</div>
<div class="apps-menu" [ngClass]="{'open': riskOpen}" *ngIf="logged && userRisk">
  <div class="backdrop" (click)="toggleRisk()"></div>
  <div class="apps-menu-container">
    <div class="only-mobile close" (click)="toggleRisk()">
      <img alt="Close" src="/assets/images/icons/close_b.svg" />
    </div>
    <div routerLink="/multirisk" (click)="toggleRisk()" *ngIf="userRisk">
      <img alt="Multi Risk Tool" src="/assets/images/icons/multi_risk.svg" />
      <div class="font-mont-semi-bold">Multi Risk</div>
    </div>
    <div routerLink="/pricing" (click)="toggleRisk()" *ngIf="userRisk">
      <img alt="Pricing Tool" src="/assets/images/icons/pricing.svg" />
      <div class="font-mont-semi-bold">Pricing</div>
    </div>
    <div routerLink="/max-offer" (click)="toggleRisk()" *ngIf="userRisk">
      <img alt="Max Offer Tool" src="/assets/images/icons/decision_models.svg" />
      <div class="font-mont-semi-bold">Max offer</div>
    </div>
    <div routerLink="/term-tool" (click)="toggleRisk()" *ngIf="userRisk">
      <img alt="Term Tool" src="/assets/images/icons/send_via_text.svg" />
      <div class="font-mont-semi-bold">Term Tool</div>
    </div>
    <div routerLink="/data-robot" (click)="toggleRisk()" *ngIf="userRisk">
      <img alt="Data Robot Tool" src="/assets/images/icons/send_via_text.svg" />
      <div class="font-mont-semi-bold">Data Robot</div>
    </div>
    <div routerLink="/fraud-list" (click)="toggleRisk()">
      <img alt="Fraud list tool" src="/assets/images/icons/send_via_text.svg" />
      <div class="font-mont-semi-bold">Fraud List</div>
    </div>
  </div>
</div>
<app-header-send-application-dialog *ngIf="logged"></app-header-send-application-dialog>
<app-header-send-upsell-application *ngIf="logged"></app-header-send-upsell-application>
<app-header-vendor-brocker-select-dialog *ngIf="logged"></app-header-vendor-brocker-select-dialog>
<app-header-report-dialog *ngIf="logged"></app-header-report-dialog>