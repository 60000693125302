declare var $: any;
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Userpilot } from 'userpilot';
@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
    public loginErrorMsg1 = '';
    public loginErrorMsg2 = '';

    @ViewChild('emailInput', { static: true }) emailInputRef: ElementRef;
    @ViewChild('passwordInput', { static: true }) passwordInputRef: ElementRef;
    @ViewChild('mEmailInput', { static: true }) mEmailInputRef: ElementRef;

    emailCtrl = new UntypedFormControl('', [Validators.required, Validators.email]);

    constructor(private ngZone: NgZone, private router: Router, private authService: AuthService) {
    }

    ngOnInit() {
    }


    onSignin() {
        const promise = new Promise((resolve, reject) => {
            const email = this.emailInputRef.nativeElement.value;
            const password = this.passwordInputRef.nativeElement.value;
            this.authService.singinUser(email, password).then(
                (data: any) => {
                    this.authService.getLoggedUserData().then(
                        (getLoggedData: any) => {
                            this.userPilotIdentification(getLoggedData.object);
                            this.ngZone.run(() => this.router.navigate(['home'])).then();
                            resolve(data);
                        },
                        (getLoggedError: any) => {
                            $('#loginErrorModal').modal('show');
                            reject(getLoggedError);
                        }
                    );
                },
                (error: any) => {
                    if (error && error.error && error.error.object && error.error.object.error_description == 'User is locked.') {
                        this.loginErrorMsg1 = 'Your user has been locked. Please click on "Forgot password?" to reset your password or contact support to';
                        this.loginErrorMsg2 = 'support@clicklease.com';
                    } else {
                        this.loginErrorMsg1 = 'The User Name or Password is invalid. Please try again.';
                        this.loginErrorMsg2 = '';
                    }

                    $('#loginErrorModal').modal('show');
                    reject(error);
                }
            );
        });
        return promise;
    }

    inputClear() {
        this.emailCtrl.reset();
    }

    onForgotPassword() {
        const promise = new Promise((resolve, reject) => {
            const email = this.mEmailInputRef.nativeElement.value;
            this.authService.sendResetPasswordEmail(email).subscribe(
                data => {
                    $('#forgotPasswordSuccessModal').modal('show');
                    resolve(data);
                },
                error => {
                    $('#forgotPasswordErrorModal').modal('show');
                    reject(error);
                }
            );
        });
        return promise;
    }

    userPilotIdentification(userInformation: any) {
        const rolesInfo = userInformation.roles.map(function (item) {
            return item['description'];
        });

        Userpilot.identify(
            userInformation.id
            , {
                name: userInformation.firstName,
                email: userInformation.userName,
                created_at: userInformation.lastLogin,
                company:  // optional 
                {
                    id: userInformation.defaultVendor.vendorId, // Company Unique ID
                    name: userInformation.defaultVendor.vendorName,
                    created_at: userInformation.lastLogin,
                },

                //Additional properties
                role: rolesInfo.toString(),
                // industry: '',
                // equipmenClassification: '',
                // trailerDealer: false,
                // accountCreatedDate: '',
                // edgarFundedDeals: 0
            }
        );
    }
}
