<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="AddCoSignerEmail"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <form [formGroup]="sendForm" (ngSubmit)="sendApp();">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src="/assets/images/icons/close_b.svg" />
                        CLOSE WINDOW
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                        <i class="far fa-envelope"></i>
                        Add Co-Signer Via Email...
                    </h5>
                    <div class="form-row">
                        <div class="form-group col-sm-12">
                            <label for="headerSendEmailInput"
                                [ngClass]="{ 'color-red': submitted && sendForm.controls.email.errors }">Email:
                                *</label>
                            <input type="text" formControlName="email" class="form-control" id="headerSendEmailInput"
                                placeholder="Email" name="headerSendEmailInput"
                                [ngClass]="{ 'is-invalid': submitted && sendForm.controls.email.errors }">
                            <div *ngIf="submitted && sendForm.controls.email.errors" class="invalid-feedback">
                                <div *ngIf="sendForm.controls.email.errors.required">
                                    Email is required.
                                </div>
                                <div *ngIf="sendForm.controls.email.errors.email">
                                    Invalid Email.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                                SEND
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="modalIdE" [title]="errorTitle" [messageA]="errorMessageA" [messageB]="errorMessageB"
    [messageC]="errorMessageC" [messageBClass]="errorMessageBClass" [dialogClass]="dialogClass"
    (closeModal)="onCloseModal();">
</app-error-dialog>