import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
declare var $: any;

@Component({
    selector: 'app-leasable-items-drag-and-drop',
    templateUrl: './leasable-items-drag-and-drop.component.html',
    styleUrls: ['./leasable-items-drag-and-drop.component.css']
})
export class LeasableItemsDragAndDropComponent implements OnInit {

    @Input() elementId = 'elementidtestweird';
    @Input() lineA = 'Drag and drop file';
    @Input() lineB = 'here or';
    @Input() lineC = 'select file.';
    @Input() uploaded = false;
    @Input() customClass = '';
    @Output() onFileAdded = new EventEmitter<any>();
    @Output() onClick = new EventEmitter<any>();

    @Input() documents: any[] = [];
    public isDragOver = false;

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit() {
        var formatBytes = function (a: any, b: any) { if (0 == a) return "0 Bytes"; var c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]; };
        var droppedFiles = false;
        var onFileAdded = this.onFileAdded;
        $('#' + this.elementId).on('drag dragstart dragend dragover dragenter dragleave drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
        }).on('dragover dragenter', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = true;
        }.bind(this)).on('dragleave dragend drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = false;
        }.bind(this)).on('drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            droppedFiles = e.originalEvent.dataTransfer.files;

            if (droppedFiles[0].type === 'text/csv') {
                var getBase64 = function (file: any) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        onFileAdded.emit({ file: file });
                    };
                    reader.onerror = function (error) {
                    };

                };

                $.each(droppedFiles, function (i: any, file: any) {
                    getBase64(file);
                });
            }

        });

        if (this.onClick.observers.length) {
            $('#' + this.elementId).on('click', function (e: any) {
                e.preventDefault();
                e.stopPropagation();
                this.onClick.emit(e);
            }.bind(this));
        }
    }

    fileChangeEvent(inputFile: any) {
        var formatBytes = function (a: any, b: any) { if (0 == a) return "0 Bytes"; var c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]; };
        if (inputFile.target.accept === 'text/csv') {
            var droppedFiles = inputFile.target.files;
            var onFileAdded = this.onFileAdded;
            var getBase64 = function (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    onFileAdded.emit({ file: file });

                };
                reader.onerror = function (error) {
                };

            };
        }

        $.each(droppedFiles, function (i: any, file: any) {
            getBase64(file);
        });

        $(inputFile.target).val('');
    }

    onRemoveDocument(document: any) {
        var index = this.documents.indexOf(document);
        if (index > -1) {
            this.documents.splice(index, 1);
            
        }
        this.documents = [];
    }

}
