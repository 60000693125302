import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from './analytics/google.analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent {
    title = 'internal-portal';

    constructor(public router: Router, public googleAnalyticsService: GoogleAnalyticsService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.googleAnalyticsService.trackPageView(event.urlAfterRedirects);
            }
        });
    }
}
