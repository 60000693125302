import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConnectionService } from '../shared/services/connection.service';
import { v4 as uuidv4 } from 'uuid';
declare var ga: Function;
declare var gtag: Function;
declare var gaData: any;

@Injectable()
export class GoogleAnalyticsService {
    private leadId: number;
    private sessionId: string;
    private browserId: string;
    private experimentId: string;
    private GA4ClientId: string;
    private applicationId: string;
    private v3Label: string = "application-token";
    private clientId: string;
    public clickSignVariant: string = '';

    constructor(private connectionService: ConnectionService) {
        void (0);
        let sessionData = sessionStorage.getItem('session_id_v3');
        let browserData = localStorage.getItem('browser_id_v3');
        let experimentData = localStorage.getItem('experiment_id_v3');
        let experimentDataGA4 = localStorage.getItem('experiment_id_v3_GA4');
        let clientData = localStorage.getItem('client_id_v3');
        if (sessionData) {
            this.sessionId = sessionData;
        } else {
            this.sessionId = uuidv4();
            sessionStorage.setItem('session_id_v3', this.sessionId);
        }
        if (clientData) {
            this.clientId = clientData;
        } else {
            this.clientId = uuidv4();
            sessionStorage.setItem('session_id_v3', this.clientId);
        }
        if (browserData) {
            this.browserId = browserData;
        }
        if (experimentData) {
            this.experimentId = experimentData;
        }
        if(experimentDataGA4) {
            this.GA4ClientId = experimentDataGA4;
        }
        this.gaTrack((tracker: any) => {
            this.experimentId = tracker.get('clientId');
            localStorage.setItem('experiment_id_v3', this.experimentId);
            if (gaData && gaData[environment.TRACKING_ID] && gaData[environment.TRACKING_ID].experiments) {
                this.browserId = Object.keys(gaData[environment.TRACKING_ID].experiments)[0];
                localStorage.setItem('browser_id_v3', this.browserId);
            }
        });
        this.gtagTrackId('get', environment.TRACKING_ID_GA4, 'client_id', (GA4_ID: string) => {
            this.GA4ClientId = GA4_ID.replace('.', '_');
            localStorage.setItem('experiment_id_v3_GA4', this.GA4ClientId);
        });
    }

    public gaTrack(param1?, param2?, param3?) {
        if (typeof ga === 'function') {
            ga(param1, param2, param3);
        } else {
            setTimeout(() => {
                this.gaTrack(param1, param2, param3);
            }, 400);
        }
    }

    public gaTagTrack(param1?, param2?, param3?) {
      if (typeof gtag === 'function') {
          gtag(param1, param2, param3);
      } else {
          setTimeout(() => {
              this.gaTagTrack(param1, param2, param3);
          }, 400);
      }
  }

  public gtagTrackId(param1?, param2?, param3?, param4?) {
    if (typeof gtag === 'function') {
        gtag(param1, param2, param3, param4);
    } else {
        setTimeout(() => {
            this.gtagTrackId(param1, param2, param3, param4);
        }, 400);
    }
}

    public trackLoginEvent(userId: string) {
        this.gaTrack('set', 'userId', userId);
        this.gaTrack('set', 'dimension1', userId);
        this.trackEvent('login', 'login', 'login');
        this.gaTagTrack('event', 'login',{
            'user_id': userId
        })


    }

    public trackPageView(page: string) {
        this.gaTrack('set', 'page', page);
        this.gaTrack('send', 'pageview');
        this.gaTagTrack('event', 'pageview',{})
    }

    public setLeadId(leadId: any, vendorName: string) {
        if (leadId) {
            this.leadId = parseInt(leadId);
            this.trackV3Event('prove-response-success', vendorName, leadId);
        } else {
            this.leadId = undefined;
        }
    }

    public setClickSignVariant(signVersion: string) {
        this.clickSignVariant = signVersion;
    }

    public setApplicationId(applicationId: number) {
        if (applicationId) {
            this.applicationId = applicationId + '';
        } else {
            this.applicationId = undefined;
        }
    }

    public trackEvent(
        eventCategory: string,
        eventAction: string,
        eventLabel: string = null,
        eventValue: number = null,
        customDimensions?: any) {

        if (customDimensions) {
            this.gaTrack('set', customDimensions);
        }

        const event = {
            'eventCategory': eventCategory,
            'eventAction': eventAction,
            'eventLabel': eventLabel,
            'eventValue': eventValue != null && eventValue != undefined ? eventValue : 0
        }

        this.gaTrack('send', 'event', event);

        if (customDimensions) {
            Object.keys(customDimensions).forEach(k => customDimensions[k] = null);
            this.gaTrack('set', customDimensions);
        }
    }

    public trackV2Event(
        eventAction: string,
        vendorName: string,
        token?: string,
        eventData?: string,
        label?: string
    ) {
        let customDimensions = {};
        customDimensions[environment.GA_DIMENSIONS.REFERRAL_PAGE] = vendorName;
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_EXPERIENCE] = 'No Experiment Active';
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_VARIANT] = 'V2';
        customDimensions[environment.GA_DIMENSIONS.CLIENT_ID] = this.experimentId;
        customDimensions[environment.GA_DIMENSIONS.APPLICATION_ID] = this.applicationId;
        customDimensions[environment.GA_DIMENSIONS.SOURCE] = this.connectionService.getApplicationSource();
        if (eventData) {
            customDimensions[environment.GA_DIMENSIONS.DATA] = eventData;
        }
        this.trackEvent('application', eventAction, token ? 'application-token' : 'application', 1, customDimensions);
        this.gaTagTrack('event', eventAction, {
            'data': eventData,
            'vendor_name': vendorName,
            'application_token': token,
            'google_experience': 'No Experiment Active',
            'google_variant': 'V2',
            'label': token ? 'application-token' : 'application',
            'session_id': this.sessionId,
            'clk_client_id': this.GA4ClientId,
            'application_id': this.applicationId,
            'source': this.connectionService.getApplicationSource()
          });
        let postData: any = {
            action: eventAction,
            category: 'application',
            data: eventData,
            label: token ? 'application-token' : 'application',
            value: 1,
            referralPage: vendorName,
            googleVariant: 'V2',
            googleExperience: 'No Experiment Active',
            sessionId: this.sessionId,
            applicationId: this.applicationId,
            browserId: this.browserId,
            experienceId: this.experimentId ? this.experimentId : this.clientId
        };
        //this.connectionService.postV3Request(`${environment.gatewayV3}googleanalytics-service/api/analytics-dataNL`, postData, 'application/json', null).subscribe(data => { });
    }

    public trackClickSignEvent(
        eventAction: string,
        vendorName: string,
        eventData?: string,
        errorCode?: any
    ) {
        let customDimensions = {};
        customDimensions[environment.GA_DIMENSIONS.REFERRAL_PAGE] = vendorName;
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_EXPERIENCE] = environment.GoogleExperience;
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_VARIANT] = this.clickSignVariant;
        customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.DATA] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.CLIENT_ID] = this.experimentId;
        customDimensions[environment.GA_DIMENSIONS.APPLICATION_ID] = this.applicationId;
        customDimensions[environment.GA_DIMENSIONS.SOURCE] = this.connectionService.getApplicationSource();
        if (errorCode) {
            let stringData;
            if (typeof errorCode === 'string' || errorCode instanceof String) {
                stringData = errorCode;
            } else {
                try {
                    stringData = JSON.stringify(errorCode);
                } catch (error) {
                    stringData = errorCode + '';
                }
            }
            customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = stringData;
        }
        if (eventData) {
            customDimensions[environment.GA_DIMENSIONS.DATA] = eventData;
        }
        this.trackEvent('application', eventAction, 'click-sign-token', 1, customDimensions);
        this.gaTagTrack('event', eventAction, {
          'data': eventData,
          'error_data': errorCode,
          'vendor_name': vendorName,
          'google_experience': environment.GoogleExperience,
          'google_variant': this.clickSignVariant,
          'label': 'click-sign-token',
          'session_id': this.sessionId,
          'clk_client_id': this.GA4ClientId,
          'application_id': this.applicationId,
          'source': this.connectionService.getApplicationSource()
        });
        let postData: any = {
            action: eventAction,
            category: 'application',
            data: eventData,
            label: 'click-sign-token',
            value: 1,
            referralPage: vendorName,
            googleVariant: this.clickSignVariant,
            googleExperience: environment.GoogleExperience,
            sessionId: this.sessionId,
            applicationId: this.applicationId,
            browserId: this.browserId,
            experienceId: this.experimentId ? this.experimentId : this.clientId
        };
        //this.connectionService.postV3Request(`${environment.gatewayV3}googleanalytics-service/api/analytics-dataNL`, postData, 'application/json', null).subscribe(data => { });
    }

    public trackAplicationConversion() {
        this.gaTrack('event', 'conversion', { 'send_to': 'AW-702859012/TKQcCMzO5KwDEISOk88C' });

    }

    public trackV3Event(
        eventAction: string,
        vendorName: string,
        eventData?: string,
        errorCode?: any
    ) {
        let customDimensions = {};
        customDimensions[environment.GA_DIMENSIONS.REFERRAL_PAGE] = vendorName;
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_EXPERIENCE] = 'No Experiment Active';
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_VARIANT] = 'V3';
        customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.DATA] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.CLIENT_ID] = this.experimentId;
        customDimensions[environment.GA_DIMENSIONS.APPLICATION_ID] = this.applicationId;
        customDimensions[environment.GA_DIMENSIONS.SOURCE] = this.connectionService.getApplicationSource();
        if (errorCode) {
            let stringData;
            if (typeof errorCode === 'string' || errorCode instanceof String) {
                stringData = errorCode;
            } else {
                try {
                    stringData = JSON.stringify(errorCode);
                } catch (error) {
                    stringData = errorCode + '';
                }
            }
            customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = stringData;
        }
        if (eventData) {
            customDimensions[environment.GA_DIMENSIONS.DATA] = eventData;
        }
        this.trackEvent('application', eventAction, this.v3Label, 1, customDimensions);
        this.gaTagTrack('event', eventAction, {
          'data': eventData,
          'error_data': errorCode,
          'vendor_name': vendorName,
          'google_experience': 'No Experiment Active',
          'google_variant': 'V3',
          'label': this.v3Label,
          'lead_id': this.leadId,
          'clk_client_id': this.GA4ClientId,
          'application_id': this.applicationId,
          'source': this.connectionService.getApplicationSource()
        });

        let postData: any = {
            action: eventAction,
            category: "application",
            data: eventData,
            label: this.v3Label,
            value: 1,
            referralPage: vendorName,
            googleVariant: "V3",
            googleExperience: 'No Experiment Active',
            leadId: this.leadId ? this.leadId : undefined,
            sessionId: this.sessionId,
            applicationId: this.applicationId,
            browserId: this.browserId,
            experienceId: this.experimentId ? this.experimentId : this.clientId
        };
        //this.connectionService.postV3Request(`${environment.gatewayV3}googleanalytics-service/api/analytics-dataNL`, postData, 'application/json', null).subscribe(data => { });

    }

    public dlTrackEvent(eventAction: string, vendorName: string, token: string, eventData?: string, errorCode?: any) {
        let customDimensions = {};
        customDimensions[environment.GA_DIMENSIONS.REFERRAL_PAGE] = vendorName;
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_EXPERIENCE] = 'No Experiment Active';
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_VARIANT] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.DATA] = this.applicationId ? this.applicationId : 'n/a';
        customDimensions[environment.GA_DIMENSIONS.CLIENT_ID] = this.experimentId;
        customDimensions[environment.GA_DIMENSIONS.APPLICATION_ID] = token;
        customDimensions[environment.GA_DIMENSIONS.SOURCE] = this.connectionService.getApplicationSource();
        if (errorCode) {
            let stringData;
            if (typeof errorCode === 'string' || errorCode instanceof String) {
                stringData = errorCode;
            } else {
                try {
                    stringData = JSON.stringify(errorCode);
                } catch (error) {
                    stringData = errorCode + '';
                }
            }
            customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = stringData;
        }
        if (eventData) {
            customDimensions[environment.GA_DIMENSIONS.DATA] = eventData;
        }
        this.trackEvent('application', eventAction, 'dl-process', 1, customDimensions);
        this.gaTagTrack('event', eventAction, {
          'data': eventData,
          'error_data': errorCode,
          'vendor_name': vendorName,
          'google_experience': 'No Experiment Active',
          'google_variant': 'n/a',
          'label': 'dl-process',
          'clk_client_id': this.GA4ClientId,
          'application_id': this.applicationId,
          'source': this.connectionService.getApplicationSource()
        });

        let postData: any = {
            action: eventAction,
            category: "application",
            data: eventData,
            label: 'dl-process',
            value: 1,
            referralPage: vendorName,
            googleVariant: "n/a",
            googleExperience: 'No Experiment Active',
            leadId: this.leadId ? this.leadId : undefined,
            sessionId: this.sessionId,
            applicationId: this.applicationId,
            browserId: this.browserId,
            experienceId: this.experimentId ? this.experimentId : this.clientId
        };
        //this.connectionService.postV3Request(`${environment.gatewayV3}googleanalytics-service/api/analytics-dataNL`, postData, 'application/json', null).subscribe(data => { });
    }

    public setV3Label(labelName: string) {
        this.v3Label = labelName;
    }

    get getLabel() {
        return this.v3Label;
    }

    public trackTiming(
        timingCategory: any,
        timingVar: any,
        timingLabel: any,
        timingValue: any,
        customDimensions?: any
    ) {
        if (customDimensions) {
            this.gaTrack('set', customDimensions);
        }

        this.gaTrack('send', 'timing', {
            'timingCategory': timingCategory,
            'timingVar': timingVar,
            'timingValue': timingValue,
            'timingLabel': timingLabel
        });

        if (customDimensions) {
            Object.keys(customDimensions).forEach(k => customDimensions[k] = null);
            this.gaTrack('set', customDimensions);
        }
    }

    public trackErrorEvent(eventCategory: any, eventAction: any, eventLabel: any, eventValue: any, errorCode: any, method: any, data: any) {

        let customDimensions: any = {
            'dimension2': errorCode,
            'dimension3': method,
            'dimension4': data
        };
        customDimensions[environment.GA_DIMENSIONS.CLIENT_ID] = this.experimentId;
        customDimensions[environment.GA_DIMENSIONS.APPLICATION_ID] = this.applicationId;
        customDimensions[environment.GA_DIMENSIONS.SOURCE] = this.connectionService.getApplicationSource();
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_EXPERIENCE] = environment.GoogleExperience;
        this.gaTrack('set', customDimensions);

        this.gaTrack('send', 'event',
            {
                'eventCategory': eventCategory,
                'eventAction': eventAction,
                'eventLabel': eventLabel,
                'eventValue': eventValue != null && eventValue != undefined ? eventValue : 0
            });

            this.gaTagTrack('event', eventAction, {
              'data': data,
              'application_id': this.applicationId,
              'lead_id': this.leadId,
              'session_id': this.sessionId,
              'clk_client_id': this.GA4ClientId,
              'error_data': errorCode,
              'google_experience': environment.GoogleExperience,
              'source': this.connectionService.getApplicationSource()
            });

        customDimensions = {
            'dimension2': null,
            'dimension3': null,
            'dimension4': null
        };
        customDimensions[environment.GA_DIMENSIONS.CLIENT_ID] = null;
        customDimensions[environment.GA_DIMENSIONS.APPLICATION_ID] = null;
        customDimensions[environment.GA_DIMENSIONS.SOURCE] = null;
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_EXPERIENCE] = null;
        this.gaTrack('set', customDimensions);
    }

    public trackEdgarApplicationEvent(eventAction: string, vendorName: string, eventData?: string, errorCode?: any) {
        this.v3Label = 'edgar-application';
        this.trackApplicationEvent('edgar-application', 'EdgarApplication', eventAction, vendorName, eventData, errorCode);
    }

    public trackRoseVerificationEvent(eventAction: string, vendorName: string, eventData?: string, errorCode?: any) {
        this.v3Label = 'rose-verification';
        this.trackApplicationEvent('rose-verification', 'RoseVerification', eventAction, vendorName, eventData, errorCode);
    }

    public trackApplicationEvent(
        label: string,
        variant: string,
        eventAction: string,
        vendorName: string,
        eventData?: string,
        errorCode?: any
    ) {
        let customDimensions = {};
        customDimensions[environment.GA_DIMENSIONS.REFERRAL_PAGE] = vendorName;
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_EXPERIENCE] = 'No Experiment Active';
        customDimensions[environment.GA_DIMENSIONS.GOOGLE_VARIANT] = variant;
        customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.DATA] = 'n/a';
        customDimensions[environment.GA_DIMENSIONS.CLIENT_ID] = this.experimentId;
        customDimensions[environment.GA_DIMENSIONS.APPLICATION_ID] = this.applicationId;
        customDimensions[environment.GA_DIMENSIONS.SOURCE] = this.connectionService.getApplicationSource();
        if (errorCode) {
            let stringData;
            if (typeof errorCode === 'string' || errorCode instanceof String) {
                stringData = errorCode;
            } else {
                try {
                    stringData = JSON.stringify(errorCode);
                } catch (error) {
                    stringData = errorCode + '';
                }
            }
            customDimensions[environment.GA_DIMENSIONS.ERROR_CODE] = stringData;
        }
        if (eventData) {
            customDimensions[environment.GA_DIMENSIONS.DATA] = eventData;
        }
        this.trackEvent('application', eventAction, this.v3Label, 1, customDimensions);
        this.gaTagTrack('event', eventAction, {
          'data': eventData,
          'error_data': errorCode,
          'vendor_name': vendorName,
          'google_experience': 'No Experiment Active',
          'google_variant': variant,
          'label': this.v3Label,
          'lead_id': this.leadId,
          'session_id': this.sessionId,
          'clk_client_id': this.GA4ClientId,
          'application_id': this.applicationId,
          'source': this.connectionService.getApplicationSource()
        });
        let postData: any = {
            action: eventAction,
            category: "application",
            data: eventData,
            label: label,
            value: 1,
            referralPage: vendorName,
            googleVariant: variant,
            googleExperience: 'No Experiment Active',
            leadId: this.leadId ? this.leadId : undefined,
            sessionId: this.sessionId,
            applicationId: this.applicationId,
            browserId: this.browserId,
            experienceId: this.experimentId ? this.experimentId : this.clientId
        };
        //this.connectionService.postV3Request(`${environment.gatewayV3}googleanalytics-service/api/analytics-dataNL`, postData, 'application/json', null).subscribe(data => { });
    }
}
