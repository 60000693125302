<app-header></app-header>
<div class="containerD">
    <div class="mx-auto frame">
        <div class="boxDec">
            <div>
                <img class="imgDec" src="/assets/images/Declined.svg" alt="declined" />
            </div>
            <h5 class="tittleDeclined font-mont-regular">DECLINED</h5>
            <div class="decline-desciption">
                <div class="font-mont-book">We're sorry. We were unable to approve your {{vendorUser ? ' client': ''}}</div>
                <div class="font-mont-book">application, based on the information provided.</div>
                <div class="font-mont-book">Contact vendor services, at the number</div>
                <div class="font-mont-book">below to discuss possible next steps.</div>
            </div>
            <a href="home" *ngIf="vendorUser">
                <div class="rounded-pill btn btn-primary btn-lg disabled bth font-mont-regular"
                    (click)="onBackClick();">
                    Back to Portal
                </div>
            </a>
            <div class="information">
                <span class="font-mont-regular">Need Help? Have Questions?</span>
            </div>
            <div class="phone">
                <div class="font-mont-book">Call <span class="font-mont-book">1-888-509-5592</span></div>
            </div>
        </div>
    </div>
</div>