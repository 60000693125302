import { Component, OnInit, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';

@Component({
    selector: 'app-documents-table',
    templateUrl: './documents-table.component.html',
    styleUrls: ['./documents-table.component.css']
})
export class DocumentsTableComponent implements OnInit {

    @Input() documents: any[] = [];

    constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

    ngOnInit() {
        this.googleAnalyticsService.trackEvent("app-component", "app-documents-table");
    }

    onRemoveDocument(document: any) {
        var index = this.documents.indexOf(document);
        if (index > -1) {
            this.documents.splice(index, 1);
        }
    }
}
