import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-ept-status-bar',
    templateUrl: './ept-status-bar.component.html',
    styleUrls: ['./ept-status-bar.component.css']
})
export class EptStatusBarComponent implements OnInit {

    @Input() processStatus: any = {};

    constructor() {
    }

    ngOnInit(): void { }

    itemStyle(item: any) {
        return item.style ? item.style : 'SUCCESS';
    }
}
