import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dl-success-submition',
  templateUrl: './dl-success-submition.component.html',
  styleUrls: ['./dl-success-submition.component.css']
})
export class DlSuccessSubmitionComponent implements OnInit {
  token: string;
  showModal: boolean = false;
  partnerName: string;
  docFee: string;
  accountNumber: string;
  applicationId: number;

  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      this.partnerName = navigation.extras.state.partnerName;
      this.docFee = navigation.extras.state.docFee;
      this.accountNumber = navigation.extras.state.accountNumber;
      this.applicationId = navigation.extras.state.applicationId;
    } else {
      this.googleAnalyticsService.dlTrackEvent('dl-submition-error', this.authService.vendorReferrerName, this.token, "success with missing params");
      this.router.navigate(['../upload-license-error'], { queryParamsHandling: "merge" });
    }
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    this.googleAnalyticsService.setApplicationId(this.applicationId);
    this.googleAnalyticsService.dlTrackEvent('dl-submition-sucess', this.authService.vendorReferrerName, this.token)

  }

}
