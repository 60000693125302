import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services';
import { DataResponse, DataArray } from 'src/app/shared/types';

/**
 * @class StatesService
 * Service in charge of storing the states.
 */
@Injectable({
    providedIn: 'root'
})
export class StatesService {
    /**
     * @private
     * List of loaded states within the {@link StatesService service}.
     * @default null
     * @type {DataArray}
     */
    private states: DataArray = null;

    constructor(private connectionService: ConnectionService) {
        this.loadStates();
    }

    /**
     * @description List of loaded states
     */
    get stateList() {
        return this.states;
    }

    /**
     * @async
     * @description Loads the states inside the {@link StatesService service}.
     */
    async loadStates(): Promise<void> {
        const { object } = await this.connectionService.getRequestUnsecuredP<DataResponse>({
            url: `${environment.gateway}utils/states`,
            contentType: 'application/json'
        });

        this.states = object;
    }
}