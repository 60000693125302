import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
declare var $: any;

@Component({
    selector: 'app-upload-document',
    templateUrl: './upload-document.component.html',
    styleUrls: ['./upload-document.component.css']
})
export class UploadDocumentComponent implements OnInit, AfterViewInit {

    public isDragOver = false;

    @Input() elementId = 'elementidtestweird';
    @Input() lineA = 'Drag your file here or click to';
    @Input() lineB = 'upload from your computer.';
    @Input() uploaded = false;
    @Input() customClass = '';
    @Output() onFileAdded = new EventEmitter<any>();
    @Output() onClick = new EventEmitter<any>();

    constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

    ngOnInit() {
        this.googleAnalyticsService.trackEvent("app-component", "app-upload-document");
    }

    ngAfterViewInit() {
        var formatBytes = function (a: any, b: any) { if (0 == a) return "0 Bytes"; var c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]; };

        var droppedFiles = false;
        var onFileAdded = this.onFileAdded;
        $('#' + this.elementId).on('drag dragstart dragend dragover dragenter dragleave drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
        }).on('dragover dragenter', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = true;
        }.bind(this)).on('dragleave dragend drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = false;
        }.bind(this)).on('drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            droppedFiles = e.originalEvent.dataTransfer.files;

            var getBase64 = function (file: any) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    var fileReaded = {
                        name: file.name,
                        size: formatBytes(file.size, 0),
                        rawSize: file.size,
                        type: file.type,
                        data: reader.result,
                        remove: 'remove'
                    };

                    onFileAdded.emit({ file: fileReaded });
                };
                reader.onerror = function (error) {
                };
            };

            $.each(droppedFiles, function (i: any, file: any) {
                getBase64(file);
            });
        });

        if (this.onClick.observers.length) {
            $('#' + this.elementId).on('click', function (e: any) {
                e.preventDefault();
                e.stopPropagation();
                this.onClick.emit(e);
            }.bind(this));
        }
    }

    fileChangeEvent(inputFile: any) {
        var formatBytes = function (a: any, b: any) { if (0 == a) return "0 Bytes"; var c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]; };

        var droppedFiles = inputFile.target.files;
        var onFileAdded = this.onFileAdded;

        var getBase64 = function (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                var fileReaded = {
                    name: file.name,
                    size: formatBytes(file.size, 0),
                    rawSize: file.size,
                    type: file.type,
                    data: reader.result,
                    remove: 'remove'
                };

                onFileAdded.emit({ file: fileReaded });
            };
            reader.onerror = function (error) {
            };
        };

        $.each(droppedFiles, function (i: any, file: any) {
            getBase64(file);
        });

        $(inputFile.target).val('');
    }

}
