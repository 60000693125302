<div class="bg-secondary loading-screen-wrapper">
    <div class="login-form-container">
        <div class="login-form">
            <div class="form-header">
                <h3 class="font-mont-bold">LOGIN</h3>
                <img src="/assets/images/icons/login_user.svg" />
                <div class="clear"></div>
            </div>
            <form>
                <div class="form-group">
                    <label class="font-mont-book" for="expiredEmail">Email</label>
                    <input type="email" id="expiredEmail" name="email" ngModel class="form-control" #emailInput>
                </div>
                <div class="form-group">
                    <label class="font-mont-book" for="expiredPassword">Password</label>
                    <input type="password" id="expiredPassword" name="password" ngModel class="form-control" #passwordInput>
                </div>
                <div class="bottom-buttons">
                    <button class="btn btn-primary float-right" type="submit" (click)="onSignin()">NEXT</button>
                </div>
            </form>
            <div class="footer-links">
            </div>
        </div>
    </div>
</div>