import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dl-error-upload-failed',
  templateUrl: './dl-error-upload-failed.component.html',
  styleUrls: ['./dl-error-upload-failed.component.css']
})
export class DlErrorUploadFailedComponent implements OnInit {
  public token: any;

  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams.token;
  }
  
  backToUpload() {
    this.googleAnalyticsService.dlTrackEvent('dl-try-again', this.authService.vendorReferrerName, this.token);
    this.router.navigate(['../upload-license'], { queryParamsHandling: "merge" });
  }
}
