import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/analytics/google.analytics.service";
import { ClickSingService } from '../click-sing.service';
import { ErrorListService } from 'src/app/shared/services';

@Component({
  selector: 'app-try-again',
  templateUrl: './try-again.component.html',
  styleUrls: ['./try-again.component.css']
})
export class TryAgainComponent implements OnInit {
  token: any;
  accesToken: string;
  applicationId: number;
  signURL: string;
  private clickProcess: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private clickSignService: ClickSingService,
    private errorService: ErrorListService
  ) { 
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      this.accesToken = navigation.extras.state.accessToken;
      this.applicationId = navigation.extras.state.appId;
      this.token = navigation.extras.state.token;
    } else this.errorService.redirectToV3Error(null, 'try-again-without-params', 'click-sign-otp');
  }

  ngOnInit(): void {
    this.googleAnalyticsService.trackClickSignEvent('try-again-shown', this.clickSignService.vendorReferrerName);
  }

  tryAgain() {
    if(this.clickProcess) {
      return;
    }
    this.googleAnalyticsService.trackClickSignEvent('try-again-continue', this.clickSignService.vendorReferrerName);
    this.clickProcess = true;
    this.clickSignService.retryDocusignKba(this.applicationId, this.accesToken, this.token).then((response) => {
      this.googleAnalyticsService.trackClickSignEvent('try-again-response', this.clickSignService.vendorReferrerName, JSON.stringify(response));
      if (response && response.code === "412") {
        this.router.navigate(["../offers-selection"], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge",
          state: {
            accessToken: this.accesToken,
            token: this.token,
            appId: this.applicationId,
            offersChanged: true,
          },
        });
      } else if (response && response.object) {        
        this.router.navigate(['../docusign-contract'], { relativeTo: this.activatedRoute, queryParamsHandling: "merge", state: { docusignUrl: response.object.url, emitApplicationId: this.applicationId, accesToken: this.accesToken, token: this.token } });
      } else this.errorService.redirectToV3Error(response, 'signDocusign', 'click-sign-otp');  
    }).catch((error) => {
      if (error && error.error && error.error.code === "412") {
        this.router.navigate(["../declined"], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: "merge",
        });
      } else this.errorService.redirectToV3Error(error, 'signDocusign', 'click-sign-otp');
    });
  }
}
