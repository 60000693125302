import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateValidator, EmailValidator } from 'src/app/utils/shared';

export interface ISoleForm {
    businessType: string;
    email: string;
    DBA: string;
    businessStartDate: Date;
    termsService: boolean;
    businesAddressDifferent?: boolean;
    businessAddress?: string,
    zipCode?: string;
    city?: string;
    state?: string;
    county?: string;
}

export class SoleFormItem {
    form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
            businessType: ['SoleProprietorship', Validators.required],
            email: ['', [Validators.required, Validators.email, EmailValidator()]],
            DBA: ['', Validators.required],
            businessStartDate: ['', [Validators.required, DateValidator()]],
            termsService: [false, [Validators.requiredTrue]],
            businesAddressDifferent: [false, [Validators.required]],
            businessAddress: [''],
            zipCode: [''],
            city: [''],
            state: [''],
            county: [''],
        });
        this.form.controls.businesAddressDifferent.setValue(false, {emitEvent: false});
    }

    decode(data: ISoleForm) {
        this.form.controls.businessType.setValue(data.businessType);
        this.form.controls.email.setValue(data.email);
        this.form.controls.DBA.setValue(data.DBA);
        this.form.controls.businessStartDate.setValue(data.businessStartDate);
        this.form.controls.businesAddressDifferent.setValue(data.businesAddressDifferent, {emitEvent: false});
        this.form.controls.businessAddress.setValue(data.businessAddress);
        this.form.controls.zipCode.setValue(data.zipCode);
        this.form.controls.city.setValue(data.city);
        this.form.controls.state.setValue(data.state);
        this.form.controls.county.setValue(data.county);
        this.form.controls.termsService.setValue(data.termsService);
    }

    enconde(): ISoleForm {
        return {
            businessType: this.form.controls.businessType.value,
            email: this.form.controls.email.value,
            DBA: this.form.controls.DBA.value,
            businessStartDate: this.form.controls.businessStartDate.value,
            businesAddressDifferent: this.form.controls.businesAddressDifferent.value,
            termsService: this.form.controls.termsService.value,
            businessAddress: this.form.controls.businessAddress.value,
            zipCode: this.form.controls.zipCode.value,
            city: this.form.controls.city.value,
            state: this.form.controls.state.value,
            county: this.form.controls.county.value
        }
    }

    setDefaultData(differentBusiness) {
        if(!differentBusiness) {
            this.decode(
                {
                    businessType: 'SoleProprietorship',
                    email: 'me@mail.com',
                    DBA: 'My DBA Name',
                    businessStartDate: new Date(2000, 10, 10),
                    businesAddressDifferent: differentBusiness,
                    termsService: true
                }
            );
        } else {
            this.decode(
                {
                    businessType: 'SoleProprietorship',
                    email: 'me@mail.com',
                    DBA: 'My DBA Name',
                    businessStartDate: new Date(2000, 10, 10),
                    businesAddressDifferent: differentBusiness,
                    termsService: true,
                    businessAddress: '1182 Main St',
                    zipCode: '14013',
                    city: 'Basom'.toUpperCase(),
                    state: 'NY',
                    county: ''
                }
            );
        }

    }

}
