import { Component, OnInit, ViewChild, EventEmitter, Output, ElementRef, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Papa } from 'ngx-papaparse';
declare var $: any;

@Component({
  selector: 'app-leasable-import',
  templateUrl: './leasable-import.component.html',
  styleUrls: ['./leasable-import.component.css'],
})

export class LeasableImportComponent implements OnInit {

  @ViewChild('csvReader') csvReader: any;
  @ViewChild('importProductsModal') public importProductsModal: ModalDirective;
  @ViewChild('fileImportInput') fileImportInput: any;
  @ViewChild("csvFile", { read: ElementRef }) csvFile: ElementRef;

  @Output() toTable = new EventEmitter<any>();

  public headersProducts: any = [];
  public products: any = [];
  public documents: any[] = [];
  public records: any[] = [];
  public appId: any;
  public applicationsSearched: boolean = false;
  public modalClass: boolean = false;
  public vendorID = '';
  public cleanFilter: any;


  file: any;
  fileUpload: boolean = false;
  header: boolean = false;
  data: any;
  fieldDelimiter: string;
  fieldEnclosure: string;


  constructor(public router: Router, private papa: Papa) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      this.appId = navigation.extras.state.appId;
    }
  }

  ngOnInit(): void {
  }

  openSearchModal1() {
    this.applicationsSearched = false;
    this.clear();
    $('#importProductsModal').modal({ backdrop: 'static', keyboard: false });
  }

  openSearchModal2() {
    $('#mappingProductsModal').modal({ backdrop: 'static', keyboard: false });
    $("#importProductsModal").modal("hide");    
  }

  onCloseSearchModal() {
    this.clear();
    $("#importProductsModal").modal("hide");
  }

  setData(newData: any) {
    let newDataArray = eval(newData);
    this.products = [...[newDataArray][0]];
    
  }

  /*checked() {
    this.isChecked = true;
  }*/

  fileChangeListener(importForm: NgForm) {

    let reader: FileReader = new FileReader();
    reader.readAsText(this.file[0]);
    reader.onload = (e) => {
      let csv: any = reader.result;

      // get headers to mapping
      let allTextLines = csv.split(/\r\n/);
      if (allTextLines.length <= 1) {
        allTextLines = csv.split(/\n/);
      }
      let headers = allTextLines[0].split(/;|,|\t/);

      // get items information
      allTextLines = this.papa.parse(csv, {
        complete: (result) => {
          return result;
        }
      });

      // delete first and last array without information
      allTextLines.data.shift();
      //allTextLines.data.pop();

      // mapping headers with items information
      const arr = allTextLines.data.map(function (row) {
        const el = headers.reduce(function (object, header, index) {
          object[header] = row[index];
          return object;
        }, {});
        return el;
      });
      this.addHeaders(headers, arr);
    }

  }

  addHeaders(header: any, arr: any) {
    if (this.products.length != 0 && this.headersProducts.length != 0) {
      this.products = [];
      this.headersProducts = [];
    }
    header.forEach((currentValue, index) => {
      this.headersProducts.push(currentValue);
    });

    arr.forEach((currentValue, index) => {   
      this.products.push(currentValue);      
    });
    
  }

  onFileAdded(file: any) {
    this.documents = [];
    this.documents.push(file);
    
    let arr = [];
    arr.push(file.file);
    this.file = arr;
    
  }

  onSelect(event) {
    this.file = event.addedFiles;
  }

  onRemove(event) {
    this.file.splice(this.file.indexOf(event), 1);
    this.file = null;
  }

  clear() {
    this.appId = null;
    this.applicationsSearched = false;
    this.file = null;
    this.fileUpload = false;
    this.header = false;
    this.products = [];
    //this.fieldDelimiter = '';
    //this.fieldEnclosure = '';
    this.documents = [];
    //this.isChecked = false;

    $("#importProductsModal input[type='radio']").prop('checked', false).change();
  }

  selectVendor(vendor: string) {
    this.vendorID = vendor;
  }

  /********Clean filter***********/
  clean() {
    this.cleanFilter = {};

    let filterName = document.querySelectorAll('.modalFilter');
    let vendorList = document.querySelectorAll('.vendorList');
    let filter = document.querySelectorAll('.searchRentagle');

    filterName[0]['value'] = "";

    filter.forEach(element => {
      element.remove();
    });

    vendorList.forEach(element => {
      element.remove();
    });

  }

  getVendorTest(data) {
    this.toTable.emit(data);
  }
}
