

export function formatPersonData({
  id,
  phone,
  birthDate,
  lastName,
  firstName,
  ssn,
  zipcode,
  street,
  authorization
}) {
  return {
    leadId: id,
    accessToken: authorization,
    individual: {
      phoneNumber: phone,
      firstName,
      lastName,
      dob: birthDate,
      ssn,
      addresses: [
        {
          postalCode: zipcode,
          address: street
        }
      ]
    }
  };
}