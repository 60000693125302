import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

export class CustomerSupportTicketModel {


    public form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.form = this.formBuilder.group({
            emailAddress: ['', Validators.required],
            fullName: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            phoneExt: [''],
            helpTopic: ['', Validators.required],
            issueSummary: ['', Validators.required],
            issueDetails: ['']
        });
    }

    decode(data: any) {
        this.form.controls.emailAddress.setValue('');
        this.form.controls.fullName.setValue('');
        this.form.controls.phoneNumber.setValue('');
        this.form.controls.phoneExt.setValue('');
        this.form.controls.helpTopic.setValue('');
        this.form.controls.issueSummary.setValue('');
        this.form.controls.issueDetails.setValue('');
    }

    encode(documents: any): any {
        return {
            'email': this.form.controls.emailAddress.value,
            'name': this.form.controls.fullName.value,
            'phone': this.form.controls.phoneNumber.value + this.form.controls.phoneExt.value ? 'X' + this.form.controls.phoneExt.value : '',
            'topicId': this.form.controls.helpTopic.value,
            'subject': this.form.controls.issueSummary.value,
            'message': 'data:text/html,' + this.form.controls.issueDetails.value,
            'alert': true,
            'attachments': this.encodeAttachments(documents),
            'autorespond': true,
            'ip': '10.76.20.10',
            'source': 'API'
        }
    }

    encodeAttachments(documents: any): any {
        let attachments = [];
        documents.forEach((document: any) => {
            let content = {};
            content[document.file.name] = document.file.data;
            attachments.push(content);
        });
        return attachments;
    }

    setDefaultData() {
        this.form.controls.emailAddress.setValue('me@mail.com');
        this.form.controls.fullName.setValue('Jane Doe');
        this.form.controls.phoneNumber.setValue('3219870000');
        this.form.controls.phoneExt.setValue('');
        this.form.controls.helpTopic.setValue(2);
        this.form.controls.issueSummary.setValue('Test Issue');
        this.form.controls.issueDetails.setValue('');
    }

}