import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import {
    StartedFormService
} from 'src/app/application/v3/services';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-otp-input',
    templateUrl: './otp-input.component.html',
    styleUrls: ['./otp-input.component.css', '../../application/v3/application.component.css']
})
export class OTPInputComponent implements OnInit {
    @Output() OTPData = new EventEmitter<string>();
    @Output() OTPReSend = new EventEmitter<any>();
    @ViewChildren('formRow') rows: any;
    @Input() invalidCode: boolean;
    @Input() usaPhoneNumber: string;
    @Input() leadId: string;
    @Input() otpRose: boolean;
    @Input() started: boolean;
    @Input() useService: any;
    @Output() invalidCodeChange = new EventEmitter<boolean>();
    private clickProccess = false;
    resendView = false;
    codeResended = false;
    appId: string = null;
    token: string = null;
    form: UntypedFormGroup;
    formInput = ['input1', 'input2', 'input3', 'input4'];

    constructor(
        private activatedRoute: ActivatedRoute,
        private errorService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService,
        private startedFormService: StartedFormService
    ) {
        this.form = this.toFormGroup(this.formInput);
    }

    get phoneClicked() {
        return this.otpRose ? this.usaPhoneNumber : this.startedForm.controls.mobilePhoneNumber.value + '';
    }

    get startedForm() {
        return this.startedFormService.form;
    }

    private activateProccess() {
        this.clickProccess = true;
        this.codeResended = true;
    }

    private resetProccess() {
        this.resendView = false;
        this.clickProccess = false;
    }

    ngOnInit(): void {
        this.appId = this.activatedRoute.snapshot.queryParams.appId;
        this.token = this.activatedRoute.snapshot.queryParams.token;

        if (this.activatedRoute.snapshot.queryParams.phoneNumber && !this.usaPhoneNumber) {
            this.usaPhoneNumber = this.activatedRoute.snapshot.queryParams.phoneNumber.replace(/X/g, '');
        }
    }

    toFormGroup(elements) {
        const group: any = {};

        elements.forEach((key: any) => {
            group[key] = new FormControl('', Validators.required);
        });

        return new UntypedFormGroup(group);
    }

    keyUpEvent(event, index) {
        let pos = index;
        if (event.data && !isNaN(event.data)) {
            pos++;
        }
        if (pos > -1 && pos < this.formInput.length) {
            if (this.rows._results[pos] && this.rows._results[pos].nativeElement) {
                this.rows._results[pos].nativeElement.focus();
            }
        }
    }

    onSubmit() {
        let otp = '';
        Object.keys(this.form.controls).forEach(key => {
            otp = otp + this.form.controls[key].value
        });
        this.OTPData.emit(otp);
    }

    onPaste(event) {
        const data = event.clipboardData.getData('text').split('');

        Array.from(data, (value: string, index) => {
            this.form.controls[`input${index + 1}`].setValue(value);
        });
    }

    clearCode() {
        Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].setValue('');
        });
    }

    onResend() {
        this.invalidCodeChange.emit(false);
        this.clearCode();
        if (this.usaPhoneNumber && !this.leadId) {
            this.googleAnalyticsService.trackClickSignEvent(
                'not-get-code-clicked',
                this.authService.vendorReferrerName,
                ''
            );

            this.googleAnalyticsService.trackClickSignEvent(
                'right-phone-shown',
                this.authService.vendorReferrerName,
                ''
            );
        } else {
            this.googleAnalyticsService.trackV3Event(
                'not-get-code-clicked',
                this.authService.vendorReferrerName,
                ''
            );

            this.googleAnalyticsService.trackV3Event(
                'right-phone-shown',
                this.authService.vendorReferrerName,
                ''
            );
        }
        this.resendView = true;
    }

    sendNewCode() {
        if (this.clickProccess) return;

        if (!this.usaPhoneNumber && this.startedFormService.getControlInvalid('mobilePhoneNumber')) {
            this.googleAnalyticsService.trackV3Event(
                'send-code-error',
                this.authService.vendorReferrerName,
                'phone-error'
            );
            return;
        }

        this.activateProccess();

        if (this.usaPhoneNumber && !this.otpRose) {
            if (this.leadId) {
                this.useService.sendOtpWithLeadId(this.leadId)
                    .then(res => this.resetProccess())
                    .catch(err => this.errorService.redirectToV3Error(
                        err,
                        'resentOtp-code',
                        'finicity-otp'
                    ));
            } else this.useService.sendAgreement(this.appId, this.token)
                .then(res => this.resetProccess())
                .catch(err => this.errorService.redirectToV3Error(
                    err,
                    'sentOtp-code',
                    'click-sign-otp'
                ));
        } else this.useService.sendPhoneClicked(this.phoneClicked)
            .then(res => this.resetProccess())
            .catch(err => this.errorService.validateRedirectError(err, 'sentOtp-code', 'started'));
    }
}
