<app-application-wrapper [subTitleA]="isClickSign ? 'LEASE AGREEMENT' : 'BANK VERIFICATION'"
  [subTitleB]="isClickSign ? 'STEP 2 OF 4' : ''" [style]="!isClickSign && !currentFlow ? 1 : 0" [stepIndex]="3"
  [steps]="steps">
  <ng-template appApplicationWrapperHelper>
    <div class="content-container">
      <h1 class="title font-mont-bold">Connect a bank account</h1>
      <h1 class="title-mobile font-mont-bold">Bank Account</h1>
      <p class="font-mont-regular" *ngIf="isClickSign">Your account could not be verified using the information
        provided.
        Please try again using the verified account connection tool below.</p>
      <p class="font-mont-regular" *ngIf="!isClickSign">This is the account we will take payments from once you start your lease.</p>
      <button *ngIf="!production" class="test-button test-buttonV1" 
        (click)="fakeFinicitySuccess()">Fake
        Finicity Success</button>
      <div class="finicityIframe" id="finicityIframe" [hidden]="loading"></div>
      <div class="finicity-loading loading-width" [hidden]="!loading">
        <div class="loading-animation">
          <img src="/assets/images/icons/application-finicity/logo.svg" />
          <span></span>
          <span></span>
          <img src="/assets/images/icons/application-finicity/bank.svg" />
        </div>
        <div class="font-mont-bold">Your account information is being verified.</div>
        <div class="font-mont-semi-bold">Thank you for submitting your bank information. We are verifying your account.
          This
          usually takes less than a minute. We will notify you of your status on this screen.</div>
      </div>
      <div class="font-mont-regular reminder-email" *ngIf="!isClickSign && !loading">
        <span class="font-mont-regular">Don’t have time right now? <a class="font-mont-regular verify-link" (click)="sendFinicityVerifyBankAccountEmail()">Get an email</a> to verify later.</span>
      </div>
    </div>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="/assets/images/icons/bank-account.svg" alt="">
  </ng-container>
</app-application-wrapper>

<app-pop-up-notify *ngIf="showNotify" [title]="title" [textA]="textA" [sent]="sent" [textB]="textB" [phone]="phone"
  (closeNotify)="onCloseNotify($event)"></app-pop-up-notify>
