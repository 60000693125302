import { Component, OnInit, OnDestroy, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from 'src/app/shared/services/loading-screen.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements AfterViewInit, OnDestroy {

    loadingSubscription: Subscription;
    finicityLoadingSubscription: Subscription;
    debounceTime = 200;

    showLoading: boolean = false;
    showFinicityLoading: boolean = false;

    finicityPercentage: number = 0;

    animation = Math.floor(Math.random() * 3);

    get finicityPercentageFloor() {
        return Math.floor(this.finicityPercentage);
    }

    constructor(private loadingScreenService: LoadingScreenService,
        private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.showLoading = false;
        this.showFinicityLoading = false;
        this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(debounceTime(this.debounceTime)).subscribe(
            (status: boolean) => {
                document.getElementsByTagName('body')[0].style.overflow = status ? 'hidden' : 'initial';
                this.showLoading = status;
                this._changeDetectorRef.detectChanges();
            }
        );

        this.finicityLoadingSubscription = this.loadingScreenService.finicityLoadingStatus.pipe(debounceTime(this.debounceTime)).subscribe(
            (status: boolean) => {
                document.getElementsByTagName('body')[0].style.overflow = status ? 'hidden' : 'initial';
                this.showFinicityLoading = status;
                this._changeDetectorRef.detectChanges();
            }
        );
    }

    ngOnDestroy(): void {
        this.loadingSubscription.unsubscribe();
        this.finicityLoadingSubscription.unsubscribe();
    }

}
