import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { ArrayFilterPipe } from 'src/app/shared/pipes/array.filter.pipe';
import { ArraySortPipe } from 'src/app/shared/pipes/array.sort.pipe';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-vendor-list',
    templateUrl: './vendor-list.component.html',
    styleUrls: ['./vendor-list.component.css']
})
export class VendorListComponent implements OnInit {

    public newVendorForm: UntypedFormGroup;
    public selectedRow: number = -1;
    public tempSelectedRow: number = -1;
    public addNewVendorSubmitted = false;

    public modalId = 'ErrorsModal';
    public messageTitle = '';
    public messageMessageA = '';
    public messageMessageB = '';
    public messageMessageC = '';
    public messageMessageBClass = '';
    public dialogClass = '';

    public filter: string = '';
    public filterValue: string = '';
    public sortField: string = '';
    public invertedSort: boolean = false;

    public vendorId = '';
    public vendors: any[] = [];
    public statesOfIncorporation: any[] = [];
    public industries: any[] = [];
    public equipmentClassifications: any[] = [];
    public vendorTypes: any[] = [];
    public businessTypes: any[] = [];
    public cities: any[] = [];

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private arrayFilterPipe: ArrayFilterPipe,
        private arraySortPipe: ArraySortPipe,
        private authService: AuthService
    ) {
        this.newVendorForm = this.formBuilder.group({
            dbaName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', Validators.required],
            equipmentClassification: ['', Validators.required],
            accountName: ['', Validators.required],
            address: ['', Validators.required],
            city: ['', Validators.required],
            entityType: ['', Validators.required],
            federalTaxId: ['', Validators.required],
            industry: ['', Validators.required],
            state: ['', Validators.required],
            zipCode: ['', Validators.required],

            type: [''],
            brokerCommissionPercentage: [''],
            commissionPercentage: [''],
            defaultSalespersonId: [''],
            excludeLessee: [''],
            gradeProfileId: [''],
            ownerId: [''],
            parentExternalId: [''],
            parentId: [''],
            secondaryContactEmail: ['', [Validators.required, Validators.email]],
            secondaryContactId: [''],

            id: ['']
        });
    }

    ngOnInit() {
        //this.googleAnalyticsService.trackEvent("app-component", "app-vendor-list");
        this.getVendorList().then((vendorsData) => {
            this.getStates().then((statesData) => {
                this.getNaics().then((naicsData) => {
                    this.getStructures().then((structuresData) => {
                        this.getEquipmentClassifications().then((eClassData) => {
                            this.getVendorTypes().then((vendorTypesData) => {
                            });
                        });
                    });
                });
            });
        });
    }

    getStates() {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequestUnsecured(environment.gateway + 'utils/states', 'application/json').subscribe(
                (data: any) => {
                    this.statesOfIncorporation = data.object;
                    resolve(data);
                },
                (error: any) => {
                    resolve(error);
                }
            );
        });
        return promise;
    }

    getNaics() {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequestUnsecured(environment.gateway + 'utils/naics', 'application/json').subscribe(
                (data: any) => {
                    this.industries = data.object;
                    resolve(data);
                },
                (error: any) => {
                    resolve(error);
                }
            );
        });
        return promise;
    }

    getStructures() {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequestUnsecured(environment.gateway + 'utils/structures', 'application/json').subscribe(
                (data: any) => {
                    this.businessTypes = data.object;
                    resolve(data);
                },
                (error: any) => {
                    resolve(error);
                }
            );
        });
        return promise;
    }

    getEquipmentClassifications() {
        const promise = new Promise((resolve, reject) => {
            this.equipmentClassifications = [
                'Automotive',
                'Construction/Landscaping',
                'COVID Exception',
                'DTG/Embroidery',
                'Education',
                'Electronics',
                'Entertainment/Amusement',
                'Fitness',
                'Food Service',
                'Food Truck',
                'Healthcare/Spa',
                'Industrial',
                'Janitorial/Remediation',
                'Office/Printing',
                'Photo-A/V',
                'Rental',
                'Salon',
                'Software',
                'Trucking',
                'Vending',
                'Medical'
            ];
            resolve(this.equipmentClassifications);
        });
        return promise;
    }

    getVendorTypes() {
        const promise = new Promise((resolve, reject) => {
            this.vendorTypes = [
                'BROKER',
                'VENDOR',
                'INDYREP',
                'STRATEGICPARTNER'
            ];
            resolve(this.vendorTypes);
        });
        return promise;
    }

    onCreateNewModelSubmit() {
        this.addNewVendorSubmitted = false;
        this.newVendorForm.controls.dbaName.setValue(null);
        this.newVendorForm.controls.email.setValue(null);
        this.newVendorForm.controls.phone.setValue(null);
        this.newVendorForm.controls.equipmentClassification.setValue(null);
        this.newVendorForm.controls.accountName.setValue(null);
        this.newVendorForm.controls.address.setValue(null);
        this.newVendorForm.controls.city.setValue(null);
        this.newVendorForm.controls.entityType.setValue(null);
        this.newVendorForm.controls.federalTaxId.setValue(null);
        this.newVendorForm.controls.industry.setValue(null);
        this.newVendorForm.controls.state.setValue(null);
        this.newVendorForm.controls.zipCode.setValue(null);
        this.newVendorForm.controls.type.setValue(null);
        this.newVendorForm.controls.brokerCommissionPercentage.setValue(null);
        this.newVendorForm.controls.commissionPercentage.setValue(null);
        this.newVendorForm.controls.defaultSalespersonId.setValue(null);
        this.newVendorForm.controls.excludeLessee.setValue(null);
        this.newVendorForm.controls.gradeProfileId.setValue(null);
        this.newVendorForm.controls.ownerId.setValue(null);
        this.newVendorForm.controls.parentExternalId.setValue(null);
        this.newVendorForm.controls.parentId.setValue(null);
        this.newVendorForm.controls.secondaryContactEmail.setValue(null);
        this.newVendorForm.controls.secondaryContactId.setValue(null);
        this.newVendorForm.controls.id.setValue(null);
        $('#addNewModelModal').modal();
    }

    onCreateSubmit() {
        this.addNewVendorSubmitted = true;
        if (this.newVendorForm.invalid) {
            return;
        }

        let vendor = {
            dbaName: this.newVendorForm.controls.dbaName.value,
            email: this.newVendorForm.controls.email.value,
            phone: this.newVendorForm.controls.phone.value,
            equipmentClassification: this.newVendorForm.controls.equipmentClassification.value,
            accountName: this.newVendorForm.controls.accountName.value,
            address: this.newVendorForm.controls.address.value,
            city: this.newVendorForm.controls.city.value,
            entityType: this.newVendorForm.controls.entityType.value,
            federalTaxId: this.newVendorForm.controls.federalTaxId.value,
            industry: this.newVendorForm.controls.industry.value,
            state: this.newVendorForm.controls.state.value,
            zipCode: this.newVendorForm.controls.zipCode.value,
            type: this.newVendorForm.controls.type.value,
            brokerCommissionPercentage: this.newVendorForm.controls.brokerCommissionPercentage.value,
            commissionPercentage: this.newVendorForm.controls.commissionPercentage.value,
            defaultSalespersonId: this.newVendorForm.controls.defaultSalespersonId.value,
            excludeLessee: this.newVendorForm.controls.excludeLessee.value,
            gradeProfileId: this.newVendorForm.controls.gradeProfileId.value,
            ownerId: this.newVendorForm.controls.ownerId.value,
            parentExternalId: this.newVendorForm.controls.parentExternalId.value,
            parentId: this.authService.getVendorId(),
            secondaryContactEmail: this.newVendorForm.controls.secondaryContactEmail.value,
            secondaryContactId: this.newVendorForm.controls.secondaryContactId.value,
            id: this.newVendorForm.controls.id.value
        };

        const promise = new Promise((resolve, reject) => {
            this.connectionService.postRequest(environment.gateway + 'vendor', vendor, 'application/json').subscribe(
                (data: any) => {
                    $('#addNewModelModal').modal('hide');
                    this.showSuccessMessage('Vendor ' + data.object.dbaName + ' created!');
                    this.vendorId = data.object.id;
                    this.getVendorList(this.vendorId);
                    resolve(data);
                },
                (error: any) => {
                    $('#addNewModelModal').modal('hide');
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
        return promise;
    }

    getVendorList(vendorId?: any) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'findvendors/' + this.authService.getVendorId(), 'application/json').subscribe(
                (data: any) => {
                    this.vendors = data.object;
                    let filteredAndSortedResult = this.arrayFilterPipe.transform(this.vendors, this.filter, this.filterValue);
                    filteredAndSortedResult = this.arraySortPipe.transform(filteredAndSortedResult, this.sortField, this.invertedSort);

                    if (vendorId) {
                        for (let index = 0; index < filteredAndSortedResult.length; index++) {
                            if (filteredAndSortedResult[index].id == vendorId) {
                                this.tempSelectedRow = index;
                            }
                        }
                    }
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
        return promise;
    }

    showErrorMessage(pError: string) {
        this.messageTitle = 'Sorry, we found a problem';
        this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
        this.messageMessageB = pError;
        this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
        this.messageMessageBClass = 'mB20';
        this.dialogClass = 'w600';
        $('#errorModalDialog' + this.modalId).modal();
    }

    showSuccessMessage(pMessage: string) {
        this.messageTitle = 'Success!!';
        this.messageMessageA = pMessage;
        this.messageMessageB = '';
        this.messageMessageC = '';
        this.messageMessageBClass = '';
        this.dialogClass = 'w450';
        $('#errorModalDialog' + this.modalId).modal();
    }

    toggleSelectedRow(rowId: number, appId: number) {
        this.vendorId = String(appId);
        if (this.tempSelectedRow == rowId) {
            this.selectedRow = -1;
            this.tempSelectedRow = -1;
        } else {
            this.tempSelectedRow = rowId;
        }
    }

    onItemLoaded() {
        setTimeout(() => {
            this.selectedRow = this.tempSelectedRow;
            setTimeout(() => {
                let el = document.getElementById('itemNumber_' + this.selectedRow);
                let pos = el.getBoundingClientRect().top + window.pageYOffset - 95;
                window.scrollTo({ top: pos, behavior: 'smooth' });
            }, 250);
        }, 250);
    }

    onSortClick(field: any) {
        this.selectedRow = -1;
        this.tempSelectedRow = -1;
        if (this.sortField == field) {
            this.invertedSort = !this.invertedSort;
        } else {
            this.invertedSort = false;
            this.sortField = field;
        }
    }

    onZipCodeKeyUp() {
        const promise = new Promise((resolve, reject) => {
            this.getZipCodeData(this.newVendorForm.controls.zipCode.value).then(
                (data: any) => {
                    if (data.object) {
                        this.cities = data.object.city;
                        this.newVendorForm.controls.city.setValue(data.object.city[0]);
                        this.newVendorForm.controls.state.setValue(data.object.state);
                        resolve(data);
                    } else {
                        this.cities = [];
                        resolve(data);
                    }
                },
                (error: any) => {
                    this.cities = [];
                    resolve(error);
                }
            );
        });
        return promise;
    }

    getZipCodeData(zipCode: string) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'application/zipData/' + zipCode, 'application/json').subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }
}
