import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lastFourDigits' })
export class LastFourDigitsPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            value = value + '';
            return value.slice ? value.slice(value.length - 4) : '';
        } else return '';
    }
}
