import { MultiRiskAttibuteModelItem } from './multi-risk.attribute.model.item';

export class MultiRiskModelItem {
    active: boolean;
    description: string;
    express: boolean;
    frozenDt: number;
    id: number;
    name: string;
    outcomeId: number;
    datarobotModelId: number;
    priority: number;
    outcomeName: string;
    datarobotName: string;
    logic: string;
    logicType: string;
    attributes: MultiRiskAttibuteModelItem[];

    constructor(data: any, attributes: any, operators: any, datarobotmodels: any, outcomes: any) {
        this.active = data.active;
        this.description = data.description;
        this.express = data.express;
        this.frozenDt = data.frozenDt;
        this.id = data.id;
        this.name = data.name;
        this.outcomeId = data.outcomeId;
        this.datarobotModelId = data.datarobotModelId;
        this.priority = data.priority;
        this.logic = data.logic;
        this.logicType = data.logicType;

        datarobotmodels.forEach((element: any) => {
            if (element.id === this.datarobotModelId) {
                if (this.datarobotModelId === 0) {
                    this.datarobotName = "All Models";
                } else {
                    this.datarobotName = element.description;
                }
            }
        });

        outcomes.forEach((element: any) => {
            if (element.id === this.outcomeId) {
                this.outcomeName = element.description;
            }
        });

        this.attributes = [];
        data.attributes.forEach((element: any) => {
            this.attributes.push(new MultiRiskAttibuteModelItem(element, attributes, operators));
        });
    }
}
