<app-application-wrapper [style]="0" [footerHidden]="true">
    <ng-template appApplicationWrapperHelper>
        <div class="center-cont" style="position: relative;">
            <div class="msg">
                <h2 class="font-mont-bold">We were unable to</h2>
                <h2 class="font-mont-bold">confirm your identity</h2>
            </div>
            <div class="notification">
                <p class="font-mont-regular">What’s next?</p>
            </div>
            <div class="menssage">
                <img src="../../../../assets/images/icons/info-icon.png" alt="">
                <p class="font-mont-regular">Please check your personal information, including your SSN and security question responses, and try again.</p>

            </div>

            <div class="">
                <button type="button" class="btn btn-lg submit-btn  font-mont-bold" (click)="tryAgain()"> 
                    Try Again
                </button>
            </div>
        </div>
    </ng-template>
    <ng-container ngProjectAs="[image]">
        <img src="../../../../assets/images/edgar-decline.svg" alt="">
    </ng-container>
</app-application-wrapper>