import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { imeiOption } from './imeiOption';

declare var $: any;
const IMEI_PREFIX = '0';

@Component({
  selector: 'app-submit-invoice',
  templateUrl: './submit-invoice.component.html',
  styleUrls: ['./submit-invoice.component.css', '../ept-ui-elements.css'],
})
export class SubmitInvoiceComponent implements OnInit {
  @Input() appId: any = 0;
  @Input() nextStep: any = '';
  @Input() appGPSUnitCostRequired: boolean = false;
  @Input() appLegalName: string = '';
  @Input() appVendorId: number = 0;

  @Output() reloadSlice = new EventEmitter<void>();

  private token: string;
  private user_hash: string;
  private vendorId: number = null;
  private vendorLocations: any;
  private company: string;
  public documents: any[] = [];

  vendorList: any[] = [];
  selectedLocation: any = 'Store Location';

  public modalId = '';
  public messageTitle = '';
  public messageMessageA = '';
  public messageMessageB = '';
  public messageMessageC = '';
  public messageMessageAClass = '';
  public messageMessageBClass = '';
  public messageMessageCClass = '';
  public dialogClass = '';

  public uploadDocumentId = 'uploadDocumentId' + this.appId;

  //filter
  selectedRow: number = -1;
  tempSelectedRow: number = -1;
  sortField: string = '';
  invertedSort: boolean = false;
  filter: string = '';
  filterValue: string = '';
  search: string = '';
  searchIMEI: string = '';

  show: boolean = false;
  selectedId: any;
  countShow: boolean = false;
  lastId: any;
  idList: any[] = [
    'inputId',
    'inputImgId',
    'vendorListId'
  ];
  private imeiDeviceDetails: any;
  public errorMessage = '';

  imeiOptionList: imeiOption[] = [];
  imeiOptionSelected: imeiOption = {} as imeiOption;
  exemptionReasonsList: any[] = [];

  public invoiceShippingInfo = {
    address: '',
    city: '',
    state: '',
    zipCode: ''
  }

  public pdfModalId = 'pdfViewerSubmitInvoice' + this.appId;
  public poFileBase64 = '';
  public zoom = 1.5;


  constructor(private connectionService: ConnectionService, private authService: AuthService,) {

  }

  ngOnInit() {
    this.uploadDocumentId = 'uploadDocumentId' + this.appId;
    this.modalId = 'UploadDocumentModalId' + this.appId;
    this.pdfModalId = 'pdfViewerSubmitInvoice' + this.appId;

    this.vendorId = this.authService.getVendorId();

    this.getShippingAddress();
    this.getPDFFile();

    if (this.isGPSRequired()) {
      this.getVendorLocations();
    }
  }


  isGPSRequired() {
    return this.authService.gpsUnitRequired() && this.appGPSUnitCostRequired;
  }


  onFileAdded(file: any) {
    this.documents = [];
    this.documents.push(file);
  }

  onFileRemoved() {
    //Right now only 1 file is allowed
    this.documents.splice(0, 1);
  }

  onUploadInvoiceFile() {
    return new Promise((resolve, reject) => {
      const nachaFile = {
        documentB: this.documents[0].file.data.substr(this.documents[0].file.data.indexOf(',') + 1),
        name: this.documents[0].file.name
      };
      this.connectionService.postRequest(environment.gateway + 'application/' + this.appId + '/invoice', nachaFile,
        'application/json').subscribe(
          (data: any) => {
            this.messageTitle = 'Invoice Received';
            this.messageMessageA = 'Invoice has been submitted and is under review for funding.';
            this.messageMessageB = '';
            this.messageMessageC = 'Approved invoices submitted by 5 pm eastern time should be funded the same day.';
            this.messageMessageAClass = 'upload-success-a';
            this.messageMessageBClass = 'upload-success-b';
            this.messageMessageCClass = 'upload-success-c';
            this.dialogClass = 'w650';

            $('#errorModalDialogUploadDocumentModalId' + this.appId).modal();
            this.documents = [];
            resolve(data);
          },
          (error: any) => {
            this.messageTitle = 'Sorry, we found a problem';
            this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
            this.messageMessageB = error.error.msg;
            this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
            this.messageMessageAClass = '';
            this.messageMessageBClass = 'mB20';
            this.messageMessageCClass = '';
            this.dialogClass = 'w600';
            $('#errorModalDialogUploadDocumentModalId' + this.appId).modal();
            this.documents = [];
            reject(error);
          }
        );
    });
  }

  onCloseModal() {
    this.reloadSlice.emit(this.appId);
  }

  addExemptionReasonToOptionList() {
    if (this.exemptionReasonsList) {
      this.exemptionReasonsList.forEach((currentValue) => {
        let current: imeiOption = {} as imeiOption;
        current.description = currentValue.description;
        current.id = currentValue.id;
        current.isIMEI = false;

        this.imeiOptionList.push(current);
      });
    }

  }

  addIMEIToOptionList(dataReceived: any[]) {
    if (dataReceived) {
      dataReceived.forEach((currentValue) => {
        let current: imeiOption = {} as imeiOption;
        current.description = currentValue.imei;
        current.id = null;
        current.isIMEI = true;
        this.imeiOptionList.push(current);
      });
    }

  }

  async getVendorLocations() {
    const userName: string = 'apiuserclk@clicklease.com';
    const password: string = 'OtuDndhNfeaRk7TDnAaD';

    const promiseToken = await new Promise((resolve, reject) => {
      const body = {
        username: userName,
        password: password,
      };
      this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/authentication-management/token', body, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            this.token = data.data.token;
            resolve(this.token);
          },
          (error: any) => {
            this.showErrorMessage();
            reject(error);
          });
    });

    if (promiseToken) {
      const promiseHash = await new Promise((resolve, reject) => {
        const body = {
          username: userName,
          password: password,
          token: this.token
        };
        this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/authentication-management/login', body, 'application/json', this.authService.keyCloakToken)
          .subscribe(
            (data: any) => {
              this.user_hash = data.data.user_hash;
              resolve(this.user_hash);
            },
            (error: any) => {
              this.showErrorMessage();
              reject(error);
            }
          );
      });
      if (promiseHash) {
        const promiseLocations = await new Promise((resolve, reject) => {
          const body = {
            token: this.token,
            user_hash: this.user_hash,
            vendor_id: this.vendorId
          };
          this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/user-management/users/vendor', body, 'application/json', this.authService.keyCloakToken)
            .subscribe(
              (data: any) => {
                this.vendorLocations = data.data;
                resolve(data.data);
              },
              (error: any) => {
                this.showErrorMessage();
                reject(error);
              }
            );
        });
        const exemptionsPromise = await new Promise((resolve, reject) => {
          this.connectionService.getV3Request(`${environment.gatewayV3}gpsservice/api/gps-device-exempt-reasons/${this.appVendorId}`, 'application/json', this.authService.keyCloakToken)
            .subscribe(
              (data: any) => {
                if (data.object) {
                  this.exemptionReasonsList = data.object;
                  resolve(data);
                }
              },
              (error: any) => {
                this.showErrorMessage();
                reject(error);
              }
            );
        });

        if (promiseLocations && exemptionsPromise) {
          if (this.vendorLocations.length <= 1) {
            this.company = this.vendorLocations.company;
            this.getImeiList(0);
          } else {
            this.vendorLocations.forEach((location, index) => {
              this.vendorList.push({
                id: index,
                address: location.address,
                company: location.company,
                city: location.city,
                email: location.email,
                first_name: location.first_name,
                last_name: location.last_name
              });
            });
          }
        } if (!promiseLocations && exemptionsPromise) {
          this.getImeiList(0);
        }
      }
    }

  }
  // Get devices by vendor and location
  async getImeiList(id: number) {
    this.imeiOptionSelected = {} as imeiOption;
    this.imeiOptionList = [];
    this.showList(this.lastId);
    return await new Promise((resolve, reject) => {
      if (this.vendorList.length > 1) {
        this.company = this.vendorList[id].company;
        this.selectedLocation = this.vendorList[id].company;
      }
      const body = {
        token: this.token,
        user_hash: this.user_hash,
        vendor_id: this.vendorId,
        company: this.company,
        activated: 'NO'
      };

      this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/user-management/users/device', body, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            if (!data.data || data.data.length === 0) {
              this.imeiOptionList = [];
            } else {
              this.addIMEIToOptionList(data.data);
            }
            this.addExemptionReasonToOptionList();
            resolve(data);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  getImeiDetails() {
    let imeiParameter = this.imeiOptionSelected.description.trim();
    //Voltswitch fix 15/16 digits
    if (imeiParameter.length === 15) {
      imeiParameter = IMEI_PREFIX + imeiParameter;
    }


    return new Promise((resolve, reject) => {

      const body = {
        token: this.token,
        user_hash: this.user_hash,
        imei: imeiParameter
      };
      this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/device-management/device/details', body, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            this.imeiDeviceDetails = data.data;

            if (!this.imeiDeviceDetails) {
              this.errorMessage = 'This device number does not exist';
            } else {
              if (this.imeiDeviceDetails?.activated === '1') {
                this.errorMessage = 'This device is already active';
              }
              else {
                if (this.imeiDeviceDetails?.imei_associated) {
                  this.errorMessage = 'This device is already associated to another app';
                }
                else {
                  this.saveImeiNumber(imeiParameter);
                }
              }
            }

            resolve(data);
          },
          (error: any) => {
            //Standard error message
            this.showErrorMessage();
            reject(error);
          }
        );
    });
  }

  saveImeiNumber(imeiParameter: string) {
    return new Promise((resolve, reject) => {

      let exemptReason = null;
      let imei = null;

      if (this.imeiOptionSelected) {
        if (this.imeiOptionSelected.isIMEI) {
          exemptReason = null;
          imei = imeiParameter;
        } else {
          exemptReason = this.imeiOptionSelected.id;
          imei = null;
        }
      }

      const body = {
        application_id: this.appId,
        gpsDeviceExemptReasonId: exemptReason,
        imei: imei
      };

      this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/device-management/device/synchronize', body, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            resolve(data);
            this.onUploadInvoiceFile();
          },
          (error: any) => {
            this.showErrorMessage();
            reject(error);
          }
        );
    });
  }

  getShippingAddress() {
    return new Promise((resolve, reject) => {
      this.connectionService.getRequest(environment.gateway + 'equipment/' + this.appId, 'application/json').subscribe(
        (data: any) => {
          if (data.object) {
            data.object.forEach((invoiceItem: any) => {
              this.invoiceShippingInfo.address = invoiceItem.streetAddress;
              this.invoiceShippingInfo.zipCode = invoiceItem.zipCode;
              this.invoiceShippingInfo.city = invoiceItem.city;
              this.invoiceShippingInfo.state = invoiceItem.state;
            });
          }
          resolve(data);
        },
        (error: any) => {
          resolve(error);
        }
      );
    });
  }

  getPDFFile() {
    return new Promise((resolve, reject) => {

      this.connectionService.postRequest(
        environment.gateway + 'document/po/' + this.appId, {}, 'application/json').subscribe(
          (data: any) => {
            data.object.forEach((poDocument: any) => {
              var linkSource = 'data:application/pdf;base64,' + poDocument.documentB;
              this.poFileBase64 = linkSource;
            });

            resolve(data);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  openModal() {
    $('#pdfViewerSubmitInvoice' + this.appId).modal();
  }

  downloadFile() {
    var downloadLink = document.createElement('a');
    var fileName = 'PODocument.pdf';

    downloadLink.href = this.poFileBase64;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  onsave() {
    this.errorMessage = '';
    //Only if GPS required and IMEI not blank
    if (this.isGPSRequired()) {
      //If exempt reason
      if (this.imeiOptionSelected.id && this.imeiOptionSelected.isIMEI === false) {
        this.saveImeiNumber(this.imeiOptionSelected.id.toString());
      } else {
        this.getImeiDetails();
      }
    } else {
      this.onUploadInvoiceFile();
    }
  }

  onSortClick(field: any) {
    this.selectedRow = -1;
    this.tempSelectedRow = -1;
    if (this.sortField == field) {
      this.invertedSort = !this.invertedSort;
    } else {
      this.invertedSort = false;
      this.sortField = field;
    }
  }

  onSortSearch(field: string) {
    if (this.imeiOptionSelected.description) {
      this.imeiOptionSelected = {
        description: field, id: null, isIMEI: true
      }
    }

    this.search = 'description';
    this.searchIMEI = field;
    this.imeiOptionSelected.description = field;
    this.selectedRow = -1;
    this.tempSelectedRow = -1;
    this.errorMessage = '';
    if (field.length > 15) {
      this.errorMessage = 'IMEI numbers contain 15 digits';
    }
  }

  showList(id) {
    if (this.show) {
      this.selectedId = id;
      if (this.lastId !== id) {
        this.countShow = false;
        this.lastId = id;
      }
      return;
    }
    this.show = true;
    this.selectedId = id;
    this.lastId = id;
  }

  // check that the input or the select icon is not clicked
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    let id = event.target.getAttribute('id');
    let isDisabled = event.target.disabled;
    if ((!this.idList.includes(id) && this.countShow === true) || this.countShow || isDisabled) {
      this.show = false;
      this.countShow = false;
      return;
    } if (!this.idList.includes(id) && !this.countShow) {
      this.countShow = false;
      return
    }
    this.countShow = true;
  }

  selectItem(item: any) {
    this.imeiOptionSelected = item;
    this.errorMessage = '';
    this.showList(this.lastId);
  }

  showErrorMessage() {
    this.messageTitle = 'Sorry, we found a problem';
    this.messageMessageA = '';
    this.messageMessageC = 'Unfortunately we are unable to process your request at this moment. Please contact customer service at 1-888-509-5592 for more information.';
    this.messageMessageAClass = '';
    this.messageMessageBClass = 'mB20';
    this.messageMessageCClass = '';
    this.dialogClass = 'w600';
    $('#errorModalDialogUploadDocumentModalId' + this.appId).modal();

  }

  isSubmitDocumentsAllowed() {
    if (this.documents.length < 1) {
      return true;
    } else {
      if (this.isGPSRequired()) {
        if (Object.keys(this.imeiOptionSelected).length === 0) {
          return true;
        } else {
          if (this.imeiOptionSelected.description === '') {
            return true
          }
        }
      }
    }
    return false;
  }

  percentageToDisplay() {
    return Math.round(this.zoom * 100).toString() + '%';
  }

  zoomIn() {
    this.zoom += 0.05;
  }

  zoomOut() {
    if (this.zoom > 0.05) {
      this.zoom -= 0.05;
    }

  }
}
