import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'app-summary-declined',
    templateUrl: './summary-declined.component.html',
    styleUrls: ['./summary-declined.component.css']
})
export class SummaryDeclinedComponent implements OnInit {
    @Input() processStatus: any = '';

    constructor() { }

    ngOnInit() {
    }

}
