import { IBusiness } from 'src/app/application/v3/model';
import { formatUSPhone } from '..';

export function formatBusines(businessData, personalData, legalName, isSoleApp) {
    return <IBusiness>{
        dbaName: businessData.DBA,
        businessType: businessData.businessType,
        businessStartDate: businessData.businessStartDate.toISOString().slice(0, 10),
        address: isSoleApp ? (businessData.businesAddressDifferent ? businessData.businessAddress : personalData.street) : businessData.businessAddress,
        zipCode: isSoleApp ? (businessData.businesAddressDifferent ? businessData.zipCode : personalData.zipcode) : businessData.zipCode,
        state: isSoleApp ? (businessData.businesAddressDifferent ? businessData.state : personalData.state) : businessData.state,
        city: isSoleApp ? (businessData.businesAddressDifferent ? businessData.city : personalData.city) : businessData.city,
        county: isSoleApp ? (businessData.businesAddressDifferent ? businessData.county : personalData.county) : businessData.county,
        email: businessData.email ? businessData.email : businessData.businessEmail,
        legalName: businessData.businessName ? businessData.businessName : legalName,
        businessPhone: isSoleApp ? personalData.phone : businessData.businessPhone
    }
}

export function formatBusinessData(
    {
        businessStructure,
        legalName,
        dbaName,
        email,
        businessStartDate,
        ownershipPercentage,
        businessStreet,
        businessZipcode,
        businessCity,
        businessState,
        businessCounty,
        businessPhone
    }) {
    let businessData = {
        businessType: businessStructure,
        businessName: legalName,
        DBA: dbaName,
        email: email,
        businessEmail: email,
        businessStartDate: businessStartDate ? new Date(`${businessStartDate}T12:00`) : new Date(),
        termsService: true,
        percentOwnership: ownershipPercentage,
        businesAddressDifferent: true,
        businessAddress: businessStreet,
        zipCode: businessZipcode,
        city: businessCity,
        state: businessState,
        county: businessCounty,
        businessPhone: businessPhone ? formatUSPhone(businessPhone) : businessPhone
    };
    return businessStructure ? businessData : false;
}