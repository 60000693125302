import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-decline-finicity',
    templateUrl: './decline-finicity.component.html',
    styleUrls: ['./decline-finicity.component.css']
})
export class DeclineFinicityComponent implements OnInit {

    constructor(private router: Router) {
        void (0);
    }

    ngOnInit(): void {
        void (0);
    }

    onBackClick() {
        this.router.navigateByUrl('/');
    }
}