<label for="file{{elementId}}" class="upload-medium {{customClass}}" [ngClass]="{'done': uploaded}">
    <div class="drop-files py-5 rounded" [attr.id]="elementId" [ngClass]="{'is-dragover': isDragOver}">
        <div class="uploader-image"></div>
        <!-- <div class="font-mont-book">{{lineA}}</div>
        <div class="font-mont-book">{{lineB}}</div> -->
        <!-- <span _ngcontent-tea-c50="" class="font-mont-book" style="
            max-width: 175px;
            display: flex;
            justify-content: center;
            margin: auto;
            padding-bottom: 1rem;">Drag your file here or click to upload from your computer.</span> -->
        <span class="d-flex justify-content-center font-mont-book mx-auto pb-3"> {{(lineA && lineB && lineA + ' '
            +lineB)
            || 'Drag your file here or click to upload from your computer.'}}</span>
    </div>
</label>
<input type="file" placeholder="upload invoice" id="file{{elementId}}" name="file{{elementId}}"
    (change)="fileChangeEvent($event)" />