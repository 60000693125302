declare var $: any;
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    loginErrorMsg = '';

    private token = '';
    public resetForm: UntypedFormGroup;
    public submitted: boolean = false;

    @ViewChild('passwordInput', { static: true }) passwordInputRef: ElementRef;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: UntypedFormBuilder
    ) {
        this.resetForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,30}$/)]],
            repassword: ['', Validators.required]
        }, {
            validator: this.MustMatch('password', 'repassword')
        });
    }

    ngOnInit() {
        this.token = this.route.snapshot.queryParams['token'];
    }

    onResetPassword() {
        this.submitted = true;
        if (this.resetForm.invalid) {
            return;
        }

        const password = this.resetForm.controls.password.value;
        this.authService.resetPassword(this.token, password).then(
            data => $('#changePasswordSuccessModal').modal('show'),
            error => { this.loginErrorMsg = error.error.errorMsg;
                $('#changePasswordErrorModal').modal('show');
            }
        );
    }

    onChangedOk() {
        this.router.navigate(['signin']);
    }

    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}
