import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { MultiRiskComponent } from './admin/multi-risk/multi-risk.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';
import { PricingComponent } from './admin/pricing-tool/pricing-tool.component';
import { ImpersonateComponent } from './admin/impersonate/impersonate.component';
import { AchConverterComponentComponent } from './admin/ach-converter-component/ach-converter-component.component';
import { TermToolComponent } from './admin/term-tool/term-tool.component';
import { MaxOfferToolComponent } from './admin/max-offer-tool/max-offer-tool.component';
import { DataRobotToolComponent } from './admin/data-robot-tool/data-robot-tool.component';
import { AddOwnerTokenComponent } from './application-token/add-owner-token/add-owner-token.component';
import { CustomerUserComponent } from './auth/customer-user/customer-user.component';
import { FraudListComponent } from './admin/fraud-list/fraud-list.component';
import { TokenErrorV3Component } from './application/errors/token-error-v3/token-error-v3.component';
import { GeneralErrorV3Component } from './application/errors/general-error-v3/general-error-v3.component';
import { FinicityDeclinedComponent } from './bank-process/finicity-declined/finicity-declined.component';
import { FraudErrorComponent } from './application/errors/fraud-error/fraud-error.component';
import { DataErrorV3Component } from './application/errors/data-error-v3/data-error-v3.component';
import { DeclineErrorComponent } from './application/errors/decline-error/decline-error.component';
import { StartedSuccessComponent } from './application/v3/started-success/started-success.component';
import { MobileStartedSuccessComponent } from './application/v3/mobile-started-success/mobile-started-success.component';
import { DocuSignCompletedComponent } from './click-sing/docu-sign-completed/docu-sign-completed.component';
import { LeasableListComponent } from './leasable-items/leasable-list/leasable-list.component';
import { LeasableImportComponent } from './leasable-items/leasable-import/leasable-import.component';
import { LeasableItemsDragAndDropComponent } from './leasable-items/leasable-items-drag-and-drop/leasable-items-drag-and-drop.component';
import { LeasebleExportComponent } from './leasable-items/leasable-export/leasable-export.component';
import { LeasableImportPreviewComponent } from './leasable-items/leasable-import/leasable-import-preview/leasable-import-preview.component';
import { LeasableFilterComponent } from './leasable-items/leasable-filter/leasable-filter.component';
import { BenefitsComponent } from './application/v3/benefits/benefits.component';
import { GeneralErrorComponent } from './error/general-error/general-error.component';
import { DeclinedComponent } from './application/declined/declined.component';
import { DeclineFinicityComponent } from './application/decline-finicity/decline-finicity.component';
import { DeclineDataErrorComponent } from './application/decline-data-error/decline-data-error.component';
import { TokenErrorComponent } from './error/token-error/token-error.component';

import { AuthGuard } from './auth/auth-guard.service';
import { AuthAntiGuard } from './auth/auth-anti-guard.service';
import { AuthTokenGuard } from './auth/auth-token-guard.service';
import { AuthBrokerGuard } from './auth/auth-broker-guard.service';
import { DirectAccessGuardGuard } from './auth/direct-access-guard.guard';
import { BlockGuard } from './application/v2/block.guard';

import { OffersComponent } from './application/offers/offers.component';
import { SummaryComponent } from './home/summary/summary.component';
import { VendorListComponent } from './vendors/vendor-list/vendor-list.component';

import { UploadDriverLicenseComponent } from './dl-process/upload-driver-license/upload-driver-license.component';
import { UploadInvoiceComponent } from './application/invoice/upload-invoice/upload-invoice.component';
import { AuthLeasableGuard } from './auth/auth-leasable-guard.service';
import { AuthTokenParamGuard } from './auth/auth-token-param-guard.service';
import { SubmitErrorsComponent } from './application/new-finicity-errors/submit-errors/submit-errors.component';
import { ConfirmStatusComponent } from './application/new-finicity-errors/confirm-status/confirm-status.component';

import { DlErrorUploadFailedComponent } from './dl-process/dl-error-upload-failed/dl-error-upload-failed.component';
import { DlSuccessSubmitionComponent } from './dl-process/dl-success-submition/dl-success-submition.component';
import { AuthRiskGuard } from './auth/auth-risk-guard.service';

import { AppResumeComponent } from './application/app-resume/app-resume.component';
import { AppSoftDeclineComponent } from './application/errors/soft-decline/app-soft-decline.component';
import { VerifyingPageComponent } from './application/verifying-page/verifying-page.component';
import { VendorExcludeAppResultsGuardGuard } from './auth/vendor-exclude-app-results-guard.guard';
import { NotFoundErrorComponent } from './application/errors/not-found-error/not-found-error.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/signin', pathMatch: 'full' },
    { path: 'support', component: CustomerSupportComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'about', component: AboutComponent },
    { path: 'customer-user', component: CustomerUserComponent },
    { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard, BlockGuard] },
    { path: 'signin', component: SigninComponent, canActivate: [AuthAntiGuard] },
    { path: 'resetpassword/reset', component: ResetPasswordComponent, canActivate: [AuthAntiGuard] },

    //Risk paths
    { path: 'pricing', component: PricingComponent, canActivate: [AuthGuard, AuthRiskGuard] },
    { path: 'term-tool', component: TermToolComponent, canActivate: [AuthGuard, AuthRiskGuard] },
    { path: 'max-offer', component: MaxOfferToolComponent, canActivate: [AuthGuard, AuthRiskGuard] },
    { path: 'data-robot', component: DataRobotToolComponent, canActivate: [AuthGuard, AuthRiskGuard] },
    { path: 'multirisk', component: MultiRiskComponent, canActivate: [AuthGuard, AuthRiskGuard] },
    { path: 'fraud-list', component: FraudListComponent, canActivate: [AuthGuard, AuthRiskGuard] },

    //Admin paths
    { path: 'impersonate', component: ImpersonateComponent, canActivate: [AuthGuard] },
    { path: 'ach-converter', component: AchConverterComponentComponent, canActivate: [AuthGuard] },

    //possible legacy not used components
    { path: 'application/offers', component: OffersComponent, canActivate: [AuthGuard] },
    { path: 'vendor', component: VendorListComponent, canActivate: [AuthBrokerGuard] },

    //used as component but not the path
    { path: 'application/summary', component: SummaryComponent, canActivate: [AuthGuard] },

    //clicksign flow modules.

    { path: 'application/offerselection', redirectTo: 'clicksign/otp-verification', pathMatch: 'full' },
    { path: 'clicksign', loadChildren: () => import('./click-sing').then(m => m.ClickSingModule) },
    { path: 'oldsign', loadChildren: () => import('./click-sing/old-sing/old-sing.module').then(m => m.OldSingModule) },
    { path: 'completion/trigger', component: DocuSignCompletedComponent },
    { path: 'upload-invoice/:id', component: UploadInvoiceComponent, canActivate: [AuthTokenGuard] },

    //Driver License flow
    { path: 'upload-license', component: UploadDriverLicenseComponent, canActivate: [AuthTokenGuard]},
    { path: 'upload-license-error', component: DlErrorUploadFailedComponent, canActivate: [DirectAccessGuardGuard] },
    { path: 'upload-license-success', component: DlSuccessSubmitionComponent, canActivate: [DirectAccessGuardGuard] },


    //legacy errors
    { path: 'token-error', component: TokenErrorComponent },
    { path: 'general-error', component: GeneralErrorComponent, canActivate: [AuthGuard] },
    { path: 'application/error', component: DeclineFinicityComponent },
    { path: 'application/data-error-declined', component: DeclineDataErrorComponent },
    { path: 'application/declined', component: DeclinedComponent },

    //leaseable items paths
    {
        path: 'leasable', children: [
            { path: 'list', component: LeasableListComponent },
            { path: 'import', component: LeasableImportComponent },
            { path: 'preview', component: LeasableImportPreviewComponent },
            { path: 'items-drag-and-drop', component: LeasableItemsDragAndDropComponent },
            { path: 'export', component: LeasebleExportComponent },
            { path: 'filter', component: LeasableFilterComponent },
        ], canActivate: [AuthLeasableGuard]
    },

    //Application flow paths and errors
    { path: 'benefits/:token', component: BenefitsComponent, canActivate: [AuthTokenParamGuard] },
    { path: 'inowner', component: AddOwnerTokenComponent, canActivate: [AuthTokenGuard] },
    { path: 'finicity-link', redirectTo: 'inlineapp/finicity-link', pathMatch: 'full' },
    { path: 'finicity-link-v3', redirectTo: 'token-error', pathMatch: 'full' },
    { path: 'application', redirectTo: 'edgar', pathMatch: 'full' },
    { path: 'application/started', redirectTo: 'edgar', pathMatch: 'full' },
    { path: 'started-success', component: StartedSuccessComponent },
    { path: 'mobile-success', component: MobileStartedSuccessComponent, canActivate: [DirectAccessGuardGuard] },
    { path: 'started-failed', component: FraudErrorComponent },
    { path: 'token-error-v3', component: TokenErrorV3Component },
    { path: 'general-error-v3', component: GeneralErrorV3Component, canActivate: [DirectAccessGuardGuard] },
    { path: 'finicity-declined', component: FinicityDeclinedComponent, canActivate: [DirectAccessGuardGuard] },
    { path: 'process-error', component: FraudErrorComponent, canActivate: [DirectAccessGuardGuard] },
    { path: 'data-error', component: DataErrorV3Component, canActivate: [DirectAccessGuardGuard] },
    { path: 'decline-error', component: DeclineErrorComponent, canActivate: [DirectAccessGuardGuard, VendorExcludeAppResultsGuardGuard] },
    { path: 'app-result', component: AppSoftDeclineComponent, canActivate: [DirectAccessGuardGuard] },
    { path: 'submit-errors', component: SubmitErrorsComponent, canActivate: [DirectAccessGuardGuard] },
    { path: 'confirm-status', component: ConfirmStatusComponent, canActivate: [DirectAccessGuardGuard] },
    { path: 'inlineapp', redirectTo: 'inlineapp/started', pathMatch: 'full' },
    { path: 'inlineapp', loadChildren: () => import('./application/v3/application.module').then(m => m.ApplicationModule), canActivate: [AuthTokenGuard]},
    { path: 'edgar', loadChildren: () => import('./application/edgar-application/edgar-application.module').then(m => m.EdgarApplicationModule), canActivate: [AuthGuard, BlockGuard] },
    { path: 'v3/inlineapp', redirectTo: 'inlineapp/started', pathMatch: 'full' },
    { path: 'v3/inlineapp/started', redirectTo: 'inlineapp/started', pathMatch: 'full' },
    { path: 'v3/started-success', redirectTo: 'started-success', pathMatch: 'full' },
    { path: 'v3/started-failed', redirectTo: 'started-failed', pathMatch: 'full' },
    { path: 'v3/inlineapp/otp-started', redirectTo: 'inlineapp/started', pathMatch: 'full' },
    { path: 'v3/inlineapp/mobile-started/:requestId', redirectTo: 'inlineapp/mobile-started/:requestId', pathMatch: 'full' },
    { path: 'app-resume', component: AppResumeComponent },
    { path: 'link-resume', component: VerifyingPageComponent},
    { path: '**', component: NotFoundErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
