import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { BankProcessService } from 'src/app/bank-process';

@Component({
    selector: 'app-fail-giact-error-modal',
    templateUrl: './fail-giact-error-modal.component.html',
    styleUrls: ['./fail-giact-error-modal.component.css']
})
export class FailGiactErrorModalComponent implements OnInit {
    @Input() title = 'We still can’t verify your bank account';
    @Input() textA = '';
    @Input() sent = '';
    @Input() textB = 'Don’t worry, we have another way to verify.';
    @Input() phone = '';
    @Input() applicationId: boolean;
    @Input() data: any;
    @Input() access_token: string;
    @Input() token: any;
    @Output() onBankAccountContinue = new EventEmitter<any>();

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private bankProcessService: BankProcessService,
    ) { }

    get isClickSign() {
        return this.bankProcessService.isClicksing;
    }

    private trackEvent(eventName, refererName, eventData, errorCode?) {
        if (this.isClickSign) {
            this.googleAnalyticsService.trackClickSignEvent(
                eventName,
                refererName,
                eventData,
                errorCode
            );
        } else this.googleAnalyticsService.trackV3Event(
            eventName,
            refererName,
            eventData,
            errorCode
        );
    }

    ngOnInit(): void {
        this.trackEvent(
            'finicity-required-modal-open',
            this.bankProcessService.vendorReferrerName,
            'Fail giact error modal open'
        );
    }

    failGiactContinueBnt() {
        this.trackEvent(
            'finicity-required-modal-continue',
            this.bankProcessService.vendorReferrerName,
            'Fail giact error modal button Continue Clicked'
        )
        this.onBankAccountContinue.emit();
    }
}