import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderModule } from '../header/header.module';

import { BankProcessRoutingModule } from './bank-process-routing.module';

import { BankProcessService } from './bank-process.service';

//bank-process
import { ConnectAccountComponent } from './connect-account/connect-account.component';
import { DeniedBVComponent } from './denied-bv/denied-bv.component';
import { GiactErrorComponent } from './giact-error/giact-error.component';
import { FinicityProcessComponent } from './finicity-process/finicity-process.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { BankAccountErrorModalComponent } from './connect-account/bank-account-error-modal/bank-account-error-modal.component';
import { PreApprovalComponent } from './pre-approval/pre-approval.component';
import { PopUpNotifyComponent } from './pop-up-notify/pop-up-notify.component';
import { FailGiactErrorModalComponent } from './connect-account/fail-giact-error-modal/fail-giact-error-modal.component';
import { ReadMoreComponent } from './read-more/read-more.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    ConnectAccountComponent,
    DeniedBVComponent,
    GiactErrorComponent,
    FinicityProcessComponent,
    BankAccountComponent,
    BankAccountErrorModalComponent,
    PreApprovalComponent,
    PopUpNotifyComponent,
    FailGiactErrorModalComponent,
    ReadMoreComponent
  ],
  imports: [
    CommonModule,
    BankProcessRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgxMaskModule.forRoot(options),
    HeaderModule
  ],
  providers: [BankProcessService]
})
export class BankProcessModule { }
