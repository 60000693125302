<div class="error-content">
    <div class="logo">
        <img class="click-lease-logo" src="\assets\images\click-lease-logo-svg.svg"/>
    </div>
    <div class="cases" [ngSwitch]="true">
        <div class="switchcase"
            *ngSwitchCase="event == 'XA01' || event == 'XB01' || event == 'XN05' || event == 'XT00'">
            <img src="/assets/images/icons/application-errors/error_computer_icon.svg" />
            <div class="font-mont-bold">Oops. There seems to be an issue <br> with the account number.</div>
            <div class="font-mont-regular">Please verify that you entered this information correctly and try again.
            </div>
        </div>
        <div class="switchcase" *ngSwitchCase="event == '2222' || event == '5555' || event == 'XT04'">
            <img src="/assets/images/icons/application-errors/error_computer_icon.svg" />
            <div class="font-mont-bold">Oops. This doesn’t appear to be a <br> checking account.</div>
            <div class="font-mont-regular">Please enter a checking account.</div>
        </div>
        <div class="switchcase" *ngSwitchCase="event == '409'">
            <img src="/assets/images/icons/application-errors/icon_fraud.svg" />
            <div class="font-mont-bold">Oops. This bank account may <br />
                already be in use.</div>
            <div class="font-mont-regular">Please try again with a different account.</div>
        </div>
        <div class="switchcase" *ngSwitchDefault>
            <img src="/assets/images/icons/application-errors/denied-error-icon.svg" />
            <div class="font-mont-bold">Sorry, our system cannot accept <br> this bank account. </div>
            <div class="font-mont-regular">Please try adding a different account.</div>
        </div>
    </div>
    <button class="font-mont-bold" (click)="backEvent()">Try Again</button>
    <div class="footer-message font-mont-regular">Need help? Have questions? Call <span class="font-mont-regular">1-888-509-5592</span></div>
</div>