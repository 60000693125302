import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-application-details-notes',
    templateUrl: './application-details-notes.component.html',
    styleUrls: ['./application-details-notes.component.css', '../../ept-ui-elements.css']
})
export class ApplicationDetailsNotesComponent implements OnInit {
    @Input() id = 0;
    @Input() notes = '';

    public open: boolean = false;
    public form: UntypedFormGroup;

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService) {
        this.form = this.formBuilder.group({
            notes: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.form.controls.notes.setValue(this.notes);
    }

    onFormSubmit() {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.postRequest(
                environment.gateway + 'application/' + this.id + '/notes', { notes: this.form.controls.notes.value },
                'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
        });
        return promise;
    }

}
