import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IRequestBody } from 'src/app/application/v3/model';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ConnectionService } from 'src/app/shared/services';
import { AuthService } from 'src/app/auth/auth.service';
import { Experimental, formatBusines, isMobile } from 'src/app/utils/shared';
import {
    PersonalFormService,
} from 'src/app/application/v3/services';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    applicationId: number = null;
    daysToExpire: number = 30;
    maxApprovedAmount: number = null;
    offerList = [
        {
            term: 24,
            payment: 674
        },
        {
            term: 48,
            payment: 367
        },
        {
            term: 72,
            payment: 247
        }
    ];
    requestedAmount: number = null;
    roseOtpCode: string = null;
    isSolePropiertorApp: boolean = false;
    vendorPhone: string;

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private connectionService: ConnectionService,
        private authService: AuthService,
        private personalFormService: PersonalFormService,
    ) { }

    get accessToken() {
        return this.authService.authTokenV3;
    }

    set accessToken(token) {
        this.authService.authTokenV3 = token;
    }

    get queryToken() {
        return this.authService.getQueryToken
    }

    get isProduction() {
        return this.connectionService.isProduction;
    }

    get vendorReferrerName() {
        return this.authService.vendorReferrerName;
    }

    private getBusinessData() {
        return <IRequestBody> {
            salesPersonId: 0,
            requestedAmount: 0,
            googleExperience: environment.GoogleExperience,
            googleVariant: 'V3',
            lessee: {
                owner: {
                    leadId: 0,
                    primaryOwner: true,
                    ownershipPercentage: 100,
                    termFlag: true,
                    marketingFlag: true
                }
            }
        };
    }

    async authForRose(appId, access_token) {
        try {
            const response = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/continueFlowRose?appId=${appId}`,
                contentType: 'application/json',
                postData: {},
                access_token
            });

            Experimental.updateExperimental('B');

            return response;

        } catch (err) { return err; }
    }

    async estimateOffersCalculation(newAmount): Promise<boolean> {
        try {
            const respose = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}offerservice/api/getReCalcPricingOffer/${this.applicationId}?newRequestAmount=${newAmount}&env=${environment.xEnvironmentUrl}`,
                postData: {},
                contentType: 'application/json',
                access_token: this.accessToken
            });
            let valid = false;

            if (respose && respose.object && respose.object.offerList && respose.object.offerList.length) {
                this.offerList = respose.object.offerList;
                valid = true;
            }

            return valid;
        } catch (err) { return err; }
    }

    async getUserProveData(requestId: string) {
        try {
            const response: any = await this.connectionService.getV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/prove-decisioning/${requestId}`,
                contentType: 'application/json'
            });

            return response;
        } catch (err) { throw err; }
    }

    /*async sendAgreementCode(id: string, vendor_token: string) {
        try {
            this.googleAnalyticsService.trackClickSignEvent(
                'send-code-clicked',
                this.vendorReferrerName,
                ''
            );

            const response = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/sendAgreementOtp/${id}?signingVersion=${this.googleAnalyticsService.clickSignVariant}`,
                contentType: 'application/json',
                vendor_token
            });

            this.googleAnalyticsService.trackClickSignEvent(
                'enter-code-shown',
                this.vendorReferrerName,
                'resend'
            );

            return response;
        } catch (err) { return err; }
    }*/

    /*async sendLeadCode(id: string) {
        try {
            const response = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/sendOtpWithLeadId/${id}`,
                contentType: 'application/json',
                postData: {}
            });

            this.googleAnalyticsService.trackV3Event(
                'enter-code-shown',
                this.vendorReferrerName,
                'resend'
            );

            return response;
        } catch (err) { return err; }
    }*/

    async sendPhoneAuth(payload: any) {
        try {
            payload.isMobile = isMobile();
            payload.staticToken = this.authService.getQueryToken;

            const response = this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/sendPhoneAuth`,
                contentType: 'application/json',
                postData: payload,
                access_token: this.authService.authTokenV3
            });

            return response;
        } catch (err) { return err; }
    }

    async sendPhoneClicked(phoneDT: string) {
        try {
            this.googleAnalyticsService.trackV3Event(
                'send-code-clicked',
                this.vendorReferrerName,
                ''
            );

            const response = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/sentOtp/${phoneDT}`,
                contentType: 'application/json',
                postData: {},
            });

            this.googleAnalyticsService.trackV3Event(
                'enter-code-shown',
                this.vendorReferrerName,
                'resend'
            );

            return response;
        } catch (err) { return err; }
    }

    async submitApplication(businessData, isSoleApp, personalData, requestedAmount) {
        try {
            const postData = this.getBusinessData();
            const tokenInfo = await this.authService.vendorTokenInfo;
            let endpoint: string;
            let legalName: string = `${personalData.firstName} ${personalData.lastName}`;
            postData.requestedAmount = requestedAmount;
            postData.lessee.business = formatBusines(businessData, personalData, legalName, isSoleApp);
            postData.lessee.owner.leadId = personalData.id;
            postData.lessee.owner.ownershipPercentage = businessData['percentOwnership'] ? parseInt(businessData['percentOwnership']) : 100;
            postData.salesPersonId = tokenInfo.id;

            const data = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}leaseapplication/api/applicationWithToken2?token=${this.queryToken}`,
                postData,
                contentType: 'application/json',
                access_token: this.accessToken
            });

            this.googleAnalyticsService.trackAplicationConversion();
            if (data && data.object) {
                this.googleAnalyticsService.setApplicationId(this.applicationId);
                this.googleAnalyticsService.trackV3Event('application-response', this.vendorReferrerName, JSON.stringify(data));

                this.applicationId = data.object.applicationId;
                this.maxApprovedAmount = data.object.maxApproved;
                this.requestedAmount = data.object.requestAmount;
                this.daysToExpire = data.object.daysToExpire;

                if (data.msg == 'DATA_ERROR') {
                    throw { redirect: 'data-error', error: data };
                }

                if (data.object.status == 'REJECTED') {
                    throw { redirect: 'decline-error', error: data };
                }

                if (data.object.offerList && data.object.offerList.length) {
                    this.offerList = data.object.offerList;
                    return { finicity: data.object.vodRequired, data: data };

                } else if (data.object.vodLink && data.object.vodRequired) {
                    return { finicity: true, data: data };

                } else if (data.object.flow == 'HIGH' && data.object.status == 'OFFERSGENERATED') {
                    return { bankProcess: true, data: data };
                }
                throw { redirect: 'process-error', error: data };
            } else if (data) {
                this.googleAnalyticsService.trackV3Event('application-response', this.vendorReferrerName, JSON.stringify(data));
                if (data.msg == 'DATA_ERROR') {
                    throw { redirect: 'data-error', error: data };
                }
                if (data.msg == 'No offer available') {
                    throw { redirect: 'decline-error', error: data };
                }
            }
            this.googleAnalyticsService.trackV3Event('application-response', this.vendorReferrerName, JSON.stringify(data));
            throw { redirect: 'general-error-v3', error: data };
        } catch (err) {
            if(err.redirect) {
                throw err;
            } else throw { redirect: 'general-error-v3', error: err };
        }
    }

    async mobileAuthRose(applicationId, access_token) {
        try {
            const response = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/mobileAuthRose/${applicationId}`,
                postData: {},
                contentType: 'application/json',
                access_token,
                vendor_token: this.queryToken
            });

            return response
        } catch (err) { throw err }
    }

    setApplicationData(appData: any) {
        if (appData) {
            this.requestedAmount = appData.requestAmount;
            this.maxApprovedAmount = appData.maxApproved;
            this.daysToExpire = appData.daysToExpire;

            if (appData.applicationId) {
                this.applicationId = appData.applicationId;
            }

            if (appData.vendorPhone) {
                this.vendorPhone = appData.vendorPhone;
            }

            if (appData.applicationId) {
                this.applicationId = appData.applicationId;

                if (appData.name) {
                    this.personalFormService.setValueToControl(
                        'firstName',
                        appData.name
                    );
                } else this.personalFormService.setValueToControl(
                    'firstName',
                    appData.legalName ? appData.legalName : ''
                );

                if (appData.finicityUrl) {
                    return 'finicity';
                }

                if (appData.offerList && appData.offerList.length) {
                    this.offerList = appData.offerList;
                    return 'approval';
                }

                if (appData.bankStatusRequired) {
                    //TODO this.bankSatusRequired = appData.bankStatusRequired;
                    return 'bankProcess';
                }

                return 'err';
            }
            return 'new';
        }
        return 'err';
    }

    validateVendorPhone() {
        if (!this.vendorPhone) {
            this.authService.vendorTokenInfo.then((tokenInfo: { phone: string; }) => {
                this.vendorPhone = tokenInfo.phone;
            });
        }
    }
}
