import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../auth/auth.service';

declare var $: any;
const IMEI_PREFIX = '0';

@Component({
  selector: 'app-funded',
  templateUrl: './funded.component.html',
  styleUrls: ['./funded.component.css', '../ept-ui-elements.css'],
})
export class FundedComponent implements OnInit {

  public imeiNumber: string = '';


  @Input() appId: any = 0;
  @Output() reloadSlice = new EventEmitter<void>();

  private token: string;
  private user_hash: string;

  public modalId = '';
  public messageTitle = '';
  public messageMessageA = '';
  public messageMessageB = '';
  public messageMessageC = '';
  public messageMessageAClass = '';
  public messageMessageBClass = '';
  public messageMessageCClass = '';
  public dialogClass = '';

  private imeiDeviceDetails: any;
  public errorMessage = '';
  public imeiRequired = false;
  public imeiAssociated = false;
  constructor(private connectionService: ConnectionService, private authService: AuthService,) {

  }

  ngOnInit() {
    this.modalId = 'fundModalId' + this.appId;

    this.getGpsPendingDeviceValidation().then((result) => {
      if (this.imeiRequired) {
        this.getTokenAndHash();
      }
    });

  }

  onCloseModal() {
    this.reloadSlice.emit(this.appId);
  }


  async getTokenAndHash() {
    const userName: string = 'apiuserclk@clicklease.com';
    const password: string = 'OtuDndhNfeaRk7TDnAaD';

    const promiseToken = await new Promise((resolve, reject) => {
      const body = {
        username: userName,
        password: password,
      };
      this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/authentication-management/token', body, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            this.token = data.data.token;
            resolve(this.token);
          },
          (error: any) => {
            this.showErrorMessage();
            reject(error);
          });
    });

    if (promiseToken) {
      const promiseHash = await new Promise((resolve, reject) => {
        const body = {
          username: userName,
          password: password,
          token: this.token
        };
        this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/authentication-management/login', body, 'application/json', this.authService.keyCloakToken)
          .subscribe(
            (data: any) => {
              this.user_hash = data.data.user_hash;
              resolve(this.user_hash);
            },
            (error: any) => {
              this.showErrorMessage();
              reject(error);
            }
          );
      });
    }

  }

  onSortSearch(field: string) {
    this.errorMessage = '';
    if (field.length > 15) {
      this.errorMessage = 'IMEI numbers contain 15 digits';
    }
  }


  getImeiDetails() {

    let imeiParameter = this.imeiNumber
    //Voltswitch fix 15/16 digits
    if (imeiParameter.length === 15) {
      imeiParameter = IMEI_PREFIX + imeiParameter;
    }

    if (this.token && this.user_hash) {
      return new Promise((resolve, reject) => {

        const body = {
          token: this.token,
          user_hash: this.user_hash,
          imei: imeiParameter
        };
        this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/device-management/device/details', body, 'application/json', this.authService.keyCloakToken)
          .subscribe(
            (data: any) => {
              this.imeiDeviceDetails = data.data;

              if (!this.imeiDeviceDetails) {
                this.errorMessage = 'This device number does not exist';
              } else {
                if (this.imeiDeviceDetails?.activated === '1') {
                  this.errorMessage = 'This device is already active';
                }
                else {
                  if (this.imeiDeviceDetails?.imei_associated) {
                    this.errorMessage = 'This device is already associated to another app';
                  }
                  else {
                    this.saveImeiNumber(imeiParameter);
                  }
                }
              }

              resolve(data);
            },
            (error: any) => {
              //Standard error message
              this.showErrorMessage();
              reject(error);
            }
          );
      });
    }


  }

  async getGpsPendingDeviceValidation() {
    return await new Promise((resolve, reject) => {
      this.connectionService.getV3Request(`${environment.gatewayV3}gpsservice/api/gps-device-exempt-reasons/application/${this.appId}/validate-pending-device/`, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            if (data.object) {
              this.imeiRequired = data.object.imei_required;
              this.imeiAssociated = data.object.imei_associated;

              resolve(data.object);
            }
          },
          (error: any) => {
            this.showErrorMessage();
            reject(error);
          }
        );
    });
  }

  onsave() {
    this.errorMessage = '';
    this.getImeiDetails();
  }

  showErrorMessage() {
    this.messageTitle = 'Sorry, we found a problem';
    this.messageMessageA = '';
    this.messageMessageC = 'Unfortunately we are unable to process your request at this moment. Please contact customer service at 1-888-509-5592 for more information.';
    this.messageMessageAClass = '';
    this.messageMessageBClass = 'mB20';
    this.messageMessageCClass = '';
    this.dialogClass = 'w600';
    $('#errorModalDialogUploadDocumentModalId' + this.appId).modal();

  }

  saveImeiNumber(imeiParameter: string) {
    return new Promise((resolve, reject) => {
      const body = {
        application_id: this.appId,
        imei: imeiParameter
      };
      this.connectionService.postV3Request(environment.gatewayV3 + 'gpsservice/api/device-management/device/synchronize', body, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            this.imeiRequired = false;
            this.imeiAssociated = true;
            resolve(data);
          },
          (error: any) => {
            this.showErrorMessage();
            reject(error);
          }
        );
    });
  }

  isIMEIEmpty() {
    if (this.imeiNumber === '') {
      return true;
    }
    return false;
  }

}
