import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationOwnerItem } from 'src/app/application/application/application.owner.item';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-add-owner-token',
    templateUrl: './add-owner-token.component.html',
    styleUrls: ['./add-owner-token.component.css']
})
export class AddOwnerTokenComponent implements OnInit {

    production: boolean = environment.production;

    id = 0;
    token: any;
    owner: ApplicationOwnerItem = new ApplicationOwnerItem(this.formBuilder);
    submitted = false;
    statesIssued: any[] = [];
    cities: any[] = [];
    states: any[] = [];
    county: string = '';
    maxAgeDate = new Date((new Date()).setFullYear(new Date().getFullYear() - 18));

    public modalId = 'AddCoSignerSuccess';
    public messageTitle = '';
    public messageMessageA = '';
    public messageMessageB = '';
    public messageMessageC = '';
    public messageMessageAClass = '';
    public messageMessageBClass = '';
    public messageMessageCClass = '';
    public dialogClass = '';

    public modalIdE = 'AddCoSignerError'
    public messageTitleE = '';
    public messageMessageAE = '';
    public messageMessageBE = '';
    public messageMessageCE = '';
    public messageMessageAClassE = '';
    public messageMessageBClassE = '';
    public messageMessageCClassE = '';
    public dialogClassE = '';

    constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private connectionService: ConnectionService,
        public router: Router
    ) { }

    ngOnInit(): void {
        //this.googleAnalyticsService.trackEvent("app-component", "app-add-owner-token");
        this.token = this.route.snapshot.queryParams.token;
        this.getAppInfo().then(
            () => this.getStates()
        );
    }

    getAppInfo() {
        return new Promise((resolve, reject) => {
            this.connectionService.getCustomRequest(environment.gateway + 'applicationNl', 'application/json', undefined, 'token', this.token).subscribe(
                (data: any) => {
                    this.id = data.object.id;
                    resolve(data);
                },
                (error: any) => {
                    resolve(error);
                }
            );
        });
    }

    getStates() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequestUnsecured(environment.gateway + 'utils/states', 'application/json').subscribe(
                (data: any) => {
                    this.statesIssued = data.object;
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    onContinueClick() {
        this.submitted = true;
        if (this.owner.form.invalid) {
            return;
        }
        this.addressvalidation().then(
            () => this.addOwner()
        );
    }

    addressvalidation() {
        return new Promise((resolve, reject) => {
            const address = {
                city: this.owner.oForm.city.value,
                county: this.county,
                state: this.owner.oForm.state.value,
                zipCode: this.owner.oForm.zipCode.value,
            };

            this.connectionService.postRequest(
                environment.gateway + 'addressvalidation/', address, 'application/json').subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (error: any) => {
                        this.messageTitleE = 'Sorry, we found a problem';
                        this.messageMessageAE = 'Unfortunately we are unable to process your request at this moment.';
                        this.messageMessageBE = error.error.msg;
                        this.messageMessageCE = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.messageMessageBClassE = 'mB20';
                        this.dialogClassE = 'w600';
                        $('#additionaAddCoSigner').modal('hide');
                        $('#errorModalDialogAddCoSignerError').modal();
                        reject(error);
                    }
                );
        });
    }

    addOwner() {
        return new Promise((resolve, reject) => {
            this.connectionService.postCustomRequest(
                `${environment.gateway}ownerNl/${this.id}`, this.owner.encode(), 'application/json', undefined, 'token', this.token).subscribe(
                    (data: any) => {
                        this.messageTitle = 'Owner Added';
                        this.messageMessageA = 'Owner added successfully.';
                        this.messageMessageB = '';
                        this.messageMessageC = '';
                        this.messageMessageBClass = '';
                        this.dialogClass = 'w450';
                        $('#additionaAddCoSigner').modal('hide');
                        $('#errorModalDialogAddCoSignerSuccess').modal();
                        resolve(data);
                    },
                    (error: any) => {
                        this.messageTitleE = 'Sorry, we found a problem';
                        this.messageMessageAE = 'Unfortunately we are unable to process your request at this moment.';
                        if (error.error.msg.split('Max value').length > 1) {
                            this.messageMessageBE = error.error.msg + ': ' + error.error.object.value + '%';
                        } else {
                            this.messageMessageBE = error.error.msg;
                        }
                        this.messageMessageCE = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.messageMessageBClassE = 'mB20';
                        this.dialogClassE = 'w600';
                        $('#additionaAddCoSigner').modal('hide');
                        $('#errorModalDialogAddCoSignerError').modal();
                        reject(error);
                    }

                );
        });
    }

    onZipCodeKeyUp(owner: any) {
        this.getZipCodeData(owner.zipCode.value).then(
            (data: any) => {
                if (data.object) {
                    this.cities = data.object.city;
                    this.states = this.statesIssued;
                    owner.state.setValue(data.object.state);
                    owner.city.setValue(data.object.city[0]);
                    data.object.cityCounties.forEach(element => {
                        if (element.city === owner.city.value) {
                            this.county = element.county;
                            return;
                        }
                    });
                } else {
                    this.cities = [];
                }
            },
            (error: any) => {
                this.cities = [];
            }
        );
    }

    getZipCodeData(zipCode: string) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'application/zipData/' + zipCode, 'application/json').subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    onCompleteInformationClick(owner: ApplicationOwnerItem) {
        owner.setDefaultData();
        this.onZipCodeKeyUp(owner.oForm);
        this.owner.oForm.authorize.setValue(true);
    }

    onCloseModal() {
        this.router.navigateByUrl('/home');
    }
}
