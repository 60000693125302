import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class V3IframeCommunicationService {
    private requestedAmount: number = 0;

    setRequestedAmount(amount: any) {
        this.requestedAmount = parseInt(amount + '');
    }

    get getRequestedAmount() {
        return this.requestedAmount;
    }

}