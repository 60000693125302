import { Component, OnInit, ContentChild, Output, EventEmitter, Input } from '@angular/core';
import { ApplicationWrapperHelperDirective } from '../directives/application-wrapper-helper.directive';
declare var $: any;

@Component({
  selector: 'app-modals-wrapper',
  templateUrl: './modals-wrapper.component.html',
  styleUrls: ['./modals-wrapper.component.css']
})
export class ModalsWrapperComponent implements OnInit {
  @ContentChild(ApplicationWrapperHelperDirective) content!: ApplicationWrapperHelperDirective;

  @Output() closeModal = new EventEmitter<void>();
  @Input() close = false;
  @Input() modalOff = false;


  constructor() { }

  ngOnInit(): void {

  }

  onCloseModal() {
    this.closeModal.emit();
  }

}
