export enum EBarStatus {
    COMPLETED = 'completed', // blue check
    NONE = 'none', // no check icon
    ERROR = 'error', // red check
    EMPTY = 'empty', // empty check
    DONE = 'done' // gray check
}

export type StatusBarItem = {
    order: number;
    name: string;
    status: EBarStatus;
}
export type StatusBarItemStyle = {
    txtColor: string;
    iconUrl: string;
    iconPos: 'start' | 'end' | 'none';
}

export interface IStatusBarProps {
    tlt?: string;
    vAlign?: 'center' | 'top' | 'bottom';
    hAlign?: 'center' | 'start' | 'end';
    statusList: StatusBarItem[];
    status: 'pending' | 'done' | 'none' | 'completed'
    extraOpts?: {
        extraStatus?: {
            [statusName: string]: (StatusBarItem & StatusBarItemStyle)
        },
        styleOpts?: {
            stepStatus?: { [statusKey: string]: StatusBarItemStyle }
            overallStatus?: {
                [status: string]: { color: string }
            }
        }
    }
}
