import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { Observable, Subscriber } from 'rxjs';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'src/app/auth/auth.service';
//import { threadId } from 'worker_threads';

@Component({
  selector: 'app-upload-driver-license',
  templateUrl: './upload-driver-license.component.html',
  styleUrls: ['./upload-driver-license.component.css']
})
export class UploadDriverLicenseComponent implements OnInit {
  public token: any;
  url: string = ''
  side: boolean;
  messageLeft: string;
  messageRight: string;

  // FILE UPLOADER
  hasDropOverLeft: boolean;
  hasDropOverRight: boolean;
  isErrorLeft: boolean;
  isErrorRight: boolean;

  frontUpload = new FileUploader({
    url: this.url,
    disableMultipart: true,
    maxFileSize: 1024 * 1024 * 10,
    allowedFileType: ['png', 'jpg', 'pdf', 'jpeg']
  });

  backUpload = new FileUploader({
    url: this.url,
    disableMultipart: true,
    maxFileSize: 1024 * 1024 * 10,
    allowedFileType: ['png', 'jpg', 'pdf', 'jpeg']

  });

  clickProccess: boolean = false;
  rightBase64: any;
  leftBase64: any;

  showModal: boolean = false;

  rightInputFile: any;
  leftInputFile: any;

  frontImageUrl: any;
  backImageUrl: any;

  constructor(
    private sanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService,
    private connectionService: ConnectionService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    this.googleAnalyticsService.dlTrackEvent('open', this.authService.vendorReferrerName, this.token);
  }

  handleFiles(files: FileList, side: boolean) {
    let file = files.item(0);
    if (file.type.includes('png') || file.type.includes('jpg') || file.type.includes('pdf') || file.type.includes('jpeg')) {
      if (file.size < 1024 * 1024 * 10) {
        this.convertToBase64(file, side);
        if (side) {
          this.isErrorRight = false;
          this.rightInputFile = file;
          this.backImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.rightInputFile));
          this.googleAnalyticsService.dlTrackEvent('dl-image-uploaded', this.authService.vendorReferrerName, this.token, 'back');
        } else {
          this.isErrorLeft = false;
          this.leftInputFile = file;
          this.frontImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.leftInputFile));
          this.googleAnalyticsService.dlTrackEvent('dl-image-uploaded', this.authService.vendorReferrerName, this.token, 'front');
        }
      } else {
        this.showErrorMessage("fileSize", side);
      }
    } else {
      this.showErrorMessage("fileType", side);
    }
  }

  convertToBase64(file: File, side: boolean) {
    const observable = new Observable((Subscriber: Subscriber<any>) => {
      this.readFile(file, Subscriber);
    });
    observable.subscribe(
      (data: any) => {
        if (side) {
          this.rightBase64 = data;
        } else {
          this.leftBase64 = data;
        }

      },
      (error) => {
        this.showErrorMessage("formatType", side);
        if (side) {
          this.rightInputFile = null;
          this.backImageUrl = '';
          this.googleAnalyticsService.dlTrackEvent('dl-image-error', this.authService.vendorReferrerName, this.token, 'back', 'formatError');
        } else {
          this.leftInputFile = null;
          this.frontImageUrl = '';
          this.googleAnalyticsService.dlTrackEvent('dl-image-error', this.authService.vendorReferrerName, this.token, 'front', 'formatError');
        }
      });
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  onContinueClick() {
    if (this.clickProccess || (!this.leftBase64 || !this.rightBase64)) {
      return;
    }
    this.clickProccess = true;
    const data = [
      { name: this.leftInputFile.name, documentB: this.leftBase64.substr(this.leftBase64.indexOf(',') + 1) },
      { name: this.rightInputFile.name, documentB: this.rightBase64.substr(this.rightBase64.indexOf(',') + 1) }
    ];
    this.googleAnalyticsService.dlTrackEvent('dl-submition-continue', this.authService.vendorReferrerName, this.token, JSON.stringify({nameLeft: this.leftInputFile.name, nameRight: this.rightInputFile.name}));
    this.connectionService.postRequestWithToken(
      environment.gateway + 'saveDocument/upload-license', data, 'application/json', this.token).subscribe(
        (response: any) => {
          this.googleAnalyticsService.dlTrackEvent('dl-submition-response', this.authService.vendorReferrerName, this.token, JSON.stringify(response));
          if (response.object) {
            this.router.navigate(['../upload-license-success'], { relativeTo: this.activatedRoute, queryParamsHandling: "merge", state: { partnerName: response.object.partnerName, docFee: response.object.docFee, accountNumber: response.object.accountNumber, applicationId: response.object.applicationId } });
          } else {
            this.googleAnalyticsService.dlTrackEvent('dl-submition-error', this.authService.vendorReferrerName, this.token, JSON.stringify(response));
            this.router.navigate(['../upload-license-error'], { queryParamsHandling: "merge" });
          }
        },
        (error: any) => {
          this.googleAnalyticsService.dlTrackEvent('dl-submition-error', this.authService.vendorReferrerName, this.token, JSON.stringify(error));
          this.router.navigate(['../upload-license-error'], { queryParamsHandling: "merge" });
        }
      );
  }

  removeLeftFiles() {
    this.leftInputFile = null;
    this.frontImageUrl = '';
    this.leftBase64 = null;
    this.googleAnalyticsService.dlTrackEvent('dl-image-removed', this.authService.vendorReferrerName, this.token, 'front')
  }
  removeRightFiles() {
    this.rightInputFile = null;
    this.backImageUrl = '';
    this.rightBase64 = null;
    this.googleAnalyticsService.dlTrackEvent('dl-image-removed', this.authService.vendorReferrerName, this.token, 'back')
  }

  fileOverBase(e: any): void {
    this.hasDropOverLeft = e;
  }

  fileOverBaseR(e: any): void {
    this.hasDropOverRight = e;
  }

  showErrorMessage(filter: string, side) {
    var msg: string = '';
    switch (filter) {
      case "fileSize":
        msg = "File size too large. Must be 10MB or less.";
        break;
      case "fileType":
        msg = "Invalid File Type. JPG, PNG or PDF only.";
        break;
      case "formatType":
        msg = "Uploaded File is not supported.";
      default:
        msg = '';
        break;
    }
    if (side) {
      this.messageRight = msg;
      this.rightBase64 = null;
      this.isErrorRight = true;
      this.googleAnalyticsService.dlTrackEvent('dl-image-error', this.authService.vendorReferrerName, this.token, 'back', filter);
    } else {
      this.messageLeft = msg;
      this.leftBase64 = null;
      this.isErrorLeft = true;
      this.googleAnalyticsService.dlTrackEvent('dl-image-error', this.authService.vendorReferrerName, this.token, 'front', filter);
    }
  }
}
