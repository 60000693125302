import { TermToolCondition } from "./term-tool.condition";

export class TermToolItem {
    id: number;
    name: string;
    description: string;
    active: boolean;
    logic: string;
    minimumTerm: number;
    middleTerm: number;
    maxTerm: number;
    dataRobotModelId: number;
    logicType: string;
    priority: number;
    conditions: TermToolCondition[];
    datarobotName: string;

    constructor(data: any, attributes: any, opperators: any, datarobotmodels: any) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.active = data.active;
        this.logic = data.logic;
        this.minimumTerm = data.minimumTerm;
        this.middleTerm = data.middleTerm;
        this.maxTerm = data.maxTerm;
        this.dataRobotModelId = data.dataRobotModelId;
        this.logicType = data.logicType;
        this.priority = data.priority;
        this.conditions = [];

        datarobotmodels.forEach((element: any) => {
            if (element.id === this.dataRobotModelId) {
                if (this.dataRobotModelId === 0) {
                    this.datarobotName = "All Models";
                } else {
                    this.datarobotName = element.description;
                }
            }
        });

        data.conditions.forEach((element: any) => {
            this.conditions.push(new TermToolCondition(element, attributes, opperators));
        });
    }
}