import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../v3/services';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { Experimental } from 'src/app/utils/shared';
import { ErrorListService } from 'src/app/shared/services';

@Component({
    selector: 'app-verifying-page',
    templateUrl: './verifying-page.component.html',
    styleUrls: ['./verifying-page.component.css']
})
export class VerifyingPageComponent implements OnInit {
    private encodeKey: string;
    private appId: number;
    private accessToken: string;
    private token: string;

    constructor(private applicationService: ApplicationService,
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        private errorListService: ErrorListService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private connectionService: ConnectionService
    ) {
        this.encodeKey = this.activatedRoute.snapshot.queryParams.key;
        //error needed on missing key
        if (!this.encodeKey) {
          this.googleAnalyticsService.trackV3Event('verifying-page', this.authService.vendorReferrerName, "verifying-page", "Mising encodeKey");
          this.router.navigate(['/token-error-v3'], { state: { token: this.token, data: 'verifying-page' } });
          return;
      }
    }

    ngOnInit(): void {
        this.googleAnalyticsService.setV3Label('app-resume');
        this.getToken();
    }

    getToken() {
        this.connectionService.getV3Request(`${environment.gatewayV3}prefillservice/api/open/decodeKey?encodedKey=${this.encodeKey}`, 'application/json').subscribe(
            (data: any) => {
                if(!data || !data.object) {
                    this.router.navigate(['/token-error-v3'], { queryParamsHandling: "merge", state: { endpoint: "decodeKey-error-" + data.msg, start: "resume", token: this.encodeKey } });
                    return;
                }
                //analytics needed to store response
                this.googleAnalyticsService.trackV3Event('verifying-page-response', this.authService.vendorReferrerName, JSON.stringify(data));
                this.appId = data.object.applicationId;
                this.applicationService.applicationId = data.object.applicationId;
                this.googleAnalyticsService.setApplicationId(this.appId);
                this.accessToken = data.object.accessToken;
                this.applicationService.accessToken = this.accessToken;
                this.token = data.object.vendorToken;
                //error needed if some data is missing
                if(!this.appId || !this.accessToken || !this.token ){
                    this.errorListService.redirectToV3Error(data, 'decodeKey-missing', 'resume');
                } else{
                  this.authForRose();
                }
            },
            (error: any) => {
                //error needed
                this.errorListService.redirectToV3Error(error, 'decodeKey-error', 'resume');
            }
        );
    }

    authForRose() {
        this.connectionService.postV3Request(`${environment.gatewayV3}prefillservice/api/continueFlowRose?appId=${this.appId}`, {}, 'application/json', this.accessToken, this.token).subscribe(
            (data: any) => {
                let nextStep: string = this.applicationService.setApplicationData(data.object);
                Experimental.updateExperimental('B');

                switch (nextStep) {
                    case "approval":
                        this.googleAnalyticsService.trackV3Event('rose-started-approved-flow', this.authService.vendorReferrerName, JSON.stringify(data));
                        this.router.navigate(['/inlineapp/approval'], { queryParams: { token: this.token, key: this.encodeKey }});
                        break;
                    case "finicity":
                        this.googleAnalyticsService.trackV3Event('rose-started-finicity-flow', this.authService.vendorReferrerName, JSON.stringify(data));
                        this.router.navigate(['/inlineapp/bank-process/bank-verification'], { queryParams: { token: this.token, key: this.encodeKey }, state: { appId: this.applicationService.applicationId, accessToken: this.accessToken, token: this.token, finicityURL: data.object.finicityUrl, started: 'resume' }});
                        break;
                    case "bankProcess":
                        this.googleAnalyticsService.trackV3Event('bank-process-flow', this.authService.vendorReferrerName, JSON.stringify(data));
                        this.router.navigate(['/inlineapp/bank-process/bank-information'], { queryParams: { token: this.token, key: this.encodeKey }, state: { appId: this.applicationService.applicationId, accessToken: this.accessToken, token: this.token, started: 'resume' }});
                        break;
                    case "new":
                        this.googleAnalyticsService.trackV3Event('otp-new-application', this.authService.vendorReferrerName, JSON.stringify(data));
                        if (data && data.object && data.object.maxApproved) {
                            this.router.navigate(['/inlineapp'], { queryParams: { token: this.token }});
                            break;
                        }
                    default:
                        this.router.navigate(['/decline-error'], {queryParamsHandling: "merge", state: { endpoint: "continue-flow-rose-error"}});
                        break;
                }
            },
            (error: any) => {
                this.errorListService.redirectToV3Error(error, 'continueFlowRose-error', 'resume');
            }
        )
    }
}
