import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';

declare var $: any;

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent implements OnInit {

    @Input() title: string;
    @Input() modalId: string;
    @Input() messageA: string;
    @Input() messageB: string;
    @Input() messageC: string;
    @Input() dialogClass: string;
    @Input() messageAClass: string;
    @Input() messageBClass: string;
    @Input() messageCClass: string;
    @Input() props: any;
    @Input() mdlStatus$: BehaviorSubject<boolean | null> = null;

    @Output() closeModal = new EventEmitter<void>();

    constructor(private googleAnalyticsService: GoogleAnalyticsService, private cd: ChangeDetectorRef) { }

    ngOnInit() {
        this.mdlStatus$ &&
            this.mdlStatus$.subscribe(mdlStatus => {
                if (typeof mdlStatus !== 'boolean') return;
                if (mdlStatus) $(`#errorModalDialog${this.modalId}`).modal('show');
                else $(`#errorModalDialog${this.modalId}`).modal('hide');
                // this.cd.markForCheck();
            })
        this.googleAnalyticsService.trackEvent("app-component", "app-error-dialog");
    }

    onCloseModal() {
        if (this.closeModal) {
            this.closeModal.emit();
            // this.isMdlOpen.next(false)
        }
    }

    getProps(): any {
        return this.props;
    }

}
