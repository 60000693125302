<div class="login-header">
	<img src="/assets/images/click-lease-logo-svg.svg" />
</div>
<div class="login-form-container">
	<div class="login-form">
		<div class="form-header">
			<h3 class="font-mont-bold">LOGIN</h3>
			<img src="/assets/images/icons/login_user.svg" />
			<div class="clear"></div>
		</div>
		<form>
			<div class="form-group">
				<label class="font-mont-book" for="email">Email</label>
				<input type="email" id="email" name="email" ngModel class="form-control" #emailInput>
			</div>
			<div class="form-group">
				<label class="font-mont-book" for="password">Password</label>
				<input type="password" id="password" name="password" ngModel class="form-control" #passwordInput>
			</div>
			<div class="bottom-buttons">
				<a data-toggle="modal" data-target="#forgotPasswordModal" class="font-mont-book">Forgot password?</a>
				<button class="btn btn-primary float-right" id="onSignin" type="submit" (click)="onSignin()">NEXT</button>
			</div>
		</form>
		<div class="footer-links">
		</div>
	</div>
</div>
<div class="login-footer">
	<a routerLink="/support">Support</a>
	<a routerLink="/privacy">Privacy</a>
	<a routerLink="/about">About</a>
	<a routerLink="/terms">Terms</a>
</div>

<!-- Login error modal -->
<div class="modal fade" id="loginErrorModal" role="dialog">
	<div class="modal-dialog small">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<img src="/assets/images/icons/close_b.svg" />
					CLOSE WINDOW
				</button>
			</div>
			<div class="modal-body">
				<h5 class="modal-title font-mont-bold">Login Error</h5>
				<div class="dialog-message">
					{{loginErrorMsg1}}
					<a href="mailto:{{loginErrorMsg2}}" class="">{{loginErrorMsg2}}</a>
				</div>
				<div class="clear"></div>
				<div class="row text-right">
					<div class="col-12">
						<button type="button" class="btn btn-primary dialog-button close-button"
							data-dismiss="modal">OK</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Forgot password modal -->
<div class="modal fade" data-backdrop="static" id="forgotPasswordModal" role="dialog">
	<div class="modal-dialog small">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" (click)="inputClear()" class="close" data-dismiss="modal" aria-label="Close" (click)="inputClear()">
					<img src="/assets/images/icons/close_b.svg" />
					CLOSE WINDOW
				</button>
			</div>
			<div class="modal-body">
				<h5 class="modal-title font-mont-bold">Forgot Password</h5>
				<form>
					<div class="form-group col-md-12">
						<label for="mEmailInput">Email:</label>
						<input type="email" id="mEmailInput" name="mEmailInput" ngModel class="form-control" #mEmailInput
						[formControl]="emailCtrl" placeholder="Email">
					</div>	
					<div class="Form-Validation" *ngIf="emailCtrl.errors && emailCtrl.dirty">
						<small *ngIf="emailCtrl.hasError('required')">Email Address is required.</small>
						<small *ngIf="emailCtrl.hasError('email')">Invalid email address.</small>
					</div>	
				</form>
				<div class="clear"></div>
				<div class="row text-right">
					<div class="col-12">
						<button type="button" class="btn btn-secondary dialog-button close-button"
						(click)="inputClear()" data-dismiss="modal">CANCEL</button>
						<button type="button" class="btn btn-primary dialog-button close-button"
						[disabled]="emailCtrl.invalid"
							(click)="onForgotPassword()" data-dismiss="modal">SEND</button>
					</div>
				</div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</div>

<!-- Error sending email password modal -->
<div class="modal fade"  id="forgotPasswordErrorModal" role="dialog">
	<div class="modal-dialog small">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button"  class="close" data-dismiss="modal" aria-label="Close">
					<img src="/assets/images/icons/close_b.svg" />
					CLOSE WINDOW
				</button>
			</div>
			<div class="modal-body">
				<h5 class="modal-title font-mont-bold">Forgot Password Error</h5>
				<div class="dialog-message">Error sending email.</div>
				<div class="clear"></div>
				<div class="row text-right">
					<div class="col-12">
						<button type="button" class="btn btn-primary dialog-button close-button"
						(click)="inputClear()" data-dismiss="modal">OK</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Email password sent successfully modal -->
<div class="modal fade" data-backdrop="static" id="forgotPasswordSuccessModal" role="dialog">
	<div class="modal-dialog small">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" (click)="inputClear()" class="close" data-dismiss="modal" aria-label="Close">
					<img src="/assets/images/icons/close_b.svg" />
					CLOSE WINDOW
				</button>
			</div>
			<div class="modal-body">
				<h5 class="modal-title font-mont-bold">Forgot Password Sent</h5>
				<div class="dialog-message">The reset password email was sent. Please review your email.</div>
				<div class="clear"></div>
				<div class="row text-right">
					<div class="col-12">
						<button type="button" class="btn btn-primary dialog-button close-button"
						(click)="inputClear()" data-dismiss="modal">OK</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>