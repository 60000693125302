import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { ArrayLikePipe } from '../shared/pipes/array.like.pipe';
import { HeaderSendApplicationDialogComponent } from './header-send-application-dialog/header-send-application-dialog.component';
import { HeaderSendUpsellApplicationComponent } from './header-send-upsell-application/header-send-upsell-application.component';
import { HeaderVendorBrockerSelectDialogComponent } from './header-vendor-brocker-select-dialog/header-vendor-brocker-select-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ApplicationRoutingModule } from './application-routing.module';
import { HeaderReportDialogComponent } from './header-report-dialog/header-report-dialog.component';


export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderSendApplicationDialogComponent,
        HeaderSendUpsellApplicationComponent,
        HeaderVendorBrockerSelectDialogComponent,
        HeaderReportDialogComponent
    ],
    imports: [
        CommonModule,
        ApplicationRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        NgxMaskModule.forRoot(options)
    ],
    exports: [
        HeaderComponent
    ],
    providers: [
    ]
})
export class HeaderModule { }
