import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-submit-errors',
  templateUrl: './submit-errors.component.html',
  styleUrls: ['./submit-errors.component.css']
})
export class SubmitErrorsComponent implements OnInit {

  token: any;
  production: boolean = environment.production;
  applicationId: number = 0;

  errorTitle = '';
  errorId = 'AppE';
  errorMessageA = '';
  errorMessageB = '';
  errorMessageC = '';
  errorMessageBClass = '';

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.token = this.authService.getQueryToken;
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
        this.applicationId = navigation.extras.state.applicationId;
    }
  }

  ngOnInit(): void {
  }

  sendFinicityVerifyBankAccountEmail() {
    return new Promise((resolve, reject) => {
        this.connectionService.getCustomRequest(`${environment.gateway}application/finicity-link/${this.applicationId}`, 'application/json', `newEmail=${null}`, this.token ? 'token' : null, this.token).subscribe(
            (data: any) => {
                this.onFinicityLinkSentSuccess();
                resolve(data);
            },
            (error: any) => {
                this.onFinicityLinkSentError(error.error ? error.error.msg : 'Bank verification link request error');
                reject(error);
            }
        );
    });
  }

  onFinicityLinkSentError(error: any) {
      this.errorTitle = 'Sorry, we found a problem';
      this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
      this.errorMessageB = error;
      this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
      this.errorMessageBClass = 'mB20';
      $('#errorModalDialogAppE').modal();
  }

  onFinicityLinkSentSuccess() {
      this.errorTitle = 'Email sent';
      this.errorMessageA = 'An email to verify your bank account was sent.';
      this.errorMessageB = '';
      this.errorMessageC = '';
      this.errorMessageBClass = '';
      $('#errorModalDialogAppE').modal();
  }

}
