import { Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { EBarStatus, IStatusBarProps, StatusBarItem } from 'src/app/home/home/shared/UI/ept-status-bar/status-bar-types';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';

interface IBankSteps {
    name: string,
    desc: string,
}

type MdlType = 'msg-err' | 'sms' | 'email';

@Component({
    selector: 'app-bank-verification',
    templateUrl: './bank-verification.component.html',
    styleUrls: ['./bank-verification.component.css', '../ept-ui-elements.css']
})
export class BankVerificationComponent implements OnInit {

    @Input()
    processStatus: any = {};

    @Input()
    appOpts: { appSource?: 'rose' | 'edgar', riskClassification?: 'high' | 'low' | 'other', [k: string]: any } = {}

    @Input()
    application: any;

    @Output()
    resendLink: EventEmitter<any> = new EventEmitter();

    statusBarProps: IStatusBarProps = {
        statusList: [],
        status: 'none'
    };
    steps: IBankSteps[] = [];

    phoneNumber: string = '';
    ownerEmail: string = '';

    mdlBodyTlt = '';
    mdlBodyDesc = '';
    supportMsg = 'If that doesn\'t work, please contact our support team at (888) 509-5592';
    actionDesc: string = '';
    isMdlOpen = false;
    mdlBodyType: MdlType;
    initMdlBody: MdlType;

    constructor(private connectionService: ConnectionService, private authService: AuthService) { }

    ngOnInit(): void {
        this.statusBarProps = this.mapStatusBarProps(this.processStatus.vodStatusProcess);
        //
        this.steps.push({
            name: 'Bank Verification Status',
            desc: this.processStatus.vodStatusProcess?.bullet || null,
        });
        //
        this.actionDesc = this.processStatus.vodStatusProcess?.bankApproved
            ? 'Bank Verification Approved.'
            : `${this.processStatus?.sentence} ${this.processStatus?.sentence2 ?? ''}`;
        this.mdlBodyType = this.appOpts.riskClassification === 'other' ? 'email' : (this.appOpts.appSource === 'edgar' ? 'sms' : 'email');
        this.initMdlBody = this.mdlBodyType;
        // console.log('BankVerificationComponent: ', this.mdlBody);
        this.phoneNumber = this.getOwner().mobilePhoneNumber || '';
        this.ownerEmail = this.getOwner().email || '';
    }

    mapStatusBarProps(pProcessStatus: any): IStatusBarProps {
        //* If bankVerificationProcess is not found, it will stop method execution
        if (!this.processStatus.vodStatusProcess || !this.processStatus.vodStatusProcess.statusBar) return;
        const {
            statusBar = [],
            customerRequiredFlag = 'NONE',
            bankApproved = false,
            idApproved = this.appOpts?.mobVerify ?? false
        } = pProcessStatus;
        const props: IStatusBarProps = {
            tlt: this.getStatusBarTlt() + ' Verification Status',
            status: bankApproved ? 'completed' : 'pending',
            statusList: statusBar.map((s, i) => ({
                order: +s.order,
                name: `${s.textA} ${s?.textB ?? ''}`,
                status: (() => {
                    let resStatus = !s.completed ? EBarStatus.EMPTY : EBarStatus.COMPLETED;
                    if (!s.completed) return resStatus;
                    const crrStatusName = `${s.textA} ${s?.textB ?? ''}`.toLowerCase();
                    if (this.appOpts.hasOwnProperty('mobVerify') && crrStatusName.includes('identification'))
                        resStatus = idApproved ? EBarStatus.COMPLETED : EBarStatus.ERROR
                    if (crrStatusName.includes('bank'))
                        resStatus = bankApproved ? EBarStatus.COMPLETED : EBarStatus.ERROR;
                    return resStatus;
                })()
            }) as StatusBarItem) || statusBar
        }
        return props;
    }

    changeMdlStatus(mdlStatus: boolean) {
        this.isMdlOpen = mdlStatus;
        if (this.mdlBodyType.includes('msg-')) setTimeout(() => this.mdlBodyType = this.initMdlBody, 400);
    }

    sendBankVerificationEmailLink() {
        this.connectionService.getRequest(`${environment.gateway}application/finicity-link/${this.application.id}`, 'application/json')
            .subscribe(
                res => {
                    this.mdlBodyType = 'email';
                    this.mdlBodyTlt = 'We just sent another link.';
                    this.mdlBodyDesc = 'Lets make sure you have the right email. Right now we have:';
                    this.isMdlOpen = true;
                },
                err => {
                    this.mdlBodyType = 'msg-err';
                    this.mdlBodyTlt = 'Something went wrong';
                    this.mdlBodyDesc = 'We are not able to send the link on this moment.';
                    this.isMdlOpen = true;
                }
            );
        return;
    }

    sendBankVerificationSmSLink() {
        let endPoint = `${environment.gatewayV3}prefillservice/api/reSendVerificationPPFlowSms?isCommSender=true&appId=${this.application.id}${environment.development ? '&testPhone=7262085668' : ''}`
        this.connectionService.getV3Request(endPoint, 'application/json', this.authService.keyCloakToken)
            .subscribe(
                res => {
                    this.mdlBodyType = 'sms';
                    this.mdlBodyTlt = 'We just sent another link.';
                    this.mdlBodyDesc = 'Lets make sure you have the right phone number. Right now we have:';
                    this.isMdlOpen = true;
                },
                err => {
                    this.mdlBodyType = 'msg-err';
                    this.mdlBodyTlt = 'Something went wrong';
                    this.mdlBodyDesc = 'We are not able to send the link on this moment.';
                    this.isMdlOpen = true;
                }
            );
        return;
    }

    getOwner() {
        return this.application.appSummary.lessee?.owners[0];
    }

    getStageName(stagePos: number, pApp: any) {
        let stageFound = pApp?.appSummary;
        if (!stageFound || !stageFound.applicationProcess || !stageFound.applicationProcess.length || stagePos < 0) stageFound = null;
        else stageFound = stageFound.applicationProcess[stagePos];
        return stageFound;
    }

    getStatusBarTlt() {
        const stageObj = this.getStageName(1, this.application);
        const stageLbl = ['bank', 'i.d'].find(lbl => stageObj?.textA.toLowerCase().includes(lbl));
        if (!stageObj || !(stageObj.user.toLowerCase() === 'customer') || !stageLbl) return '';
        return stageObj.textA;
    }

    getBoldMeg(appStage: string, fullMsg: string, mdlType?: MdlType) {
        let msgRes = '';
        if (appStage.toLowerCase() === 'declined') {
            const idx = fullMsg.toLowerCase().indexOf('you can try again');
            idx > 0 && (msgRes = fullMsg.substring(idx));
        }
        if ((mdlType === 'email' || mdlType === 'sms') || appStage.toLowerCase().includes('approved')) {
            const idx = fullMsg.toLowerCase().indexOf('you cannot send');
            idx > 0 && (msgRes = fullMsg.substring(idx));
        };
        return msgRes;
    }

    get actionTlt(): string {
        return 'Customer Action Required: Complete Verification Steps';
    }

    get isHighRisk(): boolean {
        const highRiskProp = this?.appOpts?.isHighRisk ?? false;
        return highRiskProp && ((typeof highRiskProp === 'string' && highRiskProp === 'true') || highRiskProp)
    }

    get isBankApproved(): boolean {
        return this.processStatus.vodStatusProcess.bankApproved;
    }
}
