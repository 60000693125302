import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-report-dialog',
  templateUrl: './header-report-dialog.component.html',
  styleUrls: ['./header-report-dialog.component.css']
})


export class HeaderReportDialogComponent implements OnInit {

  /* Checkbox and radiobuttons */
  public gpsCheckBoxChecked = false;
  public gpsXSL = null;
  public gpsCSV = null;

  public feedbackCheckBoxChecked = false;
  public feedbackXSL = null;
  public feedbackCSV = null;
  //Reports coming fron BE
  private feedbackReport: any[];
  private gpsReport: any[];
  /* Work around required to apply the underline and blue color style for hyperlink text on ExcelJS  */
  private linkStyle = {
    underline: true,
    color: { argb: 'FF0000FF' },
  };

  constructor(private connectionService: ConnectionService,
    private authService: AuthService) {
  }

  ngOnInit() {
  }

  gpsChecked() {
    this.gpsCheckBoxChecked = !this.gpsCheckBoxChecked;
    if (this.gpsCheckBoxChecked) {
      this.gpsXSL = "gpsXSL";
      this.gpsCSV = null;
    } else {
      this.gpsXSL = null;
      this.gpsCSV = null;
    }
  }

  feedbackChecked() {
    this.feedbackCheckBoxChecked = !this.feedbackCheckBoxChecked;
    if (this.feedbackCheckBoxChecked) {
      this.feedbackXSL = "feedbackXSL";
      this.feedbackCSV = null;
    } else {
      this.feedbackXSL = null;
      this.feedbackCSV = null;
    }

  }

  gpsFormatsChecked(e) {
    this.gpsCheckBoxChecked = true;
    if (e.target.value === 'gpsXSL') {
      this.gpsCSV = null;
    } else {
      this.gpsXSL = null;
    }
  }

  feedbackFormatsChecked(e) {
    this.feedbackCheckBoxChecked = true;
    if (e.target.value === 'feedbackXSL') {
      this.feedbackCSV = null;
    } else {
      this.feedbackXSL = null;
    }
  }

  cleanOptions() {
    this.gpsCheckBoxChecked = false;
    this.gpsXSL = null;
    this.gpsCSV = null;

    this.feedbackCheckBoxChecked = false;
    this.feedbackXSL = false;
    this.feedbackCSV = false;
  }

  closeDialog() {
    this.cleanOptions();
  }

  somethingToExport() {
    if (this.gpsCheckBoxChecked === true || this.feedbackCheckBoxChecked === true) {
      return true;
    }
    return false;
  }

  exportSelectedReports() {
    if (this.feedbackCheckBoxChecked) {
      this.getFeedbackReport();
    }
    if (this.gpsCheckBoxChecked) {
      this.getGPSReport();
    }
  }

  getFeedbackReport() {
    return new Promise((resolve, reject) => {
      const body = {
        start: null,
        end: null
      };
      this.connectionService.postV3Request(environment.gatewayV3 + 'feedbackservice/api/feedbacks/export', body, 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            this.feedbackReport = data;
            this.createFeedbackFile();
            resolve(data);
          },
          (error: any) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  createFeedbackFile() {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Feedback');
    const linkCells = [];

    worksheet.columns = [
      { header: 'Email Associated', key: 'emailAssociated', width: 35 },
      { header: 'Feedback Type', key: 'feedbackType', width: 25 },
      { header: 'ID', key: 'id', width: 10 },
      { header: 'Time Stamp', key: 'timeStamp', width: 30 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Comment', key: 'comment', width: 40 },
      { header: 'Screenshot', key: 'screenshot', width: 90 },
      { header: 'Contact', key: 'contact', width: 10 }
    ];
    let dataStartRowNumber = 1;
    //Iterate the report data
    this.feedbackReport.map((fb) => {
      dataStartRowNumber += 1;
      linkCells.push({
        row: dataStartRowNumber,
        column: 7,
      });
      worksheet.addRow(
        {
          emailAssociated: fb.salesPerson.email,
          feedbackType: fb.feedbackType.title,
          id: fb.id,
          timeStamp: this.getCreatedDateFormatAsLocale(fb),
          name: this.getFullName(fb),
          comment: fb.description,
          screenshot: fb.attachmentUrlList.join('\r\n'),
          contact: fb.contactme ? 'Yes' : 'No'
        }, "n");
    });

    if (this.feedbackXSL) {
      worksheet.getColumn(7).alignment = { wrapText: true };
      // Apply our link style to the array of cells we prepared.
      this.applyHyperLinkStyle(worksheet, linkCells);

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'FeedbackReport.xlsx');
      });
    }

    if (this.feedbackCSV) {
      workbook.csv.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'text/csv' });
        fs.saveAs(blob, 'FeedbackReport.csv');
      })
    }
  }

  getGPSReport() {
    return new Promise((resolve, reject) => {

      this.connectionService.getV3Request(environment.gatewayV3 + 'gpsservice/api/application/export', 'application/json', this.authService.keyCloakToken)
        .subscribe(
          (data: any) => {
            this.gpsReport = data;
            this.createGPSFile();
            resolve(data);
          },
          (error: any) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  createGPSFile() {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('IMEI logs');
    worksheet.columns = [
      { header: 'Opportunity Name', key: 'opportunityName', width: 35 },
      { header: 'Vendor name', key: 'vendorName', width: 25 },
      { header: 'Vendor ID', key: 'vendorId', width: 10 },
      { header: 'Created Date', key: 'createdDate', width: 15 },
      { header: 'Funding Date', key: 'fundingDate', width: 15 },
      { header: 'GPS Active', key: 'gpsActive', width: 10 },
      { header: 'GPS Provider', key: 'gpsProvider', width: 15 },
      { header: 'GPS IMEI Number', key: 'gpsImeiNumber', width: 20 },
      { header: 'GPS Unit Cost', key: 'gpsUnitCost', width: 15 },
      { header: 'IMEI Selector Used', key: 'gpsImeiSelectorUsed', width: 10 }
    ];

    //Iterate the report data
    this.gpsReport.map((repItem) => {
      worksheet.addRow(
        {
          opportunityName: repItem.customerApplicationId,
          vendorName: repItem.vendor.dbaName,
          vendorId: repItem.vendor.id,
          createdDate: this.getSimpleDateFormatAsLocale(repItem.createdDate),
          fundingDate: this.getSimpleDateFormatAsLocale(repItem.fundedDate),
          gpsActive: repItem.gpsActive ? 'Yes' : 'No',
          gpsProvider: repItem.gpsProvider,
          gpsImeiNumber: repItem.imei,
          gpsUnitCost: repItem.gpsUnitCost,
          gpsImeiSelectorUsed: repItem.imeiSelectorUsed ? 'Yes' : 'No',
        }, "n");
    });

    if (this.gpsXSL != null) {
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'IMEILogs.xlsx');
      });
    }

    if (this.gpsCSV != null) {
      workbook.csv.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'text/csv' });
        fs.saveAs(blob, 'IMEILogs.csv');
      })
    }
  }

  getFullName(feedbackItem) {
    return feedbackItem.salesPerson.firstName.concat(" ", feedbackItem.salesPerson.middleName ? feedbackItem.salesPerson.middleName + ' ' : '').concat(feedbackItem.salesPerson.lastName)
  }

  getCreatedDateFormatAsLocale(feedbackReportItem) {
    const createdDate = new Date(feedbackReportItem.createdDate);
    return createdDate.toLocaleDateString().concat(' at ', createdDate.toLocaleTimeString())
  }

  getSimpleDateFormatAsLocale(gpsReportItemDate) {
    const convertedDate = new Date(gpsReportItemDate);
    return convertedDate.toLocaleDateString();
  }

  applyHyperLinkStyle(sheet, cells) {
    cells.forEach(cellInfo => {
      const cell = sheet.getRow(cellInfo.row).getCell(cellInfo.column);
      if (cell) {
        cell.font = this.linkStyle;
      }
    });
  }
}


