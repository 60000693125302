import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-contract-status',
    templateUrl: './contract-status.component.html',
    styleUrls: ['./contract-status.component.css']
})
export class ContractStatusComponent implements OnInit {
    @Input() currentStep: any = '';
    @Input() nextStep: any = '';
    @Input() processStatus: any = '';

    public useBullets: boolean = false;
    public dlStatusUR: boolean = false;
    public bullet: string = '';
    public title: string = 'Customer Action Required'

    constructor() { }

    ngOnInit() {
        let barAmount: number = 0;
        if (this.processStatus.contractStatusProcess.bullet) {
            barAmount += 1;
        }
        if (this.processStatus.dlStatusProcess.bullet) {
            barAmount += 1;
        }
        if (barAmount > 1) {
            this.useBullets = true;
        } else {
            this.useBullets = false;
        }

        if (this.processStatus.dlStatusProcess && this.processStatus.dlStatusProcess.statusBar) {
            if (this.processStatus.dlStatusProcess.statusBar[0] &&
                this.processStatus.dlStatusProcess.statusBar[1] &&
                this.processStatus.dlStatusProcess.statusBar[2] &&
                this.processStatus.dlStatusProcess.statusBar[0].completed &&
                this.processStatus.dlStatusProcess.statusBar[1].completed &&
                !this.processStatus.dlStatusProcess.statusBar[2].completed) {
                this.dlStatusUR = true;
                this.title = 'Driver’s License is Under Review.'
            }
        }
    }

    onFileAdded(file: any) { }

    get titleClass() {
        if (this.dlStatusUR) {
            return 'title-blue';
        } else if (
            this.processStatus.contractStatusProcess.customerRequiredFlag == 'RED' ||
            this.processStatus.dlStatusProcess.customerRequiredFlag == 'RED'
        ) {
            return 'title-red';
        } else if (
            this.processStatus.contractStatusProcess.customerRequiredFlag == 'GRAY' ||
            this.processStatus.dlStatusProcess.customerRequiredFlag == 'GRAY'
        ) {
            return 'title-gray';
        } else {
            return 'title-none';
        }
    }

    get showCustomerAccionRequired() {
        //* check if a customer action is required. it is if customerRequiredFlag = 'RED'
        let show = this.processStatus.contractStatusProcess.customerRequiredFlag != 'GRAY' && this.processStatus.contractStatusProcess.customerRequiredFlag != 'NONE';
        show = show || this.processStatus.dlStatusProcess.customerRequiredFlag != 'GRAY' && this.processStatus.dlStatusProcess.customerRequiredFlag != 'NONE';
        //* shows an action required if it meets both  it's not full completed and it's not declined
        show = show && !this.processStatus.info.processStatusComplete && !this.processStatus.info.declineWithoutOptions;
        return show;
    }

}
