import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateValidator, EmailValidator } from 'src/app/utils/shared';
import { IBusinessForm } from 'src/app/application/v3/model';

@Injectable({
    providedIn: 'root'
})
export class BusinessFormService {
    readonly form = this.formBuilder.group({
        businessAddress: ['', Validators.required],
        businessEmail: ['', [Validators.required, Validators.email, EmailValidator()]],
        businessName: ['', Validators.required],
        businessPhone: ['', Validators.required],
        businessStartDate: ['', [Validators.required, DateValidator()]],
        businessType: ['', Validators.required],
        city: ['', Validators.required],
        county: ['', Validators.required],
        DBA: ['', Validators.required],
        percentOwnership: ['', [Validators.required, Validators.max(100), Validators.min(51)]],
        state: ['', Validators.required],
        termsService: [true, [Validators.requiredTrue]],
        zipCode: ['', Validators.required],
    });

    constructor(private formBuilder: FormBuilder) { }

    get controls() {
        return this.form.controls;
    }

    get enconde() {
        return <IBusinessForm>{
            businessAddress: this.getControlValue('businessAddress'),
            businessEmail: this.getControlValue('businessEmail'),
            businessName: this.getControlValue('businessName'),
            businessPhone: Number(this.getControlValue('businessPhone')),
            businessStartDate: new Date(this.getControlValue('businessStartDate')),
            businessType: this.getControlValue('businessType'),
            city: this.getControlValue('city'),
            county: this.getControlValue('county'),
            DBA: this.getControlValue('DBA'),
            percentOwnership: Number(this.getControlValue('percentOwnership')),
            state: this.getControlValue('state'),
            termsService: true,
            zipCode: this.getControlValue('zipCode'),
        }
    }

    get invalid() {
        return this.form.invalid;
    }

    get valid() {
        return this.form.valid;
    }

    private getControlById(key: string) {
        return this.controls[key];
    }

    decode({
        businessAddress,
        businessEmail,
        businessName,
        businessPhone,
        businessType,
        businessStartDate,
        county,
        city,
        DBA,
        percentOwnership,
        state,
        zipCode
    }: IBusinessForm): void {
        this.setValueToControl('businessAddress', businessAddress);
        this.setValueToControl('businessEmail', businessEmail);
        this.setValueToControl('businessName', businessName);
        this.setValueToControl('businessPhone', businessPhone);
        this.setValueToControl('businessStartDate', businessStartDate);
        this.setValueToControl('businessType', businessType);
        this.setValueToControl('city', city);
        this.setValueToControl('county', county);
        this.setValueToControl('DBA', DBA);
        this.setValueToControl('percentOwnership', percentOwnership);
        this.setValueToControl('state', state);
        this.setValueToControl('zipCode', zipCode);
    }

    getControlValue(key: string) {
        return this.getControlById(key).value;
    }

    getEncodedValue(key: string) {
        return this.enconde[key];
    }

    reset() {
        this.form.reset();
    }

    setAddresData(city, county, state, errors = null) {
        this.setValueToControl('state', state);
        this.setValueToControl('city', city);
        this.setValueToControl('county', county);
        this.setErrors('zipCode', errors);
    }

    cleanAddressData() {
        this.setValueToControl('state', '');
        this.setValueToControl('city', '');
        this.setValueToControl('county', '');
        this.setValueToControl('zipCode', '');
    }

    setErrors(key: string, errors = null) {
        this.getControlById(key).setErrors(errors);
    }

    setDefaultData(pOwnershipPercentage?: number) {
        this.decode({
            businessType: 'Corporation',
            businessName: 'Jane',
            DBA: 'Doe',
            percentOwnership: pOwnershipPercentage ? pOwnershipPercentage : 70,
            businessStartDate: new Date(2000, 10, 10),
            termsService: true,
            businessEmail: 'business@mail.com',
            businessAddress: '1182 Main St',
            zipCode: 84105,
            city: 'Salt Lake City'.toUpperCase(),
            state: 'UT',
            county: 'Salt Lake',
            businessPhone: 2001062109
        });
    }

    setValueToControl(key: string, data) {
        this.getControlById(key).setValue(data);
    }

    setZipCodeError() {
        this.setAddresData('', '', '', { 'wrong': true });
    }
}
