<app-header></app-header>
<div class="error-box-container">
    <div class="body-container clearfix" *ngIf="loaded" [ngSwitch]="displayOption">
        <div *ngSwitchCase="'finicity'">
            <div class="row finicity-container">
                <div class="col-12">
                    <div class="finicityIframe" id="finicityIframe" *ngIf="vodRequired">
                    </div>
                    <div class="finicity-right">
                        <div class="row">
                            <div class="col-12 finicity-title">
                                <div>
                                    <span>Almost there!</span>
                                    For final approval, we have to verify the primary checking account you use for your
                                    business.
                                </div>
                                <div>
                                    To verify your checking account, we've partnered with a third party service,
                                    Finicity. Clicklease
                                    does
                                    not store or have access to your banking credentials.
                                </div>
                            </div>
                        </div>
                        <div>Here's how it works:</div><br>
                        <ol>
                            <li>Search for (and select) your bank</li>
                            <li>Enter your login credentials</li>
                            <li>Select the checking account you use for your business</li>
                            <li>You're done! You'll receive your final decision in a few short minutes</li>
                        </ol>
                        <div class="finicity-email-link font-mont-semi-bold"
                            (click)="sendFinicityVerifyBankAccountEmail();">
                            Don't remember your log-in? Click here and we'll email you a link to verify your bank
                            account.
                        </div>
                        <div class="row">
                            <div class="col-12 live-chat-container">
                                <a class="live-chat" target="_blank"
                                    href="https://home-c35.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=97e77c7f-d8a6-4af0-95eb-f8975110c562&bu=4599274">
                                    <img src="/assets/images/icons/live_chat.svg" alt="live-chat" />
                                </a>
                            </div>
                        </div>
                        <div>Any questions: Chat or Call us at 888-509-5592</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'offers'">
            <div class="row">
                <div class="col-12 box">
                    <div class="col-12 box-title">
                        Congratulations, you have been approved for {{offers.maxApproved | currency:'$
                        ':'code':'.0-0'}}!
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 box">
                    <div class="box-child clearfix">
                        <table class="table table-striped table-hover">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Term</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let offer of offers.offerList">
                                    <th scope="row"> {{offer.term}} </th>
                                    <td> {{ (offer.useMaxApprovedAmount ? offers.maxApproved : offers.requestAmount) |
                                        currency:'$ ':'code':'.0-0'}}
                                    </td>
                                    <td> {{ offer.payment | currency:'$ ':'code':'.2-2' }} </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="offers-legend">
                            ACH Payments are required for all transactions
                        </div>
                        <div class="terms-support">
                            If you have any questions about the payment or terms of the transaction, please call us at:
                            888-555-5555. If you'd like us to call you, please click
                            <a routerLink="/support">HERE</a>
                        </div>
                        <div class="form-row display-block">
                            <div class="col-xl-3 col-lg-5 col-md-4 float-right">
                                <button type="submit" class="btn btn-primary btn-lg col-md-12"
                                    (click)="onContinueClick();">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'offersError'">
            <div class="row">
                <div class="col-12 box">
                    <div class="col-12 box-title">
                        Unfortunately, we are unable to approve your request at this time.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 box">
                    <div class="col-12 box-error-message">
                        Unfortunately, we are unable to approve your request at this time. We hope to have another
                        opportunity to support your financing needs.
                    </div>
                </div>
            </div>
            <div class="row box">
                <div class="col-12 font-mont-book box-footer">
                    Please contact customer service at 1-888-509-5592 for more information.
                </div>
            </div>
        </div>
        <div class="boxDeclined m-0 row justify-content-center " *ngSwitchCase="'dROffersError'">
            <div class="boxDecCont">
                <div class="spaced"><img src="/assets/images/icons/declined_img.svg" alt="live-chat" /></div>
                    <h5 class="text-primary">DECLINED</h5>
                    <p>We’re sorry. We were unable to approve your applicant, 
                        {{customerName}}, based on the information provided.</p>
                    <p class="spaced2">Contact vendor services at the number below to discuss possible next steps.</p>
                    <a href="home" class="btn btn-primary btn-lg rounded-pill spaced" role="button" aria-pressed="true">Back to Portal</a>
                <div class="text-primary font-weight-bold">
                    <span><a href="">Need Help? </a></span> 
                    <span><a href="">Have Questions?</a></span>
                </div>
                <div class="phoneContact">
                    <span>Call: </span>
                    <span class="text-primary">1-888-509-5592</span>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'finicityError'">
            <div class="row">
                <div class="col-12 box">
                    <div class="col-12 box-title">
                        Unfortunately, we are unable to approve your request at this time.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 box">
                    <div class="col-12 box-error-message">
                        Unfortunately, we are unable to approve your request at this time. We hope to have another
                        opportunity to support your financing needs.
                    </div>
                </div>
            </div>
        </div>
        <app-error-dialog [modalId]="errorId" [title]="errorTitle" [messageA]="errorMessageA" [dialogClass]="'w450'">
        </app-error-dialog>
    </div>
</div>
<app-footer></app-footer>