import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  This
  @Input() current: number = 0;
  @Input() total: number = 0;

  @Output() goTo: EventEmitter<number> = new EventEmitter<number>()
  @Output() next: EventEmitter<number> = new EventEmitter<number>()
  @Output() previous: EventEmitter<number> = new EventEmitter<number>()
  @Output() perPage: EventEmitter<number> = new EventEmitter<number>()

  sliceSize: number = 10;
  statusFilterOpen: boolean = false;
  sortField: string = '';
  invertedSort: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public pages: number[] = []

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.current && changes.current.currentValue) ||
      (changes.total && changes.total.currentValue)
    ) {
      this.pages = this.getPages(this.current, this.total)
    }
  }

  public onGoTo(page: number): void {
    this.goTo.emit(page)
    this.onSortClick('inactive');

  }

  public onNext(): void {
    this.next.emit(this.current)
    this.onSortClick('inactive');

  }

  public onPrevious(): void {
    this.previous.next(this.current)
    this.onSortClick('inactive');

  }

  public onFirst(): void {
    this.goTo.emit(1);
    this.onSortClick('inactive');

  }

  public onLast(): void {
    this.goTo.emit(this.total);
    this.onSortClick('inactive');

  }

  public onPerPage(size: number): void {
    this.sliceSize = size;
    this.perPage.emit(this.sliceSize);
    this.onSortClick('inactive');
  }

  private getPages(current: number, total: number): number[] {
    if (total <= 7) {
      return [...Array(total).keys()].map(x => ++x)
    }
    if (current > 5) {
      if (current >= total - 6) {
        return [total - 6, total - 5, total - 4, total - 3, total - 2, total - 1, total]
      } else {
        return [current - 4, current - 3, current - 2, current - 1, current, current + 1, current + 2, current + 3, current + 4]
      }
    }
    return [1, 2, 3, 4, 5]
  }

  onSortClick(field: string) {
    if (this.sortField == field) {
      this.invertedSort = !this.invertedSort;
    } else {
      this.invertedSort = false;
      this.sortField = field;
    }
  }

}
