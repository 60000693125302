import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExpiredLoginService {

    private _loading = false;
    public onSuccessFunction: any;
    public onErrorFunction: any;
    loadingStatus: Subject<boolean> = new Subject();

    get loading(): boolean {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
        this.loadingStatus.next(value);
    }

    showLoginDialog(successFunction: any, errorFunction: any) {
        this.onSuccessFunction = successFunction;
        this.onErrorFunction = errorFunction;
        this.loading = true;
    }

    hideLoginDialog() {
        this.loading = false;
    }
}
