<nav aria-label="pagination" class="d-flex justify-content-end">
  <ul>
    <li>
      <button aria-label="Go To Previous Page" [attr.aria-disabled]="current === 1" [disabled]="current === 1"
        (click)="onFirst()">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.99989 11.0351C8.99994 11.1267 8.97482 11.2166 8.92727 11.2949C8.87971 11.3732 8.81155 11.437 8.73023 11.4792C8.64891 11.5214 8.55756 11.5404 8.46615 11.5343C8.37474 11.5281 8.28679 11.4969 8.21189 11.4441L1.07889 6.40812C1.01368 6.36192 0.960492 6.30077 0.923784 6.22978C0.887077 6.15879 0.86792 6.08004 0.86792 6.00012C0.86792 5.9202 0.887077 5.84145 0.923784 5.77046C0.960492 5.69947 1.01368 5.63832 1.07889 5.59212L8.21189 0.556119C8.28679 0.503347 8.37474 0.472166 8.46615 0.465984C8.55756 0.459801 8.64891 0.478853 8.73023 0.52106C8.81155 0.563267 8.87971 0.627007 8.92727 0.705317C8.97482 0.783628 8.99994 0.8735 8.99989 0.965119V11.0351ZM10.0789 6.40812C10.0137 6.36192 9.96049 6.30077 9.92378 6.22978C9.88708 6.15879 9.86792 6.08004 9.86792 6.00012C9.86792 5.9202 9.88708 5.84145 9.92378 5.77046C9.96049 5.69947 10.0137 5.63832 10.0789 5.59212L17.2119 0.556119C17.2868 0.503347 17.3747 0.472166 17.4662 0.465984C17.5576 0.459801 17.6489 0.478853 17.7302 0.52106C17.8116 0.563267 17.8797 0.627007 17.9273 0.705317C17.9748 0.783628 17.9999 0.8735 17.9999 0.965119V11.0351C17.9999 11.1267 17.9748 11.2166 17.9273 11.2949C17.8797 11.3732 17.8116 11.437 17.7302 11.4792C17.6489 11.5214 17.5576 11.5404 17.4662 11.5343C17.3747 11.5281 17.2868 11.4969 17.2119 11.4441L10.0789 6.40812V6.40812Z"
            fill="#C7C7C6" />
        </svg>
      </button>
    </li>
    <li>
      <button aria-label="Go To Previous Page" [attr.aria-disabled]="current === 1" [disabled]="current === 1"
        (click)="onPrevious()">
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 6L6 0V12L0 6Z" fill="#C7C7C6" />
        </svg>
      </button>
    </li>
    <li *ngFor="let page of pages;">
      <a class="pageNum" [attr.aria-current]="page === current ? 'page' : null" [attr.aria-label]="
            page === current ? 'Current Page, Page ' + page : 'Go to Page ' + page
          " [class.current]="page === current" tabindex="0" (click)="onGoTo(page)" (keyup.enter)="onGoTo(page)">
        {{ page }}</a>
    </li>
    <li>
      <button aria-label="Go To Next Page" [attr.aria-disabled]="current === total" [disabled]="current === total"
        (click)="onNext()">
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 6L0 12V0L6 6Z" fill="#C7C7C6" />
        </svg>
      </button>
    </li>
    <li>
      <button aria-label="Go To Next Page" [attr.aria-disabled]="current === total" [disabled]="current === total"
        (click)="onLast()">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.788 11.4441C0.713106 11.4969 0.625151 11.5281 0.533741 11.5343C0.442331 11.5404 0.350979 11.5214 0.269661 11.4792C0.188342 11.437 0.120184 11.3732 0.072629 11.2949C0.0250738 11.2166 -5.03528e-05 11.1267 7.57649e-08 11.0351V0.965119C-5.03528e-05 0.8735 0.0250738 0.783628 0.072629 0.705317C0.120184 0.627007 0.188342 0.563267 0.269661 0.52106C0.350979 0.478853 0.442331 0.459801 0.533741 0.465984C0.625151 0.472166 0.713106 0.503347 0.788 0.556119L7.921 5.59212C7.98621 5.63832 8.0394 5.69947 8.07611 5.77046C8.11282 5.84145 8.13197 5.9202 8.13197 6.00012C8.13197 6.08004 8.11282 6.15879 8.07611 6.22978C8.0394 6.30077 7.98621 6.36192 7.921 6.40812L0.788 11.4441V11.4441ZM9 0.965119C8.99995 0.8735 9.02507 0.783628 9.07263 0.705317C9.12018 0.627007 9.18834 0.563267 9.26966 0.52106C9.35098 0.478853 9.44233 0.459801 9.53374 0.465984C9.62515 0.472166 9.71311 0.503347 9.788 0.556119L16.921 5.59212C16.9862 5.63832 17.0394 5.69947 17.0761 5.77046C17.1128 5.84145 17.132 5.9202 17.132 6.00012C17.132 6.08004 17.1128 6.15879 17.0761 6.22978C17.0394 6.30077 16.9862 6.36192 16.921 6.40812L9.788 11.4441C9.71311 11.4969 9.62515 11.5281 9.53374 11.5343C9.44233 11.5404 9.35098 11.5214 9.26966 11.4792C9.18834 11.437 9.12018 11.3732 9.07263 11.2949C9.02507 11.2166 8.99995 11.1267 9 11.0351V0.965119Z"
            fill="#C7C7C6" />
        </svg>
      </button>
    </li>
    <li>
      <span class="results">Results per Page: {{sliceSize}}</span>
    </li>


    <button (click)="onSortClick('active')"><img src="/assets/images/icons/down.svg" /></button>

    <div *ngIf="!invertedSort && sortField == 'active'" class="popover-menu">
      <div class="results-top-options">
        <a role="button" (click)="onPerPage(10)">10</a>
        <a role="button" (click)="onPerPage(15)">15</a>
        <a role="button" (click)="onPerPage(20)">20</a>
      </div>
    </div>
  </ul>
</nav>
