import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { BankProcessService } from 'src/app/bank-process';

@Component({
    selector: 'app-giact-error',
    templateUrl: './giact-error.component.html',
    styleUrls: ['./giact-error.component.css']
})
export class GiactErrorComponent implements OnInit {
    event: any = '';
    onShowChange: boolean = false;

    constructor(
        private router: Router,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private bankProcessService: BankProcessService,
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state) {
            this.event = navigation.extras.state.event;
        } else this.errorListService.redirectToV3Error(
            null,
            'giact-error-params',
            this.isClickSign ? 'click-sign-otp' : 'started'
        );
    }

    get isClickSign() {
        return this.bankProcessService.isClicksing;
    }

    ngOnInit(): void {
        if (this.isClickSign) {
            this.googleAnalyticsService.trackClickSignEvent(
                'giact-error',
                this.bankProcessService.vendorReferrerName,
                this.event
            );
        } else this.googleAnalyticsService.trackV3Event(
            'giact-error',
            this.bankProcessService.vendorReferrerName,
            this.event
        );
    }

    backEvent() {
        history.back();
    }
}