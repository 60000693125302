import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function EmailValidator(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    let EmailValidator = require('commons-validator-js').EmailValidator;
    let validator = new EmailValidator();
    const forbidden = validator.isValid(control.value);

    let response: ValidationErrors | null = null;
    if (!forbidden) response = {
      emailFormatError: {
        value: control.value
      }
    }
    return response;
  }
}
