import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-customer-user',
    templateUrl: './customer-user.component.html',
    styleUrls: ['./customer-user.component.css']
})
export class CustomerUserComponent implements OnInit {
    loginTitle = '';
    loginMsg1 = '';
    loginMsg2 = '';

    submitted: boolean = false;
    customerUserForm: UntypedFormGroup;
    maxAgeDate = new Date((new Date()).setFullYear(new Date().getFullYear() - 18));

    constructor(private formBuilder: UntypedFormBuilder, private connectionService: ConnectionService) {
        this.customerUserForm = this.formBuilder.group({
            ssn: ['', Validators.required],
            dob: ['', Validators.required]
        });
    }

    ngOnInit() {
        void (0);
    }

    onSignin() {
        if (this.customerUserForm.invalid) {
            this.submitted = true;
            return;
        }

        const ssn = this.customerUserForm.controls.ssn.value;
        const dob = this.getFormatedDate(this.customerUserForm.controls.dob.value);
        this.connectionService.postRequestUnsecured(`${environment.gateway}createCommunityUser?ssn=${ssn}&dob=${dob}`, {}, 'application/json').then(
            (data: any) => {
                if (data.error) {
                    this.loginMsg1 = 'We are unable to locate your application, please contact ';
                    this.loginMsg2 = 'support@clicklease.com';

                    if (data.error) {
                        if (data.error.object) {
                            this.loginMsg1 = data.error.object;
                            this.loginMsg2 = '';
                        } else if (data.error.msg) {
                            this.loginMsg1 = data.error.msg;
                            this.loginMsg2 = '';
                        }
                    }

                    this.loginTitle = 'Error!';

                    $('#loginErrorModal').modal('show');

                } else {
                    this.loginTitle = 'Success!'
                    this.loginMsg1 = `Congratulations!! We have sent further instructions about login into the customer portal to this email: ${data.object}`;
                    this.loginMsg2 = '';

                    $('#loginErrorModal').modal('show');
                }
            },
            (error: any) => {
                this.loginMsg1 = 'We are unable to locate your application, please contact ';
                this.loginMsg2 = 'support@clicklease.com';

                if (error.status === 400 && error.error && error.error.object) {
                    this.loginTitle = 'Error!';
                    this.loginMsg1 = error.error.object;
                    this.loginMsg2 = '';
                }

                $('#loginErrorModal').modal('show');
            }
        );
    }

    getFormatedDate(date: any): string {
        const year = date.getFullYear();
        const month = date.getMonth() > 8 ? '-' + (date.getMonth() + 1) : '-0' + (date.getMonth() + 1);
        const day = date.getDate() > 9 ? '-' + date.getDate() : '-0' + date.getDate();
        return year + month + day;
    }
}
