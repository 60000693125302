import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { MoneyStepItem } from '../money-step/money-step.item';
import { StartedItem } from '../started/started.item';
import { BehaviorSubject } from 'rxjs';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { IPersonalInformation, PersonalInformationItem } from '../personal-information/personal-information.item';
import { BusinessFormItem } from '../business-information/business-form/business-form.item';
import { SoleFormItem } from '../business-information/sole-form/sole-form.item';

@Injectable({
    providedIn: 'root'
})
export class EdgarApplicationPeristenceService {
    startedInfo: StartedItem = new StartedItem(this.formBuilder);
    moneyInfo: MoneyStepItem = new MoneyStepItem(this.formBuilder, this.authService);
    soleInfo: SoleFormItem = new SoleFormItem(this.formBuilder);
    businessInfo: BusinessFormItem = new BusinessFormItem(this.formBuilder);
    personalInfo: PersonalInformationItem = new PersonalInformationItem(this.formBuilder);

    private $accessToken = new BehaviorSubject<string>(null);
    private $states = new BehaviorSubject<any>(null);
    private statesLoaded: boolean = false;
    private zipCodesData: any = {};

    personalInformationRetry: boolean = false;
    production: boolean = environment.production;
    storeErrors: any = {};
    isVendorApplication: boolean = false;
    newRose: boolean = true;

    constructor(
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private connectionService: ConnectionService
    ) { }

    get startedForm() { return this.startedInfo.form; }
    get moneyForm() { return this.moneyInfo.form; }
    get soleForm() { return this.soleInfo.form; }
    get businessForm() { return this.businessInfo.form; }
    get isSolePropiertorApp() { return this.businessInfo.form.controls.businessType.value == 'SoleProprietorship'; }
    get personalForm() { return this.personalInfo.form;}

    reset() {
        this.startedInfo = new StartedItem(this.formBuilder);
        this.moneyInfo = new MoneyStepItem(this.formBuilder, this.authService);
        this.soleInfo = new SoleFormItem(this.formBuilder);
        this.businessInfo = new BusinessFormItem(this.formBuilder);
        this.personalInfo = new PersonalInformationItem(this.formBuilder);
    }

    setMoneyFormDefaultData() {
        this.moneyInfo.setDefaultData();
    }

    setStates(states: any) {
        if (!this.statesLoaded) {
            this.$states.next(states);
            this.statesLoaded = true;
        }
    }

    getStates() {
        if (this.statesLoaded) {
            return this.$states;
        } else {
            return this.connectionService.getRequestUnsecured(`${environment.gateway}utils/states`, 'application/json');
        }
    }

    setAccessToken(accessToken: string) {
        this.$accessToken.next(accessToken);
    }

    getAccessToken(): BehaviorSubject<string> {
        return this.$accessToken;
    }

    setZipCodeLoaded(zipCode: string, address: string, city: string, zipCodeData: any) {
        let fullCode: string = zipCode;
        if (address) {
            fullCode = fullCode + "_" + encodeURIComponent(address);
        }
        if (city) {
            fullCode = fullCode + '_' + encodeURIComponent(city);
        }
        if (!this.zipCodesData[fullCode]) {
            let newZipCodeData = new BehaviorSubject<any>(null);
            newZipCodeData.next(zipCodeData);
            this.zipCodesData[fullCode] = newZipCodeData;
        }
    }

    getZipCodeData(zipCode: string, address: string, city: string, accessToken: string) {
        let fullCode: string = zipCode;
        if (address) {
            fullCode = fullCode + "_" + encodeURIComponent(address);
        }
        if (city) {
            fullCode = fullCode + '_' + encodeURIComponent(city);
        }
        if (this.zipCodesData[fullCode]) {
            return this.zipCodesData[fullCode];
        } else {
            return this.connectionService.postV3Request(`${environment.gatewayV3}addressservice/api/getValidAddressForTaxes`, { zipcode: zipCode, streetAddress: address, city }, 'application/json', this.authService.keyCloakToken);
        }
    }

    putPersonalInformation(personalData: IPersonalInformation, accessToken: string) {
        return this.connectionService.patchV3Request(`${environment.gatewayV3}prefillservice/api/leads/${personalData.id}`, personalData, 'application/json', accessToken);
    }

    createUTCDate(date: string) {
        let plainDate = new Date(date);
        return new Date(plainDate.getUTCFullYear(), plainDate.getUTCMonth(), plainDate.getUTCDate());
    }

    parseLead(lead: any) {
        if (!lead.mobileVerified) {
            this.personalInfo.form.controls.birthDate.setValidators(Validators.required);
            this.personalInfo.form.controls.socialSecurityNumber.setValidators(Validators.required);
            if (lead.id) this.personalInfo.form.controls.id.setValue(lead.id);
            if (lead.phone) this.personalInfo.form.controls.phoneNumber.setValue(Number(lead.phone));
            this.newRose = true;
            return;
        }

        this.newRose = false;
        this.personalInfo.form.controls.birthDate.clearValidators();
        this.personalInfo.form.controls.socialSecurityNumber.clearValidators();

        this.personalInfo.decode(
            {
                id: lead.id ? lead.id : '',
                firstName: lead.firstName ? lead.firstName : '',
                lastName: lead.lastName ? lead.lastName : '',
                birthDate: lead.birthDate ? this.createUTCDate(lead.birthDate) : null,
                ssn: null,
                street: lead.street ? lead.street : '',
                zipcode: lead.zipcode ? lead.zipcode : null,
                city: lead.city ? lead.city.toUpperCase() : '',
                state: lead.state ? lead.state : '',
                county: lead.county ? lead.county : '',
                phoneNumber: lead.phone ? Number(lead.phone) : null
            }
        );

        this.moneyInfo.decode({
            financingRequested: lead.requestedAmount ? lead.requestedAmount : null
        });

        this.soleInfo.decode({
            businessType: 'SoleProprietorship',
            email: lead.email ? lead.email : '',
            DBA: lead.dbaName ? lead.dbaName : '',
            businessStartDate: lead.businessStartDate ? this.createUTCDate(lead.businessStartDate) : null,
            businesAddressDifferent: true,
            termsService: true,
            businessAddress: lead.businessStreet ? lead.businessStreet : '',
            zipCode: lead.businessZipcode ? lead.businessZipcode : null,
            city: lead.businessCity ? lead.businessCity.toUpperCase() : '',
            state: lead.businessState ? lead.businessState : '',
            county: lead.businessCounty ? lead.businessCounty : ''
        });

        this.businessInfo.decode({
            businessType: lead.businessStructure ? lead.businessStructure : '',
            businessName: lead.legalName ? lead.legalName : '', //???
            DBA: lead.dbaName ? lead.dbaName : '',
            percentOwnership: lead.ownershipPercentage ? lead.ownershipPercentage : '',
            businessStartDate: lead.businessStartDate ? this.createUTCDate(lead.businessStartDate) : null,
            termsService: true,
            businessEmail: lead.email ? lead.email : '',
            businessAddress: lead.businessStreet ? lead.businessStreet : '',
            zipCode: lead.businessZipcode ? lead.businessZipcode : null,
            city: lead.businessCity ? lead.businessCity.toUpperCase() : '',
            state: lead.businessState ? lead.businessState : '',
            county: lead.businessCounty ? lead.businessCounty : '',
            businessPhone: lead.businessPhone ? Number(lead.businessPhone) : null
        });
    }
}
