<button (click)="onToggleSelectedRow()"
    class="btn-collapse-window d-flex align-items-center text-left border-0 bg-transparent w-100 mt-3 mb-4 pl-0">
    <span class="d-flex align-items-strech">
        <img src="/assets/images/icons/close_b.svg" alt="close b">
    </span>
    <span class="font-mont-semi-bold text-capitalize pl-2 pt-1">collapse window</span>
</button>
<div class="only-mobile-block application-table-row">
    <div>
        <span class="font-mont-semi-bold">Business Name:</span>
        {{app.legalName}}
    </div>
    <div *ngIf="app.showVendorName">
        <span class="font-mont-semi-bold">Vendor Name:</span>
        {{app.vendorName}}
    </div>
    <div>
        <span class="font-mont-semi-bold">Application ID:</span>
        {{app.id}}
    </div>
    <div>
        <span class="font-mont-semi-bold">Owner Name:</span>
        {{ownersNames}}
    </div>
    <div>
        <span class="font-mont-semi-bold">Sales Associate:</span>
        {{app.salesPersonAssociate}}
    </div>
    <div>
        <span class="font-mont-semi-bold">Approval Amount:</span>
        {{app.approvalAmount | currency:'$ ':'code':'.0-0'}}
    </div>
    <div>
        <span class="font-mont-semi-bold">Current Status:</span>
        {{app.currentStage}}
    </div>
    <div>
        <span class="font-mont-semi-bold">Your Next Step:</span>
        {{app.nextStep}}
    </div>
</div>
<div class="app-process pb-3">
    <div class="scroll-container d-flex" [ngStyle]="{'width': scrollContainerWidth}">
        <ng-container *ngFor="let step of application.applicationProcess | sort:'order'; let i = index">
            <div *ngIf="true" class="flex-grow-1"
                [ngStyle]="{'flex-basis': 100 / application.applicationProcess.length + '%'  }">
                <div class="process-arrow process-arrow-curve d-flex align-items-center flex-column position-relative text-center w-100 pl-lg-3 pl-2 pr-2 pt-3"
                    [ngStyle]="{'--convex--offset': -(application.applicationProcess.length + 0.5) + '%','--pa-font-clr': step?.fontColor, '--pa-bg': step?.color}"
                    [ngClass]="{ 'before-concave':  i > 0, 'after-convex': application.applicationProcess.length - 1 > i, 'pr-3': i === 0}">
                    <span *ngIf="application.applicationProcess.length - 1 > i" class="after-curve-bg"></span>
                    <div class="process-arrow-status d-flex justify-content-center align-items-stretch bg-white rounded-circle p-2 my-2"
                        [ngSwitch]="step.status">
                        <img *ngSwitchCase="'completed'" src="/assets/images/icons/check-2-gray.svg"
                            alt="circle check" />
                        <img class="p-1" *ngSwitchCase="'rejected'" src="/assets/images/icons/close_red.svg"
                            alt="circle times" />
                        <img *ngSwitchDefault src="/assets/images/icons/circle_empty.svg" alt="circle empty" />
                    </div>
                    <span *ngIf="step.user"
                        class="process-user-step font-mont-bold text-uppercase mt-1">{{step.user}}:</span>
                    <span *ngIf="step.textA || step.textB" class="font-mont-semi-bold"
                        [ngClass]="{'mt-1': (step.textA || step.textB) && !step.user}">
                        {{step?.textA?? ''}} {{step?.textB ?? ''}}
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="next-step-section">
    <div class="row">
        <div class="col-12 next-step-section-title font-mont-bold">
            NEXT STEP:
        </div>
    </div>
    <app-create-send-contract *ngIf="showCreateSendContracts" (reloadSlice)="onReloadSlice();" [appId]="appId"
        [statesOfIncorporation]="statesOfIncorporation" [defaultOfferId]="application.defaultOfferId"
        [vendorPaused]="vendorPaused" [maxApprovedAmount]="application.maxApprovedAmount">
    </app-create-send-contract>
    <app-bank-verification *ngIf="showVodStatus" [application]="app" [processStatus]="application.processStatus"
        (resendLink)="sendBankLink($event)" [appOpts]="{
            isHighRisk: app?.highRisk, 
            validPath: app?.validationPath, 
            mobVerify: app?.mobileVerification, 
            appSource: app?.edgarApp? 'edgar': 'rose',
            riskClassification: (app?.riskClassification?.toLowerCase() ?? 'other') | lowercase 
        }">
    </app-bank-verification>
    <app-submit-invoice [appId]="appId" [appGPSUnitCostRequired]="app.gpsUnitCostRequired"
        [appLegalName]="application.lessee.legalName" [appVendorId]="app.vendorId" (reloadSlice)="onReloadSlice();"
        *ngIf="showSubmitInvoice" [nextStep]="nextStep"></app-submit-invoice>
    <app-invoice-review *ngIf="showInvoiceReview"></app-invoice-review>
    <app-funding-pending *ngIf="showFundingPending"></app-funding-pending>
    <app-funded *ngIf="showFunded" [appId]="appId"></app-funded>
    <app-summary-declined *ngIf="showDeclined" [processStatus]="application.processStatus"></app-summary-declined>
    <app-driver-license-declined *ngIf="showDLDeclined" [processStatus]="application.processStatus">
    </app-driver-license-declined>
    <app-data-error *ngIf="showDataError" [processStatus]="application.processStatus"></app-data-error>
    <app-contract-status *ngIf="showContractStatus" [nextStep]="nextStep" [currentStep]="currentStep"
        [processStatus]="application.processStatus"></app-contract-status>
    <app-pre-approval *ngIf="showResendBankVerificationLink"></app-pre-approval>
    <div class="row sub-section-title"
        *ngIf="application.additionalOptions.length > 0 && !application.processStatus.info.declineWithoutOptions">
        <div class="col-12">
            <img src="/assets/images/icons/edit_blue.svg" alt="edit blue" />
            <span>Additional Options</span>
        </div>
    </div>
    <div class="row sub-section-form row"
        *ngIf="application.additionalOptions.length > 0 && !application.processStatus.info.declineWithoutOptions">
        <div *ngFor="let additionalOption of application.additionalOptions"
            class="col-12 col-sm-6 col-md-4 auto-size-lg">
            <div [ngSwitch]="additionalOption">
                <app-additional-add-co-signer *ngSwitchCase="'Add Co-Signer'" [statesIssued]="statesOfIncorporation"
                    [id]="appId" (reloadSlice)="onReloadSlice();" (emailClick)="onEmailClick();"
                    [ownerEmail]="application.lessee.email" [vendors]="application.lessee.owners">
                </app-additional-add-co-signer>
                <app-additional-add-co-signer-email [id]="appId" (reloadSlice)="onReloadSlice();">
                </app-additional-add-co-signer-email>
                <app-additional-resend-dl-link *ngSwitchCase="'Re-send DL'" [id]="appId"
                    (reloadSlice)="onReloadSlice();">
                </app-additional-resend-dl-link>
                <app-additional-resend-contract *ngSwitchCase="'Re-Send'" [id]="appId" (reloadSlice)="onReloadSlice();"
                    [statesOfIncorporation]="statesOfIncorporation" [defaultOfferId]="application.defaultOfferId"
                    [maxApprovedAmount]="application.maxApprovedAmount">
                </app-additional-resend-contract>
                <app-additional-payment-calculator *ngSwitchCase="'Recalc'" [id]="appId"
                    [approvedAmount]="application.maxApprovedAmount">
                </app-additional-payment-calculator>
                <app-additional-download-po *ngSwitchCase="'Download-Po'" [id]="appId"></app-additional-download-po>
                <app-additional-resubmit *ngSwitchCase="'CS-Agent'" [id]="appId" [appStatus]="application.status">
                </app-additional-resubmit>
                <app-additional-change-salesperson *ngSwitchCase="'Salesperson-Admin'" [id]="appId"
                    [vendorId]="app.vendorId">
                </app-additional-change-salesperson>
                <app-additional-resend-bank-verification-link *ngSwitchCase="'Bank Verification'" [id]="appId"
                    (reloadSlice)="onReloadSlice();">
                </app-additional-resend-bank-verification-link>
            </div>
        </div>
    </div>
</div>
<div class="summary-section">
    <div class="row">
        <div class="col-12 summary-section-title font-mont-bold">
            APPLICATION DETAIL:
        </div>
    </div>
    <app-application-details-risk [risk]="risk">
    </app-application-details-risk>
    <app-application-details-notes [id]="appId" [notes]="application.applicationNotes"></app-application-details-notes>
    <div class="collapsable-tab sub-title d-flex d-flex justify-content-between align-items-center pt-3">
        <button (click)="openBusiness ? openBusiness = false : openBusiness = true"
            class="ept-btn-app-detail pl-0 pr-lg-4 m-0 border-0 flex-grow-lg-1 text-left bg-transparent">
            <span [ngClass]="{'collapse-icon--open': openBusiness }" class="collapse-icon mr-2"></span>
            <span class="font-mont-semi-bold">BUSINESS INFORMATION</span>
        </button>
    </div>
    <div class="row app-business-info" [ngClass]="{ 'open': openBusiness }">
        <div class="col-12 hide-mobile">
            <table aria-describedby="lesee">
                <tbody>
                    <tr>
                        <td>Legal Name:</td>
                        <td>{{application.lessee.legalName}}</td>
                        <td>DBA:</td>
                        <td>{{application.lessee.dbaName}}</td>
                        <td>Business Start Date:</td>
                        <td>{{application.lessee.businessPurchaseDate | date:'MM/yyyy'}}</td>
                    </tr>
                    <tr>
                        <td>Business Email:</td>
                        <td colspan="2">{{application.lessee.email}}</td>
                        <td>Equipment Description:</td>
                        <td colspan="2">{{application.description}}</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td colspan="5">{{application.lessee.fullAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 only-mobile-block">
            <table aria-describedby="lesee">
                <tbody>
                    <tr>
                        <td>Legal Name:</td>
                        <td>{{application.lessee.legalName}}</td>
                    </tr>
                    <tr>
                        <td>DBA:</td>
                        <td>{{application.lessee.dbaName}}</td>
                    </tr>
                    <tr>
                        <td>Business Start Date:</td>
                        <td>{{application.lessee.businessPurchaseDate | date:'MM/yyyy'}}</td>
                    </tr>
                    <tr>
                        <td>Business Email:</td>
                        <td>{{application.lessee.email}}</td>
                    </tr>
                    <tr>
                        <td>Equipment Description:</td>
                        <td>{{application.description}}</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{{application.lessee.fullAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="collapsable-tab sub-title d-flex d-flex justify-content-between align-items-center pt-3">
        <button (click)="openOwner ? openOwner = false : openOwner = true"
            class="ept-btn-app-detail pl-0 pr-lg-4 m-0 border-0 flex-grow-lg-1 text-left bg-transparent">
            <span [ngClass]="{'collapse-icon--open': openOwner }" class="collapse-icon mr-2"></span>
            <span class="font-mont-semi-bold">Guarantor Information</span>
        </button>
    </div>
    <div class="row app-owner-info" *ngFor="let owner of application.lessee.owners" [ngClass]="{ 'open': openOwner }">
        <div class="col-12 hide-mobile">
            <table aria-describedby="guarantor">
                <tbody>
                    <tr>
                        <td colspan="2">Guarantor:</td>
                        <td colspan="4">{{owner.firstName}} {{owner.lastName}}</td>
                        <td colspan="2">Mobile:</td>
                        <td colspan="4" class="text-right">{{owner.mobilePhoneNumber | mask: '(000) 000-0000'}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">Address:</td>
                        <td colspan="10">{{owner.fullAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 only-mobile-block">
            <table aria-describedby="guarantor">
                <tbody>
                    <tr>
                        <td>Guarantor:</td>
                        <td>{{owner.firstName}} {{owner.lastName}}</td>
                    </tr>
                    <tr>
                        <td>Mobile:</td>
                        <td>{{owner.mobilePhoneNumber | mask: '(000) 000-0000'}}</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{{owner.fullAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <app-application-details-history [history]="application.applicationHistory"></app-application-details-history>
</div>
<button (click)="onToggleSelectedRow()"
    class="btn-collapse-window d-flex align-items-center text-left border-0 bg-transparent w-100 mt-3 mb-4 pl-0">
    <span class="d-flex align-items-strech">
        <img src="/assets/images/icons/close_b.svg" alt="close b">
    </span>
    <span class="font-mont-semi-bold text-capitalize pl-2 pt-1">collapse window</span>
</button>
