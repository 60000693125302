import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ClickSingService } from 'src/app/click-sing';

@Component({
  selector: 'app-docusign-otp',
  templateUrl: './docusign-otp.component.html',
  styleUrls: ['./docusign-otp.component.css']
})
export class DocusignOtpComponent implements OnInit {
    private clickProccess = false;
    showModal = false;
    usaPhoneNumber: string;
    appId: string;
    token: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private clickSingService: ClickSingService,
    ) { }

    get production() {
        return this.clickSingService.isProduction;
    }

    private redirectToOfferSelection(accessToken) {
        this.router.navigate(['../offers-selection'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: "merge",
            state: {
                accessToken,
                token: this.token,
                appId: this.appId
            }
        });
    }

    private redirectToOldSign(accessToken) {
        this.router.navigate(['../../oldsign/offers-selection'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: "merge",
            state: {
                accessToken,
                token: this.token,
                appId: this.appId
            }
        });
    }

    private validateRedirect(response) {
        if (response.code == '200') {

            this.googleAnalyticsService.trackClickSignEvent(
                'otp-signing-flow',
                this.clickSingService.vendorReferrerName,
                JSON.stringify(response)
            );
            this.googleAnalyticsService.setClickSignVariant(response.signingVersionEnum);
            if(response.signingVersionEnum === "S2") {
                this.redirectToOfferSelection(response.authorization);
            } else this.redirectToOldSign(response.authorization);
            
        }

        this.googleAnalyticsService.trackClickSignEvent(
            'otp-signing-response',
            this.clickSingService.vendorReferrerName,
            JSON.stringify(response)
        );
    }

    private validateSignError(data, callback) {
        console.log(data);

        if (data && data.error && data.error.code === '409') {

            this.googleAnalyticsService.trackClickSignEvent(
                'invalid-code-entered',
                this.clickSingService.vendorReferrerName,
                'payments-otp'
            );

            callback({ invalidCode: true });
        } else this.errorListService.redirectToV3Error(
            data,
            'validateOtp-error',
            'click-sign-otp'
        );
    }

    ngOnInit(): void {
        this.googleAnalyticsService.setLeadId(0, '');
        this.googleAnalyticsService.setV3Label('click-sign-token');

        this.appId = this.activatedRoute.snapshot.queryParams.appId;
        this.token = this.activatedRoute.snapshot.queryParams.token;

        if(!this.appId) {
            this.googleAnalyticsService.trackClickSignEvent(
                'open',
                this.clickSingService.vendorReferrerName,
                "otp"
            );

            this.errorListService.redirectToTokenError(this.token, 'click-sign-appId');

            return;
        }
        
        this.googleAnalyticsService.setApplicationId(parseInt(this.appId));
        this.googleAnalyticsService.trackClickSignEvent(
            'open',
            this.clickSingService.vendorReferrerName,
            "otp"
        );

        if(this.activatedRoute.snapshot.queryParams.phoneNumber) {
            if(this.activatedRoute.snapshot.queryParams.phoneNumber.replaceAll) {
                this.usaPhoneNumber = this.activatedRoute.snapshot.queryParams.phoneNumber.replaceAll('X', '');
            } else {
                this.usaPhoneNumber = this.activatedRoute.snapshot.queryParams.phoneNumber.replace(/X/g, '');
            }
        } else {
            this.errorListService.redirectToTokenError(this.token, 'click-sign-phone');
            return;
        }

        this.validateTokenForOffer();
    }

    closeModal($event) {
        this.showModal = $event;
    }

    onContinueClick() {
        if(this.clickProccess) {
            return;
        }

        this.googleAnalyticsService.trackClickSignEvent(
            'otp-started-completed',
            this.clickSingService.vendorReferrerName,
            "payments"
        );

        this.clickProccess = true;

        this.clickSingService.sendAgreement(this.appId, this.token)
            .then(res => {
                this.showModal = true;
                this.googleAnalyticsService.trackClickSignEvent(
                    'enter-code-shown',
                    this.clickSingService.vendorReferrerName,
                    ''
                );
                this.clickProccess = false;

            }).catch(err => this.errorListService.redirectToV3Error(
                err,
                'sentOtp-code',
                'click-sign-otp'
            ));
    }

    verifyActionCallback({ otp, callback }) {
        this.clickSingService.validateAgreement(this.appId, otp)
            .then(response => this.validateRedirect(response))
            .catch(err => this.validateSignError(err, callback));
    }

    validateTokenForOffer() {
        this.clickSingService.validateTokenOffert(this.token).then(res => {
            if(res.code === "200") {
                this.googleAnalyticsService.trackClickSignEvent(
                    'validate-token-offer',
                    this.clickSingService.vendorReferrerName,
                    JSON.stringify(res)
                );
                if(res.object.status && res.object.status.indexOf('REJECTED') !== -1) {
                    this.router.navigate(['/decline-error'], {queryParamsHandling: "merge", state: { endpoint: "open-link-decline", start: "click-sign-otp", error: true } });
                }
            } else this.errorListService.redirectToTokenError(this.token, 'click-sign-token');
        }).catch(err => {
            console.log(err);
            this.errorListService.redirectToTokenError(this.token, 'click-sign-token');
        })
    }
}
