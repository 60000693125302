export class FraudulentRuleItem {
  id: number;
  ssn: string;
  dateOfBirth: Date;
  homeAddressZipCode: string;
  homeAddressStreetNumber: string;
  businessAddressZipCode: string;
  ipAddress: number;
  source: string;
  businessAddressStreetNumber: string;

  constructor(data: any) {
    this.id = data.id;
    this.ssn = data.ssn;
    this.dateOfBirth = data.dateOfBirth;
    this.homeAddressZipCode = data.homeAddressZipCode;
    this.homeAddressStreetNumber = data.homeAddressStreetNumber;
    this.businessAddressZipCode = data.businessAddressZipCode;
    this.ipAddress = data.ipAddress;
    this.source = data.source;
    this.businessAddressStreetNumber = data.businessAddressStreetNumber;
  }
}