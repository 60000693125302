<div [hidden]="!modalOff" >
    <app-application-wrapper [style]="2" class="applicationWrapper">
        <ng-template appApplicationWrapperHelper>
        </ng-template>
    </app-application-wrapper>
</div>
<div id="popup" [ngClass]="{'overlayOff': modalOff, 'overlay': !modalOff}">
    <div id="modalBody" [ngClass]="{'modalBodyOff': modalOff, 'modalBody': !modalOff}">
        <div *ngIf="close" >
            <a id="cerrar" [ngClass]="{'closeoff': modalOff}" (click)="onCloseModal();">&times;</a>
        </div>
        <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container> 
    </div>
</div>
