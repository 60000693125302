<app-header></app-header>
<div class="body-container">
    <div class="row decision-model-title-container">
        <h1 class="decision-title font-mont-regular">Please upload your ACH file.</h1>
    </div>
    <div class="form-row">
        <div class="form-group col-12">
            <label for="achFile">Ach Document:</label>
            <input type="file" class="form-control" id="achFile" placeholder="Ach File" name="achFile"
                (change)="uploadFile($event)">
        </div>
    </div>
    <div class="row">
        <div class="col-12 attibute-list">
            <button class="btn btn-primary float-right" type="submit" (click)="onDownloadAchCsv();">
                Generate CSV
            </button>
            <button class="btn btn-primary float-right" type="submit" [hidden]="csv == ''" (click)="downloadFileCsv();">
                Download CSV
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>