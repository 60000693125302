<button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular double-white"
    (click)="onClick();">
    <div>Resend</div>
    <div>DL Link</div>
</button>
<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="AdditionalResendDlLinkModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <form [formGroup]="sendForm" (ngSubmit)="sendApp();">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src="/assets/images/icons/close_b.svg" alt="close b" />
                        CLOSE WINDOW
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="modal-title w-100 text-left font-mont-bold" id="exampleModalLongTitle">
                        <img _ngcontent-mwd-c4="" src="/assets/images/icons/send_blue.svg" alt="send">
                        Resend DL Link
                    </h5>
                    <div class="form-row">
                        <div class="form-group col-sm-12">
                            Do you want to resend the DL link by email or Text?
                        </div>
                        <div class="form-group col-sm-12">
                            <label for="headerSendRadio"></label>
                            <input type="radio" id="headerSendEmailRadio" value="email"
                                [formControl]="sendForm.controls.radio" name="headerSendRadio">
                            <span>Email</span>
                            <input type="radio" id="headerSendTextRadio" value="text"
                                [formControl]="sendForm.controls.radio" name="headerSendRadio">
                            <span>Text</span>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="sendForm.controls.radio.value == 'email'">
                        <div class="form-group col-sm-12">
                            <input type="text" formControlName="email" class="form-control" id="headerSendEmailInput"
                                placeholder="Email" name="headerSendEmailInput"
                                [ngClass]="{ 'is-invalid': submitted && sendForm.controls.email.errors }">
                            <div *ngIf="submitted && sendForm.controls.email.errors" class="invalid-feedback">
                                <div *ngIf="sendForm.controls.email.errors.required">
                                    Email is required.
                                </div>
                                <div *ngIf="sendForm.controls.email.errors.email">
                                    Invalid Email.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="sendForm.controls.radio.value == 'text'">
                        <div class="form-group col-sm-12">
                            <input type="text" formControlName="text" class="form-control" id="headerSendTextInput"
                                placeholder="Mobile Phone Number" name="headerSendTextInput" [showMaskTyped]="true"
                                [ngClass]="{ 'is-invalid': submitted && sendForm.controls.text.errors }"
                                mask="(000) 000-0000">
                            <div *ngIf="submitted && sendForm.controls.text.errors" class="invalid-feedback">
                                <div *ngIf="sendForm.controls.text.errors.required">
                                    Mobile phone number is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                                RESEND DL LINK
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="modalIdE" [title]="errorTitle" [messageA]="errorMessageA" [messageB]="errorMessageB"
    [messageC]="errorMessageC" [messageBClass]="errorMessageBClass" [dialogClass]="dialogClass">
</app-error-dialog>
