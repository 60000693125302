import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ArrayFilterPipe } from 'src/app/shared/pipes/array.filter.pipe';
import { ArraySortPipe } from 'src/app/shared/pipes/array.sort.pipe';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { FraudulentRuleItem } from './fraudulentRule.fraudulentRule.item';
declare var $: any;

@Component({
  selector: 'app-fraud-list',
  templateUrl: './fraud-list.component.html',
  styleUrls: ['./fraud-list.component.css']
})
export class FraudListComponent implements OnInit {

  token: any;
  fraudulentForm: UntypedFormGroup;

  fraudulentRules: FraudulentRuleItem[] = [];

  newFraudulentForm: UntypedFormGroup;
  addFraudulentSubmitted = false;
  fraudulentRuleId = '';
  fraudulentIdToDelete = 0;

  tempSelectedRow: number = -1;

  modalId = 'ErrorsModal';
  messageTitle = '';
  messageMessageA = '';
  messageMessageB = '';
  messageMessageC = '';
  messageMessageBClass = '';
  dialogClass = '';

  userFraudulent = false;

  filter: string = '';
  filterValue: string = '';

  sortField: string = '';
  invertedSort: boolean = false;

  minDate = new Date("1900-1-1");
  today = new Date();
  maxAgeDate = new Date((new Date()).setFullYear(new Date().getFullYear() - 18));

  constructor(private connectionService: ConnectionService,
    private formBuilder: UntypedFormBuilder,
    private arrayFilterPipe: ArrayFilterPipe,
    private arraySortPipe: ArraySortPipe,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this.fraudulentForm = this.formBuilder.group({ fraudulentRule: [''] });
    this.newFraudulentForm = this.formBuilder.group({
      ssn: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      homeAddressZipCode: ['', Validators.required],
      homeAddressStreetNumber: ['', Validators.required],
      businessAddressZipCode: ['', Validators.required],
      businessAddressStreetNumber: ['', Validators.required],
      ipAddress: ['', Validators.required]
    });
    const navigation = this.router.getCurrentNavigation();
    this.token = navigation.extras.state && navigation.extras.state.token ? navigation.extras.state.token : null;
  }

  ngOnInit(): void {
    this.getFraudulentRulesList();
    if (!this.token) {
      this.token = this.route.snapshot.queryParams.token;
    }
    if (!this.token) {
      let userData = this.authService.getLoggedUser();
      this.userFraudulent = userData ? userData.roles.filter((f: any) => f.id === 10).length > 0 : false;
      console.log(this.userFraudulent);
    }
  }

  onCreateNewFraudulentSubmit() {
    this.addFraudulentSubmitted = false;
    this.newFraudulentForm.controls.ssn.setValue('');
    $('#addNewFraudulentModal').modal();
  }

  onCreateSubmit() {
    this.addFraudulentSubmitted = true;
    if (this.newFraudulentForm.invalid) {
      return;
    }

    $('#addNewFraudulentModal').modal('hide');
    return new Promise((resolve, reject) => {
      const postData: any = {
        ssn: this.newFraudulentForm.controls.ssn.value,
        dateOfBirth: this.getFormatedDate(this.newFraudulentForm.controls.dateOfBirth.value),
        homeAddressZipCode: this.newFraudulentForm.controls.homeAddressZipCode.value,
        homeAddressStreetNumber: this.newFraudulentForm.controls.homeAddressStreetNumber.value,
        businessAddressZipCode: this.newFraudulentForm.controls.businessAddressZipCode.value,
        businessAddressStreetNumber: this.newFraudulentForm.controls.businessAddressStreetNumber.value,
        ipAddress: this.newFraudulentForm.controls.ipAddress.value
      };
      this.connectionService.postRequest(`${environment.gateway}fraudulentRule`, postData, 'application/json').subscribe(
        (data: any) => {
          $('#addNewFraudulentModal').modal('hide');
          this.showSuccessMessage("New Fraudulent Rule Created!");
          this.fraudulentRuleId = data.object.id;
          this.getFraudulentRulesList(this.fraudulentRuleId).then(
            () => this.fraudulentForm.controls.fraudulentRule.setValue(this.fraudulentRuleId),
            () => this.fraudulentForm.controls.fraudulentRule.setValue(this.fraudulentRuleId)
          );
          resolve(data);
        },
        (error: any) => {
          $('#addNewFraudulentModal').modal('hide');
          this.showErrorMessage(error.error.msg);
          reject(error);
        }
      );
    });
  }

  private showSuccessMessage(pMessage: string) {
    this.messageTitle = 'Success!!';
    this.messageMessageA = pMessage;
    this.messageMessageB = '';
    this.messageMessageC = '';
    this.messageMessageBClass = '';
    this.dialogClass = 'w450';
    $('#errorModalDialog' + this.modalId).modal();
  }

  getFraudulentRulesList(fraudulentRuleId?: any) {
    return new Promise((resolve, reject) => {
      this.connectionService.getRequest(`${environment.gateway}fraudulentRule/list`, 'application/json').subscribe(
        (data: any) => {
          let result = [];
          data.object.forEach(element => {
            let lAdjustment = new FraudulentRuleItem(element);
            result.push(lAdjustment);
          });
          this.fraudulentRules = result;
          let filteredAndSortedResult = this.arrayFilterPipe.transform(result, this.filter, this.filterValue);
          filteredAndSortedResult = this.arraySortPipe.transform(filteredAndSortedResult, this.sortField, this.invertedSort);

          if (fraudulentRuleId) {
            for (let index = 0; index < filteredAndSortedResult.length; index++) {
              if (filteredAndSortedResult[index].id == fraudulentRuleId) {
                this.tempSelectedRow = index;
              }
            }
          }
          resolve(data);
        },
        (error: any) => {
          this.showErrorMessage(error.error.msg);
          reject(error);
        }
      );
    });
  }

  private showErrorMessage(pError: string) {
    this.messageTitle = 'Sorry, we found a problem';
    this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
    this.messageMessageB = pError;
    this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
    this.messageMessageBClass = 'mB20';
    this.dialogClass = 'w600';
    $('#errorModalDialog' + this.modalId).modal();
  }

  getFormatedDate(date: any): string {
    const year = date.getFullYear();
    const month = date.getMonth() > 8 ? '-' + (date.getMonth() + 1) : '-0' + (date.getMonth() + 1);
    const day = date.getDate() > 9 ? '-' + date.getDate() : '-0' + date.getDate();
    return year + month + day;
  }

  onDeleteFraudulentRule(fraudulentToDelete: number) {
    this.fraudulentIdToDelete = fraudulentToDelete;
    $('#deleteFraudulentRule').modal();
  }

  onConfirmDeleteFraudulentRule() {
    $('#deleteFraudulentRule').modal('hide');
    return new Promise((resolve, reject) => {
      this.connectionService.deleteRequest(`${environment.gateway}fraudulentRule/remove/` + this.fraudulentIdToDelete, 'application/json').subscribe(
        (data: any) => {
          $('#deleteFraudulentRule').modal('hide');
          this.showSuccessMessage("Fraudulent Rule Deleted!");
          this.fraudulentRuleId = data.object.id;
          this.getFraudulentRulesList(this.fraudulentRuleId).then(
            () => this.fraudulentForm.controls.fraudulentRule.setValue(this.fraudulentRuleId),
            () => this.fraudulentForm.controls.fraudulentRule.setValue(this.fraudulentRuleId)
          );
          resolve(data);
        },
        (error: any) => {
          $('#addNewFraudulentModal').modal('hide');
          this.showErrorMessage(error.error.msg);
          reject(error);
        }
      );
    });
  }
}
