<div class="pop">
    <button class="clk-btn  popover__trigger" (click)="clean()">
    <img src="../../../assets/images/icons/leasable-items/export-leasable.svg" alt="export-leasable" srcset="">
    Export
  </button>
    <div class="dropdown-content popover__menu">
        <div>
            <label class="labelWhite topSpacing"> Export Data </label>
            <hr />
        </div>
        <div>
            <label class="labelWhite"> Select Business </label>
        </div>
        <div>
            <form>
                <div *ngIf="vendorName !== '' ">
                    <input type="text " class="form-control inputVendorName" id="Business" readonly placeholder="Business" name="vendorName" ngModel="{{vendorName}}" value="{{ vendorName }} " />
                </div>
                <div *ngIf="vendorName == '' ">
                    <app-leasable-filter (newItemEvent)="selectVendor($event)" [selectedVendor]="cleanFilter" [searchClass]="exportClass"></app-leasable-filter>
                </div>
                <div>
                    <form>
                        <div class="radioButton">
                            <input class="allProducts" id="allProducts" type="radio" name="custom-radio-btn" (click)="onClick(false)">
                            <label class="custom-radio" for="allProducts"></label>
                            <span class="label-text">Export All Products</span>
                        </div>
                        <div *ngIf="itemsSelected.length !== 0" class="radioButton">
                            <input class="onlyProducts" id="onlyProducts" type="radio" name="custom-radio-btn" (click)="onClick(true)">
                            <label class="custom-radio" for="onlyProducts"></label>
                            <span class="label-text">Export Selected Only</span>
                        </div>
                    </form>
                </div>
                <div *ngIf="isExport; else elseButton">
                    <button class="btn " (click)="getItemsExport()">Export CSV</button>
                </div>
                <ng-template #elseButton>
                    <div>
                        <button class="btn disabled">Export CSV</button>
                    </div>
                </ng-template>
            </form>
        </div>
        <br>
    </div>
</div>