<div class="row sub-section-title">
    <div class="col-12">
        <img src="/assets/images/icons/declined.svg" alt="declined" />
        <span *ngIf="processStatus.info.declineSentence">
            {{processStatus.info.declineSentence}}
        </span>
        <span *ngIf="!processStatus.info.declineSentence">
            Application has been declined. It's not uncommon for a customer to get approved after they add a Co-Signer.
        </span>
    </div>
</div>