import { Component, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-additional-download-po',
    templateUrl: './additional-download-po.component.html',
    styleUrls: ['./additional-download-po.component.css']
})
export class AdditionalDownloadPOComponent implements OnInit {

    @Input() id = 0;

    public modalIdE = 'POError' + this.id;
    public messageTitleE = '';
    public messageMessageAE = '';
    public messageMessageBE = '';
    public messageMessageCE = '';
    public messageMessageAClassE = '';
    public messageMessageBClassE = '';
    public messageMessageCClassE = '';
    public dialogClassE = '';

    constructor(
        private connectionService: ConnectionService,
        public googleAnalyticsService: GoogleAnalyticsService
    ) { }

    ngOnInit() {
        this.modalIdE = 'POError' + this.id;
    }

    onClick() {
        const promise = new Promise((resolve, reject) => {

            this.connectionService.postRequest(
                environment.gateway + 'document/po/' + this.id, {}, 'application/json').subscribe(
                    (data: any) => {
                        data.object.forEach((poDocument: any) => {
                            var linkSource = 'data:application/pdf;base64,' + poDocument.documentB;
                            var downloadLink = document.createElement('a');
                            var fileName = 'PODocument.pdf';

                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();

                            this.googleAnalyticsService.trackEvent('additional-options', 'download', 'download-po');
                        });

                        resolve(data);
                    },
                    (error: any) => {
                        this.messageTitleE = 'Sorry, we found a problem';
                        this.messageMessageAE = 'Unfortunately we are unable to process your request at this moment.';
                        this.messageMessageBE = error.error.msg;
                        this.messageMessageCE = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.messageMessageBClassE = 'mB20';
                        this.dialogClassE = 'w600';
                        $('#errorModalDialogPOError' + this.id).modal();
                        reject(error);
                    }
                );
        });
        return promise;
    }

}
