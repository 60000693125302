export class DataRobotItem {
    public id: number;
    public description: string;
    public projectToken: string;
    public modelToken: string;
    public deploymentId: string;
    public testModel: boolean;
    public useDeploymentId: boolean;
    public modelAffix: string;
    public byOwner: boolean;

    constructor(data: any) {
        this.id = data.id;
        this.description = data.description;
        this.projectToken = data.projectToken;
        this.modelToken = data.modelToken;
        this.deploymentId = data.deploymentId;
        this.testModel = data.testModel;
        this.useDeploymentId = data.useDeploymentId;
        this.modelAffix = data.modelAffix;
        this.byOwner = data.byOwner;
    }
}