import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ClickSingService } from 'src/app/click-sing';


@Component({
    selector: 'app-confirm-terms',
    templateUrl: './confirm-terms.component.html',
    styleUrls: ['./confirm-terms.component.css']
})
export class ConfirmTermsComponent implements OnInit {
    private applicationId: string = null;
    private accessToken: string = null;
    private clickProcess = false;
    private token: string = null;
    docuSignUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer,
        private errorService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private clickSingService: ClickSingService,
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras.state) {
            this.token = navigation.extras.state.token;
            this.applicationId = navigation.extras.state.appId;
            this.accessToken = navigation.extras.state.accessToken;
        } else this.errorService.redirectToV3Error(
            null,
            'docusign-url-params',
            'click-sign-otp'
        );
    }

    get applicationData() {
        return this.clickSingService.formattedConnectedAccount;
    }

    get leaseTaxIncluded() {
        return this.clickSingService.leaseTaxIncluded;
    }

    private redirectToOffers() {
        this.router.navigate(['../offers-selection'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            state: {
                accessToken: this.accessToken,
                token: this.token,
                appId: this.applicationId,
                offersChanged: true
            }
        });
    }

    private redirectToSing(docusignUrl) {
        this.router.navigate(['../docusign-contract'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            state: {
                docusignUrl,
                token: this.token,
                emitApplicationId: this.applicationId,
                accesToken: this.accessToken
            }
        });
    }

    private validateResponse(response) {
        if (response && response.code === '412') {
            this.redirectToOffers();
        } else if (response && response.object) {
            this.redirectToSing(response.object.url);
        } else this.errorService.redirectToV3Error(
            response,
            'signDocusign',
            'click-sign-otp'
        );
    }

    ngOnInit() {
        this.googleAnalyticsService.trackClickSignEvent(
            'confirm-terms-open',
            this.clickSingService.vendorReferrerName,
            this.applicationId
        );
    }

    onContinueClick() {
        if (this.clickProcess) return

        this.clickProcess = true;     
        
        this.clickSingService.signDocument(
            this.applicationId,
            this.accessToken,
            this.token
        ).then(response => this.validateResponse(response))
            .catch(err => this.errorService.redirectToV3Error(
                err,
                'signDocusign',
                'click-sign-otp'
            ));
    }
}
