import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-declined',
  templateUrl: './declined.component.html',
  styleUrls: ['./declined.component.css']
})
export class DeclinedComponent implements OnInit {

  constructor(private googleAnalyticsService: GoogleAnalyticsService, private authService: AuthService) { }

  ngOnInit(): void {
    this.googleAnalyticsService.trackClickSignEvent('kba-decline', this.authService.vendorReferrerName);
  }

}
