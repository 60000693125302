import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-upload-invoice',
    templateUrl: './upload-invoice.component.html',
    styleUrls: ['./upload-invoice.component.css']
})
export class UploadInvoiceComponent implements OnInit {
    id: any = '';
    token: any;

    modalId = '';
    messageTitle = '';
    messageMessageA = '';
    messageMessageB = '';
    messageMessageC = '';
    messageMessageAClass = '';
    messageMessageBClass = '';
    messageMessageCClass = '';
    dialogClass = '';

    documents: any[] = [];
    uploadDocumentId = 'uploadDocumentId' + this.id;

    constructor(private route: ActivatedRoute, private router: Router, private connectionService: ConnectionService) { }

    ngOnInit(): void {
        //this.googleAnalyticsService.trackEvent("app-component", "app-upload-invoice");
        if (this.route.snapshot.paramMap.get('id') && this.route.snapshot.queryParams.token) {
            this.id = this.route.snapshot.paramMap.get('id');
            this.token = this.route.snapshot.queryParams.token;
            this.uploadDocumentId = 'uploadDocumentId' + this.id;
        } else {
            this.router.navigateByUrl('/');
        }

        this.modalId = 'UploadDocumentModalId' + this.id;
    }

    onFileAdded(file: any) {
        this.documents = [];
        this.documents.push(file);
    }

    uploadInvoiceFile() {
        return new Promise((resolve, reject) => {
            const nachaFile = {
                documentB: this.documents[0].file.data.substr(this.documents[0].file.data.indexOf(',') + 1),
                name: this.documents[0].file.name
            };
            this.connectionService.postRequest(environment.gateway + 'application/' + this.id + '/invoice', nachaFile,
                'application/json').subscribe(
                    (data: any) => {
                        this.messageTitle = 'Invoice Received';
                        this.messageMessageA = 'Invoice has been submitted and is under review for funding.';
                        this.messageMessageB = '';
                        this.messageMessageC = 'Approved invoices submitted by 5 pm eastern time should be funded the same day.';
                        this.messageMessageAClass = 'upload-success-a';
                        this.messageMessageBClass = 'upload-success-b';
                        this.messageMessageCClass = 'upload-success-c';
                        this.dialogClass = 'w650';
                        $('#errorModalDialogUploadDocumentModalId' + this.id).modal();
                        this.documents = [];
                        resolve(data);
                    },
                    (error: any) => {
                        this.messageTitle = 'Sorry, we found a problem';
                        this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
                        this.messageMessageB = error.error.msg;
                        this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                        this.messageMessageAClass = '';
                        this.messageMessageBClass = 'mB20';
                        this.messageMessageCClass = '';
                        this.dialogClass = 'w600';
                        $('#errorModalDialogUploadDocumentModalId' + this.id).modal();
                        this.documents = [];
                        resolve(error);
                    }
                );
        });
    }

    onCloseModal() {
        this.router.navigateByUrl('/home');
    }
}
