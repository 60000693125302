<button type="submit" class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular double-white"
    (click)="onClick();">
    <div>Resend</div>
    <div>Bank Verification Link</div>
</button>
<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="modalId" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w600" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title w-100 text-left position-absolute" id="exampleModalLongTitle">
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="close b" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="sendForm" (ngSubmit)="sendApp();">
                    <div class="row">
                        <div class="form-group col-12 info-type">
                            <label for="sameInfo">Has the information below changed?</label>
                            <input type="radio" formControlName="sameInfo" id="invoice-same-info-no" value="false"
                                name="sameInfo" (change)="changeSameInfo()">
                            <label for="invoice-same-info-no">No</label>
                            <input type="radio" formControlName="sameInfo" id="invoice-same-info-yes" value="true"
                                name="sameInfo" (change)="changeSameInfo()">
                            <label for="invoice-same-info-yes">Yes</label>
                            <label class="no-description" *ngIf="sendForm.controls.sameInfo.value === 'true'">
                                Please update the applicable fields below then click 'CONTINUE'.
                            </label>
                        </div>
                    </div>
                    <div class="row sub-section-form">
                        <div class="col-12 m-b-20">
                            <label for="name">
                                Guarantor's Email:
                                <div class="tooltip-two">
                                    <img src="/assets/images/icons/gray_i.svg" alt="gray i" />
                                    <span class="tooltip-two-text">
                                        Where contracts are sent.
                                    </span>
                                </div>
                            </label>
                            <input type="text" formControlName="ownerEmail" class="form-control" id="ownerEmail"
                                name="ownerEmail"
                                [ngClass]="{ 'is-invalid': submitted && sendForm.controls.ownerEmail.errors }">
                            <div *ngIf="submitted && sendForm.controls.ownerEmail.errors" class="invalid-feedback">
                                <div *ngIf="sendForm.controls.ownerEmail.errors.required">
                                    Guarantor email is required.
                                </div>
                                <div *ngIf="sendForm.controls.ownerEmail.errors.email">
                                    Invalid email address.
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="modalIdE" [title]="errorTitle" [messageA]="errorMessageA" [messageB]="errorMessageB"
    [messageC]="errorMessageC" [messageBClass]="errorMessageBClass" [dialogClass]="dialogClass">
</app-error-dialog>