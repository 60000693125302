import { Component, HostListener, OnInit } from '@angular/core';
import { V3IframeCommunicationService } from 'src/app/application/v3/services';

@Component({
    selector: 'app-v3-iframe-communication',
    templateUrl: './v3-iframe-communication.component.html',
    styleUrls: ['./v3-iframe-communication.component.css']
})
export class V3IframeCommunicationComponent implements OnInit {

    private iframeHostName: string = '*';

    constructor(private v3IframeCommunicationService: V3IframeCommunicationService) { }

    ngOnInit(): void {
        if(window.parent && window.parent.window) {
            window.parent.window.postMessage({type: 'parent-ecommerce-data', event: 'loaded'}, this.iframeHostName);
        }
    }

    @HostListener('window:message',['$event'])
    onMessage(e) {
        if(e.isTrusted && e.data && e.data.type == 'embed-application-data') {
            if(e.data.price) {
                this.v3IframeCommunicationService.setRequestedAmount(e.data.price);
            }
            if(e.data.hostname) {
                this.iframeHostName = e.data.hostname;
            }
            document.getElementById('close-ecommerce-iframe').style.display = "block";
        }
    }

    sendCloseEventToParent() {
        if(window.parent && window.parent.window) {
            window.parent.window.postMessage({type: 'parent-ecommerce-data', event: 'close'}, this.iframeHostName);
            location.reload();
        }
    }
}
