<app-header></app-header>
<div class="body-container">
    <div class="row">
        <div class="col-12">
            <div class="row fraud-list-title-container">
                <h1 class="fraud-list-title font-mont-regular">Fraud List</h1>
                <button class="btn btn-secondary font-mont-regular float-right" type="submit"
                    id="addNewFraudRowOpenDlgBtn" (click)="onCreateNewFraudulentSubmit()">
                    <div class="tooltip-two">
                        <div class="plus-area">
                            <em class="fas fa-plus"></em>
                        </div>
                        <span class="tooltip-two-text">
                            Click here to add a new fraudulent rule.
                        </span>
                    </div>
                </button>
            </div>
            <div class="form-row">
                <table aria-describedby="Rules Table" class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    SSN
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    Date of Birth
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    Home Address
                                </div>
                                <div class="font-mont-semi-bold margin-r-20">
                                    Zip Code
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    Home Address
                                </div>
                                <div class="font-mont-semi-bold margin-r-20">
                                    Street Number
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    Business Address
                                </div>
                                <div class="font-mont-semi-bold margin-r-20">
                                    Zip Code
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    Business Address
                                </div>
                                <div class="font-mont-semi-bold margin-r-20">
                                    Street Number
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    IP Address
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20">
                                    Source
                                </div>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let fraudulentRule of fraudulentRules | filter:filter:filterValue | sort:sortField:invertedSort; let i = index">
                            <tr class="row-data cursor-pointer">
                                <td>{{fraudulentRule.ssn | mask: '000-00-0000'}}</td>
                                <td class="text-center">{{fraudulentRule.dateOfBirth}}</td>
                                <td class="text-center">{{fraudulentRule.homeAddressZipCode}}</td>
                                <td class="text-center">{{fraudulentRule.homeAddressStreetNumber}}</td>
                                <td class="text-center">{{fraudulentRule.businessAddressZipCode}}</td>
                                <td class="text-center">{{fraudulentRule.businessAddressStreetNumber}}</td>
                                <td class="text-center">{{fraudulentRule.ipAddress}}</td>
                                <td class="text-center">{{fraudulentRule.source}}</td>
                                <td>
                                    <button type="button" id="deleteButton" name="deleteButton" *ngIf="userFraudulent"
                                        (click)="onDeleteFraudulentRule(fraudulentRule.id);" class="btn btn-primary ">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                            <tr class="no-highlight summary">
                                <td colspan="5">
                                    <div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addNewFraudulentModal" tabindex="-1" role="dialog" aria-labelledby="addNewFraudulentModal"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img alt="Close" src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="fraudulentModalLongTitle">
                    <img alt="Add new" src="/assets/images/icons/add_blue.svg" />
                    ADD NEW FRAUDULENT RULE
                </h5>
                <form [formGroup]="newFraudulentForm" (ngSubmit)="onCreateSubmit();">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="newSsn"
                                        [ngClass]="{ 'color-red': addFraudulentSubmitted && newFraudulentForm.controls.ssn.errors }">SSN:*</label>
                                    <input type="text" formControlName="ssn" class="form-control" id="newSsn"
                                        placeholder="SSN" name="ssn" maxlength="9"
                                        [ngClass]="{ 'is-invalid': addFraudulentSubmitted && newFraudulentForm.controls.ssn.errors }">
                                    <div *ngIf="addFraudulentSubmitted && newFraudulentForm.controls.ssn.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newFraudulentForm.controls.ssn.errors.required">
                                            SSN is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12 bsDatepicker">
                                    <label for="dateOfBirth"
                                        [ngClass]="{ 'color-red': addFraudulentSubmitted && newFraudulentForm.controls.dateOfBirth.errors }">Date
                                        of Birth:*</label>
                                    <input type="text" formControlName="dateOfBirth" class="form-control"
                                        id="dateOfBirth" name="dateOfBirth" placeholder="MM/DD/YY"
                                        [ngClass]="{ 'is-invalid': addFraudulentSubmitted && newFraudulentForm.controls.dateOfBirth.errors }"
                                        bsDatepicker [maxDate]="maxAgeDate"
                                        [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, containerClass: 'theme-dark-blue' }">
                                    <div *ngIf="addFraudulentSubmitted && newFraudulentForm.controls.dateOfBirth.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newFraudulentForm.controls.dateOfBirth.errors.required">
                                            Date of Birth is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="homeAddressZipCode"
                                        [ngClass]="{ 'color-red': addFraudulentSubmitted && newFraudulentForm.controls.homeAddressZipCode.errors }">Home
                                        Address Zip Code:*</label>
                                    <input type="text" formControlName="homeAddressZipCode" class="form-control"
                                        id="homeAddressZipCode" placeholder="Home Address Zip Code"
                                        name="homeAddressZipCode"
                                        [ngClass]="{ 'is-invalid': addFraudulentSubmitted && newFraudulentForm.controls.homeAddressZipCode.errors }">
                                    <div *ngIf="addFraudulentSubmitted && newFraudulentForm.controls.homeAddressZipCode.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newFraudulentForm.controls.homeAddressZipCode.errors.required">
                                            Home Address Zip Code is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="homeAddressStreetNumber"
                                        [ngClass]="{ 'color-red': addFraudulentSubmitted && newFraudulentForm.controls.homeAddressStreetNumber.errors }">Home
                                        Address Street Number:*</label>
                                    <input type="text" formControlName="homeAddressStreetNumber" class="form-control"
                                        id="homeAddressStreetNumber" placeholder="Home Address Street Number"
                                        name="homeAddressStreetNumber"
                                        [ngClass]="{ 'is-invalid': addFraudulentSubmitted && newFraudulentForm.controls.homeAddressStreetNumber.errors }">
                                    <div *ngIf="addFraudulentSubmitted && newFraudulentForm.controls.homeAddressStreetNumber.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newFraudulentForm.controls.homeAddressStreetNumber.errors.required">
                                            Home Address Street Number is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="businessAddressZipCode"
                                        [ngClass]="{ 'color-red': addFraudulentSubmitted && newFraudulentForm.controls.businessAddressZipCode.errors }">Business
                                        Address Zip Code:*</label>
                                    <input type="text" formControlName="businessAddressZipCode" class="form-control"
                                        id="businessAddressZipCode" placeholder="Business Address Zip Code"
                                        name="businessAddressZipCode"
                                        [ngClass]="{ 'is-invalid': addFraudulentSubmitted && newFraudulentForm.controls.businessAddressZipCode.errors }">
                                    <div *ngIf="addFraudulentSubmitted && newFraudulentForm.controls.businessAddressZipCode.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newFraudulentForm.controls.businessAddressZipCode.errors.required">
                                            Business Address Zip Code is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="businessAddressStreetNumber"
                                        [ngClass]="{ 'color-red': addFraudulentSubmitted && newFraudulentForm.controls.businessAddressStreetNumber.errors }">Business
                                        Address Street Number:*</label>
                                    <input type="text" formControlName="businessAddressStreetNumber"
                                        class="form-control" id="businessAddressStreetNumber"
                                        placeholder="Business Address Street Number" name="businessAddressStreetNumber"
                                        [ngClass]="{ 'is-invalid': addFraudulentSubmitted && newFraudulentForm.controls.businessAddressStreetNumber.errors }">
                                    <div *ngIf="addFraudulentSubmitted && newFraudulentForm.controls.businessAddressStreetNumber.errors"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="newFraudulentForm.controls.businessAddressStreetNumber.errors.required">
                                            Business Address Street Number is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="ipAddress"
                                        [ngClass]="{ 'color-red': addFraudulentSubmitted && newFraudulentForm.controls.ipAddress.errors }">Ip
                                        Address:*</label>
                                    <input type="text" formControlName="ipAddress" class="form-control" id="ipAddress"
                                        placeholder="Ip Address" name="ipAddress"
                                        [ngClass]="{ 'is-invalid': addFraudulentSubmitted && newFraudulentForm.controls.ipAddress.errors }">
                                    <div *ngIf="addFraudulentSubmitted && newFraudulentForm.controls.ipAddress.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newFraudulentForm.controls.ipAddress.errors.required">
                                            Ip Address is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" >
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right">
                                ADD RULE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteFraudulentRule" tabindex="-1" role="dialog" aria-labelledby="deleteFraudulentRule"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img alt="Close" src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="fraudulentModalLongTitle">
                    <img alt="Add new" src="/assets/images/icons/add_blue.svg" />
                    ARE YOU SURE?
                </h5>
                <button type="submit"
                    class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right"
                        data-dismiss="modal">
                    NO, CANCEL
                </button>
                <button type="button"
                    class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right"
                    id="confirmDeleteButton" (click)="onConfirmDeleteFraudulentRule()">
                    YES, DELETE
                </button>
            </div>
        </div>
    </div>
</div>

<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageBClass]="messageMessageBClass" [dialogClass]="dialogClass"></app-error-dialog>