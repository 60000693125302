import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-confirm-status',
  templateUrl: './confirm-status.component.html',
  styleUrls: ['./confirm-status.component.css']
})
export class ConfirmStatusComponent implements OnInit {  
  partner_name: string = "";

  isVerifyInfo: boolean = false;  
  
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
      const navigation = this.router.getCurrentNavigation();
      if (navigation.extras.state) {
        this.isVerifyInfo = navigation.extras.state.screen == 'verify' ? true : false;
      }
    }

  ngOnInit(): void {
    this.partner_name = this.authService.vendorReferrerName;

    if (this.isVerifyInfo){
      setTimeout(function(){
            $('.btnVerify').css({'opacity':'1'})
      }, 2000);
    }    
  }

}
