<label for="file{{elementId}}" class="upload-medium {{customClass}}" [ngClass]="{'done': uploaded}">
    <div class="drop-files" [attr.id]="elementId" [ngClass]="{'is-dragover': isDragOver}">
        <div class="uploader-image" [ngClass]="{'uploaded': fileName}"></div>
        <div class="font-mont-book line-a">{{fileName ? fileName : lineA}}</div>
        <div class="font-mont-book line-b">{{lineB}}</div>
        <div class="rounded-pill btn btn-primary btn-lg disabled bth font-mont-regular">
            CHOOSE FILE
        </div>
    </div>
</label>
<input type="file" id="file{{elementId}}" name="file{{elementId}}" (change)="fileChangeEvent($event)">