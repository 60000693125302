import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
declare var $: any;

@Injectable()
export class ToastService {

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  createToast(pTitle: string, pSubTitle: string, pBody: string, options: any) {
    let toastContainer = document.getElementById('toastContainer');
    if (toastContainer === null || toastContainer === undefined) {
      toastContainer = this.renderer.createElement('div');
      this.renderer.addClass(toastContainer, 'toast-container');
      this.renderer.setAttribute(toastContainer, 'id', 'toastContainer');
      this.renderer.appendChild(document.body, toastContainer);
    }
    const divToast = this.renderer.createElement('div');
    this.renderer.addClass(divToast, 'toast');
    this.renderer.setAttribute(divToast, 'role', 'alert');
    this.renderer.setAttribute(divToast, 'aria-live', 'assertive');
    this.renderer.setAttribute(divToast, 'aria-atomic', 'true');
    if (!options.autohide) {
      this.renderer.setAttribute(divToast, 'data-autohide', 'false');
    }

    const divToastHeader = this.renderer.createElement('div');
    this.renderer.addClass(divToastHeader, 'toast-header');
    this.renderer.appendChild(divToast, divToastHeader);

    const divImageToastHeader = this.renderer.createElement('div');
    this.renderer.addClass(divImageToastHeader, 'bd-placeholder-img');
    this.renderer.addClass(divImageToastHeader, 'rounded');
    this.renderer.addClass(divImageToastHeader, 'mr-2');
    this.renderer.addClass(divImageToastHeader, 'div-image-toast-header');
    this.renderer.appendChild(divToastHeader, divImageToastHeader);

    const strongHeader = this.renderer.createElement('strong');
    const StrongHeaderText = this.renderer.createText(pTitle);
    this.renderer.addClass(strongHeader, 'mr-auto');
    this.renderer.appendChild(strongHeader, StrongHeaderText);
    this.renderer.appendChild(divToastHeader, strongHeader);

    const smallHeader = this.renderer.createElement('small');
    const smallHeaderText = this.renderer.createText(pSubTitle);
    this.renderer.appendChild(smallHeader, smallHeaderText);
    this.renderer.appendChild(divToastHeader, smallHeader);

    if (options.close) {
      const buttonHeaderClose = this.renderer.createElement('button');
      this.renderer.addClass(buttonHeaderClose, 'ml-2');
      this.renderer.addClass(buttonHeaderClose, 'mb-1');
      this.renderer.addClass(buttonHeaderClose, 'close');
      this.renderer.setAttribute(buttonHeaderClose, 'type', 'button');
      this.renderer.setAttribute(buttonHeaderClose, 'data-dismiss', 'toast');
      this.renderer.setAttribute(buttonHeaderClose, 'aria-label', 'Close');
      this.renderer.appendChild(divToastHeader, buttonHeaderClose);

      const iHeaderButtonClose = this.renderer.createElement('i');
      this.renderer.addClass(iHeaderButtonClose, 'fas');
      this.renderer.addClass(iHeaderButtonClose, 'fa-times');
      this.renderer.setAttribute(iHeaderButtonClose, 'aria-hidden', 'true');
      this.renderer.appendChild(buttonHeaderClose, iHeaderButtonClose);
    }

    const divToastBody = this.renderer.createElement('div');
    const divToastBodyText = this.renderer.createText(pBody);
    this.renderer.addClass(divToastBody, 'toast-body');
    this.renderer.appendChild(divToastBody, divToastBodyText);
    this.renderer.appendChild(divToast, divToastBody);

    this.renderer.appendChild(toastContainer, divToast);
    $(divToast).toast({ delay: 5000 });
    $(divToast).toast('show');
    $(divToast).on('hidden.bs.toast', function () {
      $(divToast).toast('dispose');
      this.renderer.removeChild(toastContainer, divToast);
    }.bind(this));

    return divToast;
  }

  closeToast(pToast: any) {
    $(pToast).toast('hide');
  }
}
