<app-header></app-header>
<div class="container-fluid main-table">
    <div class="row">
        <div class="table-container">
            <div style="line-height: 250%;">
                <div class="row mt-3">
                    <div class="d-flex justify-content-start">
                        <h3 class="title-product">
                            Product Catalog
                        </h3>
                        <span class="selected">{{selectedItems}} Selected </span>
                        <a class="slash">|</a>
                        <div class="pop" #bulkDrop>
                            <!--Sort-->
                            <button class="btn-bulk popover__trigger"><u>Bulk Edit</u></button>
                            <div class="dropdown-content popover__menu bulkTable">
                                <button class="bulk-op" (click)="deleteSelected()">
                  <img class="bulk-icon" src="../../../assets/images/icons/leasable-items/bulk-delete.svg"
                    alt="bulk-delete">
                  <span class="bulk">
                    Delete {{selectedItems}} items
                  </span>
                </button>
                                <button class="bulk-op" (click)="submitItems()" (click)="isSubmit=false">
                  <img class="bulk-icon" src="../../../assets/images/icons/leasable-items/bulk-submit.svg"
                    alt="bulk-submit">
                  <span class="bulk">
                    Submit {{selectedItems}} items for Review
                  </span>
                </button>
                                <!-- data-toggle="modal"  -->
                                <button class="bulk-op" data-target="#myModal" (click)="convertModal()">
                  <img class="bulk-icon" src="../../../assets/images/icons/leasable-items/bulk-edit.svg"
                    alt="bulk-edit">
                  <span class="bulk"> Edit  Status of {{selectedItems}} items</span>
                </button>
                                <!-- data-toggle="modal"  -->
                                <button class="bulk-op" data-target="#myModal" (click)="isBulk = true" (click)="submit(importForm)" *ngIf="isAdmin">
                  <img class="bulk-icon" src="../../../assets/images/icons/leasable-items/bulk-approve.svg"
                    alt="bulk-edit">
                  <span class="bulk"> Approve {{selectedItems}} items</span>
                </button>
                            </div>
                        </div>
                    </div>

                    <div class="ml-auto buttons-header justify-content-end">
                        <div class="pop" #sortDrop>
                            <!--Sort-->
                            <button class="clk-btn popover__trigger">Sort by: <label class="sortLabel">{{sortField}}</label><img
                  src="/assets/images/icons/down.svg" /></button>
                            <div class="dropdown-content popover__menu sortTable">
                                <a (click)="sortBy('status,asc')">Status</a>
                                <a (click)="sortBy('title,asc')">Ascending</a>
                                <a (click)="sortBy('title,desc')">Descending</a>
                            </div>
                        </div>
                        <!--End Sort-->
                        <div class="pop" #toggleButton>
                            <button class="clk-btn popover__trigger" (click)="clean()">
                <img src="../../../assets/images/icons/leasable-items/filter_leasable.svg" alt="filter_leasable">
                Filter
                <label class="tag" *ngIf="isfiltered">
                  <img class="imgTag" src="../../../assets/images/icons/leasable-items/quit_clean.svg" alt="quit_clean"
                    (click)="unfilter()"> {{vendorName}}
                </label>
              </button>
                            <div class="dropdown-content spacing1 popover__menu">
                                <div class="">
                                    <label class="labelWhite topSpacing"> Filter Items </label>
                                    <hr class="line" />
                                </div>
                                <div>
                                    <label class="labelWhite"> By Business </label>
                                </div>
                                <div>
                                    <form>
                                        <div>
                                            <app-leasable-filter (newItemEvent)="selectVendor($event)" [selectedVendor]="cleanFilter" [searchClass]="filterClass">
                                            </app-leasable-filter>
                                        </div>
                                        <button class="btn" (click)="filterItems()">Filter Items </button>
                                    </form>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                    <button class="clk-btn" name="add" data-toggle="modal" data-target="#myModal" (click)="option(true)">
            <img src="../../../assets/images/icons/leasable-items/add_new.svg" alt="add_new"> Add New</button>
                    <app-leasable-import (toTable)=" getImportElements($event)"></app-leasable-import>
                    <div #menu>
                        <app-leasable-export [itemsSelected]="itemsSelected" [vendorName]="vendorName" [vendorIdNew]="vendorID" (newItemEvent)="selectAlert($event)">
                        </app-leasable-export>
                    </div>
                </div>
            </div>

            <div class="row">
                <div style="min-height: 260px">
                    <table class="table app-list-table table-responsive">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="col" class="text-left">
                                    <div class="div-style title-style">Product Title</div>
                                </th>
                                <th scope="col" class="text-left">
                                    <div class="div-style title-style">Business</div>
                                </th>
                                <th scope="col" class="hide-mobile">
                                    <div class="div-style sku-style">SKU</div>
                                </th>
                                <th scope="col" class="hide-mobile">
                                    <div class="div-style des-style">Description</div>
                                </th>
                                <th scope="col" class="hide-mobile">
                                    <div class="div-style status-style">Status</div>
                                </th>
                                <!-- <th scope="col" class="td-w-130 hide-mobile">
                                    <div class="pop" #filterStatus> -->
                                <!--Sort-->
                                <!-- <div class="div-style status-style popover__trigger">Status <img
                                            src="/assets/images/icons/leasable-items/filter_leasable.svg" /></div>
                                        <div class="dropdown-content popover__menu statusFilter">
                                        <div class="row align-items-start" *ngFor="let status of statusList">
                                            <input class="square-fix" type="checkbox" [(ngModel)]="status.checked"
                                            (click)="filterByStatus(status.name, $event.target.checked)" />
                                            <label class="square-label">{{status.name}}</label>
                                        </div>
                                        </div>
                                    </div>
                                    </th> -->
                                <th *ngIf="isAdmin" scope="col" class="td-w-130 hide-mobile">
                                    <div class="div-style status-style">Actions</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of items[0];index as i">
                                <tr>
                                    <!-- CHECKBOXES -->
                                    <!-- <ng-template *ngIf="isAdmin"> -->
                                    <td *ngIf="item.status!=='Rejected' && item.sku.length > 0; else elseBlock">
                                        <input type="checkbox" name="slc_option" class="slcItem" id="slc-{{i}}" (click)="onLoad()" (click)="getSelected(i)">
                                    </td>
                                    <!-- </ng-template> -->
                                    <ng-template #elseBlock>
                                        <td [ngClass]="{'rejectedTd':item.status=='Rejected','':item.status!=='Rejected','noSkuTd':item.sku.length <= 0}">
                                            <input type="checkbox" name="slc_option" class="slcItem" id="slc-{{i}}" (click)="onLoad()" disabled>
                                        </td>
                                    </ng-template>
                                    <!-- END CHECKBOXES -->

                                    <!-- TITLE -->
                                    <td class="item-title" [ngClass]="{'rejectedTd':item.status=='Rejected','':item.status!=='Rejected','noSkuTd':item.sku.length <= 0}">
                                        {{item.title}}</td>

                                    <!-- BUSINESS TITLE -->
                                    <td class="business-title" [ngClass]="{'rejectedTd':item.status=='Rejected','':item.status!=='Rejected','noSkuTd':item.sku.length <= 0}">
                                        {{vendorName}}</td>
                                    <!-- SKU -->
                                    <td class="sku-title" *ngIf="item.sku.length > 0; else noSku" [ngClass]="{'rejectedTd':item.status=='Rejected','':item.status!=='Rejected','noSkuTd':item.sku.length <= 0}">
                                        {{item.sku}}</td>
                                    <ng-template #noSku>
                                        <td [ngClass]="{'noSkuTd':item.sku.length <= 0}">{{item.sku}}
                                        </td>
                                    </ng-template>
                                    <!-- DESCRIPTION -->
                                    <td class="descrip" [ngClass]="{'rejectedTd':item.status=='Rejected','':item.status!=='Rejected','noSkuTd':item.sku.length <= 0}">
                                        {{item.description}}</td>
                                    <td *ngIf="item.sku.length > 0; else noSkuStatus" [ngClass]="{'rejectedTd':item.status=='REJECTED','':item.status!=='REJECTED','noSkuTd':item.sku.length <= 0}">
                                        <div [ngClass]="{'success':item.status==='LEASABLE','review':item.status==='IN REVIEW','rejected':item.status==='REJECTED','draft':item.status==='DRAFT','pending':item.status==='PENDING'}">
                                            <label class="status" for="status">{{item.status}}</label>
                                        </div>
                                    </td>
                                    <ng-template #noSkuStatus>
                                        <td [ngClass]="{'noSkuTd':item.sku.length <= 0}">
                                            <div [ngClass]="{'draft-errors':item.status==='DRAFT'}">
                                                <label class="status" for="status">
                          <img src="../../../assets/images/icons/leasable-items/warning.svg" alt="">{{item.sku}}
                          {{item.status}}</label>
                                            </div>
                                        </td>
                                    </ng-template>

                                    <td *ngIf="isAdmin" [ngClass]="{'rejectedTd':item.status=='REJECTED','':item.status!=='REJECTED','noSkuTd':item.sku.length <= 0}">
                                        <a data-toggle="modal" data-target="#myModal" (click)="getItem(i)" class="buttons_space" (click)="option(false)">
                                            <img src="../../../assets/images/icons/leasable-items/action-edit.svg" alt="action-edit">
                                        </a>
                                        <a class="buttons_space" [ngClass]="{'disabled': item.sku.length <= 0 || item.status ==='LEASABLE'||item.status ==='PENDING','enable':item.status==='REJECTED'}" (click)="isSubmit = true" (click)="getItem(i)" (click)="submitItems()">
                                            <img src="../../../assets/images/icons/leasable-items/action-submit.svg" alt="action-submit">
                                        </a>
                                        <a class="popDelete buttons_space" (click)="deletepop(i)" (click)="getItem(i)" (click)="option(false)">
                                            <img src="../../../assets/images/icons/leasable-items/delete_icon.svg" alt="delete_icon">
                                            <div class="tooltiptext" *ngIf="delPop === i">
                                                <div class="form-group ">
                                                    <label class="titleDelete">Are you sure?</label>
                                                    <label class="messageDelete">Are you sure you want to delete this item?</label>
                                                </div>
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <button type="button " class="btnDelete" (click)="individualDelete(selectItem.sku)">
                              Delete
                            </button>
                                                    </div>
                                                    <div class="col-6">
                                                        <button type="button" class="btnCancel" (click)="deletepop('')">
                              Cancel
                            </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <div class="table-responsive mh-260" style="min-height: 260px">
                        <app-pagination [current]="current" [total]="total" (goTo)="onGoTo($event)" (next)="onNext($event)" (previous)="onPrevious($event)" (perPage)="onPerPage($event)">
                        </app-pagination>
                        <br>
                        <div *ngIf="isError" class="alertExport">
                            <label for="one" class="alert-message">
                <button class="close" (click)="closeAlert()">
                  <img src="../../../assets/images/icons/leasable-items/quit.svg" alt="quit">
                </button>
                Items not found.
              </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- //Modal Edit// -->
<div class="modal fade" id="myModal" tabindex="-1 " role="dialog " aria-labelledby="myModalLabel " aria-hidden="true ">
    <div class="modal-dialog " style="margin-top: 115px;">
        <div class="modal-content">
            <div class="modal-body ">
                <div *ngIf="isEnabled == true && lastStatus === 'PENDING' && isAdmin">
                    <button class=" rejectedButton rejectedOrAproveLabel" (click)="rejectorAprove(false)" (click)="submit(importForm)">Reject</button>
                    <button class="aproveButton rejectedOrAproveLabel" (click)="rejectorAprove(true)" (click)="submit(importForm)">Approve</button>
                    <br>
                </div>
                <div class="form-group close_button">
                    <button type="button " class="close" data-dismiss="modal" aria-label="Close ">
            <img src="../../../assets/images/icons/leasable-items/quit.svg" alt="quit_clean">
          </button>
                </div>
                <div class="container form-group labelHeader">
                    <!-- <label class="labelLeasable ">LEASABLE</label> -->
                    <div *ngIf="!isBulk" class="marginStatusEdit" [ngClass]="{ success: selectItem.status === 'LEASABLE', review: selectItem.status === 'IN REVIEW', rejected: selectItem.status === 'REJECTED', draft: selectItem.status === 'DRAFT','pending':selectItem.status==='PENDING' }">
                        <label class="status" for="status">{{ selectItem.status }}</label>
                    </div>
                    <button *ngIf="isEnabled == true" type="button" class="align_trash" data-toggle="modal" data-target="#modalDelete">
            <img src="../../../assets/images/icons/leasable-items/delete_icon.svg" alt="">
          </button>
                    <div class="modal fade" id="modalDelete" tabindex="-1" aria-labelledby="selectOfferModalTitle" aria-hidden="true">
                        <div class="modal-dialog deleteModalDialog ">
                            <div class="modal-content rounded-0">
                                <div class="modal-body-delete">
                                    <div class="form-group ">
                                        <label class="titleDelete">Are you sure?</label>
                                        <label class="messageDelete">Are you sure you want to delete this item?</label>
                                    </div>
                                    <div class="row ">
                                        <div class="col-6 ">
                                            <button type="button " class="btnDelete" (click)="individualDelete(selectItem.sku)">
                        Delete
                      </button>
                                        </div>
                                        <div class="col-6 ">
                                            <button type="button" class="btnCancel" (click)="closeModal()">
                        Cancel
                      </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <form (ngSubmit)="submit(importForm)" #importForm="ngForm">
                    <div class="container">
                        <div class="form-group">
                            <label class="label-select" for="Business " *ngIf="!isBulk">Business</label>
                            <label class="label-select" for="Business " *ngIf="isBulk">Update Status</label>
                            <div class="radio-update-status" *ngIf="isBulk">
                                <div *ngIf="itemsSelected.length > 0; else noItems">
                                    <input type="radio" id="onlySelected" name="editItems" class="radio-fix" value="onlySelected" ngModel>
                                    <label class="bulk-status-label">Selected Only</label>
                                    <input type="radio" id="allItems" value="allItems" name="editItems" class="radio-fix" ngModel>
                                    <label class="bulk-status-label">All filters for {{vendorName}}</label>
                                </div>
                                <ng-template #noItems>
                                    <input type="radio" disabled="true" id="onlySelected" name="editItems" class="radio-fix" value="onlySelected" ngModel>
                                    <label class="bulk-status-label">Selected Only</label>
                                    <input type="radio" id="allItems" value="allItems" name="editItems" class="radio-fix" (click)="isSubmit = true" ngModel>
                                    <label class="bulk-status-label">All filters for {{vendorName}}</label>
                                </ng-template>

                            </div>

                            <div *ngIf="isBulk">
                                <label class="label-select" for="selectStatus">Status</label>
                                <select class="form-control" name="selectStatus" ngModel>
                  <option class="form-control" value="LEASABLE" *ngIf="isAdmin">Leasable</option>
                  <option class="form-control" value="DRAFT">Draft</option>
                  <option class="form-control" value="REJECTED" *ngIf="isAdmin">Rejected</option>
                   <!--<option [ngValue]="product.sku" selected disabled>-</option>
                 <option [ngValue]="op" *ngFor="let op of headersProducts">
                    {{op}}
                  </option>-->
                </select>
                            </div>

                            <div *ngIf="!isVendor && !isBulk">
                                <input type="text " class="form-control" id="Business" readonly placeholder="Business" name="vendorName" ngModel="{{vendorName}}" value="{{ vendorName }} " />
                            </div>
                            <div *ngIf="isVendor">
                                <app-leasable-filter (newItemEvent)="selectVendor($event)" [selectedVendor]="cleanFilter" [searchClass]="modalClass">
                                </app-leasable-filter>
                            </div>
                        </div>
                        <div *ngIf="!isBulk" class="form-group">
                            <label class="label-select" for="Product ">Product Title</label>
                            <input type="text " class="form-control" id="Product" placeholder="Product Title" name="title" ngModel="{{selectItem.title}}" value="{{selectItem.title}}" />
                        </div>
                        <div *ngIf="!isBulk" class="form-group">
                            <label class="label-select " for="SKU ">SKU</label>
                            <input type="text " class="form-control val" id="sku" placeholder="SKU" name="sku" ngModel="{{selectItem.sku}}" value="{{ selectItem.sku }}" />
                        </div>
                        <div *ngIf="!isBulk" class="form-group ">
                            <label class="label-select " for="Description ">Description</label>
                            <textarea name="texto " class="form-control " name="description" ngModel="{{selectItem.description}}" value="{{ selectItem.description }}" id="Description" rows="4 " cols="10 " placeholder="Description"></textarea>
                        </div>
                        <!-- <div *ngIf="!isBulk" class="form-group col">
                            <div class="row align-items-start">
                                <div class="form-check form-check-inline pr-2">
                                    <input class="form-check-input" type="checkbox" name="Title" id="Title" value="" />
                                    <label class="form-check-label check_label" for="Title">Title Required</label>
                                </div>
                                <div class="form-check form-check-inline pr-2">
                                    <input class="form-check-input" type="checkbox" name="Bundle" id="Bundle" value="" />
                                    <label class="form-check-label check_label" for="Bundle">Is Bundle</label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row ">
                        <div class="col-6 ">
                            <button type="button " class="button_Cancel " data-dismiss="modal " (click)="currentPage() ">
                <i class="cancel_Font ">Cancel</i>
              </button>
                        </div>
                        <div class="col-6 ">
                            <button type="submit" class="button_Save ">
                <i class="save_Font ">Save</i>
              </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
