<app-application-wrapper [subTitleA]="'LEASE AGREEMENT'" [subTitleB]="'STEP 3 OF 4'">
    <ng-template appApplicationWrapperHelper>
        <div>
            <h1 class="title font-mont-bold">Review your lease <br> payments</h1>
            <div class="terms">
                <ul class="timeline">
                    <li class="timeline-item">
                        <div class="">
                            <img class="img" src="/assets/images/icons/documentation_payments.svg" alt="" />
                            <img class="content_vector_doc" src="/assets/images/icons/vector_parments.svg" alt="" />
                        </div>
                        <div class="timeline-content documentation">
                            <div>
                                <div class="font-mont-bold">Documentation fee</div>
                                <div class="font-mont-regular">{{ applicationData.docFee | currency:'$ ':'code':'.2-2' }}</div>
                            </div>
                            <div class="font-mont-regular">This one-time payment will be charged from ••• {{applicationData.accountNumbers}} once the invoice is uploaded and approved.</div>
                        </div>
                    </li>
                    <li class="timeline-item">
                        <div class="">
                            <img class="img" src="/assets/images/icons/lease_payments.svg" alt="" />
                        </div>
                        <div class="timeline-content lease">
                            <div class="font-mont-regular">Lease payments start one month later.</div>
                        </div>
                    </li>
                    <li class="timeline-item">
                        <div class="">
                            <img class="img" src="/assets/images/icons/montly_payments.svg" alt="" />
                        </div>
                        <div class="timeline-content montly">
                            <div>
                                <div class="font-mont-bold">Monthly for {{applicationData.leaseTerm}} months</div>
                                <div class="font-mont-regular">{{ applicationData.totalPayment | currency:'$':'code':'.2-2' }} </div>
                            </div>
                            <div class="font-mont-regular">Your recurring payments may begin before the equipment is delivered.</div>
                        </div>
                    </li>
                    <p class="font-mont-regular">Includes estimated taxes and purchase option payments.
                    </p>
                </ul>
            </div>

            <button type="submit" class="btn btn-secondary btn-lg submit-btn w-100 font-mont-bold" (click)="onContinueClick()">Continue</button>
            <div class="back">
                <a href="javascript:history.back()" class="font-mont-book">Go Back</a>
            </div>
        </div>
    </ng-template>
    <ng-container ngProjectAs="[image]">
        <img src="../../../../assets/images/logoPayment.svg" alt="">
    </ng-container>
</app-application-wrapper>