import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingScreenService {

    private _loading = false;
    private _finicityLoading = false;
    loadingStatus: Subject<boolean> = new Subject();
    finicityLoadingStatus: Subject<boolean> = new Subject();

    get loading(): boolean {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
        this.loadingStatus.next(value);
    }

    get finicityLoading(): boolean {
        return this._finicityLoading;
    }

    set finicityLoading(value) {
        this._finicityLoading = value;
        this.finicityLoadingStatus.next(value);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startFinicityLoading() {
        this.finicityLoading = true;
    }

    stopFinicityLoading() {
        this.finicityLoading = false;
    }
}
