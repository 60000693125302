export function maskBankAccount(bankNumber: string): number {
    let result;
    if (bankNumber && bankNumber.length > 4) {
        const maskCount = bankNumber.substring(0, bankNumber.length - 4).length;
        let stringResult = "";
    
        for (let i = 0; i < maskCount; i++) {
            stringResult = stringResult + '*';
        }
        result = stringResult + bankNumber.substr(-4);
    } else {
        result = bankNumber;
    }

    return Number(result);
}