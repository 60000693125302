import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
    RequestsParams,
    RequestResponse,
    MyHttpParams,
} from 'src/app/models';

@Injectable()
export class ConnectionService {
    private applicationSource: string = environment.xApplicationSource;
    public applicationSources = {
        vp: 'VP',
        rc: 'RC',
        woocommerce: 'WC',
        jsdk: 'JSDK',
        shopify: 'SP'
    };

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        const newSource = new URLSearchParams(window.location.search).get('source');
        if (newSource && this.applicationSources[newSource]) {
            this.applicationSource = newSource;
        }
    }

    get isProduction() {
        return environment.production;
    }

    private getHttpHeadders(pContentType: string): HttpHeaders {
        if (environment.userSecret && environment.x3scaleProxySecretToken && environment.xClkToken) {
            return new HttpHeaders({
                'Content-type': pContentType,
                'user-secret': environment.userSecret,
                'X-3scale-proxy-secret-token': environment.x3scaleProxySecretToken,
                'X-clk-token': environment.xClkToken,
                'X-application-source': this.applicationSources[this.applicationSource],
                'X-environment-url': environment.xEnvironmentUrl
            });
        } else {
            return new HttpHeaders({
                'Content-type': pContentType,
                'X-application-source': this.applicationSources[this.applicationSource],
                'X-environment-url': environment.xEnvironmentUrl
            });
        }
    }

    private getV3HttpHeaders(pContentType: string, pToken: string): HttpHeaders {
        if (pToken) {
            return new HttpHeaders({
                'Content-type': pContentType,
                'Authorization': 'Bearer ' + pToken,
                'X-application-source': this.applicationSources[this.applicationSource],
                'X-environment-url': environment.xEnvironmentUrl
            });
        } else {
            return new HttpHeaders({
                'Content-type': pContentType,
                'X-application-source': this.applicationSources[this.applicationSource],
                'X-environment-url': environment.xEnvironmentUrl
            });
        }
    }

    getApplicationSource() {
        return this.applicationSources[this.applicationSource];
    }

    getV3Request(url: string, contentType?: string, access_token?: string, blobType?: boolean) {
        let httpOptions: any = {
            headers: {}
        };

        if (blobType) {
            httpOptions.responseType = 'blob' as 'json';
        }

        httpOptions.headers = this.getV3HttpHeaders(contentType, access_token);
        return this.httpClient.get(url, httpOptions);
    }

    postV3Request(url: string, postData: any, contentType?: string, access_token?: string, vendor_token?: string) {
        let httpOptions = {
            headers: {},
            params: {}
        };
        httpOptions.headers = this.getV3HttpHeaders(contentType, access_token);
        if (vendor_token) {
            httpOptions.params = new HttpParams().set('token', vendor_token);
        }
        return this.httpClient.post(url, postData, httpOptions);
    }

    patchV3Request(url: string, postData: any, contentType?: string, access_token?: string) {
        let httpOptions = {
            headers: {},
            params: {}
        };
        httpOptions.headers = this.getV3HttpHeaders(contentType, access_token);
        return this.httpClient.patch(url, postData, httpOptions);
    }

    postV3EmptyHeader(url: string, postData: any, contentType?: string) {
        let httpOptions = {
            headers: {},
            params: {}
        };
        httpOptions.headers = new HttpHeaders({
            'Content-type': contentType
        });
        return this.httpClient.post(url, postData, httpOptions);
    }

    postRequest(pUrl: string, pPostData: any, pContentType: string, pParams?: string) {
        let url = pUrl;
        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const postData = pPostData;
        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: new HttpParams().set('access_token', this.authService.getAccessToken())
        };
        return this.httpClient.post(url, postData, httpOptions);
    }

    postLeasableRequest(pUrl: string, pPostData: any, pContentType: string, pParams?: string, pTokenType?: string, pToken?: string) {
        let url = pUrl;
        let httpOptionsParams: HttpParams;


        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const postData = pPostData;
        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: httpOptionsParams
        };
        return this.httpClient.post(url, postData, httpOptions);
    }

    postCustomRequest(pUrl: string, pPostData: any, pContentType: string, pParams?: string, pTokenType?: string, pToken?: string) {
        let url = pUrl;
        let httpOptionsParams: HttpParams;

        if (pTokenType) {
            if (pToken) {
                httpOptionsParams = new HttpParams().set(pTokenType, pToken);
            } else {
                httpOptionsParams = new HttpParams().set(pTokenType, this.authService.getAccessToken());
            }
        } else {
            httpOptionsParams = new HttpParams().set('access_token', this.authService.getAccessToken());
        }

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const postData = pPostData;
        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: httpOptionsParams
        };
        return this.httpClient.post(url, postData, httpOptions);
    }

    postRequestWithToken(pUrl: string, pPostData: any, pContentType: string, token: string, pParams?: string) {
        let url = pUrl;

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const postData = pPostData;
        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: new HttpParams().set('token', token)
        };
        return this.httpClient.post(url, postData, httpOptions);
    }

    postRequestWithAccessToken(pUrl: string, pPostData: any, pContentType: string, token: string, pParams?: string) {
        let url = pUrl;

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const postData = pPostData;

        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: new HttpParams().set('access_token', token)
        };
        return this.httpClient.post(url, postData, httpOptions);
    }

    getRequest(pUrl: string, pContentType: string, pParams?: string, pOptions?: any, pParamsObject?: any) {
        let url = pUrl;
        let params: HttpParams;

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        if (pOptions != null && pOptions !== undefined) {
            params = new MyHttpParams({
                interceptorMetadata: { options: pOptions }
            }).set('access_token', this.authService.getAccessToken());
        } else {
            params = new HttpParams().set('access_token', this.authService.getAccessToken());
        }

        if (pParamsObject) {
            params = params.append(pParamsObject.name, pParamsObject.value);
        }

        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: params
        };

        return this.httpClient.get(url, httpOptions);
    }

    getLeasableRequest(pUrl: string, pContentType: string, pParams?: string, pOptions?: any): Observable<any> {
        let url = pUrl;
        let params: HttpParams;

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
        };

        return this.httpClient.get(url, { observe: 'response' });
    }

    getCustomRequest(pUrl: string, pContentType: string, pParams?: string, pTokenType?: string, pToken?: string) {
        let url = pUrl;
        let httpOptionsParams: HttpParams;

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        if (pTokenType) {
            if (pToken) {
                httpOptionsParams = new HttpParams().set(pTokenType, pToken);
            } else {
                httpOptionsParams = new HttpParams().set(pTokenType, this.authService.getAccessToken());
            }
        } else {
            httpOptionsParams = new HttpParams().set('access_token', this.authService.getAccessToken());
        }


        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: httpOptionsParams
        };

        return this.httpClient.get(url, httpOptions);
    }

    getRequestWithToken(pUrl: string, pContentType: string, token: string, pParams?: string) {
        let url = pUrl;

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: new HttpParams().set('token', token)
        };

        return this.httpClient.get(url, httpOptions);
    }

    getRequestWithAccessToken(pUrl: string, pContentType: string, token: string, pParams?: string) {
        let url = pUrl;

        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }

        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: new HttpParams().set('access_token', token)
        };

        return this.httpClient.get(url, httpOptions);
    }

    deleteRequest(pUrl: string, pContentType: string) {
        const url = pUrl;
        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: new HttpParams().set('access_token', this.authService.getAccessToken())
        };
        return this.httpClient.delete(url, httpOptions);
    }

    deleteLeasableListRequest(pUrl: string, pDelData: any, pContentType: string, pParams?: string) {
        let url = pUrl;
        let httpOptionsParams: HttpParams;
        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }
        const delData = pDelData;
        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: httpOptionsParams,
            body: delData
        };
        return this.httpClient.delete(url, httpOptions);
    }

    deleteLeasableRequest(pUrl: string, pContentType: string, pParams?: string) {
        let url = pUrl;
        let httpOptionsParams: HttpParams;
        if (pParams != null && pParams !== undefined) {
            url = url + '?' + pParams;
        }
        const httpOptions = {
            headers: this.getHttpHeadders(pContentType),
            params: httpOptionsParams
        };
        return this.httpClient.delete(url, httpOptions);
    }

    postRequestUnsecured(pUrl: string, pPostData: any, pContentType: string) {
        const url = pUrl;
        const postData = pPostData;
        const httpOptions = { headers: this.getHttpHeadders(pContentType) };

        return new Promise((resolve, reject) => {
            this.httpClient.post(url, postData, httpOptions).pipe(tap(
                data => resolve(data),
                error => resolve(error)
            )).subscribe();
        });
    }

    getRequestUnsecured(pUrl: string, pContentType: string) {
        const url = pUrl;
        const httpOptions = { headers: this.getHttpHeadders(pContentType) };
        return this.httpClient.get(url, httpOptions);
    }

    // -

    getV3RequestP<T = RequestResponse>({
        url,
        contentType,
        access_token,
        blobType
    }: RequestsParams): Promise<T> {

        return new Promise((resolve, reject) => {
            const httpOptions: any = {
                headers: this.getV3HttpHeaders(contentType, access_token)
            };

            if (blobType) httpOptions.responseType = 'blob' as 'json';

            this.httpClient.get<T>(url, httpOptions).subscribe({
                next: response => resolve(<T>response),
                error: error => reject(error)
            });
        })
    }

    patchV3RequestP<T = RequestResponse>({
        contentType,
        postData,
        url,
        access_token
    }: RequestsParams): Promise<T> {
        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: this.getV3HttpHeaders(contentType, access_token),
                params: {}
            };

            this.httpClient.patch<T>(url, postData, httpOptions).subscribe({
                next: response => resolve(response),
                error: error => reject(error),
            })
        })
    }

    postCustomRequestP<T = RequestResponse>({
        url,
        params,
        postData,
        contentType,
        tokenType = 'access_token',
        token
    }: RequestsParams): Promise<T> {

        return new Promise((resolve, reject) => {
            if (params != null && params !== undefined) url = `${url}?${params}`;

            this.httpClient.post<T>(url, postData, {
                headers: this.getHttpHeadders(contentType),
                params: new HttpParams().set(tokenType, token)
            }).subscribe({
                next: response => resolve(response),
                error: err => reject(err)
            })
        });
    }

    postV3EmptyHeaderP<T = RequestResponse>({
        url,
        postData,
        contentType
    }: RequestsParams): Promise<T> {
        return new Promise((resolve, reject) =>
            this.httpClient.post<T>(url, postData, {
                headers: new HttpHeaders({
                    'Content-type': contentType
                }),
                params: {}
            }).subscribe({
                next: response => resolve(response),
                error: error => reject(error),
            })
        );
    }

    postV3RequestP<T = RequestResponse>({
        url,
        postData,
        contentType,
        access_token,
        vendor_token
    }: RequestsParams): Promise<T> {
        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: this.getV3HttpHeaders(contentType, access_token),
                params: {}
            };
            if (vendor_token) {
                httpOptions.params = new HttpParams().set('token', vendor_token);
            }

            this.httpClient.post<T>(url, postData, httpOptions).subscribe({
                next: response => resolve(response),
                error: error => reject(error)
            });
        });
    }

    getRequestUnsecuredP<T = RequestResponse>({
        url,
        contentType
    }: RequestsParams): Promise<T> {
        return new Promise((resolve, reject) =>
            this.httpClient.get<T>(url, {
                headers: this.getHttpHeadders(contentType)
            }).subscribe({
                next: response => resolve(response),
                error: error => reject(error)
            })
        );
    }
}
