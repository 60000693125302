import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { ClickSingService } from 'src/app/click-sing';

@Component({
  selector: 'app-drivers-license',
  templateUrl: './drivers-license.component.html',
  styleUrls: ['./drivers-license.component.css']
})
export class DriversLicenseComponent implements OnInit {

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private clickSingService: ClickSingService,
  ) { }

  ngOnInit(): void {
    this.googleAnalyticsService.trackClickSignEvent(
      'final-step-open',
      this.clickSingService.vendorReferrerName
    );
  }

}
