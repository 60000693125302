<app-application-wrapper [subTitleA]="isClicksign ? 'LEASE AGREEMENT' : 'BANK VERIFICATION'"
  [subTitleB]="isClicksign ? 'STEP 2 OF 4' : ''" [style]="!isClicksign && !currentFlow  ? 1 : 0" [steps]="steps"
  [stepIndex]="3">
  <ng-template appApplicationWrapperHelper>
    <div [ngClass]="{'content-FlowAB':!isClicksign && !currentFlow}">
      <h1 class="title font-mont-bold titleDesktop">Connect a bank account</h1>
      <h1 class="title font-mont-bold titleMovil">Bank account</h1>
      <p class="font-mont-regular" *ngIf="isClicksign">Enter the business bank account information for the account
        you’ll use to
        make your payments. ACH (Checking Account) is required for all transactions.</p>
      <p class="font-mont-regular" *ngIf="!isClicksign">Before we can provide an approval, we need to check if you have
        a bank account we partner with. Don't worry - we won't be charging this account until you sign a lease with us!
      </p>
      <h2 class="font-mont-bold">BANK DETAILS</h2>
      <div class="box-child clearfix" *ngIf="form">
        <form [formGroup]="form" (ngSubmit)="uploadBankInfo()">
          <div class="row" *ngIf="!production">
            <button class="btn btn-primary btn-lg auto-populate"
              (click)="onCompleteInformationClick(); $event.preventDefault();">
              <i class="fa fa-check"></i>
            </button>
          </div>
          <div class="form-row form-row1">
            <div class="form-row form-table-row">
              <div class="form-group row-accountRoutingNumber  col-xl-6 col-lg-6 col-md-6">
                <label class="font-mont-bold" for="accountRoutingNumber"
                  [ngClass]="{ 'color-red': formControls.accountRoutingNumber.touched && (formControls.accountRoutingNumber.errors || invalidRouting) }">Routing
                  Number</label>
                <input type="text" formControlName="accountRoutingNumber"
                  class="form-control cls-primary-input font-mont-regular" id="accountRoutingNumber"
                  placeholder="Bank Routing Number" name="accountRoutingNumber"
                  [ngClass]="{ 'is-invalid': formControls.accountRoutingNumber.touched && (formControls.accountRoutingNumber.errors || invalidRouting) }"
                  [showMaskTyped]="true" mask="000000000" (keydown)="clearRoutingInput()">
                <div
                  *ngIf="formControls.accountRoutingNumber.touched && (formControls.accountRoutingNumber.errors || invalidRouting)"
                  class="invalid-msg">
                  <div
                    *ngIf="formControls.accountRoutingNumber.errors && formControls.accountRoutingNumber.errors.required">
                    You must supply the bank routing number.
                  </div>
                  <div *ngIf="!formControls.accountRoutingNumber.errors && invalidRouting">
                    Invalid bank routing number.
                  </div>
                </div>
              </div>
              <div class="form-group row-checkingAccountNumber col-xl-6 col-lg-6 col-md-6">
                <label class="font-mont-bold" for="bankName"
                  [ngClass]="{ 'color-red': submitted && formControls.bankName.errors }">Bank or Credit Union</label>
                <input type="text" formControlName="bankName" class="Bname form-control font-mont-bold" id="bankName"
                  placeholder="Bank Name" name="bankName" readonly
                  [ngClass]="{ 'is-invalid': submitted && formControls.bankName.errors }">
                <div *ngIf="submitted && formControls.bankName.errors" class="invalid-msg">
                  <div *ngIf="formControls.bankName.errors.required">
                    Bank name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-xl-12 col-lg-12 col-md-12">
              <label class="font-mont-bold" for="accountNumber"
                [ngClass]="{ 'color-red': formControls.accountNumber.touched && formControls.accountNumber.errors }">Checking
                Account
                Number</label>
              <input type="text" formControlName="accountNumber"
                class="form-control cls-primary-input font-mont-regular" id="accountNumber" placeholder="Account Number"
                name="accountNumber" (click)="clearAccountInput()" [(ngModel)]="accountNumberInput"
                [ngClass]="{ 'is-invalid': formControls.accountNumber.touched && formControls.accountNumber.errors }">
              <div *ngIf="formControls.accountNumber.touched && formControls.accountNumber.errors" class="invalid-msg">
                <div *ngIf="formControls.accountNumber.errors.required">
                  You must supply the bank account number.
                </div>
              </div>
            </div>
            <div class="form-group col-xl-12 col-lg-12 col-md-12">
              <label class="font-mont-bold" for="accountNumberConfirm"
                [ngClass]="{ 'color-red': formControls.accountNumberConfirm.touched && formControls.accountNumberConfirm.errors }">Confirm
                Account Number</label>
              <input type="text" formControlName="accountNumberConfirm"
                class="form-control cls-primary-input font-mont-regular" id="accountNumberConfirm"
                placeholder="Account Number" name="accountNumberConfirm" [(ngModel)]="accountNumberConfirmInput"
                (click)="clearAccountInputConfirm()"
                [ngClass]="{ 'is-invalid': formControls.accountNumberConfirm.touched && formControls.accountNumberConfirm.errors }">
              <div *ngIf="formControls.accountNumberConfirm.touched && formControls.accountNumberConfirm.errors"
                class="invalid-msg">
                <div *ngIf="formControls.accountNumberConfirm.errors.required">
                  You must supply the bank account number.
                </div>
                <div *ngIf="formControls.accountNumberConfirm.errors.mustMatch">
                  Bank account number must match
                </div>
              </div>
            </div>
            <div class="form-group col-xl-12 col-lg-12 col-md-12">
              <label class="font-mont-bold" for="accountName"
                [ngClass]="{ 'color-red': formControls.accountName.touched && formControls.accountName.errors }">Name on
                Account</label>
              <input type="text" formControlName="accountName" class="form-control cls-primary-input font-mont-regular"
                id="accountName" placeholder="Name on Bank Account (Business Name)" name="accountName"
                [ngClass]="{ 'is-invalid': formControls.accountName.touched && formControls.accountName.errors }">
              <div *ngIf="formControls.accountName.touched && formControls.accountName.errors" class="invalid-msg">
                <div *ngIf="formControls.accountName.errors.required">
                  You must supply the bank account name.
                </div>
              </div>
            </div>
            <div *ngIf="!isClicksign" class="div-LabelLink">
              <a class="font-mont-regular verify-link" (click)="sendFinicityVerifyBankAccountEmail()"> Click here</a>
              <span class="font-mont-regular"> to receive an email with a link to verify your bank account later.</span>
            </div>
          </div>
        </form>
      </div>
      <span class="font-mont-regular terms">
        <app-read-more [textToDisplay]="disclosureText"></app-read-more>
      </span>
      
      <div>
        <button type="submit" class="cls-primary-btn font-mont-bold" [disabled]="form.invalid"
          (click)="uploadBankInfo();">Continue</button>
        <div class="back" *ngIf="isClicksign">
          <a href="javascript:history.back()">Go Back</a>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="/assets/images/icons/bank-account.svg" alt="">
  </ng-container>
</app-application-wrapper>
<app-oops-bank-account-error-modal *ngIf="showErrorModal" [applicationId]="applicationId" [accessToken]="access_token"
  (onAccountContinue)="onSelectedAccount()"></app-oops-bank-account-error-modal>
<app-pop-up-notify *ngIf="showNotify" [title]="title" [textA]="textA" [sent]="sent" [textB]="textB" [phone]="phone"
  (closeNotify)="onCloseNotify($event)"></app-pop-up-notify>
<app-fail-giact-error-modal *ngIf="showGiactErrorModal" [applicationId]="applicationId" [data]="bankURL"
  [accessToken]="access_token" (onBankAccountContinue)="onfailGiactContinue()">
</app-fail-giact-error-modal>