import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-additional-resubmit',
    templateUrl: './additional-resubmit.component.html',
    styleUrls: ['./additional-resubmit.component.css']
})
export class AdditionalResubmitComponent implements OnInit {
    @Input() id = 0;
    @Input() appStatus: string;
    public modalId = 'reSubmitModal' + this.id;
    public modalIdE = 'AdditionalResubmitCompleted' + this.id;

    public submitted: boolean = false;
    public errorTitle = '';
    public errorMessageA = '';
    public errorMessageB = '';
    public errorMessageC = '';
    public errorMessageBClass = '';
    public dialogClass = '';

    //Holds the BE response
    public vendorData: any = {
        numberOfElements: 0,
        totalElements: 0,
        records: []
    };

    public salesPersonData: any = [];

    //Pagination for vendors
    public vNumberOfElements = 0;
    public vSliceNumber = 0;
    public vSliceSize = 8;
    public vTotalElements = 0;
    public vTotalPages = 0;
    public vSlices: number[] = [];

    //Pagination for sales persons
    public sNumberOfElements = 0;
    public sSliceNumber = 0;
    public sSliceSize = 8;
    public sTotalElements = 0;
    public sTotalPages = 0;
    public sSlices: number[] = [];

    //Holds the selected item
    public selectedVendor: any = {};
    public selectedSalesPerson: any = {};

    //Holds the search in every step
    public searchVendorText = '';
    public searchSalesPersonText = '';

    public currentStep = 1;

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
        public googleAnalyticsService: GoogleAnalyticsService,
        public router: Router,
    ) { }


    ngOnInit() {
        this.modalId = 'reSubmitModal' + this.id;
        this.modalIdE = 'AdditionalResubmitCompleted' + this.id;
    }

    onClick() {
        this.searchVendorText = '';
        this.searchSalesPersonText = '';
        this.selectedVendor = {};
        this.selectedSalesPerson = {};
        this.googleAnalyticsService.trackEvent('additional-options', 'open', 'replicate-opp-new-vendor');
        $('#reSubmitModal' + this.id).modal();
    }

    closeWindow() {
        this.currentStep = 1;
        this.cleanVendorsPagination();
    }
    cleanVendorsPagination() {
        this.selectedVendor = {};
        this.vNumberOfElements = 0;
        this.vSliceNumber = 0;
        this.vSliceSize = 8;
        this.vTotalElements = 0;
        this.vTotalPages = 0;
        this.vSlices = [];
    }

    resubmitApplication() {
        const promise = new Promise((resolve, reject) => {
            this.googleAnalyticsService.trackEvent('additional-options', 'submit', 'replicate-opp-new-vendor');
            const url = environment.gateway + 'application/' + this.id + '/newvendor/' + this.selectedSalesPerson.id;
            this.connectionService.postRequest(url, {}, 'application/json', 'sp_id=' + this.authService.getSalesPersonId()).subscribe(
                (data: any) => {
                    this.router.navigateByUrl('/application/offers', { state: { applicationId: data.object.applicationId, generateOffers: true } });
                    $('#reSubmitModal' + this.id).modal('hide');
                    resolve(data);
                },
                (error: any) => {
                    this.errorTitle = 'Sorry, we found a problem';
                    this.errorMessageA = 'Unfortunately we are unable to process your request at this moment.';
                    this.errorMessageB = error.error.msg;
                    this.errorMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
                    this.errorMessageBClass = 'mB20';
                    this.dialogClass = 'w600';
                    $('#errorModalDialogAdditionalResubmitCompleted' + this.id).modal();
                    reject(error);
                }
            );
        });
        return promise;
    }

    onVendorNameKeyUp(slice: number, elements: number) {
        if (this.searchVendorText.trim() != '') {
            this.getVendors(this.searchVendorText.trim(), slice, elements).then(
                (vendorsData: any) => {
                    this.selectedVendor = {};
                    this.vendorData = vendorsData.object;
                    this.vNumberOfElements = vendorsData.object && vendorsData.object.numberOfElements ? vendorsData.object.numberOfElements : 0;
                    this.vSliceNumber = vendorsData.object && vendorsData.object.sliceNumber ? vendorsData.object.sliceNumber : 0;
                    this.vSliceSize = vendorsData.object && vendorsData.object.sliceSize ? vendorsData.object.sliceSize : 0;
                    this.vTotalElements = vendorsData.object && vendorsData.object.totalElements ? vendorsData.object.totalElements : 0;
                    this.vTotalPages = vendorsData.object && vendorsData.object.totalPages ? vendorsData.object.totalPages : 0;
                    this.vSlices = Array.from(Array(this.vTotalPages), (x, index) => index + 1);
                },
                (vendorsError: any) => {
                    this.vendorData = {
                        numberOfElements: 0,
                        totalElements: 0,
                        records: []
                    };
                    this.cleanVendorsPagination();
                }
            );
        } else {
            this.vendorData = {};
            this.cleanVendorsPagination();
        }
    }

    onSalesPersonNameKeyUp(slice: number, elements: number) {
        this.getSalesPeople(this.selectedVendor.id, this.searchSalesPersonText.trim(), slice, elements).then(
            (salespeopleData: any) => {
                this.selectedSalesPerson = {};
                this.salesPersonData = salespeopleData.object.records;
                this.sNumberOfElements = salespeopleData.object && salespeopleData.object.numberOfElements ? salespeopleData.object.numberOfElements : 0;
                this.sSliceNumber = salespeopleData.object && salespeopleData.object.sliceNumber ? salespeopleData.object.sliceNumber : 0;
                this.sSliceSize = salespeopleData.object && salespeopleData.object.sliceSize ? salespeopleData.object.sliceSize : 0;
                this.sTotalElements = salespeopleData.object && salespeopleData.object.totalElements ? salespeopleData.object.totalElements : 0;
                this.sTotalPages = salespeopleData.object && salespeopleData.object.totalPages ? salespeopleData.object.totalPages : 0;
                this.sSlices = Array.from(Array(this.sTotalPages), (x, index) => index + 1);

            },
            (salespeopleError: any) => {
                this.salesPersonData = [];
            }
        );
    }

    onVendorClick(vendor: any) {
        if (vendor.salesPersonCount === 0) {
            return;
        }
        this.selectedVendor = vendor;
        this.selectedSalesPerson = {};
        this.searchSalesPersonText = '';
        this.onSalesPersonNameKeyUp(0, 5);
    }

    onSalesPersonClick(salesperson: any) {
        this.selectedSalesPerson = salesperson;
    }

    getVendors(vendorName: string, page: number, pageSize: number) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'vendor/filtered/' + page + '/' + pageSize, 'application/json', '', null, { name: "name", value: vendorName }
            ).subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

    getSalesPeople(vendorId: number, salesPersonName: string, page: number, pageSize: number) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'salesperson/filtered/' + page + '/' + pageSize, 'application/json',
                'vendorId=' + vendorId + '&name=' + salesPersonName
            ).subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

    /***** Vendors *****/
    onVBackClick() {
        if (this.vSliceNumber > 0) {
            this.onVendorNameKeyUp(this.vSliceNumber - 1, this.vSliceSize);
        }
    }

    onVSliceClick(slice: number) {
        this.onVendorNameKeyUp(slice, this.vSliceSize);
    }

    onVNextClick() {
        if (this.vSliceNumber < this.vTotalPages - 1) {
            this.onVendorNameKeyUp(this.vSliceNumber + 1, this.vSliceSize);
        }
    }



    /***** Sales Persons*****/
    onSBackClick() {
        if (this.sSliceNumber > 0) {
            this.onSalesPersonNameKeyUp(this.sSliceNumber - 1, this.sSliceSize);
        }
    }

    onSSliceClick(slice: number) {
        this.onSalesPersonNameKeyUp(slice, this.sSliceSize);
    }

    onSNextClick() {
        if (this.sSliceNumber < this.sTotalPages - 1) {
            this.onSalesPersonNameKeyUp(this.sSliceNumber + 1, this.sSliceSize);
        }
    }


    onContinueClick() {
        this.currentStep = 2;
    }

    onGoBackClick() {
        this.currentStep = 1;
    }

    onCancelClick() {
        this.searchVendorText = '';
        this.searchSalesPersonText = '';
        this.currentStep = 1;
        this.cleanVendorsPagination();
        $('#reSubmitModal' + this.id).modal("hide");
    }

    getSelectedVendorText() {
        let returnValue = 'Choose Account';

        if (this.selectedVendor.id) {
            returnValue = '';
            if (this.selectedVendor.accountName) {
                returnValue = this.selectedVendor.accountName;
            }

        }

        return returnValue;
    }

    getSalesPersonText() {
        let returnValue = 'Choose Sales Person';

        if (this.selectedSalesPerson.id) {
            returnValue = '';
            if (this.selectedSalesPerson.name) {
                returnValue = this.selectedSalesPerson.name;
            }

        }

        return returnValue;
    }
}
