import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-declined',
    templateUrl: './declined.component.html',
    styleUrls: ['./declined.component.css']
})
export class DeclinedComponent implements OnInit {
    vendorUser = false;

    constructor(private router: Router, private authService: AuthService) {
    }

    ngOnInit(): void {
        this.vendorUser = this.authService.getUserId();
    }

    onBackClick() {
        this.router.navigateByUrl('/');
    }
}
