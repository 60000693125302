
export function getProveRightCity(cities: string[], proveCity: string) {
  let rightCity = cities[0];

  if (proveCity) {
      Array.from(cities, city => {
          if (proveCity.toUpperCase() === city) rightCity = city;
      });
  }
  
  return rightCity;
}