<app-header></app-header>

<div class="body-container">
    <div class="row mb-3 center">
        <h1 class="font-mont-bold">
            Select the vendor you would like to impersonate
        </h1>
    </div>
    <div class="row center">
        <div class="input-icons">
            <i class="fa fa-search icon"></i>
            <input type="text" class="font-mont-regular searchTextbox" [(ngModel)]="searchText"
                placeholder="Search by vendor ID, vendor name, or account name" name="searchText"
                (keyup)="onVendorNameKeyUp(0, 8)">
        </div>
    </div>
    <hr>


    <div class="row center">
        <table class="table table-hover table-borderless ">
            <thead>
                <tr class="heading">
                    <th class="text-left font-mont-semi-bold">Account Name</th>
                    <th class="font-mont-semi-bold">DBA Name</th>
                    <th class="text-left font-mont-semi-bold">Vendor ID</th>
                </tr>
            </thead>

            <tbody *ngIf="searchText.trim()">
                <tr [ngClass]="{'selected-row': vendor.id == selectedVendor.id, 'row-disabled': vendor.defaultSalesPersonId == null}"
                    *ngFor="let vendor of vendorData.records" (click)="onVendorClick(vendor);">
                    <td [ngClass]="{'font-mont-bold': vendor.id == selectedVendor.id}">{{vendor.accountName}}</td>
                    <td style="border-top: none;" [ngClass]="{'font-mont-bold': vendor.id == selectedVendor.id}">
                        {{vendor.name}}</td>
                    <td [ngClass]="{'font-mont-bold': vendor.id == selectedVendor.id}">{{vendor.id}}</td>
                </tr>
            </tbody>

        </table>
        <nav aria-label="Page navigation example" *ngIf="searchText && vTotalPages > 1">
            <ul class="pagination justify-content-end">
                <li class="page-item" [ngClass]="{'disabled': vSliceNumber == 0}" (click)="onVBackClick();">
                    <a class="page-link" tabindex="-1">Previous</a>
                </li>
                <li class="page-item" *ngFor="let slice of vSlices" [ngClass]="{'active': vSliceNumber + 1 == slice}"
                    (click)="onVSliceClick(slice - 1);">
                    <a class="page-link"
                        *ngIf="slice - 1 > vSliceNumber - 5 && vSliceNumber + 5 > slice - 1">{{slice}}</a>
                </li>
                <li class="page-item" [ngClass]="{'disabled': vSliceNumber == vTotalPages - 1}"
                    (click)="onVNextClick();">
                    <a class="page-link">Next</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="row center">
        <div *ngIf="!searchText.trim()">
            <p class="font-mont-regular searchLegend">Start by searching for a vendor ID, vendor name, or
                account name</p>
        </div>
    </div>
    <div class="row center" *ngIf="searchText.trim()">
        <div class="col-12">
            <button class="btn btn-primary font-mont-regular" [ngClass]="{'disabled': !selectedVendor.name }"
                type="button" [disabled]="!selectedVendor.name" (click)="onContinueClick();">
                Continue
            </button>
        </div>
    </div>
</div>