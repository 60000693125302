import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-decline-error',
  templateUrl: './decline-error.component.html',
  styleUrls: ['../general-error-v3/general-error-v3.component.css', './decline-error.component.css']
})
export class DeclineErrorComponent implements OnInit {
  //DeniedErrorComponent
  excludeAppResults: boolean = false;
  partnerName: string = "";

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.googleAnalyticsService.trackV3Event('decline-error', this.authService.vendorReferrerName, 'decline-error');
    this.partnerName = this.authService.vendorReferrerName;
    if (this.authService.vendor.excludeApplicationResults) {
      this.excludeAppResults = this.authService.vendor.excludeApplicationResults;
    } else {
      this.excludeAppResults = false;
    }
  }

}
