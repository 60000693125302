<div *ngIf="vendorPaused">
    <div class="row sub-section-title">
        <div class="col-12">
            <img src="/assets/images/icons/mcoPause.svg" alt="Pause funding" />
            <span class="title-red">Funding has been paused</span>
        </div>
    </div>

    <div class="row sub-section-content">
        <div class="col-12">
            <span class="font-mont-regular ">Due to missing MCOs, funding has been
                paused, and new contracts cannot be sent until
                the MCOs or Titles are received. Please ship all </span>
            <label (click)="openBannerModal()" class="font-mont-regular regularText withUnderline">
                outstanding MCOs
            </label>
            <span class="font-mont-regular"> as soon as possible to resume.</span>
        </div>
    </div>
</div>

<div class="row sub-section-title">
    <div class="col-12">
        <img src="/assets/images/icons/edit_blue.svg" alt="edit blue" />
        <span>Create and Send Contracts</span>
    </div>
</div>



<form [formGroup]="sendDocsForm" (ngSubmit)="openModal()">
    <div class="row sub-section-form">
        <div class="ept-fld col-12 col-lg-6">
            <label for="name">
                Invoice/Quote #:
                <div class="tooltip-ept">
                    <img src="/assets/images/icons/light_gray_i_2.svg" alt="" />
                    <span class="tooltip-ept-text">
                        If your invoice is not finalized, you may enter your quote or estimate number.
                    </span>
                </div>
            </label>
            <input type="text" formControlName="invoiceNumber" class="form-control" id="invoiceNumber"
                placeholder="Invoice/Quote #" name="invoiceNumber" autocomplete="off"
                [ngClass]="{ 'is-invalid': sendDocsFormSubmitted && sendDocsForm.controls.invoiceNumber.errors }">
            <div *ngIf="sendDocsFormSubmitted && sendDocsForm.controls.invoiceNumber.errors" class="invalid-feedback">
                <div *ngIf="sendDocsForm.controls.invoiceNumber.errors.required">
                    Invoice number is required.
                </div>
            </div>
        </div>
        <div class="ept-fld col-12 col-lg-6 ml-2">
            <label for="name">
                Financed Amount:
                <div class="tooltip-ept">
                    <img src="/assets/images/icons/light_gray_i_2.svg" alt="" />
                    <span class="tooltip-ept-text">
                        The amount we will be funding for the transaction. This amount should exclude sales tax and not
                        include more than 25% of soft costs.
                    </span>
                </div>
            </label>
            <input type="text" formControlName="subTotal" class="form-control" id="subTotal" name="subTotal"
                [ngClass]="{ 'is-invalid': sendDocsFormSubmitted && sendDocsForm.controls.subTotal.errors }"
                placeholder="$" prefix="$ " mask="separator.2" thousandSeparator="," autocomplete="off">
            <div *ngIf="sendDocsFormSubmitted && sendDocsForm.controls.subTotal.errors" class="invalid-feedback">
                <div *ngIf="sendDocsForm.controls.subTotal.errors.required">
                    Requested Amount is required.
                </div>
                <div *ngIf="sendDocsForm.controls.subTotal.errors.requestedAmount">
                    The requested amount must be greater than $ 500.
                </div>
            </div>
            <span>Excluding Sales Tax</span>
        </div>
        <div class="col-12 p-0">
            <button type="submit" [disabled]="isDisabled"
                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular">
                Send Contracts
            </button>
        </div>
    </div>
</form>
<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="summarySendDocsAddress"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="max-width: 690px;" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title w-100 text-left position-absolute" id="exampleModalLongTitle">
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body" *ngIf="currentStep === 1">
                <form [formGroup]="gpsTrackerRequiredForm" (ngSubmit)="submitGPSForm()">

                    <div class="form-group mt-2 ml-4">
                        <label class="ept-fld font-mont-bold gpsTitle" for="gpsTrackerRequired">Does the equipment
                            require a GPS tracker?
                            <div class="tooltip-ept">
                                <img src="/assets/images/icons/light_gray_i_2.svg" alt="" />
                                <span class="tooltip-ept-text">
                                    Trailers, Skids, and similar items valued at $10,000 or more require a GPS unit.
                                </span>
                            </div>
                        </label>

                        <div class="row">
                            <div>
                                <div style="width: 50px; margin-left: 15px; margin-top: 15px;">
                                    <input id="noGPS" type="radio" value="false" name="gpsTrackerRequired"
                                        formControlName="gpsTrackerRequired">
                                    <label class="font-mont-semi-bold radioOptionsText">No</label>
                                </div>

                            </div>

                            <div style="width: 70px; margin-left: 15px; margin-top: 15px; cursor: pointer;">
                                <input id="yesGPS" type="radio" value="true" name="gpsTrackerRequired"
                                    formControlName="gpsTrackerRequired">
                                <label class="font-mont-semi-bold radioOptionsText">Yes</label>
                            </div>
                        </div>
                        <button class="btn btn-primary nextButton" type="submit"
                            [ngClass]="{'disabled': !gpsTrackerRequiredForm.valid }"
                            [disabled]="!gpsTrackerRequiredForm.valid">Continue</button>

                    </div>

                </form>

            </div>
            <div class="modal-body" *ngIf="currentStep === 2">
                <form [formGroup]="invoiceAddressForm" (ngSubmit)="onSubmitInvoiceDialog();">
                    <div class="row">
                        <div class="form-group col-12 invoice-type">
                            <label for="sameAddres">Is the equipment to be installed at the address
                                below?</label>
                            <input type="radio" formControlName="sameAddres" id="invoice-same-address-no" value="false"
                                name="sameAddres" (change)="changeSameAddress()">
                            <label for="invoice-same-address-no">No</label>
                            <input type="radio" formControlName="sameAddres" id="invoice-same-address-yes" value="true"
                                name="sameAddres" (change)="changeSameAddress()">
                            <label for="invoice-same-address-yes">Yes</label>
                            <label class="no-description"
                                *ngIf="invoiceAddressForm.controls.sameAddres.value === 'false'">
                                If 'No', please update the address where equipment will be delivered/installed
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <input type="text" formControlName="streetAddress" class="form-control"
                                        id="streetAddress" placeholder="Street Address" name="streetAddress"
                                        [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.streetAddress.errors }">
                                </div>
                                <div class="form-group col-6">
                                    <input type="text" formControlName="unit" class="form-control" id="unit"
                                        placeholder="Unit" name="unit"
                                        [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.unit.errors }">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <input type="text" formControlName="zipCode" class="form-control" id="zipCode"
                                        placeholder="zipCode" name="zipCode" (keyup)="onZipCodeKeyUp()"
                                        [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.zipCode.errors }">
                                </div>
                                <div class="form-group col-6">
                                    <select formControlName="city" id="city" class="form-control"
                                        [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.city.errors }">
                                        <option value="" disabled selected>City</option>
                                        <option *ngFor="let city of invoiceAddresArrays.cities" [ngValue]="city">
                                            {{city}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-6">
                                    <select formControlName="state" id="state" class="form-control"
                                        [ngClass]="{ 'is-invalid': invoiceAddressFormSubmitted && invoiceAddressForm.controls.state.errors }"
                                        [attr.disabled]="true">
                                        <option value="" disabled selected>State</option>
                                        <option *ngFor="let state of invoiceAddresArrays.states" [ngValue]="state.code">
                                            {{state.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-6">
                                    <select formControlName="county" id="county" class="form-control"
                                        [ngClass]="{ 'is-invalid': invoiceAddressForm.controls.county.errors }">
                                        <option value="" disabled selected>County</option>
                                        <option *ngFor="let county of invoiceAddresArrays.counties" [ngValue]="county">
                                            {{county}}
                                        </option>
                                    </select>
                                    <div *ngIf="invoiceAddressForm.controls.county.errors"
                                        class="invalid-feedback pl-2 pt-2">
                                        <div *ngIf="invoiceAddressForm.controls.county.errors.required">
                                            County is required.
                                        </div>
                                        <div *ngIf="invoiceAddressForm.controls.county.errors.countyNotMatchCity">
                                            City and County do not match. Please verify your answer.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button *ngIf="gpsUnitRequired" type="submit" (click)="moveFirstStep()"
                                class="btn btn-primary round-button font-mont-regular backButton">
                                Back
                            </button>
                            <button type="submit" class="btn btn-primary round-button font-mont-regular sendButton">
                                Send Contracts
                            </button>
                        </div>
                    </div>
                </form>
            </div>


        </div>
    </div>
</div>
<app-error-dialog [modalId]="modalIdE" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageBClass]="messageMessageBClass" [dialogClass]="dialogClass"
    (closeModal)="onCloseModal();"></app-error-dialog>
<div class="modal fade" id="{{attempModalId}}" tabindex="-1" role="dialog" aria-labelledby="attempModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w600" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 font-mont-bold" id="exampleModalLongTitle">Having problems?
                </h5>
                <div>It seems you are having issues sending documents to your customer.</div>
                <div>Please speak with a member of our Customer Care team to get this resolved.</div>
                <div>You can reach them at (888) 509-5592.</div>
                <div class="row">
                    <div class="col-6">
                        <div class="attemps-number mr-auto">(Resend Docs attempt {{numberOfAttempts}} of 3)</div>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-secondary close-button"
                            [ngClass]="{'full': numberOfDocsExceeded}" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary close-button"
                            (click)="openSelectOffersDialogAfterQuantityValidation();" data-dismiss="modal"
                            *ngIf="!numberOfDocsExceeded">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade success-create-send-modal" id="{{sendContractsSuccessId}}" tabindex="-1" role="dialog"
    aria-labelledby="sendContractsSuccess" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg w600" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseModal();">
                    <img src="/assets/images/icons/close_b.svg" alt="" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 font-mont-bold text-center" id="exampleModalLongTitle">
                    Nice! The contract has been sent to the customer to be signed.
                </h5>
                <h6 class="font-mont-semi-bold">Here's what happens next:</h6>
                <ol>
                    <li>The customer will get an email with a link taking them to sign their documents.</li>
                    <li>
                        They will choose their term, enter their bank account, sign the contract and upload their
                        driver’s license. You can monitor their status in the portal.
                    </li>
                    <li>
                        Once the contracts are signed, you will receive your Purchase Order via email and you may
                        release the items or setup delivery. That email will instruct you on how to get paid!
                    </li>
                </ol>
                <div class="row">
                    <div class="col-12 text-right">
                        <button type="button" class="btn btn-secondary close-button full" data-dismiss="modal"
                            (click)="onCloseModal();">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>