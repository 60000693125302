import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '../../shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArraySortPipe } from '../../shared/pipes/array.sort.pipe';
import { ArrayFilterPipe } from '../../shared/pipes/array.filter.pipe';
import { MaxOfferItem } from './max-offer.item';
declare var $: any;

@Component({
    selector: 'app-max-offer-tool',
    templateUrl: './max-offer-tool.component.html',
    styleUrls: ['./max-offer-tool.component.css']
})
export class MaxOfferToolComponent implements OnInit {

    dataRobotModels: any[] = [];
    adjustmentId = '';
    operators: any[] = [];

    adjustments: MaxOfferItem[] = [];

    newAdjustmentForm: UntypedFormGroup;

    outcomes: any[] = [];
    attributes: any[] = [];
    allModelsModel: any;

    adjustmentForm: UntypedFormGroup;

    addNewAdjustmentSubmitted = false;
    selectedRow: number = -1;
    tempSelectedRow: number = -1;

    modalId = 'ErrorsModal';
    messageTitle = '';
    messageMessageA = '';
    messageMessageB = '';
    messageMessageC = '';
    messageMessageBClass = '';
    dialogClass = '';

    statusFilterOpen: boolean = false;
    dataRobotModelFilterOpen: boolean = false;

    filter: string = '';
    filterValue: string = '';

    sortField: string = '';
    invertedSort: boolean = false;

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private arrayFilterPipe: ArrayFilterPipe,
        private arraySortPipe: ArraySortPipe
    ) {
        this.adjustmentForm = this.formBuilder.group({ adjustment: [''] });
        this.newAdjustmentForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required]
        });
        this.allModelsModel = {
            id: 0, description: 'All Models'
        };
    }

    ngOnInit() {
        this.getDataRobotModelList();
        this.getAttributeList().then(
            (attributeData) => {
                this.getOperatorList().then(
                    (operatorData) => {
                        // This is intentional
                    },
                    (operatorError) => {
                        // This is intentional
                    }
                );
            },
            (attributeError) => {
                // This is intentional
            }
        );
        this.getAdjustmentsList();
    }

    onCreateNewAdjustmentSubmit() {
        this.addNewAdjustmentSubmitted = false;
        this.newAdjustmentForm.controls.name.setValue('');
        this.newAdjustmentForm.controls.description.setValue('');
        $('#addNewAdjustmentModal').modal();
    }

    onCreateSubmit() {
        this.addNewAdjustmentSubmitted = true;
        if (this.newAdjustmentForm.invalid) {
            return;
        }

        return new Promise((resolve, reject) => {
            const postData: any = {
                active: false,
                description: this.newAdjustmentForm.controls.description.value,
                name: this.newAdjustmentForm.controls.name.value,
                datarobotModelId: this.dataRobotModels[0].id,
                logic: '1',
                logicType: 'ALL_AND'
            };
            this.connectionService.postRequest(`${environment.gateway}maxfundingadjustment`, postData, 'application/json').subscribe(
                (data: any) => {
                    $('#addNewAdjustmentModal').modal('hide');
                    this.showSuccessMessage("New Max Funding Adjustment Created!");
                    this.adjustmentId = data.object.id;
                    this.getAdjustmentsList(this.adjustmentId).then(
                        () => this.adjustmentForm.controls.adjustment.setValue(this.adjustmentId),
                        () => this.adjustmentForm.controls.adjustment.setValue(this.adjustmentId)
                    );
                    resolve(data);
                },
                (error: any) => {
                    $('#addNewAdjustmentModal').modal('hide');
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getAdjustmentsList(adjustmentId?: any) {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}maxfundingadjustment/list`, 'application/json').subscribe(
                (data: any) => {
                    let result = [];
                    data.object.forEach(element => {
                        let lAdjustment = new MaxOfferItem(element, this.attributes, this.operators, this.dataRobotModels);
                        result.push(lAdjustment);
                    });
                    this.adjustments = result;
                    let filteredAndSortedResult = this.arrayFilterPipe.transform(result, this.filter, this.filterValue);
                    filteredAndSortedResult = this.arraySortPipe.transform(filteredAndSortedResult, this.sortField, this.invertedSort);

                    if (adjustmentId) {
                        for (let index = 0; index < filteredAndSortedResult.length; index++) {
                            if (filteredAndSortedResult[index].id == adjustmentId) {
                                this.tempSelectedRow = index;
                            }
                        }
                    }
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getAttributeList() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}attribute/list`, 'application/json').subscribe(
                (data: any) => {
                    this.attributes = data.object;
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getOperatorList() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}operator/list`, 'application/json').subscribe(
                (data: any) => {
                    this.operators = data.object;
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    getDataRobotModelList() {
        return new Promise((resolve, reject) => {
            this.connectionService.getRequest(`${environment.gateway}datarobotmodel/list`, 'application/json').subscribe(
                (data: any) => {
                    this.dataRobotModels = data.object;
                    this.dataRobotModels.push(this.allModelsModel);
                    resolve(data);
                },
                (error: any) => {
                    this.showErrorMessage(error.error.msg);
                    reject(error);
                }
            );
        });
    }

    toggleSelectedRow(rowId: number, appId: number) {
        this.adjustmentId = String(appId);
        if (this.tempSelectedRow == rowId) {
            this.selectedRow = -1;
            this.tempSelectedRow = -1;
        } else {
            this.tempSelectedRow = rowId;
        }
    }

    onItemLoaded() {
        setTimeout(() => {
            this.selectedRow = this.tempSelectedRow;
            setTimeout(() => {
                let el = document.getElementById('itemNumber_' + this.selectedRow);
                let pos = el.getBoundingClientRect().top + window.pageYOffset - 95;
                window.scrollTo({ top: pos, behavior: 'smooth' });
            }, 250);
        }, 250);
    }

    toggleStatusFilter() {
        this.statusFilterOpen = !this.statusFilterOpen;
    }

    toggleDataRobotModelFilter() {
        this.dataRobotModelFilterOpen = !this.dataRobotModelFilterOpen;
    }

    onStatusCheckClick(field: any, value: any) {
        this.filter = field;
        this.filterValue = value;
        this.selectedRow = -1;
        this.tempSelectedRow = -1;
    }

    onSortClick(field: any) {
        this.selectedRow = -1;
        this.tempSelectedRow = -1;
        if (this.sortField == field) {
            this.invertedSort = !this.invertedSort;
        } else {
            this.invertedSort = false;
            this.sortField = field;
        }
    }

    private showErrorMessage(pError: string) {
        this.messageTitle = 'Sorry, we found a problem';
        this.messageMessageA = 'Unfortunately we are unable to process your request at this moment.';
        this.messageMessageB = pError;
        this.messageMessageC = 'Please contact customer service at 1-888-509-5592 for more information.';
        this.messageMessageBClass = 'mB20';
        this.dialogClass = 'w600';
        $('#errorModalDialog' + this.modalId).modal();
    }

    private showSuccessMessage(pMessage: string) {
        this.messageTitle = 'Success!!';
        this.messageMessageA = pMessage;
        this.messageMessageB = '';
        this.messageMessageC = '';
        this.messageMessageBClass = '';
        this.dialogClass = 'w450';
        $('#errorModalDialog' + this.modalId).modal();
    }
}