import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-impersonate',
    templateUrl: './impersonate.component.html',
    styleUrls: ['./impersonate.component.css']
})
export class ImpersonateComponent implements OnInit {

    public vNumberOfElements = 0;
    public vSliceNumber = 0;
    public vSliceSize = 0;
    public vTotalElements = 0;
    public vTotalPages = 0;
    public vSlices: number[] = [];
    public selectedVendor: any = {};

    public vendorData: any = {
        numberOfElements: 0,
        totalElements: 0,
        records: []
    };


    public searchText: string = ''

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
        public router: Router
    ) { }

    ngOnInit() {

    }

    getVendors(vendorName: string, page: number, pageSize: number) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(
                environment.gateway + 'vendor/filtered/' + page + '/' + pageSize, 'application/json', '', null, { name: "name", value: vendorName }
            ).subscribe(
                (data: any) => {
                    resolve(data);
                },

                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

    onVendorNameKeyUp(slice: number, elements: number) {
        if (this.searchText.trim() != '') {
            this.getVendors(this.searchText.trim(), slice, elements).then(
                (vendorsData: any) => {
                    this.selectedVendor = {};
                    this.vendorData = vendorsData.object;
                    this.vNumberOfElements = vendorsData.object && vendorsData.object.numberOfElements ? vendorsData.object.numberOfElements : 0;
                    this.vSliceNumber = vendorsData.object && vendorsData.object.sliceNumber ? vendorsData.object.sliceNumber : 0;
                    this.vSliceSize = vendorsData.object && vendorsData.object.sliceSize ? vendorsData.object.sliceSize : 0;
                    this.vTotalElements = vendorsData.object && vendorsData.object.totalElements ? vendorsData.object.totalElements : 0;
                    this.vTotalPages = vendorsData.object && vendorsData.object.totalPages ? vendorsData.object.totalPages : 0;
                    this.vSlices = Array.from(Array(this.vTotalPages), (x, index) => index + 1);
                },
                (vendorsError: any) => {
                    this.vendorData = {
                        numberOfElements: 0,
                        totalElements: 0,
                        records: []
                    };
                }
            );
        }
        else {
            this.selectedVendor = {};
            this.vendorData = {
                numberOfElements: 0,
                totalElements: 0,
                records: []
            };
        }
    }

    onVSliceClick(slice: number) {
        this.onVendorNameKeyUp(slice, 8);
    }

    onVBackClick() {
        if (this.vSliceNumber > 0) {
            this.onVendorNameKeyUp(this.vSliceNumber - 1, 8);
        }
    }

    onVNextClick() {
        if (this.vSliceNumber < this.vTotalPages - 1) {
            this.onVendorNameKeyUp(this.vSliceNumber + 1, 8);
        }
    }

    onVendorClick(vendor: any) {
        if (vendor.defaultSalesPersonId == null) {
            return;
        }
        this.selectedVendor = vendor;
    }

    onContinueClick() {
        this.authService.setImpersonatedVendor({
            vendorId: this.selectedVendor.id,
            vendorName: this.selectedVendor.name,
            accountName: this.selectedVendor.accountName,
            gpsUnitRequired: this.selectedVendor.gpsUnitRequired,
            salesPersonId: this.selectedVendor.defaultSalesPersonId,
            salesPersonName: this.selectedVendor.defaultSalesPerson,
            flowVersion: this.selectedVendor.flowVersion,
            maxApplicationAmount: this.selectedVendor.maxApplicationAmount
        });
        this.router.navigateByUrl('/home');
    }

}
