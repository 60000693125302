import { ChangeDetectionStrategy, Component, Input, OnInit, Renderer2, ViewChild, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";


@Component({
  selector: 'app-ept-backdrop',
  templateUrl: './ept-backdrop.component.html',
  styleUrls: ['./ept-backdrop.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('backdropState', [
      state('show', style({
        opacity: 0.7
      })),
      state('hide', style({
        opacity: 0
      })),

      transition(':enter', [style({ opacity: 0 }), animate('.15s linear', style({ opacity: 0.7 }))]),
      transition(':leave', [style({ opacity: 0.7 }), animate('150ms linear', style({ opacity: 0 }))])
    ])
  ]
})
export class EptBackdropComponent implements OnInit {

  @ViewChild('eptBackdrop', { static: false }) backdropEle;

  @Input()
  set isShow(pStatus: boolean) {
    this._isShow = pStatus;
    this.cd.detectChanges();
    const { nativeElement: bdEle = null } = this.backdropEle || {};
    // console.log('backdrop changed!!');
    if (bdEle) {
      this.render.setAttribute(bdEle, 'background-color', this.getBgClr())
      this.render.appendChild(document.body, bdEle);
    }
  };
  get isShow(): boolean {
    return !!this._isShow;
  }
  private _isShow: boolean;

  @Input() bgClr: 'light' | 'dark' = 'light';

  @Output() changeBdStatus: EventEmitter<boolean> = new EventEmitter();


  constructor(private render: Renderer2, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    // this.render.appendChild(document.body, backdropEle)
  }

  changeStatus() {
    this.isShow = !this.isShow;
    this.changeBdStatus.emit(this.isShow);
  }

  getBgClr(pTheme?: string): string {
    !pTheme && (pTheme = this.bgClr || 'light');
    let clrVal: string;
    if (pTheme === 'light') clrVal = '#fff';
    if (pTheme === 'dark') clrVal = '#000';
    return clrVal;
  }

}