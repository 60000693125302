import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-application-details-risk',
    templateUrl: './application-details-risk.component.html',
    styleUrls: ['./application-details-risk.component.css', '../../ept-ui-elements.css']
})
export class ApplicationDetailsRiskComponent implements OnInit {

    @Input() risk: any = {};
    @Input() appId: number = null;
    @Input() skipMultiRisk: boolean = false;

    @Output() skipMr: EventEmitter<{ mrToggleState: boolean, appId: number }> = new EventEmitter();

    private _mrExceptionFldName: string = `mrDeclineException_${Date.now()}`;
    public open: boolean = false;

    constructor() { }

    ngOnInit(): void { }

    mrToggleChange(e: Event) {
        const target: HTMLInputElement = (e?.target as HTMLInputElement) || null;
        const toggleState = { mrToggleState: false, appId: this.appId };
        if (target?.checked) toggleState.mrToggleState = true;
        else toggleState.mrToggleState = false;
        this.skipMr.emit(toggleState);
    }

    isMrToggleShow(): boolean {
        return !!this.risk && !!this.appId;
    }

    public get mrExceptionFldName(): string {
        return (!!this.appId && `mrDeclineException_${this.appId}`) || this._mrExceptionFldName;
    }

}
