import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { IStarted } from 'src/app/application/v3/model';
import { Experimental } from 'src/app/utils/shared';
import { Primitive } from 'src/app/shared/types';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/shared/services';

/**
 * @class StartedFormService
 */
@Injectable({
    providedIn: 'root'
})
export class StartedFormService {
    readonly form = this.formBuilder.group({
        ssnNumber: ['', Validators.required],
        mobilePhoneNumber: ['', Validators.required],
        mobilePhoneNumberTest: [''],
        stayUpDate: [true],
    });

    constructor(
        private formBuilder: FormBuilder,
        private connectionService: ConnectionService,
    ) { }

    /**
     *
     */
    get controls() {
        return this.form.controls;
    }

    /**
     *
     */
    get enconde() {
        return <IStarted> {
            last4: this.getControlValue('ssnNumber'),
            phoneNumber: this.getControlValue('mobilePhoneNumber'),
            marketingFlag: true
        }
    }

    /**
     *
     */
    get invalid() {
        return this.form.invalid;
    }

    /**
     *
     */
    get valid() {
        return this.form.valid;
    }

    /**
     *
     * @param {string} controlKey
     * @returns {FormControl<Primitive>}
     */
    private getControlById<T = FormControl<Primitive>>(controlKey: string): T {
        return this.controls[controlKey];
    }

    /**
     *
     * @async
     * @param otpCode
     * @param phone
     * @param vendor_token
     * @returns
     */
    async verifyActionOTP(otpCode: string, vendor_token: string, phone = this.getControlValue('mobilePhoneNumber')) {
        try {
            const response = await this.connectionService.postV3RequestP({
                url: `${environment.gatewayV3}prefillservice/api/validateOtp/`,
                postData: { otpCode, phone },
                contentType: 'application/json',
                vendor_token
            });

            if (response.object.leadClassification) {
                Experimental.updateExperimental(response.object.leadClassification);
            }

            return response;
        } catch (err) { throw err; }
    }

    /**
     *
     * @param {number} phoneNumber
     * @param checkPhone
     */
    updatePhoneAndStayUpdate(phoneNumber: number) {
        this.setValueToControl('mobilePhoneNumber', phoneNumber);

    }

    /**
     *
     * @param {IStarted} data
     */
    decode({
        last4,
        phoneNumber,
        phoneNumberTest = '',
    }: IStarted): void {
        this.setValueToControl('ssnNumber', last4);
        this.setValueToControl('mobilePhoneNumber', phoneNumber);
        this.setValueToControl('mobilePhoneNumberTest', phoneNumberTest);
    }

    /**
     *
     * @param {string} controlKey
     * @param {function} event
     */
    emitOnControlChange(controlKey: string, event): void {
        this.getControlById(controlKey).valueChanges.subscribe(function() { event(...arguments) });
    }

    /**
     *
     * @param {string} controlKey
     * @returns {boolean}
     */
    getControlInvalid(controlKey: string): boolean {
        return this.getControlById(controlKey).invalid;
    }

    /**
     *
     * @param {string} controlKey
     * @returns {boolean}
     */
    getControlValid(controlKey: string): boolean {
        return this.getControlById(controlKey).valid;
    }

    /**
     *
     * @param {string} controlKey
     * @returns {Primitive}
     */
    getControlValue(controlKey: string) {
        return this.getControlById(controlKey).value;
    }

    /**
     *
     * @param {string} controlKey
     * @returns {Primitive}
     */
    getEncodedValue<T = Primitive>(controlKey: string): T {
        return this.enconde[controlKey];
    }

    /**
     *
     */
    reset(): void {
        this.form.reset();
    }

    /**
     *
     */
    setDefaultData(): void {
        this.decode({
            last4: 3136,
            phoneNumber: 2001062109,
            marketingFlag: true
        });
        this.form.updateValueAndValidity();
    }

    /**
     *
     * @param {string} controlKey
     * @param {Primitive} value
     */
    setValueToControl(controlKey: string, value: Primitive): void {
        this.getControlById(controlKey).setValue(value);
    }
}
