import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorListService {
    errorData: string = null;
    hasNavError = false;
    storeErrors: any = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService,
    ) { }

    redirectToValidate(data) {
        console.log(data);
        this.router.navigate(['../verify-error'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            state: {
                otp: true
            }
        });
    }

    redirectToV3Error(data: any, endpoint: string, start?: string) {
        this.setErrorsData(data);

        this.router.navigate(['/general-error-v3'], {
            queryParamsHandling: 'merge',
            state: {
                error: true,
                endpoint,
                start
            }
        });
    }

    redirectToTokenError(token, data) {
        this.router.navigate(['/token-error-v3'], {
            state: { token, data }
        });
    }

    setErrorsData(data) {
        if (data) {
            console.log(data);
            this.storeErrors = data.error ? data.error : data;
        }
    }

    setLegacyError() {
        this.googleAnalyticsService.trackClickSignEvent(
            'finicity-error',
            this.authService.vendorReferrerName,
            this.errorData,
            this.storeErrors
        );
    }

    setV3Error() {
        this.googleAnalyticsService.trackV3Event(
            'finicity-error',
            this.authService.vendorReferrerName,
            this.errorData,
            this.storeErrors
        );
    }

    validateRedirectError(data, endpoint: string, start: string) {
        if (data && data.error && data.error.code === '204') {
            this.redirectToValidate(data);
        } else {
            this.redirectToV3Error(data, endpoint, start);
        }
    }

    validateNavError() {
        const navigation = this.router.getCurrentNavigation();

        if (navigation.extras.state && navigation.extras.state.endpoint) {
            this.errorData = navigation.extras.state.endpoint;
            this.hasNavError = true;
        }
    }
}