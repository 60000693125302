import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthBrokerGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.authService.validateSession()
                .then((data) => {
                    if (this.authService.getVendorType() == 'BROKER') {
                        window.scroll(0, 0);
                        resolve(true);
                    } else {
                        this.router.navigate(['home']);
                        reject(false);
                    }
                })
                .catch((error) => {
                    this.router.navigate(['signin']);
                    reject(false);
                });
        });
    }
}
