import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { ConnectionService } from 'src/app/shared/services';
import { ZipCodeList, ZipCodeRespose } from 'src/app/application/v3/model';

/**
 * @class ZipCodeService
 * This service is responsible for storing a 
 * {@link ZipCodeList list of zip codes}, in addition to being able to load a 
 * new one, in case it does not exist within the loaded list.
 */
@Injectable({
    providedIn: 'root'
})
export class ZipCodeService {
    /**
     * @private
     * @readonly
     * This property contains a list of stored 
     * {@link ZipCodeList response zip codes} for use within the application.
     * @type {ZipCodeList}
     */
    private readonly zipCodesData: ZipCodeList = {};

    constructor(
        private connectionService: ConnectionService,
        private authService: AuthService,
    ) { }

    /**
     * @private
     * This method is responsible for returning the id of the address formatted, 
     * it validates if the current state of the application is in production, 
     * since this affects the formatted value.
     * @param {string} zipCode Zip code value to validate
     * @param {string} address Address value to validate
     * @returns {string} Formatted identifier
     */
    private formatAddress(zipCode: string, address: string, city?: string): string {
        let zipCodePropertyName: string = zipCode;
        if (address) {
            zipCodePropertyName = `${zipCode}_${encodeURIComponent(address)}`;
        }
        if(city) {
            zipCodePropertyName = zipCodePropertyName + '_' + encodeURIComponent(city);
        }

        return zipCodePropertyName;
    }

    /**
     * @async
     * This method is responsible for loading a new zipcode returned by the API, 
     * within the {@link ZipCodeService.zipCodesData Zip Code List} and 
     * returning the loaded value.
     * @param {string} zipCode Zip code value to validate
     * @param {string} address Address value to validate
     * @returns {Promise<ZipCodeRespose>} Promise whose result is a new zip code 
     * loaded.
     */
    async addZipCode(zipCode: string, address: string, city?: string): Promise<ZipCodeRespose> {
        try {
            const result = await this.connectionService.postV3RequestP<ZipCodeRespose>({
                url: `${environment.gatewayV3}addressservice/api/getValidAddressForTaxes`,
                postData: {
                    zipcode: zipCode,
                    streetAddress: address,
                    city: city
                },
                contentType: 'application/json',
                access_token: this.authService.authTokenV3,
            });
    
            if (!this.validateZipCode(zipCode, address, city)) {
                this.zipCodesData[this.formatAddress(zipCode, address, city)] = result;
            }
    
            return this.getZipCode(zipCode, address, city);
        } catch (err) { return err; }
    }

    /**
     * This method is responsible for returning a loaded zipcode, stored within 
     * the {@link ZipCodeService.zipCodesData Zip Code List}.
     * @param {string} zipCode Zip code value to validate
     * @param {string} address Address value to validate
     * @returns {ZipCodeRespose} The {@link ZipCodeList zip code loaded}, if it 
     * does not exist, it returns undefined.
     */
    getZipCode(zipCode: string, address: string, city?: string): ZipCodeRespose | undefined {
        return this.zipCodesData[this.formatAddress(zipCode, address, city)];
    }

    /**
     * This method validates the existence of a 
     * {@link ZipCodeList response zip code} within the 
     * {@link ZipCodeService.zipCodesData Zip Code List}.
     * @param {string} zipCode Zip code value to validate
     * @param {string} address Address value to validate
     * @returns {boolean} Validation result. Returns "true" if the attribute 
     * exists and "false" if it does not.
     */
    validateZipCode(zipCode: string, address: string, city?: string): boolean {
        let hasZipCode = false;

        if (this.getZipCode(zipCode, address, city)) hasZipCode = true;

        return hasZipCode;
    }
}