<div class="modal fade" id="headerReportModal" tabindex="-1" role="dialog" aria-labelledby="headerReportModal" 
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog();">
                    <img src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold">
                    Download reports
                </h5>
                <div class="row">
                    <div class="form-group col-sm-1">
                        <div>
                            <div class="form-check ck">
                                <input class="form-check-input font-mont-regular" type="checkbox" id="gpsReport"
                                    [checked]="gpsCheckBoxChecked" (change)="gpsChecked()">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-11">
                        <div class="row">
                            <label class="form-check-label font-mont-regular" for="gpsReport">
                                GPS Reports
                            </label>
                        </div>
                        <div class="row mt-2">
                            <div class="form-check">
                                <input class="form-check-input font-mont-regular"  type="radio"
                                    name="gridRadioGPS" id="gpsXSL" value="gpsXSL" [(ngModel)]="gpsXSL"
                                    (change)="gpsFormatsChecked($event)">
                                <label class="form-check-label font-mont-regular" for="gpsXSL">
                                    XLS
                                </label>
                            </div>
                            <div class="form-check ml-5">
                                <input class="form-check-input font-mont-regular" type="radio"
                                    name="gridRadioGPS" id="gpsCSV" value="gpsCSV" [(ngModel)]="gpsCSV"
                                    (change)="gpsFormatsChecked($event)">
                                <label class="form-check-label font-mont-regular" for="gpsCSV">
                                    CSV
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-sm-1">
                        <div>
                            <div class="form-check">
                                <input class="form-check-input font-mont-regular" type="checkbox" id="feedbackReport"
                                    [checked]="feedbackCheckBoxChecked" (change)="feedbackChecked()">
                            </div>
                        </div>

                    </div>
                    <div class="form-group col-sm-11">
                        <div class="row">
                            <label class="form-check-label font-mont-regular" for="feedbackReport">
                                Feedback Reports
                            </label>
                        </div>
                        <div class="row mt-2">

                            <div class="form-check">
                                <input class="form-check-input font-mont-regular" type="radio"
                                    name="gridRadioFeedback" id="feedbackXSL" value="feedbackXSL"
                                    [(ngModel)]="feedbackXSL" (change)="feedbackFormatsChecked($event)">
                                <label class="form-check-label font-mont-regular" for="feedbackXSL">
                                    XLS
                                </label>
                            </div>

                            <div class="form-check ml-5">
                                <input class="form-check-input font-mont-regular" type="radio"
                                    name="gridRadioFeedback" id="feedbackCSV" value="feedbackCSV"
                                    [(ngModel)]="feedbackCSV" (change)="feedbackFormatsChecked($event)">
                                <label class="form-check-label font-mont-regular" for="feedbackCSV">
                                    CSV
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="button" (click)="exportSelectedReports() "
                            class="btn btn-primary button-download col-12 rounded-pill font-mont-regular mt-3 mb-4 "
                            [ngClass]="{'disabled': !somethingToExport() }"
                            [disabled]="!somethingToExport()">Download</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>