<div class="titleContainer">
    <div class="row sub-section-title" *ngIf="imeiAssociated">
        <div class="col-12">
            <img src="/assets/images/icons/blue-circle-check.svg" alt="IMEI activated" />
            <span>IMEI has been activated.</span>
        </div>
    </div>

    <div class="sub-section-title" *ngIf="imeiRequired">
        <h2 class="font-mont-regular pt-3 pb-1">
            <span class="d-inline-flex justify-content-stretch align-text-bottom mr-1">
                <img src="/assets/images/icons/add_equipment.svg" alt="Add IMEI icon" />
            </span>Add IMEI
        </h2>

        <div class="ept-fld pb-4">
            <label for="name">GPS IMEI:
                <div class="tooltip-ept">
                    <img src="/assets/images/icons/light_gray_i_2.svg" alt="" />
                    <p class="tooltip-ept-text">
                        You can find the IMEI number located on the inventory sticker that came with the device.
                    </p>
                </div>
            </label>
            <input class="custom-select" id="imeiNumber" type="text" name="imeiNumber" placeholder="GPS IMEI"
                (keyup)="onSortSearch(imeiNumber)" [(ngModel)]="imeiNumber" autocomplete="off">

            <p *ngIf="errorMessage === ''" class="font-mont-regular filterLabel">Enter the IMEI number shown on the GPS
                sticker/device</p>
            <p *ngIf="errorMessage !== ''" class="font-mont-regular errorLabel">{{errorMessage}}</p>

            <button type="button" class="submitButton btn btn-secondary btn-lg rounded-pill font-mont-regular"
                [ngClass]="{'submitButtonEnable': !isIMEIEmpty()}" (click)="onsave()" [disabled]="isIMEIEmpty()"
                data-dismiss="modal">Add IMEI</button>
        </div>
    </div>

    <div class="row sub-section-title">
        <div class="col-12">
            <img src="/assets/images/icons/funded_icon_rocket.svg" alt="Funded" />
            <span>Congratulations! Your application has funded.</span>
        </div>
    </div>

</div>

<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageAClass]="messageMessageAClass" [messageBClass]="messageMessageBClass"
    [messageCClass]="messageMessageCClass" [dialogClass]="dialogClass" (closeModal)="onCloseModal();">