import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { CsvFileTypes, IgxCsvExporterOptions, IgxCsvExporterService } from 'igniteui-angular';
import { AuthService } from "src/app/auth/auth.service";
import { ClickResponse } from "src/app/auth/click.response.model";
import { ConnectionService } from "src/app/shared/services/connection.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from 'rxjs';
import { setTimeout } from 'timers';


@Component({
  selector: 'app-leasable-export',
  templateUrl: './leasable-export.component.html',
  styleUrls: ['./leasable-export.component.css']
})
export class LeasebleExportComponent implements OnInit {

  @Input() itemsSelected: any;
  @Input() vendorName: any = "";
  @Input() vendorIdNew: any = "";
  @Output() newItemEvent = new EventEmitter<boolean>();
  @ViewChild('toggleButton2') toggleButton2: ElementRef;

  public selectedVendor: any = {};
  public items: any[] = [];
  public vendorID = "";
  public isError: boolean = false;
  public cleanFilter: any;
  public exportClass: boolean = true;
  public checked = false;
  public isExport: boolean = false;

  product: any = [{
    sku: '',
    vendorId: 0,
    title: '',
    description: '',
    bundledItem: '',
    maxPrice: 0,
    minPrice: 0,
    maxSoftCostPercent: 0,
    status: '',
    archived: true,
    residentialShipping: true,
    titleRequired: true
  }];

  ngOnInit(): void {
  }

  constructor(private csvExportService: IgxCsvExporterService,
    private _authService: AuthService,
    private _connectionService: ConnectionService) {
  }

  public exportCsvButtonHandler() {
    var d = new Date,
      dformat = [d.getMonth() + 1,
      d.getDate(),
      d.getFullYear()].join('/') + ' ' +
        [d.getHours(),
        d.getMinutes(),
        d.getSeconds()].join(':');

    const opt: IgxCsvExporterOptions = new IgxCsvExporterOptions('Clicklease-Export-' + dformat, CsvFileTypes.CSV);
    this.product = []; 
    
    for (let i = 0; i < this.items[0].length; i++) {
      this.product.push({
        sku: this.items[0][i].sku,
        vendorId: this.items[0][i].vendorId,
        title: this.items[0][i].title.replace('"', ' '),
        description: this.items[0][i].description,
        bundledItem: this.items[0][i].isBundle,
        maxPrice: this.items[0][i].maxPrice,
        minPrice: this.items[0][i].minPrice,
        maxSoftCostPercent: this.items[0][i].maxSoftCostPercent,
        status: this.items[0][i].status,
        archived: this.items[0][i].archive,
        residentialShipping: this.items[0][i].residentialShipping,
        titleRequired: this.items[0][i].titleRequired
      });
    }
    //this.product.shift();
    
    this.csvExportService.exportData(this.product, opt);
    this.isError = false;
    this.newItemEvent.emit(this.isError);
  }  

  onClick(val) {
    this.checked = val;  
    if(this.vendorID != "" || this.vendorIdNew != ""){
      this.isExport = true;
    }else{
      this.clean();
    }    
  }

  getItemsExport() {
    var idVendor;
    if(this.vendorName == ""){
      idVendor= this.vendorID['id'];
    }else{
      idVendor= this.vendorIdNew;
    }
    
    const getInfo = {
      vendorId: idVendor,
      accessToken: this._authService.getAccessToken()
    }
    this._connectionService.getLeasableRequest(
      environment.gatewayV3 + 'leasableitems/api/items/' +
      getInfo.vendorId, 'application/json', 'session_token=' +
      getInfo.accessToken + '&page=' + 0 + '&size=' + 1000)
      .subscribe(
        (data: any) => {         
          this.items = [];                   
          if(this.checked){
            this.items.push(this.itemsSelected);          
          }else{
            this.items.push(data.body);  
          }      
          
          if (this.items[0].length == 0) {
            console.log(this.items[0].length);            
            this.errorAlert();              
          }else{
            this.exportCsvButtonHandler();
          }
        },
        (error: any) => {
          if (error.status == 400) {
            console.log('Error:', error, ' [Bad Request]');
            this.errorAlert();
          } else {
            console.log("Error: ", error);
            this.errorAlert();
          }
        });
  }

  selectVendor(vendor: string) {
    this.vendorID = vendor;
  }

  errorAlert() {
    this.isError = true;
    this.newItemEvent.emit(this.isError);
  }
  
  /********Clean filter***********/
  clean() {
    this.cleanFilter = {};
    this.vendorID ="";
    
    let filterExportName = document.querySelectorAll('.icon');
    let vendorList = document.querySelectorAll('.vendorList');
    let filter = document.querySelectorAll('.filter');
    let radioButton = document.querySelectorAll('.allProducts');
    
    if(this.vendorName == ''){
      filterExportName[1]['value'] = "";
    }

    radioButton[0]['checked']=false;

    if(this.itemsSelected.length !== 0){
      let radioButton2 = document.querySelectorAll('.onlyProducts');
      radioButton2[0]['checked']=false;
    }
    this.isExport = false;
    
 
    filter.forEach(element => {
      element.remove();
    });

    vendorList.forEach(element => {
      element.remove();
    });
  
  }
}
