import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filter"
})
export class ArrayFilterPipe implements PipeTransform {
    transform(array: any, field: string, value: any): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        if(!field || !value) {
            return array;
        }
        if(value === 'true') {
            value = true;
        }
        if(value === 'false') {
            value = false;
        }
        array = array.filter((a: any) => {
            return a[field] == value;
        });
        return array;
    }
}