import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from "@angular/forms";
import { AuthService } from "src/app/auth/auth.service";
import { ConnectionService } from "src/app/shared/services/connection.service";
import { environment } from "src/environments/environment";
import { ClickResponse } from 'src/app/auth/click.response.model';
declare var $: any;

@Component({
  selector: "app-leasable-list",
  templateUrl: "./leasable-list.component.html",
  styleUrls: ["./leasable-list.component.css"],
})
export class LeasableListComponent implements OnInit {

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('sortDrop') sortDrop: ElementRef;
  @ViewChild('bulkDrop') bulkDrop: ElementRef;
  //@ViewChild('filterStatus') filterStatus: ElementRef;

  public current: number = 1;
  public items: any[] = [];
  public allItems: any[] = [];
  public perPage = 10;
  public total: number = 0;
  public index: number;
  public filterClass: boolean = true;
  public isError: boolean = false;
  public cleanFilter: any;
  public vendorID = '';
  public vendorName = '';
  public isEnabled: boolean = false;
  public itemsSelected: any[] = [];
  public isVendor: boolean = false;
  public isAdmin: boolean = false
  public isBulk: boolean = false;
  public isSubmit: boolean = false;
  public lastStatus = '';
  public delPop: any;
  public selectItem: any = [{
    title: "",
    sku: "",
    description: "",
    softMax: "",
    price: null,
    min: null,
    status: "Draft",
  }];

  public vendor = {
    id: 0,
    name: ''
  };
  public statusList: Array<Object> = [
    { name: "Leasable", checked: false },
    //{ name: "In Review", checked: false },
    { name: "Draft", checked: false },
    { name: "Draft with Errors", checked: false },
    { name: "Rejected", checked: false },
    { name: "Archived", checked: false }
  ];

  isfiltered: boolean = false;
  selectedItems: number = 0;
  statusFilterOpen: boolean = false;
  sortField: string = '';
  sort: string = '';

  constructor(
    private _authService: AuthService,
    private _connectionService: ConnectionService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.getLeasableItems();
    this.getAllItems();
    this.isError = false;
    this.bulkVal();
  }

  ngAfterViewInit() {
    this.poopover();
    this.closePopover();
  }



  onLoad() {
    var count: number = this.checkedOps();
    var items = document.querySelectorAll<HTMLInputElement>(".slcItem");

    items.forEach((item) => {
      item.addEventListener("change", function () {
        if (item.checked) {
          count += 1;
        } else {
          if (count > 0) {
            count -= 1;
          }
        }
      });
      this.selectedItems = count;
    });
  }


  getItem(id: any) {
    this.isBulk = false;
    this.index = id;
    this.selectItem = this.items[0][id];
    this.lastStatus = this.selectItem.status;

    let adminUser = JSON.parse(localStorage.getItem('logged_user_data'));
    adminUser.roles.forEach(element => {
      if (element.id == 11) {
        this.isAdmin = true;
      }
    });
  }

  getSelected(id: any) {
    var cont: number = 0;
    var count: number = this.checkedOps();
    var tmp: any[] = [];

    if (count - 1 < this.itemsSelected.length) {
      this.itemsSelected.forEach(item => {
        if (this.items[0][id].sku == item.sku) {
          tmp.push(item);
        } else {
          tmp.unshift(item);
        }
      });
      this.itemsSelected = [];
      tmp.pop();
      this.itemsSelected = tmp;
    } else {
      if (count != 0) {
        this.itemsSelected.push(this.items[0][id]);
      }
    }
  }

  getLeasableItems() {
    let vendor = localStorage.getItem('vendorInfo');

    if (this.vendorID != '' || vendor != null) {
      if (vendor != null && this.vendorID == '') {
        vendor = JSON.parse(vendor);
        this.vendorID = vendor['id'];
        this.vendorName = vendor['name'];
      }
      const getInfo = {
        vendorId: this.vendorID,
        accessToken: this._authService.getAccessToken(),
        currentPage: this.current - 1,
        sizePage: this.perPage
      }

      this._connectionService.getLeasableRequest(
        environment.gatewayV3 + 'leasableitems/api/items/' + getInfo.vendorId
        , 'application/json', 'session_token=' + getInfo.accessToken + '&sort=' + this.sort + '&page=' + getInfo.currentPage + '&size=' + getInfo.sizePage)
        .subscribe(
          (data) => {
            this.items = [];
            this.items.push(data['body']);
            this.total = Math.ceil(data.headers.get('x-total-count') / this.perPage);
            this.isfiltered = true;

            if (this.items[0].length == 0) {
              this.isError = true;
              this.isfiltered = false;
            } else {
              this.isError = false;
            }
            if (this.vendor['name'] != '') {
              this.vendorName = this.vendor['name'];
              localStorage.removeItem('vendorInfo');
            }

          },
          (error: any) => {
            console.log(error);
            this.items = [];
            this.total = 0;
            this.isError = true;
            this.isfiltered = false;
          });
    }
  }

  getAllItems() {
    const getInfo = {
      vendorId: this.vendorID,
      accessToken: this._authService.getAccessToken()
    };
    this._connectionService.getLeasableRequest(
      environment.gatewayV3 + 'leasableitems/api/items/' +
      getInfo.vendorId, 'application/json', 'session_token=' +
      getInfo.accessToken + '&page=' + 0 + '&size=' + 1000)
      .subscribe(
        (data: any) => {
          this.allItems = [];
          this.allItems.push(data.body);
        },
        (error: any) => {
          if (error.status == 400) {
            console.log('Error:', error, ' [Bad Request]');
          } else {
            console.log("Error: ", error);
          }
        });
  }

  getImportElements(data) {
    this.items = data;
  }

  selectVendor(vendor: any) {
    this.vendor = vendor;
    this.vendorID = vendor.id;
  }

  deleteSelected() {
    if (this.itemsSelected.length > 0) {
      const getInfo = {
        vendorId: this.vendorID,
        accessToken: this._authService.getAccessToken()
      }
      this._connectionService.deleteLeasableListRequest(
        environment.gatewayV3 + 'leasableitems/api/itemsList/' + getInfo.vendorId, this.itemsSelected, 'application/json', 'session_token=' + getInfo.accessToken)
        .subscribe(
          (data: ClickResponse) => {
            localStorage.setItem('vendorInfo', JSON.stringify({ id: this.vendorID, name: this.vendorName }));
            this.onCloseMapingModal();
          },
          (error: any) => {
            console.log(error);
          });
    }
  }

  individualDelete(id: any) {
    var skuDelete = id;
    const getInfo = {
      vendorId: this.vendorID,
      accessToken: this._authService.getAccessToken()
    }
    this._connectionService.deleteLeasableRequest(
      environment.gatewayV3 + 'leasableitems/api/items/' + getInfo.vendorId + '/' + skuDelete, 'application/json', 'session_token=' + getInfo.accessToken)
      .subscribe(
        (data: ClickResponse) => {
          localStorage.setItem('vendorInfo', JSON.stringify({ id: this.vendorID, name: this.vendorName }));
          this.onCloseMapingModal();
        },
        (error: any) => {
          console.log(error);
        });
  }

  individualUpdate(importForm: NgForm) {
    let itemsToEdit: any[] = [];
    let status: string;
    let toUpdate: any[] = [];

    // validate whether the selected or all items should be edited.
    if ((this.itemsSelected.length > 0 && importForm.value.editItems === 'onlySelected')
      || (this.itemsSelected.length > 0 && importForm.value.editItems === '')) {
      this.itemsSelected.forEach(element => {
        itemsToEdit.push(element);
      });
    } else {
      this.allItems[0].forEach(element => {
        itemsToEdit.push(element);
      });
    }
    if (itemsToEdit.length > 0) {

      itemsToEdit.forEach(item => {
        if (item.status == 'PENDING' && importForm.value.editItems === '') {
          status = 'LEASABLE';
        } else if (importForm.value.editItems !== '') {
          status = importForm.value.selectStatus;
        } else {
          status = item.status;
        }

        var tempItem = {
          vendorId: this.vendorID,
          title: item.title,
          sku: item.sku,
          description: item.description,
          status: status,
          archived: false
        }
        toUpdate.push(tempItem);
      });
      this.selectItem = toUpdate;
    }
  }

  submit(importForm: NgForm) {
    let status = '';

    if (this.lastStatus != '' && this.lastStatus == 'PENDING') {
      status = this.selectItem.status;
    } else {
      status = 'DRAFT';
    }
    if (!this.isBulk) {
      this.selectItem = [{
        vendorId: this.vendorID,
        title: importForm.value.title,
        sku: importForm.value.sku,
        description: importForm.value.description,
        status: status,
        archived: false
      }];
      this.submitLeasableRequest();
    } else {
      if(this.itemsSelected.length > 0 || this.isSubmit ){
        this.individualUpdate(importForm);
        this.submitLeasableRequest();
      } else{
        this.isBulk = false;
      }
    }
  }

  submitLeasableRequest(){
    const getInfo = {
      vendorId: this.vendorID,
      accessToken: this._authService.getAccessToken()
    }
    this._connectionService.postLeasableRequest(
      environment.gatewayV3 + 'leasableitems/api/itemsList/' + getInfo.vendorId, this.selectItem, 'application/json', 'session_token=' + getInfo.accessToken)
      .subscribe(
        (data: ClickResponse) => {
          localStorage.setItem('vendorInfo', JSON.stringify({ id: this.vendorID, name: this.vendorName }));
          this.lastStatus = '';
          this.isBulk = false;
          this.onCloseMapingModal();
        },
        (error: any) => {
          console.log(error);
          $("#myModal").modal("hide");
        });
  }

  submitItems() {
    if (this.isSubmit) {
      if (this.selectItem.status == "DRAFT" || this.selectItem.status == "REJECTED") {
        this.selectItem = [{
          vendorId: this.vendorID,
          title: this.selectItem.title,
          sku: this.selectItem.sku,
          description: this.selectItem.description,
          status: "PENDING",
          archived: false
        }];
        this.executePost();
      }
    } else {
      if(this.itemsSelected.length>0){
        var tmp: any;
        this.selectItem = [];
        this.itemsSelected.forEach(item => {
          if (item.status == "DRAFT" || item.status == "REJECTED") {
            tmp = {
              vendorId: this.vendorID,
              title: item.title,
              sku: item.sku,
              description: item.description,
              status: "PENDING",
              archived: false
            };
            this.selectItem.push(tmp);
          }
        });
        this.executePost();
      }
    }
  }

  executePost() {
    const getInfo = {
      vendorId: this.vendorID,
      accessToken: this._authService.getAccessToken()
    }
    this._connectionService.postLeasableRequest(
      environment.gatewayV3 + 'leasableitems/api/itemsList/' + getInfo.vendorId, this.selectItem, 'application/json', 'session_token=' + getInfo.accessToken)
      .subscribe(
        (data: ClickResponse) => {
          localStorage.setItem('vendorInfo', JSON.stringify({ id: this.vendorID, name: this.vendorName }));
          this.onCloseMapingModal();
        },
        (error: any) => {
          console.log(error);
          $("#myModal").modal("hide");
        });
  }

  // CLEAN
  closePopovers() {
    var popovers = document.querySelectorAll('.pop');
    for (var i = 0; i < popovers.length; i++) {
      popovers[i].classList.remove('popover--active');
    }
    setTimeout(() => {
      this.reset();
    }, 1000);
  }

  reset() {
    this.total = 0;
  }

  closePopover() {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (!this.toggleButton.nativeElement.contains(e.target)
        && !this.menu.nativeElement.contains(e.target)
        && !this.sortDrop.nativeElement.contains(e.target)
        && !this.bulkDrop.nativeElement.contains(e.target)
        //&& !this.filterStatus.nativeElement.contains(e.target)
        && e.target['localName'] !== 'label') {
        var popovers = document.querySelectorAll('.pop');

        for (var i = 0; i < popovers.length; i++) {
          popovers[i].classList.remove('popover--active');
        }
      }

      /***Close delete popover ****/
      var popDelete = document.querySelectorAll('.popDelete');
      if (popDelete.length > 0
        && e.target['className'] !== 'col-6'
        //&& e.target['className'] !== 'SVGAnimatedString'
        && e['path'][1].classList[0] !== 'popDelete') {
        this.delPop = '';
      }
      /***End Close delete popover ****/
    });

  }

  // SORT
  sortBy(sortBy: string) {
    this.current = 1;
    this.sort = sortBy;
    if (this.sort === 'status,asc') {
      this.sortField = 'Status';
    } if (this.sort === 'vendor,asc') {
      this.sortField = 'Business';
    } if (this.sort === 'title,asc') {
      this.sortField = 'Ascending';
    } if (this.sort === 'title,desc') {
      this.sortField = 'Descending';
    } if (this.sort === 'date,asc') {
      this.sortField = ' Date Ascending';
    } if (this.sort === 'date,desc') {
      this.sortField = 'Date Descending';
    }
    this.filterItems();
  }

  // FILTER
  clean() {
    this.cleanFilter = {};
    let filterName = document.querySelectorAll('.popoverFilter');
    let vendorList = document.querySelectorAll('.vendorList');
    let filter = document.querySelectorAll('.filter');
    filterName[0]['value'] = "";
    filter.forEach(element => {
      element.remove();
    });
    vendorList.forEach(element => {
      element.remove();
    });
  }

  unfilter() {
    this.isfiltered = false;
    this.items = [];
    localStorage.removeItem('vendorInfo');
    location.reload();
  }

  filterItems() {
    this.getLeasableItems();
    this.closePopovers();
    this.isError = false;
  }

  toggleStatusFilter() {
    this.statusFilterOpen = !this.statusFilterOpen;
  }

  /*filterByStatus(name: string, isChecked: boolean) {
    let filterStatus: string;
    this.statusList.forEach((element, index) => {
      if (element['name'] == name) {
        this.statusList[index]['checked'] = isChecked;
        console.log(this.statusList[index]);
      }
    });
  }*/

  // END  FILTER
  // ALERT
  selectAlert(isError: boolean) {
    this.isError = isError;
    if (this.isError) {
      this.items = [];
    }
    var popovers = document.querySelectorAll('.pop');
    for (var i = 0; i < popovers.length; i++) {
      popovers[i].classList.remove('popover--active');
    };
  }

  closeAlert() {
    this.isError = false;
  }
  //  END ALERT
  // PAGINATION

  public onGoTo(page: number): void {
    this.current = page;
    this.paginate(this.current, this.perPage);
  }

  public onNext(page: number): void {
    this.current = page + 1;
    this.paginate(this.current, this.perPage);
  }

  public onPrevious(page: number): void {
    this.current = page - 1;
    this.paginate(this.current, this.perPage);
  }

  public onPerPage(perPage: number): void {
    this.perPage = perPage;
    this.current = 1;
    this.paginate(this.current, this.perPage);
  }

  public paginate(current: number, perPage: number): string[] {
    this.getLeasableItems();
    return [...this.items.slice((current - 1) * perPage).slice(0, perPage)];
  }

  currentPage() {
    this.paginate(this.current, this.perPage);
  }

  // OTHERS

  closeModal() {
    $("#modalDelete").modal("hide");
  }

  convertModal() {
    if(this.items.length > 0){
      this.isBulk = true;
      var bulkModal = document.querySelector('[id=myModal] .modal-body');
      bulkModal.setAttribute('class', 'modal-body bulk-edit-modal');
      $('#myModal').modal('show');
    }

  }

  onCloseMapingModal() {
    $("#myModal").modal("hide");
    location.reload();
  }

  option(option: boolean) {
    var bulkModal = document.querySelector('.modal-body');
    bulkModal.classList.remove("bulk-edit-modal");
    // this.isBulk = false;
    var vals = document.querySelectorAll("input.val");


    if (this.vendorName == "" || this.items[0].length == 0) {
      this.isVendor = true;
    } else {
      this.isVendor = false;
    }
    if (option == false) {
      this.isEnabled = true;
      vals.forEach(val => {
        val.setAttribute("readonly", "true");
      });
    } else {
      vals.forEach(val => {
        val.removeAttribute("readonly");
      });
      this.isEnabled = false;
      this.selectItem = {
        title: "",
        sku: "",
        description: ""
      };
    }
  }

  poopover() {
    var popovers = document.querySelectorAll('.pop');
    var popoverTriggers = document.querySelectorAll('.popover__trigger');

    for (var i = 0; i < popoverTriggers.length; i++) {
      popoverTriggers[i].addEventListener('click', function (event) {
        closeAllOthers(this.parentElement);
        this.parentElement.classList.toggle('popover--active');
      });
    }
    function closeAllOthers(ignore) {
      for (var i = 0; i < popovers.length; i++) {
        if (popovers[i] !== ignore) {
          popovers[i].classList.remove('popover--active');
        }
      }
    }
  }

  checkedOps() {
    var items = document.querySelectorAll<HTMLInputElement>(".slcItem");
    var count: number = 0;
    items.forEach((item) => {
      if (item.checked) {
        count += 1;
      }
    });
    return count;
  }

  rejectorAprove(option: boolean) {
    if ((this.lastStatus == 'PENDING' || this.isBulk) && this.isAdmin) {
      if (option) {
        this.selectItem.status = 'LEASABLE';
      } else if (!option) {
        this.selectItem.status = 'REJECTED';
      }
    }
  }

  deletepop(id: any) {
    this.delPop = id;
  }

  bulkVal() {
    if (this._authService.getLoggedUser().roles.filter((f: any) => f.id === 11).length > 0) {
      this.isAdmin = true;
    }
    var bulk = document.querySelectorAll('button.bulk-op');
    /*if (!this.isAdmin) {
      bulk.forEach(elm => {
        elm.setAttribute('disabled', 'true')
      });
    }*/
  }
}



