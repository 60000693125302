<app-header></app-header>
<div class="containerD">
    <div class="mx-auto frame">
        <div class="boxDec">
            <div>
                <img class="imgDec" src="/assets/images/Declined.svg" alt="declined" />
            </div>
            <h5 class="tittleDeclined font-mont-regular">Sorry your link has expired!</h5>
            <div class="decline-desciption">
                <div class="font-mont-book">Please contact your sales representative to request a new link OR</div>
                <div class="font-mont-book">You can reach out to clicklease support via email <a
                        href="mailto:support@clicklease.com">support@clicklease.com</a></div>
                <div class="font-mont-book"></div>
                <div class="font-mont-book">Please copy and paste the token below to better assist you.</div>
                <div class="font-mont-book"><span class="font-mont-semi-bold">Token:</span> {{token}}</div>
            </div>
            <div class="information">
                <span class="font-mont-regular">Need Help? Have Questions?</span>
            </div>
            <div class="phone">
                <div class="font-mont-book">Call <span class="font-mont-book">1-888-509-5592</span></div>
            </div>
        </div>
    </div>
</div>