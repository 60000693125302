import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
declare var $: any;

@Component({
    selector: 'app-invoice-uploader',
    templateUrl: './invoice-uploader.component.html',
    styleUrls: ['./invoice-uploader.component.css']
})
export class InvoiceUploaderComponent implements OnInit, AfterViewInit {

    isDragOver = false;
    fileName = '';

    @Input() elementId = 'elementidtestweird';
    @Input() lineA = 'JPG, PNG or PDF, smaller than 10MB';
    @Input() lineB = 'Drag and drop your image here or';
    @Input() uploaded = false;
    @Input() customClass = '';
    @Output() onFileAdded = new EventEmitter<any>();
    @Output() onClick = new EventEmitter<any>();

    constructor() {
        void (0);
    }

    ngOnInit() {
        //this.googleAnalyticsService.trackEvent("app-component", "app-invoice-uploader");
        void (0);
    }

    ngAfterViewInit() {
        $('#' + this.elementId).on('drag dragstart dragend dragover dragenter dragleave drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
        }).on('dragover dragenter', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = true;
        }.bind(this)).on('dragleave dragend drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = false;
        }.bind(this)).on('drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            this.decodeFile(e.originalEvent.dataTransfer.files);
        }.bind(this));

        if (this.onClick.observers.length) {
            $('#' + this.elementId).on('click', function (e: any) {
                e.preventDefault();
                e.stopPropagation();
                this.onClick.emit(e);
            }.bind(this));
        }
    }

    fileChangeEvent(inputFile: any) {
        this.decodeFile(inputFile.target.files)

        $(inputFile.target).val('');
    }

    decodeFile(inputFile: any) {

        var droppedFiles = inputFile;
        var onFileAdded = this.onFileAdded;

        var getBase64 = function (file: any) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                var fileReaded = {
                    name: file.name,
                    size: this.formatBytes(file.size, 0),
                    rawSize: file.size,
                    type: file.type,
                    data: reader.result,
                    remove: 'remove'
                };

                this.fileName = `${file.name} - ${this.formatBytes(file.size, 0)}`;

                onFileAdded.emit({ file: fileReaded });
            }.bind(this);
            reader.onerror = function (error) {
                void (0);
            };
        }.bind(this);

        $.each(droppedFiles, function (i: any, file: any) {
            getBase64(file);
        });
    }

    formatBytes = function (a: any, b: any) {
        if (0 == a) {
            return "0 Bytes";
        } else {
            var c = 1024,
                d = b || 2,
                e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
                f = Math.floor(Math.log(a) / Math.log(c));
            return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
        }
    };
}
