import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-vendor-list-item',
    templateUrl: './vendor-list-item.component.html',
    styleUrls: ['./vendor-list-item.component.css']
})
export class VendorListItemComponent implements OnInit {

    public vendorSubmitted = false;
    public addNewVendorSubmitted = false;
    public salesPersonForm: UntypedFormGroup;

    @Input() vendor: any = {};
    @Input() statesOfIncorporation: any[] = [];
    @Input() industries: any[] = [];
    @Input() equipmentClassifications: any[] = [];
    @Input() vendorTypes: any[] = [];
    @Input() businessTypes: any[] = [];

    @Output() itemLoaded = new EventEmitter<void>();
    @Output() showErrorMessage = new EventEmitter<string>();
    @Output() getVendorList = new EventEmitter<any>();

    public vendorForm: UntypedFormGroup;

    public cities: any[] = [];

    constructor(
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.salesPersonForm = this.formBuilder.group({
            id: [''],
            vendorId: [''],

            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contactPhone: ['', Validators.required]
        });

        this.vendorForm = this.formBuilder.group({
            dbaName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', Validators.required],
            equipmentClassification: ['', Validators.required],
            accountName: ['', Validators.required],
            address: ['', Validators.required],
            city: ['', Validators.required],
            entityType: ['', Validators.required],
            federalTaxId: ['', Validators.required],
            industry: ['', Validators.required],
            state: ['', Validators.required],
            zipCode: ['', Validators.required],

            type: [''],
            brokerCommissionPercentage: [''],
            commissionPercentage: [''],
            defaultSalespersonId: [''],
            excludeLessee: [''],
            gradeProfileId: [''],
            ownerId: [''],
            parentExternalId: [''],
            parentId: [''],
            secondaryContactEmail: ['', Validators.email],
            secondaryContactId: [''],

            id: ['']
        });
    }

    ngOnInit(): void {
        this.vendorForm.controls.dbaName.setValue(this.vendor.dbaName);
        this.vendorForm.controls.email.setValue(this.vendor.email);
        this.vendorForm.controls.phone.setValue(this.vendor.phone);
        this.vendorForm.controls.equipmentClassification.setValue(this.vendor.equipmentClassification);
        this.vendorForm.controls.accountName.setValue(this.vendor.accountName);
        this.vendorForm.controls.address.setValue(this.vendor.address);
        this.vendorForm.controls.city.setValue(this.vendor.city);
        this.vendorForm.controls.entityType.setValue(this.vendor.entityType);
        this.vendorForm.controls.federalTaxId.setValue(this.vendor.federalTaxId);
        this.vendorForm.controls.industry.setValue(String(this.vendor.industry));
        this.vendorForm.controls.state.setValue(this.vendor.state);
        this.vendorForm.controls.zipCode.setValue(this.vendor.zipCode);
        this.vendorForm.controls.type.setValue(this.vendor.type);
        this.vendorForm.controls.brokerCommissionPercentage.setValue(this.vendor.brokerCommissionPercentage);
        this.vendorForm.controls.commissionPercentage.setValue(this.vendor.commissionPercentage);
        this.vendorForm.controls.defaultSalespersonId.setValue(this.vendor.defaultSalespersonId);
        this.vendorForm.controls.excludeLessee.setValue(this.vendor.excludeLessee);
        this.vendorForm.controls.gradeProfileId.setValue(this.vendor.gradeProfileId);
        this.vendorForm.controls.ownerId.setValue(this.vendor.ownerId);
        this.vendorForm.controls.parentExternalId.setValue(this.vendor.parentExternalId);
        this.vendorForm.controls.parentId.setValue(this.vendor.parentId);
        this.vendorForm.controls.secondaryContactEmail.setValue(this.vendor.secondaryContactEmail);
        this.vendorForm.controls.secondaryContactId.setValue(this.vendor.secondaryContactId);
        this.vendorForm.controls.id.setValue(this.vendor.id);
        this.onZipCodeKeyUp();
        this.itemLoaded.emit();
        //this.googleAnalyticsService.trackEvent("app-component", "app-vendor-list-item");
    }

    onEditVendor() {
        this.vendorSubmitted = true;
        if (this.vendorForm.invalid) {
            return;
        }

        let vendor = {
            dbaName: this.vendorForm.controls.dbaName.value,
            email: this.vendorForm.controls.email.value,
            phone: this.vendorForm.controls.phone.value,
            equipmentClassification: this.vendorForm.controls.equipmentClassification.value,
            accountName: this.vendorForm.controls.accountName.value,
            address: this.vendorForm.controls.address.value,
            city: this.vendorForm.controls.city.value,
            entityType: this.vendorForm.controls.entityType.value,
            federalTaxId: this.vendorForm.controls.federalTaxId.value,
            industry: this.vendorForm.controls.industry.value,
            state: this.vendorForm.controls.state.value,
            zipCode: this.vendorForm.controls.zipCode.value,
            type: this.vendorForm.controls.type.value,
            brokerCommissionPercentage: this.vendorForm.controls.brokerCommissionPercentage.value,
            commissionPercentage: this.vendorForm.controls.commissionPercentage.value,
            defaultSalespersonId: this.vendorForm.controls.defaultSalespersonId.value,
            excludeLessee: this.vendorForm.controls.excludeLessee.value,
            gradeProfileId: this.vendorForm.controls.gradeProfileId.value,
            ownerId: this.vendorForm.controls.ownerId.value,
            parentExternalId: this.vendorForm.controls.parentExternalId.value,
            parentId: this.vendorForm.controls.parentId.value,
            secondaryContactEmail: this.vendorForm.controls.secondaryContactEmail.value,
            secondaryContactId: this.vendorForm.controls.secondaryContactId.value,
            id: this.vendorForm.controls.id.value
        };

        const promise = new Promise((resolve, reject) => {
            this.connectionService.postRequest(environment.gateway + 'vendor', vendor, 'application/json').subscribe(
                (data: any) => {
                    this.getVendorList.emit(this.vendor.id);
                    resolve(data);
                },
                (error: any) => {
                    this.getVendorList.emit(this.vendor.id);
                    console.log(error);
                    reject(error);
                }
            );
        });
        return promise;
    }

    onCreateNewAttributeSubmit(id?: any) {
        this.addNewVendorSubmitted = false;

        const promise = new Promise((resolve, reject) => {
            if (id) {
                this.connectionService.getRequest(environment.gateway + 'salesperson/' + id, 'application/json').subscribe(
                    (data: any) => {
                        this.salesPersonForm.controls.id.setValue(data.object.id);
                        this.salesPersonForm.controls.vendorId.setValue(data.object.vendorId);
                        this.salesPersonForm.controls.firstName.setValue(data.object.firstName);
                        this.salesPersonForm.controls.lastName.setValue(data.object.lastName);
                        this.salesPersonForm.controls.email.setValue(data.object.email);
                        this.salesPersonForm.controls.contactPhone.setValue(data.object.contactPhone);
                        $('#createAttributeModal').modal();
                        resolve(data);
                    },
                    (error: any) => {
                        this.showErrorMessage.emit(error.error.msg);
                        reject(error);
                    }
                );
            } else {
                this.salesPersonForm.controls.id.setValue(null);
                this.salesPersonForm.controls.vendorId.setValue('');
                this.salesPersonForm.controls.firstName.setValue('');
                this.salesPersonForm.controls.lastName.setValue('');
                this.salesPersonForm.controls.email.setValue('');
                this.salesPersonForm.controls.contactPhone.setValue('');
                $('#createAttributeModal').modal();
                resolve(true);
            }
        });
        return promise;
    }

    onCreateClick() {
        this.addNewVendorSubmitted = true;
        if (this.salesPersonForm.invalid) {
            return;
        }

        $('#createAttributeModal').modal('hide');
        const promise = new Promise((resolve, reject) => {
            const postData: any = {
                id: this.salesPersonForm.controls.id.value,
                vendorId: this.vendor.id,
                firstName: this.salesPersonForm.controls.firstName.value,
                lastName: this.salesPersonForm.controls.lastName.value,
                email: this.salesPersonForm.controls.email.value,
                contactPhone: this.salesPersonForm.controls.contactPhone.value
            };
            this.connectionService.postRequest(environment.gateway + 'salesperson', postData, 'application/json').subscribe(
                (data: any) => {
                    this.getVendorList.emit(this.vendor.id);
                    resolve(data);
                },
                (error: any) => {
                    this.getVendorList.emit(this.vendor.id);
                    this.showErrorMessage.emit(error.error.msg);
                    reject(error);
                }
            );
        });
        return promise;
    }

    onZipCodeKeyUp() {
        const promise = new Promise((resolve, reject) => {
            this.getZipCodeData(this.vendorForm.controls.zipCode.value).then(
                (data: any) => {
                    if (data.object) {
                        this.cities = data.object.city;
                        this.vendorForm.controls.city.setValue(data.object.city[0]);
                        this.vendorForm.controls.state.setValue(data.object.state);
                        resolve(data);
                    } else {
                        this.cities = [];
                        resolve(data);
                    }
                },
                (error: any) => {
                    this.cities = [];
                    resolve(error);
                }
            );
        });
        return promise;
    }

    getZipCodeData(zipCode: string) {
        const promise = new Promise((resolve, reject) => {
            this.connectionService.getRequest(environment.gateway + 'application/zipData/' + zipCode, 'application/json').subscribe(
                (data: any) => {
                    resolve(data);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
        return promise;
    }

}
