import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';

export class ApplicationBusinessInfo {

    public form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private maxRequestedAmount?: any) {
        this.form = this.formBuilder.group({
            legalCompanyName: ['', Validators.required],
            doingBusinessAs: ['', Validators.required],
            businessType: ['', Validators.required],
            businessStartDate: ['', Validators.required],
            businessAddress: ['', Validators.required],
            zip: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            county: ['', Validators.required],
            primaryContactEmail: ['', [Validators.required, Validators.email]],
            requestedAmount: ['', [Validators.required, Validators.min(500)]],
            equipmentDescription: ['']
        });

        this.authService.maxApplicationAmount.then(
            (data: number) => {
                this.form.controls.requestedAmount.setValidators(data ? [Validators.required, Validators.max(data), Validators.min(500)] : [Validators.required, Validators.min(500)]);
                this.form.controls.requestedAmount.updateValueAndValidity();
            }
        );
    }

    decode(data: any) {
        this.form.controls.legalCompanyName.setValue(data.lessee.legalName);
        this.form.controls.doingBusinessAs.setValue(data.lessee.dbaName);
        this.form.controls.businessType.setValue(data.lessee.businessStructure);
        this.form.controls.businessStartDate.setValue(data.lessee.businessPurchaseDate);
        this.form.controls.businessAddress.setValue(data.lessee.address);
        this.form.controls.zip.setValue(data.lessee.zipCode);
        this.form.controls.city.setValue(data.lessee.city);
        this.form.controls.state.setValue(data.lessee.state);
        this.form.controls.county.setValue(data.lessee.county);
        this.form.controls.primaryContactEmail.setValue(data.lessee.email);
        this.form.controls.requestedAmount.setValue(data.requestedAmount);
        this.form.controls.equipmentDescription.setValue(data.description);
    }

    encode(): any {
        return {
            'description': this.form.controls.equipmentDescription.value,
            'requestedAmount': this.form.controls.requestedAmount.value,
            'lessee': {
                'legalName': this.form.controls.legalCompanyName.value,
                'dbaName': this.form.controls.doingBusinessAs.value,
                'businessStructure': this.form.controls.businessType.value,
                'businessPurchaseDate': this.getFormatedDate(this.form.controls.businessStartDate.value),
                'address': this.form.controls.businessAddress.value,
                'zipCode': this.form.controls.zip.value,
                'state': this.form.controls.state.value,
                'city': this.form.controls.city.value,
                'county': this.form.controls.county.value,
                'email': this.form.controls.primaryContactEmail.value
            }
        };
    }

    getFormatedDate(date: any): string {
        const year = date.getFullYear();
        const month = date.getMonth() > 8 ? '-' + (date.getMonth() + 1) : '-0' + (date.getMonth() + 1);
        const day = date.getDate() > 9 ? '-' + date.getDate() : '-0' + date.getDate();
        return year + month + day;
    }

    setDefaultData() {
        this.form.controls.legalCompanyName.setValue('Clicklease Demo');
        this.form.controls.doingBusinessAs.setValue('My DBA Name');
        this.form.controls.businessType.setValue('Corporation');
        this.form.controls.businessStartDate.setValue(new Date(2016, 0, 1));
        this.form.controls.businessAddress.setValue('1182 Main St');
        this.form.controls.zip.setValue('84105');
        this.form.controls.city.setValue('Salt Lake City'.toUpperCase());
        this.form.controls.state.setValue('UT');
        this.form.controls.county.setValue('Salt Lake'.toUpperCase());
        this.form.controls.primaryContactEmail.setValue('me@mail.com');
        this.form.controls.requestedAmount.setValue(15000);
        this.form.controls.equipmentDescription.setValue('Demo Test Equipment');
    }

    requestedAmount(control: UntypedFormControl) {
        if (control.errors && !control.errors.requestedAmount) {
            return;
        }
        if (control.value < 500) {
            control.setErrors({ requestedAmount: true });
        } else {
            control.setErrors(null);
        }
        return new Promise((resolve, reject) => {
            void (0);
        });
    }
}

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}
