import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-fraud-error',
  templateUrl: './fraud-error.component.html',
  styleUrls: ['./fraud-error.component.css']
})
export class FraudErrorComponent implements OnInit {

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.googleAnalyticsService.trackV3Event('process-error', this.authService.vendorReferrerName, 'process-error');
  }

}
