import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationService } from 'src/app/application/v3/services';

@Component({
    selector: 'app-data-error-v3',
    templateUrl: './data-error-v3.component.html',
    styleUrls: ['../general-error-v3/general-error-v3.component.css', './data-error-v3.component.css']
})
export class DataErrorV3Component implements OnInit {

    constructor(
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        private applicationService: ApplicationService
    ) { }

    ngOnInit(): void {
        //this.applicationService.clearForms(); reset all form services
        this.googleAnalyticsService.trackV3Event(
            'data-error',
            this.applicationService.vendorReferrerName,
            'data-error'
        );
    }

    navegateStarted(): void {
        if (this.applicationService.queryToken) {
            window.location.href = `${window.location.protocol}//${window.location.host}/inlineapp?token=${this.applicationService.queryToken}`;
        } else {
            this.router.navigate([`/`]);
        }
    }
}
