<app-application-wrapper [subTitleA]="''" [subTitleB]="''" [footerHidden]="true">
  <ng-template appApplicationWrapperHelper>
    <div class="pre-logo"></div>
      <div class="content-container">
        <h1 class="cls-title-30px font-mont-bold">Thanks for submitting your information.</h1>
        <h4 class="subtitle font-mont-semi-bold">What’s next?</h4>
        <div class="p-container">
          <div class="div-img">
            <img src="assets\images\phone_icon.svg" alt="" class="building-img">
          </div>
            <div class="div-p-cont">
              <p class="font-mont-regular">
                Please reach out to {{partnerName}} to discuss your financing options.
              </p>
            </div>
        </div>
  </div>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="/assets/images/icons/application-errors/denied-error-page.svg" alt="">
  </ng-container>
</app-application-wrapper>
