<app-application-wrapper [style]="2">
    <ng-template appApplicationWrapperHelper>
        <iframe class="embed-responsive-item" [src]="docuSignUrl" allowfullscreen title="docusignIframe"></iframe>
    </ng-template>
</app-application-wrapper>
<div class="extra-buttons" *ngIf="development">
    <button type="submit" class="btn btn-secondary btn-lg submit-btn font-mont-bold" (click)="goToFailedStep()">Fail</button>
    <!-- <button type="submit" class="btn btn-secondary btn-lg submit-btn font-mont-bold" (click)="goToError()">Error</button>     -->
    <button type="submit" class="btn btn-secondary btn-lg submit-btn font-mont-bold" (click)="goToFailPage()">Error</button>
    <button type="submit" class="btn btn-secondary btn-lg submit-btn font-mont-bold" (click)="goToFinalStep()">Final</button>
</div>