<app-application-wrapper [subTitleA]="'LEASE AGREEMENT'" [subTitleB]="''">
  <ng-template appApplicationWrapperHelper>
    <div>
      <div class="drivers-license-title font-mont-bold">Great. You're finished!</div>
      <div class="upload-license-subtitle ">
        <span class="sub-span">{{partnerName}}</span> will create an invoice and send it to Clicklease for final review.
      </div>
      <div class="upload-license-subtitle">
        Shortly after Clicklease approves the invoice, the  <span class="sub-span">doc fee of ${{docFee}}</span>  will be taken from bank account account number <span class="sub-span">{{accountNumber}}</span>.
      </div>
      <div class="upload-license-subtitle">
        The first payment will occur 30 days after the doc fee is processed.
      </div>
    </div>
  </ng-template>
  <ng-container ngProjectAs="[image]">
    <img src="/assets/images/icons/g12.svg" alt="">
  </ng-container>
  <ng-container ngProjectAs="[mobileImage]">
    <img src="/assets/images/icons/g12.svg" alt="">
  </ng-container>
</app-application-wrapper>