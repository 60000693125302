<div class="re-submit-invoice-text" *ngIf="nextStep == 'Re-Submit Invoice'">
    <span class="font-mont-semi-bold red-text">Notice: </span>
    <span>previous invoice submission rejected. invoice revisions required. An email with details has been sent.</span>
</div>
<div class="sub-section-title">
    <h2 class="font-mont-regular pt-3 pb-4">
        <span class="d-inline-flex justify-content-stretch align-text-bottom mr-1">
            <img src="/assets/images/icons/upload.svg" alt="submit invoice icon" />
        </span>{{isGPSRequired() ? 'Submit Invoice and GPS IMEI Tracking Information' : 'Submit Invoice' }}
    </h2>
</div>

<section class="cbxGpsImei" *ngIf="isGPSRequired()">
    <div class="ept-fld pb-4" *ngIf="vendorList.length > 1">
        <label for="name">
            Store Location
        </label>
        <label class="custom-select" id="vendorListId" for="vendorListId" name="searchVendor"
            (click)="showList(1)"><Span>{{selectedLocation}}</Span></label>
        <img class="select-icon clslabel" id="inputImgId" src="/assets/images/expand_more.svg" (click)="showList(1)">
        <ul [ngClass]="{'showList': show === true && selectedId === 1}">
            <li *ngFor='let location of vendorList' (click)="getImeiList(location.id)">
                <Span>{{location.company}}</Span>
            </li>
        </ul>
    </div>
    <div class="ept-fld pb-4">
        <label for="name">
            GPS IMEI:
            <div class="tooltip-ept">
                <img src="/assets/images/icons/light_gray_i_2.svg" alt="" />
                <span class="tooltip-ept-text">
                    You can find the IMEI number located on the inventory sticker that came with the device.
                </span>
            </div>
        </label>
        <input class="custom-select" id="inputId" type="text" name="searchIMEI" placeholder="GPS IMEI"
            [ngModel]="imeiOptionSelected.description" (click)="showList(2)" [disabled]="imeiOptionList?.length === 0"
            (keyup)="onSortSearch(searchImei.value)" #searchImei autocomplete="off">
        <img [ngClass]="{'disabled': imeiOptionList?.length === 0}" class="select-icon" id="inputImgId"
            src="/assets/images/expand_more.svg" alt="new_variable" (click)="showList(2)">
        <ul [ngClass]="{'showList': show === true && selectedId === 2}">
            <li *ngFor='let imei of imeiOptionList | filter:filter:filterValue | sort:sortField:invertedSort | like:search:searchIMEI; let i = index'
                (click)="selectItem(imei)">
                <span>{{imei.description}}</span>
            </li>
        </ul>

        <p *ngIf="errorMessage === ''" class="font-mont-regular filterLabel">Filter by the last four digits of the IMEI
        </p>
        <p *ngIf="errorMessage !== ''" class="font-mont-regular errorLabel">{{errorMessage}}</p>

    </div>

</section>

<div *ngIf="isGPSRequired()">
    <div class="row">
        <div class="col-12">
            <p class="upload-desc font-mont-semi-bold">For invoices with a GPS unit</p>
            <label class="upload-label font-mont-regular">Place the GPS tracker sticker on the invoice before
                uploading.</label>
            <div style="display: inline-block; margin-left: 4px;">
                <div class="tooltip-right">
                    <img class="exampleIcon" src="/assets/images/icons/submitInvoiceExampleIcon.svg" alt="File details">
                    <label class="font-mont-semi-bold seeExample">See example</label>
                    <span class="tooltip-right-text">
                        <img src="/assets/images/icons/submitInvoiceImagePopup.svg" width="535" height="465" />
                    </span>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="row sub-section-form">
    <div class="col-lg-6 col-sm-12 mt-2">
        <div class="fileContainer">
            <div class="container onlyOneFile">
                <!-- Only one file is allowed due to business restrictions -->
                <div *ngFor="let document of documents">
                    <div class="row mt-3">
                        <div class="col-12" style="text-align:right">
                            <img (click)="onFileRemoved()" class="removeIcon mb-1"
                                src="/assets/images/icons/submitInvoiceRemoveFile.svg" alt="remove file">
                        </div>
                    </div>
                    <div class="row onlyOneFileContainerIcon">
                        <img class="fileIcon mb-1" src="/assets/images/icons/submitInvoiceFileIcon.svg"
                            alt="remove file">
                    </div>
                    <div class="row onlyOneFileContainer">
                        <label class="font-mont-semi-bold filesName">
                            {{document.file.name}}
                        </label>
                    </div>
                </div>
                <div class="container uploadZone">
                    <app-upload-document *ngIf="documents.length === 0" (onFileAdded)="onFileAdded($event);"
                        [elementId]="uploadDocumentId">
                    </app-upload-document>
                </div>

            </div>
        </div>
    </div>

    <div class="col-lg-6 col-sm-12 mt-2">
        <div class="checksContainer">
            <p class="instructionsTitle font-mont-bold">A few tips to get your invoice approved</p>
            <div class="flex-check-container">
                <div>
                    <label class="font-mont-semi-bold checksName">
                        &bull; Do the addresses match?
                    </label>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <p class="font-mont-semi-bold">Bill to address</p>
                        <div class="font-mont-regular" style="line-height: 3px;">
                            <p>Clicklease LLC</p>
                            <p>1182 W 2400 S</p>
                            <p>West Valley, UT 84119</p>
                        </div>

                    </div>
                    <div class="col-6">
                        <p class="font-mont-semi-bold">Ship to address</p>
                        <div class="font-mont-regular" style="line-height: 3px;">
                            <p>{{appLegalName}}</p>
                            <p>{{invoiceShippingInfo.address}}</p>
                            <p>{{invoiceShippingInfo.city}}, {{invoiceShippingInfo.state}}
                                {{invoiceShippingInfo.zipCode}}</p>
                        </div>

                    </div>
                </div>
            </div>


            <div class="flex-check-container ">
                <label class="font-mont-semi-bold checksName">
                    &bull; Have taxes been removed from the invoice?
                </label>

                <div class="tooltip-two">
                    <img src="/assets/images/icons/light_gray_i_2.svg" alt=""
                        style="margin-top: 1px; margin-left: 4px;" />
                    <span class="tooltip-two-text">
                        Clicklease qualifies for a resale tax exemption.
                    </span>
                </div>
            </div>

            <div class="flex-check-container">
                <div>
                    <label class="font-mont-semi-bold checksName">
                        &bull; Does the invoice amount match the</label>
                    <label (click)="openModal()" class="font-mont-semi-bold checksName withUnderline">purchase
                        order</label>
                    <label class="font-mont-semi-bold checksName">issued by</label>
                    <label class="font-mont-semi-bold checksName"
                        style="margin-left: 12px; line-height: 1px;">Clicklease?</label>
                </div>
            </div>

            <div class="noteContainer">
                <div>
                    <span class="noteLabel font-mont-regular">NOTE: Payment is not guaranteed until invoice is approved.
                        Then we will</span>
                </div>
                <div>
                    <span class="noteLabel font-mont-regular">initiate funding and you will be notified of the
                        deposit.

                    </span>
                </div>
            </div>
        </div>
    </div>
</div>


<button type="button" class="submitButton btn btn-secondary btn-lg rounded-pill font-mont-regular mt-4"
    [ngClass]="{'submitButtonEnable': !isSubmitDocumentsAllowed()}" (click)="onsave()"
    [disabled]="isSubmitDocumentsAllowed()" data-dismiss="modal">Submit</button>


<div class="modal fade" id="{{pdfModalId}}" tabindex="-1" role="dialog" aria-labelledby="pdfViewerSubmitInvoice"
    data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="max-width: 740px;" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title w-100 text-left position-absolute" id="exampleModalLongTitle">
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/images/icons/close_b.svg" alt="" />
                    CLOSE WINDOW
                </button>
            </div>
            <!-- <div class="modal-body"> -->
            <div class="row">
                <div class="mainToolbar">
                    <div class="downloadContainer">
                        <button class="downloadButton font-mont-regular" (click)="downloadFile()">
                            Download
                            <img class="icon" src="/assets/images/icons/submitInvoiceDownloadPO.svg" alt="Download PO">
                        </button>
                    </div>

                    <div class="zoomControl">
                        <div>
                            <button class="zoomButton font-mont-bold" (click)="zoomOut()">-</button>
                        </div>
                        <div class="rangeContainer">
                            <input type="range" [(ngModel)]="zoom" min="1" step="0.05" max="2.50"
                                class="form-control-range" id="formControlRange">
                        </div>
                        <div>
                            <button class="zoomButton" (click)="zoomIn()">+</button>
                        </div>

                    </div>
                    <div class="percentageContainer">
                        <label class="font-mont-regular">{{percentageToDisplay()}}</label>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="pdf-viewer-viewer-window">
                    <pdf-viewer [src]="poFileBase64" [original-size]="false" [render-text]="true" [rotation]="0"
                        [show-all]="false" [fit-to-page]="true" [zoom]="zoom" [zoom-scale]="'page-fit'"
                        [external-link-target]="'blank'" [autoresize]="false" [show-borders]="false" class="pdf-viewer">
                    </pdf-viewer>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>


<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageAClass]="messageMessageAClass" [messageBClass]="messageMessageBClass"
    [messageCClass]="messageMessageCClass" [dialogClass]="dialogClass" (closeModal)="onCloseModal();">