import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import { async, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ConnectionService } from "../services/connection.service";

@Injectable({ providedIn: 'root' })
export class CustomFormValidationsService {

    constructor(private connectService: ConnectionService) { }

    isCountyInCity(opts: { kZip: string, kCounty: string, kCity: string, pToken?: string }, formGrp?): ValidatorFn {
        return async (pCrrCtrl?: AbstractControl): Promise<any> => {
            let error: null | { [k: string]: boolean } = null;
            formGrp = pCrrCtrl.parent;
            const getZipCodeData = ({ zipCode, token }) => this.connectService.getRequest(
                `${environment.gateway}application${(token ? 'Nl' : '')}/zipData/${zipCode}`,
                'application/json', null, null,
                (token ? { name: 'token', value: token } : null)).toPromise()
                .then(
                    (data: any) => {
                        return data
                    },
                    (err: any) => {
                        console.log(err);

                        return err;
                    }
                );
            const crrCounty = formGrp.get(opts.kCounty);
            const crrCity = formGrp.get(opts.kCity);
            const crrZipCode = formGrp.get(opts.kZip);
            if (crrCity && crrCounty && crrZipCode) {
                let zipCodeInfo = await getZipCodeData({ zipCode: formGrp.get(opts.kZip).value, token: opts?.pToken ?? null });
                zipCodeInfo = zipCodeInfo['object'];
                if (zipCodeInfo) {
                    //* if county is found, its a valid county
                    const countyFound = zipCodeInfo['cityCounties']
                        .filter(({ city, county }) => city.toLocaleLowerCase() === crrCity.value.toLocaleLowerCase()
                            && county.toLocaleLowerCase() === crrCounty.value.toLocaleLowerCase());
                    if (!countyFound.length) error = { countyNotMatchCity: true };
                }
            }
            else error = { required: true };
            return error;
        }
    }

}