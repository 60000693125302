<button class="clk-btn" (click)="openSearchModal1();" (click)="clean()">
  <img src="../../../assets/images/icons/leasable-items/import_leasable.svg" alt="import_leasable">
  Import</button>
<div class="container">
  <div class="modal fade fullscreen-modal" id="importProductsModal" tabindex="-1"
    aria-labelledby="selectOfferModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
        <div class="modal-body">
          <div class="close_button" (click)="onCloseSearchModal()"></div>
          <form (ngSubmit)="fileChangeListener(importForm)" #importForm="ngForm">
            <div class="container">
              <div class="row">
                <label for="recipient-name" class="labelHeader spacing3">Import Products</label>
              </div>
              <div class="row align-items-start">
                <label for="" class="labelTitle4 spacing3">Business account to import into</label>
              </div>
              <div class="row align-items-start">
                <app-leasable-filter (newItemEvent)="selectVendor($event)" class="spacing3" [searchClass]="modalClass" [selectedVendor]="cleanFilter">
                </app-leasable-filter>
              </div>
              <div class="row align-items-start fullscreen-area">
                <div class="spacing3">
                  <label for="recipient-name" class="labelTitle2">Upload CSV</label>
                  <app-leasable-items-drag-and-drop (onFileAdded)="onFileAdded($event);" [documents]="documents">
                  </app-leasable-items-drag-and-drop>
                </div>
              </div>
              <br>
              <div class="row align-items-start">
                <div class="spacing3">
                  <button type="button" class="btnCancel" data-dismiss="modal" aria-label="Close"
                    (click)="onCloseSearchModal()">
                    Cancel
                  </button>
                </div>
                <div *ngIf="documents.length <= 0 || vendorID == ''; else elseButton">
                  <button class="btnImport disabled" disabled>
                    <img class="iconAlign" src="../../../assets/images/icons/leasable-items/download_leasable.svg"
                      alt="download_leasable">
                    <span>
                      Import

                    </span>
                  </button>
                </div>
                <ng-template #elseButton>
                  <div>
                    <button class=" btnImport" (click)="openSearchModal2();">
                      <img class="" src="../../../assets/images/icons/leasable-items/download_leasable.svg"
                        alt="download_leasable">
                      <span>
                        Import

                      </span>
                    </button>
                  </div>
                </ng-template>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<app-leasable-import-preview [headersProducts]="headersProducts" [products]="products" [vendorID]="vendorID">
</app-leasable-import-preview>
