<div class="containerD">
    <div class="mx-auto frame">
        <div class="boxDec">
            <div>
                <img class="imgDec" src="/assets/images/icons/application-errors/table-list-icon.svg" alt="de declined" />
            </div>
            <h5 class="tittleDeclined font-mont-bold">Oops!</h5>
            
            <h2 class="subtitle font-mont-regular">We are experiencing errors trying to submit your information. 
                Please</h2>
            <div class="subtitle font-mont-regular">click this <span class="font-mont-bold cursor-pointer" (click)="sendFinicityVerifyBankAccountEmail();">link</span>
                to receive an email so you can try again later.</div>

            <div class="information">
                <span class="font-mont-regular">Need Help? Have Questions?</span>
            </div>
            <div class="phone">
                <div class="font-mont-book">Call <span class="font-mont-book">1-888-509-5592</span></div>
            </div>
        </div>
    </div>
</div>
<app-error-dialog [modalId]="errorId" [title]="errorTitle" [messageA]="errorMessageA" [messageB]="errorMessageB"
    [messageC]="errorMessageC" [messageBClass]="errorMessageBClass" [dialogClass]="'w600'">
</app-error-dialog>