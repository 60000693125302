<app-header></app-header>
<div class="containerD">
    <div class="mx-auto frame">
        <div class="boxDec">
            <div>
                <img class="imgDec" src="/assets/images/icons/de_decline.svg" alt="de declined" />
            </div>
            <h5 class="tittleDeclined font-mont-regular">Unfortunately, we are unable to</h5>
            <h5 class="tittleDeclined font-mont-regular">process your application at this time.</h5>
            <div class="decline-desciption">
                <div class="font-mont-book">Please confirm that you do not have a credit lock in your account.</div>
                <div class="font-mont-book">Check your email for information on contacting Experian to</div>
                <div class="font-mont-book">remove the lock. Additionally, if your credit is not locked, you</div>
                <div class="font-mont-book">may want to review the information you submitted to make sure</div>
                <div class="font-mont-book">the information is correct.</div>
            </div>
            <div class="information">
                <span class="font-mont-regular">Need Help? Have Questions?</span>
            </div>
            <div class="phone">
                <div class="font-mont-book">Call <span class="font-mont-book">1-888-509-5592</span></div>
            </div>
        </div>
    </div>
</div>