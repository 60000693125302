<app-application-wrapper [subTitleA]="'LEASE AGREEMENT'" [style]="0">
    <ng-template appApplicationWrapperHelper>
        <div [ngClass]="{'scroll-disabled' : showModal}">
            <div>
                <h1 class="welcome-title font-mont-bold">
                    Welcome back! Your lease documents are ready to be signed.
                </h1>
                <div class="welcome-subtitle font-mont-regular">Get your one-time passcode to get started.</div>
                <div class="mobile-number-check font-mont-regular">
                    Send text message to (XXX) XXX-{{usaPhoneNumber}}
                </div>
            </div>
            <button type="submit" class="btn btn-secondary btn-lg submit-btn w-100 font-mont-bold"
                (click)="onContinueClick()">Continue</button>
        </div>
    </ng-template>
    <ng-container ngProjectAs="[image]">
        <img src="../../../../assets/images/logoEmail.svg" alt="image">
    </ng-container>
    <ng-container ngProjectAs="[mobileImage]">
        <img src="../../../../assets/images/logoEmail.svg" alt="image">
    </ng-container>
</app-application-wrapper>
<app-otp-modal
    *ngIf="showModal"
    [usaPhoneNumber]="usaPhoneNumber"
    [useService]="clickSingService"
    (verifyActionOTP)="verifyActionCallback($event)"
    (closeModal)="closeModal($event);"
    >
</app-otp-modal>