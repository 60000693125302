import { Component, OnInit, Input } from '@angular/core';
import { LeasableImportComponent } from '../leasable-import.component';
import { AuthService } from '../../../auth/auth.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { environment } from 'src/environments/environment';
import { ClickResponse } from 'src/app/auth/click.response.model';
declare var $: any;
@Component({
  selector: 'app-leasable-import-preview',
  templateUrl: './leasable-import-preview.component.html',
  styleUrls: ['./leasable-import-preview.component.css']
})

export class LeasableImportPreviewComponent implements OnInit {

  public title: string;
  public sku: string;
  public description: string;
  public isBundle: string;
  public price: number;
  public minPrice: number;

  @Input() headersProducts: any = [];
  @Input() products: any = [];
  @Input() vendorID: string = '';
  invoice: LeasableImportComponent; // for invoice details

  product: any = [{
    sku: '',
    vendorId: 0,
    title: '',
    description: '',
    bundledItem: '',
    status: '',
    archived: true,
    residentialShipping: true,
    titleRequired: true
  }]

  constructor(
    private _authService: AuthService,
    private _connectionService: ConnectionService) { }

  ngOnInit(): void {
    this.cleanList();
  }

  import() {
    this.cleanList();
    
    for (let item of this.products) {
      if(item[this.sku] != undefined && item[this.sku] != ''){        
        let description = item[this.description].replaceAll(/(\\n|\\h|\\r|\[gap]|\[\/row]|\[\/col]|\t|<[^>]*>|\[.*|&nbsp;|âœ”|Â|")/gim, '').substring(0, 255);
        let sku = item[this.sku];
        let status = 'DRAFT';
        if (sku == '') {
          status = 'DRAFT';
        }
        this.product.push({
          sku: item[this.sku],
          vendorId: this.vendorID['id'],
          title: item[this.title],
          description: description,
          bundledItem: 'false',
          status: status,
          archived: false,
          residentialShipping: false,
          titleRequired: false
        });
      }      
      
    }
    this.deleteLeasableItems();
    this.postLeasableItems(this.product);
  }

  postLeasableItems(postItems) {
    this._connectionService.postLeasableRequest(
      environment.gatewayV3 + 'leasableitems/api/itemsList/' + this.vendorID['id'], postItems, 'application/json', 'session_token=' + this._authService.getAccessToken())
      .subscribe(
        (data: ClickResponse) => {
          localStorage.setItem('vendorInfo', JSON.stringify({ id: this.vendorID['id'], name: this.vendorID['name'] }));
          this.onCloseMapingModal();
        },
        (error: any) => {
          console.log(error);
        });

  }

  onCloseMapingModal() {
    $("#mappingProductsModal").modal("hide");
    location.reload();
  }

  cleanList() {
    this.product = [];
  }

  deleteLeasableItems() {
    const getInfo = {
      vendorId: this.vendorID['id'],
      accessToken: this._authService.getAccessToken()
    }
    this._connectionService.deleteLeasableRequest(
      environment.gatewayV3 + 'leasableitems/api/items/' + getInfo.vendorId,
      'application/json', 'session_token=' + getInfo.accessToken)
      .subscribe(
        (data: ClickResponse) => {
        }, (error: any) => {
          console.log("Error: ", error.status);
        }
      );

  }

  cleanHeaders() {
    this.headersProducts = '';
    location.reload();
  }

}


