<app-header></app-header>
<div class="body-container">
    <div [formGroup]="scheduleBacklogForm" class="schedule-container">
        <div class="row decision-model-title-container">
            <h1 class="decision-title font-mont-regular">Schedule Backlog</h1>
        </div>
        <div class="form-row">
            <div class="form-group col-3" [formGroup]="scheduleBacklogForm">
                <label for="dataRobotModel"
                    [ngClass]="{ 'color-red': scheduleBacklogSubmitted && scheduleBacklogForm.controls.dataRobotModelSelect.errors }">For
                    model*:</label>
                <select id="dataRobotModel" class="form-control" name="dataRobotModel"
                    formControlName="dataRobotModelSelect"
                    [ngClass]="{ 'is-invalid': scheduleBacklogSubmitted && scheduleBacklogForm.controls.dataRobotModelSelect.errors }">
                    <option *ngFor="let dataRobotModel of dataRobotModels" [ngValue]="dataRobotModel">
                        {{dataRobotModel.description}}
                    </option>
                </select>
                <div *ngIf="scheduleBacklogSubmitted && scheduleBacklogForm.controls.dataRobotModelSelect.errors"
                    class="invalid-feedback">
                    <div *ngIf="scheduleBacklogForm.controls.dataRobotModelSelect.errors.required">
                        Model is required.
                    </div>
                </div>
            </div>
            <div class="form-group col-3" [formGroup]="scheduleBacklogForm">
                <label for="scheduleStartDate"
                    [ngClass]="{ 'color-red': scheduleBacklogSubmitted && scheduleBacklogForm.controls.scheduleStartDate.errors }">Apps
                    Start Date:*</label>
                <input type="text" class="form-control" id="scheduleStartDate" name="scheduleStartDate"
                    formControlName="scheduleStartDate" bsDatepicker placeholder="MM/DD/YYYY"
                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, containerClass: 'theme-dark-blue' }"
                    [ngClass]="{ 'is-invalid': scheduleBacklogSubmitted && scheduleBacklogForm.controls.scheduleStartDate.errors }">
                <div *ngIf="scheduleBacklogSubmitted && scheduleBacklogForm.controls.scheduleStartDate.errors"
                    class="invalid-feedback">
                    <div *ngIf="scheduleBacklogForm.controls.scheduleStartDate.errors.required">
                        Apps Start Date is required.
                    </div>
                </div>
            </div>
            <div class="form-group col-3" [formGroup]="scheduleBacklogForm">
                <label for="scheduleEndDate">Apps End Date:</label>
                <input type="text" class="form-control" id="scheduleEndDate" name="scheduleEndDate"
                    formControlName="scheduleEndDate" bsDatepicker placeholder="MM/DD/YYYY"
                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, containerClass: 'theme-dark-blue' }">
            </div>
            <div class="form-group col-3" [formGroup]="scheduleBacklogForm" *ngIf="(progressBarInfo.status == 'Open')">
                <button type="submit" id="submitButton" name="submitButton" (click)="onScheduledBacklogSubmit();"
                    class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right">
                    Schedule
                </button>
            </div>
            <div class="form-group col-3" [formGroup]="scheduleBacklogForm" *ngIf="!(progressBarInfo.status == 'Open')">
                <button type="submit" id="submitButton" name="submitButton" (click)="cancelScheduledBacklog();"
                    class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right">
                    Cancel
                </button>
            </div>
        </div>
        <div class="form-row">
            <div class="col-12">
                <div class="progress">
                    <div class="progress-text">{{progressBarInfo.completed}} / {{progressBarInfo.total}}</div>
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                        [style.width.%]="progressBarInfo.total != 0 ? progressBarInfo.completed / progressBarInfo.total * 100 : 0">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 models-list">
            <div class="row decision-model-title-container">
                <h1 class="decision-title font-mont-regular">Data Robot Models</h1>
                <button class="btn btn-secondary font-mont-regular float-right" type="submit"
                    id="createNewPricingAdjustmentOpenDlgBtn" (click)="onCreateNewDataRobotModelSubmit()">
                    <div class="tooltip-two">
                        <div class="plus-area">
                            <em class="fas fa-plus"></em>
                        </div>
                        <span class="tooltip-two-text">
                            Click here to add a new data robot model.
                        </span>
                    </div>
                </button>
            </div>
            <div class="form-row">
                <table class="table" aria-label="Data-robot Models">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center"
                                    (click)="onSortClick('description')">
                                    Description
                                </div>
                                <em class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'description'"></em>
                                <em class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'description'"></em>
                            </th>
                            <th scope="col" class="text-left font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center"
                                    (click)="onSortClick('modelAffix')">
                                    Affix
                                </div>
                                <em class="fas fa-caret-up caret-up-icon v-center"
                                    *ngIf="!invertedSort && sortField == 'modelAffix'"></em>
                                <em class="fas fa-caret-down caret-down-icon v-center"
                                    *ngIf="invertedSort && sortField == 'modelAffix'"></em>
                            </th>
                            <th scope="col" class="text-center font-mont-semi-bold">
                                <div class="font-mont-semi-bold margin-r-20 v-center"
                                    (click)="onSortClick('testModel')">
                                    Test Model
                                </div>
                                <em class="fas fa-filter filter-icon" [ngClass]="{'v-center': sortField != 'testModel'}"
                                    (click)="toggleTestModelFilter()"></em>
                                <em class="fas fa-caret-up caret-up-icon"
                                    *ngIf="!invertedSort && sortField == 'testModel'"></em>
                                <em class="fas fa-caret-down caret-down-icon"
                                    *ngIf="invertedSort && sortField == 'testModel'"></em>
                                <div class="apps-menu" [ngClass]="{'open': testModelFilterOpen}"
                                    (click)="toggleTestModelFilter()">
                                    <div class="backdrop"></div>
                                    <div class="apps-menu-container">
                                        <div (click)="onStatusCheckClick('testModel', '');">
                                            <em *ngIf="filterValue == ''" class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != ''" class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> </span>
                                            <div class="clear"></div>
                                        </div>
                                        <div (click)="onStatusCheckClick('testModel', 'true');">
                                            <em *ngIf="filterValue == 'true'" class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != 'true'" class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> True </span>
                                            <div class="clear"></div>
                                        </div>
                                        <div (click)="onStatusCheckClick('testModel', 'false');">
                                            <em *ngIf="filterValue == 'false'" class="fas fa-check-square"></em>
                                            <em *ngIf="filterValue != 'false'" class="fas fa-square"></em>
                                            <span class="font-mont-semi-bold"> False </span>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let dataRobotModel of dataRobotModels | filter:filter:filterValue | sort:sortField:invertedSort; let i = index">
                            <tr (click)="toggleSelectedRow(i, dataRobotModel.id);" class="row-data cursor-pointer"
                                [ngClass]="{'selected': selectedRow == i}" [attr.id]="'itemNumber_' + i">
                                <td>{{dataRobotModel.description}}</td>
                                <td>{{dataRobotModel.modelAffix}}</td>
                                <td class="text-center">{{dataRobotModel.testModel ? 'True' : 'False'}}</td>
                            </tr>
                            <tr class="no-highlight summary" [ngClass]="{'selected': selectedRow == i}">
                                <td colspan="3">
                                    <div *ngIf="tempSelectedRow == i">
                                        <app-data-robot-item [dataRobotModelId]="dataRobotModelId"
                                            [dataRobotModels]="dataRobotModels" (itemLoaded)="onItemLoaded();"
                                            (getDataRobotModelList)="getDataRobotModelList($event);"
                                            (showErrorMessage)="showErrorMessage($event);">
                                        </app-data-robot-item>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>

<div class="modal fade" id="addNewDataRobotModelModal" tabindex="-1" role="dialog"
    aria-labelledby="addNewDataRobotModelModal" data-keyboard="false" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img alt="Close" src="/assets/images/icons/close_b.svg" />
                    CLOSE WINDOW
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title w-100 text-left font-mont-bold" id="adjustmentModalLongTitle">
                    <img alt="Add new" src="/assets/images/icons/add_blue.svg" />
                    Add New DataRobot Model
                </h5>
                <form [formGroup]="newDataRobotModelForm" (ngSubmit)="onCreateSubmit();">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="newAdjustmentDescription"
                                        [ngClass]="{ 'color-red': addNewDataRobotModelSubmitted && newDataRobotModelForm.controls.description.errors }">Description:
                                        *</label>
                                    <input type="text" formControlName="description" class="form-control"
                                        id="newAdjustmentDescription" placeholder="Description" name="description"
                                        [ngClass]="{ 'is-invalid': addNewDataRobotModelSubmitted && newDataRobotModelForm.controls.description.errors }">
                                    <div *ngIf="addNewDataRobotModelSubmitted && newDataRobotModelForm.controls.description.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newDataRobotModelForm.controls.description.errors.required">
                                            Description is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="newDataRobotModelAffix"
                                        [ngClass]="{ 'color-red': addNewDataRobotModelSubmitted && newDataRobotModelForm.controls.modelAffix.errors }">Model
                                        Affix:
                                        *</label>
                                    <input type="text" formControlName="modelAffix" class="form-control"
                                        id="newDataRobotModelAffix" placeholder="Model Affix" name="modelAffix"
                                        [ngClass]="{ 'is-invalid': addNewDataRobotModelSubmitted && newDataRobotModelForm.controls.modelAffix.errors }">
                                    <div *ngIf="addNewDataRobotModelSubmitted && newDataRobotModelForm.controls.modelAffix.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="newDataRobotModelForm.controls.modelAffix.errors.required">
                                            Model Affix is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit"
                                class="btn btn-primary btn-lg col-md-12 col-xs-12 round-button font-mont-regular float-right">
                                Add New Model
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-error-dialog [modalId]="modalId" [title]="messageTitle" [messageA]="messageMessageA" [messageB]="messageMessageB"
    [messageC]="messageMessageC" [messageBClass]="messageMessageBClass" [dialogClass]="dialogClass"></app-error-dialog>
<app-footer></app-footer>