import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { WhatMakesUsDifferentComponent } from '../application/v2/what-makes-us-different/what-makes-us-different.component';
import { HeaderEnhanceComponent } from '../application-token/header-enhance/header-enhance.component';
import { OTPModalComponent } from './otp-modal/otp-modal.component';
import { OTPInputComponent } from './otp-input/otp-input.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FooterComponent } from './footer/footer.component';
import { ApplicationWrapperComponent } from './application-wrapper/application-wrapper.component';
import { ApplicationWrapperHelperDirective } from './directives/application-wrapper-helper.directive';
import { ModalsWrapperComponent } from './modals-wrapper/modals-wrapper.component';
import { WordHighlightDirective } from './directives/word-highlight.directive';
import { LastFourDigitsPipe } from './pipes/lastFourDigits.pipe';
import { ArrayLikePipe } from './pipes/array.like.pipe';
import { ArraySortPipe } from './pipes/array.sort.pipe';
import { ArrayFilterPipe } from './pipes/array.filter.pipe';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { DocumentsTableComponent } from './documents-table/documents-table.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        ErrorDialogComponent,
        WhatMakesUsDifferentComponent,
        HeaderEnhanceComponent,
        PaginationComponent,
        OTPModalComponent,
        OTPInputComponent,
        FooterComponent,
        UploadDocumentComponent,
        DocumentsTableComponent,
        ApplicationWrapperComponent,
        ApplicationWrapperHelperDirective,
        WordHighlightDirective,
        LastFourDigitsPipe,
        ArrayLikePipe,
        ArraySortPipe,
        ModalsWrapperComponent,
        LastFourDigitsPipe,
        ArrayFilterPipe
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgxMaskModule.forRoot(options)
    ],
    exports: [
        ErrorDialogComponent,
        WhatMakesUsDifferentComponent,
        HeaderEnhanceComponent,
        PaginationComponent,
        OTPModalComponent,
        OTPInputComponent,
        FooterComponent,
        UploadDocumentComponent,
        DocumentsTableComponent,
        ApplicationWrapperComponent,
        ApplicationWrapperHelperDirective,
        WordHighlightDirective,
        ArrayLikePipe,
        ArraySortPipe,
        ModalsWrapperComponent,
        LastFourDigitsPipe,
        ArrayFilterPipe

    ],
    providers: [
        LastFourDigitsPipe,
        ArrayLikePipe,
        ArraySortPipe,
        ArrayFilterPipe
    ]
})
export class SharedModule { }
