<div class="collapsable-tab sub-title d-flex d-flex justify-content-between align-items-center pt-3">
    <button (click)="open ? open = false : open = true"
        class="ept-btn-app-detail pl-0 pr-lg-4 m-0 border-0 flex-grow-lg-1 text-left bg-transparent">
        <span [ngClass]="{'collapse-icon--open': open}" class="collapse-icon mr-2"></span>
        <span class="font-mont-semi-bold">application history</span>
    </button>
</div>
<div class="row container" [ngClass]="{ 'open': open }">
    <div class="col-12">
        <table>
            <thead>
                <tr>
                    <th class="font-mont-semi-bold text-center">Action</th>
                    <th class="font-mont-semi-bold text-center">Date (Eastern Time)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let historyLine of history">
                    <td class="text-center">{{historyLine.action}}</td>
                    <td class="text-center">{{historyLine.timestamp}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>