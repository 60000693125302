import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DirectAccessGuardGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.router.url === '/') {
      if(state && state.url && state.url.indexOf('/rose-app/') !== -1 && route && route.queryParams && route.queryParams.key) {
        this.router.navigate([`/app-resume`], {queryParams: route.queryParams});
      } else if(state && state.url && state.url.indexOf('/inlineapp/') !== -1 && route && route.queryParams && route.queryParams.token) {
        if(route.queryParams.key) {
          this.router.navigate([`/app-resume`], {queryParams: {key: route.queryParams.key}});
        } else {
          this.router.navigate([`/inlineapp`], {queryParams: route.queryParams});
        }
      } else if(state && state.url && (state.url.indexOf('/clicksign/') !== -1 || state.url.indexOf('/oldsign/') !== -1) && route && route.queryParams && route.queryParams.token && route.queryParams.appId && route.queryParams.phoneNumber) {
        this.router.navigate([`/clicksign/otp-verification`], {queryParams: route.queryParams});
      } else if(state && state.url && state.url.indexOf('/upload-license') !== -1 && route && route.queryParams && route.queryParams.token) {
        this.router.navigate([`/upload-license`], {queryParams: route.queryParams});
      } else  {
        this.router.navigate(['/']);
      }
      return false;
    }
    return true;
  }
}
