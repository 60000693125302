import { Component, ContentChild, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationWrapperHelperDirective } from '../directives/application-wrapper-helper.directive';

@Component({
    selector: 'app-application-wrapper',
    templateUrl: './application-wrapper.component.html',
    styleUrls: ['./application-wrapper.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ApplicationWrapperComponent implements OnInit {
    @ContentChild(ApplicationWrapperHelperDirective) content!: ApplicationWrapperHelperDirective;

    vendorLogo: string = '/assets/images/click-lease-logo.png';

    @Input() subTitleA = '';
    @Input() subTitleB = '';
    @Input() stepIndex = 0;
    @Input() style = 0;
    @Input() footerHidden = false;
    @Input() steps = [
        'Personal Information',
        'Request Funding',
        'Business Information',
        'Approval'
    ];

    constructor() {
    }

    ngOnInit(): void {
    }
}
