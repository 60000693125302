import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ErrorListService } from 'src/app/shared/services';
import { GoogleAnalyticsService } from 'src/app/analytics/google.analytics.service';
import { BankProcessService } from 'src/app/bank-process';

@Component({
    selector: 'app-oops-bank-account-error-modal',
    templateUrl: './bank-account-error-modal.component.html',
    styleUrls: ['./bank-account-error-modal.component.css']
})
export class BankAccountErrorModalComponent implements OnInit {
    @Output() onAccountContinue = new EventEmitter<any>();
    @Input() applicationId: boolean;
    @Input() accessToken: string;
    connectAccount: any = null;
    submitted = false;

    constructor(
        private errorListService: ErrorListService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private bankProcessService: BankProcessService,
    ) { }

    ngOnInit(): void {
        this.googleAnalyticsService.trackClickSignEvent(
            'bank-account-error-open',
            this.bankProcessService.vendorReferrerName,
            'Bank account modal open'
        );

        this.bankProcessService.prefillBankAccountInfo(
            this.applicationId,
            this.accessToken
        )
            .then(data => this.connectAccount = data)
            .catch(err => this.errorListService.redirectToV3Error(
                err,
                'prefill_bank_account_popup_data',
                'click-sign-otp'
            ));
    }

    onAccountContinueClick() {
        this.googleAnalyticsService.trackClickSignEvent(
            'bank-account-error-continue-click',
            this.bankProcessService.vendorReferrerName,
            'Account Continue Clicked'
        );

        if (this.submitted) return;

        this.submitted = true;

        this.onAccountContinue.emit();
    }
}

