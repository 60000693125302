<label for="file{{elementId}}" class="upload-medium {{customClass}}" [ngClass]="{'done': uploaded}">
  <div class="drag-area" [attr.id]="elementId" [ngClass]="{'is-dragover': isDragOver}">
    <div *ngIf="documents.length == 0">
      <div class="uploader-image"></div>
      <div class="font-mont-book">{{lineA}} {{lineB}} <a class="fileSelectText">{{lineC}}</a></div>
    </div>
    <tr *ngFor="let document of documents">
      <div class="font-mont-book fileItem">{{document.file.name}}{{document.file.type}}</div>
      <div *ngIf="documents.length">
        <input type="file" id="file{{elementId}}" name="file{{elementId}}" (change)="fileChangeEvent($event)"
          accept="text/csv">
      </div>
    </tr>
  </div>
</label>
<div *ngIf="documents.length == 0">
  <input type="file" id="file{{elementId}}" name="file{{elementId}}" (change)="fileChangeEvent($event)"
    accept="text/csv">
</div>
